<br>
<div class="mdl-form">
    <mat-label class="color-azul">Filtrar por:</mat-label>
</div>
<form  class="mdl-form" name="modificacioBecaForm" id="modificacioBecaForm" [formGroup]="modificacioBecaForm">      

<mat-form-field  class="inpunt-width xs-width" appearance="outline">
    <mat-label>Ciclos</mat-label>
    <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" id="ciclo"  >
    <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
        {{ciclo.Clave}}
    </mat-option> 
    </mat-select>
</mat-form-field>    

<mat-form-field  class="input-centro-width xs-width" appearance="outline">
    <mat-label>Colegios</mat-label>
    <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
    <mat-option #allSelectedCentros (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
    <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
        (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
        {{centro.Nombre}}
    </mat-option>
    </mat-select>
</mat-form-field>

<mat-form-field  class="inpunt-width xs-width" appearance="outline">
    <mat-label>Secciones</mat-label>
    <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones"  multiple>
        <mat-option #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
        (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
        {{seccion.Nombre}}
        </mat-option>
    </mat-select>
</mat-form-field>   

<!-- <mat-form-field appearance="outline" class="inpunt-width xs-width" >
    <mat-label>Tipo beca:</mat-label>
    <mat-select #mySelTipoBeca name="tipoBeca" formControlName="tipoBeca"
        id="tipoBeca"  multiple>
        <mat-option  #allSelectedTipoBeca  (click)="toggleAllSelectionTipoBeca()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let tipo of tiposBeca" [value]="tipo.TipoBecaID" 
                    (click)="tosslePerOneTipoBeca(allSelectedTipoBeca.viewValue)"> 
                    {{tipo.Nombre}}
        </mat-option>
    </mat-select>
</mat-form-field>       -->

<mat-form-field class="input-centro-width xs-width" appearance="outline">
    <mat-label>Datos del alumno</mat-label>
        <input matInput placeholder="Datos del alumno" value="" formControlName="filtro" name="filtro" >
    </mat-form-field>  
<br><br>

<section>
    <div class="example-button-row">
        <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
    </div>
</section>

</form>
<br>
<div class="mdl-form mat-elevation-z8">

<mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="select">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Enviar <br> carta</mat-header-cell>
    <mat-cell  *matCellDef="let element">
        <mat-checkbox [(ngModel)]="element.Solicitud.EnviarCorreo" (change)='calculaValores($event)'></mat-checkbox> 
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="colegio">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Colegio </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.Solicitud.Centro.Nombre}}
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seccion">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Sección </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.Solicitud.Seccion.Nombre}}
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CURP">
    <mat-header-cell mat-header-cell *matHeaderCellDef> CURP </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.Solicitud.Alumno.CURP}}
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nombre">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.Solicitud.Alumno.Nombre}} {{element.Solicitud.Alumno.ApellidoPaterno}}  {{element.Solicitud.Alumno.ApellidoMaterno}}
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tipo">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo beca </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.Solicitud.TipoBeca.Nombre}}  
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="porcentaje">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Porcentaje </mat-header-cell>
    <mat-cell *matCellDef="let element"> 
        {{element.PorcentajeAprobado}}%
    </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="estatus">
    <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo de <br> carta</mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="tipo">
        <mat-select  [disabled]="!element.Solicitud.EnviarCorreo"
        [(ngModel)]="element.TipoCarta.TipoCartaID" >
            <mat-option  [value]="0"><label>Seleccione</label></mat-option>       
            <mat-option *ngFor="let tipoCarta of tiposCarta" [value]="tipoCarta.TipoCartaID" > 
                {{tipoCarta.Nombre}}
            </mat-option>                             
        </mat-select>
    </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
            <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element.Solicitud)" matTooltip="Historial">
              <mat-icon>history</mat-icon></button>    
              <button mat-icon-button color="basic" aria-label="Editar"  matTooltip="Editar" 
              [disabled]="!element.Solicitud.EnviarCorreo"
            (click)="verComentarios(element)" matTooltipPosition="right"><mat-icon>description</mat-icon></button>       
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
</mat-table>

<div *ngIf="length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
</div>
<br>
</div>          
<br>
<div class="mdl-form">
<div class="example-button-row">
    <button mat-raised-button class="color-btn"   (click)="enviarCorreo()"  class="button"> ENVIAR CORREO </button>  
</div>    
</div>