import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatTableDataSource} from '@angular/material/table';
import { first } from 'rxjs/operators';
import { TipoBeca } from '@app/_models/tipoBeca';
import { RequisitosService } from '@app/_services/requisitos.service';
import { TipoBecaReq } from '@app/_models/tipoBecaReq';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { TipobecaRequisitoCentroComponent } from '@app/_components/tipobeca-requisito-centro/tipobeca-requisito-centro.component';
import { TipobecaRequisitoSeccionComponent } from '@app/_components/tipobeca-requisito-seccion/tipobeca-requisito-seccion.component';
import { TipoBecaReqEspecificaciones} from '@app/_models/TipoBecaReqEspecificaciones';
import { EspecificacionBecaService } from '@app/_services/especificacionBeca.service';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';

@Component({
  selector: 'app-especificacion-req',
  templateUrl: './especificacion-req.component.html',
  styleUrls: ['./especificacion-req.component.scss']
})
export class EspecificacionReqComponent implements OnInit {

  displayedColumnsCiclos: string[] = ['Nombre', 'seccion', 'xCentro'];
  dataSourceRequisitos = new MatTableDataSource<TipoBecaReqEspecificaciones>();
  detalleBecaForm: FormGroup;
  becas: TipoBeca[] = []; 
  maxInvalid = false
  enableBtn = false
  cargandoRequisitos = null;
  requisitosTipoBeca : TipoBecaReq[] = [];
  tipoBecaReqEspecificaciones : TipoBecaReqEspecificaciones[] = [];
  length:number = 0
  idUsuario: number;
  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  cargandoBecas = null;
  renglonSeleccionado : TipoBecaReqEspecificaciones;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private requisitoService : RequisitosService,
    private becaservice: TipoBecaService,
    private especificacionRequisitoService : EspecificacionBecaService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    //this.iniciarTiposBeca();
    this.detalleBecaForm = this.formBuilder.group({
      beca: ['', Validators.required],
    }); 
  }
  
  onSubmit(){
    this.enableBtn = false;
    this.especificacionRequisitoService.GuardarEspecificacionBecaRequisitos(this.tipoBecaReqEspecificaciones, this.idUsuario ).pipe(first()).subscribe(
      data => {
        if (data.Exito){
          const dialogData = new ConfirmDialogModel('', "Especificaciones guardadas", false);
          const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });    
        }else{
          const dialogData = new ConfirmDialogModel('Error', data.Mensaje, false);
          const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });    


        }    
        this.enableBtn  = true;   
      },
      () => {}
    );
  }

  onNoClick(): void {
  }

  iniciarTiposBeca() {
      this.limpiarTabla();
      this.becaservice.consultaTiposBeca().then(becas => {
        this.becas = becas;
        this.cargandoBecas = null;
      }).catch(err => {
        this.cargandoBecas = "(Error)";
        console.log("Error" + err);
      });
  }  

  onSelectTipoBeca() {
    var beca = this.detalleBecaForm.get('beca').value;
    if(beca != null && beca != ''){
      this.consultarEspecificacionesTipoBeca(beca);
    }
  }  
  

  especificacionXSeccion(renglon : TipoBecaReqEspecificaciones){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.renglonSeleccionado = renglon;
    if(mCopy.BecaRequisitoSecciones == null){
      mCopy.BecaRequisitoSecciones = [];
    }

    const dialogRef = this.dialog.open(TipobecaRequisitoSeccionComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosSecciones(renglon);
      }else{
        var requisitoBeca = this.tipoBecaReqEspecificaciones.find(r => r.TipoBecaRequisito.TipoBecaRequisitoID == renglon.TipoBecaRequisito.TipoBecaRequisitoID);
        requisitoBeca.BecaRequisitoSecciones = mCopy.BecaRequisitoSecciones;
      }
    });
  }

  eliminarEspecificacionXSeccion(renglon : TipoBecaReqEspecificaciones){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por sección?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.BecaRequisitoSecciones  = [];
      }
    });    
  }

  especificacionXCentro(renglon : TipoBecaReqEspecificaciones){
    const mCopy = JSON.parse(JSON.stringify(renglon));    
    this.renglonSeleccionado = renglon;  
    if(mCopy.BecaRequisitoCentros == null){
      mCopy.BecaRequisitoCentros = [];
    }  
      const dialogRef = this.dialog.open(TipobecaRequisitoCentroComponent, {
        width: '60%',
        data: {Operation: 'Editar', Objeto: renglon}
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosCentros(renglon);
      }else{
        var requisitoBeca = this.tipoBecaReqEspecificaciones.find(r => r.TipoBecaRequisito.TipoBecaRequisitoID == renglon.TipoBecaRequisito.TipoBecaRequisitoID);
        requisitoBeca.BecaRequisitoCentros = mCopy.BecaRequisitoCentros;

      }

    });
  }

  eliminarEspecificacionXCentro(renglon : TipoBecaReqEspecificaciones){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por centro?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.BecaRequisitoCentros  = [];
      }
    });    
  }

  llenarTipoBecaReqEspecificaciones(){
    this.tipoBecaReqEspecificaciones = [];
    this.requisitosTipoBeca.forEach(
      x => {
        var RequisitoEspecificiones = new TipoBecaReqEspecificaciones();
        RequisitoEspecificiones.TipoBecaRequisito = x;
        RequisitoEspecificiones.BecaRequisitoSecciones = [];
        RequisitoEspecificiones.BecaRequisitoCentros = [];
        this.tipoBecaReqEspecificaciones.push(RequisitoEspecificiones);
      }
    );
  }
 
  consultarEspecificacionesTipoBeca(TipoBecaID : number){
    this.especificacionRequisitoService.consultaEspecificacionRequisitosBeca(TipoBecaID, this.idUsuario).then(especificaciones => {
      this.tipoBecaReqEspecificaciones = especificaciones;
   
      this.dataSourceRequisitos = new MatTableDataSource<TipoBecaReqEspecificaciones>(this.tipoBecaReqEspecificaciones);
      this.length = this.dataSourceRequisitos.data.length;
      this.cargandoRequisitos = null;
    }).catch(err => {
      //this.hayErrores = true;
      this.cargandoRequisitos = "(Error)";
      console.log("Error" + err);
    });
  }

  agregarRequisitosCentros(tipoBecaEspecificaciones : TipoBecaReqEspecificaciones){
    this.renglonSeleccionado.BecaRequisitoCentros = tipoBecaEspecificaciones.BecaRequisitoCentros;
  }

  agregarRequisitosSecciones(tipoBecaEspecificaciones : TipoBecaReqEspecificaciones){
    this.renglonSeleccionado.BecaRequisitoSecciones = tipoBecaEspecificaciones.BecaRequisitoSecciones;
  }

  limpiarTabla(){
    this.detalleBecaForm.get('beca').setValue('');
    this.tipoBecaReqEspecificaciones = [];
    this.dataSourceRequisitos = new MatTableDataSource<TipoBecaReqEspecificaciones>(this.tipoBecaReqEspecificaciones);
    this.length = this.dataSourceRequisitos.data.length;
    this.cargandoRequisitos = null;
  }

  verify() {
    this.enableBtn = false;
    this.tipoBecaReqEspecificaciones.forEach(x => {
      if( (x.BecaRequisitoCentros!= null &&   x.BecaRequisitoCentros.length > 0 ) 
      ||  (x .BecaRequisitoSecciones != null  &&  x.BecaRequisitoSecciones.length > 0) ){
        this.enableBtn = true;
      }
    });
  }
}