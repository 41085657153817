import { Component, OnInit, ViewChild } from '@angular/core';
import { SolicitudAdmisionModel } from '@app/_models/solicitudAdmisionModel';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Router } from '@angular/router';
import { CargasSevice } from '@app/_services/cargas.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-cargas',
  templateUrl: './cargas.component.html',
  styleUrls: ['./cargas.component.scss']
})
export class CargasComponent implements OnInit {

  displayedColumns: string[] = ['tipoBeca', 'colegio', 'id', 'seccion', 'grado', 'porcentaje'];
  cargasForm: FormGroup;

  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB

  tipoBeca = ''
  colegios = ''
  tipeConsulta = ''
  ciclos = ''

  disabled_ = false;
  archivoCargado = false
  tipoConsulta = false;
  selected = '';
  nombreArchivo = 'Ningún archivo seleccionado' 
  ultimoCargado = "";
  ultimoCargadoMime =  "";

  idUsuario: number;
  cargandoSecciones = null;

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  nombreProspecto_:string = "";
  ErrorExt: boolean = false;
  

  porcentaje : number =0;
  cargando :boolean = false;
  timerIdCargando :any;
  timerId : any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private cargas: CargasSevice, 
    private router: Router,
    public dialog: MatDialog) { }


  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.cargasForm = this.formBuilder.group({
      tipoConsulta: [{ value: '', disabled: this.disabled_ }, Validators.required],
    });

  
    

  }

  getSolcitudesData(event?:PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    return event;
  }

  filterFor(option){
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      this.tipoConsulta = true;
      this.archivoCargado = true;
      this.ErrorExt = false;
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.ErrorExt = true;
      this.Archivo_ = null;
      this.archivoCargado = false;
      this.nombreArchivo = 'Ningún archivo seleccionado' 
    }
  }

  importarDocumento(){ 
    var TipoCargaID   = this.cargasForm.get('tipoConsulta').value;  
    let nNulos:number = 0;
    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
          
        this.cargando = true;
        this.porcentaje = 0;
        
        this.cargas.importar(filesFD, TipoCargaID, this.idUsuario).then( res => {
          this.cargando = false;
          if(res.Exito){
            this.descargarDoc(this.Archivo_.name, this.Archivo_.type,TipoCargaID);
          }else{
            const dialogData = new ConfirmDialogModel('Error', res.Mensaje , false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });
          }

          //this.getUltimoProcesado(TipoCargaID);
          if(this.timerId)   clearInterval(this.timerId);
        }).catch(err => {
          this.cargando = false;
          console.log("Error"+ err);
        });

        var segundos = this.Archivo_.size*2;
        console.log("segundos " + segundos);
        this.timerId = setInterval(() => this.getPorcentaje(TipoCargaID), segundos);//5000

    
      }else {
        this.cargando = false;
        console.log("Exceso de peso");
        const dialogData = new ConfirmDialogModel('Error', "El documento excede el peso permitido :5M" , false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }
    }else{
      this.cargando = false;
      const dialogData = new ConfirmDialogModel('Error', "No se encontró el documento", false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

    }
  }

  descargarUltimo(){
    var TipoCargaID   = this.cargasForm.get('tipoConsulta').value;  
    if(this.ultimoCargado != null && this.ultimoCargado != ''){
      this.descargarDoc(this.ultimoCargado, this.ultimoCargadoMime,TipoCargaID )
    }

  }


  descargarDoc(nombreArchivo : string , mimeType : string, tipoCarga : number){
    this.cargas.descargarDocumentoSalida(nombreArchivo, mimeType, tipoCarga, this.idUsuario)
    .subscribe (
      data => {
        saveAs(data, nombreArchivo, {
          type: mimeType
       });
      }, 
      error => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }


  onSelectCarga(){
    var TipoCargaID   = this.cargasForm.get('tipoConsulta').value;  

    if(TipoCargaID != null && TipoCargaID != ''){
      if(this.timerIdCargando)   clearInterval(this.timerIdCargando);
      this.cargas.cargaEnProceso(TipoCargaID,this.idUsuario).then( e => {
        this.cargando = e;
        console.log("Carga en proceso " + this.cargando);
        this.getUltimoProcesado(TipoCargaID);

        if(this.cargando){
          this.timerIdCargando = setInterval(() => this.getPorcentaje(TipoCargaID), 18000);
        }
      }).catch(err => {
        this.cargando = false;
        this.mensajeError();
        console.log("Error" + err);
      }); 
  

    }
  }

  getUltimoProcesado(TipoCargaID : number){
    this.cargas.ultimoProcesado(TipoCargaID, this.idUsuario).then( archivo => {
      if(archivo!= null && archivo.Nombre != null){
        this.ultimoCargado = archivo.Nombre;
        this.ultimoCargadoMime = archivo.MimeType;
      }else{
        this.ultimoCargado = '';
        this.ultimoCargadoMime = '';
      }

    }).catch(err => {
      this.cargando = false;
      this.mensajeError(),
      console.log("Error" + err);
    }); 
  }

  getPorcentaje(TipoCargaID : number){
    console.log("Consulta porcentaje ");
    this.cargas.porcentajeProcesado(TipoCargaID, this.idUsuario).then( porcentaje => {
      this.porcentaje = porcentaje;


    }).catch(err => {
      this.cargando = false;
      this.mensajeError();
      console.log("Error" + err);
    }); 
  }

  cancelarCarga(){
    var TipoCargaID   = this.cargasForm.get('tipoConsulta').value;  

    if(TipoCargaID != null && TipoCargaID != ''){
      this.cargas.cancelarProceso(TipoCargaID, this.idUsuario).then( cancelado => {
        this.cargando = false;
      }).catch(err => {
        console.log("Error" + err);
      }); 
  

    }
  }

  mensajeError(){
    const dialogData = new ConfirmDialogModel('Error', "Hubo un error al contactar al servidor", false);    
    this.dialog.open(AlertaComponent, {
    maxWidth: "500px",
    data: dialogData
  });

  }




}