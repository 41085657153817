import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { OperationResult } from '@app/_models/operationResult';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { ResumenPropuesta } from '@app/_models/resumenPropuesta';
import { AnalisisComparativo } from '@app/_models/analisisComparativo';
import { ResponseRest } from '@app/_models/responseRest';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';

@Injectable({ providedIn: 'root' })
export class PropuestaBecaSolicitudService {

    constructor(private http: HttpClient) {}

    getPropuestaBecasSolicitud(propuesta :PropuestaBeca, SeccionID  :number){
        return this.http.post<PropuestaSolicitud[]>(`${environment.apiUrl}/GetPropuestaBecasSolicitudes`,  {propuesta, SeccionID } );
    }    
    
    GuardarPropuestaBecasSolicitudes(propuesta: PropuestaBeca, SeccionID  :number){
        return this.http.post<any>(`${environment.apiUrl}/GuardarPropuestaBecasSolicitudes`,   {propuesta, SeccionID }  );
    }

    getResumenPropuestaBecas(propuesta :PropuestaBeca, SeccionID  :number){
        return this.http.post<ResumenPropuesta>(`${environment.apiUrl}/GetResumenPropuestaBecas`,  {propuesta, SeccionID } );
    }   
  
    getAnalisisPropuestaBecas(propuesta :PropuestaBeca, SeccionID  :number){
        return this.http.post<AnalisisComparativo>(`${environment.apiUrl}/GetAnalisisComparativo`,  {propuesta, SeccionID } );
    }   
    
}