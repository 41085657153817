import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Usuario } from '@app/_models/usuario';
import { DatosUsuario } from '@app/_models/datosUsuario';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  public get isAuthenticated(): boolean {
    return this.currentUserSubject.value != null;
  }

  login(username: string, password: string) {
    return this.http.post<Usuario>(`${environment.apiUrl}/login`, { Curp: username, Password: password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  loginEmpresa(username: string, password: string) {
    return this.http.post<Usuario>(`${environment.apiUrl}/LoginEmpresa`, { Usuario: username, Password: password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  loginEmpleado(username: string, password: string ) {
    return this.http.post<Usuario>(`${environment.apiUrl}/LoginEmpleado`, { Usuario: username, Password: password })    
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  // loginEmpleado(username: string) {
  //   return this.http.post<Usuario>(`${environment.apiUrl}/Login/${username}/UsuarioSession`, { Usuario: username })
  //     .pipe(map(user => {
  //       // store user details and jwt token in local storage to keep user logged in between page refreshes
  //       if(user == null || !user.IsActive || !user.IsEmpleado){
  //         this.currentUserSubject.next(null);
  //         return
  //       }
  //       localStorage.setItem('currentUser', JSON.stringify(user));
  //       this.currentUserSubject.next(user);
  //       return user;
  //     }));
  // }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  obtieneAspirante(): Promise<DatosUsuario> {
    var prospectoId = 1280;
    var promise =  this.http.get<DatosUsuario>(`${environment.apiUrl}/Aspirante/${prospectoId}`).toPromise();

    console.log("Prom: " + promise);    
    return promise
  }

  obtienePerfil(){
    var prospectoId = 1280;
    var promise =  this.http.get<DatosUsuario>(`${environment.apiUrl}/Aspirante/${prospectoId}`).toPromise();
    try{
      promise.then( perfil => {
        console.log("aspiratne ");
        console.log("aspiratne " + JSON.stringify(perfil));
        return 0
      }).catch( err => {
        console.log("Error" + err);
  
      }).finally(() => {
        console.log("Finally: ");    
      });
    }catch(e){
      console.log("Errrrr: " + e);
    } 
    return 1;
  }

  /*
  obtienePerfil(){//: Promise<Object> {
    var  GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
    var promise =   this.http.get(GRAPH_ENDPOINT).toPromise();
    console.log("Prom: " + promise); 
    console.log("typeOf: " + (typeof promise)); 
    try{
      promise.then( perfil => {
        console.log("aspiratne ");
        console.log("aspiratne " + JSON.stringify(perfil));
      }).catch( err => {
        console.log("Error" + err);
  
      }).finally(() => {
        console.log("Finally: ");    
      });
    }catch(e){
      console.log("Errrrr: " + e);
    } 
    console.log("Retornando: ");    
    //return promise
  }*/

}
