import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BecaOtorgada } from '@app/_models/becaOtorgada';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { MensajePropuestaComponent } from '../mensaje-propuesta/mensaje-propuesta.component';

@Component({
  selector: 'app-mensaje-correo',
  templateUrl: './mensaje-correo.component.html',
  styleUrls: ['./mensaje-correo.component.scss']
})
export class MensajeCorreoComponent implements OnInit {


  comment = false;

  editarMensaje = false;
  constructor(
    public dialogRef: MatDialogRef<MensajeCorreoComponent>,
    public dialogClose: MatDialogRef<MensajeCorreoComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: BecaOtorgada) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.editarMensaje = this.data.EditarComentarios;
    if (this.data.Comentarios){
      this.comment = true;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    if(this.data.Comentarios != ""){
      this.dialogClose.close();
    }
    else{this.data.Comentarios = "";}
    this.dialogClose.close();
  }  
}