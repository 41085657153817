<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">CARGA DE ARCHIVOS.</mat-card-title>
  </div>
  <br>
  <form  class="mdl-form" name="cargasForm" id="cargasForm" [formGroup]="cargasForm">  
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Tipo de carga</mat-label>
      <mat-select #mySelCampus [(value)]="selected" name="tipoConsulta"
      (openedChange)="onSelectCarga()"
       formControlName="tipoConsulta" id="tipoConsulta">
        <mat-option value="1"><label>Presupuestos</label></mat-option>  
        <mat-option value="2"><label>Carga alumnos propuesta</label></mat-option>
        <mat-option value="3"><label>Carga cuotas propuesta</label></mat-option>
        <mat-option value="4"><label>Becas especiales</label></mat-option>              
        <mat-option value="5"><label>Alumnos padres</label></mat-option>
        <mat-option value="6"><label>Alumnos promedio</label></mat-option>
        <mat-option value="7"><label>Alumnos morosos</label></mat-option>
        <mat-option value="8"><label>Becas actuales</label></mat-option>
        <mat-option value="9"><label>Solicitudes propuesta de beca</label></mat-option>
      </mat-select>
    </mat-form-field>  
    <br><br>        

    <div *ngIf="cargando" >
      <mat-progress-bar mode="indeterminate" ></mat-progress-bar>
      <br>
      <label class="color-marino fz-15">
        Porcentaje procesado:   {{porcentaje}}%
        </label>
      <br>
      <button   class="button" mat-raised-button (click)="cancelarCarga()">Cancelar</button>
    </div>

    <br><br>    
    
    <div *ngIf="ultimoCargado!=''  && !cargando" >
      <label class="color-marino fz-15">
        Último archivo procesado:   {{ultimoCargado}}
      </label>
      <button   class="button" mat-raised-button (click)="descargarUltimo()">
        <mat-icon>download</mat-icon>
        <span class='lato-bold espaciado margin-btn'>Descargar</span>
      </button>    
    </div>
    <br><br>  


    <mat-hint *ngIf="ErrorExt">Extensiones aceptadas: .xlsx</mat-hint>
    <div  *ngIf="!cargando" class="row">
      <button [disabled]="!selected" class="button" mat-raised-button 
      (click)="seleccionarArchivo()"><span class='lato-bold espaciado margin-btn'>+ CARGAR ARCHIVO</span></button>
      <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
      <button *ngIf="archivoCargado" class="buttonSecondary" mat-raised-button ><span class='lato-bold espaciado margin-btn' (click)="importarDocumento()" >+ IMPORTAR ARCHIVO</span></button>
      <input class="hidden" id="upload-file" type="file" accept=".xlsx" (change)="adjuntarArchivo($event)">            
    </div>
  </form>
  <br><br>
</mat-card>
  