import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleEmpresasComponent } from '../_components/detalle-empresas/detalle-empresas.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Empresa } from '@app/_models/empresa';
import { FormBuilder } from '@angular/forms';
import { EmpresaService } from '@app/_services/empresa.service';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-catalogo-empresas',
  templateUrl: './catalogo-empresas.component.html',
  styleUrls: ['./catalogo-empresas.component.scss']
})
export class CatalogoEmpresasComponent implements OnInit {

  displayedColumns: string[] = ['Nombre', 'Telefono', 'Correo', 'Contacto', 'Costo', 'Acciones'];
  dataSource = new MatTableDataSource<Empresa>();

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  
  filtro: string = "";
  buscando = false;  
  idUsuario : number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, 
    private authenticationService: AuthenticationService, 
    private empresaService: EmpresaService, private formBuilder: FormBuilder) { }

  agregarEmpresa(): void {
    const dialogRef = this.dialog.open(DetalleEmpresasComponent, {
      width: '900px',
      data: {Operation: 'NUEVO'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscarEmpresa();
    });
  }

  editarEmpresa(empresa:Empresa): void {
    const dialogRef = this.dialog.open(DetalleEmpresasComponent, {
      width: '900px',
      data: {Operation: 'EDITAR', Empresa: empresa}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result ){
        this.buscarEmpresa();
      }
    });
  }

  eliminarEmpresa(empresa:Empresa){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la empresa?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.empresaService.delete(empresa)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscarEmpresa();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
              });
            }            
          },
          error => {
            this.error = true;
            this.errorMessage = "Error al eliminar la empresa";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la Solicitud.', false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
            });
          });
      }
    });    
  }
  
  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.dataSource.paginator = this.paginator;
    this.buscarEmpresa();
  }

  
  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscarEmpresa();

    return event;
  }

  getEmpresa(nRegistros:number, nPagina:number) {
    this.loading = true;
    
    this.empresaService.get(nRegistros, nPagina, this.filtro, this.idUsuario).then( respuesta => {
      this.dataSource = new MatTableDataSource<Empresa>(respuesta.Dato);
      this.length = respuesta.Total;
      this.loading = false;
    } ).catch(err => {
      console.log("Error" + err);
    }); 
  }

  buscarEmpresa() {
    this.getEmpresa(this.pageSize, this.pageIndex + 1);
  }

  filterText(){
    this.pageIndex = 0;
    if(!this.buscando){
      this.buscando = true;
      setTimeout (() => {
        this.buscarEmpresa();
        this.buscando = false;
      }, 3000);   
    }
  }
}