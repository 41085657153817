import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Ciclo } from '@app/_models/ciclo';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';

@Injectable({ providedIn: 'root' })
export class EstatusSolicitudService {

    constructor(private http: HttpClient) {
    }

    consultarEstatusSolicitud():Promise<EstatusSolicitud[]>{
        return this.http.get<EstatusSolicitud[]>(`${environment.apiUrl}/EstatusSolicitud`).toPromise();

    }

    /*
    consultarEstatusSolicitudMayEnv():Promise<EstatusSolicitud[]>{
        return this.http.get<EstatusSolicitud[]>(`${environment.apiUrl}/EstatusSolicitudMayorEnv`).toPromise();

    }*/
    
    consultarEstatusSolicitudRevision():Promise<EstatusSolicitud[]>{
        return this.http.get<EstatusSolicitud[]>(`${environment.apiUrl}/EstatusSolicitudRevision`).toPromise();

    }

}