import { Component, OnInit, Inject } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SolicitudService } from '@app/_services/solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { EstudioSolicitudService } from '@app/_services/estudioSolicitud.service';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';
import { Empresa } from '@app/_models/empresa';
import { ContactoEmpresa } from '@app/_models/contactoEmpresa';
import { TelefonoEmpresa } from '@app/_models/telefonoEmpresa';
import { EstudioEmpresa } from '@app/_models/estudioEmpresa';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { Ciclo } from '@app/_models/ciclo';
import { CicloService } from '@app/_services/ciclo.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-estudio-socioeconomico',
  templateUrl: './estudio-socioeconomico.component.html',
  styleUrls: ['./estudio-socioeconomico.component.scss']
})
export class EstudioSocioeconomicoComponent implements OnInit {
  estudioForm: FormGroup;
  result: string = '';
  AlumnoID : number;
  Ciclo: Ciclo;
  
  fechaSolicitudFin: Date; 
  fechaExtemporanea = null;
  Solicitud : Solicitud;
  EstudioSolicitud : EstudioSolicitud;
  editorData: '<p>Hello, world!</p>'
  idUsuario : number;
  isAnahuac: boolean = false;
  hayEmpresa: boolean = false;
  guardando: boolean = false;

  public Editor = ClassicEditor;
  tipoBeca = '';

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private solicitudService : SolicitudService,
    private estudioSolicitud : EstudioSolicitudService,
    private seleccionBecaService : SeleccionBecaService,
    public datepipe: DatePipe,
    private cicloService : CicloService,
    private authenticationService: AuthenticationService,
    private router: Router) { 
      this.EstudioSolicitud = new EstudioSolicitud();
      this.EstudioSolicitud.EstudioEmpresa = new EstudioEmpresa();
      this.EstudioSolicitud.Empresa = new Empresa();
      this.EstudioSolicitud.Empresa.ContactoEmpresa = new ContactoEmpresa();
      this.EstudioSolicitud.Empresa.TelefonoEmpresa = new TelefonoEmpresa();
    }

  ngOnInit(): void {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.fechaSolicitudFin = this.Solicitud.FechaFin ? this.Solicitud.FechaFin : null;
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      this.becaExtemporanea();
      }).catch(err => {
      console.log("Error" + err);
    }); 

    this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{
      this.tipoBeca = Solicitud.TipoBeca.Nombre
    }).catch(err => {
      console.log("Error" + err);
    }); 

    this.estudioSolicitud.datosEmpresasSolicitud(this.Solicitud.SolicitudID).then(empresaSol =>{
      if(empresaSol != null){
        this.hayEmpresa = true;
        this.EstudioSolicitud = empresaSol;
        this.estudioForm.get('contenidoCorreo').setValue(this.EstudioSolicitud.ContenidoCorreo);              
      }
    }).catch(err => {
      this.hayEmpresa = false;
      console.log("Error" + err);
    });     

    this.estudioForm = this.formBuilder.group({
      contenidoCorreo:              [''],
    });   
  }

  enviar(){
    var contenidoCorreo = this.estudioForm.get('contenidoCorreo').value;
    console.log("Correo " + JSON.stringify(contenidoCorreo));
  }

  guardar(){
    this.save('/pantalla-principal', this.fechaExtemporanea ?  this.fechaExtemporanea : this.fechaSolicitudFin);
  }

  guardarContinuar(){
    this.save('/resumen-solicitud', null);
  }

  save(ruta : string, fechaFin: Date){
    
    if(!this.estudioForm.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return;
    }

    this.guardando = true;
    var contenidoCorreo =  this.estudioForm.get('contenidoCorreo').value;
    this.EstudioSolicitud.ContenidoCorreo = contenidoCorreo;
    console.log("Guardando estudio " + JSON.stringify(this.EstudioSolicitud));
    this.estudioSolicitud.guardarMensaje(this.EstudioSolicitud).then(respuesta =>{
      if (respuesta.Exito) {
        this.guardando = false;
        if(fechaFin){
          let latest_date = this.datepipe.transform(fechaFin, 'dd/MM/yyyy');
          var dialogData = new ConfirmDialogModel('', "Recuerda que debes completar la solicitud antes del día" + " " + latest_date + " " + "o tu solicitud será eliminada."  , false);
        }else{
          var dialogData = new ConfirmDialogModel('', "Información guardada", false); 
        }  
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        

        dialogRef.afterClosed().subscribe( () => {
          this.router.navigate([ruta]);          
        });
      }else{
        //this.error = true; 
        this.guardando = false; 
        console.log("Error " + JSON.stringify(respuesta))
        const dialogData = new ConfirmDialogModel('Error', respuesta.Mensaje, false);    
      }
    }).catch(err => {
      this.guardando = false;
      console.log("Error" + err);
    });  
  }

  enviarSolicitud( ): void {
    const message = `¿Está seguro de que quiere enviar la solicitud?`;
    const dialogData = new ConfirmDialogModel("Confirmar", message);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        this.Solicitud.UsuarioID = this.idUsuario;
        this.solicitudService.enviarSolictud(this.Solicitud).then( res => {
          if(res.Exito){

            const dialogData = new ConfirmDialogModel('', "Solicitud enviada", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });        
    
            dialogRef.afterClosed().subscribe( () => {
              this.router.navigate(['/pantalla-principal']);          
            });
                    
          }else{
            const dialogData = new ConfirmDialogModel('', "Hubo un error al enviar la solicitud", false);    
          }
        }).catch(err => {
          console.log("Error"+ err);
        });
      }
    });
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
    //const message = `¿Ocurrio un error al conectar con el servidor?`;

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  }  

  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if(e.FechaFin){
        this.fechaExtemporanea = e.FechaFin;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  } 
}