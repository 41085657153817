import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoBecaEspecificaciones } from '@app/_models/TipoBecaEspecificaciones';
import { TipoBecaReq } from '@app/_models/tipoBecaReq';
import { TipoBecaReqEspecificaciones } from '@app/_models/TipoBecaReqEspecificaciones';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EspecificacionBecaService {
  constructor(private http: HttpClient) {}


  
  consultaEspecificacionBeca( TipoBecaID : number, UsuarioID: number){
    return this.http.get<TipoBecaEspecificaciones[]>(`${environment.apiUrl}/EspecificacionBeca/${TipoBecaID}/${UsuarioID}/EspecificacionBeca`).toPromise();
  }   

  GuardarEspecificacionBeca( especificaciones: TipoBecaEspecificaciones[], UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/GuardarEspecificacionBeca`,   {especificaciones, UsuarioID }  );
  }


  consultaEspecificacionRequisitosBeca( TipoBecaID : number,  UsuarioID: number){
    return this.http.get<TipoBecaReqEspecificaciones[]>(`${environment.apiUrl}/EspecificacionBeca/${TipoBecaID}/${UsuarioID}/EspecificacionesRequisitos`).toPromise();
  }    

  GuardarEspecificacionBecaRequisitos( especificaciones: TipoBecaReqEspecificaciones[], UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/GuardarEspecificacionBecaRequisitos`,   {especificaciones, UsuarioID }  );
  }

  consultaRequsitoBeca( TipoBecaID : number,  CentroID : string, SeccionID : number, GradoID: number){
    return this.http.get<TipoBecaReq[]>(`${environment.apiUrl}/EspecificacionBeca/${TipoBecaID}/${CentroID}/${SeccionID}/${GradoID}/RequisitosTipoBeca`).toPromise();
  }    

  consultaRequsitosPorBeca( TipoBecaID : number){
    return this.http.get<TipoBecaReq[]>(`${environment.apiUrl}/EspecificacionBeca/${TipoBecaID}/RequisitosXBeca`).toPromise();
  }    
}