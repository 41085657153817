import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Ciclo } from '@app/_models/ciclo';
import { CicloCentro } from '@app/_models/cicloCentro';

@Injectable({ providedIn: 'root' })
export class CicloService {

    constructor(private http: HttpClient) {}

    consultaCiclo():Promise<Ciclo[]>{
        return this.http.get<Ciclo[]>(`${environment.apiUrl}/ciclo`).toPromise();
    }

    consultaCicloActivo(AlumnoID: number):Promise<Ciclo>{
        return this.http.get<Ciclo>(`${environment.apiUrl}/${AlumnoID}/GetCicloActivo`).toPromise();
    }

    consultaCicloAnterior(AlumnoID : number):Promise<Ciclo>{
        return this.http.get<Ciclo>(`${environment.apiUrl}/${AlumnoID}/GetCicloAnterior`).toPromise();
    }    

    consultaCicloSiguiente(AlumnoID : number):Promise<Ciclo>{
        return this.http.get<Ciclo>(`${environment.apiUrl}/${AlumnoID}/GetCicloSiguiente`).toPromise();
    }   

    GuardarCicloCentros( cicloCentros: CicloCentro[], UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/GuardarCicloCentros`,   {cicloCentros, UsuarioID }  ).toPromise();
      }
    
      consultaCicloCentros( CicloID : number,  UsuarioID : number){
        return this.http.get<CicloCentro[]>(`${environment.apiUrl}/Ciclo/${CicloID}/${UsuarioID}/CiclosCentros`).toPromise();
      }    

}