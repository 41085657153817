<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste-claro">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/seleccion-beca" class="circulo bg-celeste">
                        <mat-icon routerLink="/seleccion-beca" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-generales" class="circulo bg-rosado">
                        <mat-icon routerLink="/datos-generales" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-familiares" class="circulo bg-verde">
                        <mat-icon routerLink="/datos-familiares" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-azul">
                        <h2>4</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>5</h2>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <br><br>
    <mat-card-title class="card-title fz-40">Requisitos</mat-card-title>
    <br>
    <div class="example-form">
        <p class="text-center" *ngIf="requiereDoc">Anexe los siguientes documentos que son requeridos para continuar con el proceso de solicitud de beca.</p>
        <p class="text-center" *ngIf="!requiereDoc">La solicitud de beca no requiere carga de documentos puede continuar con el proceso.</p>
        <div class="row text-center">              
            <mat-label class="color-marino">Tipo Beca: {{tipoBeca}}</mat-label>
        </div>
        <br><br><br>
        <div *ngFor="let documento of documentos">
            <table > 
                <tr>
                  <td style="width: 40%;" >
                    <span class="lato-bold" style="font-size: 20px; color:#082C46"> <img src="/assets/Carga-documentos/ALT_Icono_Carga-Docs.png"/> &nbsp;  &nbsp; {{documento.RequisitoBeca.Nombre}}</span>
                    <br>
                    <div class="lato-bold-italic">&nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp;  &nbsp; ( {{documento.RequisitoBeca.Extensiones}} )</div>
                  </td>
                  <td style="width: 20%;">
                    <button class="{{ documento.BotonDescripcion=='Seleccionar' ? 'btn-seleccionar' : 'btn-modificar'}}"  mat-raised-button 
                      (click)="seleccionarArchivo(documento.RequisitoBeca.Clave)">
                      <span class='lato-bold espaciado margin-btn' style="font-size: 90%;">{{documento.BotonDescripcion | uppercase }}</span>
                    </button>   
                  </td>
                  <td style="width: 40%;">
                      <span class="lato-regular" style="color:#8B99A6"> &nbsp; {{documento.EstatusCarga_}}</span>  
                  </td>
                  <td> <input class="hidden" type="file" id="{{documento.RequisitoBeca.Clave}}" name="{{documento.RequisitoBeca.Clave}}" accept="{{documento.RequisitoBeca.Extensiones}}" (change)="adjuntarArchivo($event, documento.RequisitoBeca.Clave)"> </td>
                </tr>
              </table>
        </div>
    </div>
    <br><br><br><br><br><br>
    <div class="btn-div margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" 
            style="margin-right: 1rem;"  matTooltip="Guardar y volver al menú principal" (click)="guardar()" mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" [disabled]="!archivoCargado || guardando"
            (click)="importarDocumentos('continuar')" routerLinkActive="router-link-active"  mat-raised-button>SIGUIENTE</button>
    </div>
</mat-card>