<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">REVISIÓN DE PROPUESTAS DE BECAS</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br>
  <div class="mdl-form">
      <mat-label class="color-azul">Filtrar por:</mat-label>
  </div>
  <form class="mdl-form" name="revisionBeca" id="revisionBeca" [formGroup]="revisionBeca">      

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Ciclos</mat-label>
      <mat-select #mySelCiclo name="ciclos" formControlName="ciclos" id="ciclos">
        <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
            {{ciclo.Nombre}}
        </mat-option> 
      </mat-select>
    </mat-form-field>

    <mat-form-field class="inpunt-width xs-width"  appearance="outline">
      <mat-label>Regiones</mat-label>
      <mat-select #mySelRegion name="ciclos" formControlName="regiones" id="regiones" (openedChange)="onSelectedRegiones($event)" multiple>
        <mat-option #allSelectedRegiones (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
          (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
          {{region.Nombre}}
        </mat-option>
      </mat-select>   
    </mat-form-field>    

    <mat-form-field  class="input-centro-width xs-width" appearance="outline">
      <mat-label>Colegios</mat-label>
      <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
          <mat-option #allSelectedCentros (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
          <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
          (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
          {{centro.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Estatus</mat-label>
        <mat-select #mySelEstatus name="estatus"  formControlName="estatus" id="estatus" multiple>
          <mat-option #allSelectedEstatus (click)="toggleAllSelectionEstatus()" [value]="'0'"><label>Todos</label></mat-option>
          <mat-option *ngFor="let estatus of estatusBecas" [value]="estatus.EstatusPropuestaID"
          (click)="tosslePerOneEstatus(allSelectedEstatus.viewValue)" > 
            {{estatus.Nombre}}
          </mat-option> 
        </mat-select>
    </mat-form-field> 

    <div>
      <button mat-raised-button (click)="buscar()" class="button"> BUSCAR </button>
    </div>
  </form>
  <br><br>
  <div class="mdl-form mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>
      
        <ng-container matColumnDef="Ciclo">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Ciclo </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Ciclo"> 
            {{element.Ciclo.Nombre}}
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="Centro">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Colegio </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="Centro">
            {{element.Centro.Nombre}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="MontoAprobado">
            <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Monto Presupuestal autorizado </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="MontoAprobado">
              {{element.MontoAprobado | currency:'MXN'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="costo">
          <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Costo de beca </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="costo">
            {{element.CostoBecas | currency:'MXN'}}
          </mat-cell>
        </ng-container>

        
        <ng-container matColumnDef="porcentaje">
          <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Porcentaje consumido </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="porcentaje">
            {{element.PorcentajeConsumido | number  : '1.2-2'}}%            
          </mat-cell>
        </ng-container>        

        <ng-container matColumnDef="Estatus">
            <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Estatus </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="Estatus">
              {{element.EstatusPropuesta.Nombre}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
              <button mat-icon-button color="basic" aria-label="Ver propuesta de beca" (click)="verSolicitud(element)" matTooltip="Ver propuesta de beca"  matTooltipPosition="right">
                  <mat-icon>visibility</mat-icon></button>
              <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element)" matTooltip="Historial">
                  <mat-icon>history</mat-icon></button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      </mat-table>      
      
      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
      </div>

      <mat-paginator #paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="pageEvent = getServerData($event)"
      >
      </mat-paginator>
  </div>   
  <br><br>     
</mat-card>