<mat-card class="realiza-pago"> 
    <mat-card-content class="card-cont">
      <br>
      <br>
      <mat-card-title class="card-title fz-30">
        Bienvenidos al portal de becas.
      </mat-card-title>
      <p class="card-title fz-24">Para solicitar una beca es necesario que realice los siguientes pasos:</p>
      <br><br>
      <mat-grid-list [cols]="breakpoint" rowHeight="25px" (window:resize)="onResize($event)">
        <mat-grid-tile colspan="1" rowspan="8">
          <mat-card class="mat-card-fx colorCeleste" >
            <mat-card-content class="card-table">
                <br><br> 
                <div class="circulo">
                    <h2 class="helv-med fz-30">1</h2>
                </div>
                <br><br>
                <div class="text-center">
                    <label class="text-center color-blanco fz-24">
                      Selecciona el tipo de beca.
                    </label> 
                </div>
            </mat-card-content>
        </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="8">
          <mat-card class="mat-card-fx colorMorado">
            <mat-card-content class="card-table">
                <br><br> 
                <div class="circulo">
                    <h2 class="helv-med fz-30">2</h2>
                  </div>
                <br><br>
                <div class="text-center">
                    <label class="text-center color-blanco fz-24">
                      Revisión de datos generales.
                    </label> 
                </div>
            </mat-card-content>
        </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="8">
          <mat-card class="mat-card-fx colorVerde">
            <mat-card-content class="card-table">
                <br><br> 
                <div class="circulo">
                    <h2 class="helv-med fz-30">3</h2>
                  </div>
                <br><br>
                <div class="text-center">
                    <label class="text-center color-blanco fz-24">
                      Información familiar.
                    </label> 
                </div>
            </mat-card-content>
        </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="8">
          <mat-card class="mat-card-fx colorAzul">
            <mat-card-content class="card-table">
                <br><br> 
                <div class="circulo">
                    <h2 class="helv-med fz-30">4</h2>
                  </div>
                <br><br>
                <div class="text-center">
                    <label class="text-center color-blanco fz-24">
                      Requisitos.
                    </label> 
                </div>
            </mat-card-content>
          </mat-card>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="8">
          <mat-card class="mat-card-fx colorNaranja">
            <mat-card-content class="card-table">
                <br><br> 
                <div class="circulo">
                    <h2 class="helv-med fz-30">5</h2>
                  </div>
                <br><br>
                <div class="text-center">
                    <label class="text-center color-blanco fz-24" style="margin-right: 3rem;">
                      Estudio Socioeconómico.
                    </label> 
                </div>
            </mat-card-content>
        </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
      <br><br><br>
      <div class="text-center">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }"
        (click)="descargarReglamento()" [disabled]="!permitirSol||loading" mat-raised-button>SOLICITAR BECA</button>
      </div>
      <br><br>
      <table *ngIf="!permitirSol" class="obj-center">
          <tr>
              <td style="width: 3rem;">
                  <img style="width: 50%;" src="/assets/warning.png" class="logo-universidad" />
              </td>
              <td>
                  <p class="fz-19 color-marino">{{mensajePermitirSol}}</p>
              </td>
          </tr>
      </table>
      <br>
        <mat-table  [dataSource]="dataSource" > 
          <ng-container matColumnDef="Ciclo">
            <mat-header-cell *matHeaderCellDef class="ciclo-header"> Ciclo </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Ciclo" class="ciclo-content"> {{element.Ciclo.Clave}} </mat-cell>
          </ng-container>
  
  
          <ng-container matColumnDef="Curp">
            <mat-header-cell *matHeaderCellDef class="curp-header"> Curp </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Curp" class="curp-content"> {{element.Alumno.CURP}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Nombre">
            <mat-header-cell *matHeaderCellDef class="nombre-header"> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Nombre" class="nombre_content"> {{element.Alumno.Nombre}}  {{element.Alumno.ApellidoPaterno}} {{element.Alumno.ApellidoMaterno}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Centro">
            <mat-header-cell *matHeaderCellDef class="centro-header"> Centro </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Matricula" class="centro-content"> {{ element.Centro != null ?  element.Centro.Nombre :''}} </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Seccion" >
            <mat-header-cell *matHeaderCellDef class="seccion-header"> Sección </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Matricula" class="seccion-header"> {{ element.Seccion != null ? element.Seccion.Nombre : ''}} </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="Grado">
              <mat-header-cell *matHeaderCellDef class="grado-header"> Grado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Matricula" class="grado-header"> {{  element.GradoEducativo != null ?element.GradoEducativo.Nombre : ''}} </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="Estatus">
              <mat-header-cell *matHeaderCellDef class="estatus-header"> Estatus </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Estatus" class="estatus-header">  {{element.EstatusSolicitud.Nombre}} </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="Opciones">
              <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Estatus"> 
                <button mat-icon-button color="basic" aria-label="Editar" [disabled]="!element.SolicitudEditable" matTooltip="Editar" (click)="editarSolicitud(element)" matTooltipPosition="right">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="basic" aria-label="Eliminar" [disabled]="!element.PermitirBorrado" matTooltip="Eliminar" (click)="eliminarSolicitud(element)" matTooltipPosition="right">
                  <mat-icon>delete</mat-icon>
                </button>
                <button mat-icon-button color="basic" aria-label="Descargar documentos" [disabled]="element.PermitirBorrado" matTooltip="Descargar solicitud" (click)="descargar(element)"   matTooltipPosition="right">
                  <mat-icon>cloud_download</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
        
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table> 
  
        <div *ngIf="length == 0" class="text-center margin-top">
          <span class="color-obscuro">No hay registros para mostrar.</span>
        </div>
  
        <div class="loading-screen-icon" *ngIf="loading">
          <mat-spinner></mat-spinner>
        </div>

      </mat-card-content>
  </mat-card>