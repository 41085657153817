<br>
<mat-card-title style="color: #3970B5;" class="rob-17 text-center">
  Agregar hijo
</mat-card-title>
<br>
<mat-divider></mat-divider>
<form class="example-container" name="detalleHermanoForm" id="detalleHermanoForm" [formGroup]="detalleHermanoForm">

  <div class="row">
    <mat-form-field class="inpunt-width xs-width" appearance="fill">
      <mat-label>CURP:</mat-label>
      <input matInput formControlName="curp" (ngModelChange)="validarCurp()" required>
      <mat-hint *ngIf="errorCurp">El CURP tiene un formato inválido</mat-hint>
    </mat-form-field>
  </div>
  <br>

  <div class="row">
    <mat-form-field class="inpunt-width xs-width" appearance="fill">
        <mat-label>Nombre:</mat-label>
        <input matInput formControlName="nombre" formControlName="nombre" (keypress)="name_omit_special($event)" required>
    </mat-form-field> 

    <mat-form-field class="inpunt-width xs-width" appearance="fill">
        <mat-label>Apellido paterno:</mat-label>
        <input matInput formControlName="apellidoPaterno" formControlName="apellidoPaterno" (keypress)="name_omit_special($event)" required>
    </mat-form-field> 
    
    <mat-form-field class="inpunt-width xs-width" appearance="fill">
        <mat-label>Apellido materno:</mat-label>
        <input matInput formControlName="apellidoMaterno" (keypress)="name_omit_special($event)">
    </mat-form-field>
  </div>
  <br>

  <div class="row">
    <mat-form-field  class="inpunt-width xs-width" appearance="fill">
      <mat-label>Sección:</mat-label>
      <mat-select placeholder="Seccion" formControlName="seccion" [compareWith]="compareSecction"  
        (ngModelChange)='verify()'  (openedChange)="onSelectSeccion($event)" required >
        <mat-option *ngFor="let c of secciones" [value]="c">
          {{c.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field  class="inpunt-width xs-width" appearance="fill">
      <mat-label>Centro:</mat-label>
      <mat-select placeholder="Centro" formControlName="centro" (ngModelChange)='verify()' [compareWith]="compareCentro"  
          (openedChange)="onSelectCentro($event)" required [disabled]="centros.length == 0">
        <mat-option *ngFor="let c of centros" [value]="c">
          {{c.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field  class="inpunt-width xs-width" appearance="fill">
      <mat-label>Grado:</mat-label>
      <mat-select placeholder="Grado" formControlName="grado" (ngModelChange)='verify()'
        [compareWith]="compareGrado" [disabled]="grados.length == 0">
        <mat-option *ngFor="let c of grados" [value]="c">
          {{c.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br>

  <table>
    <tr>
      <td>
        <div class="registro-full-with" style="margin-right: 2rem;">
          <p class="color-azul">Cuenta con beca</p>
          <br>
          <mat-radio-group (ngModelChange)='verify()' aria-label="Seleccione una opcion" required formControlName="tieneBeca">
            <mat-radio-button style="margin-right: 1rem;" value="1"><label>Si</label></mat-radio-button>
            <mat-radio-button value="0"><label>No</label></mat-radio-button>
          </mat-radio-group>
        </div>
      </td>
      <td>
        <mat-form-field *ngIf="detalleHermanoForm.controls.tieneBeca.value == 1" style="margin-top: 3rem;" class="inpunt-width xs-width" appearance="fill">
          <mat-label>Porcentaje:</mat-label>
          <input matInput requiered formControlName="porcentaje" maxlength="3" (ngModelChange)="validarPorcentaje()"
            (keypress)="omit_special_mont($event)" onPaste="return false" autocomplete="off">
          <mat-hint *ngIf="porcentajeErr">El porcentaje debe ser un número válido menor a 100</mat-hint>
        </mat-form-field>
      </td>
    </tr>
  </table>
</form>

<br><br><br>
<div class="example-form margin-div">
  <button class="button" (click)="agregar()" [disabled]="!enableBtn || errorCurp || 
  detalleHermanoForm.controls.tieneBeca.value == 1 && porcentajeErr" mat-raised-button>GUARDAR</button>
  <button class="buttonSecondary margin-left" (click)="cancelar()"  mat-raised-button matTooltip="Cancelar registro">CANCELAR</button>
</div>