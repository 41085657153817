import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Centro } from '@app/_models/centro';
import { Solicitud } from '@app/_models/solicitud';
import { Observable } from 'rxjs';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class ExpedientesAsignadosService {

  constructor(private http: HttpClient) {
  }

  consultaCentrosEmpresa( idUsuario: number):Promise<Centro[]>{
    return this.http.get<Centro[]>(`${environment.apiUrl}/ExpedientesAsignados/${idUsuario}/GetCentroXEmpresa`).toPromise();    
  }    
  
  consultarSolicitudesAsignadas(nRegistros:number, nPagina:number, idsCentros : string[], idsEstatus : number[],  idUsuario: number):Promise<any>{
    return this.http.post<any>(`${environment.apiUrl}/ConsultarExpedientesAsignados`, {nRegistros, nPagina,idsCentros, idsEstatus, idUsuario}).toPromise();    
  }    

  iniciarProceso(SolicitudID : number, UsuarioID : number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/ExpedientesAsignados/${SolicitudID}/${UsuarioID}/IniciarProceso`, {});
  }

  obtieneFicha(SolicitudID : number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/ExpedientesAsignados/${SolicitudID}/ObtieneFicha`, {});
  }
}