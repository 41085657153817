import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_shared/auth.guard';
import { AdministracionComponent } from './administracion/administracion.component';
import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { RecuperaCuentaComponent } from './recupera-cuenta/recupera-cuenta.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { CambiaContrasenaValidarComponent } from './cambia-contrasena-validar/cambia-contrasena-validar.component';
import { RegistroComponent } from './registro/registro.component';
import { ReferenciaComponent } from './referencia/referencia.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { ActivarComponent} from './activar/activar.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { RegistroEsperaComponent} from './registro-espera/registro-espera.component';
import { RecuperaCuentaEsperaComponent} from './recupera-cuenta-espera/recupera-cuenta-espera.component';
import { PantallaPrincipalComponent } from './pantalla-principal/pantalla-principal.component';
import { AdministracionUsuariosComponent } from './administracion-usuarios/administracion-usuarios.component';
import { DescargaDocumentosComponent } from './descarga-documentos/descarga-documentos.component';
import { ProspectosAvisoComponent } from './prospectos-aviso/prospectos-aviso.component';
import { ContactoComponent}  from './contacto/contacto.component';
import { RevisionSolicitudesComponent } from './revision-solicitudes/revision-solicitudes.component';
import { RevisionRequisitosComponent } from './revision-requisitos/revision-requisitos.component';
import { AsignacionPresupuestoComponent } from './asignacion-presupuesto/asignacion-presupuesto.component';
import { PropuestasBecasComponent } from './propuestas-becas/propuestas-becas.component';
import { EstudioSocioeconomicoComponent } from './estudio-socioeconomico/estudio-socioeconomico.component';
import { CatalogoEmpresasComponent } from './catalogo-empresas/catalogo-empresas.component';
import { AperturaCiclosComponent } from './apertura-ciclos/apertura-ciclos.component';
import { InformacionBecasComponent } from './informacion-becas/informacion-becas.component';
import { ProfileComponent } from './profile/profile.component';
import { SeleccionBecaComponent } from './seleccion-beca/seleccion-beca.component';
import { DatosGeneralesComponent } from './datos-generales/datos-generales.component';
import { DatosFamiliaresComponent } from './datos-familiares/datos-familiares.component';
import { RequisitosComponent } from './requisitos/requisitos.component';
import { ConclusionBecaComponent } from './conclusion-beca/conclusion-beca.component';
import { ExpedientesAsignadosComponent } from './expedientes-asignados/expedientes-asignados.component';
import { AltaEmpresaComponent } from './alta-empresa/alta-empresa.component';
import { ContrasenaEmpresasComponent } from './contrasena-empresas/contrasena-empresas.component';
import { AdmEstudiosocioeconomicoComponent } from './adm-estudiosocioeconomico/adm-estudiosocioeconomico.component';
import { RevisionEstudioSocioeconomicoComponent } from './revision-estudio-socioeconomico/revision-estudio-socioeconomico.component';
import { AdmUsuariosEmpresasComponent } from './adm-usuarios-empresas/adm-usuarios-empresas.component';
import { RevisionPropuestaBecasComponent } from './revision-propuesta-becas/revision-propuesta-becas.component';
import { MsalGuard } from '@azure/msal-angular';
import { ReportesComponent } from './reportes/reportes.component';
import { AdmSobrepasosComponent } from './adm-sobrepasos/adm-sobrepasos.component';
import { ResumenBecasComponent } from './resumen-becas/resumen-becas.component';
import { ResumenSolicitudComponent } from './resumen-solicitud/resumen-solicitud.component';
import { RecuperaCuentaEmpresaComponent } from './recupera-cuenta-empresa/recupera-cuenta-empresa.component';
import { ModificacionBecasComponent } from './modificacion-becas/modificacion-becas.component';
import { RevisionModificacionBecasComponent } from './revision-modificacion-becas/revision-modificacion-becas.component';
import { BecasExtemporaneasComponent } from './becas-extemporaneas/becas-extemporaneas.component';
import { BecasExternasComponent } from './becas-externas/becas-externas.component';
import { CartaBecaComponent } from './carta-beca/carta-beca.component';
import { CargasComponent } from './cargas/cargas.component';
import { RenovacionBecaComponent } from './renovacion-beca/renovacion-beca.component';
import { RecuperaCuentaEmpleadoComponent } from './recupera-cuenta-empleado/recupera-cuenta-empleado.component';
import { ContrasenaEmpleadoComponent } from './contrasena-empleado/contrasena-empleado.component';

const routes: Routes = [{ path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'pantalla-principal', component: PantallaPrincipalComponent, canActivate: [AuthGuard], data: { roles: ["PROSPECTO"] } },
  { path: 'inicio', component: InicioComponent, canActivate: [AuthGuard] },
  { path: 'administracion', component: AdministracionComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'descarga-documentos', component: DescargaDocumentosComponent, canActivate: [AuthGuard], data: { roles: ["ESTUDIOSOC", "ADMIN"] }  },
  { path: 'administracion-usuarios', component: AdministracionUsuariosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] } },
  { path: 'administracion-prospectos', component: ProspectosAvisoComponent, canActivate: [AuthGuard], data: { roles: ["CARGOS"] } },
  { path: 'referencia', component: ReferenciaComponent },
  { path: 'ayuda', component: AyudaComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'registro-espera/:curp', component: RegistroEsperaComponent },
  { path: 'recupera-cuenta', component: RecuperaCuentaComponent },
  { path: 'recupera-cuenta-espera/:correo', component: RecuperaCuentaEsperaComponent },
  { path: 'cambia-contrasena', component: CambiaContrasenaComponent },
  { path: 'cambia-contrasena-validar', component: CambiaContrasenaValidarComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:anahuac', component: LoginComponent },
  { path: 'activar', component: ActivarComponent },
  { path: 'logout', component: SignOutComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'revision-solicitudes', component: RevisionSolicitudesComponent, canActivate: [AuthGuard], data: { roles: ["REVISOL", "ADMIN"] }   },
  { path: 'revision-requisitos', component: RevisionRequisitosComponent, canActivate: [AuthGuard], data: { roles: ["REVIREQ", "ADMIN"] }   },
  { path: 'asignacion-presupuesto', component: AsignacionPresupuestoComponent, canActivate: [AuthGuard], data: { roles: ["ASIGPRESU", "ADMIN"] }  },
  { path: 'propuestas-becas', component: PropuestasBecasComponent, canActivate: [AuthGuard], data: { roles: ["PROPBECA", "ADMIN"] } },
  { path: 'estudio-socioeconomico', component: EstudioSocioeconomicoComponent, canActivate: [AuthGuard] },
  { path: 'catalogo-empresas', component: CatalogoEmpresasComponent, canActivate: [AuthGuard], data: { roles: ["CATEMPR", "ADMIN"] }   },
  { path: 'apertura-ciclos', component: AperturaCiclosComponent, canActivate: [AuthGuard], data: { roles: ["CONFBECAS", "APERCICLO", "ADMIN"] }   },
  { path: 'informacion-becas', component: InformacionBecasComponent },
  { path: 'cargas', component: CargasComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CARGAS"] }   },
  { path: 'profile', component: ProfileComponent,  canActivate: [MsalGuard]},
  { path: 'seleccion-beca', component: SeleccionBecaComponent, canActivate: [AuthGuard] },
  { path: 'datos-generales', component: DatosGeneralesComponent, canActivate: [AuthGuard] },
  { path: 'datos-familiares', component: DatosFamiliaresComponent, canActivate: [AuthGuard] },
  { path: 'requisitos', component: RequisitosComponent, canActivate: [AuthGuard] },
  { path: 'conclusion-beca', component: ConclusionBecaComponent, canActivate: [AuthGuard]  },
  { path: 'expedientes-asignados', component: ExpedientesAsignadosComponent, canActivate: [AuthGuard], data: { roles: ["EMPRESA", "ADMIN"] }   },
  { path: 'alta-empresa', component: AltaEmpresaComponent },
  { path: 'recupera-cuenta-empresa', component: RecuperaCuentaEmpresaComponent },
  { path: 'recupera-cuenta-empleado', component: RecuperaCuentaEmpleadoComponent },
  { path: 'contrasena-empresas', component: ContrasenaEmpresasComponent },
  { path: 'contrasena-empleado', component: ContrasenaEmpleadoComponent },
  { path: 'adm-estudiosocioeconomico', component: AdmEstudiosocioeconomicoComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] }   },
  { path: 'revision-estudio-socioeconomico', component: RevisionEstudioSocioeconomicoComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN"] }   },
  { path: 'adm-usuarios-empresas', component: AdmUsuariosEmpresasComponent, canActivate: [AuthGuard]  },
  { path: 'revision-propuesta-becas', component: RevisionPropuestaBecasComponent, canActivate: [AuthGuard], data: { roles: ["REVPROPBECA", "APROPROPBECA", "ADMIN"] }  },
  { path: 'adm-sobrepasos', component: AdmSobrepasosComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN","ADMSOBREPASOS"] }  },
  { path: 'resumen-becas', component: ResumenBecasComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CONSOLIDADO"] }  },
  { path: 'resumen-solicitud', component: ResumenSolicitudComponent, canActivate: [AuthGuard] },
  { path: 'reportes', component: ReportesComponent, canActivate: [AuthGuard], data: { roles: ["REPORSOL", "REPORPRESU", "ADMIN"] }     },
  { path: 'modificacion-becas', component: ModificacionBecasComponent, canActivate: [AuthGuard], data: { roles: ["MODBECAS","ADMIN"] }  },
  { path: 'revision-modificacion-becas', component: RevisionModificacionBecasComponent, canActivate: [AuthGuard], data: { roles: ["REVMODBECAS","ADMIN"] }  },
  { path: 'becas-extemporaneas', component: BecasExtemporaneasComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "BECAEXTEMPORANEA"] }  },
  { path: 'carta-beca', component: CartaBecaComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "CARTABECA"] }  },
  { path: 'becas-externas', component: BecasExternasComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "ASIGBEEXT"] }  },
  { path: 'renovacion-becas', component: RenovacionBecaComponent, canActivate: [AuthGuard], data: { roles: ["ADMIN", "RENOVACION"] }  },
  { path: 'recupera-cuenta-empleado', component: RecuperaCuentaEmpleadoComponent },];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }