import { Component, OnInit } from '@angular/core';
import { HttpClient, JsonpInterceptor, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthError } from 'msal';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile;

  constructor(private authService: MsalService,
     private http: HttpClient,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    console.log("On init profile");
    this.getProfile();
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT )
    .subscribe({
      next: (profile) => {
        this.profile = profile;
        console.log("profile" + JSON.stringify(this.profile));
      },
      error: (err: AuthError) => {
        console.log("Error AuthError", JSON.stringify(err) );

        // If there is an interaction required error,
        // call one of the interactive methods and then make the request again.
        if (InteractionRequiredAuthError.isInteractionRequiredError(err.errorCode)) {
          console.log("Error adquiriendo token" );
          this.authService.acquireTokenPopup({
            scopes: this.authService.getScopesForEndpoint(GRAPH_ENDPOINT)
          })
          .then(() => {
            this.http.get(GRAPH_ENDPOINT)
              .toPromise()
              .then(profile => {
                this.profile = profile;
                console.log("Profile 2");
              });
          });
        }
      }
    });
  }
  
}
