import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service';
import { NavigationService } from '@app/_services/navigation.service';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { AuthError, InteractionRequiredAuthError } from 'msal';
import { FooterComponent } from '../footer/footer.component';
import { Redes } from '@app/_models/redes';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
 
  @Output() toggleSidenav = new EventEmitter<void>();

  private returnUrl = '/';
  private _isAuthenticated = false;
  public isProspect: boolean = false;
  public isAdmin: boolean = false;
  public isEmpresa: boolean = false;
  public isAnahuac: boolean = false;
  public isEmpleado : boolean = false;
  titlePortal = ''
  clase = 'toolbar-hg'
  footer:FooterComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    private authService: MsalService
  ) {
    
    let anahuac = localStorage.getItem('usrAhc');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        if(this.returnUrl == "/login/anahuac" || anahuac == "true") this.isAnahuac = true;
      }
    });

    this.navigationService.isMobile.subscribe(data => {
      this.isMobile = data;
      return;
    });

    this.authenticationService.currentUser.subscribe(user => {
      this.isAdmin = false;
      this.isEmpresa = false;

      if (user != null) { 
        if(user.IsProspecto){
          this.isProspect = true;
        }
        if(user.IsAdmin){
          this.isAdmin = true;
          this.titlePortal = 'Adminstración'
          this.clase = 'toolbar-row'
        }
        if(user.IsEmpresa){
          this.isEmpresa = true;
          this.titlePortal = 'Empresas'
          this.clase = 'toolbar-row'
        }
        if(user.IsEmpleado){
          this.isEmpleado = true;
          this.titlePortal = 'Colaborador'
          this.clase = 'toolbar-row'
        }
      }
    })
  };

  public isMobile: boolean = false;

  public get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated;
  }

  public toggleMenu() {
    this.navigationService.isToggledMenu.next(!this.navigationService.isToggledMenu.value);
  }

  public onProfile() {
    this.router.navigate(['/perfil']);
  }

  public isLogin() {
    console.log(this.route);
    return this.route.component == "LoginComponent";
  }

  public logout() {
    if(this.isEmpleado){
      var loggedIn = !!this.authService.getAccount();
      if(loggedIn){
        this.authService.logout();
      }
    }
    if(this.footer != null){
      this.footer.redes =    new Redes();

      this.footer.redes.Correo = "info@rcsa.mx";
      this.footer.redes.Telefono = "8159500160";
      this.footer.redes.Facebook = "https://www.facebook.com/semperaltius";
      this.footer.redes.Youtube = "https://www.youtube.com/channel/UCKlJZHTHptnvtjYvIAtARFQ";
      this.footer.redes.Linkedin = "https://www.linkedin.com/authwall?trk=gf&trkInfo=AQG3RhCrEhI3ZAAAAXL4SLzAtiAEfBxLAqDKHf0dX-i5wd-FBEz24UJicQp0CnFy8OAfo5MBp0ZYYX3_u5iTQbmIy1N31cE4R9kXW4E1tI24JKiPTq7NZkHLEJHLboDW6vX411Q=&originalReferer=https://www.semperaltius.edu.mx/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fred-de-colegios-semper-altius";
      this.footer.redes.Pinterest = "https://www.pinterest.com/semperaltius/";
      this.footer.redes.Instagram = "https://www.instagram.com/redsemperaltius/";
    }

    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit() {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
  }


  setFooter(footer : FooterComponent){
    this.footer = footer;
  }
  


}
