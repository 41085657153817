<mat-card class="seleccion-beca-card"> 
    <div class="example-form row">
      <mat-card-title class="text-left fz-26">RECUPERA CUENTA DE EMPLEADO.</mat-card-title>
      <mat-divider></mat-divider>
    </div>
    <br>
    <div class="principal1">
      <div class="seleccion-beca-card">
        <br><br>
        <form class="registro-form" name="recuperarCuentaForm" id="recuperarCuentaForm" [formGroup]="recuperarCuentaForm">
          <mat-form-field class="recupera-full-width" appearance="fill">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" [(ngModel)]='correo' required>                
          </mat-form-field> 
          <br><br>
          <mat-form-field class="recupera-full-width" appearance="fill">
            <mat-label>Número de empleado*</mat-label>
            <input matInput  (keypress)="numberOnly($event)"  maxlength="10" 
            [(ngModel)]='numeroEmpleado' formControlName="numeroEmpleado" id="numeroEmpleado" name="numeroEmpleado">    
          </mat-form-field>


          <br><br>
          <div class="text-center">
            <button class="buttonSecondary" mat-raised-button routerLink="/pantalla-principal" matTooltip="Volver al menú principal">CANCELAR</button>
            <button class="button" mat-raised-button (click)="onSubmit()" [disabled]="!enableBtn">CONTINUAR</button>
          </div>
        </form>
      </div>
    </div>  
</mat-card>