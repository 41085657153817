<mat-card class="seleccion-beca-card">
    <br>
    <div class="example-form">
      <mat-card-title class="text-left fz-26">REGISTRO DE BECAS EXTERNAS.</mat-card-title>
      <mat-divider></mat-divider>
    </div>
    <div class="row" class="example-form">
      <button mat-raised-button class="button" (click)="nuevaBeca()" >+ NUEVO</button>
    </div>
    <br><br>
    <div class="example-form">
      <mat-label class="color-azul">Filtrar por:</mat-label>
    </div>
    <form  class="example-form" name="revisionSolicitudForm" id="revisionSolicitudForm" [formGroup]="revisionSolicitudForm">      
    
      <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Ciclos</mat-label>
        <mat-select #mySelCiclo name="ciclos" formControlName="ciclos" id="ciclos">
          <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID"> 
              {{ciclo.Nombre}}
          </mat-option> 
        </mat-select>
      </mat-form-field>   
  
      <mat-form-field class="input-centro-width xs-width" appearance="outline">
        <mat-label>Colegios</mat-label>
        <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
          <mat-option #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
          <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
            (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
            {{centro.Nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Secciones</mat-label>
        <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones" (openedChange)="onSelectedSecciones($event)" multiple>
            <mat-option #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
            <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
              (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
              {{seccion.Nombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>   
  
      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Tipo Beca</mat-label>
        <mat-select formControlName="tipobeca">
          <mat-option *ngFor="let c of tiposBeca" [value]="c.TipoBecaID">
            {{c.Nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Alumno</mat-label>
        <input matInput placeholder="Nombre o curp del alumno" value="" id="nombre"
          name="nombre" formControlName="nombre" >
      </mat-form-field>
      
      <div>
        <button mat-raised-button class="button" (click)="buscar()"> BUSCAR </button>
      </div>
    </form>
    <br><br>
    <div class="example-form mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>  
          
        <ng-container matColumnDef="colegio">
          <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Colegio </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="colegio" class='column-id-prospecto'>
            {{element.Solicitud.Centro.Nombre}} 
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="seccion">
          <mat-header-cell *matHeaderCellDef class="sub-title seccion-position"> Sección </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="seccion" class="seccion-position-content">
            {{element.Solicitud.Seccion.Nombre}} 
          </mat-cell>
        </ng-container>
            
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef class="sub-title curp-position"> CURP </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="id" class="curp-position-content"> 
            {{element.Solicitud.Alumno.CURP}} 
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef class="sub-title nombre-position"> Nombre del alumno</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="nombre" class="nombre-position-content"> 
             {{element.Solicitud.Alumno.Nombre}} {{element.Solicitud.Alumno.ApellidoPaterno}}  {{element.Solicitud.Alumno.ApellidoMaterno}} 
          </mat-cell>
        </ng-container>    
  
        <ng-container matColumnDef="tipobeca">
          <mat-header-cell *matHeaderCellDef class="sub-title tipo-beca-position"> Tipo de<br> beca </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="tipo beca" class="tipo-beca-position-content"> 
            {{element.Solicitud.TipoBeca.Nombre}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="porcentaje">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Porcentaje de<br> beca </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="porcentaje" class="porcentaje-beca-content"> 
             {{element.Porcentaje}}%
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell> 
          <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
            <button mat-icon-button color="basic" aria-label="Editar beca externa" (click)="editar(element)" matTooltip="Editar beca externa" matTooltipPosition="right">
              <mat-icon>edit</mat-icon></button>
              
              <button mat-icon-button color="basic" aria-label="Eliminar beca externa" (click)="eliminar(element)" matTooltip="Eliminar beca externa" matTooltipPosition="right">
                <mat-icon>delete_forever</mat-icon></button>              

                <button mat-icon-button color="basic" matTooltip="Descargar documento" [disabled]="!element.TieneArchivo"   matTooltipPosition="right" (click)="descargarDoc(element)">
                  <mat-icon>cloud_download</mat-icon></button>              

              <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element)" matTooltip="Historial">
                <mat-icon>history</mat-icon></button>           
            </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
  
      </mat-table>     
      
      <mat-paginator #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 20]"
        (page)="pageEvent = getServerData($event)" >
      </mat-paginator>
    </div>         
    <br><br>
  </mat-card>