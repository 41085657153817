import { DocumentoEstudio } from './documentoEstudio';
import { Solicitud } from './solicitud';


export class RetroEstudioSolicitud{

    RetroEstudioSolicitudID: number;
    SolicitudID : number;
    EmpresaID : number;
    Porcentaje: number;
    Comentarios: string;
    Solicitud : Solicitud;
    DocEstudioSolicitud :DocumentoEstudio;
}
