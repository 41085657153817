import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleReqCicloComponent } from '../_components/detalle-req-ciclo/detalle-req-ciclo.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { RequisitosService } from '@app/_services/requisitos.service'
import { RequisitoBeca } from '@app/_models/requisitoBeca';

@Component({
  selector: 'app-configuracion-req',
  templateUrl: './configuracion-req.component.html',
  styleUrls: ['./configuracion-req.component.scss']
})
export class ConfiguracionReqComponent implements OnInit {

  links = ['First', '/ayuda', 'Third'];
  activeLink = this.links[0];

  displayedColumnsReq: string[] = ['Clave', 'Detalles', 'Opciones'];
  
  dataSource = new MatTableDataSource<RequisitoBeca>();

  loading: boolean = false;
  error: boolean = false;
  errorMessageP: string = "";

  pageEvent: PageEvent;
  
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  
  filtro: string = "";
  result: string = '';
  errorMessage: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private requisioService:RequisitosService) { }

  nuevoReq(): void{
    const dialogRef = this.dialog.open(DetalleReqCicloComponent, {
      width: '600px',
      data: {operacion: 'Crear'}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.buscar();
    });
  }

  editarReq(requisito:RequisitoBeca): void {
    const dialogRef = this.dialog.open(DetalleReqCicloComponent, {
      width: '600px',
      data: {Operation: 'Editar', Requisito: requisito}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  eliminarRequisito(requisito:RequisitoBeca): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar el requisito?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.requisioService.delete(requisito)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscar();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              });      
            }            
          },
          () => {
            this.error = true;
            this.errorMessage = "Error al eliminar el requisito";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedio un error al eliminar el requisito.', false);    
          });
      }
    });    
  }

  ngOnInit() {
    this.buscar()
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();
    return event;
  }

  buscar() { 
    this.requisioService.listadoRequisitos(this.pageSize, (this.pageIndex+1), this.filtro).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<RequisitoBeca>(data.Dato);
        this.dataSource.paginator = this.paginator;        
      },
      () => {
        this.errorMessageP = "Error al obtener la lista de requisitos";
      });
  }

  filterText(){
    setTimeout (() => {
      console.log("Buscando " + this.filtro);
      this.buscar();
   }, 1000);    

    this.pageIndex = 0;
  }
}