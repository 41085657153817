import { Centro } from './centro';
import { TipoBecaReq } from './tipoBecaReq';
import { TipoBecaReqSeccion } from './tipoBecaReqSeccion';


export class TipoBecaReqCentro{
    Aplicable : boolean;
    Centro : Centro;
    Minimo : number;
    Maximo : number;
    TipoBecaRequisito: TipoBecaReq;
    RequisitoSecciones : TipoBecaReqSeccion[];
    Invalido : boolean;

    TipoBecaID  : number;
    EspecificacionReq  : string;

    TipoBecaRequisitoCentroSecID : number;
}