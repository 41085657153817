import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';
import { SolicitudBecaExterna } from '@app/_models/becaExterna';
import { OperationResult } from '@app/_models/operationResult';

@Injectable({ providedIn: 'root' })
export class BecaExternaService {

  constructor(private http: HttpClient) {}

  buscar(nRegistros:number, nPagina:number, cicloID : number, centros : string[],
    secciones : number[], tiposBeca : number[], nombre : string, UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/ListadoBecasExterna`,  { cicloID, centros, secciones, tiposBeca,nRegistros, nPagina, nombre, UsuarioID} );   
  }
    
  guardarbecaexterna(becaExterna: SolicitudBecaExterna){
    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/BecaExterna`, becaExterna  ).toPromise();    
  }
  
  delete(becaExterna: SolicitudBecaExterna){
    let params = new HttpParams();
    params = params.append('SolicitudBecaExternaID',  becaExterna.SolicitudBecaExternaID.toString());

    return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/BecaExterna`, { params } );
  }

  importar(archivos: FormData, BecaExternaID: number) {
    
    let params = new HttpParams();
    params = params.append('BecaExternaID', BecaExternaID.toString() );

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/BecaExterna/GuardarDocumento`, archivos, { params } );
  }

  descargarDocumento(BecaExternaID: number) {

    let params = new HttpParams();
    params = params.append('BecaExternaID', BecaExternaID.toString());
    
    return this.http.get(`${environment.apiUrl}/DescargaDocBecaExterna/`, { params, responseType: 'blob' } );
  }
}