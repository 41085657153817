import { Component, OnInit, ViewChild, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { CentroService } from '@app/_services/centro.service';
import { MatOption } from '@angular/material/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Ciclo} from '@app/_models/ciclo';
import { Centro } from '@app/_models/centro';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetalleAlumnoBecasComponent } from '@app/_components/detalle-alumno-becas/detalle-alumno-becas.component';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
import { CommonModule, CurrencyPipe} from '@angular/common';
import { PropuestaBecaTabsComponent } from '@app/_components/propuesta-beca-tabs/propuesta-beca-tabs.component';

@Component({
  selector: 'app-detalle-datos-becas',
  templateUrl: './detalle-datos-becas.component.html',
  styleUrls: ['./detalle-datos-becas.component.scss']
})
export class DetalleDatosBecasComponent implements OnInit {

  nuevaPropuestaDatos: FormGroup;
  formattedAmount;
  amount;

  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;

  propuesta : PropuestaBeca;

  centros: Centro[] = [];
  ciclosBecas : Ciclo [];  

  cargandoCiclos = null;
  cargandoCentros = null;
  idUsuario: number;
  enableBtn = false; 
  editable = false;
  esperando = false;
  propuestaEditable = true;

  constructor(private formBuilder: FormBuilder, 
    public dialog: MatDialog, 
    private centroService: CentroService,
    private cicloBecaService: CicloBecaService,
    private authenticationService: AuthenticationService, 
    private propuestaBecaService:PropuestaBecaService,
    private currencyPipe : CurrencyPipe,
    public dialogClose: MatDialogRef<PropuestaBecaTabsComponent>,
    public dialogRef: MatDialogRef<PropuestaBecaTabsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    console.log("DetalleDatosBecasComponent");
    this.editable = true;

    this.nuevaPropuestaDatos = this.formBuilder.group({
      ciclo: [{ value: '' }, Validators.required],
      centro: [{ value: '' }, Validators.required],
      monto: [''],
    });
    
    // //use pipe to display currency
    // this.nuevaPropuestaDatos.valueChanges.subscribe( form => {
    //   if (form.monto){
    //     this.nuevaPropuestaDatos.patchValue({
    //       monto: this.currencyPipe.transform(form.monto.replace(/\D/g, '').replace(/^0+/, ''), 'MXN', 'symbol', '1.0-0')
    //     }, {emitEvent: false});
    //   }
    // });

    if(this.data != null){
      this.propuesta = this.data.Objeto;
      var Monto = '';

      if(this.propuesta != null){
        Monto = '$' + this.propuesta.MontoAprobado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }

      this.nuevaPropuestaDatos.get('monto').setValue(Monto);  

      if(this.data.Operation == 'Editar'){
        this.editable = false;
        this.propuesta = this.data.Objeto;
        this.nuevaPropuestaDatos.get('ciclo').setValue(this.propuesta.Ciclo.CicloID);    
        this.nuevaPropuestaDatos.get('centro').setValue(this.propuesta.Centro.CentroID);    
        
        this.nuevaPropuestaDatos.get('monto').setValue(Monto);  
        this.enableBtn  = true;          
      }
      if(this.data.Operation == 'Revision'){
        this.nuevaPropuestaDatos = this.formBuilder.group({
          ciclo: [{  value : this.propuesta.Ciclo.CicloID, disabled: true }, Validators.required],
          centro: [{  value : this.propuesta.Centro.CentroID, disabled: true }, Validators.required],
          monto: [{  value : Monto, disabled: true }, Validators.required],
        });  
      }else{
        this.propuesta = new PropuestaBeca();
      }
    }

    this.getCiclosBecas();
    this.getCentros()
  }
 
  save(){
    this.esperando = true;
    this.enableBtn = false;

    if (this.data.Operation === 'Editar') {
      this.propuesta.PropuestaBecaID = this.data.Objeto.PropuestaBecaID;
    }
    
    var currency = this.nuevaPropuestaDatos.get('monto').value;
    this.propuesta.MontoAprobado = Number(currency.replace(/[^0-9\.-]+/g,""));

    this.propuesta.Ciclo = new Ciclo();
    this.propuesta.Ciclo.CicloID =  this.nuevaPropuestaDatos.get('ciclo').value;

    this.propuesta.Centro = new Centro();
    this.propuesta.Centro.CentroID = this.nuevaPropuestaDatos.get('centro').value;

    this.propuesta.UsuarioID = this.idUsuario;

    console.log("Propuesta " + JSON.stringify(this.propuesta));
    this.propuestaBecaService.post(this.propuesta).pipe(first()).subscribe(
      data => {
        if (data.Exito){
          this.propuesta.PropuestaBecaID = data.Dato;
          this.data.Objeto = this.propuesta;
          var  Monto = '$' + this.propuesta.MontoAprobado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          this.nuevaPropuestaDatos.get('monto').setValue(Monto);  
    
          const dialogData = new ConfirmDialogModel('', "Propuesta guardada", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 
        }else{
          const dialogData = new ConfirmDialogModel('Error al guardar la propuesta',  data.Mensaje, false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 


        }   
        this.esperando = false;
        this.enableBtn = true;
      },
      error => {
        this.esperando = false;
      }
    );
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }

  siguiente(): void {
    this.dialogClose.close();
    const dialogRef = this.dialog.open(DetalleAlumnoBecasComponent, {
      width: '80%',
      data: {operacion: '1'}
    });
  }

  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      this.permitirCrearPropuesta();
    }
  }  

  onSelectedColegios(entrando) {

    if(!entrando){
      console.log("Cargando...");   
    
      this.permitirCrearPropuesta();
    }
  }   

  formatCurrency(input, blur) {
    var input_val = input.value;
    
    if (input_val === "") { return; }
   
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
  
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);
  
      left_side = this.formatNumber(left_side);
  
      right_side = this.formatNumber(right_side);
      
      if (blur === "blur") {
        right_side += "00";
      }
      
      right_side = right_side.substring(0, 2);
      input_val = left_side + "." + right_side;
  
    } else {
      input_val = this.formatNumber(input_val);
      input_val = input_val;
      
      if (blur === "blur") {
        input_val += ".00";
      }
    }
    
    input.value = "$" + input_val; 
  }

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  permitirCrearPropuesta(){

    var CicloID =  this.nuevaPropuestaDatos.get('ciclo').value;
    var CentroID = this.nuevaPropuestaDatos.get('centro').value;
    if( CicloID != null  && CicloID != 0 && CentroID != null  && CentroID != ''){
      var propuestaBeca = new PropuestaBeca();
      propuestaBeca.Ciclo = new Ciclo();
      propuestaBeca.Ciclo.CicloID =  this.nuevaPropuestaDatos.get('ciclo').value;
  
      propuestaBeca.Centro = new Centro();
      propuestaBeca.Centro.CentroID = this.nuevaPropuestaDatos.get('centro').value;
  
      this.propuestaBecaService.permitirCreacionPropuesta(propuestaBeca).pipe(first()).subscribe(
        data => {
          if (!data.Exito){
            if(data.Dato == "PROCESO"){
              const dialogData = new ConfirmDialogModel(data.Mensaje, "Mensaje", false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              }); 
            }else if(data.Dato == "NO_PROCESO"){
              this.crearComplementoPropuesta(propuestaBeca);
            }
          }
        },
        error => {
        }
      );      
    }
  }

  crearComplementoPropuesta(propuesta:PropuestaBeca){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Desea crear un complemento para la propuesta existente?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.propuestaBecaService.crearComplementoPropuesta(propuesta).pipe(first()).subscribe(
          data => {
            if (data.Exito){
              this.propuesta = data.Dato;
              this.data.Objeto = this.propuesta;

              var  Monto = '$' + this.propuesta.MontoAprobado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              this.nuevaPropuestaDatos.get('monto').setValue(Monto);  
              this.nuevaPropuestaDatos.get('monto').disable();
              const dialogData = new ConfirmDialogModel('', "Complemento creado", false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              });              
            }
          },
          error => {
          }
        ); 
      }
    });    
  }


  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {

      let ciclosActivos = ciclos.filter(c => {
        return new Date(c.FechaFin) >= new Date()
      })
      this.ciclosBecas  = ciclosActivos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }  

  getCentros() {
    var regiones  = [0] 
    this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;

    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

 

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.nuevaPropuestaDatos.controls != null && 
      this.nuevaPropuestaDatos.controls.userType != null &&
      this.nuevaPropuestaDatos.controls.userType.value != null){
      if(this.nuevaPropuestaDatos.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  tosslePerOneCiclos(all){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
    if(this.nuevaPropuestaDatos.controls != null && 
      this.nuevaPropuestaDatos.controls.userType != null &&
      this.nuevaPropuestaDatos.controls.userType.value != null){
      if(this.nuevaPropuestaDatos.controls.userType.value.length==this.centros.length)
      this.allSelectedCiclos.select();
    }
  } 

  verify() {
    if(this.nuevaPropuestaDatos.value.ciclo != "" &&
      this.nuevaPropuestaDatos.value.centro != ""  &&
      this.nuevaPropuestaDatos.value.monto != "" )
    {
      this.enableBtn = true;
      return true
    }
  }

  omit_special_mont(e: any){
    
    if  (/^[0-9]+$/.test(e.key) || e.charCode == 46 || e.key == "."){
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }

  setPropuestaEditable(propuestaEditable : boolean){
    this.propuestaEditable = propuestaEditable;
    if(!this.propuestaEditable){
      this.nuevaPropuestaDatos.get('monto').disable();  
    }

  }
}