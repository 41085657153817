<mat-card-title class="card-title fz-20">Información de estudio socioeconómico</mat-card-title>
<mat-divider></mat-divider>
<br>
<form class="modal-form" name="detalleEstudio" id="detalleEstudio" [formGroup]="detalleEstudio">

    <mat-card-title class="color-azul fz-20">Datos del alumno</mat-card-title>
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>CURP*:</mat-label>
            <input matInput formControlName="curp">
        </mat-form-field>

        <mat-form-field class="input-centro-width xs-width" appearance="outline">
            <mat-label>Centro:</mat-label>
            <input matInput formControlName="centro">
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Grado:</mat-label>
            <input matInput formControlName="grado">
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Nombre:</mat-label>
            <input matInput formControlName="nombre">
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido Paterno:</mat-label>
            <input matInput formControlName="aPaterno">
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido Materno:</mat-label>
            <input matInput formControlName="aMaterno">
        </mat-form-field>
    </div>
    <br>
    <mat-card-title class="color-azul fz-20">Porcentaje sugerido de beca</mat-card-title>
    <mat-form-field appearance="outline"  class="input-full-width xs-width">
        <mat-label>Porcentaje</mat-label>
        <input matInput formControlName="porcentaje"
        (keypress)="omit_special_char($event)" maxlength="3" (ngModelChange)="validarPorcentaje()">
        <span matSuffix>%</span>
    </mat-form-field>
    <mat-hint *ngIf="porcentajeError">El porcentaje debe ser mayor a 0 y menor a 100.</mat-hint>
    <br><br>
    
    <mat-card-title class="color-azul fz-20">Comentarios</mat-card-title>
    <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Comentarios</mat-label>
        <textarea matInput name="motivo" formControlName="comentarios" rows="8"></textarea>
    </mat-form-field>
    
    <mat-card-title class="color-azul fz-20">Importar archivos de soporte</mat-card-title>
    <br>
    <div class="row">
       <button class="buttonSecondary {{ documento.BotonDescripcion=='Seleccionar' ? 'btn-seleccionar' : 'btn-modificar'}}" mat-raised-button 
        (click)="seleccionarArchivo('upload-file')">
            <span class='lato-bold espaciado margin-btn' style="font-size: 16px;">{{documento.BotonDescripcion | uppercase }}</span>
        </button>  
        <input class="input-search text-ident xs-width" [(value)]="NombreArchivo" disabled>
        <input class="hidden" type="file" id="upload-file" name="upload-file" accept=".zip" (change)="adjuntarArchivo($event)">
    </div>
    <br><br><br>

    <div class="loading-screen-icon" *ngIf="esperando">
        <mat-spinner></mat-spinner>
      </div>  
    <div style="text-align: right;">
        <button class="button" style="margin-right: 1rem;" (click)="cancelar()" mat-raised-button>CANCELAR</button>
        <button class="button" style="margin-right: 1rem;" (click)="importarEstudio()" [disabled]="detalleEstudio.invalid || porcentajeError || !archivoAdjuntado"  mat-raised-button>ENVIAR</button>
    </div>
</form>