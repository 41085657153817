import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleUsuarioComponent } from '../_components/detalle-usuario/detalle-usuario.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { EmpleadoService } from '@app/_services/empleado.service'
import { EmpleadoModel } from '@app/_models/empleado.model';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-administracion-usuarios',
  templateUrl: './administracion-usuarios.component.html',
  styleUrls: ['./administracion-usuarios.component.scss']
})
export class AdministracionUsuariosComponent implements OnInit {

  displayedColumns: string[] = ['NumeroEmpleado',  'Nombre', 'Correo',  'Opciones'];
  dataSource = new MatTableDataSource<EmpleadoModel>();

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  
  filtro: string = "";

  buscando = false;  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private usuarioService:EmpleadoService, private formBuilder: FormBuilder) { }

  agregarUsuario(): void {
    const dialogRef = this.dialog.open(DetalleUsuarioComponent, {
      width: '900px',
      data: {operacion: 'NUEVO'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDataUsuarios();
    });
  }

  editarUsuario(empleado:EmpleadoModel): void {
    const dialogRef = this.dialog.open(DetalleUsuarioComponent, {
      width: '900px',
      data: {operacion: 'EDITAR', user: empleado}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.getDataUsuarios();
      }
    });
  }

  eliminarUsuario(empleado:EmpleadoModel){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar el usuario?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.usuarioService.delete(empleado)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.getDataUsuarios();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
              });
            }            
          },
          error => {
            this.error = true;
            this.errorMessage = "Error al eliminar el empleado";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la Solicitud.', false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
            });
          });
      }
    });    
  }
  
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getDataUsuarios();
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    this.getDataUsuarios();

    return event;
  }

  getDataUsuarios() {
    this.getUsuarios(this.pageSize, this.pageIndex + 1);
  }

  getUsuarios(nRegistros:number, nPagina:number) {
    this.loading = true;
    
    this.usuarioService.get(nRegistros, nPagina, this.filtro).then( respuesta => {
      
      this.dataSource = new MatTableDataSource<EmpleadoModel>(respuesta.Dato);
      this.length = respuesta.Total;
      this.loading = false;
    } ).catch(err => {
      console.log("Error" + err);
    }); 
  }

  filterText(){
    this.pageIndex = 0;    
    if(!this.buscando){
      this.buscando = true;
      setTimeout (() => {
        this.getDataUsuarios();
        this.buscando = false;
      }, 3000);   
    }
  }
}
