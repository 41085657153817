import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { DetalleHermanosComponent } from '../_components/detalle-hermanos/detalle-hermanos.component';
import { Solicitud } from '@app/_models/solicitud';
import { SolicitudService } from '@app/_services/solicitud.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { DatosFamiliaresService } from '@app/_services/datosFamiliares.service';
import { Alumno } from '@app/_models/alumno';
import { Responsable } from '@app/_models/responsable';
import { Contacto } from '@app/_models/contacto';
import { DatePipe } from '@angular/common'
import { HermanoSolicitud } from '@app/_models/hermanoSolicitud';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '@app/_services/authentication.service';
import { first } from 'rxjs/operators';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { CicloService } from '@app/_services/ciclo.service';
import { Ciclo } from '@app/_models/ciclo';

@Component({
  selector: 'app-datos-familiares',
  templateUrl: './datos-familiares.component.html',
  styleUrls: ['./datos-familiares.component.scss']
})
export class DatosFamiliaresComponent implements OnInit {
  datosFamiliaresForm: FormGroup;
  Solicitud : Solicitud;
  AlumnoID : number;
  Ciclo: Ciclo;
  
  checked = false;
  fechaSolicitudFin: Date; 
  result: string = '';
  invalidPhone = false;
  curpErrorPadre = false;
  curpErrorMadre = false;
  curpErrorTutor = false;
  formCompleto = false;

  correoError = false;

  fechaExtemporanea = null;
  tipoBeca = '';
  disabled_ = false;
  archivoCargado = false;
  tipoConsulta = false;
  selected = '';
  nombreArchivo = 'Ningún archivo seleccionado';
  ResponsablesOriginales :  Responsable[];  
  isAnahuac: boolean = false;
  check: boolean = false;
  guardando: boolean = false;

  telCasaP: boolean = false;
  telCelP: boolean = false;
  telOfiP: boolean = false;

  telCasaM: boolean = false;
  telCelM: boolean = false;
  telOfiM: boolean = false;

  telCasaT: boolean = false;
  telCelT: boolean = false;
  telOfiT: boolean = false;

  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB
    
  length:number = 0; 
  displayedColumns: string[] = ['curp', 'Nombre', 'Seccion','Centro', 'Grado', 'Opciones'];
  dataSource = new MatTableDataSource<HermanoSolicitud>(); 

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private solicitudService : SolicitudService,
    private cicloService : CicloService,
    private seleccionBecaService : SeleccionBecaService,
    private familiaresService : DatosFamiliaresService,
    private authenticationService: AuthenticationService, 
    private router: Router) { }

  ngOnInit(): void {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      this.becaExtemporanea();
      }).catch(err => {
      console.log("Error" + err);
    }); 

    this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{
      this.tipoBeca = Solicitud.TipoBeca.Nombre
    }).catch(err => {
      console.log("Error" + err);
    }); 

    if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
      this.router.navigate(['/seleccion-beca']);

    }else{
      this.datosFamiliaresForm = this.formBuilder.group({
        CURPPadre:              [''],
        primerApellidoPadre:    [''],
        segundoApellidoPadre:   [''],        
        nombrePadre:            [''],
        correoPadre:            ['', Validators.email],
        telefonoCasaPadre:      [''],
        celularPadre:           [''],
        telefonoOficinaPadre:   [''],
        CURPMadre:              [''],
        primerApellidoMadre:    [''],
        segundoApellidoMadre:   [''],        
        nombreMadre:            [''],
        correoMadre:            ['', Validators.email],
        telefonoCasaMadre:      [''],
        celularMadre:           [''],
        telefonoOficinaMadre:   [''],
        CURPTutor:              [''],
        primerApellidoTutor:    [''],
        segundoApellidoTutor:   [''],        
        nombreTutor:            [''],
        correoTutor:            ['', Validators.email],
        telefonoCasaTutor:      [''],
        celularTutor:           [''],
        telefonoOficinaTutor:   [''],
        checkTutor:   ['']
      });    

      this.fechaSolicitudFin = this.Solicitud.FechaFin;
      this.familiaresService.obtenerFamiliaresBeca(this.Solicitud.SolicitudID).then(solicitud =>{
        this.Solicitud = solicitud;
        if(this.Solicitud.Alumno.Responsables.length ==0){
          this.recuperaResponsablesOriginales();
        }else{
          if(solicitud.Alumno){
            var Responsable = this.obtenerResponsable(solicitud.Alumno, 1);
            if(Responsable != null){
              this.datosFamiliaresForm.get('CURPPadre').setValue(Responsable.CURP);      
              this.datosFamiliaresForm.get('primerApellidoPadre').setValue(Responsable.ApellidoPaterno);      
              this.datosFamiliaresForm.get('segundoApellidoPadre').setValue(Responsable.ApellidoMaterno);      
              this.datosFamiliaresForm.get('nombrePadre').setValue(Responsable.Nombre);      
              if(Responsable.Contacto != null){
                this.datosFamiliaresForm.get('correoPadre').setValue(Responsable.Contacto.Correo);      
                this.datosFamiliaresForm.get('telefonoCasaPadre').setValue(Responsable.Contacto.NumeroCasa);      
                this.datosFamiliaresForm.get('celularPadre').setValue(Responsable.Contacto.Celular);      
                this.datosFamiliaresForm.get('telefonoOficinaPadre').setValue(Responsable.Contacto.Oficina);      
              }
            }
    
            Responsable = this.obtenerResponsable(solicitud.Alumno, 2);
            if(Responsable != null){
              this.datosFamiliaresForm.get('CURPMadre').setValue(Responsable.CURP);      
              this.datosFamiliaresForm.get('primerApellidoMadre').setValue(Responsable.ApellidoPaterno);      
              this.datosFamiliaresForm.get('segundoApellidoMadre').setValue(Responsable.ApellidoMaterno);      
              this.datosFamiliaresForm.get('nombreMadre').setValue(Responsable.Nombre);      
              if(Responsable.Contacto != null){
                this.datosFamiliaresForm.get('correoMadre').setValue(Responsable.Contacto.Correo);      
                this.datosFamiliaresForm.get('telefonoCasaMadre').setValue(Responsable.Contacto.NumeroCasa);      
                this.datosFamiliaresForm.get('celularMadre').setValue(Responsable.Contacto.Celular);      
                this.datosFamiliaresForm.get('telefonoOficinaMadre').setValue(Responsable.Contacto.Oficina);      
              }
            }     
            
            Responsable = this.obtenerResponsable(solicitud.Alumno, 3);
            if(Responsable != null){
              this.datosFamiliaresForm.controls.checkTutor.setValue("true");
              this.nombreArchivo = solicitud.DocumentoTutor ? solicitud.DocumentoTutor.NombreOriginal : 'Ningún archivo seleccionado';
              this.datosFamiliaresForm.get('CURPTutor').setValue(Responsable.CURP);      
              this.datosFamiliaresForm.get('primerApellidoTutor').setValue(Responsable.ApellidoPaterno);      
              this.datosFamiliaresForm.get('segundoApellidoTutor').setValue(Responsable.ApellidoMaterno);      
              this.datosFamiliaresForm.get('nombreTutor').setValue(Responsable.Nombre);      
              if(Responsable.Contacto != null){
                this.datosFamiliaresForm.get('correoTutor').setValue(Responsable.Contacto.Correo);      
                this.datosFamiliaresForm.get('telefonoCasaTutor').setValue(Responsable.Contacto.NumeroCasa);      
                this.datosFamiliaresForm.get('celularTutor').setValue(Responsable.Contacto.Celular);      
                this.datosFamiliaresForm.get('telefonoOficinaTutor').setValue(Responsable.Contacto.Oficina);
              }
            }               
    
            if(this.Solicitud.HermanosSolicitud == null){
              this.Solicitud.HermanosSolicitud = [];
            }
            this.validaForm();
            this.dataSource = new MatTableDataSource<HermanoSolicitud>(this.Solicitud.HermanosSolicitud);
          }
        }
      }).catch(err => {
        console.log("Error" + err);
      });        
    }
  }

  guardar(){
    this.save('/pantalla-principal', this.fechaExtemporanea ?  this.fechaExtemporanea : this.fechaSolicitudFin);
  }

  guardarContinuar(){
    this.save('/requisitos', null);
  }

  save(ruta : string, fechaFin: Date){
    
    var recordatorio = false;
    if(!this.datosFamiliaresForm.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return;
    }
    var responsables = [];

    var responsable = this.obtenerResponsable(this.Solicitud.Alumno, 1);
    if(responsable == null){
      responsable = new Responsable();
      responsable.Contacto = new Contacto();
    }

    responsable.CURP =  this.datosFamiliaresForm.get('CURPPadre').value;
    responsable.ApellidoPaterno =  this.datosFamiliaresForm.get('primerApellidoPadre').value;
    responsable.ApellidoMaterno =  this.datosFamiliaresForm.get('segundoApellidoPadre').value;
    responsable.Nombre =  this.datosFamiliaresForm.get('nombrePadre').value;
    responsable.Contacto.Correo = this.datosFamiliaresForm.get('correoPadre').value;
    responsable.Contacto.NumeroCasa = this.datosFamiliaresForm.get('telefonoCasaPadre').value;
    responsable.Contacto.Celular= this.datosFamiliaresForm.get('celularPadre').value;
    responsable.Contacto.Oficina = this.datosFamiliaresForm.get('telefonoOficinaPadre').value;
    responsable.ParentescoID = 1;
    if (responsable.CURP) responsables.push(responsable);
    
    responsable = this.obtenerResponsable(this.Solicitud.Alumno, 2);
    if(responsable == null){
      responsable = new Responsable();
      responsable.Contacto = new Contacto();
    }

    responsable.CURP =  this.datosFamiliaresForm.get('CURPMadre').value;
    responsable.ApellidoPaterno =  this.datosFamiliaresForm.get('primerApellidoMadre').value;
    responsable.ApellidoMaterno =  this.datosFamiliaresForm.get('segundoApellidoMadre').value;
    responsable.Nombre =  this.datosFamiliaresForm.get('nombreMadre').value;
    responsable.Contacto.Correo = this.datosFamiliaresForm.get('correoMadre').value;
    responsable.Contacto.NumeroCasa = this.datosFamiliaresForm.get('telefonoCasaMadre').value;
    responsable.Contacto.Celular= this.datosFamiliaresForm.get('celularMadre').value;
    responsable.Contacto.Oficina = this.datosFamiliaresForm.get('telefonoOficinaMadre').value;
    responsable.ParentescoID = 2;
    if (responsable.CURP) responsables.push(responsable);

    responsable = this.obtenerResponsable(this.Solicitud.Alumno, 3);
    if(responsable == null){
      responsable = new Responsable();
      responsable.Contacto = new Contacto();
    }

    responsable.CURP =  this.datosFamiliaresForm.get('CURPTutor').value;
    responsable.ApellidoPaterno =  this.datosFamiliaresForm.get('primerApellidoTutor').value;
    responsable.ApellidoMaterno =  this.datosFamiliaresForm.get('segundoApellidoTutor').value;
    responsable.Nombre =  this.datosFamiliaresForm.get('nombreTutor').value;
    responsable.Contacto.Correo = this.datosFamiliaresForm.get('correoTutor').value;
    responsable.Contacto.NumeroCasa = this.datosFamiliaresForm.get('telefonoCasaTutor').value;
    responsable.Contacto.Celular= this.datosFamiliaresForm.get('celularTutor').value;
    responsable.Contacto.Oficina = this.datosFamiliaresForm.get('telefonoOficinaTutor').value;
    responsable.ParentescoID = 3;
    if (responsable.CURP) responsables.push(responsable);

    this.Solicitud.Alumno.Responsables = responsables;
    this.guardando = true;
    this.familiaresService.guardarFamiliaresBeca(this.Solicitud).then(respuesta =>{
      if (respuesta.Exito) {
        this.guardando = false;
        if(respuesta.Dato){
          this.importarDocumento(this.Solicitud.SolicitudID, ruta, fechaFin);
        }else{
          if(fechaFin){
            let latest_date = this.datepipe.transform(fechaFin, 'dd/MM/yyyy');
            var dialogData = new ConfirmDialogModel('', "Recuerda que debes completar la solicitud antes del día" + " " + latest_date + " " + "o tu solicitud será eliminada."  , false);
            recordatorio = true;
          }else{
            var dialogData = new ConfirmDialogModel('', "Información guardada", false); 
          }    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });       
  
          dialogRef.afterClosed().subscribe( result => {
            if(recordatorio){
                this.solicitudService.enviarcorreoRecordatorio(this.Solicitud.SolicitudID).then( res =>{
                }).catch(err => {
                  console.log("Error" + err);
                }).finally(() => {
            
                });

            }
            this.router.navigate([ruta]);          
          }); 
        }

      }else{
        //this.error = true;  
        this.guardando = false;
        const dialogData = new ConfirmDialogModel('Error', respuesta.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

      }
    }).catch(err => {
      this.guardando = false;
      console.log("Error" + err);
    });        
  }

  agregarHermano(): void {
    const dialogRef = this.dialog.open(DetalleHermanosComponent, {
      width: '75%',
      data: {Operacion: 'Crear'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result && result.Operacion === 'Crear'){
        this.Solicitud.HermanosSolicitud.push(result.HermanoSolicitud);
        this.dataSource = new MatTableDataSource<HermanoSolicitud>(this.Solicitud.HermanosSolicitud);
      }
    });
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    this.tipoConsulta = true;
    this.archivoCargado = true;
    this.nombreArchivo = document.target.files[0].name
    this.Archivo_ = document.target.files[0];
    this.validaForm();
  }

  importarDocumento(SolicitudID: number, ruta : string, fechaFin: Date){ 

    let nNulos:number = 0;
    let filesFD = new FormData();
    if(fechaFin){
      let latest_date = this.datepipe.transform(fechaFin, 'dd/MM/yyyy');
      var dialogData = new ConfirmDialogModel('', "Recuerda que debes completar la solicitud antes del día" + " " + latest_date + " " + "o tu solicitud será eliminada."  , false);
    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });        
    }

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.familiaresService.importar(filesFD, SolicitudID).pipe(first()).subscribe(data =>
          {
            if(data.Exito){
              var dialogData = new ConfirmDialogModel('', "Información guardada", false); 
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              });  

              this.router.navigate([ruta]);
            }
          });

      }else {
        console.log("Exceso de peso");
        const dialogData = new ConfirmDialogModel('Error', "El documento excede el peso permitido :5M" , false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }
    }else{
      if(this.nombreArchivo == 'Ningún archivo seleccionado'){
        const dialogData = new ConfirmDialogModel('Error', "No se encontró el documento probatorio", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
  
      }else{
        this.router.navigate([ruta]);

      }
    }
  }

  editarHermano(hermanoElement:any): void {
    const dialogRef = this.dialog.open(DetalleHermanosComponent, {
      width: '75%',
      data: {Operacion: 'Editar', HermanoSolicitud: hermanoElement}
    });

    dialogRef.afterClosed().subscribe(result => {

      const setVal = this.Solicitud.HermanosSolicitud.find( op => op.Alumno.Matricula === hermanoElement.Alumno.Matricula);

      const index: number = this.Solicitud.HermanosSolicitud.indexOf(setVal);
      this.Solicitud.HermanosSolicitud[index] =hermanoElement;
      this.dataSource = new MatTableDataSource<HermanoSolicitud>(this.Solicitud.HermanosSolicitud);
    });
  }

  eliminarHermano(hermanoElement:HermanoSolicitud ): void {
    const message = `¿Está seguro de que  quiere borrar el hermano?`;
    const dialogData = new ConfirmDialogModel("Confirmar", message);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        
        const index: number = this.Solicitud.HermanosSolicitud.indexOf(hermanoElement);
        if (index !== -1) {
          this.Solicitud.HermanosSolicitud.splice(index, 1);
          this.dataSource = new MatTableDataSource<HermanoSolicitud>(this.Solicitud.HermanosSolicitud);
        }   
      }
    });
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  }  
  
  obtenerResponsable(Alumno : Alumno,  ParentescoID : number): Responsable{
    var Responsable = null;
    Alumno.Responsables.forEach(element => {
      if(element.ParentescoID == ParentescoID){
        Responsable = element;
      }
    });
    return Responsable;
  }

  recuperaResponsablesOriginales(){
    this.familiaresService.recuperaResponsables( this.AlumnoID).then( e => {
      if(e != null){
        this.ResponsablesOriginales = e;
        this.initFormulario();
        this.validaForm();
      }

    }).catch(err => {
      console.log("Error" + err);
    });  
  }  

  obtenerResponsableOriginal( ParentescoID : number): Responsable{
    var Responsable = null;
    this.ResponsablesOriginales.forEach(element => {
      if(element.ParentescoID == ParentescoID){
        Responsable = element;
      }
    });
    return Responsable;
  }

  initFormulario(){

    var responsable = this.obtenerResponsableOriginal(1);
    if(responsable){
      this.datosFamiliaresForm.get('CURPPadre').setValue(responsable.CURP);      
      this.datosFamiliaresForm.get('primerApellidoPadre').setValue(responsable.ApellidoPaterno);      
      this.datosFamiliaresForm.get('segundoApellidoPadre').setValue(responsable.ApellidoMaterno);      
      this.datosFamiliaresForm.get('nombrePadre').setValue(responsable.Nombre);      
      if(responsable.Contacto != null){
        this.datosFamiliaresForm.get('correoPadre').setValue(responsable.Contacto.Correo);      
        this.datosFamiliaresForm.get('telefonoCasaPadre').setValue(responsable.Contacto.NumeroCasa);      
        this.datosFamiliaresForm.get('celularPadre').setValue(responsable.Contacto.Celular);      
        this.datosFamiliaresForm.get('telefonoOficinaPadre').setValue(responsable.Contacto.Oficina); 
      }
    }
    var Responsable = this.obtenerResponsableOriginal(2);
    if(Responsable != null){
      this.datosFamiliaresForm.get('CURPMadre').setValue(Responsable.CURP);      
      this.datosFamiliaresForm.get('primerApellidoMadre').setValue(Responsable.ApellidoPaterno);      
      this.datosFamiliaresForm.get('segundoApellidoMadre').setValue(Responsable.ApellidoMaterno);      
      this.datosFamiliaresForm.get('nombreMadre').setValue(Responsable.Nombre);      
      if(Responsable.Contacto != null){
        this.datosFamiliaresForm.get('correoMadre').setValue(Responsable.Contacto.Correo);      
        this.datosFamiliaresForm.get('telefonoCasaMadre').setValue(Responsable.Contacto.NumeroCasa);      
        this.datosFamiliaresForm.get('celularMadre').setValue(Responsable.Contacto.Celular);      
        this.datosFamiliaresForm.get('telefonoOficinaMadre').setValue(Responsable.Contacto.Oficina);      
      }
    }
  }

  validarCurpPadre(){
    var curp =  this.datosFamiliaresForm.get('CURPPadre').value;
    this.curpErrorPadre = this.validarCurp(curp);
  }

  validarCurpMadre(){
    var curp =  this.datosFamiliaresForm.get('CURPMadre').value;
    this.curpErrorMadre = this.validarCurp(curp);
  }

  validarCurpTutor(){
    var curp =  this.datosFamiliaresForm.get('CURPTutor').value;
    this.curpErrorTutor = this.validarCurp(curp);
  }

  validarCurp(curp : string) : boolean{

   let error = false; 
    if(curp != null){
      curp = curp.toUpperCase();
      error=false;
      if(curp != null && curp.length >0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado)  //Coincide con el formato general?
        error=true;
      }
    }
    return error;
  } 

  omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]+$/.test(e.key)) {
      return true;
    } 
    e.preventDefault();
    return false;
  }

  numberPhone(e){
    if (/^[0-9]*$/.test(e.key)) {
      return true;
    } 
    else {
      e.preventDefault(); 
      return false;
    }
  }

  verifyNumPadre(){
    let numC = this.datosFamiliaresForm.controls.telefonoCasaPadre.value
    let numM = this.datosFamiliaresForm.controls.celularPadre.value
    let numO = this.datosFamiliaresForm.controls.telefonoOficinaPadre.value

    if (numC?.length > 0 && 7 >= numC?.length || numC?.length == 9) {
      this.telCasaP = true;
    }else {this.telCasaP = false;}
    if (numM?.length > 0 && 7 >= numM?.length || numM?.length == 9) {
      this.telCelP = true;
    }else{this.telCelP = false;}
    if (numO?.length > 0 && 7 >= numO?.length || numO?.length == 9) {
      this.telOfiP = true;
    }else{this.telOfiP = false;}
  }

  verifyNumMadre(){
    let numC = this.datosFamiliaresForm.controls.telefonoCasaMadre.value
    let numM = this.datosFamiliaresForm.controls.celularMadre.value
    let numO = this.datosFamiliaresForm.controls.telefonoOficinaMadre.value

    if (numC?.length > 0 && 7 >= numC?.length || numC?.length == 9) {
      this.telCasaM = true;
    }else {this.telCasaM = false;}
    if (numM?.length > 0 && 7 >= numM?.length || numM?.length == 9) {
      this.telCelM = true;
    }else{this.telCelM = false;}
    if (numO?.length > 0 && 7 >= numO?.length || numO?.length == 9) {
      this.telOfiM = true;
    }else{this.telOfiM = false;}
  }

  verifyNumTutor(){
    let numC = this.datosFamiliaresForm.controls.telefonoCasaTutor.value
    let numM = this.datosFamiliaresForm.controls.celularTutor.value
    let numO = this.datosFamiliaresForm.controls.telefonoOficinaTutor.value

    if (numC?.length > 0 && 7 >= numC?.length || numC?.length == 9) {
      this.telCasaT = true;
    }else {this.telCasaT = false;}
    if (numM?.length > 0 && 7 >= numM?.length || numM?.length == 9) {
      this.telCelT = true;
    }else{this.telCelT = false;}
    if (numO?.length > 0 && 7 >= numO?.length || numO?.length == 9) {
      this.telOfiT = true;
    }else{this.telOfiT = false;}
  }

  validarEmail(valor) {
    if(valor != null){
      if(valor != null && valor.length > 0){
        if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor)){
        } else {
          this.correoError=true;
        }
      }
    }
  }

  validaForm(){
    this.formCompleto = true;
    if(this.tieneDatosPadre() || this.tieneDatosMadre() || this.tieneDatosTutor() ){
      if((this.tieneDatosPadre() && !this.datosValidosPadre()) 
            || (this.tieneDatosMadre() && !this.datosValidosMadre()) 
            || (this.tieneDatosTutor() && !this.datosValidosTutor())){
        this.formCompleto = false;
       }

    }else{
      this.formCompleto = false;
    }
    
  }

  tieneDatosPadre(): boolean{
    var tieneDatosPadre = false;
    if( (this.datosFamiliaresForm.controls.CURPPadre.value != "" && this.datosFamiliaresForm.controls.CURPPadre.value != null )||
      (this.datosFamiliaresForm.controls.primerApellidoPadre.value != "" && this.datosFamiliaresForm.controls.primerApellidoPadre.value != null ) ||
      (this.datosFamiliaresForm.controls.segundoApellidoPadre.value != "" && this.datosFamiliaresForm.controls.segundoApellidoPadre.value != null ) ||
      (this.datosFamiliaresForm.controls.nombrePadre.value != "" && this.datosFamiliaresForm.controls.nombrePadre.value != null ) ||
      (this.datosFamiliaresForm.controls.telefonoCasaPadre.value != "" && this.datosFamiliaresForm.controls.telefonoCasaPadre.value != null ) ||
      (this.datosFamiliaresForm.controls.correoPadre.value != "" && this.datosFamiliaresForm.controls.correoPadre.value != null )||
      (this.datosFamiliaresForm.controls.celularPadre.value != ""&& this.datosFamiliaresForm.controls.celularPadre.value != null ) ){
        tieneDatosPadre = true
    } 
    return tieneDatosPadre;

  }

  datosValidosPadre(): boolean{
    var validos = true;;
    if(   this.datosFamiliaresForm.controls.CURPPadre.value ==  null || 
            this.datosFamiliaresForm.controls.CURPPadre.value == "" ||
            this.datosFamiliaresForm.controls.primerApellidoPadre.value == null || 
            this.datosFamiliaresForm.controls.primerApellidoPadre.value == "" ||
            this.datosFamiliaresForm.controls.nombrePadre.value  == null  ||
            this.datosFamiliaresForm.controls.nombrePadre.value == ""  ||
            this.datosFamiliaresForm.controls.celularPadre.value  == null  ||
            this.datosFamiliaresForm.controls.celularPadre.value == ""  ||
            this.datosFamiliaresForm.controls.correoPadre.value == ""  ){
        validos = false;
    }
    return validos;

  }

  tieneDatosMadre(): boolean{
      var  tieneDatosMadre = false;
      if( (this.datosFamiliaresForm.controls.CURPMadre.value != ""  && this.datosFamiliaresForm.controls.CURPMadre.value != null )    ||  
      (this.datosFamiliaresForm.controls.primerApellidoMadre.value != ""   && this.datosFamiliaresForm.controls.primerApellidoMadre.value != null)||
      (this.datosFamiliaresForm.controls.segundoApellidoMadre.value != ""  && this.datosFamiliaresForm.controls.segundoApellidoMadre.value != null )||
      (this.datosFamiliaresForm.controls.nombreMadre.value != ""  && this.datosFamiliaresForm.controls.nombreMadre.value != null) ||
      (this.datosFamiliaresForm.controls.telefonoCasaMadre.value != ""  && this.datosFamiliaresForm.controls.telefonoCasaMadre.value != null) ||
      (this.datosFamiliaresForm.controls.correoMadre.value != ""   && this.datosFamiliaresForm.controls.correoMadre.value != null) ||
      (this.datosFamiliaresForm.controls.celularMadre.value != ""  && this.datosFamiliaresForm.controls.celularMadre.value != null) ){
        tieneDatosMadre = true
    } 

    return tieneDatosMadre;
  }


  datosValidosMadre(): boolean{
    var  validos = true;;
    if( this.datosFamiliaresForm.controls.CURPMadre.value ==  null || 
            this.datosFamiliaresForm.controls.CURPMadre.value == "" ||
            this.datosFamiliaresForm.controls.primerApellidoMadre.value == null || 
            this.datosFamiliaresForm.controls.primerApellidoMadre.value == "" ||
            this.datosFamiliaresForm.controls.nombreMadre.value  == null  ||
            this.datosFamiliaresForm.controls.nombreMadre.value == ""  ||
            this.datosFamiliaresForm.controls.celularMadre.value  == null  ||
            this.datosFamiliaresForm.controls.celularMadre.value == ""  ||
            this.datosFamiliaresForm.controls.correoMadre.value == "" ){
        validos = false;
    }
    return validos;
  }
  
  tieneDatosTutor(): boolean{
    var  tieneDatosTutor = false;
    if( (this.datosFamiliaresForm.controls.checkTutor.value  )||
      (this.datosFamiliaresForm.controls.CURPTutor.value != ""  && this.datosFamiliaresForm.controls.CURPTutor.value != null) ||
    (this.datosFamiliaresForm.controls.primerApellidoTutor.value != ""  && this.datosFamiliaresForm.controls.primerApellidoTutor.value != null) ||
    (this.datosFamiliaresForm.controls.segundoApellidoTutor.value != ""  && this.datosFamiliaresForm.controls.segundoApellidoTutor.value != null )||
    (this.datosFamiliaresForm.controls.nombreTutor.value != ""  && this.datosFamiliaresForm.controls.nombreTutor.value != null) ||
    (this.datosFamiliaresForm.controls.telefonoCasaTutor.value != ""  && this.datosFamiliaresForm.controls.telefonoCasaTutor.value != null) ||
    (this.datosFamiliaresForm.controls.correoTutor.value != ""   && this.datosFamiliaresForm.controls.correoTutor.value != null)||
    (this.datosFamiliaresForm.controls.celularTutor.value != ""  && this.datosFamiliaresForm.controls.celularTutor.value != null)){
      tieneDatosTutor = true
  } 
    return tieneDatosTutor;
  }

  datosValidosTutor(): boolean{
    var validos = true;;

    if(this.datosFamiliaresForm.controls.CURPTutor.value ==  null || 
      this.datosFamiliaresForm.controls.CURPTutor.value == "" ||
      this.datosFamiliaresForm.controls.primerApellidoTutor.value == null || 
      this.datosFamiliaresForm.controls.primerApellidoTutor.value == "" ||
      this.datosFamiliaresForm.controls.nombreTutor.value  == null  ||
      this.datosFamiliaresForm.controls.nombreTutor.value == ""  ||
      this.datosFamiliaresForm.controls.correoTutor.value == "" ||
      this.nombreArchivo == "Ningún archivo seleccionado"){
            
      validos = false;
    }
    return validos;
  }

  tutorCheck(e) {
    this.check = true   
    if(!e.target.checked){  
      this.check = false   
      this.datosFamiliaresForm.get('CURPTutor').setValue('');
      this.datosFamiliaresForm.get('primerApellidoTutor').setValue('');
      this.datosFamiliaresForm.get('segundoApellidoTutor').setValue('');
      this.datosFamiliaresForm.get('nombreTutor').setValue('');
      this.datosFamiliaresForm.get('correoTutor').setValue('');
      this.datosFamiliaresForm.get('telefonoCasaTutor').setValue('');
      this.datosFamiliaresForm.get('celularTutor').setValue('');
      this.datosFamiliaresForm.get('telefonoOficinaTutor').setValue('');
      this.datosFamiliaresForm.get('checkTutor').setValue('');
    }
  }
 
  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if(e.FechaFin){
        this.fechaExtemporanea = e.FechaFin;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  } 
}