import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';
import { RetroEstudioSolicitud } from '@app/_models/retroEstudio';

@Injectable({ providedIn: 'root' })
export class CargaEstudioSocService {

constructor(private http: HttpClient) {}

  obtenerEstudioSocio(SolicitudID:number, EmpresaID : number):Promise<RetroEstudioSolicitud>{
    return  this.http.get<RetroEstudioSolicitud>(`${environment.apiUrl}/InformacionEstudio/${SolicitudID}/${EmpresaID}/RetroEmpresa`).toPromise();
  }


  importar(archivo: FormData, SolicitudID : number, UsuarioID : number,
    comentarios : string , porcentaje : number) {

      let params = new HttpParams();
      params = params.append('SolicitudID', SolicitudID.toString() );
      params = params.append('UsuarioID', UsuarioID.toString() );
      params = params.append('comentarios', comentarios.toString() );
      params = params.append('porcentaje', porcentaje.toString() );
  
      return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/InformacionEstudio`, archivo, {params});
 }



  actualizarDatos(SolicitudID : number, UsuarioID : number,
    comentarios : string , porcentaje : number): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/ActualizarDatosEstudio`, {SolicitudID, UsuarioID, comentarios, porcentaje}).toPromise();
  
  }




}