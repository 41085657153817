import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { EstatusPropuesta } from '@app/_models/estatusPropuesta';

@Injectable({ providedIn: 'root' })
export class EstatusPropuestaService {

    constructor(private http: HttpClient) {
    }

    consultarEstatusPropuesta():Promise<EstatusPropuesta[]>{
        return this.http.get<EstatusPropuesta[]>(`${environment.apiUrl}/EstatusPropuesta`).toPromise();
    }

    consultarEstatusPropuestaRevisionAprobacion(isAprobador : boolean):Promise<EstatusPropuesta[]>{
        console.log("is aprobador"+ isAprobador);
        if(isAprobador){
            return this.http.get<EstatusPropuesta[]>(`${environment.apiUrl}/EstatusPropuestaRevisionAprobacion`).toPromise();
        }else{
            return this.http.get<EstatusPropuesta[]>(`${environment.apiUrl}/EstatusPropuestaRevision`).toPromise();
        }

    }


}