import { TipoRequisito } from './tipoRequisito';
import { TipoBeca } from './tipoBeca';
import { EspecificacionRequisito } from './especificacionRequisito';


export class RequisitoBeca{

    RequisitoBecaID: number;
    Clave : string;
    Nombre : string;
    Descripcion : string;
    Especificacion: boolean;
    Minimo : number;
    Maximo : number;
    TipoBeca : TipoBeca;
    Extensiones: string;
    EspecificacionRequisito: EspecificacionRequisito; 
    UsuarioID : number;
}
