<mat-card-title class="card-title fz-20">
    Especificaciones de  {{dataTipoBecaSecciones.TipoBecaSeccion.Seccion.Nombre}} por centros
  </mat-card-title>
  <p class="color-marino fz-14 text-center"> {{dataTipoBecaSecciones.TipoBecaSeccion.TipoBecaNombre}}</p>
    <mat-table  [dataSource]="dataSourceRequisitoCentros" >

        <ng-container matColumnDef="Aplicable">
            <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Descripción">
              <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
            </mat-cell>
        </ng-container>   

        <ng-container matColumnDef="centro">
          <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Centro.Nombre}} </mat-cell>
        </ng-container>      
  
        <ng-container matColumnDef="grado">
          <mat-header-cell *matHeaderCellDef> Especificación por grado </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Máximo"> 
            <button mat-icon-button color="basic" matTooltip="Agregar especificación por grado"
            [disabled]="!element.Aplicable"
            matTooltipPosition="right" (click)="especificacionXGrado(element)">
          <mat-icon>dns</mat-icon></button>
          <div class="row" *ngIf="element.BecaSeccionGrados.length > 0">
            <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar {{element.BecaSeccionGrados.length}} especificaciones"
            (click)="eliminarEspecificacionXGrado(element)"
            matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
          </div>
          </mat-cell>
        </ng-container> 
  
        <mat-header-row *matHeaderRowDef="displayedColumnsCentros"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsCentros;">
        </mat-row>
    </mat-table> 
  
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <br><br><br><br>
  <div class="modal-form margin-div">
    <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>  
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn"  >ACEPTAR</button>    
  </div>
  
  <div *ngIf="error">
    <br>
    <mat-card class='card-danger'>{{errorMessage}}</mat-card>
  </div>