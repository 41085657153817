export * from "./calcula-valores";
export * from "./get-secciones";
export * from "./get-tipos-beca";
export * from "./max-length-check";
export * from "./min-max-validation-function";
export * from "./on-no-click";
export * from "./on-select-seccion";
export * from "./operacion-costo";
export * from "./refleja-aprobado";
export * from "./save";
export * from "./update-propuesta";
export * from "./validar-porcentaje";
export * from "./ver-comentarios";