import { Centro } from './centro';
import { Ciclo } from './ciclo';
import { ComplementoPropuesta } from './complementoPropuesta';
import { EstatusPropuesta } from './estatusPropuesta';
import { PropuestaAlumno } from './propuesta-alumno';
import { PropuestaCuota } from './propuesta-cuota';
import { PropuestaSolicitud } from './propuestaSolicitud';

export class PropuestaBeca{

    PropuestaBecaID: number;
    MontoAprobado : number; 
    CostoBecas : number;
    PorcentajeConsumido : number;

    Centro : Centro;
    Ciclo : Ciclo;

    PropuestaAlumnos : PropuestaAlumno[];
    PropuestaCuotas :PropuestaCuota[];

    PropuestaSolicitudes :PropuestaSolicitud[];

    EstatusPropuesta : EstatusPropuesta;
    UsuarioID: number;

    ComplementoPropuesta : ComplementoPropuesta;
    EsExterna : boolean;
    TotalAlumnos : number;
    PorcentajeBecaSEP: number;
}

export interface PropuestaBecaOperation {
    prop: PropuestaBeca,
    operacion: string
}