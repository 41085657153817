import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { LoadingScreenService } from '@app/_services/loading-screen.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { CryptoUtils, Logger } from 'msal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuarioAlumno: string = "";
  passwordAlumno: string = "";

  usuarioEmpresa: string = "";
  passwordEmpresa: string = "";

  usuarioEmpleado: string = "";
  passwordEmpleado: string = "";

  returnUrl: string = "";
  error: boolean = false;
  errorMessage: string = "";
  loading: boolean = false;
  breakpoint = 3
  imgCol = 2
  imgRow = 5
  hide = true;
  anahuac = "false";
  isIframe = false;
  loggedIn = false;
  public isAnahuac: boolean = false;
  private subscription: Subscription;  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService,
    private broadcastService: BroadcastService, 
    private authService: MsalService,
    
  ) {
    let anahuac = localStorage.getItem('usrAhc');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        if(this.returnUrl == "/login/anahuac" || anahuac == "true") this.isAnahuac = true;
      }
    });
  }

  ngOnInit() { 
    
    if (this.router.url == "/login/anahuac"){
      this.anahuac = "true";
    }
    localStorage.setItem('usrAhc', this.anahuac);

   //this.breakpoint = (window.innerWidth <= 960) ? 0 : 3;
   this.onResizeInit(window.innerWidth);
    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();

    /*
    this.subscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
      var user = this.authService.getAccount();
      if(this.loggedIn){
        this.authenticationService.loginEmpleado(user.userName, this.numEmpleado)
        .pipe(first())
        .subscribe(
          data => {
            
            if(data != undefined){
              console.log("Navegando");
              this.navigationService.isToggledMenu.next(true);
              this.router.navigate([this.returnUrl]);
              this.loadingScreenService.stopLoading();
            }
            else{
              this.error = true;
              this.errorMessage = "Hubo un error al autenticar, Usuario no registradok.";
              this.loadingScreenService.stopLoading();
              this.authService.logout();
            }
          },
          error => {
            this.error = true;
            this.errorMessage = "Hubo un error al autenticar.";
            this.loadingScreenService.stopLoading();
            this.authService.logout();
          }); 

      }
     
    });*/

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));


    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.authenticationService.currentUserValue != null) {
      this.router.navigate(['/inicio']);
    }
  }

  login(): void {

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.login(this.usuarioAlumno, this.passwordAlumno)
      .pipe(first())
      .subscribe(
        data => {
          console.log("Navegando");
          this.navigationService.isToggledMenu.next(true);
          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }


  loginEmpresa(): void {

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.loginEmpresa(this.usuarioEmpresa, this.passwordEmpresa)
      .pipe(first())
      .subscribe(
        data => {
          console.log("Navegando");
          this.navigationService.isToggledMenu.next(true);
          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }

  loginEmpleado(): void {

    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.loginEmpleado(this.usuarioEmpleado, this.passwordEmpleado)
      .pipe(first())
      .subscribe(
        data => {
          console.log("Navegando");
          this.navigationService.isToggledMenu.next(true);
          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });
  }

  onResizeInit(event) {
    if(event <= 960){
      this.breakpoint = 1;
      this.imgCol = 1
      this.imgRow = 2
      return
    }
    this.breakpoint = 3;
    this.imgCol = 2
    this.imgRow = 5
  }

  onResize(event) {
    if(event.target.innerWidth <= 960){
      this.breakpoint = 1;
      this.imgCol = 1
      this.imgRow = 2
      return
    }
    this.breakpoint = 3;
    this.imgCol = 2
    this.imgRow = 5
  }

  checkAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  /*
  loginEmpledo() {
    this.loadingScreenService.startLoading();
    this.error = false;
    this.errorMessage = "";

    this.authenticationService.loginEmpleado(this.usuario, this.password)
      .pipe(first())
      .subscribe(
        data => {
          console.log("Navegando");
          this.navigationService.isToggledMenu.next(true);
          this.router.navigate([this.returnUrl]);
          this.loadingScreenService.stopLoading();
        },
        error => {
          this.error = true;
          this.errorMessage = "Usuario o contraseña inválidos.";
          this.loadingScreenService.stopLoading();
        });

    
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
      console.log("despues login popup")
    }
  }*/

  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
