import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  public isProsp: boolean = false;
  public isEmpleado: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: MsalService
  ) { }

  ngOnInit(): void {

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.isProsp = user.IsProspecto;
        this.isEmpleado = user.IsEmpleado;
      }
    })
 
    let anahuac = localStorage.getItem('usrAhc');
    localStorage.clear()
    
    if(anahuac == "true") {
      this.authenticationService.logout();
      this.router.navigate(["/login/anahuac"]);
      return
    }

    if(this.isEmpleado){
      var loggedIn = !!this.authService.getAccount();
      if(loggedIn){
        this.authService.logout();
      }
      this.authenticationService.logout();
      sessionStorage.clear ();
      this.router.navigate(["/login"]);

    }else{
      this.authenticationService.logout();
      this.router.navigate(["/login"]);
    }
  }
}
