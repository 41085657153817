import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { SolicitudService } from './solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { ResponseRest } from '@app/_models/responseRest';
import { HermanoSolicitud } from '@app/_models/hermanoSolicitud';
import { HermanoSolicitudOperation } from '@app/_models/hermanoOperacion';
import { Responsable } from '@app/_models/responsable';

@Injectable({ providedIn: 'root' })
export class DatosFamiliaresService {

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService) {}

  guardarFamiliaresBeca(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/DatosFamiliares`, solicitud).toPromise(); 
  }

  obtenerFamiliaresBeca(SolicitudID : number): Promise<Solicitud> {
    var promise =  this.http.get<Solicitud>(`${environment.apiUrl}/DatosFamiliares/${SolicitudID}/ObtenerDatosFamiliares`).toPromise();
    console.log("Prom: " + promise);    
    return promise;
  }

  guardarHermano(hermano : HermanoSolicitudOperation):Promise<ResponseRest<string>> {
    return  this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Hermano`, hermano).toPromise();
  }     

  eliminarHermano(hermano : HermanoSolicitud):Promise<ResponseRest<string>> {
    let params = new HttpParams();
    params = params.append('AlumnoID', hermano.HermanoSolicitudID.toString() );
    return  this.http.delete<ResponseRest<string>>(`${environment.apiUrl}/Hermano`, {params}).toPromise();      
  } 
  
  recuperaResponsables( AlumnoID : number){
    return this.http.get<Responsable[]>(`${environment.apiUrl}/${AlumnoID}/RecuperaResponsables` ).toPromise();

  }      

  importar(archivos: FormData, SolicitudID: number) {
    
    let params = new HttpParams();
    params = params.append('SolicitudID', SolicitudID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/GuardarDocumentoTutor`, archivos, { params } );
  }

}