import { Seccion } from './seccion';
import { TipoBecaReq } from './tipoBecaReq';
import { TipoBecaReqSecGra } from './tipoBecaReqSecGra';

export class TipoBecaReqSeccion{

    Aplicable : boolean;
    Seccion: Seccion;
    TipoBecaRequisito: TipoBecaReq;
    Minimo : number;
    Maximo : number;
    RequisitoGrados : TipoBecaReqSecGra[];
    
    TipoBecaRequisitoSeccionID: number;
    TipoBecaRequisitoCentroSecID: number;

    Invalido :boolean;
    
}