import { Alumno } from './alumno';
import { TipoBeca } from './tipoBeca';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from './seccion';
import { Centro } from './centro';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from './gradoEducativo';
import { EstatusSolicitud } from './estatusSolicitud';
import { HermanoSolicitud } from './hermanoSolicitud';
import { elementAt } from 'rxjs/operators';
import { Ciclo } from './ciclo';
import { DocumentoTutorSolicitud } from './docTutorSolicitud';
import { CalificacionRequisitoSolicitud } from './calificacionRequisitoSolicitud';
import { EstatusEnvioCorreo } from './estatusEnvioCorreo';
import { EstatusRenovacion } from './estatusRenovacion';

export class Solicitud {

    SolicitudID: number;
    Alumno : Alumno;
    TipoBeca: TipoBeca;
    EsRenovacion: boolean;
    PorcentajeBecaActual : number;
    Seccion : Seccion;
    Centro : Centro;
    Ciclo : Ciclo;
    GradoEducativo : GradoEducativo;
    EstatusSolicitud : EstatusSolicitud;
    MotivoSolicitud : string;
    PromedioAnterior: number;
    PromedioActual :number;
    PromedioConducta :number;

    HermanosSolicitud :  HermanoSolicitud[];

    UsuarioID: number;

    SolicitudEditable : boolean;
    PermitirBorrado : boolean;

    DocumentoTutor : DocumentoTutorSolicitud;

    ClaveTipoRequisito : string;
    CalificacionEnsayo : CalificacionRequisitoSolicitud;
    CalificacionPruebaApt : CalificacionRequisitoSolicitud;

    RequiereEnsayo : boolean;
    RequierePruebaAptitud : boolean;
    Comentarios : string;
    EstatusEnvioCorreo : EstatusEnvioCorreo;
    EstatusRenovacion : EstatusRenovacion;

    
    EnviarCorreo : boolean;

    FechaFin : Date;

    Admision : boolean;

  }