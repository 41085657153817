import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent} from '@angular/material/paginator';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { MatTableDataSource} from '@angular/material/table';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { first } from 'rxjs/operators';
import { TipoBeca } from '@app/_models/tipoBeca';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { RequisitosService } from '@app/_services/requisitos.service';
import { TipoBecaReq } from '@app/_models/tipoBecaReq';
import { TipoBecaOp } from '@app/_models/tipoBecaOp';
import { TipoBecaSeccion } from '@app/_models/tipoBecaSeccion';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-detalle-becas-ciclo',
  templateUrl: './detalle-becas-ciclo.component.html',
  styleUrls: ['./detalle-becas-ciclo.component.scss']
})
export class DetalleBecasCicloComponent implements OnInit {

  displayedColumnsCiclos: string[] = ['Nombre', 'Minimo', 'Maximo'];
  
  dataSourceRequisitos = new MatTableDataSource<TipoBecaReq>();

  detalleBecaForm: FormGroup;

  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;

  @ViewChild('allSelectedRequisitos') private allSelectedRequisitos: MatOption;
  @ViewChild('mySelRequisitos') mySelRequisitos: MatSelect;

  secciones: Seccion[] = []; 
  selectedSecciones : number [];  

  requisitos: RequisitoBeca[] = []; 
  selectedRequisitos : number [];  

  maxInvalid = false
  enableBtn = false
  cargandoSecciones = null;
  cargandoRequisitos = null;

  porcentajeErr: boolean = false;
  requisitoEspecificacion: TipoBecaReq[] = []; 
  
  allRequsitosBeca: TipoBecaReq[] = []; 

  pageEvent: PageEvent;
  
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";

  guardando = false;
  checkPorcentaje = false;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private seccionService: SeccionService,
    private requisitoService : RequisitosService,
    private becaservice: TipoBecaService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetalleBecasCicloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TipoBecaOp) {}

  ngOnInit(): void {

    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.chargeSeccions();
    this.cargarRequisitos();

    this.detalleBecaForm = this.formBuilder.group({
      clave: ['', Validators.required],
      requisitos: ['', Validators.required],
      nombre: ['', Validators.required],
      tipoBeca: ['', Validators.required],
      mostrarEnPropuesta: ['', Validators.required],
      incluirEnPresupuesto: ['', Validators.required],
      secciones: ['', Validators.required],
      porcentaje: [''],
      checkPorcentaje: ['']
    }); 


    if(this.data.Operation == 'Editar'){
      
      this.enableBtn = true;
      this.detalleBecaForm.get('clave').setValue(this.data.TipoBeca.Clave);    
      this.detalleBecaForm.get('nombre').setValue(this.data.TipoBeca.Nombre);    
      
      if(this.data.TipoBeca.PorProceso){
        this.detalleBecaForm.get('tipoBeca').setValue("1");    
      }else{
        console.log("TipoBeca directa" );
        this.detalleBecaForm.get('tipoBeca').setValue("0");    
      }

      if(this.data.TipoBeca.MostrarEnPropuesta){
        this.detalleBecaForm.get('mostrarEnPropuesta').setValue("1");    
      }else{
        this.detalleBecaForm.get('mostrarEnPropuesta').setValue("0");    
      }
      
      if(this.data.TipoBeca.IncluirEnPresupuesto){
        this.detalleBecaForm.get('incluirEnPresupuesto').setValue("1");    
      }else{
        this.detalleBecaForm.get('incluirEnPresupuesto').setValue("0");    
      }

      this.selectedRequisitos = this.data.TipoBeca.TipoBecaRequisitoes.map(x => x.RequisitoBeca.RequisitoBecaID);

      this.detalleBecaForm.get('requisitos').setValue(this.selectedRequisitos);    
      this.cargarTableEspecificaiones();

      this.selectedSecciones = this.data.TipoBeca.TipoBecaSeccions.map(x => x.Seccion.SeccionID);
      this.detalleBecaForm.get('secciones').setValue(this.selectedSecciones);  
      
      this.detalleBecaForm.get('clave').disable();
      this.detalleBecaForm.get('porcentaje').setValue(this.data.TipoBeca.Porcentaje);    
      this.detalleBecaForm.get('checkPorcentaje').setValue(this.data.TipoBeca.MostrarPorcentaje);
      this.checkPorcentaje = this.data.TipoBeca.MostrarPorcentaje;


      this.data.TipoBeca.TipoBecaRequisitoes.forEach(element => {
        this.validateMinMax(element);
      });

    }
  }

  validarPorcentaje(){
    this.porcentajeErr = false;
    
    var validaPorcentaje = this.detalleBecaForm.get('porcentaje').value;      
    if(validaPorcentaje != null){
      var validaPorcentajeNumber =  Number(validaPorcentaje);      
      if(validaPorcentajeNumber){
        if( validaPorcentajeNumber > 100 || validaPorcentajeNumber < 0){              
          this.porcentajeErr = true;
        }
      }
      else{
        this.porcentajeErr = true;
      }
    }
  }
  
  mostrarPorcentaje(){
    this.checkPorcentaje = this.detalleBecaForm.get('checkPorcentaje').value;     
  }

  cargarTableEspecificaiones() {
    this.requisitoEspecificacion = [];
    if(this.selectedRequisitos != null){
      this.selectedRequisitos.forEach(element => {

        var requisitoBeca = this.data.TipoBeca.TipoBecaRequisitoes.find(r => r.RequisitoBeca.RequisitoBecaID == element);
        if(requisitoBeca != null && requisitoBeca.RequisitoBeca.Especificacion && 
          requisitoBeca.RequisitoBeca.EspecificacionRequisito.RequiereRango){
            this.requisitoEspecificacion.push(requisitoBeca);
        }
      }); 
      this.dataSourceRequisitos = new MatTableDataSource<TipoBecaReq>(this.requisitoEspecificacion);
      this.length = this.dataSourceRequisitos.data.length;
    }
  }
    
  onSubmit(){
    
    if (this.detalleBecaForm.invalid) {
      return;
    }

    this.error = false;

    this.guardando = true;
    var beca = new TipoBeca();

    if (this.data.Operation === 'Editar') {
      beca.TipoBecaID = this.data.TipoBeca.TipoBecaID;
    }

    beca.Clave = this.detalleBecaForm.get('clave').value;
    beca.Nombre = this.detalleBecaForm.get('nombre').value;

    beca.PorProceso = this.detalleBecaForm.get('tipoBeca').value == "1"? true: false;
    beca.IncluirEnPresupuesto = this.detalleBecaForm.get('incluirEnPresupuesto').value == "1" ?  true: false;
    beca.MostrarEnPropuesta = this.detalleBecaForm.get('mostrarEnPropuesta').value == "1"? true: false;
    beca.Porcentaje = this.detalleBecaForm.get('porcentaje').value;
    beca.MostrarPorcentaje = this.detalleBecaForm.get('checkPorcentaje').value;

    this.selectedRequisitos.forEach(element => {

      var requisitoBeca = this.requisitoEspecificacion.find(r => r.RequisitoBeca.RequisitoBecaID == element);
      if(requisitoBeca == null){
        var tipoBecaReq = new TipoBecaReq();
        var req = this.requisitos.find(r => r.RequisitoBecaID == element);
        if(req!= null){
          tipoBecaReq.RequisitoBeca = req;
          this.allRequsitosBeca.push(tipoBecaReq);
        }
      }else{
        this.allRequsitosBeca.push(requisitoBeca);
      }
    });

    beca.TipoBecaRequisitoes = this.allRequsitosBeca;

    beca.TipoBecaSeccions =[];
    var seccionesIds = this.detalleBecaForm.get('secciones').value;
    seccionesIds.forEach(element => {
     var seccion = this.secciones.find(r => r.SeccionID == element);
     var tipoBecaSeccion = new TipoBecaSeccion();
     tipoBecaSeccion.Seccion = seccion;
 
       beca.TipoBecaSeccions.push(tipoBecaSeccion)
    });

    beca.UsuarioID = this.idUsuario;

    this.becaservice.post(beca)
      .pipe(first())
      .subscribe(
      data => {
        if (data.Exito){
          this.guardando = false;
          const dialogData = new ConfirmDialogModel('', "Configuración guardada correctamente", false);

          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData 
          });
          this.dialogRef.close(true);
        } else{
          this.guardando = false;
          this.errorMessage = data.Mensaje;

          const dialogData = new ConfirmDialogModel('ERROR', this.errorMessage, false);

          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData 
          });
        }      
      },
      () => {
        this.guardando = false;
        this.error = true;
        this.errorMessage = "Error al guardar el requisito";
      });
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.detalleBecaForm.reset();
        this.dialogRef.close(false);
      }
    }); 
  }

  chargeSeccions(){
    this.seccionService.seccionesByUser(this.idUsuario).then(secciones => {
      this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
      this.cargandoSecciones = null;

    }).catch(err => {
      this.cargandoSecciones = "(Error)";
      console.log("Error" + err);
    });
  }

  cargarRequisitos(){
    this.requisitoService.comboRequisitos().then(requisitos => {
      this.requisitos = requisitos.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
      this.cargandoRequisitos = null;

    }).catch(err => {
      this.cargandoRequisitos = "(Error)";
      console.log("Error" + err);
    });
  }

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }

    if(this.detalleBecaForm.controls != null && 
      this.detalleBecaForm.controls.userType != null &&
      this.detalleBecaForm.controls.userType.value != null){
      if(this.detalleBecaForm.controls.userType.value.length==this.secciones.length)
        this.allSelectedSecciones.select();
    }
  } 

  toggleAllSelectionRequisito() {
    if (this.allSelectedRequisitos.selected) {
      this.mySelRequisitos.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelRequisitos.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneRequisitos(all){ 
    if (this.allSelectedRequisitos.selected) {  
     this.allSelectedRequisitos.deselect();
     return false;
    }

    if(this.detalleBecaForm.controls != null && 
      this.detalleBecaForm.controls.userType != null &&
      this.detalleBecaForm.controls.userType.value != null){
      if(this.detalleBecaForm.controls.userType.value.length==this.requisitos.length)
        this.allSelectedRequisitos.select();
    }
  } 

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    return event;
  }

  onSelectRequisitos(entrando) {

    if(!entrando){
      this.requisitoEspecificacion = [];
      if(this.selectedRequisitos != null){
        this.selectedRequisitos.forEach(element => {
          var requisito = this.requisitos.find(r => r.RequisitoBecaID == element);
          if(requisito != null && requisito.Especificacion && 
            requisito.EspecificacionRequisito != null  && requisito.EspecificacionRequisito.RequiereRango ){
              var tipoBecaReq = new TipoBecaReq();
              tipoBecaReq.RequisitoBeca = requisito;
              tipoBecaReq.Minimo = requisito.Minimo;
              tipoBecaReq.Maximo = requisito.Maximo;
            this.requisitoEspecificacion.push(tipoBecaReq);
            this.length = this.requisitoEspecificacion.length;
          }
        }); 
        
        this.dataSourceRequisitos = new MatTableDataSource<TipoBecaReq>(this.requisitoEspecificacion);
      }
    }
  }

  verify() {
    if (!this.detalleBecaForm.invalid) {
      this.enableBtn = true;
      return
    }
    else{
      this.enableBtn = false;
    }
  }

  validateMinMax(tipoBecaReq:TipoBecaReq){
    
    if(( tipoBecaReq.RequisitoBeca.Minimo <= tipoBecaReq.Minimo && tipoBecaReq.Minimo <= tipoBecaReq.RequisitoBeca.Maximo)
        && (tipoBecaReq.RequisitoBeca.Minimo <= tipoBecaReq.Maximo && tipoBecaReq.Maximo <= tipoBecaReq.RequisitoBeca.Maximo)){
      tipoBecaReq.Invalido = false;
    }else{
      tipoBecaReq.Invalido = true;
    }

    if(tipoBecaReq.Maximo < tipoBecaReq.Minimo) tipoBecaReq.Invalido = true;

    var valido = true;
    this.requisitoEspecificacion.forEach(x => {
      if(x.Invalido){
        valido = false;
      }
    });

    this.enableBtn = !this.detalleBecaForm.invalid && valido;
  }

  omit_special_char(e: any) {
    if (e.keyCode === 95 || e.key == "_" || e.charCode == 95) {
      return true;
    }
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  number_special_char(e: any) {
    if (/^[0-9\s]*$/.test(e.key) || e.charCode == 46) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }
}
