import { AlumnoPromedio } from './alumnoPromedio';

export class Sobrepaso {

    SobrepasoID: number;
    Comentarios : string;
    NombreOriginal : string;
    MimeType : string;
    NombreFisico : string;
    AlumnoPromedio : AlumnoPromedio;
    Archivo_: File;
    UsuarioID: number;
    TieneArchivo : boolean;
}