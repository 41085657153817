import { Injectable } from '@angular/core';
import { Centro } from '@app/_models/centro';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { Alumno } from '@app/_models/alumno';
import { SolicitudService } from './solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';
import { ResponseRest } from '@app/_models/responseRest';


@Injectable({ providedIn: 'root' })
export class EstudioSolicitudService {

  constructor(private http: HttpClient) {
  }

  datosEmpresasSolicitud(SolicitudID : number): Promise<EstudioSolicitud> {

    var promise =  this.http.get<EstudioSolicitud>(`${environment.apiUrl}/EstudioSolicitud/${SolicitudID}/EmpresaSolicitud`).toPromise();
    console.log("Prom: " + promise);    
    return promise;
  }
    
  requiereEstudio(SolicitudID : number): Promise<boolean> {

    return  this.http.get<boolean>(`${environment.apiUrl}/EstudioSolicitud/${SolicitudID}/RequiereEstudio`).toPromise();
  }

  guardarMensaje(estudioSolicitud : EstudioSolicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/EstudioSolicitud`, estudioSolicitud).toPromise();
  }
}