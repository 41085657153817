import { GradoEducativo } from './gradoEducativo';
import { Seccion } from './seccion';

export class TipoBecaSeccionGrado{

    TipoBecaSeccionID: number;
    TipoBecaSeccionCentroID: number;
    GradoEducativo : GradoEducativo;
    TipoBecaNombre :string;
     SeccionNombre :string;

    Aplicable: boolean;
}