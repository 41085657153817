
import { DescargaDocumentosService } from '@app/_services/descargaDocumentos.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { saveAs } from 'file-saver';
import { Centro } from '@app/_models/centro';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { Ciclo} from '@app/_models/ciclo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { Solicitud } from '@app/_models/solicitud';
import { Empresa } from '@app/_models/empresa';
import { EmpresaService } from '@app/_services/empresa.service';
import { first } from 'rxjs/operators';
import { RetroEstudioSolicitud } from '@app/_models/retroEstudio';

@Component({
  selector: 'app-descarga-documentos',
  templateUrl: './descarga-documentos.component.html',
  styleUrls: ['./descarga-documentos.component.scss']
})

export class DescargaDocumentosComponent implements OnInit {

  displayedColumns: string[] = ['idAlumno', 'nombre', 'nivel', 'grado', 'porcentaje', 'acciones'];

  dataSource = new MatTableDataSource<RetroEstudioSolicitud>();
  descargaDocForm: FormGroup;
  enableBtn = false;
  
  empresas: Empresa[] = [];
  centros: Centro[] = [];
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  grados: GradoEducativo[] = [];
  selectedCentros : Centro [];
  selectedEmpresas : Empresa [];
  selectedRegiones : Region [];  
  selectedSecciones : Seccion [];  
  selectedGrados : GradoEducativo [];  
  ciclosBecas : Ciclo [];  
  solicitudes : Solicitud [];  
  
  cargandoEmpresas = null;
  cargandoCentros = null;
  cargandoRegiones = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;

  idUsuario: number;

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  nombreProspecto_:string = "";
  errorMessageP: string = "";

  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('allSelectedGrados') private allSelectedGrados: MatOption;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;
  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;
  @ViewChild('allSelectedEmpresas') private allSelectedEmpresas: MatOption;
  @ViewChild('mySelEmpresas') mySelEmpresas: MatSelect;

  constructor(
    private authenticationService: AuthenticationService, 
    private empresaService : EmpresaService,
    private regionService:RegionService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private gradoService: GradoEducativoService,
    private cicloBecaService: CicloBecaService,
    private formBuilder: FormBuilder,
    private descargadocumendosService: DescargaDocumentosService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getRegiones();    
    this.getCiclosBecas();
    this.getEmpresas();

    this.descargaDocForm = this.formBuilder.group({
      ciclo: ['',  Validators.required],
      centros: [''],
      regiones: [''],
      secciones: [''],
      grados: [''],
      empresas: [''],
      nombre: [''],
    });
  }
  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  getEmpresas() {
    this.empresaService.consultaEmpresas().then(empresas => {
      this.empresas  = empresas;
      this.cargandoEmpresas = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }

  downloadDocs(RetroEstudioSolicitudID:number, CURP: string) {
    this.descargadocumendosService.download(RetroEstudioSolicitudID,  CURP)
    .subscribe (
      data => {
        saveAs(data, 'estudio_'+CURP + '.zip', {
          type: 'application/zip' 
       });
      }, 
      () => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }
 
  buscar() { 

    var  cicloID=this.descargaDocForm.get('ciclo').value;  

    if(cicloID == null ||  cicloID == 0){
        return;
    }

    var empresas = this.descargaDocForm.get('empresas').value;
    var centros   = this.descargaDocForm.get('centros').value;  
    var regiones   = this.descargaDocForm.get('regiones').value;  
    var secciones   = this.descargaDocForm.get('secciones').value;  
    var grados   = this.descargaDocForm.get('grados').value;  
    var nombre   = this.descargaDocForm.get('nombre').value;  

    this.descargadocumendosService.buscarRetros(this.pageSize, (this.pageIndex+1), cicloID, empresas, centros,regiones, secciones,
      grados, nombre, this.idUsuario).pipe(first())
      .subscribe(
        data => {
          this.length = data.Total;
          this.dataSource = new MatTableDataSource<RetroEstudioSolicitud>(data.Dato);
        },
        () => {
          this.errorMessageP = "Error al obtener la lista de pagos";
        });
  }
  
  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
    }
  }  

  onSelectedRegiones(entrando) {
    if(!entrando){ 
      var regiones   = this.descargaDocForm.get('regiones').value;  
      console.log("Regiones " + regiones);

      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
        this.secciones = [];
        this.grados = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.selectedGrados = [];
        this.descargaDocForm.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      });

    }
  }  

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.descargaDocForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones;
        this.cargandoSecciones = null;

        this.grados = [];
        this.selectedGrados = [];

      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  onSelectedSecciones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.descargaDocForm.get('centros').value;  
      var secciones   = this.descargaDocForm.get('secciones').value;  

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados;
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      });
    }
  }    

  tosslePerOneCiclos(){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.centros.length)
      this.allSelectedCiclos.select();
    }
  } 

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }
    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.centros.length)
        this.allSelectedRegion.select();
    }
  } 

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }
    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }   

  tosslePerOneGrados(all){ 
    if (this.allSelectedGrados.selected) {  
     this.allSelectedGrados.deselect();
     return false;
    }
    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.centros.length)
        this.allSelectedGrados.select();
    }
  }     

  tosslePerOneEmpresas(){ 
    if (this.allSelectedEmpresas.selected) {  
     this.allSelectedEmpresas.deselect();
     return false;
    }
    if(this.descargaDocForm.controls != null && 
      this.descargaDocForm.controls.userType != null &&
      this.descargaDocForm.controls.userType.value != null){
      if(this.descargaDocForm.controls.userType.value.length==this.empresas.length)
        this.allSelectedEmpresas.select();
    }
  }  


  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }
    
  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }
    
  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }    
 
  toggleAllSelectionSeccion() {
      if (this.allSelectedSecciones.selected) {
        this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
      } else {
        this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
      }
      //this.mySelInstitucion.close();
  }     

  toggleAllSelectionGrado() {
      if (this.allSelectedGrados.selected) {
        this.mySelGrado.options.forEach( (item : MatOption) => item.select());
      
      } else {
        this.mySelGrado.options.forEach( (item : MatOption) => {item.deselect()});
      }
      //this.mySelInstitucion.close();
  }         


  toggleAllSelectionEmpresas() {
    if (this.allSelectedEmpresas.selected) {
      this.mySelEmpresas.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelEmpresas.options.forEach( (item : MatOption) => {item.deselect()});
    }

  }

  omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  verify() {
    if (!this.descargaDocForm.invalid) {
      this.enableBtn = true;
    } 
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    this.buscar();

    return event;
  }
} 