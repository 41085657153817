export class AvancePropuesta {

    PropuestaBecaID: number;
    DatosGenerales: boolean;
    Alumnos: boolean;
    Cuotas: boolean;
    PropuestaBecas: boolean;
    ResumenBecas: boolean;
    Analisis: boolean;
  
  }