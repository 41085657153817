export class RevisionRequisitosParam{

    cicloID : number;
    empresas : string[];
    centros : string[];
    regiones : number[];
    secciones : number[];
    grados : string[];
    nombre : string;
    numRegistros : number;
    pagina : number;

}