import { Empresa } from './empresa';
import { EstatusEstudio } from './estatusEstudio';
import { EstudioEmpresa } from './estudioEmpresa';
import { Solicitud } from './solicitud';



export class EstudioSolicitud{

    EstudioSolicitudID: number;
    Solicitud : Solicitud;
    EstatusEstudio : EstatusEstudio;
    Empresa : Empresa;
    ContenidoCorreo : string;
    EstudioEmpresa: EstudioEmpresa;
}
