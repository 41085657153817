import { AlertaComponent, ConfirmDialogModel } from "@app/_components/alerta/alerta.component";

export function onNoClick(): void {
  const dialogData = new ConfirmDialogModel("Al salir se perderán todos los cambios que no han sigo guardados", '¿Desea salir?');
  const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
    maxWidth: "400px",
    data: dialogData
  });

  dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      this.dialogRef.close(false);
    }
  }); 
}