import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Solicitud } from '@app/_models/solicitud';
import { ResponseRest } from '@app/_models/responseRest';
import { AuthenticationService } from './authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';
import { Historial } from '@app/_models/historial';
import { CalificacionRequisitoSolicitud } from '@app/_models/calificacionRequisitoSolicitud';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {

  private currentSolicitudSubject: BehaviorSubject<Solicitud>;
  public currentSolicitud: Observable<Solicitud>;    

  constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) { 
          this.currentSolicitudSubject = new BehaviorSubject<Solicitud>(null);
          this.currentSolicitud = this.currentSolicitudSubject.asObservable();}

  public get currentSolicitudValue(): Solicitud {
    if(this.currentSolicitudSubject.value == null){
      let solicitud = this.obtenerSolicitudLocalStorage();
      if(solicitud != null){
        this.currentSolicitudSubject.next(solicitud)
      }
    }
    return this.currentSolicitudSubject.value;
  }      
  
  obtenerSolicitudLocalStorage(): Solicitud{
    var  solicitudIDStr = sessionStorage.getItem("solicitudID");
    if(solicitudIDStr != null && solicitudIDStr != "" &&  solicitudIDStr!= "null"){
      var solicitud = new Solicitud();
      solicitud.SolicitudID = Number(solicitudIDStr);
      return solicitud;
    }
    return null;
  }

  almacenarSolicitud(solicitud :Solicitud){
    sessionStorage.setItem("solicitudID", solicitud.SolicitudID.toString());
    this.currentSolicitudSubject = new BehaviorSubject<Solicitud>(solicitud);
    this.currentSolicitud = this.currentSolicitudSubject.asObservable();
  }

  limpiarSolicitud(){
    sessionStorage.removeItem('solicitudID');
    this.currentSolicitudSubject.next(null);
  }
      
  guardarSeleccionBeca(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/SeleccionBeca`, solicitud).toPromise();
  }

  guardarGeneralesBeca(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/SolicitudGenerales`, solicitud).toPromise();
  }

  obtenerSolicitudesAlumno(){
    let AlumnoID = this.authenticationService.currentUserValue.AlumnoID;

    return this.http.get<Solicitud[]>(`${environment.apiUrl}/Solicitud/${AlumnoID}/ObtenerSolicitudes`);
  }  

  tieneAdeudos(){
    let AlumnoID = this.authenticationService.currentUserValue.AlumnoID;
    return this.http.get<boolean>(`${environment.apiUrl}/Alumno/${AlumnoID}/TieneAdeudo`).toPromise();
  }  

  enviarSolictud(solicitud : Solicitud): Promise<ResponseRest<string>> {
    solicitud.EstatusSolicitud = new EstatusSolicitud();
    solicitud.EstatusSolicitud.EstatusSolicitudID = 2;
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Solicitud/EnviarSolicitud`, solicitud).toPromise();  
  }


  ObtenerHistorial(SolicitudID : number){
    return this.http.get<Historial[]>(`${environment.apiUrl}/${SolicitudID}/HistorialSolicitud`).toPromise();
  }

  permitirSolicitud( AlumnoID : number){
    return this.http.get<ResponseRest<boolean>>(`${environment.apiUrl}/${AlumnoID}/PermitirSolicitud` ).toPromise();
  }    

  becaExtemporanea( AlumnoID : number, CicloId : number){
    return this.http.get<BecaExtemporanea>(`${environment.apiUrl}/${AlumnoID}/${CicloId}/BecaExtemporanea` ).toPromise();
  }    

  esAlumnoMoroso( AlumnoID : number){
    return this.http.get<boolean>(`${environment.apiUrl}/${AlumnoID}/EsMoroso` ).toPromise();
  }    

  delete(solicitud: Solicitud){

    let params = new HttpParams();
    params = params.append('SolicitudID', solicitud.SolicitudID.toString() );

    return this.http.delete<ResponseRest<null>>(`${environment.apiUrl}/Solicitud/`, { params } );
  } 

  obtenerCalificacionEnsayo(solicitud : Solicitud): Promise<ResponseRest<CalificacionRequisitoSolicitud>> {
    return this.http.post<ResponseRest<CalificacionRequisitoSolicitud>>(`${environment.apiUrl}/RevisionRequisitos/ObtenerCalificacionesEnsayo`, solicitud).toPromise();
  }

  guardarCalificacionEnsayo(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/RevisionRequisitos/GuardarCalificacionEnsayo`, solicitud).toPromise();
  }

  obtenerCalificacionPruebaAptitud(solicitud : Solicitud): Promise<ResponseRest<CalificacionRequisitoSolicitud>> {
    return this.http.post<ResponseRest<CalificacionRequisitoSolicitud>>(`${environment.apiUrl}/RevisionSolicitudes/ObtenerCalificacionesPruebaApt`, solicitud).toPromise();
  }

  guardarCalificacionPruebaAptitud(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/RevisionSolicitudes/GuardarCalificacionPruebaApt`, solicitud).toPromise();
  }

  importarPruebaAptitud(archivos: FormData, SolicitudID: number) {
      
    let params = new HttpParams();
    params = params.append('SolicitudID', SolicitudID.toString() );
    //params = params.append('UsuarioID', UsuarioID.toString() );

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/RevisionSolicitudes/GuardarPuebaAptitud`, archivos, { params } );
  }

  obtenerRequisito(clave :string):Promise<RequisitoBeca>{
    return this.http.get<RequisitoBeca>(`${environment.apiUrl}/${clave}/GetRequsito`).toPromise();
  }

  enviarcorreoRecordatorio(SolicitudID : number){
    let AlumnoID = this.authenticationService.currentUserValue.AlumnoID;
    return this.http.get<ResponseRest<any>>(`${environment.apiUrl}/${AlumnoID}/${SolicitudID}/EnviarCorreoRecordatorio`).toPromise();
  }  

  procesadasXCiclo(AlumnoID : number, CicloId : number){
    return this.http.get<ResponseRest<boolean>>(`${environment.apiUrl}/${AlumnoID}/${CicloId}/ProcesadasXCiclo` ).toPromise();
  }   

  descargarReglamento(AlumnoID : number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Solicitud/${AlumnoID}/ObtenerReglamento`, {});
  }
}