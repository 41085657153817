import { Component, OnInit, ViewChild  } from '@angular/core';
import { PropuestaBecaTabsComponent } from '../_components/propuesta-beca-tabs/propuesta-beca-tabs.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { PropuestaBeca, PropuestaBecaOperation } from '@app/_models/propuesta-beca';
import { MatTableDataSource} from '@angular/material/table';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service'
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { HistorialPropuestaBecaComponent } from '@app/_components/historial-prop-beca/historial-prop-beca.component';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-propuestas-becas',
  templateUrl: './propuestas-becas.component.html',
  styleUrls: ['./propuestas-becas.component.scss']
})
export class PropuestasBecasComponent implements OnInit {

  constructor(public dialog: MatDialog, 
    private authenticationService: AuthenticationService, 
    private propuestaBecaService:PropuestaBecaService) { }

  displayedColumns: string[] = [ 'Ciclo', 'Centro', 'MontoAprobado', 'costo', 'porcentaje', 'acciones'];

  dataSource = new MatTableDataSource<PropuestaBeca>();

  errorMessage: string = "";

  pageEvent: PageEvent;
  
  pageIndex:number = 0;
  pageSize:number = 5;
  length:number = 0

  filtro: string = "";
  buscando : boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  error: boolean = false;
  idUsuario: number;

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.dataSource.paginator = this.paginator;
    this. buscar()

  }

  nuevaPropuesta(): void {
    const dialogRef = this.dialog.open(PropuestaBecaTabsComponent, {
      width: '95%',
      data: {Operation: 'NUEVO'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscar();
    });
  } 

   verHistorial(propuesta:PropuestaBeca){
     const dialogRef = this.dialog.open(HistorialPropuestaBecaComponent, {
       width: '80%',
       data: {Operation: 'Editar', Objeto: propuesta}
     });
   dialogRef.afterClosed().subscribe(result => {

   });
 }

  buscar() { 
    console.log("Buscar");
    this.propuestaBecaService.listadoPropuestas(this.pageSize, (this.pageIndex+1), this.filtro, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        const order = data.Dato.sort((a, b) => new Date(b.Fecha).getTime() - new Date(a.Fecha).getTime())
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<PropuestaBeca>(order);
      },
      error => {
        this.errorMessage = "Error al obtener la lista de propuestas";
      });
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    this.buscar();

    return event;
  }

  filterText(){
    this.pageIndex = 0;    
    if(!this.buscando){
      this.buscando = true;
      setTimeout (() => {
        this.buscar();
        this.buscando = false;
      }, 2000);   

    }
  }  

  editarPropuesta(propuesta:PropuestaBeca): void {
    const dialogRef = this.dialog.open(PropuestaBecaTabsComponent, {
      width: '98%',
      data: {Operation: 'Editar', Objeto: propuesta}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("result "+ JSON.stringify(result));
      this.buscar();
      if(result){
      }
    });
  }

  eliminarPropuesta(propuesta:PropuestaBeca): void {

    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la propuesta?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        console.log("Propuesta " + propuesta.PropuestaBecaID);
        this.propuestaBecaService.delete(propuesta)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscar();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
              });
            }            
          },
          error => {
            this.error = true;
            this.errorMessage = "Error al eliminar el requisito";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedio un error al eliminar el requisito.', false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
            });
          });
      }
    });    
  }
}