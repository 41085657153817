import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OperationResult } from '@app/_models/operationResult';
import { Usuario_ } from '@app/_models/usuario_';

@Injectable({
  providedIn: 'root'
})
export class RecuperaCuentaService {

  constructor(private http: HttpClient) { }

  recuperaCuentaEmpresa(email: string){
    let params = new HttpParams();
    params = params.append('email', email );
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuentaEmpresa`, null, { params } );
  }

  recuperaCuentaAlumno(curp: string, fechaNacimiento: Date){
    let params = new HttpParams();
    params = params.append('curp', curp );
    params = params.append('fechaNacimiento', fechaNacimiento.toDateString() );

    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuentaAlumno`, null, { params } );
  }


  recuperaCuentaEmpleado(email: string, numeroEmpleado: number){
    let params = new HttpParams();
    params = params.append('email', email );
    params = params.append('numeroEmpleado', numeroEmpleado.toString() );
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuentaEmpleado`, null, { params } );
  }


  cambiarContrasena(usuario: Usuario_){
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuenta/CambiarContrasena`, usuario );
  }

  verificaToken(usuario: Usuario_){
    let params = new HttpParams();
    params = params.append('token', usuario.Token );
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/RecuperaCuenta/VerificaToken`, null, { params } );
  }

}
