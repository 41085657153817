import { MatTableDataSource } from '@angular/material/table';
import { Solicitud } from './solicitud';
export class PropuestaSolicitud{

    PropuestaBecaSolicitudID : number;
    Solicitud : Solicitud;
    CostoColegiatura : number;
    CostoReinscripcion : number;
    CostoInscripcion : number;
    Colegiaturas : number;
    PorcentajeIncCol :number;
    PorcentajeIncIns :number;
    PorcentajeIncReinscripcion :number;

    BecaPropuesta : number;
    BecaSugerida : number;
    BecaAprobada : number;
    BecaOriginal : number;
    AplicaReinscripcion : boolean;
    AplicaReinscripcionAprob : boolean;
    AplicaInscripcion : boolean;
    AplicaInscripcionAprobada : boolean;
    Comentarios : string;
    UsuarioID: number;

    CalificacionAptitud : number;
    CalificacionEnsayo : number;

    CostoAnualBecaPropuesto : number;
    CostoAnualBecaAprobado : number;

    TieneComentarios : boolean;

    EditarComentarios : boolean;

    EsExterna : boolean;
    Condicionada : boolean;
    BecaSep : boolean;
    EsMoroso : boolean;

    detalleTable :  MatTableDataSource<PropuestaSolicitud>;
}