import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodigoPostalService } from '@app/_services/codigo-postal.service';
import { Colonia } from '@app/_models/colonia';
import { Estado } from '@app/_models/estado';
import { Municipio } from '@app/_models/municipio';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-buscar-codigo-postal',
  templateUrl: './buscar-codigo-postal.component.html',
  styleUrls: ['./buscar-codigo-postal.component.scss']
})
export class BuscarCodigoPostalComponent implements OnInit,AfterViewInit {

  estados: Estado[] = [];
  filteredEstados: Observable<Estado[]>;  
  municipios: Municipio[] = [];
  filteredMunicipios: Observable<Municipio[]>;    
  colonias: Colonia[] = [];
  filteredColonias: Observable<Colonia[]>;      
  
  selectedEstadoID : string;
  selectedMunicipioID : string;  
  selectedColoniaID : number;  
  buscarCPForm: FormGroup;

  constructor(private formBuilder: FormBuilder, 
    public dialog: MatDialogRef<BuscarCodigoPostalComponent>,
    private codigoPostalService: CodigoPostalService,
    @Inject(MAT_DIALOG_DATA) public colonia: Colonia) { 
    }

  ngOnInit(): void {
    this.buscarCPForm = this.formBuilder.group({
      estado: [{ value: '' }, Validators.required],
      municipio: [{ value: '' }, Validators.required],
      colonia: [{ value: '' }, Validators.required],
      codigoPostal: [{ value: '', disabled: true, }, Validators.required]
    });

    this.buscarCPForm.get('municipio').setValue('');     
    this.buscarCPForm.get('colonia').setValue('');      
    
    this.codigoPostalService.consultaEstados().then(estados => {
      this.estados = estados;
      this.filteredEstados = this.buscarCPForm.get('estado').valueChanges.pipe(startWith(''), 
                    map( value =>  this._filterEstados(value)));      
       this.buscarCPForm.get('estado').setValue('');    

       if(this.colonia != null){
         this.buscarCPForm.get('estado').setValue(this.colonia.Municipio.Estado.Nombre); 
         this.codigoPostalService.consultaMunicipios(this.colonia.Municipio.Estado.EstadoID).then(municipios => {
          this.municipios = municipios;
          this.filteredMunicipios = this.buscarCPForm.get('municipio').valueChanges.pipe(startWith(''), map( value =>  this._filterMunicipios(value)));      
          this.buscarCPForm.get('municipio').setValue(this.colonia.Municipio.Nombre);           
        }).catch(err => {
          console.log("Error" + err);
        });

        this.codigoPostalService.consultaColonias(this.colonia.Municipio.MunicipioID).then(colonias => {
          this.colonias = colonias;
          this.filteredColonias = this.buscarCPForm.get('colonia').valueChanges.pipe(startWith(''), map( value =>  this._filterColonias(value)));      
          this.buscarCPForm.get('colonia').setValue(this.colonia.Nombre);                     
          this.buscarCPForm.get('codigoPostal').setValue(this.colonia.CodigoPostal);                
        }).catch(err => {
          console.log("Error" + err);
        });


       }

       if(this.colonias != null && this.colonia.ColoniaID != 0){
        this.buscarCPForm.get('estado').setValue(this.colonia.Municipio.Estado.Nombre); 
        this.buscarCPForm.get('municipio').setValue(this.colonia.Municipio.Nombre);           
        this.buscarCPForm.get('colonia').setValue(this.colonia.Nombre);                     
        this.buscarCPForm.get('codigoPostal').setValue(this.colonia.CodigoPostal);                
  
      }
   
   
    }).catch(err => {
      console.log("Error" + err);
    });

  }

  ngAfterViewInit() {
  }

  

  cancelar(): void {
    this.dialog.close(false);
  }

  aceptar(): void {

    var colonia = this.buscarCPForm.get('colonia').value;   
    const ColoniaNombre = colonia ? ((typeof colonia === 'string') ? colonia : colonia.Nombre):"";
    const setVal = this.colonias.find( op => op.Nombre === ColoniaNombre);

    this.dialog.close(setVal);

  }
  

  private _filterEstados(value: any): Estado[] {
    const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : value.Nombre.toLowerCase()) : "";
    return this.estados.filter(option => option.Nombre.toLowerCase().startsWith(filterValue));
  } 

  private _filterMunicipios(value: any): Municipio[] {
    const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : value.Nombre.toLowerCase()) : "";
    return this.municipios.filter(option => option.Nombre.toLowerCase().startsWith(filterValue));
  }   
  
  private _filterColonias(value: any): Colonia[] {
    const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : value.Nombre.toLowerCase()) : "";
    return this.colonias.filter(option => option.Nombre.toLowerCase().startsWith(filterValue));
  }     

  selectedEstadoOption(event) {
    this.selectedEstadoID = event.source.value.EstadoID;
    const setVal = this.estados.find( op => op.EstadoID === this.selectedEstadoID);
     this.buscarCPForm.get('estado').setValue(setVal.Nombre); 
     this.buscarCPForm.get('municipio').setValue(''); 
     this.buscarCPForm.get('colonia').setValue(''); 
     this.buscarCPForm.get('codigoPostal').setValue('');         

     this.codigoPostalService.consultaMunicipios(this.selectedEstadoID).then(municipios => {
       this.municipios = municipios;

       this.filteredMunicipios = this.buscarCPForm.get('municipio').valueChanges.pipe(startWith(''), map( value =>  this._filterMunicipios(value)));      

     }).catch(err => {
       console.log("Error" + err);
     });


 }  

 selectedMunicipioOption(event) {
  this.selectedMunicipioID = event.source.value.MunicipioID;
  const setVal = this.municipios.find( op => op.MunicipioID === this.selectedMunicipioID);
   this.buscarCPForm.get('municipio').setValue(setVal.Nombre); 

   this.codigoPostalService.consultaColonias(this.selectedMunicipioID).then(colonias => {
     this.colonias = colonias;
     this.filteredColonias = this.buscarCPForm.get('colonia').valueChanges.pipe(startWith(''), map( value =>  this._filterColonias(value)));      
     this.buscarCPForm.get('colonia').setValue('');    
     this.buscarCPForm.get('codigoPostal').setValue('');           

   }).catch(err => {
     console.log("Error" + err);
   });


}  


selectedColoniaOption(event) {
  this.selectedColoniaID = event.source.value.ColoniaID;
  const setVal = this.colonias.find( op => op.ColoniaID === this.selectedColoniaID);
  console.log("Selected" + JSON.stringify(setVal));
   this.buscarCPForm.get('colonia').setValue(setVal.Nombre); 
   this.buscarCPForm.get('codigoPostal').setValue(setVal.CodigoPostal);      

}  



}
