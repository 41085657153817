<mat-card-title class="text-center color-rojo rob-19">Buscar código postal por colonia</mat-card-title>
<form    [formGroup]="buscarCPForm" class="form" name="buscarCPForm" id="buscarCPForm">  

  <mat-form-field  class="full-width rob-15">
      <mat-label>Estado </mat-label>
      <input matInput placeholder="estado " aria-label="estado" [matAutocomplete]="auto" formControlName="estado" >
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option id="est" name="est" *ngFor="let option of filteredEstados | async"  [value]="option" (onSelectionChange)="selectedEstadoOption($event)">
          {{option.Nombre}}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>
  <br>

  <mat-form-field  class="full-width rob-15">
    <mat-label>Municipio </mat-label>
    <input matInput placeholder="municipio" aria-label="municipio" [matAutocomplete]="auto1" formControlName="municipio" >
    <mat-autocomplete #auto1="matAutocomplete">
      <mat-option id="mun" name="mun" *ngFor="let option of filteredMunicipios | async"  [value]="option" (onSelectionChange)="selectedMunicipioOption($event)">
        {{option.Nombre}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <br>

  <mat-form-field  class="full-width rob-15">
    <mat-label>Colonia </mat-label>
    <input matInput placeholder="colonia" aria-label="colonia" [matAutocomplete]="auto2" formControlName="colonia" >
    <mat-autocomplete #auto2="matAutocomplete">
      <mat-option id="mun" name="mun" *ngFor="let option of filteredColonias | async"  [value]="option" (onSelectionChange)="selectedColoniaOption($event)">
        {{option.Nombre}}
      </mat-option>
    </mat-autocomplete>  
  </mat-form-field>

  <mat-form-field class="full-width rob-15">
    <mat-label>Código postal</mat-label>
    <input matInput   id="codigoPostal" name="codigoPostal" formControlName="codigoPostal"   >
  </mat-form-field>

  <table>
      <tr>
        <td>
            <button mat-raised-button class="bg-celeste" (click)="aceptar()">Aceptar</button>                                                       
        </td>
        <td>
          <button mat-raised-button (click)="cancelar()">Cancelar</button>
        </td>
      </tr>
  </table>
</form>    