<mat-grid-list *ngIf="!isAnahuac" rowHeight="7em" [cols]="breakpoint" (window:resize)="onResize($event)">
  <mat-grid-tile class="mat-figure" [colspan]="imgCol" [rowspan]="imgRow">
    <div class="mat-figure">
      <img style="width: 100%;" src="/assets/SemperAltus/portada.png" />
    </div>
  </mat-grid-tile>
  <mat-grid-tile rowspan="5" style="background-color: #DEEBF7; height: calc(30em + 4px);"> 
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="ALUMNO">
        <div style="height: 25rem;">
          <br><br>
          <div class="text-center">
            <mat-card-title class="text-center fz-26">Inicio de sesión.</mat-card-title>
          </div>
          <div>
            <form class="width-form">
              <div class="full-width field-container">
              <div class="text-left">
                <label class="text-left color-azul fz-15">CURP:</label>
              </div>
              <input type="text" [(ngModel)]="usuarioAlumno" name="usuario" required>
              <br/>
              <div class="text-left">
                <label class="text-left color-azul fz-15">Contraseña:</label>
              </div>
              <div class="input-container">
                <input [(ngModel)]="passwordAlumno" [type]="hide ? 'password' : 'text'" name="password" required>
                <span>
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </span>
              </div>
              <br><br>
              </div>
                <button type="button" mat-raised-button class="button" (click)="login()">ENTRAR</button>
              <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
              {{errorMessage}}
              </div>
            </form>
          </div> 
          <br>
          <div class="text-center color-azul fz-16">
            <label>
              <a href="#" routerLink="/registro">Si eres nuevo, regístrate aquí</a>
            </label>
            <br>
            <label>
              <a href="#" routerLink="/recupera-cuenta">Recupera tu cuenta aquí</a>
            </label>
          </div> 
        </div>
      </mat-tab>
      <mat-tab label="EMPLEADO">
        <div style="height: 25rem;">
          <br><br>
          <div class="text-center">
            <mat-card-title class="text-center fz-26">Inicio de sesión.</mat-card-title>
          </div>
          <div>
            <form class="width-form">
              <div class="full-width field-container">
                <div class="text-left">
                  <label class="text-left color-azul fz-15">Correo:</label>
                </div>
                <input type="text" [(ngModel)]="usuarioEmpleado" name="usuario" required>
                <br/>
                <div class="text-left">
                  <label class="text-left color-azul fz-15">Contraseña:</label>
                </div>
                <div class="input-container">
                  <input [(ngModel)]="passwordEmpleado" [type]="hide ? 'password' : 'text'" name="password" required>
                  <span>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </span>
                </div>
                <br><br>
              </div>
              <button type="button" mat-raised-button class="button" (click)="loginEmpleado()">ENTRAR</button>
              <br><br>
              <div class="text-center color-azul fz-16">
                <a href="#" routerLink="/recupera-cuenta-empleado">Recupera tu cuenta aquí</a>
              </div> 
              <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
              {{errorMessage}}
              </div>
            </form> 
          </div> 
        </div>
      </mat-tab>
      <mat-tab label="EMPRESA">
        <div style="height: 25rem;">
          <br><br>
          <div class="text-center">
            <mat-card-title class="text-center fz-26">Inicio de sesión.</mat-card-title>
          </div>
          <div>
            <form class="width-form">
              <div class="full-width field-container">
                <div class="text-left">
                  <label class="text-left color-azul fz-15">Correo:</label>
                </div>
                <input type="text" [(ngModel)]="usuarioEmpresa" name="usuario" required>
                <br/>
                <div class="text-left">
                  <label class="text-left color-azul fz-15">Contraseña:</label>
                </div>
                <div class="input-container">
                  <input [(ngModel)]="passwordEmpresa" [type]="hide ? 'password' : 'text'" name="password" required>
                  <span>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </span>
                </div>
                <br><br>
              </div>
              <button type="button" mat-raised-button class="button" (click)="loginEmpresa()">ENTRAR</button>
              <br><br>
              <div class="text-center color-azul fz-16">
                <a href="#" routerLink="/recupera-cuenta-empresa">Recupera tu cuenta aquí</a>
              </div> 
              <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
              {{errorMessage}}
              </div>
            </form>
          </div> 
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-grid-tile>
</mat-grid-list>

<mat-grid-list *ngIf="isAnahuac" rowHeight="7em" [cols]="breakpoint" (window:resize)="onResize($event)">
  <mat-grid-tile class="mat-figure" [colspan]="imgCol" [rowspan]="imgRow">
    <div class="mat-figure">
      <img style="width: 100%;" src="/assets/SemperAltus/PA_PlataformadeBecas1.jpg" />
    </div>
  </mat-grid-tile>
  <mat-grid-tile rowspan="5" style="background-color: #DEEBF7; height: calc(30em + 4px);"> 
    <div class="text-center">
      <mat-card-title class="text-center fz-30 popp-bold">PORTAL DE BECAS.</mat-card-title>
      <br><br><br>
      <div class="text-left">
        <label class="color-obscuro fz-16 popp-med labelAnahuac">CURP:</label>
      </div>
      <br>
      <input type="text" placeholder="Correo" [(ngModel)]="usuarioAlumno" name="usuario" required>
      <br/>
      <div class="text-left">
        <label class="color-obscuro fz-16 popp-med labelAnahuac">Contraseña:</label>
      </div>
      
      <input placeholder="Contraseña" [(ngModel)]="passwordAlumno" type="password" name="password" required>
      <br><br>
      <button type="button" mat-raised-button class="buttonAnahuac" (click)="login()">ENTRAR</button>
      <br><br>
      <div class="text-center color-azul fz-18">
        <a href="#" routerLink="/registro">Si eres nuevo, regístrate aquí</a>
      </div> 
      <div class="text-center color-azul fz-16">
        <a href="#" routerLink="/recupera-cuenta">Recupera tu cuenta aquí</a>
      </div> 
      <br>
      <div *ngIf="error" class="alerta alerta-warning text-center full-width" style="margin: 0.5rem 0;">
      {{errorMessage}}
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>