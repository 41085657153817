export function getSecciones() {
  if(this.data != null && this.data.Objeto != null){
    this.propuesta = this.data.Objeto;
    var centro = this.propuesta.Centro.CentroID;
    this.seccionService.consultaSecciones(centro).then(secciones => {
      this.secciones = secciones;
    }).catch(err => {
      console.log("Error" + err);
    });
  } 
}