import { CicloTipoBeca } from './cicloTipoBeca';

export class Ciclo{

    CicloID: number;
    Clave: string;
    Nombre: string;
    FechaInicio : Date;
    FechaFin : Date;    
    FechaSolicitudIni : Date;
    FechaSolicitudFin : Date;
    FechaRevisionSolIni : Date;
    FechaRevisionSolFin : Date;
    FechaRetroSolIni : Date;
    FechaRetroSolFin : Date;
    FechaEstudioIni : Date;
    FechaEstudioFin : Date;
    FechaEvaluacionIni : Date;
    FechaEvaluacionFin : Date;
    FechaCreacionPropIni : Date;
    FechaCreacionPropFin : Date;
    FechaRevisionPropIni : Date;
    FechaRevisionPropFin : Date;
    FechaRetroPropIni : Date;
    FechaRetroPropFin : Date;
    FechaPublicacionIni : Date;
    FechaPublicacionFin : Date;
    FechaCreacion : Date;
    FechaSolicitudIni2 : Date;
    FechaSolicitudFin2 : Date;

    CicloTipoBecas : CicloTipoBeca[];
    UsuarioID : number;
    
  }
  