<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">REVISIÓN DE SOLICITUDES.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br> 
  <div class="mdl-form">
    <mat-label class="color-azul">Filtrar por:</mat-label>
  </div>
  <form class="mdl-form" name="revisionSolicitudForm" id="revisionSolicitudForm" [formGroup]="revisionSolicitudForm">      
  
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Ciclo</mat-label>
      <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" id="ciclo" (openedChange)="onSelectedCiclos($event)" >
        <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
            {{ciclo.Clave}}
        </mat-option> 
      </mat-select>
    </mat-form-field>    
    <mat-form-field class="inpunt-width xs-width"  appearance="outline">
      <mat-label>Regiones</mat-label>
      <mat-select #mySelRegion name="ciclos" formControlName="regiones" id="regiones" (openedChange)="onSelectedRegiones($event)" multiple>
        <mat-option #allSelectedRegiones (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
          (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
          {{region.Nombre}}
        </mat-option>
      </mat-select>   
    </mat-form-field>  

    <mat-form-field  class="input-centro-width xs-width" appearance="outline">
      <mat-label>Colegios</mat-label>
      <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
        <mat-option #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
        <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
          (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
          {{centro.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Secciones</mat-label>
      <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones" (openedChange)="onSelectedSecciones($event)" multiple>
          <mat-option #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
          <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
            (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
            {{seccion.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   

    <mat-form-field appearance="outline" class="inpunt-width xs-width" >
      <mat-label>Estatus</mat-label>
      <mat-select #mySelEstatus name="estatus" formControlName="estatus"  
          id="estatus"  multiple>
          <mat-option  #allSelectedEstatus  (click)="toggleAllSelectionEstatus()" [value]="0"><label>Todos</label></mat-option>
          <mat-option *ngFor="let est of estatusSol" [value]="est.EstatusSolicitudID" 
                      (click)="tosslePerOneEstatus(allSelectedEstatus.viewValue)"> 
                      {{est.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="inpunt-width xs-width" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre del prospecto" value="" id="nombreProspecto"
      (keypress)="omit_special_char($event)" name="nombreProspecto" formControlName="nombreControl" >
    </mat-form-field>
    
    <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
  </form>
  <br><br>
  <div class="mdl-form mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Fecha de envío </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="colegio" class='column-id-prospecto'>
          {{element.FechaEnvio  | date: 'dd-MM-yyyy'}}
        </mat-cell>
      </ng-container>      
        
      <ng-container matColumnDef="colegio">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Colegio </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="colegio" class='column-id-prospecto'>
          {{element.Centro.Nombre}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="seccion">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Sección </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="seccion">
          {{element.Seccion.Nombre}}
        </mat-cell>
      </ng-container>
          
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef class="sub-title"> CURP </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="id"> 
          {{element.Alumno.CURP}} 
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre del alumno</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="nombre"> 
          {{element.Alumno.Nombre}} {{element.Alumno.ApellidoPaterno}}  {{element.Alumno.ApellidoMaterno}}
        </mat-cell>
      </ng-container>    

      <ng-container matColumnDef="estatus">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Estatus</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="nombre"> 
          {{element.EstatusSolicitud.Nombre}}
        </mat-cell>
      </ng-container>      

      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
            <button mat-icon-button color="basic" aria-label="Ver solicitud" matTooltip="Ver solicitud" matTooltipPosition="right" (click)="verSolicitud(element)">
              <mat-icon>visibility</mat-icon></button>
            <button mat-icon-button color="basic" aria-label="Descargar documentos" matTooltip="Descargar solicitud" (click)="descargar(element)" matTooltipPosition="right">
              <mat-icon>cloud_download</mat-icon></button>
            <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element)" matTooltip="Historial">
              <mat-icon>history</mat-icon></button>  
            <button mat-icon-button color="basic"
              [disabled]="!element.Comentarios"
              aria-label="Ver comentarios empresa" matTooltip="Comentarios empresa" matTooltipPosition="right" (click)="verComentarios(element)">
              <mat-icon>insert_comment</mat-icon></button>
            <button mat-icon-button color="basic" aria-label="Carga de prueba de aptitud" 
              (click)="agregarCalificacion(element)"  [disabled]="!element.RequierePruebaAptitud"
              matTooltip="Cargar prueba de aptitud y calificación"  matTooltipPosition="right" >
            <mat-icon>add</mat-icon></button>          
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>

    </mat-table>     
    
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
    </div>
    
    <mat-paginator #paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 20]"
      (page)="pageEvent = getServerData($event)" >
    </mat-paginator>
  </div>         
  <br><br>
</mat-card>