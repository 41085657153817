import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { SeccionService } from '@app/_services/seccion.service';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { CentroService } from '@app/_services/centro.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Solicitud } from '@app/_models/solicitud';
import { DatePipe } from '@angular/common'
import { DatosGeneralesService } from '@app/_services/datosGenerales.service';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SolicitudService } from '@app/_services/solicitud.service';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { CicloService } from '@app/_services/ciclo.service';
import { Ciclo } from '@app/_models/ciclo';

@Component({
  selector: 'app-datos-generales',
  templateUrl: './datos-generales.component.html',
  styleUrls: ['./datos-generales.component.scss']
})
export class DatosGeneralesComponent implements OnInit {
  datosGeneralesForm: FormGroup;

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  grados : GradoEducativo [] = [];

  AlumnoID : number;
  Ciclo: Ciclo;
  
  tipoBeca = ''; 
  fechaExtemporanea = null;
  curpError = false;
  errorSecundaria: boolean = false;
  Solicitud : Solicitud;
  fechaSolicitudFin: Date;
  idUsuario : number;
  AlumnoPromedio : AlumnoPromedio;
  isAnahuac: boolean = false;
  Admision : boolean = false;
  guardando: boolean = false;
  
  constructor(private formBuilder: FormBuilder,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private gradoEducativoService: GradoEducativoService,
    private authenticationService: AuthenticationService,
    private seleccionBecaService : SeleccionBecaService,
    private cicloService : CicloService,
    private datosGeneralesService : DatosGeneralesService,
    private solicitudService : SolicitudService,
    public datepipe: DatePipe,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });
    
    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      this.becaExtemporanea();
      }).catch(err => {
      console.log("Error" + err);
    }); 

    this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{
      this.tipoBeca = Solicitud.TipoBeca.Nombre
    }).catch(err => {
      console.log("Error" + err);
    }); 
    
    if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
     this.router.navigate(['/seleccion-beca']);
    }else{
      console.log("Fecha fin " + this.Solicitud.FechaFin);
      this.fechaSolicitudFin = this.Solicitud.FechaFin;
      
      this.datosGeneralesForm = this.formBuilder.group({
        matricula:        [  { value: '', disabled: true, } ],
        primerApellido:   [ '', Validators.required ],
        nombre:           [ '', Validators.required ],
        segundoApellido:  [''],
        curp:             [{value: '', disabled: true }, Validators.required],
        fechaNacimiento:  ['',Validators.required],      
        centro:           [ '', Validators.required ],
        seccion:          [ '', Validators.required ],
        grado:            [ '', Validators.required ]
      });    

      this.datosGeneralesService.obtenerGeneralesBeca(this.Solicitud.SolicitudID).then(solicitud =>{
        this.Solicitud = solicitud;
        //this.fechaSolicitudFin = solicitud.Ciclo.FechaSolicitudFin;
        if(solicitud.Seccion != null ){
          this.centroService.getXSeccion(solicitud.Seccion.SeccionID).then(centros => {
            this.centros = centros
          }).catch(err => {
          console.log("Error" + err);
          }); 
        }

        if(solicitud.Seccion != null && solicitud.Centro != null){
          this.gradoEducativoService.consultaGradosEducativos(solicitud.Centro.CentroID, solicitud.Seccion.SeccionID).then(grados => {
            this.grados = grados;
          }).catch(err => {
          console.log("Error" + err);
          }); 
        }

        if( (solicitud.Seccion == null || solicitud.Seccion.SeccionID == 0 )
          && (solicitud.Centro == null || solicitud.Centro.CentroID ==null )
          &&( solicitud.GradoEducativo == null || solicitud.GradoEducativo.GradoEducativoID == 0  ) ){
          this.buscarInformacionAlumno();
        }else{
          this.datosGeneralesForm.get('centro').setValue(solicitud.Centro.CentroID);
          this.datosGeneralesForm.get('seccion').setValue(solicitud.Seccion.SeccionID);
          this.datosGeneralesForm.get('grado').setValue(solicitud.GradoEducativo.GradoEducativoID);
          
          /*
          if (solicitud.Seccion.SeccionID == 3 && solicitud.GradoEducativo.GradoEducativoID == 15
            || solicitud.Seccion.Nombre == "Secundaria" && solicitud.GradoEducativo.Nombre == "3o. de Secundaria"){
              this.errorSecundaria = true;
          }*/
        } 
        
        this.datosGeneralesForm.get('matricula').setValue(solicitud.Alumno.Matricula);
        this.datosGeneralesForm.get('curp').setValue(solicitud.Alumno.CURP);
        this.datosGeneralesForm.get('nombre').setValue(solicitud.Alumno.Nombre);
        this.datosGeneralesForm.get('primerApellido').setValue(solicitud.Alumno.ApellidoPaterno);
        this.datosGeneralesForm.get('segundoApellido').setValue(solicitud.Alumno.ApellidoMaterno);
        this.datosGeneralesForm.get('fechaNacimiento').setValue(solicitud.Alumno.FechaNacimiento);
        this.datosGeneralesForm.get('centro').setValue(solicitud.Centro.CentroID);
        this.datosGeneralesForm.get('seccion').setValue(solicitud.Seccion.SeccionID);
        this.datosGeneralesForm.get('grado').setValue(solicitud.GradoEducativo.GradoEducativoID);
  
      }).catch(err => {
        console.log("Error" + err);
      });        

      this.seccionService.getAll().then(secciones => {
        this.secciones = secciones;
      }).catch(err => {
      console.log("Error" + err);
      });        
    }
  }

  buscarInformacionAlumno(){
    this.datosGeneralesService.buscarAlumno(this.Solicitud.SolicitudID).then( e => {
      if(e != null){
        this.AlumnoPromedio = e;

        if(this.AlumnoPromedio.Seccion != null){
          this.datosGeneralesForm.get('seccion').setValue(this.AlumnoPromedio.Seccion.SeccionID);
          this.centroService.getXSeccion(this.AlumnoPromedio.Seccion.SeccionID).then(centros => {
            this.centros = centros;
            if(this.AlumnoPromedio.Centro != null){
              this.datosGeneralesForm.get('centro').setValue(this.AlumnoPromedio.Centro.CentroID);

              if(this.AlumnoPromedio.Seccion.SeccionID != null &&this.AlumnoPromedio.Centro != null){
                this.gradoEducativoService.consultaGradosEducativos(this.AlumnoPromedio.Centro.CentroID, this.AlumnoPromedio.Seccion.SeccionID).then(grados => {
                  this.grados = grados;
                  this.datosGeneralesForm.get('grado').setValue(this.AlumnoPromedio.GradoEducativo.GradoEducativoID);                  
                }).catch(err => {
                console.log("Error" + err);
                }); 
              }              
            }            
          }).catch(err => {
          console.log("Error" + err);
          }); 
        }
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  onSelectSeccion(entrando) {
    if(!entrando){
      this.centros = [];
      var SeccionID  =  this.datosGeneralesForm.get('seccion').value;  
  
      if(SeccionID != null && SeccionID != 0){
        this.centroService.getXSeccion(SeccionID).then(centros => {
          this.datosGeneralesForm.get('centro').setValue('');
          this.datosGeneralesForm.get('grado').setValue('');
          this.centros = centros
        }).catch(err => {
        console.log("Error" + err);
        }); 
      }
    }
  }

  onSelectCentro(entrando) {
    if(!entrando){
      this.grados = [];
      var SeccionID  =  this.datosGeneralesForm.get('seccion').value;  
      var CentroID  =  this.datosGeneralesForm.get('centro').value;  
      if( SeccionID  != null && SeccionID != 0 
            && CentroID != null && CentroID != 0){
          this.gradoEducativoService.consultaGradosEducativos(CentroID, SeccionID).then(grados => {
          this.grados = grados;
        }).catch(err => {
        console.log("Error" + err);
        }); 
      }
    }
  }

  validarCurp() {
    var curp =  this.datosGeneralesForm.get('curp').value;   
    if(curp != null){
      curp = curp.toUpperCase();
      this.curpError=false;
      if(curp != null && curp.length >0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado)  //Coincide con el formato general?
          this.curpError=true;
      }
    }
  }

  validar(){
    var centro =  this.datosGeneralesForm.get('centro').value;
    var grado =  this.datosGeneralesForm.get('grado').value;
    var seccion =  this.datosGeneralesForm.get('seccion').value;

    console.log("Centro" + centro + " Seccion   "+ seccion + " grado "+ grado);

    if(centro != null && centro != ''  && grado != null && grado != '' && seccion != null && seccion != ''){
      this.gradoEducativoService.consultaSiguienteGrado(centro, seccion, grado, false).then(gradoSeccionCentro => {
        if(gradoSeccionCentro == null){
          this.errorSecundaria = true;
        }
      }).catch(err => {
      console.log("Error" + err);
      }); 
    }

  }

  guardar(){
    this.save('/pantalla-principal', this.fechaExtemporanea ?  this.fechaExtemporanea : this.fechaSolicitudFin);
  }

  guardarContinuar(){
    this.save('/datos-familiares', null);
  }

  save(ruta : string, fechaFin: Date){
    
    var recordatorio = false;
    if(!this.datosGeneralesForm.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return;
    }

    this.Solicitud.Alumno.Nombre =  this.datosGeneralesForm.get('nombre').value;
    this.Solicitud.Alumno.ApellidoMaterno =  this.datosGeneralesForm.get('segundoApellido').value;
    this.Solicitud.Alumno.ApellidoPaterno =  this.datosGeneralesForm.get('primerApellido').value;
    this.Solicitud.Alumno.FechaNacimiento =  this.datosGeneralesForm.get('fechaNacimiento').value;
    this.Solicitud.Alumno.CURP =  this.datosGeneralesForm.get('curp').value;
    this.Solicitud.Centro = new Centro();
    this.Solicitud.Centro.CentroID = this.datosGeneralesForm.get('centro').value;
    
    this.Solicitud.Seccion = new Seccion();
    this.Solicitud.Seccion.SeccionID = this.datosGeneralesForm.get('seccion').value;

    this.Solicitud.GradoEducativo = new GradoEducativo();
    this.Solicitud.GradoEducativo.GradoEducativoID = this.datosGeneralesForm.get('grado').value;
    this.Solicitud.UsuarioID = this.idUsuario;
    
    if( this.AlumnoPromedio!= null){
      this.Solicitud.Admision = this.AlumnoPromedio.Admision;
    }else{
      this.Solicitud.Admision = false;
    }

    this.guardando = true;
    this.solicitudService.guardarGeneralesBeca(this.Solicitud).then( res =>{

      if (res.Exito) {
        this.guardando = false;
        if(fechaFin){
          let latest_date = this.datepipe.transform(fechaFin, 'dd/MM/yyyy');
          var dialogData = new ConfirmDialogModel('', "Recuerda que debes completar la solicitud antes del día" + " " + latest_date + " " + "o tu solicitud será eliminada."  , false);
          recordatorio = true;          
        }else{
          var dialogData = new ConfirmDialogModel('', "Información guardada", false); 
        } 
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        

        dialogRef.afterClosed().subscribe( result => {
          if(recordatorio){
            this.solicitudService.enviarcorreoRecordatorio(this.Solicitud.SolicitudID).then( res =>{
            }).catch(err => {
              console.log("Error" + err);
            }).finally(() => {
        
            });
          }
          this.router.navigate([ruta]);          
        });
      }else{
        this.guardando = false;
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }
    }
    ).catch(err => {
      this.guardando = false;
      console.log("Error" +err);
      const message = `Ocurrio un error al conectar con el servidor`;

      const dialogData = new ConfirmDialogModel("Error", message, false);

      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => { });
    })
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {

    const dialogData = new ConfirmDialogModel(titulo, message, false);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  } 
  
  omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if( e != null && e.FechaFin){
        this.fechaExtemporanea = e.FechaFin;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  } 
}