import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Ciclo } from '@app/_models/ciclo';
import { OperationResult } from '@app/_models/operationResult';

@Injectable({ providedIn: 'root' })
export class CicloBecaService {

    constructor(private http: HttpClient) {}

    consultaCiclos():Promise<Ciclo[]>{
        return this.http.get<Ciclo[]>(`${environment.apiUrl}/Ciclo`).toPromise();
    }

    get():Promise<Ciclo[]>{
        return this.http.get<Ciclo[]>(`${environment.apiUrl}/Ciclo`).toPromise();
    }

    listadoCiclos(nRegistros:number, nPagina:number, filtro:string){
        return this.http.post<any>(`${environment.apiUrl}/ListadoCiclos`,  {nRegistros, nPagina, filtro } );
    }

    post(ciclo: Ciclo){
        return this.http.post<any>(`${environment.apiUrl}/Ciclo`,  ciclo );
    }
  
    delete(ciclo: Ciclo){
        let params = new HttpParams();
        params = params.append('CicloID', ciclo.CicloID.toString() );
    
        return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/Ciclo`, { params } );
    }  
}