import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Historial } from '@app/_models/historial';
import { environment } from '@environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class HistorialService {

    constructor(private http: HttpClient) { 


        }
        
    ObtenerHistorial(TipoID :number, ID : number){
        return this.http.get<Historial[]>(`${environment.apiUrl}/${TipoID}/${ID}/Historial`).toPromise();
    }
    
    
  }