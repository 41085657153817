<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/seleccion-beca" class="circulo bg-celeste">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-generales" class="circulo bg-rosado">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-familiares" class="circulo bg-verde">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/requisitos" class="circulo bg-azul">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-naranja">
                        <h2>5</h2>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <br><br>
    <mat-card-title class="card-title fz-40">Estudio Socioeconómico.</mat-card-title>
    <br>
    <div class="exampleform">
        <p class="text-center color-marino fz-15">A continuación se muestran los datos de la empresa que realizará el estudio 
            socioeconómico para su solicitud. Si desea enviar un mensaje a la empresa, por favor redáctelo en el espacio correspondiente.
        </p>
    </div>
    <br><br>
    <div *ngIf="!hayEmpresa" class="exampleform">
        <p class="text-center color-azul fz-18">No hay empresas registradas para realizar el estudio socioeconómico, favor de comunicarse a su colegio.
        </p>
    </div>
    <div *ngIf="hayEmpresa" class="exampleform">
        <table>
            <tr>
                <td><label class="color-azul fz-16">Nombre de la empresa:</label></td>
                <td class="color-marino">{{EstudioSolicitud.Empresa.NombreEmpresa}}</td>
            
                <td><label class="color-azul fz-16">Teléfono:</label></td>
                <td class="color-marino">{{EstudioSolicitud.Empresa.TelefonoEmpresa.Numero}}</td>
              </tr>
            
              <tr>
                <td><label class="color-azul fz-16">Nombre del contacto:</label></td>
                <td class="color-marino">
                    {{EstudioSolicitud.Empresa.ContactoEmpresa.Nombre}}
                    {{EstudioSolicitud.Empresa.ContactoEmpresa.ApellidoPaterno}}
                    {{EstudioSolicitud.Empresa.ContactoEmpresa.ApellidoMaterno}}
                </td>
            
                <td><label class="color-azul fz-16">Correo electrónico:</label></td>
                <td class="color-marino">{{EstudioSolicitud.Empresa.Correo}}</td>
              </tr>
            
              <tr>
                <td><label class="color-azul fz-16">Tipo de beca:</label></td>
                <td class="color-marino">{{tipoBeca}}</td>
            
                <td><label class="color-azul fz-16">Costo:</label></td>
                <td class="color-marino">{{EstudioSolicitud.EstudioEmpresa.Costo  | currency:'MXN'}}</td>
            </tr>
        </table>
    </div>
    <br><br>
    <div *ngIf="hayEmpresa" class="exampleform">
        <form name="datosFamiliaresForm" id="estudioForm" [formGroup]="estudioForm">  
            <mat-card-title class="color-azul">Mensaje para la empresa (Máximo 500 caractéres).</mat-card-title>
            <mat-form-field class="example-full-width xs-width margin-top" appearance="fill">
                <textarea matInput formControlName="contenidoCorreo" rows="8" maxlength="500"></textarea>
            </mat-form-field>
        </form>
        <br>
        <span class="color-marino">El correo se enviará al terminar el proceso de solicitud.</span>
    </div>
    <br><br><br>
    <div class="btn-div margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" style="margin-right: 1rem;"
            (click)="guardar()" matTooltip="Guardar y volver al menú principal" [disabled]="!EstudioSolicitud.Empresa.NombreEmpresa" mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" 
            (click)="guardarContinuar()" [disabled]="!EstudioSolicitud.Empresa.NombreEmpresa || guardando" mat-raised-button>SIGUIENTE</button>
    </div>
</mat-card>