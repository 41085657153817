<mat-card-title class="card-title fz-20">AGREGAR CALIFICACIÓN  DE {{tipoCalificacion}}</mat-card-title>
<mat-divider></mat-divider>
<br><br>

<form [formGroup]="detalleCalfForm" class="modal-form" name="detalleCalfForm" id="detalleCalfForm" autocomplete="off">

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Calificación</mat-label>
        <input matInput #input type="text" maxlength="4"  (ngModelChange)="validarCalificacion()" 
        (keypress)="numberOnly($event)"
        formControlName="calificacion" required
         onPaste="return false">
        <mat-hint *ngIf="calificacionErr">El promedio debe ser un número válido menor a 10</mat-hint>               
    </mat-form-field>

    <div *ngIf="!esPruebaAptitud">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Comentarios</mat-label>
            <textarea matInput #inputD name="comentarios" formControlName="comentarios"   
            (ngModelChange)="verify()" rows="8" required></textarea>
            <mat-hint *ngIf="inputD.value?.length == 200">Max 200 caracteres</mat-hint>
        </mat-form-field>
    </div>

    <div *ngIf="esPruebaAptitud">
        <div class="row">
            <div class="lato-bold-italic"> Extensiones aceptadas: ( {{requisitoBeca.Extensiones }} )</div>
            <button class="buttonSecondary" mat-raised-button (click)="seleccionarArchivo()">
                <span class='lato-bold espaciado margin-btn' style="font-size: 16px;">+ CARGAR ARCHIVO</span>
            </button>
            <input class="input-search text-ident xs-width"  [(value)]="nombreArchivo" disabled>
            <input class="hidden" id="upload-file" type="file" [accept]="requisitoBeca.Extensiones" (change)="adjuntarArchivo($event)">
        </div>    
    </div>

    <br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn">GUARDAR</button>
    </div>
    <div *ngIf="error">
        <mat-hint class='card-danger'>{{errorMessage}}</mat-hint>
    </div>
</form>
