import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingScreenService } from "@app/_services/loading-screen.service";
import { Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loadingSubscription: Subscription;
  public colorLoading: string = "primary";
  private colores: string[] = ["primary", "warn", "info", "accent"];
  private colorActual: number = 0;
  private timerColores: any;

  constructor(private loadingScreenService: LoadingScreenService) {
  }

  ngOnInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
      debounceTime(200)
    ).subscribe((value) => {
      this.loading = value;
      if (value == true) {
        if (!!this.timerColores) {
          clearInterval(this.timerColores);
        }
        this.colorActual = 0;
        this.timerColores = setInterval(() => {
          this.colorLoading = this.colores[++this.colorActual % this.colores.length];
        }, 500);
      } else {
        if (!!this.timerColores) {
          clearInterval(this.timerColores);
        }
      }
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

}
