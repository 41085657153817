import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { EstatusEnvioCorreo } from '@app/_models/estatusEnvioCorreo';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { BecaOtorgada } from '@app/_models/becaOtorgada';
import { TipoCarta } from '@app/_models/tipoCarta';

@Injectable({ providedIn: 'root' })
export class CartaBecaService {

    constructor(private http: HttpClient) {}

    consultarTipoCartas():Promise<TipoCarta[]>{
        return this.http.get<TipoCarta[]>(`${environment.apiUrl}/TipoCarta`).toPromise();
    }

    consultarEstatusEnvioCorreo():Promise<EstatusEnvioCorreo[]>{
        return this.http.get<EstatusEnvioCorreo[]>(`${environment.apiUrl}/EstatusEnvioCorreo`).toPromise();
    }

    listadoBecas(cicloID: number, centros : string[],
        secciones : number[], estatus : number[], tiposBeca : number[], nRegistros:number, nPagina:number, filtro:string, UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/ListadoBecasCarta`,  {cicloID,  centros, secciones,
            estatus, tiposBeca, filtro, nRegistros, nPagina, UsuarioID} );
    }


    GenerarCarta(becaOtorgada :BecaOtorgada){
        return this.http.post<any>(`${environment.apiUrl}/GenerarCarta`,  becaOtorgada );
    }


    EnviarCorreo(becasOtorgadas: BecaOtorgada[], UsuarioID: number){

        return this.http.post<any>(`${environment.apiUrl}/EnviarCartas`,  {becasOtorgadas, UsuarioID});
        
    }

    EnviarCorreoPorSeleccion(becasOtorgadas: BecaOtorgada[], UsuarioID: number){

        return this.http.post<any>(`${environment.apiUrl}/EnviarCartasPorSeleccion`,  {becasOtorgadas, UsuarioID});
        
    }


    DescargarPaquete( cicloID : number, centros : string[],
        secciones : number[],estatus : number[],  tiposBeca : number[],  filtro : string,UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/PaqueteCartas`,   {cicloID,  centros, secciones,
            estatus, tiposBeca, filtro, UsuarioID});

      } 
      
}