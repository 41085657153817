<mat-card-title class="card-title fz-20">
    Especificaciones para  {{dataTipoBecaEspecificaciones.TipoBecaSeccion.Seccion.Nombre}} por grados
  </mat-card-title>
    <mat-table  [dataSource]="dataSourceSeccionGrado" >
      <ng-container matColumnDef="aplicable">
        <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción">
          <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
        </mat-cell>
      </ng-container>   
  
      <ng-container matColumnDef="Nombre">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.GradoEducativo.Nombre}} </mat-cell>
      </ng-container>      
        
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table> 
  
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>
    <br>
  

  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <br><br><br><br>
  <div class="modal-form margin-div">
    <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>        
      <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()"  [disabled]="!enableBtn"  >ACEPTAR</button>  
  </div>
  
  <div *ngIf="error">
    <br>
    <mat-card class='card-danger'>{{errorMessage}}</mat-card>
  </div>