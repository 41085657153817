import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Prospecto } from '@app/_models/prospecto';
import { ResponseRest } from '@app/_models/responseRest';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DatosUsuario } from '@app/_models/datosUsuario';
import { Pais } from '@app/_models/pais';
import { Colonia } from '@app/_models/colonia';
import { AuthenticationService } from './authentication.service';
import { Estado } from '@app/_models/estado';
import { Municipio } from '@app/_models/municipio';

@Injectable({
  providedIn: 'root'
})
export class CodigoPostalService {

  constructor(private http: HttpClient) { }

 consultaEstados():Promise<Estado[]>{
    return this.http.post<Estado[]>(`${environment.apiUrl}/BusquedaCodigoPostal/Estados`, {}).toPromise();

}

consultaMunicipios(EstadoID : string):Promise<Municipio[]>{
    return this.http.post<Municipio[]>(`${environment.apiUrl}/BusquedaCodigoPostal/${EstadoID}/Municipios`, EstadoID).toPromise();

}


consultaColonias(MunicipioID : string):Promise<Colonia[]>{
    return this.http.post<Colonia[]>(`${environment.apiUrl}/BusquedaCodigoPostal/${MunicipioID}/Colonias`, MunicipioID).toPromise();

}



}
