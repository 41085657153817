import { Alumno } from './alumno';
import { Seccion } from './seccion';
import { Centro } from './centro';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from './gradoEducativo';

export class HermanoSolicitud {

    HermanoSolicitudID : number;
    SolicitudID : number;
    TieneBeca : boolean;
    Porcentaje : number;
    Alumno : Alumno;
    Seccion : Seccion;
    Centro : Centro;
    GradoEducativo : GradoEducativo;


  }
  
