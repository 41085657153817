import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DocumentoEstudio } from '@app/_models/documentoEstudio';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
import { RetroEstudioSolicitud } from '@app/_models/retroEstudio';
import { CargaEstudioSocService } from '@app/_services/cargaEstudioSocio.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';

@Component({
  selector: 'app-detalle-estudiosocioeconimico',
  templateUrl: './detalle-estudiosocioeconimico.component.html',
  styleUrls: ['./detalle-estudiosocioeconimico.component.scss']
})
export class DetalleEstudiosocioeconimicoComponent implements OnInit {

  extensiones  = 'zip,rar';
  maxSizeFile:number = 5120000; // 5MB  
  NombreArchivo:string = 'Ningún archivo seleccionado';  
  documento : DocumentoEstudio;
  detalleEstudio: FormGroup;
  idUsuario: number;
  porcentajeError = false;
  retroEmpresa : RetroEstudioSolicitud;
  estudioSolicitud : EstudioSolicitud;
  //guardando : boolean;
  esperando : boolean;
  archivoAdjuntado = false;

  constructor(private formBuilder: FormBuilder, 
    private authenticationService: AuthenticationService, 
    public  cargaEstudio : CargaEstudioSocService,
    public dialogRef: MatDialogRef<DetalleEstudiosocioeconimicoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.documento = new DocumentoEstudio();

    this.detalleEstudio = this.formBuilder.group({
      curp: [{ value: '', disabled: true,  }],
      centro: [{  value: '', disabled: true, }],
      grado: [{  value: '', disabled: true, }],
      nombre: [{  value: '', disabled: true, }],
      aPaterno: [{  value: '', disabled: true, }],
      aMaterno: [{  value: '', disabled: true, }],
      porcentaje: ['',  Validators.required],            
      comentarios: ['',  Validators.required],  
    });   
    
    console.log("Cargar " + this.data.Operation);
    if (this.data.Operation === 'Cargar') {
      this.estudioSolicitud = this.data.Objeto;
     
      this.detalleEstudio.get('curp').setValue(this.estudioSolicitud.Solicitud.Alumno.CURP);  
      this.detalleEstudio.get('centro').setValue(this.estudioSolicitud.Solicitud.Centro.Nombre);     
      this.detalleEstudio.get('grado').setValue(this.estudioSolicitud.Solicitud.GradoEducativo.Nombre);    
      this.detalleEstudio.get('nombre').setValue(this.estudioSolicitud.Solicitud.Alumno.Nombre);    
      this.detalleEstudio.get('aPaterno').setValue(this.estudioSolicitud.Solicitud.Alumno.ApellidoPaterno);    
      this.detalleEstudio.get('aMaterno').setValue(this.estudioSolicitud.Solicitud.Alumno.ApellidoMaterno); 
      this.obtenerRetro();   
    } 
  }

  seleccionarArchivo(clave:string){
    document.getElementById(clave).click();
  }
  
  obtenerRetro(){
    this.retroEmpresa = new RetroEstudioSolicitud();
    this.documento = new DocumentoEstudio();
    this.documento.BotonDescripcion = 'Seleccionar';
    this.documento.EstatusCarga_ ='';
    this.cargaEstudio.obtenerEstudioSocio(this.estudioSolicitud.Solicitud.SolicitudID, this.idUsuario).then(data =>{

      if(data != null){
        this.retroEmpresa = data;
        this.detalleEstudio.get('porcentaje').setValue(this.retroEmpresa.Porcentaje);    
        this.detalleEstudio.get('comentarios').setValue(this.retroEmpresa.Comentarios);   
        if(data.DocEstudioSolicitud != null){
          
          this.documento = data.DocEstudioSolicitud;
          this.documento.BotonDescripcion = 'Modificar';
          this.documento.EstatusCarga_ = data.DocEstudioSolicitud.NombreOriginal;
          this.NombreArchivo = data.DocEstudioSolicitud.NombreOriginal;
          this.archivoAdjuntado = true;
        }
      }
    },
    () => {
      this.retroEmpresa = new RetroEstudioSolicitud();
      this.retroEmpresa.DocEstudioSolicitud = new DocumentoEstudio();
    });
  }

  adjuntarArchivo(fileInput: any) {
    console.log("File " + JSON.stringify(fileInput));
      
    if (fileInput.target.files.length <= 0){
      this.NombreArchivo = fileInput.target.files[0].name;
      this.archivoAdjuntado = true;
      this.documento.Archivo_ = null;
    } else {
      this.documento.Archivo_ = fileInput.target.files[0];
      
      let extension = this.documento.Archivo_.name.substr(this.documento.Archivo_.name.lastIndexOf('.') + 1 );
      console.log("Extension " + JSON.stringify(this.documento.Archivo_.name));

      if (this.extensiones.toLowerCase().includes(extension.toLowerCase())){
        this.NombreArchivo = fileInput.target.files[0].name;
        this.archivoAdjuntado = true;
      }
      else{
        this.documento.Archivo_ = null;
        this.showMessage("Información", "Sólo se permiten archivos con las siguientes extensiones: " +this.extensiones);
      }
    }
  }

  showMessage(title:string, message:string):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }

  cancelar(): void {
    this.dialogRef.close(false);
  }

  importarEstudio(){ 
    if (this.detalleEstudio.invalid) {
      return;
    }
    console.log("Importando archivos:" + this.NombreArchivo);
    var porcentaje = this.detalleEstudio.get('porcentaje').value;
    var comentarios = this.detalleEstudio.get('comentarios').value;
    //(this.documento.Archivo_ == null || this.documento.Archivo_.name == null || this.documento.Archivo_.name == '')
    if ( this.NombreArchivo == 'Ningún archivo seleccionado')
    {
      const dialogData = new ConfirmDialogModel('Aviso', 'Debe cargar el estudio socioeconómico', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }

    if (this.documento.Archivo_ == null){
      this.esperando = true;
      this.cargaEstudio.actualizarDatos(this.estudioSolicitud.Solicitud.SolicitudID,this.idUsuario,comentarios, porcentaje).then(data =>{
        this.esperando = false;
        if(data != null && data.Exito){
          this.showMessage('Información', data.Mensaje);
          this.esperando = false;
          this.dialogRef.close(this.data);
        }
       
        },
        error => {
          this.esperando = true;
          this.retroEmpresa = new RetroEstudioSolicitud();
          this.retroEmpresa.DocEstudioSolicitud = new DocumentoEstudio();
        });
    
    }else{
      let filesFD = new FormData();

      if (this.documento.Archivo_.size <= this.maxSizeFile){
        filesFD.append("Estudio", this.documento.Archivo_, this.documento.Archivo_.name);
      } else {
        this.showMessage('Información', "El documento (" + this.NombreArchivo + ") excede el peso permitido.");
        return;
      }
      this.esperando= true;
      this.cargaEstudio.importar(filesFD,this.estudioSolicitud.Solicitud.SolicitudID,this.idUsuario,comentarios, porcentaje)
      .pipe(first()).subscribe(data => {   
        if (data.Exito){
  
        if (data.Mensaje != null) {
          this.showMessage('Información', data.Mensaje);
          this.dialogRef.close(this.data);
        }
        else
        {
          if (data.Dato != null){
            if (data.Mensaje != null){
              this.showMessage('Información', data.Mensaje);
              this.esperando = false;
              this.dialogRef.close(this.data);
            }
  
          } else {
            this.showMessage('Error', 'La solicitud no devolvió resultados.');      
            this.esperando = false;  
          }
        }
  
        } else {
        this.showMessage('Error', data.Mensaje);
        this.esperando = false;  
        }
      },
      error => {
        console.log('error', error);
        this.esperando = false;
      });    
    }


  }

  validarPorcentaje() {
    
    var porcentaje =  this.detalleEstudio.get('porcentaje').value;
    var porc = parseInt(porcentaje)
    console.log("Validando porc" + porc);
    this.porcentajeError = false;

    if(porc){
      if(porc < 0 || porc > 100 ){
        this.porcentajeError = true;
      }
    }else{
      this.porcentajeError = true;
     
    }
    console.log("Validando porcentaje" + this.porcentajeError);

  } 

  omit_special_char(e: any) {
    if (/^[0-9]+$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }
}