import { GradoEducativo } from './gradoEducativo';
import { Seccion } from './seccion';
import { TipoBecaConteo } from './tipoBecaConteo';

export class DetalleResumenPropuesta {
    Seccion : Seccion;
    GradoEducativo : GradoEducativo;
    Alumnos : number;
    BecasTotal : number;
    BecasNuevas : number;
    BecasRenovacion : number;
    PorcentajeSobreAlumnos : number;
    CostoBecaAnual : number;
    PorcentajeSobreIngresos : number;
    TipoBecas : TipoBecaConteo[];

    ColegiaturaProyectada: number;
    Colegiaturas: number;
    CostoColegiaturas : number;

}