<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste-claro">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div  routerLink="/seleccion-beca" class="circulo bg-celeste">
                        <mat-icon routerLink="/seleccion-beca" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-rosado">
                        <h2>2</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>3</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>4</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>5</h2>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <br>
    <br>
    <mat-card-title class="card-title fz-40">Datos generales</mat-card-title>
    <br>
    <form class="example-form" name="datosGeneralesForm" id="datosGeneralesForm" [formGroup]="datosGeneralesForm">  
     <div class="example-form">
        <div class="row">              
            <mat-label class="color-marino">Tipo Beca: {{tipoBeca}}</mat-label>
        </div>
        <div class="row">
            <mat-form-field class="inpunt-width xs-width" appearance="fill">
                <mat-label>Nombre(s):</mat-label>
                <input matInput id="nombre" name="nombre" 
                 (keypress)="omit_special_char($event)" formControlName="nombre" required>
            </mat-form-field> 
    
            <mat-form-field class="inpunt-width xs-width" appearance="fill">
                <mat-label>Apellido paterno:</mat-label>
                <input matInput id="primerApellido" 
                (keypress)="omit_special_char($event)" name="primerApellido" formControlName="primerApellido" required>
            </mat-form-field> 
            
            <mat-form-field class="inpunt-width xs-width" appearance="fill">
                <mat-label>Apellido materno:</mat-label>
                <input matInput id="segundoApellido" name="segundoApellido" 
                (keypress)="omit_special_char($event)" formControlName="segundoApellido">
            </mat-form-field>
        </div>
        <br>
        <div class="row">
            <mat-form-field  class="inpunt-width xs-width" appearance="fill">
                <mat-label>Sección:</mat-label>
                <mat-select placeholder="Sección" id="seccion" name="seccion" formControlName="seccion"   
                    (openedChange)="onSelectSeccion($event)" required    >
                  <mat-option *ngFor="let c of secciones" [value]="c.SeccionID">
                    {{c.Nombre}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field  class="input-centro-width xs-width" appearance="fill">
                <mat-label>Centro:</mat-label>
                <mat-select placeholder="Centro" id="centro" name="centro" formControlName="centro" 
                    (openedChange)="onSelectCentro($event)" required >
                  <mat-option *ngFor="let c of centros" [value]="c.CentroID">
                    {{c.Nombre}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
        </div>
        <br>
        <div class="row">
            <mat-form-field  class="inpunt-width xs-width" appearance="fill">
                <mat-label>Grado:</mat-label>
                <mat-select placeholder="Grado" id="grado" name="grado" formControlName="grado" (ngModelChange)="validar()" required>
                  <mat-option *ngFor="let c of grados" [value]="c.GradoEducativoID">
                    {{c.Nombre}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="inpunt-width xs-width" appearance="fill">
                <mat-label>CURP</mat-label>
                <input matInput class="uppercase" (ngModelChange)="validarCurp()" id="curp" name="curp" 
                formControlName="curp">
                <mat-hint *ngIf="curpError">El CURP tiene un formato inválido</mat-hint>
            </mat-form-field>
            
            <mat-form-field class="inpunt-width xs-width" appearance="fill">
                <mat-label>Fecha de nacimiento:</mat-label>
                <input matInput [readonly]="true" [matDatepicker]="picker" id="fechaNacimiento" name="fechaNacimiento" formControlName="fechaNacimiento" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <br>
    </div>
    </form>
    <br>
    <div *ngIf="errorSecundaria" class="icon-text text-center" style="justify-content: center;">
        <mat-icon>error_outline</mat-icon>
        <p>Debe ponerse en contacto con la preparatoria deseada.</p>
     </div>
    <br>
    <div class="btn-div margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" 
            (click)="guardar()" style="margin-right: 1rem;" matTooltip="Guardar y volver al menú principal" mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" 
            (click)="guardarContinuar()" [disabled]="curpError || errorSecundaria || guardando" mat-raised-button>SIGUIENTE</button>
    </div>
</mat-card>