import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { EmpleadoModel } from '@app/_models/empleado.model';
import { OperationResult } from '@app/_models/operationResult';

@Injectable({ 
  providedIn: 'root'
})
export class EmpleadoService {

  constructor(private http: HttpClient) { }

  get(nRegistros:number, nPagina:number, filtro: string){
    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );
    params = params.append('filtro', filtro );

    return this.http.get<any>(`${environment.apiUrl}/ListadoEmpleados`, { params }).toPromise();
  }

  detalle(EmpleadoID : number){
    return this.http.get<any>(`${environment.apiUrl}/${EmpleadoID}/DetalleEmpleado`).toPromise();
  }

  post(empleado: EmpleadoModel){
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/empleado`, empleado );
  }

  delete(empleado: EmpleadoModel){

    let params = new HttpParams();
    params = params.append('UsuarioID', empleado.UsuarioID.toString() );
    params = params.append('EmpleadoID', empleado.EmpleadoID.toString() );
    params = params.append('NumeroEmpleado', empleado.NumeroEmpleado.toString() );

    return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/empleado`, { params } );
  }
}