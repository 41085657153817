<br><br><br>
  <div class="example-form">
    <div class="example-form">
      <form name="adminInfoForm" id="adminInfoForm" >
        <div class="row">
          <button mat-raised-button class="button" (click)="nuevoBeca()">+ NUEVA BECA</button>
          <input class="input-search icon xs-width text-ident" placeholder="Buscar por nombre" [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">          
          <br><br>
        </div>
      </form>
    </div>
    <br>
    <div class="example-form mat-elevation-z8">
      <mat-table  [dataSource]="dataSource" >
          <ng-container matColumnDef="Clave">
            <mat-header-cell *matHeaderCellDef> Clave </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Número empleado"> {{element.Clave}} </mat-cell>
          </ng-container>      
          
          <ng-container matColumnDef="Nombre">
            <mat-header-cell *matHeaderCellDef> Nombre de la beca </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Área"> {{element.Nombre}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Secciones">
            <mat-header-cell *matHeaderCellDef> Secciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Secciones"> {{element.NombresSecciones}} </mat-cell>
          </ng-container>          

          <ng-container matColumnDef="Opciones">
            <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones">
                <button mat-icon-button color="basic" aria-label="Editar" matTooltip="Editar" (click)="editarBeca(element)" matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarBeca(element)" matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsBecas"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsBecas;">
          </mat-row>
          
      </mat-table> 
    
      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar.</span>
      </div>

      <mat-paginator #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="pageEvent = getServerData($event)"
        >
      </mat-paginator>

    </div>
    <br>   
  </div>
  <br><br><br>