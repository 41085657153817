import { Component, OnInit, ElementRef, ViewChild, Type, Input } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ThemePalette, ErrorStateMatcher } from '@angular/material/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { Seccion } from '@app/_models/seccion';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Usuario } from '@app/_models/usuario';
import { NavigationEnd, Router } from '@angular/router';
import { CentroService } from '@app/_services/centro.service';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Pais } from '@app/_models/pais';
import { AlumnoService } from '@app/_services/alumno.service';
import { Alumno } from '@app/_models/alumno';
import { Redes } from '@app/_models/redes';
import { CorreoAyudaComponent } from '@app/_components/correo-ayuda/correo-ayuda.component';
import { state } from '@angular/animations';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class RegistroComponent implements OnInit {
  cambioPasswordForm: FormGroup;
  confirmarControl: FormControl;

  colorForce: ThemePalette = 'primary';
  valueForce = 0;
  bufferValue = 75;

  hide = true;

  password = "";
  containsPssword = false;

  date = new FormControl(null);

  minDate: Date;
  maxDate: Date;
  curpError: boolean = false;

  passMatch = true;

  public isAnahuac: boolean = false;
  
  matcher = new PasswordErrorStateMatcher();
  private existeUsuario;
  dateError = false;
  enableBtn = false;
  dateNac= new Date();
  correo : string;

  TokenReCaptcha: string = '';
  
  correoCoincide = null;
  correoAlternoIgual = null;
  correoError = false;
  correoAlternoError = false;
  paises: Pais[] = [];  
  centros: Centro[] = [];
  secciones: Seccion[] = [];

  fortalezaError = false;
  mostrarDescripcionPass = false;  

  validateId = false
  idError = true

  show: boolean;
  show2: boolean;
  private returnUrl = '/';

  //aviso = "https://www.kilimanjaroism.com/avisodeprivacidad/";
  redes : Redes;
  
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;
  constructor(
    public dialog: MatDialog, 
    private formBuilder: FormBuilder, 
    private alummnoService: AlumnoService,
    private centroService: CentroService, 
    private router: Router, ) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 1, 0, 1);
    this.date = new FormControl(null);
    this.confirmarControl = new FormControl('',[]);
    this.show = false;

    let anahuac = localStorage.getItem('usrAhc');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        if(this.returnUrl == "/login/anahuac" || anahuac == "true") this.isAnahuac = true;
      }
    });
  }

  ngOnInit() {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true

    this.redes = new Redes();
    this.redes.Aviso = "https://www.semperaltius.edu.mx/aviso-de-privacidad";

    this.getCentro()

    this.existeUsuario = function(form: FormControl){
      if (!form || !form.value) {
        return null;
      }
    };
 
    this.cambioPasswordForm = this.formBuilder.group({
      curp: ['', Validators.required],
      correo: ['', Validators.required],
      confirmCorreo: ['', Validators.required,],
      correoAlterno: ['', Validators.required,],
      nombre: ['', Validators.required],
      primerApellido: ['', Validators.required],
      segundoApellido: [''],
      centro: ['', Validators.required],
      fechaNacimiento: ['', Validators.required],
      password: new FormControl('', [Validators.required]),
      confirmPassword: this.confirmarControl,
      acepto: ['', Validators.required],

    }, { validators: this.checkPasswords });
  }

  get f() { return this.cambioPasswordForm.controls; }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cambiarContrasena() {
    this.updateProgressBar();
    if (this.cambioPasswordForm.invalid) {
      return;
    }
  }

  getCentro(){
    this.centroService.getAll().then(centros => {
      this.centros = centros
    }).catch(err => {
    console.log("Error" + err);
    }); 
  }

  validarCorreo() {
    this.correoError = false;
    this.correoAlternoError = false;
    var correo = this.cambioPasswordForm.get('correo').value;
    var correoAlterno = this.cambioPasswordForm.get('correoAlterno').value;

    if(correo != null && correo.length > 0 || correoAlterno != null && correoAlterno.length > 0 ){
      var mailformat =`(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"`
      + `(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")`
      + `@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`
      + `|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]`
      + `:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`;
      var match = correo.match(mailformat);
      var matchAlterno = correoAlterno.match(mailformat);
      if (correo != "" && !match)
      {
        this.correoError=true;
      }

      if (correoAlterno != "" && !matchAlterno)
      {
        this.correoAlternoError=true;
      }
      
      if (!this.correoError  && !this.correoAlternoError) {
        if( correoAlterno === correo){
          this.correoAlternoIgual = true; 
        }
        else {this.correoAlternoIgual = null; }
      }
    }
  }

  validarCaracteres(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZÁÉÍÓÚÜabcdefghijklmnñoplrstuvwxyzáéíóúü "
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }
   /*visualizar_contrasena(){
    var tipo = document.getElementsByClassName("input-type-password");
    if (tipo. == "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }*/

  validarCurp() {
    var curp =  this.cambioPasswordForm.get('curp').value;

     if(curp != null){
       curp = curp.toUpperCase();
       this.curpError = false  
       if(curp != null && curp.length >0){
         var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
         validado =curp.match(re);
         if (!validado)  //Coincide con el formato general?
        this.curpError = true    
      }
    }
  } 

  updateProgressBar() {
    this.fortalezaError = false;

    this.containsPssword = false;
    if (this.f.password.value == undefined) return;
    if (this.f.password.value == null) return;
    if (this.f.password.value.trim() == '') return;

    this.valueForce = this.checkStrength(this.f.password.value);

    if (this.valueForce <= 45) {
      this.fortalezaError = true;
      this.colorForce = 'warn';
    } else if (this.valueForce <= 75) {
      this.colorForce = 'primary';
    } else {
      this.colorForce = 'primary';
    }
    this.containsPssword = true;
  }

  checkStrength(p) {
    // 1
    let force = 0;

    // 2
    const regex = /[$-/:-?{-~!"^_@#`\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5
    force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
    force += passedMatches * 10;

    // 6
    force = (p.length <= 6) ? Math.min(force, 10) : force;

    // 7
    force = (passedMatches === 1) ? Math.min(force, 10) : force;
    force = (passedMatches === 2) ? Math.min(force, 20) : force;
    force = (passedMatches === 3) ? Math.min(force, 30) : force;
    force = (passedMatches === 4) ? Math.min(force, 40) : force;

    if (force == 20) return 45;
    if (force == 30) return 75;
    if (force == 40) return 100;
    return force;
  }

  passwordView() {
    this.show = !this.show;
  }
  passwordView2() {
    this.show2 = !this.show2;
  }
  crearCuenta() {
    
    if(!this.cambioPasswordForm.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return;
    }
    
    if(this.correoError){
      this.showMessage('Debe ingresar un correo válido', 'Error');
      return;
    }

    if (this.valueForce <75) {
      this.showMessage('La contraseña no cumple con los requisitos de seguridad', 'Error');
      return;
    }    

    if (this.cambioPasswordForm.get('password').value != this.cambioPasswordForm.get('confirmPassword').value) {
      this.showMessage('No coinciden los Passwords', 'Error');
      return;
    }

    if (!this.cambioPasswordForm.valid) {
      return;
    }

    if (!this.cambioPasswordForm.get('acepto').value) {
      return;
    }

    var alumno = new Alumno()
    alumno.Usuario = new Usuario();
    alumno.Centro = new Centro();
    alumno.Usuario.Password = this.cambioPasswordForm.get('password').value;
    alumno.Usuario.Correo =  this.cambioPasswordForm.get('correo').value;
    alumno.Nombre = this.cambioPasswordForm.get('nombre').value;
    alumno.Centro.CentroID = this.cambioPasswordForm.get('centro').value;
    alumno.ApellidoPaterno = this.cambioPasswordForm.get('primerApellido').value;
    alumno.ApellidoMaterno = this.cambioPasswordForm.get('segundoApellido').value;
    alumno.FechaNacimiento = this.cambioPasswordForm.get('fechaNacimiento').value;
    alumno.CURP = this.cambioPasswordForm.get('curp').value,
    alumno.Usuario.correoAlterno =  this.cambioPasswordForm.get('correoAlterno').value;

    this.alummnoService.crearCuentaAlumno(alumno).then(res => {
      if (res.Exito) {
        this.storePassword(alumno.CURP,alumno.Usuario.Password);
        this.router.navigate(['/registro-espera', alumno.CURP]);
      } else {
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }
    })
    .catch(err => {
      console.log("Error" +err);
      const message = `Ocurrio un error al conectar con el servidor`;

      const dialogData = new ConfirmDialogModel("Error", message, false);

      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => { });
    })   
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
    //const message = `¿Ocurrio un error al conectar con el servidor?`;

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  }

  checkPasswords(group: FormGroup) {
    if (!group || !group.get('password') || !group.get('confirmPassword')) {
      return null;
    }

    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value;
																																					  
    return ((!confirmPass) || (pass === confirmPass)) ? null : { notSame: true }
  }

  cadenisa(value) {
    return JSON.stringify(value)
  }

  validar() {
    this.dateError=false;

    if (this.cambioPasswordForm.get('fechaNacimiento').value == null){
      this.dateError=true;
    }
  }

  mostrarDescripcion() {
    this.mostrarDescripcionPass= true;
  }
  
  ocultarDescripcion() {
    this.mostrarDescripcionPass= false;
  }

  private async storePassword(username: string, password: string): Promise<any> {
    if (!(window as any).PasswordCredential) {
      return Promise.resolve();
    }

    const cred = new (window as any).PasswordCredential({
      id: username,
      password,
      name: username
    });
    return (navigator as any).credentials.store(cred);
  }  

  validarEmail(){
    let email = this.cambioPasswordForm.get('correo').value;
    let confirmEmail = this.cambioPasswordForm.get('confirmCorreo').value;

    if ((!confirmEmail) || (email === confirmEmail)) {
      this.correoCoincide = null; 
    }else {this.correoCoincide = true; }
  }

  onSelectCentro(entrando) {
    if(!entrando){
      var CentroID  =  this.cambioPasswordForm.get('centro').value;  
      if(CentroID != ""){
        this.centroService.obtenerRedes(CentroID).then( e => {
          if(e){
            this.redes = e;
          }
        }).catch(err => {
          console.log("Error" + err);
        });   
        
      }
    }
  }

  mensajeAyuda(): void{
    const dialogRef = this.dialog.open(CorreoAyudaComponent, {
      width: '50%',
      data: {Operation: 'Nuevo'}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || control.parent.hasError('notSame'));
  } 
}
