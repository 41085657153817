import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalleModBecasComponent } from '../_components/detalle-mod-becas/detalle-mod-becas.component';
import { CentroService } from '@app/_services/centro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; 
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { MatTableDataSource } from '@angular/material/table';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { first } from 'rxjs/operators';
import { ModificacionBecaSolicitud} from '@app/_models/modificacionBecaSolicitud';
import { ModificacionBecasService } from '@app/_services/modificacionBeca.service';
import { EstatusModificacionBeca } from '@app/_models/estatusModificacionBeca';
import { Solicitud } from '@app/_models/solicitud';
import { HistorialSolicitudComponent } from '@app/_components/historial-solicitud/historial-solicitud.component';
import { saveAs } from 'file-saver';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';

@Component({
  selector: 'app-modificacion-becas',
  templateUrl: './modificacion-becas.component.html',
  styleUrls: ['./modificacion-becas.component.scss']
})
export class ModificacionBecasComponent implements OnInit {

  displayedColumns: string[] = ['Colegio','Seccion', 'CURP', 'nombre', 'tipo','becaOrg','becaMod','estatus', 'acciones'];
  dataSource = new MatTableDataSource<ModificacionBecaSolicitud>();

  modificacioBecaForm: FormGroup;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('mySelEstatus') mySelEstatus: MatSelect;
  @ViewChild('allSelectedEstatus') private allSelectedEstatus: MatOption;

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  selectedCentros : Centro [];
  selectedSecciones : Seccion [];  
  estatusMod: EstatusModificacionBeca[] = [];
  ciclosBecas : Ciclo [];  

  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoEstatus = null;  
  cargandoCiclos = null;

  idUsuario: number;
  
  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
  
  filtro: string = "";
  
  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private centroService: CentroService,
    private seccionService: SeccionService,
    private cicloBecaService: CicloBecaService,
    private modificacionBecaService:ModificacionBecasService,
    public dialog: MatDialog) { }

    ngOnInit(): void {

      //this.dataSource.paginator = this.paginator;
      this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
  
      this.getCiclosBecas();
      this.cargarCentros();      
      this.obtenerEstatus();
      
      this.modificacioBecaForm = this.formBuilder.group({
        ciclo: ['', Validators.required],
        centros: [''],
        regiones: [''],
        secciones: [''],
        estatus: [''],
        nombre: ['']
      });  
    }

    getCiclosBecas() {
      this.cicloBecaService.consultaCiclos().then(ciclos => {
        this.ciclosBecas  = ciclos;
        this.cargandoCiclos = null;
      }).catch(err => {
        this.cargandoCiclos = "(Error)";
        console.log("Error" + err);
      });
    }

    cargarCentros() {
      this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
      }).catch(err => {
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      });
    }
  
    editarBeca(modificacion: ModificacionBecaSolicitud): void {
      const dialogRef = this.dialog.open(DetalleModBecasComponent, {
        width: '50%',
        data: {Operation: 'Editar', Objeto: modificacion}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.buscar();
        }
      });
    }
  
    obtenerEstatus() {
      this.modificacionBecaService.consultarEstatusModificacion().then(estatus => {
        this.estatusMod = estatus;
        this.cargandoEstatus = null;
      }).catch(err => {
        this.cargandoEstatus = "(Error)";
        console.log("Error" + err);
      });
    }
  
    verHistorial(solicitud:Solicitud){
      const dialogRef = this.dialog.open(HistorialSolicitudComponent, {
        width: '80%',
        data: {Operation: 'Editar', Objeto: solicitud}
      });
  
      dialogRef.afterClosed().subscribe(() => {});
    }
  
    onSelectedColegios(entrando) {
      if(!entrando){
        console.log("Cargando...");   
        var centros   = this.modificacioBecaForm.get('centros').value;  
        console.log("Centros " + JSON.stringify(centros));
  
        this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
          this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
          this.cargandoSecciones = null;
  
        }).catch(err => {
          this.cargandoSecciones = "(Error)";
          console.log("Error" + err);
        });
      }
    }  
   
    buscar() { 
      if ( this.modificacioBecaForm.invalid) {
        return
      }
      var cicloID = this.modificacioBecaForm.get('ciclo').value;  
      var centros = this.modificacioBecaForm.get('centros').value;  
      var secciones = this.modificacioBecaForm.get('secciones').value;  
      var estatusModificacion = this.modificacioBecaForm.get('estatus').value;  
      var nombre = this.modificacioBecaForm.get('nombre').value;  

      console.log("Estatus modificacion " + JSON.stringify(estatusModificacion));
      this.modificacionBecaService.listadoModBecas(cicloID,  centros, secciones, estatusModificacion,
        this.pageSize, (this.pageIndex+1), nombre, this.idUsuario).pipe(first())
      .subscribe(
        data => {
          this.length = data.Total;
          this.dataSource = new MatTableDataSource<ModificacionBecaSolicitud>(data.Dato);
        },
        () => {
        });
    }
  
    public getServerData(event?:PageEvent){
      this.pageSize = event.pageSize;
      this.pageIndex = event.pageIndex;      
      this.buscar();
  
      return event;
    }
  
    descargarDoc(modificacionBecaSol: ModificacionBecaSolicitud){
      console.log("Decargando archivo");
    
      this.modificacionBecaService.descargarDocumento(modificacionBecaSol.ModificacionBecaSolicitudID)
      .subscribe (
        data => {
          saveAs(data, modificacionBecaSol.NombreOriginal, {
            type: modificacionBecaSol.MymeType
         });
        }, 
        () => {
            const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
            this.dialog.open(AlertaComponent, {
            maxWidth: "500px",
            data: dialogData
          });
        }
      );
    }  
  

    omit_special_char(e: any) {
      if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    }

    toggleAllSelectionCentros() {
      if (this.allSelectedCentros.selected) {
        this.mySelCentro.options.forEach( (item : MatOption) => item.select());
      } else {
        this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
      }
    }
 
    tosslePerOneSecciones(all){ 
      if (this.allSelectedSecciones.selected) {  
       this.allSelectedSecciones.deselect();
       return false;
      }
      if(this.modificacioBecaForm.controls != null && 
        this.modificacioBecaForm.controls.userType != null &&
        this.modificacioBecaForm.controls.userType.value != null){
        if(this.modificacioBecaForm.controls.userType.value.length==this.centros.length)
          this.allSelectedSecciones.select();
      }
    }  
  
    toggleAllSelectionSeccion() {
      if (this.allSelectedSecciones.selected) {
        this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
      } else {
        this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
      }
    }
    
    tosslePerOneCentros(all){ 
      if (this.allSelectedCentros.selected) {  
       this.allSelectedCentros.deselect();
       return false;
      }
  
      if(this.modificacioBecaForm.controls != null && 
        this.modificacioBecaForm.controls.userType != null &&
        this.modificacioBecaForm.controls.userType.value != null){
        if(this.modificacioBecaForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
      }
    } 

    tosslePerOneEstatus(all){ 
      if (this.allSelectedEstatus.selected) {  
       this.allSelectedEstatus.deselect();
       return false;
      }
  
      if(this.modificacioBecaForm.controls != null && 
        this.modificacioBecaForm.controls.userType != null &&
        this.modificacioBecaForm.controls.userType.value != null){
          if(this.modificacioBecaForm.controls.userType.value.length==this.estatusMod.length)
            this.allSelectedEstatus.select();
      }
    }
  
    toggleAllSelectionEstatus() {
      if (this.allSelectedEstatus.selected) {
        this.mySelEstatus.options.forEach( (item : MatOption) => item.select());
      } else {
        this.mySelEstatus.options.forEach( (item : MatOption) => {item.deselect()});
      }
    }
}
