import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { AlertaComponent,ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-mensaje-propuesta',
  templateUrl: './mensaje-propuesta.component.html',
  styleUrls: ['./mensaje-propuesta.component.scss']
})
export class MensajePropuestaComponent implements OnInit {

  comment = false;

  editarMensaje = false;
  constructor(
    public dialogRef: MatDialogRef<MensajePropuestaComponent>,
    public dialogClose: MatDialogRef<MensajePropuestaComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: PropuestaSolicitud) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.editarMensaje = this.data.EditarComentarios;
    if (this.data.Comentarios){
      this.comment = true;
    }
  }

  onConfirm(): void {
    this.dialogRef.close(true);
    const dialogData = new ConfirmDialogModel('', "Comentario guardado", false);    
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });   
  }

  onNoClick(): void {
    if(this.data.Comentarios != ""){
      this.dialogClose.close();
    }
    else{this.data.Comentarios = "";}
    this.dialogClose.close();
  }  
}