import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ObjetoOp } from '@app/_models/objectOperation';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetalleAlumnoBecasComponent } from '../detalle-alumno-becas/detalle-alumno-becas.component';
import { avancePropuestaService } from '@app/_services/avance-propuesta.service'
import { AvancePropuesta } from '@app/_models/avancePropuesta';
import { DetalleCuotasBecasComponent } from '../detalle-cuotas-becas/detalle-cuotas-becas.component';
import { DetallePropuestaBecasComponent } from '../detalle-propuesta-becas/detalle-propuesta-becas.component';
import { DetalleResumenBecasComponent } from '../detalle-resumen-becas/detalle-resumen-becas.component';
import { DetalleAnalisisBecasComponent } from '../detalle-analisis-becas/detalle-analisis-becas.component';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { DetalleDatosBecasComponent } from '../detalle-datos-becas/detalle-datos-becas.component';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-propuesta-beca-tabs',
  templateUrl: './propuesta-beca-tabs.component.html',
  styleUrls: ['./propuesta-beca-tabs.component.scss']
})
export class PropuestaBecaTabsComponent implements OnInit {

  isAprobador : boolean;
  propuestaEditable : boolean;
  propuestaSolicitudesEditable : boolean;
  propuetaSolicitudEditableRev : boolean;
  @ViewChild('tabGenerales') generalesComponent:DetalleDatosBecasComponent;
  @ViewChild('tabAlumnos') alumnosComponent:DetalleAlumnoBecasComponent;
  @ViewChild('tabCuotas') coutasCompoent:DetalleCuotasBecasComponent;
  @ViewChild('tabPropuesta') propuestaComponent:DetallePropuestaBecasComponent;
  @ViewChild('tabResumen') ResumenComponent:DetalleResumenBecasComponent;
  @ViewChild('tabAnalisis') AnalisisComponent:DetalleAnalisisBecasComponent;
  
  public avance: AvancePropuesta = {
    PropuestaBecaID : -1,
    DatosGenerales : false,
    Alumnos : false,
    Cuotas : false,
    PropuestaBecas : false,
    ResumenBecas : false,
    Analisis : false
  };

  constructor(private avancepropuestaService:avancePropuestaService, 
    private authenticationService: AuthenticationService,
    private propuestaBecaService : PropuestaBecaService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }
 
  ngOnInit(): void {
    this.isAprobador =  this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA")  > -1;
    console.log("Es aprobador");
    
    if (this.data.Objeto) {
      this.permitirModifcarPropuesta();
      this.permitirModifcarSolicitudes();
    } 
   
    this.getAvance();
  }

  tabClick(tab) {
    console.log(tab.index);
    switch(tab.index){
      case 0 :  this.generalesComponent.setPropuestaEditable(this.propuestaEditable);
                break;
      case 1 : this.alumnosComponent.getSecciones(); 
                this.alumnosComponent.propuestaEditable = this.propuestaEditable;
                break;
      case 2 : this.coutasCompoent.getSecciones(); 
              this.coutasCompoent.propuestaEditable = this.propuestaEditable;
                break;
      case 3 : this.getAvance();
                this.propuestaComponent.getSecciones(); 
                this.propuestaComponent.propuestaEditable = this.propuestaEditable;
                this.propuestaComponent.propuestaSolicitudesEditable = this.propuestaSolicitudesEditable;
                this.propuestaComponent.propuetaSolicitudEditableRev = this.propuetaSolicitudEditableRev;
              this.propuestaComponent.updatePropuesta(this.data.Objeto);
                //this.propuestaComponent.propuesta = this.data.Objeto;
               break;
      case 4 :this.getAvance(); 
              this.ResumenComponent.getSecciones();
               break;
      case 5 :this.getAvance(); 
              this.AnalisisComponent.getSecciones(); 
              this.AnalisisComponent.propuestaEditable = this.propuestaEditable;
              this.AnalisisComponent.propuestaSolicitudesEditable = this.propuestaSolicitudesEditable;
              this.AnalisisComponent.propuetaSolicitudEditableRev = this.propuetaSolicitudEditableRev;

                break;
    }
  }

  getAvance() {
    if(this.data.Operation == "NUEVO") {
      this.avance.DatosGenerales = true;
      this.avance.Alumnos = true;
      this.avance.Cuotas = true;
      this.avance.PropuestaBecas = true;
      this.avance.ResumenBecas = true;
      this.avance.Analisis = true;
    }else{
      this.avancepropuestaService.obtenerAvance(this.data.Objeto.PropuestaBecaID).then(avance => {
        this.avance = avance
        this.propuestaComponent.alumnosCutoasCargados = this.avance.Alumnos && this.avance.Cuotas;
        this.ResumenComponent.alumnosCutoasCargados = this.avance.Alumnos && this.avance.Cuotas;
        this.AnalisisComponent.alumnosCutoasCargados = this.avance.Alumnos && this.avance.Cuotas;
        console.log("Avance " + JSON.stringify(this.avance));
      }).catch(err => console.log("Error" + err));
    }


  }

  permitirModifcarPropuesta(){
    if(this.data.Operation == 'Editar' ){
      this.propuestaBecaService.propuestaEditable(this.data.Objeto.ComplementoPropuesta.ComplementoPropuestaID).then( e => {
        if(e.Exito){
          this.propuestaEditable = e.Dato;
          this.generalesComponent.setPropuestaEditable(this.propuestaEditable);
          this.generalesComponent.propuestaEditable = this.propuestaEditable;
          this.alumnosComponent.propuestaEditable = this.propuestaEditable;
          this.coutasCompoent.propuestaEditable = this.propuestaEditable;
        }
  
      }).catch(err => {
        console.log("Error" + err);
      });  
    }else{
      this.propuestaEditable = true;
    }
  } 
  
  permitirModifcarSolicitudes(){
    console.log("permitirModifcarSolicitudes");
    var esRevision =  this.data.Operation == 'Revision';

    this.propuestaBecaService.propuestaSolicitudesEditable(this.data.Objeto.ComplementoPropuesta.ComplementoPropuestaID, esRevision, this.isAprobador).then( e => {
      if(e.Exito){
        if(esRevision){
          this.propuestaSolicitudesEditable = false;
          this.propuetaSolicitudEditableRev = e.Dato;
        }
        else{
          this.propuestaSolicitudesEditable = e.Dato;
          this.propuetaSolicitudEditableRev = false;
        }
      }
    }).catch(err => {
      console.log("Error" + err);
    });  

    /*
    if(this.data.Operation == 'Editar' ){

    }else{
      this.propuestaSolicitudesEditable = true;
      this.propuetaSolicitudEditableRev = false;
    }   */

  }
  

  public actualizarTabs( avancePropuesta : AvancePropuesta){
    this.avance = avancePropuesta;
  }
  


}