import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resumen, ResumenCentro, ResumenGeneral, ResumenGradoEducativo, ResumenSeccion } from '@app/_models/resumenGeneral';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaConteo } from '@app/_models/tipoBecaConteo';
import { environment } from '@environments/environment';
import { Node, Options } from 'ng-material-treetable';
import { ColumnDefinition } from 'ng-material-treetable/src/app/treetable/models';
import { TipoBecaService } from './tipoBeca.service';

@Injectable({ providedIn: 'root' })
export class ResumenGeneralService {

    constructor(private http: HttpClient) {}


    buscar(CicloID : number, regiones : number[], centros : string[], UsuarioID : number){
      return this.http.post<any>(`${environment.apiUrl}/ResumenGeneral`,  {CicloID, regiones, centros, UsuarioID} );
  
    }

    descargar(CicloID : number, regiones : number[], centros : string[], UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/DescargarResumenGeneral`,  {CicloID, regiones, centros, UsuarioID} );
    
      }    

    updateHeaderDefinition(columnsDefinition : ColumnDefinition[], tiposBeca : TipoBeca[] ):ColumnDefinition[] {
        var newColumns = [];
        columnsDefinition.forEach( x => {
            newColumns.push(x);
        })
        tiposBeca.forEach((value, index) => {
            newColumns.push({ name : value.Clave,         title : value.Nombre,           value:"TiposBeca", isArray : true, index : index * 2  });
            newColumns.push({ name : "Costo"+value.Clave, title : "Costo "+value.Nombre,  value:"TiposBeca", isArray : true, index : (index*2)+ 1 , isCurrency : true});
        });

        return newColumns;

    }


    convertResumenGeneralAResumen(ResumenGeneral : ResumenGeneral): Node<Resumen>[]{
        var arrayOfNodesTree : Node<Resumen>[]= [];

        ResumenGeneral.ResumenSecciones.forEach( x => {
            var node : Node<Resumen> =   {
                value : { Seccion :x.Seccion.Nombre, GradoEducativo : "",  Centro : "", Cege : "", Estimados : null,  TiposBeca :  [] },
                children : this.setChildrenSecciones(x)
              };
              node.value.Estimados = x.AlumnosEstimados;
              node.value.TiposBeca = this.seTiposBecaSeccion(x);
            arrayOfNodesTree.push(node);              
        });



          return arrayOfNodesTree;
    }




    setChildrenSecciones(ResumenSeccion :ResumenSeccion): Node<Resumen>[]{
        var arrayOfNodesTree : Node<Resumen>[] = [];
        ResumenSeccion.ResumenGradoEducativo.forEach( x => {

            var node : Node<Resumen> =   {
                value : { Seccion :ResumenSeccion.Seccion.Nombre, GradoEducativo : x.GradoEducativo.Nombre,  Centro : "",  Cege : "",Estimados : null,  TiposBeca :  [] },
                children : this.setChildrenGrados(x, ResumenSeccion)
            };
            node.value.Estimados = x.AlumnosEstimados;
            node.value.TiposBeca = this.seTiposBecaGrado(x);
            arrayOfNodesTree.push(node);
        });
        return arrayOfNodesTree;
    }


    setChildrenGrados(ResumenGradoEducativo :ResumenGradoEducativo, ResumenSeccion :ResumenSeccion): Node<Resumen>[]{
        var arrayOfNodesTree : Node<Resumen>[]= [];
        ResumenGradoEducativo.resumenCentros.forEach( x => {
            var node : Node<Resumen> =   {
                value : { Seccion :ResumenSeccion.Seccion.Nombre, 
                          GradoEducativo : ResumenGradoEducativo.GradoEducativo.Nombre,
                          EstatusPropuesta : x.EstatusPropuesta.Nombre,
                         Centro :  x.Centro.Nombre,
                         Cege : x.Centro.Cege,
                         Estimados : x.AlumnosEstimados,  TiposBeca : this.seTiposBeca(x)},
                children : []
              };
            arrayOfNodesTree.push(node);
        });

        return arrayOfNodesTree;
    }    

    

    seTiposBeca(ResumenCentro : ResumenCentro): number[]{
        var conteos : number[]= [];
        if(ResumenCentro.TipoBeca){
            ResumenCentro.TipoBeca.forEach( x => {
                conteos.push(x.Conteo);
                conteos.push(x.CostoAnual);
            });
        }
        return conteos;
    }    
   

    seTiposBecaGrado(ResumenGrado : ResumenGradoEducativo): number[]{
        var conteos : number[]= [];
        if(ResumenGrado.TipoBeca){
            ResumenGrado.TipoBeca.forEach( x => {
                conteos.push(x.Conteo);
                conteos.push(x.CostoAnual);
            });
        }
        return conteos;
    }        


    seTiposBecaSeccion(ResumenSeccion : ResumenSeccion): number[]{
        var conteos : number[]= [];
        if(ResumenSeccion.TipoBeca){
            ResumenSeccion.TipoBeca.forEach( x => {
                conteos.push(x.Conteo);
                conteos.push(x.CostoAnual);
            });
        }
        return conteos;
    }       
    



    init(): Node<Resumen>[]{
        var arrayOfNodesTree : Node<Resumen>[] = [
            {
              value : { Seccion : "", GradoEducativo : "",  Centro : "",  Cege : "",Estimados : null,  TiposBeca :  [null,null] },
              children : [ ]
            },
          ]; 
          return arrayOfNodesTree;
    }

}