

import { TipoBecaReq } from './tipoBecaReq';
import { TipoBecaSeccion } from './tipoBecaSeccion';

export class TipoBeca{

    TipoBecaID: number;
    Nombre: string;
    Clave : string;

    PorProceso : boolean;
    IncluirEnPresupuesto : boolean;
    MostrarEnPropuesta : boolean;
    TipoBecaRequisitoes: TipoBecaReq[];
    TipoBecaSeccions: TipoBecaSeccion[];

    NombresSecciones : string[];

    UsuarioID: number;
    Porcentaje : number;
    MostrarPorcentaje  : boolean;
}
