import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CicloService } from '@app/_services/ciclo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ciclo } from '@app/_models/ciclo';
import { first } from 'rxjs/operators';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Sobrepaso } from '@app/_models/sobrepaso';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { SobrepasoService } from '@app/_services/sobrepasos.service';
import { DatosOriginalesService } from '@app/_services/datosOriginales.service';

@Component({
  selector: 'app-detalle-adm-sobrepasos',
  templateUrl: './detalle-adm-sobrepasos.component.html',
  styleUrls: ['./detalle-adm-sobrepasos.component.scss']
})
export class DetalleAdmSobrepasosComponent implements OnInit {
  detalleSobrepaso: FormGroup;
  disabled_ = true;
  enableBtn = false;
  disableCurp = true;
  option = []
  ciclos: Ciclo[] = [];

  idSobrepaso = null;
  guardando = false;
  cargandoCiclos = null;
  hayErrores = false;
  idUsuario: number;

  archivoCargado = false
  tipoConsulta = false;
  selected = '';
  nombreArchivo = 'Ningún archivo seleccionado' 
  AlumnoPromedio : AlumnoPromedio;

  curpError = false;  
  noAlumno = false;
  errorMessage :string;
  error : boolean;
  ErrorExt: boolean = false;
  errorComentario: boolean = false;
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DetalleAdmSobrepasosComponent>,
    private cicloService: CicloService,
    private sobrepasoService : SobrepasoService,
    private datosOriginalesService : DatosOriginalesService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp
  ) { }
 
  ngOnInit() {
    this.errorComentario = false;
    this.hayErrores = false;
    this.guardando = false;
    this.cargandoCiclos = "(Cargando ...)";
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getCiclos();

    this.detalleSobrepaso = this.formBuilder.group({
      ciclo: [{ value: ''}, Validators.required],
      curp: [ '', [Validators.required]],
      centro: [{ value: '', disabled: this.disabled_ }],
      seccion: [{ value: '', disabled: this.disabled_, }],
      grado: [{ value: '', disabled: this.disabled_, }],
      alumno: [{ value: '', disabled: this.disabled_ }],
      promedioAca: [{ value: '', disabled: this.disabled_, }],
      promedioDis: [{ value: '', disabled: this.disabled_, }],
      adeudo: [{ value: '', disabled: this.disabled_, }],
      comentarios: ['', [Validators.required]],
    });

    this.detalleSobrepaso.get('curp').disable();
    
    if (this.data.Operation === 'EDITAR') {
      this.enableBtn = true;
      this.detalleSobrepaso.get('ciclo').disable();
      var sobrepaso : Sobrepaso = this.data.Objeto;
      this.AlumnoPromedio = sobrepaso.AlumnoPromedio;

      this.nombreArchivo = sobrepaso.NombreOriginal;
      this.detalleSobrepaso.get('ciclo').setValue( sobrepaso.AlumnoPromedio.Ciclo.CicloID);
      this.detalleSobrepaso.get('curp').setValue( sobrepaso.AlumnoPromedio.Alumno.CURP);
      if(sobrepaso.AlumnoPromedio.Centro != null){
        this.detalleSobrepaso.get('centro').setValue(sobrepaso.AlumnoPromedio.Centro.Nombre);
      }
      if(sobrepaso.AlumnoPromedio.Seccion != null){
        this.detalleSobrepaso.get('seccion').setValue(sobrepaso.AlumnoPromedio.Seccion.Nombre);
      }
      if(sobrepaso.AlumnoPromedio.GradoEducativo != null){
        this.detalleSobrepaso.get('grado').setValue(sobrepaso.AlumnoPromedio.GradoEducativo.Nombre);
      }

      var nombre = sobrepaso.AlumnoPromedio.Alumno.ApellidoPaterno + " " +
      sobrepaso.AlumnoPromedio.Alumno.ApellidoMaterno + " " +
      sobrepaso.AlumnoPromedio.Alumno.Nombre;
      this.detalleSobrepaso.get('alumno').setValue( nombre);
      this.detalleSobrepaso.get('promedioAca').setValue(sobrepaso.AlumnoPromedio.PromedioAcademico);
      this.detalleSobrepaso.get('promedioDis').setValue(sobrepaso.AlumnoPromedio.PromedioDisciplina == 0 ? 'N/A' :sobrepaso.AlumnoPromedio.PromedioDisciplina);
      this.detalleSobrepaso.get('comentarios').setValue(sobrepaso.Comentarios);

      if(this.AlumnoPromedio.Moroso){
        this.detalleSobrepaso.get('adeudo').setValue("1");
      }else{
        this.detalleSobrepaso.get('adeudo').setValue("0");

      }      
      this.disableCurp = true;
    }
    else {
      this.disableCurp = false;
    }    
  }

  cancelar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    
  }

  buscarAlumno(curp: string){
    let ciclo = this.detalleSobrepaso.get('ciclo').value;
    if(ciclo == null || ciclo == '' || ciclo.value == ''){
        return;
    }
    
    this.guardando = true;



    this.datosOriginalesService.buscarAlumnoSobrepaso(ciclo, curp).then( e => {
      if(e != null){
        this.AlumnoPromedio = e;
        if(this.AlumnoPromedio.Centro != null){
          this.detalleSobrepaso.get('centro').setValue(this.AlumnoPromedio.Centro.Nombre);
        }
        if(this.AlumnoPromedio.Seccion != null){
          this.detalleSobrepaso.get('seccion').setValue(this.AlumnoPromedio.Seccion.Nombre);
        }
        if(this.AlumnoPromedio.GradoEducativo != null){
          this.detalleSobrepaso.get('grado').setValue(this.AlumnoPromedio.GradoEducativo.Nombre);
        }
  
        if(this.AlumnoPromedio.Moroso){
          this.detalleSobrepaso.get('adeudo').setValue("1");
        }else{
          this.detalleSobrepaso.get('adeudo').setValue("0");

        }
        
        var nombre = this.AlumnoPromedio.Alumno.ApellidoPaterno + " " +
                    this.AlumnoPromedio.Alumno.ApellidoMaterno + " " +
                    this.AlumnoPromedio.Alumno.Nombre;
        this.detalleSobrepaso.get('alumno').setValue( nombre);


        if ( this.AlumnoPromedio.PromedioAcademico == 0){
          this.detalleSobrepaso.get('promedioAca').setValue('N/A');
        }else{
          this.detalleSobrepaso.get('promedioAca').setValue( this.AlumnoPromedio.PromedioAcademico);
        }
        if (this.AlumnoPromedio.PromedioDisciplina == 0){
          this.detalleSobrepaso.get('promedioDis').setValue('N/A');
        }else{
          this.detalleSobrepaso.get('promedioDis').setValue(this.AlumnoPromedio.PromedioDisciplina);
        }


        this.enableBtn = true;
      }else{
        this.noAlumno = true;
        this.detalleSobrepaso.get('ciclo').enable();
        this.detalleSobrepaso.get('centro').setValue("");
        this.detalleSobrepaso.get('seccion').setValue("");
        this.detalleSobrepaso.get('grado').setValue("");
        this.detalleSobrepaso.get('alumno').setValue("");
        this.detalleSobrepaso.get('promedioAca').setValue("");
        this.detalleSobrepaso.get('promedioDis').setValue("");
      }
      this.guardando = false;
    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  aceptar(): void {
    if (this.detalleSobrepaso.invalid){
      return
    }
    this.guardando = true;
    var sobrepaso = new Sobrepaso();
    sobrepaso.UsuarioID = this.idUsuario;
    sobrepaso.Comentarios = this.detalleSobrepaso.get('comentarios').value;
    sobrepaso.AlumnoPromedio = this.AlumnoPromedio;
    if(this.data.Objeto != null){
      sobrepaso.SobrepasoID =this.data.Objeto.SobrepasoID;
    }else{
      this.data.Objeto = sobrepaso;
    }
    
    this.sobrepasoService.guardarSobrepaso(sobrepaso).then( res =>{
      if (res.Exito) {
        this.data.Objeto.SobrepasoID = res.Dato;
        if(this.Archivo_ != null){
          this.importarDocumentos(res.Dato);
        }else{
          const dialogData = new ConfirmDialogModel('', "Información guardada", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });
          this.dialogRef.close(true);
        }
      }else{
        this.errorMessage = res.Mensaje;
        this.error = true;       
        this.guardando = false;
      }
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {});
  }

  validar() {
    this.noAlumno = false;
    this.enableBtn = false;
    
    var curp =  this.detalleSobrepaso.get('curp').value;
    if(curp != null){
      curp = curp.toUpperCase();
      this.curpError=false;
      if(curp != null && curp.length >0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado) {
          this.curpError=true;
        }
        else{
          this.buscarAlumno(curp);
        }
      }
    }
  }  

  verify() {
    this.errorComentario = false;
    if (this.detalleSobrepaso.controls.ciclo.valid){
      this.detalleSobrepaso.get('curp').enable();
    }
    if(this.detalleSobrepaso.controls.comentarios.invalid) this.errorComentario = true;
    if (this.detalleSobrepaso.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }
  }

  getCiclos() {
    this.cicloService.consultaCiclo().then( ciclos => {
      
      let ciclosActivos = ciclos.filter(c => {
        return new Date(c.FechaFin) >= new Date()
      })
      this.ciclos  = ciclosActivos;
      
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/pdf" || document.target.files[0].type == "application/docx" || 
    document.target.files[0].type == "application/doc"){
      this.tipoConsulta = true;
      this.archivoCargado = true;
      this.ErrorExt = false;
      this.enableBtn = true;
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.enableBtn = false;
      this.ErrorExt = true;
      this.nombreArchivo = "";
    }
  }

  importarDocumentos(SobrepasoID: number){ 

    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.enableBtn = false; 
        this.guardando = true;   
        this.sobrepasoService.importar(filesFD,SobrepasoID ).pipe(first())
        .subscribe(data => {
          this.guardando = false;
          this.enableBtn = true;
          if(data.Exito){
            const dialogData = new ConfirmDialogModel('', "Información guardada", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });
            this.dialogRef.close(true);
          }
        });      
        
      }else {
        console.log("Exceso de peso");
      }
    }else{
      this.dialogRef.close(true);
    }
  }
}