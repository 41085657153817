<mat-card-title class="card-title fz-20">Nuevo Sobrepaso</mat-card-title>
<mat-divider></mat-divider>
<br><br>
<form [formGroup]="detalleSobrepaso" class="modal-form" name="detalleSobrepaso" id="detalleSobrepaso">  

  <div class="row">
    <mat-form-field  class="input-full-width xs-width" appearance="outline">
        <mat-label>Ciclo</mat-label>
        <mat-select #mySelCentro name="ciclo" formControlName="ciclo" (ngModelChange)='verify()' 
          id="ciclo" required>
          <mat-option *ngFor="let ciclo of ciclos" [value]="ciclo.CicloID" > 
            {{ciclo.Clave}}
          </mat-option>
        </mat-select>
    </mat-form-field> 

    <mat-form-field  class="input-full-width xs-width" appearance="outline">
      <mat-label>CURP</mat-label>
      <input matInput formControlName="curp" (ngModelChange)="validar()" [disabled]="disableCurp" maxlength="18" required>
    </mat-form-field>
    <div *ngIf="curpError">
      <mat-error *ngIf="curpError"> El CURP tiene un formato inválido </mat-error>
    </div>    
      <mat-error *ngIf="noAlumno"> No se encontró información del  alumno para este ciclo </mat-error>
    <br>
  </div>

  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Colegio</mat-label>
        <input matInput  formControlName="centro" >  
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Sección</mat-label>
      <input matInput  formControlName="seccion">  
    </mat-form-field>
  </div>

  
  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Grado</mat-label>
      <input matInput  formControlName="grado">  
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Alumno</mat-label>
      <input matInput  formControlName="alumno">
    </mat-form-field>

  </div>

  <div class="row">


    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Promedio académico</mat-label>
      <input matInput formControlName="promedioAca" autocomplete="off" onPaste="return false">
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Promedio conducta</mat-label>
      <input matInput  formControlName="promedioDis" autocomplete="off" onPaste="return false">
    </mat-form-field>

  </div>
  <div class="row">


      <div class="input-full-width xs-width">
        <mat-label class="color-marino margin-right">Adeudo</mat-label>
        <mat-radio-group aria-label="Tiene adeudo" required formControlName="adeudo">
          <mat-radio-button style="margin-right: 1rem;" value="1"><label>Si</label></mat-radio-button>
          <mat-radio-button value="0"><label>No</label></mat-radio-button>
        </mat-radio-group>         
      </div>
  </div>
  <br>
  <mat-form-field class="input-full xs-width" appearance="outline">
    <mat-label>Comentarios</mat-label>
    <textarea matInput maxlength="300" formControlName="comentarios" rows="8" (ngModelChange)='verify()'></textarea>
  </mat-form-field>
  <mat-error *ngIf="errorComentario"> El comentario es requerido.</mat-error>
  <br>
  <label class="color-marino">Evidencia</label>
  <br><br>
  <div class="row">
    <button class="buttonSecondary" mat-raised-button (click)="seleccionarArchivo()"><span class='lato-bold espaciado margin-btn' style="font-size: 16px;">+ CARGAR ARCHIVO</span></button>
    <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
    <input class="hidden" id="upload-file" type="file" accept=".pdf, .doc" (change)="adjuntarArchivo($event)">
  </div> 
  <mat-error *ngIf="ErrorExt">Extensiones aceptadas: .pdf, .doc</mat-error>
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <br>
  <div class="modal-form margin-div">
    <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación" >CANCELAR</button>
    <button mat-raised-button color="primary" class="button xsbtn" (click)="aceptar()" style="margin-left: 1em;" [disabled]="!enableBtn || detalleSobrepaso.invalid || guardando" matTooltip="Guardar sobrepaso" >GUARDAR</button>
  </div>

  <div *ngIf="error">
    <br>
    <mat-card class='card-danger'>{{errorMessage}}</mat-card>
</div>  
</form>   