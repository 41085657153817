import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatOption, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Ciclo } from '@app/_models/ciclo';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { CicloTipoBeca } from '@app/_models/cicloTipoBeca';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { CicloCentroComponent } from '../ciclo-centro/ciclo-centro.component';
import { EspecificacionCicloCentroComponent } from '../especificacion-ciclo-centro/especificacion-ciclo-centro.component';

@Component({
  selector: 'app-detalle-ciclo',
  templateUrl: './detalle-ciclo.component.html',
  styleUrls: ['./detalle-ciclo.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DetalleCicloComponent implements OnInit {

  detalleCicloForm: FormGroup;
  enableBtn = false;
  minDate: Date;
  maxDate: Date;

  guardando = false;

  becas: TipoBeca[] = []; 
  selectedBecas : number [];  
  
  becasSelecconadas: CicloTipoBeca[] = []; 

  @ViewChild('allSelectedBecas') private allSelectedBecas: MatOption;
  @ViewChild('mySelBecas') mySelBecas: MatSelect;

  cargandoBecas = null;
  idUsuario: number;
  dateError :boolean;

  error: boolean = false;
  errorMessage: string = "";

  fechaCicloError = false;
  fechaSolError = false;
  fechaReviSolError = false;
  fechaRetroSolError = false;
  fechaEstudioError = false;
  fechaEvalError = false;
  fechaPropError = false;
  fechaRevPropError = false;
  fechaRetroProError = false;
  fechaPublicacionError = false;
  fechaBachilleratoError = false;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DetalleCicloComponent>,
    private becasSevice : TipoBecaService,
    private cicloBecaService:CicloBecaService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) {
      const currentYear = new Date().getFullYear();
      const currentMoth = new Date().getMonth();
      const currentDay = new Date().getDay();
      this.minDate = new Date(currentYear - 2, currentMoth, currentDay);
      this.maxDate = new Date(currentYear + 2, currentMoth, currentDay);
    }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.cargarBecas();
   
    if(this.data.Operation == 'Crear'){}

    this.detalleCicloForm = this.formBuilder.group({
      clave: ['', Validators.required],
      becas: ['', Validators.required],
      nombre: ['', Validators.required],
      FechaIni : ['',Validators.required],
      FechaFin : ['',Validators.required],
      FechaSolicitudIni : ['',Validators.required],
      FechaSolicitudFin : ['',Validators.required],
      FechaRevisionSolIni : ['',Validators.required],
      FechaRevisionSolFin : ['',Validators.required],
      FechaBachilleratoIni : ['',Validators.required],
      FechaBachilleratoFin : ['',Validators.required],
      FechaRetroSolIni : ['',Validators.required],
      FechaRetroSolFin : ['',Validators.required],
      FechaEstudioIni : ['',Validators.required],
      FechaEstudioFin : ['',Validators.required],
      FechaEvaluacionIni : ['',Validators.required],
      FechaEvaluacionFin : ['',Validators.required],
      FechaCreacionPropIni : ['',Validators.required],
      FechaCreacionPropFin : ['',Validators.required],
      FechaRevisionPropIni : ['',Validators.required],
      FechaRevisionPropFin : ['',Validators.required],
      FechaRetroPropIni : ['',Validators.required],
      FechaRetroPropFin : ['',Validators.required],
      FechaPublicacionIni : ['',Validators.required],
      FechaPublicacionFin : ['',Validators.required],
    });   

    if(this.data.Operation == 'Editar'){

      const ciclo = <Ciclo> this.data.Objeto;
      this.detalleCicloForm.get('clave').setValue(ciclo.Clave);    
      this.detalleCicloForm.get('nombre').setValue(ciclo.Nombre);    

      this.detalleCicloForm.get('FechaIni').setValue(ciclo.FechaInicio);    
      this.detalleCicloForm.get('FechaFin').setValue(ciclo.FechaFin);    

      this.detalleCicloForm.get('FechaSolicitudIni').setValue(ciclo.FechaSolicitudIni);    
      this.detalleCicloForm.get('FechaSolicitudFin').setValue(ciclo.FechaSolicitudFin);    
      this.detalleCicloForm.get('FechaRevisionSolIni').setValue(ciclo.FechaRevisionSolIni);    
      this.detalleCicloForm.get('FechaRevisionSolFin').setValue(ciclo.FechaRevisionSolFin);    
      this.detalleCicloForm.get('FechaRetroSolIni').setValue(ciclo.FechaRetroSolIni);    
      this.detalleCicloForm.get('FechaRetroSolFin').setValue(ciclo.FechaRetroSolFin);    
      this.detalleCicloForm.get('FechaEstudioIni').setValue(ciclo.FechaEstudioIni);    
      this.detalleCicloForm.get('FechaEstudioFin').setValue(ciclo.FechaEstudioFin);    
      this.detalleCicloForm.get('FechaEvaluacionIni').setValue(ciclo.FechaEvaluacionIni);    
      this.detalleCicloForm.get('FechaEvaluacionFin').setValue(ciclo.FechaEvaluacionFin);    
      this.detalleCicloForm.get('FechaCreacionPropIni').setValue(ciclo.FechaCreacionPropIni);    
      this.detalleCicloForm.get('FechaCreacionPropFin').setValue(ciclo.FechaCreacionPropFin);    
      this.detalleCicloForm.get('FechaRevisionPropIni').setValue(ciclo.FechaRevisionPropIni);    
      this.detalleCicloForm.get('FechaRevisionPropFin').setValue(ciclo.FechaRevisionPropFin);    
      this.detalleCicloForm.get('FechaRetroPropIni').setValue(ciclo.FechaRetroPropIni);    
      this.detalleCicloForm.get('FechaRetroPropFin').setValue(ciclo.FechaRetroPropFin);    
      this.detalleCicloForm.get('FechaPublicacionIni').setValue(ciclo.FechaPublicacionIni);    
      this.detalleCicloForm.get('FechaPublicacionFin').setValue(ciclo.FechaPublicacionFin);
      this.detalleCicloForm.get('FechaBachilleratoIni').setValue(ciclo.FechaSolicitudIni2);    
      this.detalleCicloForm.get('FechaBachilleratoFin').setValue(ciclo.FechaSolicitudFin2); 

      this.enableBtn = true;
      this.detalleCicloForm.get('clave').disable();
    }
  }

  onSubmit(){
    
    if(!this.detalleCicloForm.valid){
      console.log("Invalido");
      return
    }
    console.log("Guardando");

    this.error = false;
    this.guardando = true;

    var ciclo = new Ciclo();

    if (this.data.Operation === 'Editar') {
      const c = <Ciclo> this.data.Objeto;
      ciclo.CicloID = c.CicloID;
    }
    ciclo.Clave = this.detalleCicloForm.get('clave').value;
    ciclo.Nombre = this.detalleCicloForm.get('nombre').value;
    
    ciclo.FechaInicio = this.detalleCicloForm.get('FechaIni').value;
    ciclo.FechaFin = this.detalleCicloForm.get('FechaFin').value;
    ciclo.FechaSolicitudIni = this.detalleCicloForm.get('FechaSolicitudIni').value;
    ciclo.FechaSolicitudFin = this.detalleCicloForm.get('FechaSolicitudFin').value;
    ciclo.FechaRevisionSolIni = this.detalleCicloForm.get('FechaRevisionSolIni').value;
    ciclo.FechaRevisionSolFin = this.detalleCicloForm.get('FechaRevisionSolFin').value;
    ciclo.FechaRetroSolIni = this.detalleCicloForm.get('FechaRetroSolIni').value;
    ciclo.FechaRetroSolFin = this.detalleCicloForm.get('FechaRetroSolFin').value;
    ciclo.FechaEstudioIni = this.detalleCicloForm.get('FechaEstudioIni').value;
    ciclo.FechaEstudioFin = this.detalleCicloForm.get('FechaEstudioFin').value;
    ciclo.FechaEvaluacionIni = this.detalleCicloForm.get('FechaEvaluacionIni').value;
    ciclo.FechaEvaluacionFin = this.detalleCicloForm.get('FechaEvaluacionFin').value;
    ciclo.FechaCreacionPropIni = this.detalleCicloForm.get('FechaCreacionPropIni').value;
    ciclo.FechaCreacionPropFin = this.detalleCicloForm.get('FechaCreacionPropFin').value;
    ciclo.FechaRevisionPropIni = this.detalleCicloForm.get('FechaRevisionPropIni').value;
    ciclo.FechaRevisionPropFin = this.detalleCicloForm.get('FechaRevisionPropFin').value;
    ciclo.FechaRetroPropIni = this.detalleCicloForm.get('FechaRetroPropIni').value;
    ciclo.FechaRetroPropFin = this.detalleCicloForm.get('FechaRetroPropFin').value;
    ciclo.FechaPublicacionIni = this.detalleCicloForm.get('FechaPublicacionIni').value;
    ciclo.FechaPublicacionFin = this.detalleCicloForm.get('FechaPublicacionFin').value;
    ciclo.FechaSolicitudIni2 = this.detalleCicloForm.get('FechaBachilleratoIni').value; 
    ciclo.FechaSolicitudFin2 = this.detalleCicloForm.get('FechaBachilleratoFin').value;

    this.fechaCicloError = ciclo.FechaFin < ciclo.FechaInicio;    
    this.fechaSolError =  ciclo.FechaSolicitudFin < ciclo.FechaSolicitudIni;
    this.fechaReviSolError =  ciclo.FechaRevisionSolFin < ciclo.FechaRevisionSolIni;
    this.fechaRetroSolError =  ciclo.FechaRetroSolFin < ciclo.FechaRetroSolIni;
    this.fechaEstudioError =  ciclo.FechaEstudioFin < ciclo.FechaEstudioIni;
    this.fechaEvalError =  ciclo.FechaEvaluacionFin < ciclo.FechaEvaluacionIni;
    this.fechaPropError =  ciclo.FechaCreacionPropFin < ciclo.FechaCreacionPropIni;
    this.fechaRevPropError =  ciclo.FechaRevisionPropFin < ciclo.FechaRevisionPropIni;
    this.fechaRetroProError =  ciclo.FechaRetroPropFin < ciclo.FechaRetroPropIni;
    this.fechaPublicacionError =  ciclo.FechaPublicacionFin < ciclo.FechaPublicacionIni;
    this.fechaBachilleratoError =  ciclo.FechaSolicitudFin2 < ciclo.FechaSolicitudIni2;

    if(this.fechaCicloError || this.fechaSolError ||  this.fechaReviSolError || this.fechaRetroSolError 
      || this.fechaEstudioError || this.fechaEvalError || this.fechaPropError || this.fechaRevPropError
       || this.fechaRetroProError || this.fechaPublicacionError || this.fechaBachilleratoError){
      console.log("Error ");
      this.error = true;
      this.errorMessage = "La fecha de inicio debe ser menor a la  fecha de fin";
        return;
    }

    this.becasSelecconadas = [];

    this.selectedBecas.forEach(element => {
      if(element == 0){
        var cicloTipoBeca = new CicloTipoBeca();
        cicloTipoBeca.TipoBeca = new TipoBeca();
        cicloTipoBeca.TipoBeca.TipoBecaID = 0;
        this.becasSelecconadas.push(cicloTipoBeca);
      }else{
        var beca = this.becas.find(r => r.TipoBecaID == element);
        var cicloTipoBeca = new CicloTipoBeca();
        cicloTipoBeca.TipoBeca = beca;
        this.becasSelecconadas.push(cicloTipoBeca);
      }
    });

    ciclo.CicloTipoBecas = this.becasSelecconadas;
    ciclo.UsuarioID = this.idUsuario;

    this.cicloBecaService.post(ciclo)
      .pipe(first())
      .subscribe(
      data => {
        if (data.Exito){
          this.guardando = false;

        const dialogData = new ConfirmDialogModel('', 'Ciclo guardado correctamente', false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData 
        });

          this.dialogRef.close();
        }else{
          this.guardando = false;
          this.errorMessage = data.Mensaje;
          this.error = true;
        }        
      },
      error => {
    });
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }

  validarCaracteres(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = ""
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }


  cargarBecas(){
    const ciclo = <Ciclo> this.data.Objeto;

    this.becasSevice.consultaTiposBeca().then(becas => {
      this.becas = becas.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
      this.cargandoBecas = null;

      if (this.data.Operation === 'Editar') {
        this.selectedBecas = ciclo.CicloTipoBecas.map(x => x.TipoBeca.TipoBecaID);

        if(this.selectedBecas.includes(0)){
          this.selectedBecas = this.becas.map(x => x.TipoBecaID);
          this.selectedBecas.push(0);
        }
        this.detalleCicloForm.get('becas').setValue(this.selectedBecas);          
      }
    }).catch(err => {
      this.cargandoBecas = "(Error)";
      console.log("Error" + err);
    });
  }  

  toggleAllSelectionBeca() {
    if (this.allSelectedBecas.selected) {
      this.mySelBecas.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelBecas.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneBecas(all){ 
    if (this.allSelectedBecas.selected) {  
     this.allSelectedBecas.deselect();
     return false;
    }

    if(this.detalleCicloForm.controls != null && 
      this.detalleCicloForm.controls.userType != null &&
      this.detalleCicloForm.controls.userType.value != null){
      if(this.detalleCicloForm.controls.userType.value.length==this.becas.length)
        this.allSelectedBecas.select();
    }
  } 

  verify() {
    if (!this.detalleCicloForm.invalid){

      this.enableBtn = true;
    }
  } 

  especificacionXCentro(){
    const ciclo = <Ciclo> this.data.Objeto;
      const dialogRef = this.dialog.open(EspecificacionCicloCentroComponent, {
        width: '60%',
        data: {Operation: 'Editar', Objeto: ciclo}
      });
  }



  omit_special_char(e: any) {
    if (e.keyCode === 95 || e.key == "_" || e.charCode == 95
        || e.keyCode === 189 || e.key == "-" || e.charCode == 189) {
      return true;
    }
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
        return true;
    } else {  
        e.preventDefault();
        return false;
    }
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }
}
