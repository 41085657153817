export class Reporte {

  tipoReporte :number;
  CicloID : number;
  selectedRegiones : number[];
  selectedColegios :number[];
  selectedSecciones :number[];
  FechaInicial : Date;
  FechaFinal : Date;
  idUsuario: number;
}