import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-analisis-becas',
  templateUrl: './analisis-becas.component.html',
  styleUrls: ['./analisis-becas.component.scss']
})
export class AnalisisBecasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
