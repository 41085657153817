import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { ObjetoOp } from '@app/_models/objectOperation';
import { TipoBecaEspecificaciones } from '@app/_models/TipoBecaEspecificaciones';
import { TipoBecaSeccionGrado } from '@app/_models/tipoBecaSeccionGrado';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { TipoBecaService } from '@app/_services/tipoBeca.service';

@Component({
  selector: 'app-tipobeca-sec-grado',
  templateUrl: './tipobeca-sec-grado.component.html',
  styleUrls: ['./tipobeca-sec-grado.component.scss']
})
export class TipobecaSecGradoComponent implements OnInit {

  displayedColumnsSecciones: string[] = ['aplicable','Nombre'];
  displayedColumns: string[] = ['aplicable','Nombre']; 
  dataSourceSeccionGrado = new MatTableDataSource<TipoBecaSeccionGrado>();

  grados: GradoEducativo[] = []; 
  dataTipoBecaEspecificaciones : TipoBecaEspecificaciones;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  enableBtn = true;
  //tipoRequisito: string = "";

  constructor(    private gradoService: GradoEducativoService,
    private tipoBecaService : TipoBecaService,
    public dialogRef: MatDialogRef<TipobecaSecGradoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaEspecificaciones = data.Objeto;
    }

  ngOnInit(): void {
    this.enableBtn = true;
    if(this.dataTipoBecaEspecificaciones.BecaSeccionGrados == null ||this.dataTipoBecaEspecificaciones.BecaSeccionGrados.length == 0){
      this.initGrados();
    }else{
      this.dataSourceSeccionGrado = new MatTableDataSource<TipoBecaSeccionGrado>(this.dataTipoBecaEspecificaciones.BecaSeccionGrados);
      this.length = this.dataSourceSeccionGrado.data.length;
    }
  }

  initGrados(){
    this.guardando = true;
    var TipoBecaSeccionID = this.dataTipoBecaEspecificaciones.TipoBecaSeccion.TipoBecaSeccionID;
    this.tipoBecaService.getGradosXTipoBecaSeccion(TipoBecaSeccionID).then(grados => {
      this.grados = grados;
      this.initTable();
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  initTable(){
    this.dataTipoBecaEspecificaciones.BecaSeccionGrados = [];
    
    this.grados.forEach(
      x => {
        var becaSeccionGrado = new TipoBecaSeccionGrado();
        becaSeccionGrado.GradoEducativo = x;
        this.dataTipoBecaEspecificaciones.BecaSeccionGrados.push(becaSeccionGrado);
      }
      
    );
    this.dataSourceSeccionGrado = new MatTableDataSource<TipoBecaSeccionGrado>(this.dataTipoBecaEspecificaciones.BecaSeccionGrados);
    this.length = this.dataSourceSeccionGrado.data.length;
    this.guardando = false;
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }




}
