import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Solicitud } from '@app/_models/solicitud';
import { RevisionSolicitudesService } from '@app/_services/revision-solicitudes.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-mensaje-solicitud',
  templateUrl: './mensaje-solicitud.component.html',
  styleUrls: ['./mensaje-solicitud.component.scss']
})
export class MensajeSolicitudComponent implements OnInit {

  Solicitud : Solicitud;
  isCompleta : boolean;
  constructor(
    public dialogRef: MatDialogRef<MensajeSolicitudComponent>,
    public dialogClose: MatDialogRef<MensajeSolicitudComponent>,
    public dialog: MatDialog,
    public revisionSolicitudesService :RevisionSolicitudesService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) {
      dialogRef.disableClose = true;
      this.Solicitud = data.Objeto;
      if(this.data.Operation =="Completa"){
        this.isCompleta = true;
      }
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    console.log("Confirmado " +this.data.Operation);
    if(this.isCompleta){
      this.completa();
    }else{
      this.incompleta();
    }
  
  }

  onNoClick(): void {
    this.dialogClose.close();
  } 
  

  incompleta(): void {
    this.revisionSolicitudesService.solicitudIncompleta(this.Solicitud).then( res => {
      if(res.Exito){
        this.dialogRef.close(true);
        this.dialogClose.close(true);
      }else{
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        
      }
    }).catch(err => {
      console.log("Error"+ err);
    });
  }
  
  completa(): void {
    this.revisionSolicitudesService.solicitudCompleta(this.Solicitud).then( res => {
      if(res.Exito){
        this.dialogRef.close(true);
        this.dialogClose.close(true);
      }else{
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        
      }
    }).catch(err => {
      console.log("Error"+ err);
    });
  }


}
