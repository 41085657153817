import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { EspecificacionRequisito } from '@app/_models/especificacionRequisito';
import { OperationResult } from '@app/_models/operationResult';
import { TipoBecaReq } from '@app/_models/tipoBecaReq';

@Injectable({ providedIn: 'root' })
export class RequisitosService {

    constructor(private http: HttpClient) {}

    getEspecificaciones():Promise<EspecificacionRequisito[]>{
        return this.http.get<EspecificacionRequisito[]>(`${environment.apiUrl}/EspecificacionRequisitos`).toPromise();
    }

    comboRequisitos():Promise<RequisitoBeca[]>{
        return this.http.get<RequisitoBeca[]>(`${environment.apiUrl}/AllRequisitos`).toPromise();
    } 

    comboRequisitosNumericos(TipoBecaID: number):Promise<TipoBecaReq[]>{
        return this.http.get<TipoBecaReq[]>(`${environment.apiUrl}/${TipoBecaID}/GetRequisitosNumericosXBeca`).toPromise();
    } 

    listadoRequisitos(nRegistros:number, nPagina:number, filtro:string){
        return this.http.post<any>(`${environment.apiUrl}/ListadoRequisitos`,  {nRegistros, nPagina, filtro } );
    }

    post(requisito: RequisitoBeca){
        return this.http.post<any>(`${environment.apiUrl}/Requisitos`,  requisito );
    }

    delete(requisito: RequisitoBeca){
        let params = new HttpParams();
        params = params.append('RequisitoBecaID', requisito.RequisitoBecaID.toString() );

        return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/Requisitos`, { params } );
    }     
}