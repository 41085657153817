import { Component, OnInit, ViewChild } from '@angular/core';
import { Campus } from '@app/_models/campus';
import { Grado } from '@app/_models/grado';
import { Programa } from '@app/_models/programa';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';
import { CentroService } from '@app/_services/centro.service';
import { EstatusPropuestaService } from '@app/_services/estatusPropuesta.service ';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { MatTableDataSource } from '@angular/material/table';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { Solicitud } from '@app/_models/solicitud';
import { Empresa } from '@app/_models/empresa';
import { first } from 'rxjs/operators';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service'

import { PropuestaBecaTabsComponent } from '../_components/propuesta-beca-tabs/propuesta-beca-tabs.component';
import { HistorialPropuestaBecaComponent } from '@app/_components/historial-prop-beca/historial-prop-beca.component';
import { EstatusPropuesta } from '@app/_models/estatusPropuesta';


@Component({
  selector: 'app-revision-propuesta-becas',
  templateUrl: './revision-propuesta-becas.component.html',
  styleUrls: ['./revision-propuesta-becas.component.scss']
})
export class RevisionPropuestaBecasComponent implements OnInit {

  displayedColumns: string[] = [ 'Ciclo', 'Centro', 'MontoAprobado', 'Estatus', 'costo', 'porcentaje', 'acciones'];
  dataSource = new MatTableDataSource<PropuestaBeca>();

  revisionBeca: FormGroup;


  selectedCampus : Campus [];
  selectedInstituciones : string [];
  selectedGrados: Grado[] = [];
  selectedProgramas: Programa[] = [];
  selectedCiclos: Ciclo[] = [];

  campusSrc: Campus[] = [];
  institucionesSrc: string[] = [];
  gradosSrc: Grado[] = [];
  ciclosSrc: Ciclo[] = [];
  programasSrc: Programa[] = [];
  
  idUsuario: number;

  optionCampus = [];
  optionInstituciones:string[] = [];
  optionGrados = [];
  optionCiclos = [];
  optionProgramas = [];

  errorMessage: string = "";

  institucionFiltro:string ="";
  campusFiltro:string ="";
  gradoFiltro:string ="";
  programaFiltro:string ="";
  cicloFiltro:string ="";

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  nombreProspecto_:string = "";

  filtro: string = "";

  empresas: Empresa[] = [];
  centros: Centro[] = [];
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  estatusBecas: EstatusPropuesta[] = [];
  grados: GradoEducativo[] = [];
  selectedCentros : Centro [];
  selectedEmpresas : Empresa [];
  selectedRegiones : Region [];  
  selectedSecciones : Seccion [];   
  ciclosBecas : Ciclo [];  
  solicitudes : Solicitud [];  

  cargandoEmpresas = null;
  cargandoCentros = null;
  cargandoRegiones = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;

  errorMessageP: string = "";
  isAprobador : boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('allSelectedCampus') private allSelectedCampus: MatOption;
  @ViewChild('mySelCampus') mySelCampus: MatSelect;

  @ViewChild('allSelectedInstituciones') private allSelectedInstituciones: MatOption;
  @ViewChild('mySelInstituciones') mySelInstituciones: MatSelect;

  @ViewChild('mySelGrados') mySelGrados: MatSelect;

  @ViewChild('mySelProgramas') mySelProgramas: MatSelect;

  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption; 
  @ViewChild('mySelCiclos') mySelCiclos: MatSelect;

  @ViewChild('allSelectedEstatus') private allSelectedEstatus: MatOption;
  @ViewChild('mySelEstatus') mySelEstatus: MatSelect;

  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;
  @ViewChild('mySelEmpresas') mySelEmpresas: MatSelect;

  constructor( private formBuilder: FormBuilder, 
    public dialog: MatDialog,
    private authenticationService: AuthenticationService, 
    private regionService:RegionService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private propuestaBecaService:PropuestaBecaService,
    private estatusPropuestaService:EstatusPropuestaService,
    private cicloBecaService: CicloBecaService,) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.isAprobador =  this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA")  > -1;
    
    console.log("roles " + this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA"));
    console.log("roles " + this.isAprobador);

    this.getRegiones();    
    this.getCiclosBecas();

    this.revisionBeca = this.formBuilder.group({

      ciclos: ['',  Validators.required],
      centros: [''],
      regiones: [''],
      estatus: [''],

    }); 

    this.dataSource.paginator = this.paginator;
    this.getEstatusBecas()

    this.centroService.getbyUser(this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
      this.secciones = [];
      this.grados = [];
      this.selectedCentros = [];        
      this.selectedSecciones = [];
      this.selectedGrados = [];
      this.revisionBeca.get('centros').setValue(this.selectedCentros);
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  verSolicitud(propuesta:PropuestaBeca): void {
    const dialogRef = this.dialog.open(PropuestaBecaTabsComponent, {
      width: '95%',
      data: {Operation: 'Revision', Objeto: propuesta}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });    
  }

  verHistorial(propuesta:PropuestaBeca){
    const dialogRef = this.dialog.open(HistorialPropuestaBecaComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: propuesta}
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  toggleAllSelectionInstituciones() {
    if (this.allSelectedInstituciones.selected) {
      this.mySelInstituciones.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelInstituciones.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }
  
  tosslePerOneCiclo() { 

    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
  } 

  tosslePerOneCampus() { 

    if (this.allSelectedCampus.selected) {  
     this.allSelectedCampus.deselect();
     return false;
    }
  } 

  toggleAllSelectionCampus() {
    if (this.allSelectedCampus.selected) {
      this.mySelCampus.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCampus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneInstitucion() { 

    if (this.allSelectedInstituciones.selected) {  
    this.allSelectedInstituciones.deselect();
    return false;
    }
  } 

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }
 
  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  onSelectedRegiones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var regiones   = this.revisionBeca.get('regiones').value;  
      console.log("Regiones " + regiones);

      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
        this.secciones = [];
        this.grados = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.selectedGrados = [];
        this.revisionBeca.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }
    if(this.revisionBeca.controls != null && 
      this.revisionBeca.controls.userType != null &&
      this.revisionBeca.controls.userType.value != null){
      if(this.revisionBeca.controls.userType.value.length==this.centros.length)
        this.allSelectedRegion.select();
    }
  } 

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionBeca.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones;
        this.cargandoSecciones = null;

        this.grados = [];
        this.selectedGrados = [];

      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }    

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.revisionBeca.controls != null && 
      this.revisionBeca.controls.userType != null &&
      this.revisionBeca.controls.userType.value != null){
      if(this.revisionBeca.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclos.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCiclos.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneCiclos(all){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
    if(this.revisionBeca.controls != null && 
      this.revisionBeca.controls.userType != null &&
      this.revisionBeca.controls.userType.value != null){
      if(this.revisionBeca.controls.userType.value.length==this.centros.length)
      this.allSelectedCiclos.select();
    }
  } 
  
  toggleAllSelectionEstatus() {
    if (this.allSelectedEstatus.selected) {
      this.mySelEstatus.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelEstatus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneEstatus(all){ 
    if (this.allSelectedEstatus.selected) {  
     this.allSelectedEstatus.deselect();
     return false;
    }
    if(this.revisionBeca.controls != null && 
      this.revisionBeca.controls.userType != null &&
      this.revisionBeca.controls.userType.value != null){
      if(this.revisionBeca.controls.userType.value.length==this.estatusBecas.length)
      this.allSelectedEstatus.select();
    }
  } 

  buscar() { 
    let cicloID = this.revisionBeca.get('ciclos').value;  
    if(cicloID == null ||  cicloID == 0){
      return;
    }
    var centros = this.revisionBeca.get('centros').value;  
    var regiones = this.revisionBeca.get('regiones').value;  
    var estatus = this.revisionBeca.get('estatus').value;   
    console.log("Buscando");

    this.propuestaBecaService.buscarRevisionPropuestas(this.pageSize, (this.pageIndex+1), cicloID, centros, regiones,
    estatus, this.isAprobador, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<PropuestaBeca>(data.Dato);
      },
      () => {
        this.errorMessage = "Error al obtener la lista de propuestas";
      });
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  getEstatusBecas() {
      console.log("Buscando estatus" + this.isAprobador );
      this.estatusPropuestaService.consultarEstatusPropuestaRevisionAprobacion(this.isAprobador).then(estatus => {
        this.estatusBecas = estatus;
      }).catch(() => {
      });
  
  }

  
}
