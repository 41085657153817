import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Historial } from '@app/_models/historial';
import { ObjetoOp } from '@app/_models/objectOperation';
import { ModificacionBecas } from '@app/_models/modificacionBecas';

@Component({
  selector: 'app-historial-mod-becas',
  templateUrl: './historial-mod-becas.component.html',
  styleUrls: ['./historial-mod-becas.component.scss']
})
export class HistorialModBecasComponent implements OnInit {

  displayedColumns: string[] = ['Fecha', 'Descripcion', 'Usuario'];
  
  dataSource = new MatTableDataSource<Historial>();
  esperando = false;
  modificacion : ModificacionBecas;
  length:number = 0

  constructor(public dialogClose: MatDialogRef<HistorialModBecasComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.modificacion = this.data.Objeto;
  }

  onNoClick(): void {
    this.dialogClose.close();
  }
}
