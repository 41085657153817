import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NumberValueAccessor } from '@angular/forms';
import {  ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Solicitud } from '@app/_models/solicitud';
import { EstudioSolicitudService } from '@app/_services/estudioSolicitud.service';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Empresa } from '@app/_models/empresa';
import { ContactoEmpresa } from '@app/_models/contactoEmpresa';
import { TelefonoEmpresa } from '@app/_models/telefonoEmpresa';
import { EstudioEmpresa } from '@app/_models/estudioEmpresa';
import { DatosFamiliaresService } from '@app/_services/datosFamiliares.service';
import { DatosGeneralesService } from '@app/_services/datosGenerales.service';
import { SolicitudService } from '@app/_services/solicitud.service';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { CicloService } from '@app/_services/ciclo.service';
import { Ciclo } from '@app/_models/ciclo';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-resumen-solicitud',
  templateUrl: './resumen-solicitud.component.html',
  styleUrls: ['./resumen-solicitud.component.scss']
})
export class ResumenSolicitudComponent implements OnInit {

  Solicitud : Solicitud;
  idUsuario : number;
  AlumnoID : number;
  Ciclo: Ciclo;
  
  result: string = '';
  estudioForm: FormGroup;
  EstudioSolicitud : EstudioSolicitud;

  tipoBeca = ''
  nombreAlumno = ''
  costo = ''
  seccion = ''
  grado = ''
  colegio = ''
  nombrePadre = ''
  nombreMadre = ''
  nombreTutor = ''
  CURP = ''
  promedioAct = null
  promedioCond = null
  promedioAnt = null
  nombreEmpresa = ''

  fechaExtemporanea = null;
  auxPadre = false
  auxMadre = false
  auxTutor = false

  EsRenovacion = false;

  isAnahuac: boolean = false;
  guardando: boolean = false;
  requiereEstudio = false;

  constructor(private formBuilder: FormBuilder,    
    private authenticationService: AuthenticationService, 
    private solicitudService : SolicitudService,
    private seleccionBecaService : SeleccionBecaService,
    private estudioSolicitud : EstudioSolicitudService,
    private cicloService : CicloService,
    public datepipe: DatePipe,
    private datosGeneralesService : DatosGeneralesService,
    private familiaresService : DatosFamiliaresService,
    private estudioSolicitudService : EstudioSolicitudService,
    private router: Router,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EstudioSolicitud) {
      this.EstudioSolicitud = new EstudioSolicitud();
      this.EstudioSolicitud.EstudioEmpresa = new EstudioEmpresa();
      this.EstudioSolicitud.Empresa = new Empresa();
      this.EstudioSolicitud.Empresa.ContactoEmpresa = new ContactoEmpresa();
      this.EstudioSolicitud.Empresa.TelefonoEmpresa = new TelefonoEmpresa();
    }

  ngOnInit(): void {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    
    this.estudioSolicitudService.requiereEstudio(this.Solicitud.SolicitudID).then(requiereEstudio => {
      this.requiereEstudio = requiereEstudio;
      if(this.requiereEstudio){
        this.estudioSolicitud.datosEmpresasSolicitud(this.Solicitud.SolicitudID).then(empresaSol =>{
          if(empresaSol != null){
            this.nombreEmpresa = empresaSol.Empresa.NombreEmpresa
            this.EstudioSolicitud = empresaSol;
            var cost = empresaSol.EstudioEmpresa.Costo   
            this.costo = '$' + cost.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');         
          }
        }).catch(err => {
          console.log("Error" + err);
        }); 

      }
      
      }).catch(err => {
      console.log("Error" + err);
    }); 
    
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      this.becaExtemporanea();
      }).catch(err => {
      console.log("Error" + err);
    }); 
    this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{
      this.tipoBeca = Solicitud.TipoBeca.Nombre;
      this.promedioAct = Solicitud.PromedioActual;
      this.promedioAnt = Solicitud.PromedioAnterior;
      this.promedioCond = Solicitud.PromedioConducta;
      this.EsRenovacion = Solicitud.EsRenovacion;
      
    }).catch(err => {
      console.log("Error" + err);
    });  

    this.familiaresService.obtenerFamiliaresBeca(this.Solicitud.SolicitudID).then(solicitud =>{
      if( solicitud.Alumno.Responsables){
        solicitud.Alumno.Responsables.forEach(element => {
          if(element.ParentescoID == 1){
            this.auxPadre  = true;
            this.nombrePadre = element.Nombre + ' ' + element.ApellidoPaterno + ' ' + element.ApellidoMaterno
          }
          if(element.ParentescoID == 2){
            this.auxMadre  = true;
            this.nombreMadre = element.Nombre + ' ' + element.ApellidoPaterno + ' ' + element.ApellidoMaterno
          }
          if(element.ParentescoID == 3){
            this.auxTutor  = true;
            this.nombreTutor = element.Nombre + ' ' + element.ApellidoPaterno + ' ' + element.ApellidoMaterno
          }
        });
      }
    }).catch(err => {
      console.log("Error" + err);
    }); 

    this.datosGeneralesService.obtenerGeneralesBeca(this.Solicitud.SolicitudID).then(solicitud =>{
      this.Solicitud = solicitud;
      this.Solicitud.FechaFin = solicitud.Ciclo.FechaSolicitudFin;
      this.nombreAlumno = solicitud.Alumno.Nombre + ' ' + solicitud.Alumno.ApellidoPaterno  + ' ' + solicitud.Alumno.ApellidoMaterno
      this.colegio = solicitud.Centro.Nombre
      this.grado = solicitud.GradoEducativo.Nombre
      this.seccion = solicitud.Seccion.Nombre
      this.CURP = solicitud.Alumno.CURP
    }).catch(err => {
      console.log("Error" + err);
    }); 


  }

  save(){
    let latest_date = this.datepipe.transform(this.fechaExtemporanea ? this.fechaExtemporanea : this.Solicitud.FechaFin, 'dd/MM/yyyy');
    this.showMessage('Información', "Recuerda que debes completar la solicitud antes del día " + latest_date + " o tu solicitud será eliminada.", this.Solicitud.SolicitudID);
    this.solicitudService.enviarcorreoRecordatorio( this.Solicitud.SolicitudID).then( res =>{
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {
    }); 

    this.router.navigate(['/pantalla-principal']);
  }

  enviarSolicitud( ): void {
    const message = `¿Está seguro de que quiere enviar la solicitud?`;
    const dialogData = new ConfirmDialogModel("Confirmar", message);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if(this.result){
        this.Solicitud.UsuarioID = this.idUsuario;
        this.guardando = true;
        this.solicitudService.enviarSolictud(this.Solicitud).then( res => {
          if(res.Exito){
            this.guardando = false;
            const dialogData = new ConfirmDialogModel('', "Solicitud enviada", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });        
    
            dialogRef.afterClosed().subscribe( result => {
              this.router.navigate(['/pantalla-principal']);          
            });
                    
          }else{
            this.guardando = false;
            const dialogData = new ConfirmDialogModel('', "Hubo un error al enviar la solicitud", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            });        
          }
        }).catch(err => {
          this.guardando = false;
          console.log("Error"+ err);
        });
      }
    });
  }

  private showMessage(message: string, titulo: string, SolicitudID : number): MatDialogRef<AlertaComponent, any> {
    //const message = `¿Ocurrio un error al conectar con el servidor?`;

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef;
  }  


  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if(e.FechaFin){
        this.fechaExtemporanea = e.FechaFin;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  } 


  
}