import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { Reporte } from '@app/_models/reporte';

@Injectable({ providedIn: 'root' })
export class ReporteService {

  constructor(private http: HttpClient) {}

  obtieneReporte(reporte: Reporte): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Reporte`, reporte);
  }
}