

<br>
<form class="modal-form" name="detalleBecaForm" id="detalleBecaForm" [formGroup]="detalleBecaForm" autocomplete="off">

  <mat-form-field  class="input-full-width xs-width" appearance="outline">
    <mat-label>Becas</mat-label>
    <mat-select #mySelBecas name="beca" formControlName="beca"  id="beca" (ngModelChange)="onSelectTipoBeca()" required >
      <mat-option *ngFor="let beca of becas" [value]="beca.TipoBecaID"> 
        {{beca.Nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <div class="row" *ngIf="length == 0">
    <button mat-raised-button class="button-large" (click)="agregarEspecificacion()">+ AGREGAR ESPECIFICACION</button>
    <br><br>
  </div> -->
   
</form>    
<div class="example-form mat-elevation-z8">
  <mat-table [dataSource]="dataSourceEspecificaciones" >

      <ng-container matColumnDef="Nombre">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.TipoBecaSeccion.Seccion.Nombre}} </mat-cell>
      </ng-container>      
      
      <ng-container matColumnDef="xGrado">
        <mat-header-cell *matHeaderCellDef class="sub-title">Especificación por grado </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="Grado">
          <button mat-icon-button color="basic" matTooltip="Agregar especificación por grado"
              matTooltipPosition="right" (click)="especificacionXGrado(element)">
            <mat-icon>dns</mat-icon></button>
            <div class="row" *ngIf="element.BecaSeccionGrados.length > 0">
              <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar {{element.BecaSeccionGrados.length}} especificaciones"
              (click)="eliminarEspecificacionXGrados(element)"
              matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
            </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="xCentro">
        <mat-header-cell *matHeaderCellDef> Especificación por centro </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Especificación por centro"> 
          <button mat-icon-button color="basic" matTooltip="Agregar especificación por centro"
              matTooltipPosition="right" (click)="especificacionXCentro(element)">
            <mat-icon>dns</mat-icon></button>
            <div class="row" *ngIf="element.BecaSeccionCentros.length > 0">
              <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar {{element.BecaSeccionCentros.length}} especificaciones"
               (click)="eliminarEspecificacionXCentro(element)"   matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
            </div>
        </mat-cell>
      </ng-container> 

      <mat-header-row *matHeaderRowDef="displayedColumnsCiclos"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsCiclos;">
      </mat-row>
  </mat-table> 

  <div *ngIf="length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay especificaciones para mostrar.</span>
  </div>
  <br>
</div>
<div class="loading-screen-icon" *ngIf="guardando">
  <mat-spinner></mat-spinner>
</div>
<br><br><br><br>
<div *ngIf="length != 0"  class="modal-form margin-div">
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="guardando">GUARDAR</button>
</div>

<div *ngIf="error">
  <br>
  <mat-card class='card-danger'>{{errorMessage}}</mat-card>
</div>