import { DataSource } from '@angular/cdk/table';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Historial } from '@app/_models/historial';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';

@Component({
  selector: 'app-historial-prop-beca',
  templateUrl: './historial-prop-beca.component.html',
  styleUrls: ['./historial-prop-beca.component.scss']
})
export class HistorialPropuestaBecaComponent implements OnInit {

  displayedColumns: string[] = ['Fecha', 'Descripcion', 'Usuario'];
  
  dataSource = new MatTableDataSource<Historial>();
  esperando = false;
  propuesta : PropuestaBeca;

  constructor(public dialogClose: MatDialogRef<HistorialPropuestaBecaComponent>,
              private propuestaBecaService:PropuestaBecaService,
              @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.esperando = true;
    this.propuesta =   this.data.Objeto;
    this.propuestaBecaService.ObtenerHistorial(this.propuesta.ComplementoPropuesta.ComplementoPropuestaID).then(
      historial => {
        const orderHistorial  = historial.sort((a, b) => new Date(b.Fecha).getTime() - new Date(a.Fecha).getTime())
        this.esperando = false;
        this.dataSource = new MatTableDataSource<Historial>(orderHistorial);
      }
    ).catch( err => {
      console.log("Error " + err);
    });
  }

  onNoClick(): void {
    this.dialogClose.close();
  }
}
