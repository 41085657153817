import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjetoOp } from '@app/_models/objectOperation';
import { SolicitudService } from '@app/_services/solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { first } from 'rxjs/operators';
import { DocumentoRequisitoSolicitud } from '@app/_models/documentoRequisitoSol';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { CalificacionRequisitoSolicitud } from '@app/_models/calificacionRequisitoSolicitud';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-detalle-calificacion',
  templateUrl: './detalle-calificacion.component.html',
  styleUrls: ['./detalle-calificacion.component.scss']
})
export class DetalleCalificacionComponent implements OnInit {

  detalleCalfForm: FormGroup;
  enableBtn = false;

  error: boolean = false;
  errorMessage: string = "";
  esPruebaAptitud : boolean;
  tipoCalificacion : string = "";
  calificacionRequisitoSol : CalificacionRequisitoSolicitud;
  calificacionErr  = false;
  Solicitud:Solicitud;
  message: string = "";
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  
  nombreArchivo = 'Ningún archivo seleccionado'
  documentoRequisito : DocumentoRequisitoSolicitud;
  requisitoBeca : RequisitoBeca;

  constructor(private formBuilder: FormBuilder,
    private solicitudService: SolicitudService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetalleCalificacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.Solicitud = this.data.Objeto;
    if(!this.Solicitud.CalificacionEnsayo){
      this.Solicitud.CalificacionEnsayo = new CalificacionRequisitoSolicitud();
    }
    if(!this.Solicitud.CalificacionPruebaApt){
      this.Solicitud.CalificacionPruebaApt = new CalificacionRequisitoSolicitud();
    }
    
    this.requisitoBeca = new RequisitoBeca();

    if(this.data.Objeto.ClaveTipoRequisito == "PRUEBA_APTITUD"){
      this.tipoCalificacion ="PRUEBA DE APTITUD";
      this.esPruebaAptitud = true;
    }else{
      this.tipoCalificacion = this.data.Objeto.ClaveTipoRequisito.toUpperCase();
    }

    this.solicitudService.obtenerRequisito(this.data.Objeto.ClaveTipoRequisito).then( res =>{
      if (res != null) {
        this.requisitoBeca = res;
        console.log("sol" + JSON.stringify(this.requisitoBeca));
      }
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {

    });

    this.detalleCalfForm = this.formBuilder.group({
      calificacion: ['', Validators.required],
      comentarios: [''],
    });

    if(this.esPruebaAptitud){
      this.solicitudService.obtenerCalificacionPruebaAptitud(this.Solicitud).then( res =>{
        if (res.Exito) {
          this.calificacionRequisitoSol = res.Dato;
          this.detalleCalfForm.get('calificacion').setValue(res.Dato.Calificacion);    
          this.detalleCalfForm.get('comentarios').setValue(res.Dato.Comentarios);  
          this.nombreArchivo = res.Dato.NomberArchivo;
        this.enableBtn = true;
        }else{
          this.errorMessage = res.Mensaje;
          this.error = true;       
        }
      }).catch(err => {
        console.log("Error" + err);
      }).finally(() => {
    });

    }else{
      this.solicitudService.obtenerCalificacionEnsayo(this.Solicitud).then( res =>{
        if (res.Exito) {
          this.calificacionRequisitoSol = res.Dato;
          this.detalleCalfForm.get('calificacion').setValue(res.Dato.Calificacion);    
          this.detalleCalfForm.get('comentarios').setValue(res.Dato.Comentarios);  
        this.enableBtn = true;
        }else{
          this.errorMessage = res.Mensaje;
          this.error = true;       
        }
      }).catch(err => {
        console.log("Error" + err);
      }).finally(() => {
    });
    }
  }

  onSubmit(){
    this.error = false;
    if (this.detalleCalfForm.invalid) {
      return;
    }
    var cal = this.detalleCalfForm.get('calificacion').value;
    console.log("Cal " + cal);
    if(cal <0 || cal >10){
      this.error = true;
      this.errorMessage = "La calificación debe ser menor a 10";
      return;
    }
    this.message = "Se ha guardado correctamente";
    console.log("sigue" );

    if(this.esPruebaAptitud){
      this.Solicitud.CalificacionPruebaApt = new CalificacionRequisitoSolicitud();
      this.Solicitud.CalificacionPruebaApt.Calificacion = this.detalleCalfForm.get('calificacion').value;

      this.Solicitud.CalificacionPruebaApt.Comentarios  = this.detalleCalfForm.get('comentarios').value;

      this.solicitudService.guardarCalificacionPruebaAptitud(this.Solicitud).then( res =>{
        if (res.Exito) {
          this.importarPruebaAptitud();
          this.message = "Se ha guardado correctamente";
        const dialogData = new ConfirmDialogModel('', this.message, false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        this.dialogRef.close(true);
          this.dialogRef.close(true);
        }else{
          this.errorMessage = res.Mensaje;
          this.error = true;       
  
        }}).catch(err => {
          console.log("Error" + err);
        }).finally(() => {
      });;  
  
    }else{
      this.Solicitud.CalificacionEnsayo = new CalificacionRequisitoSolicitud();
      this.Solicitud.CalificacionEnsayo.Calificacion = this.detalleCalfForm.get('calificacion').value;
      this.Solicitud.CalificacionEnsayo.Comentarios  = this.detalleCalfForm.get('comentarios').value;
  
      this.solicitudService.guardarCalificacionEnsayo(this.Solicitud).then( res =>{
        if (res.Exito) {
          const dialogData = new ConfirmDialogModel('', this.message, false);

          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });
          this.dialogRef.close(true);
        }else{
          this.errorMessage = res.Mensaje;
          this.error = true;       
  
        }}).catch(err => {
          console.log("Error" + err);
        }).finally(() => {
    
      });;  
    }
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/pdf" || document.target.files[0].type == "application/docx" || 
    document.target.files[0].type == "application/doc"){
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.nombreArchivo = "";
    }
  }

  importarPruebaAptitud(){ 

    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.enableBtn = false; 
        //this.guardando = true;   
        this.solicitudService.importarPruebaAptitud(filesFD, this.Solicitud.SolicitudID).pipe(first())
        .subscribe(data => {
          if(data.Exito){
            this.dialogRef.close(true);
          }
        });

      }else {
        console.log("Exceso de peso");
      }
    }else{
      this.dialogRef.close(true);
    }
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    
  }

  verify() {
    if(!this.detalleCalfForm.invalid)
    {
      this.enableBtn = true;
      return true 
    }
    else{
      this.enableBtn = false;
    }
  }

  validarCalificacion() {

   this.calificacionErr = false;
    
    var calificacion = this.detalleCalfForm.get('calificacion').value;  
    if(calificacion != null){
      var promedioAcademicoAntNumber =  Number(calificacion);      
      if(promedioAcademicoAntNumber){
        if( promedioAcademicoAntNumber > 10 || promedioAcademicoAntNumber < 0){
          this.calificacionErr= true;
        }
      }
      else{
        this.calificacionErr= true;
      }

      if(!this.detalleCalfForm.invalid)
      {
        this.enableBtn = true;
      }
      else{
        this.enableBtn = false;
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  }
}
