import { Component, OnInit, ViewChild } from '@angular/core';
import { SolicitudAdmisionModel } from '@app/_models/solicitudAdmisionModel';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Campus } from '@app/_models/campus';
import { Grado } from '@app/_models/grado';
import { Ciclo } from '@app/_models/ciclo';
import { Programa } from '@app/_models/programa';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SolicitudAdmisionService } from '@app/_services/solicitud-admision.service';
import { CampusService } from '@app/_services/campus.service';
import { InstitucionService } from '@app/_services/institucion.service';
import { GradoService } from '@app/_services/grado.service';
import { CicloService } from '@app/_services/ciclo.service';
import { ProspectoDocumentoService } from '@app/_services/prospecto-documento.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { saveAs } from 'file-saver';
import { SolicitudAdmisionFilters } from '@app/_models/SolicitudAdmisionFilters';

@Component({
  selector: 'app-revision-estudio-socioeconomico',
  templateUrl: './revision-estudio-socioeconomico.component.html',
  styleUrls: ['./revision-estudio-socioeconomico.component.scss']
})
export class RevisionEstudioSocioeconomicoComponent implements OnInit {

  displayedColumns: string[] = ['colegio', 'seccion', 'id', 'nombre', 'acciones'];

  dataSource = new MatTableDataSource<SolicitudAdmisionModel>();
  descargaDocForm: FormGroup;

  campusControl = new FormControl();
  institucionesControl = new FormControl();
  gradosControl = new FormControl();
  ciclosControl = new FormControl();
  programasControl = new FormControl();
  nombreControl = new FormControl();

  idUsuario: number;

  optionCampus = [];
  optionInstituciones:string[] = [];
  optionGrados = [];
  optionCiclos = [];
  optionProgramas = [];

  institucionFiltro:string ="";
  campusFiltro:string ="";
  gradoFiltro:string ="";
  programaFiltro:string ="";
  cicloFiltro:string ="";

  campusSrc: Campus[] = [];
  institucionesSrc: string[] = [];
  gradosSrc: Grado[] = [];
  ciclosSrc: Ciclo[] = [];
  programasSrc: Programa[] = [];

  selectedCampus : Campus [];
  selectedInstituciones : string [];
  selectedGrados: Grado[] = [];
  selectedProgramas: Programa[] = [];
  selectedCiclos: Ciclo[] = [];

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  nombreProspecto_:string = "";
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('allSelectedCampus') private allSelectedCampus: MatOption;
  @ViewChild('mySelCampus') mySelCampus: MatSelect;

  @ViewChild('allSelectedInstituciones') private allSelectedInstituciones: MatOption;
  @ViewChild('mySelInstituciones') mySelInstituciones: MatSelect;

  @ViewChild('allSelectedGrados') private allSelectedGrados: MatOption;
  @ViewChild('mySelGrados') mySelGrados: MatSelect;

  @ViewChild('allSelectedProgramas') private allSelectedProgramas: MatOption;
  @ViewChild('mySelProgramas') mySelProgramas: MatSelect;

  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclos') mySelCiclos: MatSelect;


  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private solicitudAdmisionService: SolicitudAdmisionService,
    private campusService: CampusService,
    private institucionService: InstitucionService,
    private prospectoDocumentoService: ProspectoDocumentoService,
    public dialog: MatDialog) { }


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    //this.getSolicitudes();
    //this.getInstituciones();

    this.descargaDocForm = this.formBuilder.group({

      campusControl: [ this.campusSrc ],
      institucionesControl: [ this.institucionesSrc ],
      gradosControl: [ this.gradosSrc ],
      ciclosControl: [ this.ciclosSrc ],
      programasControl: [ this.programasSrc ],
      nombreControl: [ ' ']

    });    

  }

  getSolicitudes() {

    let filters:SolicitudAdmisionFilters = {
      idUsuario:this.idUsuario,
      institucion: this.institucionFiltro,
      campus: this.campusFiltro,
      grado: this.gradoFiltro,
      programa: this.programaFiltro,
      ciclo: this.cicloFiltro,
      nombre: this.nombreProspecto_
    }

    this.solicitudAdmisionService.get(this.pageSize, this.pageIndex + 1, filters)
    .subscribe(

      data => {

        this.programasSrc = data.Data.programas;
        this.campusSrc = data.Data.campus;
        this.gradosSrc = data.Data.grados;
        this.ciclosSrc = data.Data.ciclos;
        this.dataSource = new MatTableDataSource<SolicitudAdmisionModel>(data.Data.solicitudesAdmision);
        this.length = data.Data.nElements;
      }, 
      error => {
        console.log("solicitudAdmisionService >> GET-Error:", error);
      }
      
    );
  }

  getSolcitudesData(event?:PageEvent) {

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      

    //this.getSolicitudes();

    return event;
  }

  getInstituciones() { 

    this.institucionService.get(this.idUsuario)
    .subscribe(

      data => {
        this.institucionesSrc = data;
      },
      
      error=> {
        console.log("Error" + error);
      }  

    );

  }

  downloadDocs(idProspecto:number) {
   
    this.prospectoDocumentoService.download(idProspecto, this.idUsuario)
    .subscribe (

      data => {
        saveAs(data, idProspecto + '.zip', {
          type: 'application/zip' 
       });
      }, 

      error => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }

    );

  }

  filtrar() {
    if (this.nombreControl.value != null) {     
      this.nombreProspecto_ = this.nombreControl.value;     
      this.buscar();
    } 
  }
  
  tosslePerOneCampus() { 

    if (this.allSelectedCampus.selected) {  
     this.allSelectedCampus.deselect();
     return false;
    }

  } 

  toggleAllSelectionCampus() {
    if (this.allSelectedCampus.selected) {
      this.mySelCampus.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCampus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneInstitucion() { 

    if (this.allSelectedInstituciones.selected) {  
    this.allSelectedInstituciones.deselect();
    return false;
    }

  } 

  toggleAllSelectionInstituciones() {
    if (this.allSelectedInstituciones.selected) {
      this.mySelInstituciones.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelInstituciones.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneGrado() { 

    if (this.allSelectedGrados.selected) {  
     this.allSelectedGrados.deselect();
     return false;
    }

  } 

  toggleAllSelectionGrados() {
    if (this.allSelectedGrados.selected) {
      this.mySelGrados.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelGrados.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOnePrograma() { 

    if (this.allSelectedProgramas.selected) {  
     this.allSelectedProgramas.deselect();
     return false;
    }

  } 

  toggleAllSelectionProgramas() {
    if (this.allSelectedProgramas.selected) {
      this.mySelProgramas.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelProgramas.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneCiclo() { 

    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }

  } 

  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclos.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCiclos.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  buscar() { 
    this.campusFiltro = this.selectedCampus != undefined ? this.selectedCampus.toString() : "";
    this.institucionFiltro = this.selectedInstituciones != undefined ? this.selectedInstituciones.toString() : "";
    this.gradoFiltro = this.selectedGrados != undefined ? this.selectedGrados.toString() : "";
    this.programaFiltro = this.selectedProgramas != undefined ? this.selectedProgramas.toString() : "";
    this.cicloFiltro = this.selectedCiclos != undefined ? this.selectedCiclos.toString() : "";
    this.nombreProspecto_ = this.nombreControl.value != null && this.nombreControl.value != undefined ? this.nombreControl.value : "" ;

    if (this.campusFiltro.startsWith('1,')){ this.campusFiltro = ''; }
    if (this.institucionFiltro.startsWith('1,')){ this.institucionFiltro = ''; }
    if (this.gradoFiltro.startsWith('1,')){ this.gradoFiltro = ''; }
    if (this.programaFiltro.startsWith('1,')){ this.programaFiltro = ''; }
    if (this.cicloFiltro.startsWith('1,')){ this.cicloFiltro = ''; }

    this.pageSize = 10;
    this.pageIndex = 0;
    this.getSolicitudes();
  }  

}
