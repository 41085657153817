<form class="example-form" name="nuevaPropuestaAnalisis" id="nuevaPropuestaAnalisis" [formGroup]="nuevaPropuestaAnalisis" autocomplete="off">
  <div *ngIf="!alumnosCutoasCargados" class="mat-aviso">
    Deber cargar el conteo de alumnos y cuotas para la propuesta
  </div>

  <mat-form-field  class="inpunt-width xs-width" appearance="outline">
    <mat-label>Sección</mat-label>
    <mat-select  name="seccion" formControlName="seccion" id="seccion"   [disabled]='!alumnosCutoasCargados' (openedChange)="onSelectSeccion($event)" >
      <mat-option  [value]="0"><label>Todos</label></mat-option>              
        <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
          {{seccion.Nombre}}
        </mat-option>
    </mat-select>
  </mat-form-field>   

  <mat-form-field class="md-width" appearance="outline">
    <mat-label>Becas SEP:</mat-label>
    <input matInput [readonly]="!propuestaSolicitudesEditable" (ngModelChange)="calcular()" formControlName="becaSEP" (keypress)="validarCaracteres($event)" (keypress)="validarDuplicados($event)" maxlength="6" min="0" max="100" oninput="if(value>100){value=value.slice(0,2);}">
  </mat-form-field>
</form>
<br><br>
<div *ngIf="!revision" style="width: 70%; margin: 0 auto;">
  <button mat-raised-button color="danger" (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
  <button mat-raised-button color="danger" (click)="guardarBecaSEP()" class="buttonSecondary xsbtn">GUARDAR</button>
  <button mat-raised-button [disabled]="!propuestaSolicitudesEditable" (click)="enviar()" class="button xsbtn">ENVIAR</button>
</div>
<br><br>
<mat-card-title class="text-center fz-18">Becas</mat-card-title>
<div class="example-form mat-elevation-z8">

  <table  class="mat-elevation-z8" style="width: 100%;">
    <tr>
      <th>Concepto</th>
      <th>Ciclo anterior</th>
      <th>Ciclo actual</th>
      <th>Ciclo siguiente</th>
    </tr>

    <tr>
      <td class="text-left">Alumnado</td>
      <td>{{analisisComparativo.cicloAnterior.resumen.Alumnos | number : '1.0-0'}}</td>
      <td>{{analisisComparativo.cicloActual.resumen.Alumnos | number : '1.0-0'}}</td>
      <td>{{analisisComparativo.cicloPropuesto.resumen.Alumnos | number : '1.0-0'}}</td>        
    </tr>

    <tr>
      <td class="text-left">Becas</td>
      <td>{{analisisComparativo.cicloAnterior.resumen.BecasTotal | number : '1.2-2'}}</td>
      <td>{{analisisComparativo.cicloActual.resumen.BecasTotal | number : '1.2-2'}}</td>
      <td>{{analisisComparativo.cicloPropuesto.resumen.BecasTotal | number : '1.2-2'}}</td>        
    </tr>   

    <tr>
      <td class="text-left">Porcentaje de becas</td>
      <td>{{(analisisComparativo.cicloAnterior.resumen.PorcentajeSobreAlumnos*100) | number : '1.2-2'}}%</td>
      <td>{{(analisisComparativo.cicloActual.resumen.PorcentajeSobreAlumnos*100) | number : '1.2-2'}}%</td>
      <td>{{(analisisComparativo.cicloPropuesto.resumen.PorcentajeSobreAlumnos*100) | number : '1.2-2'}}%</td>        
    </tr>            

    <tr>
      <td class="text-left">Asignadas SEP</td>
      <td></td>
      <td></td>
      <td>{{becaSEP| currency:'MXN'}}</td>        
    </tr> 

    <tr>
      <td class="text-left">Costo propuesta</td>
      <td>{{analisisComparativo.cicloAnterior.resumen.CostoBecaAnual | currency:'MXN'}}</td>
      <td>{{analisisComparativo.cicloActual.resumen.CostoBecaAnual | currency:'MXN'}}</td>
      <td>{{analisisComparativo.cicloPropuesto.resumen.CostoBecaAnual | currency:'MXN'}}</td>        
    </tr>            

    <tr>
      <td class="text-left">Costo anual</td>
      <td>{{analisisComparativo.cicloAnterior.resumen.CostoBecaAnual | currency:'MXN'}}</td>
      <td>{{analisisComparativo.cicloActual.resumen.CostoBecaAnual | currency:'MXN'}}</td>
      <td>{{analisisComparativo.cicloPropuesto.resumen.CostoBecaAnual +becaSEP | currency:'MXN'}}</td>        
    </tr> 
  </table>
</div>         
<br><br>
<mat-card-title class="text-center fz-18">Presupuesto</mat-card-title>
<div class="example-form mat-elevation-z8">
  
  <table  class="mat-elevation-z8" style="width: 100%;">
    <tr>
      <th>Presupuesto para becas</th>
      <th>Monto propuesto de beca</th>
      <th>Diferencia</th>
      <th>Número de colegiaturas</th>
    </tr>
    <tr> 
      <td>{{analisisComparativo.MontoAprobado | currency:'MXN'}}</td>
      <!--td>{{analisisComparativo.cicloPropuesto.resumen.CostoBecaAnual | currency:'MXN'}}</td>
      <td>{{analisisComparativo.Diferencia | currency:'MXN'}}</td-->      
      <td>{{montoPropuesta | currency:'MXN'}}</td>
      <td>{{diferencia | currency:'MXN'}}</td>
       <td>{{NumeroColegiaturas}}</td>
    </tr>
  </table>
</div>
<br>
<div class="loading-screen-icon"  *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>

<div class="example-form" *ngIf="revision" style="text-align: end;">
  <button mat-raised-button color="danger" (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>    
  <button mat-raised-button (click)="rechazar()"  [disabled]="!propuetaSolicitudEditableRev" class="button xsbtn">RECHAZAR</button>
  <button mat-raised-button *ngIf="!isAprobador" (click)="revisar()" [disabled]="!propuetaSolicitudEditableRev" class="button xsbtn">REVISAR</button>  
  <button mat-raised-button *ngIf="isAprobador" (click)="aprobar()" [disabled]="!propuetaSolicitudEditableRev" class="button xsbtn">APROBAR</button>
</div>
<br><br><br><br>