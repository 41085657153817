<mat-card class="seleccion-beca-card">
    <br>
    <div class="example-form">
      <mat-card-title class="text-left fz-26">MODIFICACIÓN DE BECAS.</mat-card-title>
      <mat-divider></mat-divider>
    </div>
    <br><br> 
    <div class="mdl-form">
      <mat-label class="color-azul">Filtrar por:</mat-label>
    </div>
    <form  class="mdl-form" name="modificacioBecaForm" id="modificacioBecaForm" [formGroup]="modificacioBecaForm">      
    
      <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Ciclos</mat-label>
        <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" id="ciclo"  >
          <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
              {{ciclo.Clave}}
          </mat-option> 
        </mat-select>
      </mat-form-field>    
     
      <mat-form-field  class="input-centro-width xs-width" appearance="outline">
        <mat-label>Colegios</mat-label>
        <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
          <mat-option #allSelectedCentros (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
          <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
            (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
            {{centro.Nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Secciones</mat-label>
        <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones"  multiple>
            <mat-option #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
            <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
              (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
              {{seccion.Nombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>   
  
      <mat-form-field appearance="outline" class="inpunt-width xs-width" >
        <mat-label>Estatus</mat-label>
        <mat-select #mySelEstatus name="estatus" formControlName="estatus"
            id="estatus"  multiple>
            <mat-option  #allSelectedEstatus  (click)="toggleAllSelectionEstatus()" [value]="0"><label>Todos</label></mat-option>
            <mat-option *ngFor="let est of estatusMod" [value]="est.EstatusModificacionBecaID" 
                        (click)="tosslePerOneEstatus(allSelectedEstatus.viewValue)"> 
                        {{est.Nombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="Nombre del prospecto" value="" id="nombreProspecto"
        formControlName="nombre"  name="nombre" >
      </mat-form-field>
      <br><br>
  
      <div class="row">
        <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
      </div>
  
    </form>
    <br>
    <div class="mdl-form mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>
      
        <ng-container matColumnDef="Colegio">
          <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Colegio </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="Colegio">
            {{element.Solicitud.Centro.Nombre}}
          </mat-cell>
        </ng-container>    
          
        <ng-container matColumnDef="Seccion">
          <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Sección </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="Sección">
            {{element.Solicitud.Seccion.Nombre}}
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="CURP">
          <mat-header-cell *matHeaderCellDef class="sub-title"> CURP </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="CURP">
            {{element.Solicitud.Alumno.CURP}}
          </mat-cell>
        </ng-container>
            
        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre del alumno</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="nombre"> 
            {{element.Solicitud.Alumno.Nombre}} {{element.Solicitud.Alumno.ApellidoPaterno}} {{element.Solicitud.Alumno.ApellidoMaterno}}
          </mat-cell>
        </ng-container>  

        <ng-container matColumnDef="tipo">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Tipo de beca </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="tipo"> 
            {{element.TipoBeca.Nombre}} 
          </mat-cell>
        </ng-container>  
            
        <ng-container matColumnDef="becaOrg">
          <mat-header-cell style="display:flex; justify-content:center;" *matHeaderCellDef class="sub-title"> % Beca <br> original </mat-header-cell>
          <mat-cell style="display:flex; justify-content:center;" *matCellDef="let element" data-label="becaOrg"> 
            {{element.BecaOriginal}}%
          </mat-cell>
        </ng-container>  
            
        <ng-container matColumnDef="becaMod">
          <mat-header-cell style="display:flex; justify-content:center;" *matHeaderCellDef class="sub-title"> % Beca <br> modificada </mat-header-cell>
          <mat-cell style="display:flex; justify-content:center;" *matCellDef="let element" data-label="becaMod"> 
            {{element.BecaModificada}}%
          </mat-cell>
        </ng-container>  
  
        <ng-container matColumnDef="estatus">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Estatus</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="estatus"> 
            {{element.EstatusModificacionBeca.Nombre}} 
          </mat-cell>
        </ng-container>      
  
        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
              <button mat-icon-button color="basic" matTooltip="Editar beca" matTooltipPosition="right" (click)="editarBeca(element)">
                <mat-icon>edit</mat-icon></button>
                <!--button mat-icon-button color="basic" matTooltip="Descargar documento" [disabled]="!element.TieneArchivo"   matTooltipPosition="right" (click)="descargarDoc(element)">
                  <mat-icon>cloud_download</mat-icon></button-->
              <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element.Solicitud)" matTooltip="Historial">
                <mat-icon>history</mat-icon></button>           
            </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
  
      </mat-table>    

      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
      </div>
      
      <mat-paginator #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 20]"
        (page)="pageEvent = getServerData($event)" >
      </mat-paginator>
    </div>         
    <br><br>
  </mat-card>