import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleCicloComponent } from '../_components/detalle-ciclo/detalle-ciclo.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { CicloBecaService } from '@app/_services/cicloBeca.service'
import { FormBuilder } from '@angular/forms';
import { Ciclo } from '@app/_models/ciclo';
import { ConfiguracionReqComponent } from '@app/configuracion-req/configuracion-req.component';
import { ConfiguracionBecaComponent } from '@app/configuracion-beca/configuracion-beca.component';
import { EspecificacionBecaComponent } from '@app/especificacion-beca/especificacion-beca.component';
import { EspecificacionReqComponent } from '@app/especificacion-req/especificacion-req.component';

@Component({
  selector: 'app-apertura-ciclos',
  templateUrl: './apertura-ciclos.component.html',
  styleUrls: ['./apertura-ciclos.component.scss']
})
export class AperturaCiclosComponent implements OnInit {

  @ViewChild('tabRequisitos') requisitosComponent:ConfiguracionReqComponent;
  @ViewChild('tabBecas') becasComponent:ConfiguracionBecaComponent;
  @ViewChild('tabEspBecas') especificacionComponent:EspecificacionBecaComponent;
  @ViewChild('tabEspReq') especificacionReqComponent:EspecificacionReqComponent;


  displayedColumnsCiclos: string[] = ['Clave', 'Nombre', 'Opciones'];
  dataSource = new MatTableDataSource<Ciclo>();

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  pageEvent: PageEvent;
  
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  
  filtro: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, private cicloBecaService:CicloBecaService, 
    private formBuilder: FormBuilder) { }

    tabClick(tab) {
      console.log(tab.index);
      switch(tab.index){
        case 0 :  this.requisitosComponent.buscar();
                  break;
        case 1 : this.becasComponent.buscar();
                  break;
        case 2 : this.especificacionComponent.iniciarTiposBeca();
                  break;
        case 3 : this.especificacionReqComponent.iniciarTiposBeca();
                 break;
        case 4 :
                 break;
      }
    }



  nuevoCiclo(): void{
    const dialogRef = this.dialog.open(DetalleCicloComponent, {
      width: '800px',
      data: {Operation: 'Crear'}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscar();
    });
  }

  editarCiclo(ciclo:Ciclo): void {
    const dialogRef = this.dialog.open(DetalleCicloComponent, {
      width: '800px',
      data: {Operation: 'Editar', Objeto: ciclo}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.buscar();
    });
  }

  eliminarCiclo(ciclo:Ciclo): void {

    console.log("ciclo " + JSON.stringify(ciclo));
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar el ciclo?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.cicloBecaService.delete(ciclo)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscar();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
              });
            }            
          },
          error => {
            this.error = true;
            this.errorMessage = "Error al eliminar el requisito";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedio un error al eliminar el requisito.', false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
            });
          });
      }
    });    
  }

  buscar() { 
    this.cicloBecaService.listadoCiclos(this.pageSize, (this.pageIndex+1), this.filtro).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<Ciclo>(data.Dato);
      },
      error => {
        this.errorMessage = "Error al obtener la lista de requisitos";
      });
  }
  
  getCicloActivos() {
    this.cicloBecaService.listadoCiclos(this.pageSize, (this.pageIndex+1), this.filtro).pipe(first())
    .subscribe(
      data => {
        let ciclosActivos = data.Dato.filter(c => {
          return new Date(c.FechaFin) >= new Date()
        })
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<Ciclo>(ciclosActivos);
      },
      error => {
        this.errorMessage = "Error al obtener la lista de requisitos";
      });
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getCicloActivos();
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  filterText(){
    setTimeout (() => {
      console.log("Buscando " + this.filtro);
      this.buscar();      
    }, 1000);    

    this.pageIndex = 0;
    if(this.filtro == "") this.getCicloActivos();
    else this.buscar();
  }




}