import { Component, OnInit } from '@angular/core';
import { ActivationService } from '@app/_services/activacion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioRecuperacion } from '@app/_models/usuarioRecuperacion';


@Component({
  selector: 'app-activar',
  templateUrl: './activar.component.html',
  styleUrls: ['./activar.component.scss']
})
export class ActivarComponent implements OnInit {
  
  usuario : UsuarioRecuperacion;
  activa = false;

  /*
  constructor(   
    private route: ActivatedRoute,
    private router: Router,
    private activationService: ActivationService) { }
    */
   constructor(private activatedRoute: ActivatedRoute,
          private router: Router,
          private activacionService:ActivationService) {
    this.activatedRoute.queryParams.subscribe(params => {
          
          if(params != null){
            console.log("redirec 1"); 
            if(params['id'] != null  && params['token'] != null){
              this.usuario = { UsuarioID : params['id'], Token : params['token']  };


            }else{
              this.router.navigate(['/login']);
            }

          }

      });
  }

  ngOnInit() {
    console.log(this.usuario); 

    this.activacionService.activarCuenta(this.usuario).then( res => {
      if(!res.Exito){
        this.router.navigate(['/login']);
      }else{
        this.activa = true;
      }
    }).catch(err => {
      console.log("Error"+ err);
      this.router.navigate(['/login']);
    });


  }

}
