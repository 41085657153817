import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Area } from '@app/_models/area';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(private http: HttpClient) { }

  get(){
    return this.http.get<Area[]>(`${environment.apiUrl}/area`);
  }
}
