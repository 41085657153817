import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Seccion } from '@app/_models/seccion';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { MatTableDataSource } from '@angular/material/table';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { PropuestaBecaSolicitudService } from '@app/_services/propuestaBecaSolicitud.service';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { PageEvent} from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  calculaValores,
  getSecciones,
  getTiposBeca,
  maxLengthCheck,
  minMaxValidationFunction,
  onNoClick,
  onSelectSeccion,
  operacionCosto,
  reflejaAprobado,
  save,
  updatePropuesta,
  validarPorcentaje,
  verComentarios
} from './methods';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-detalle-propuesta-becas',
  templateUrl: './detalle-propuesta-becas.component.html',
  styleUrls: ['./detalle-propuesta-becas.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({
        height: '0px',
        minHeight: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      transition('expanded <=> collapsed, void => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DetallePropuestaBecasComponent implements OnInit {
  // columnas de la tabla fija
  displayedColumnsFija: string[] = ['num', 'nombre', 'tipo', 'becaact', 'becasug', 'becaprop', 'becaori',
    'BecaSep', 'AplicaReinscripcion', 'AplicaInscripcion', 'condicionada', 'costoanual', 'comentarios'
  ]

  // columnas a mostrar en tabla expandible
  displayedColumnsDespleglable: string[] = ['id', 'seccion', 'grado', 'nr', 'moroso', 'costocol',
    'costoreins', 'promdis', 'promaca', 'aptitud', 'ensayo'
  ]


  expandedElement: PropuestaSolicitud | null;

  isAprobador: boolean;
  revision = false
  nuevaPropuesta: FormGroup;
  costoPropuesto: number;
  costoPropuestoSemestre: number;
  porcentajePropuesto: number;
  propuestaEditable: boolean;
  becasSep: number;
  porcentajeBecasSep: number;
  costoBecasExternas: number;
  costoBecasExternasSemestre: number;

  propuestaSolicitudesEditable: boolean;
  propuetaSolicitudEditableRev: boolean;

  pageIndex: number = 0;
  pageSize: number = 10;
  length: number = 0
  error: boolean = false;
  filtro: string = "";
  errorMessage: string = "";
  pageEvent: PageEvent;

  coordinacionName = "";
  colegioName = "Colegio no disponible";

  propuesta: PropuestaBeca;
  idColegio: number
  idUsuario: number;
  enableBtn = false;
  esperando = false;
  esCoordinador = false;
  esRevision = false;
  verde = true;
  semaforo = false;
  editarPropuesta = true;
  alumnosCutoasCargados = false;

  porcentajeMax = false;
  secciones: Seccion[] = [];
  tiposBeca: TipoBeca[];
  dataSource = new MatTableDataSource < PropuestaSolicitud > ();
  propuestaSolicitudes: PropuestaSolicitud[];
  propuestaSolicitudesDetalle: PropuestaSolicitud[] = [];
  propuestaSolicitudesTodas: PropuestaSolicitud[];
  montoEjercido: number;
  montoPropuesta: number;
  resumenPropuesta: any;

  /* instance methods */

  calculaValores: (propuestaSol: PropuestaSolicitud) => void = calculaValores.bind(this);
  getSecciones: () => void = getSecciones.bind(this);
  getTiposBeca: () => void = getTiposBeca.bind(this);
  maxLengthCheck: (object: any) => void = maxLengthCheck.bind(this);
  minMaxValidationFunction: (that: any, value: any) => number = minMaxValidationFunction.bind(this);
  onNoClick: () => void = onNoClick.bind(this);
  onSelectSeccion: (entrando: any) => void = onSelectSeccion.bind(this);
  operacionCosto: (col: any, Porc: any) => number = operacionCosto.bind(this);
  reflejaAprobado: (element: PropuestaSolicitud) => void = reflejaAprobado.bind(this);
  save: () => void = save.bind(this);
  updatePropuesta: (propuestaBeca: PropuestaBeca) => void = updatePropuesta.bind(this);
  validarPorcentaje: (e: any) => void = validarPorcentaje.bind(this);
  verComentarios: (propuesta: PropuestaSolicitud) => void = verComentarios.bind(this);

  ngOnInit(): void {
    this.costoBecasExternas = 0;
    this.costoPropuesto = 0;
    this.porcentajePropuesto = 0;

    this.isAprobador = this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA") > -1;

    this.nuevaPropuesta = this.formBuilder.group({
      monto: [''],
      costoPropuesto: [''],
      porcentajePropuesto: [''],
      seccion: [''],
      montoEjercido: [''],
      montoPropuesta: [''],
      porcentajePropuesto2: [''],
      totalMonto: ['']
      //porcentajeSep: [''],
      //totalSep: ['']
    });

    if (this.data != null) {
      this.propuesta = this.data.Objeto;


      if (this.data.Operation == 'Editar') {
        this.propuestaBecaSolicitudService.getPropuestaBecasSolicitud(this.propuesta, 0).pipe(first()).subscribe(
          data => {
            this.propuestaSolicitudesTodas = data;
          },
          error => {}
        );
        this.enableBtn = this.propuestaSolicitudesEditable || this.propuetaSolicitudEditableRev;
      }

      var Monto = '$' + this.propuesta.MontoAprobado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      
      this.nuevaPropuesta.get('monto').setValue(Monto);
      

      var porcentajePropuesto = (this.propuesta.CostoBecas * 100) / this.propuesta.MontoAprobado;
      var porcProp = porcentajePropuesto.toFixed(2)
      this.nuevaPropuesta.get('porcentajePropuesto').setValue(porcProp + '%');

      this.propuestaBecaSolicitudService.getResumenPropuestaBecas(this.propuesta, 0).subscribe(
        data => {
          var CostoBecas = '$' + data.resumen.CostoBecaAnual.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          this.nuevaPropuesta.get('costoPropuesto').setValue(CostoBecas);
        }
      )

      this.propuestaBecaService.ObtenerMontoSemestreEjercido(this.propuesta.Centro.CentroID,
        this.propuesta.Ciclo.CicloID).then(res => {
        if (res.Exito) {
          var porcProp = '$' + res.Dato.CostoAnual.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
          this.nuevaPropuesta.get('montoEjercido').setValue(porcProp);
          this.montoEjercido = res.Dato.CostoAnual;


          this.propuestaBecaService.ObtenerMontoSemestrePropuesta(this.propuesta.Centro.CentroID,
            this.propuesta.Ciclo.CicloID).then(res => {
            if (res.Exito) {
              var porcProp = '$' + res.Dato.CostoAnual.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              this.nuevaPropuesta.get('montoPropuesta').setValue(porcProp);
              this.montoPropuesta = res.Dato.CostoAnual;
              var porcentaje = ((this.montoEjercido + this.montoPropuesta) * 100) / this.propuesta.MontoAprobado;
              var porcProp = porcentaje.toFixed(2)
              this.nuevaPropuesta.get('porcentajePropuesto2').setValue(porcProp + '%');

              var totalMonto = (this.montoEjercido + this.montoPropuesta);
              console.log("totalMonto: " + totalMonto);
              var totalMontoStr = '$' + totalMonto.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
              this.nuevaPropuesta.get('totalMonto').setValue(totalMontoStr);
            }
          }).catch(err => {
            console.log("Error" + err);
          });


        }

      }).catch(err => {
        console.log("Error" + err);
      });



      this.propuestaBecaService.ObtenerMontosSemestrePropuestaComple(this.propuesta.Centro.CentroID,
        this.propuesta.Ciclo.CicloID, this.propuesta.ComplementoPropuesta.ComplementoPropuestaID).then(res => {
        if (res.Exito) {
          var MontosExternas = res.Dato;
          this.costoBecasExternasSemestre = MontosExternas.CostoAnual;

        }
      }).catch(err => {
        console.log("Error" + err);
      });


      this.propuestaBecaService.ObtenerMontosExternasComplemento(this.propuesta.Centro.CentroID,
        this.propuesta.Ciclo.CicloID, 0, this.propuesta.ComplementoPropuesta.ComplementoPropuestaID).then(res => {
        if (res.Exito) {
          var MontosExternas = res.Dato;
          this.costoBecasExternas = MontosExternas.CostoAnual;
        }
      }).catch(err => {
        console.log("Error" + err);
      });



      if (this.data.Operation == 'Revision') {
        this.propuesta = this.data.Objeto;
        this.enableBtn = this.propuestaSolicitudesEditable || this.propuetaSolicitudEditableRev;
        this.esRevision = true;
      } else {
        this.propuesta = new PropuestaBeca();
        this.esRevision = false;
      }
    }
    if (this.data.Objeto != null) {
      this.colegioName = this.data.Objeto.Centro.Nombre
    }

    if (this.authenticationService.currentUserValue.Roles.indexOf("REVPROPBECA") > -1 ||
      this.authenticationService.currentUserValue.Roles.indexOf("ADMIN") > -1
    ) {
      this.esCoordinador = true;
    }

    this.getSecciones();
    this.getTiposBeca();
  }

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogClose: MatDialogRef < DetallePropuestaBecasComponent > ,
    private authenticationService: AuthenticationService,
    private seccionService: SeccionService,
    private propuestaBecaService: PropuestaBecaService,
    private propuestaBecaSolicitudService: PropuestaBecaSolicitudService,
    private tipoBecaService: TipoBecaService,
    public dialogRef: MatDialogRef < DetallePropuestaBecasComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp, ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.coordinacionName = "Presupuesto final";
    })
  }
}