<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">REPORTES</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <div>
    <div class="text-center">
      <form class="mdl-form" name="reporteForm" id="reporteForm" [formGroup]="reporteForm">  
        <mat-form-field  class="input-centro-width md-width" appearance="outline">
            <mat-label>Tipo de reporte*</mat-label>
            <mat-select (ngModelChange)='clearDates()' formControlName="tipoReporte">
                <mat-option *ngFor="let tipo of tipoReporte" [value]="tipo.TipoID" >
                  {{tipo.Nombre}}
                </mat-option>
              </mat-select>
        </mat-form-field> 
        <br><br>
        <div *ngIf="reporteForm.controls.tipoReporte.value == 1">
          <label class="color-marino">Rango de fechas:</label>
        </div>  
        <div  *ngIf="reporteForm.controls.tipoReporte.value == 1" class="page-width-80">
          <mat-form-field class="page-half-width inpunt-width-date xs-width" appearance="outline">
            <mat-label>De:</mat-label>
            <input formControlName="fechaInicio" matInput [min]="minDate" [max]="maxDate" 
            (ngModelChange)='verify()' (input)='verify()' [readonly]="true" [matDatepicker]="picker1" [disabled]="!disabledForm">              
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <span class="space"> -  </span>
          <mat-form-field class="inpunt-width-date xs-width" appearance="outline">
            <mat-label>A:</mat-label>
            <input [readonly]="true" formControlName="fechaFin" matInput [min]="reporteForm.controls.fechaInicio.value" 
            (ngModelChange)='verify()' (input)='verify()' [matDatepicker]="picker2" [disabled]="!disabledForm">              
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field> 
        </div>                
        <mat-form-field  class="input-centro-width md-width" appearance="outline">
          <mat-label>Ciclos*</mat-label>
          <mat-select (ngModelChange)='verify()' #mySelCiclo name="ciclo" formControlName="ciclo" 
              id="ciclo" (openedChange)="onSelectedCiclos($event)" [disabled]="!disabledForm">
              <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
                {{ciclo.Nombre}}
          </mat-option>
          </mat-select>
        </mat-form-field>  
        <br>
        <mat-form-field class="input-centro-width md-width"  appearance="outline">
          <mat-label>Regiones</mat-label>
          <mat-select (ngModelChange)='verify()' #mySelRegion name="ciclos" formControlName="regiones" 
              id="regiones" (openedChange)="onSelectedRegiones($event)" multiple [disabled]="!disabledForm">
              <mat-option #allSelectedRegiones (click)="toggleAllSelectionRegiones()"[value]="0"><label>Todos</label></mat-option>
              <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
              (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
                {{region.Nombre}}
            </mat-option>
          </mat-select>   
        </mat-form-field>  
        <br>
        <mat-form-field  class="input-centro-width md-width" appearance="outline">
          <mat-label>Colegios</mat-label>
          <mat-select #mySelCentro name="centros" formControlName="centros"  
              id="centros" (ngModelChange)='verify()' (openedChange)="onSelectedColegios($event)" [disabled]="!disabledForm"  multiple>
                <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                            (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                            {{centro.Nombre}}
                </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field *ngIf="reporteForm.controls.tipoReporte.value == 6" class="input-centro-width md-width" appearance="outline">
          <mat-label>Secciones</mat-label>
          <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones" [disabled]="!disabledForm" multiple>
              <mat-option #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
              <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
                (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
                {{seccion.Nombre}}
              </mat-option>
          </mat-select>
        </mat-form-field>  
        <br><br>
        <div  class="text-center">
          <button  mat-raised-button color="primary" class="button"  (click)="generar()" [disabled]="!disablebtn"><mat-icon>cloud_download</mat-icon> DESCARGAR</button>
        </div>
      </form>
    </div>
  </div>
  <br><br>
</mat-card>  