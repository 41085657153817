import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { saveAs } from 'file-saver';
import { Centro } from '@app/_models/centro';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { DetalleCalificacionComponent } from '@app/_components/detalle-calificacion/detalle-calificacion.component';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { RevisionRequisitosService } from '@app/_services/revision-requisitos.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { Ciclo } from '@app/_models/ciclo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { RevisionRequisitosParam } from '@app/_models/revisionReqParam';
import { Solicitud } from '@app/_models/solicitud';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-revision-requisitos',
  templateUrl: './revision-requisitos.component.html',
  styleUrls: ['./revision-requisitos.component.scss']
})

export class RevisionRequisitosComponent implements OnInit {

  displayedColumns: string[] = ['colegio', 'seccion', 'id', 'nombre', 'acciones'];

  dataSource = new MatTableDataSource<Solicitud>();
  revisionRequisitosForm: FormGroup;

  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('allSelectedGrados') private allSelectedGrados: MatOption;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;
  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;

  centros: Centro[] = [];
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  grados: GradoEducativo[] = [];
  selectedCentros : Centro [];
  selectedRegiones : Region [];  
  selectedSecciones : Seccion [];  
  selectedGrados : GradoEducativo [];  
  ciclosBecas : Ciclo [];  
  solicitudes : Solicitud [];  
  
  cargandoCentros = null;
  cargandoRegiones = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;

  idUsuario: number;
  
  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private regionService:RegionService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private gradoService: GradoEducativoService,
    private cicloBecaService: CicloBecaService,
    private revisionRequsitosService : RevisionRequisitosService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.dataSource.paginator = this.paginator;

    this.revisionRequisitosForm = this.formBuilder.group({
      ciclo: [ '',  Validators.required],
      centros: [ ''],
      regiones: [''],
      secciones: [''],
      grados: [''],
      nombre: [''],
    });    

    this.getRegiones();    
    this.getCiclosBecas();
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }

  buscar() {

    if(this.revisionRequisitosForm.invalid) return
    var rev = new RevisionRequisitosParam();
    rev.cicloID=this.revisionRequisitosForm.get('ciclo').value;  

    if(rev.cicloID == null ||  rev.cicloID == 0){
        return;
    }
    
    rev.centros = this.revisionRequisitosForm.get('centros').value;  
    rev.regiones = this.revisionRequisitosForm.get('regiones').value;  
    rev.secciones = this.revisionRequisitosForm.get('secciones').value;  
    rev.grados = this.revisionRequisitosForm.get('grados').value;  
    rev.nombre = this.revisionRequisitosForm.get('nombre').value;  
    rev.numRegistros = this.pageSize;
    rev.pagina = this.pageIndex; 

    console.log("Param " + JSON.stringify(rev));

    this.revisionRequsitosService.buscar(this.pageSize, (this.pageIndex+1), rev.cicloID, rev.centros,
    rev.regiones, rev.secciones, rev.grados, rev.nombre, this.idUsuario).pipe(first()).subscribe(
        data => {
          this.length = data.Total;
          this.dataSource = new MatTableDataSource<Solicitud>(data.Dato);
        },
        error=> {
          //this.errorMessageP = "Error al obtener la lista";
        }
    );
  }  

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
    }
  }  

  onSelectedRegiones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var regiones   = this.revisionRequisitosForm.get('regiones').value;  
      console.log("Regiones " + regiones);

      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
        this.secciones = [];
        this.grados = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.selectedGrados = [];
        this.revisionRequisitosForm.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      }); 
    }
  }  

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionRequisitosForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

        this.grados = [];
        this.selectedGrados = [];

      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      }); 
    }
  }  

  onSelectedSecciones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionRequisitosForm.get('centros').value;  
      var secciones   = this.revisionRequisitosForm.get('secciones').value;  

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      }); 
    }
  }    

  tosslePerOneCiclos(all){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }

    if(this.revisionRequisitosForm.controls != null && 
      this.revisionRequisitosForm.controls.userType != null &&
      this.revisionRequisitosForm.controls.userType.value != null){
        if(this.revisionRequisitosForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCiclos.select();
    }
  } 

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }

    if(this.revisionRequisitosForm.controls != null && 
      this.revisionRequisitosForm.controls.userType != null &&
      this.revisionRequisitosForm.controls.userType.value != null){
        if(this.revisionRequisitosForm.controls.userType.value.length==this.centros.length)
          this.allSelectedRegion.select();
    }
  } 

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.revisionRequisitosForm.controls != null && 
      this.revisionRequisitosForm.controls.userType != null &&
      this.revisionRequisitosForm.controls.userType.value != null){
        if(this.revisionRequisitosForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
    }
  } 

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }

    if(this.revisionRequisitosForm.controls != null && 
      this.revisionRequisitosForm.controls.userType != null &&
      this.revisionRequisitosForm.controls.userType.value != null){
        if(this.revisionRequisitosForm.controls.userType.value.length==this.centros.length)
          this.allSelectedSecciones.select();
    }
  }   

  tosslePerOneGrados(all){ 
    if (this.allSelectedGrados.selected) {  
     this.allSelectedGrados.deselect();
     return false;
    }

    if(this.revisionRequisitosForm.controls != null && 
      this.revisionRequisitosForm.controls.userType != null &&
      this.revisionRequisitosForm.controls.userType.value != null){
        if(this.revisionRequisitosForm.controls.userType.value.length==this.centros.length)
          this.allSelectedGrados.select();
    }
  }     

  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }
    
  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }
    
  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }    

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }     
        
  toggleAllSelectionGrado() {
    if (this.allSelectedGrados.selected) {
      this.mySelGrado.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelGrado.options.forEach( (item : MatOption) => {item.deselect()});
    }
    //this.mySelInstitucion.close();
  }         

  descargarDocumentos(solicitud:Solicitud){
    this.revisionRequsitosService.download(solicitud.SolicitudID, solicitud.Alumno.CURP)
    .subscribe (
      data => {
        saveAs(data, solicitud.Alumno.CURP + '.zip', {
          type: 'application/zip' 
        });
      }, 
      error => {
          const dialogData = new ConfirmDialogModel('Error', "No se encontraron documentos para la solicitud.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }    
    
  omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  agregarCalificacion(solicitud:Solicitud){
    solicitud.ClaveTipoRequisito = "ENSAYO";
    const dialogRef = this.dialog.open(DetalleCalificacionComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: solicitud}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}