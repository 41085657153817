import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { EstatusRenovacion } from '@app/_models/estatusRenovacion';

@Injectable({ providedIn: 'root' })
export class EstatusRenovacionService {

    constructor(private http: HttpClient) {}

    consultarEstatus():Promise<EstatusRenovacion[]>{
        return this.http.get<EstatusRenovacion[]>(`${environment.apiUrl}/EstatusRenovacion`).toPromise();
    }
}