import { RequisitoBeca } from './requisitoBeca';

export class TipoBecaReq{

    TipoBecaRequisitoID: number;
    Minimo: number;
    Maximo : number;
    Invalido : boolean;
    Aplicable : boolean;
    RequisitoBeca: RequisitoBeca
}