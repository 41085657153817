import { Component, OnInit, Inject  } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Seccion } from '@app/_models/seccion';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { DetalleResumenPropuesta } from '@app/_models/detalleResumenProp';
import { ResumenPropuesta } from '@app/_models/resumenPropuesta';
import { PropuestaBecaSolicitudService } from '@app/_services/propuestaBecaSolicitud.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ResumenPropuestaSeccion } from '@app/_models/resumenPropuestaSeccion';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
@Component({
  selector: 'app-detalle-resumen-becas',
  templateUrl: './detalle-resumen-becas.component.html',
  styleUrls: ['./detalle-resumen-becas.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed, void => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DetalleResumenBecasComponent implements OnInit {

  expandedElement: DetalleResumenPropuesta | null;

  //Nombres de las primeras columnas, columnas por default antes de la actualizacion
  columnsToDisplay: any[] = [];
  //Nombres de las columnas de la segunda tabla, antes de expandir
  columnsToDisplay2: any[] = [];
  //Nombres de las columna que se muestran al expandir 
  columnsToDisplay3: any[] = [];

  displayedColumnsD1 = [
    {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false, isCurrency : false},
    {name:'BecasTotal', field:'BecasTotal', title: 'BECAS ASIGNADAS', isArray: false, isDecimal: false},
    {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false, isCurrency : false},
    {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false,isCurrency : false},
    {name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true},
    {name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false,isCurrency : true}, 
    {name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true}
  ]

  dataSource = new MatTableDataSource<DetalleResumenPropuesta>();  

  displayedColumnsD2 = [
    {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false},
    {name:'BecasTotal', field:'BecasTotal', title: 'BECAS ASIGNADAS', isArray: false, isDecimal: false},
    {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false},
    {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false},
    {name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true},
    {name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false, isCurrency : true}, 
    {name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true}
  ]

  displayedColumnsD3 = [
    {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false},
    {name:'BecasTotal', field:'BecasTotal', title: 'BECAS ASIGNADAS', isArray: false, isDecimal: false},
    {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false},
    {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false},
    {name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true},
    {name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false, isCurrency : true}, 
    {name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true}
  ]

  dataSource2 = new MatTableDataSource<ResumenPropuestaSeccion>();  

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog, 
    public dialogClose: MatDialogRef<DetalleResumenBecasComponent>,
    private authenticationService: AuthenticationService, 
    private seccionService: SeccionService,
    public dialogRef: MatDialogRef<DetalleResumenBecasComponent>,
    private propuestaBecaSolicitudService : PropuestaBecaSolicitudService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp,
  ) { }

  propuesta : PropuestaBeca;
  nuevaPropuestaResumenB: FormGroup;
  idColegio: number
  idUsuario: number;
  enableBtn = false;
  esperando = false;

  resumenPropuesta : ResumenPropuesta;
  secciones: Seccion[] = [];
  alumnosCutoasCargados = false;

  ngOnInit(): void {
    this.initColumsToDisplay();
    this.getSecciones() 

    this.nuevaPropuestaResumenB = this.formBuilder.group({
        seccion: [''],
    });
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }
  
  onSelectSeccion(entrando) {
    if(!entrando){
      console.log("Seleecion seccion...");  
      var SeccionID  =  this.nuevaPropuestaResumenB.get('seccion').value;  
  
      if(SeccionID != null ){
      this.esperando = true;
      this.enableBtn = false;

      this.propuestaBecaSolicitudService.getResumenPropuestaBecas(this.propuesta, SeccionID).pipe(first()).subscribe(
        data => {
          this.resumenPropuesta = data;
          this.resumenPropuesta.detalleSeccion.forEach(element => {
            element.detalleTable = new MatTableDataSource<DetalleResumenPropuesta>(element.detalle);
          });

          var resumen = [];
          resumen.push(this.resumenPropuesta.resumen);
          this.updateColumnas();
          //se llenan los datos de datasourse que son de la primera tabla
          this.dataSource =  new MatTableDataSource<DetalleResumenPropuesta>(resumen);
          //se llenan los datos de datasourse2 que son los de la segunda y tercer tabla
          this.dataSource2 = new MatTableDataSource<ResumenPropuestaSeccion>(data.detalleSeccion);
          this.esperando = false;
          this.enableBtn = true;
        },
        error => {
          this.esperando = false;
        }
      );        
      }
    }
  }

  getSecciones() {

    if(this.data != null && this.data.Objeto != null){
      this.propuesta = this.data.Objeto;
      var centro = this.propuesta.Centro.CentroID;
      this.seccionService.consultaSecciones(centro).then(secciones => {
        this.secciones = secciones;
      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }

  initColumsToDisplay(){
    this.columnsToDisplay = this.displayedColumnsD1.map(col => col.name);
    this.columnsToDisplay2 = this.displayedColumnsD2.map(col => col.name);
    this.columnsToDisplay3 = this.displayedColumnsD3.map(col => col.name);
  }

  updateColumnas () {
    this.displayedColumnsD1 = [
      {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false,isCurrency : false},
      {name:'BecasTotal', field:'BecasTotal', title: 'BECAS ASIGNADAS', isArray: false, isCurrency: false},
      {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false,isCurrency : false, },
      {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false,isCurrency : false}
    ];
    
    var index = 0;
    if(this.resumenPropuesta.resumen.TipoBecas){
      this.resumenPropuesta.resumen.TipoBecas.forEach(element => {
        var column = { name : element.TipoBeca.Nombre, field :  "tipoBeca", 
                      title : element.TipoBeca.Nombre, isArray : true, isCurrency : false, index : index };
        this.displayedColumnsD1.push(column)
        index++;
      });
    }

    this.displayedColumnsD1.push({name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true});
    this.displayedColumnsD1.push({name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false,isCurrency : true});
    this.displayedColumnsD1.push({name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true});
    
    this.displayedColumnsD2 = [
      {name:'Seccion', field:'Seccion', title: 'SECCIÓN', isArray: false,isCurrency : false},
      {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false,isCurrency : false},
      {name:'BecasTotal', field:'BecasTotal', title: 'BECAS ASIGNADAS', isArray: false, isDecimal: false},
      {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false,isCurrency : false},
      {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false,isCurrency : false}
    ];
        
    var index = 0;
    if(this.resumenPropuesta.resumen.TipoBecas){
      this.resumenPropuesta.resumen.TipoBecas.forEach(element => {
        var column = { name : element.TipoBeca.Nombre, field :  "tipoBeca", 
                      title : element.TipoBeca.Nombre, isArray : true, index : index };
        this.displayedColumnsD2.push(column)
        index++;
      });
    }

    this.displayedColumnsD2.push({name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true});
    this.displayedColumnsD2.push({name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false,isCurrency : true});
    this.displayedColumnsD2.push({name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true});
  
    this.displayedColumnsD3 = [
      {name:'Grado', field:'Grado', title: 'GRADO', isArray: false,isCurrency : false},
      {name:'Alumnos', field:'Alumnos', title: 'NÚMERO DE ALUMNOS', isArray: false,isCurrency : false},
      {name:'BecasTotal', field:'BecasTotal', title: "BECAS ASIGNADAS", isArray: false, isDecimal: false},
      {name:'BecasNuevas', field:'BecasNuevas', title: 'BECAS NUEVAS', isArray: false,isCurrency : false},
      {name:'BecasRenovacion', field:'BecasRenovacion', title: 'BECAS RENOVACIÓN', isArray: false,isCurrency : false}
    ];
        
    var index = 0;
    if(this.resumenPropuesta.resumen.TipoBecas){
      this.resumenPropuesta.resumen.TipoBecas.forEach(element => {
        var column = { name : element.TipoBeca.Nombre, field :  "tipoBeca", 
                      title : element.TipoBeca.Nombre, isArray : true, index : index };
        this.displayedColumnsD3.push(column)
        index++;
      });
    }

    this.displayedColumnsD3.push({name:'PorcentajeSobreAlumnos', field:'PorcentajeSobreAlumnos', title: 'PORCENTAJE SOBRE ALUMNOS', isArray: false, isDecimal: true});
    this.displayedColumnsD3.push({name:'CostoBecaAnual', field:'CostoBecaAnual', title: 'COSTO ANUAL BECAS', isArray: false,isCurrency : true});
    this.displayedColumnsD3.push({name:'PorcentajeSobreIngresos', field:'PorcentajeSobreIngresos', title: 'PORCENTAJE SOBRE INGRESOS', isArray: false, isDecimal: true});
  
    this.initColumsToDisplay();
  }  
}
