import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-recupera-cuenta-espera',
  templateUrl: './recupera-cuenta-espera.component.html',
  styleUrls: ['./recupera-cuenta-espera.component.scss']
})
export class RecuperaCuentaEsperaComponent implements OnInit {

  correo = '';

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.correo = this.route.snapshot.paramMap.get('correo');
  }
}
