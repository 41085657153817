<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">CONSOLIDADO DE BECAS.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br>
  <div class="example-form">
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="resumeBeca" name="resumeBeca" id="resumeBeca" >

      <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Ciclos</mat-label>
        <mat-select #mySelCiclo name="ciclos" formControlName="ciclo" id="ciclo" >
          <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
            {{ciclo.Clave}}
          </mat-option>
        </mat-select>
      </mat-form-field>  

      <mat-form-field class="inpunt-width xs-width"  appearance="outline">
        <mat-label>Regiones</mat-label>
        <mat-select #mySelRegion name="regiones" formControlName="regiones" 
            id="regiones" (openedChange)="onSelectedRegiones($event)" multiple>
            <mat-option  #allSelectedRegiones  (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
            <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
            (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
              {{region.Nombre}}
          </mat-option>
        </mat-select>   
      </mat-form-field> 

      <mat-form-field  class="input-centro-width xs-width" appearance="outline">
        <mat-label>Centros</mat-label>
        <mat-select #mySelCentro name="centros" formControlName="centros"  
            id="centros" 
                  multiple>
              <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
              <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                          (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                          {{centro.Nombre}}
              </mat-option>
          </mat-select>
      </mat-form-field> 
      <br/> <br/>
        
      <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
      <button mat-stroked-button color="success" style="float: right; margin-right: 2.5rem;" (click)="descargar()"
      matTooltip="Descargar" matTooltipPosition="right"> Descarga de archivo excel <mat-icon style="margin-left: 1rem;">cloud_download</mat-icon></button>     

    </form>
  </div>
  <br>
  <treetable class="table-responsive" [tree]="arrayOfNodesTree" [columns]="columnDefinition" #matTreeTable
  [options]="treeOptions"
  (nodeClicked)="logNode($event)"></treetable> 
  <br><br>
</mat-card>