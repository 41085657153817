import { EstatusRenovacion } from './estatusRenovacion';
import { Solicitud } from './solicitud';
import { TipoCarta } from './tipoCarta';

export class BecaOtorgada {

    Renovacion : boolean;
    AplicaReinscripcion : boolean;
    AplicaInscripcion : boolean;
    Solicitud : Solicitud;
    PorcentajeAprobado : number;
    BecaSep : boolean;
    Condicionada : boolean;
    TipoCarga : TipoCarta;
    Comentarios : string;
    EditarComentarios : boolean;

    EsExterna : boolean;
}
  