import { Centro } from './centro';
import { Seccion } from './seccion';
import { TipoBecaSeccionGrado } from './tipoBecaSeccionGrado';

export class TipoBecaSeccionCentro{

    TipoBecaSeccionID: number;
    Aplicable :boolean;
    TipoBecaNombre : string;
    SeccionNombre : string;
    Centro : Centro;
    BecaSeccionGrados : TipoBecaSeccionGrado[];
}