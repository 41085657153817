<mat-card class="seleccion-beca-card">
    <br>
    <div class="example-form">
        <mat-card-title class="text-left fz-26">EXPEDIENTES ASIGNADOS.</mat-card-title>
        <mat-divider></mat-divider>
    </div>
    <br><br>
    <div class="mdl-form">
        <mat-label class="color-azul">Filtrar por:</mat-label>
    </div>
    <form  class="mdl-form" name="expedientesAsignados" id="expedientesAsignados" [formGroup]="expedientesAsignados">      
        
        <div class="row">
            <mat-form-field  class="inpunt-width xs-width" appearance="outline">
                <mat-label>Centros</mat-label>
                <mat-select #mySelCentro name="centros" formControlName="centros"  
                    id="centros"  multiple>
                    <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
                    <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                                (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                                {{centro.Nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>    

            <mat-form-field appearance="outline" class="inpunt-width xs-width" >
                <mat-label>Estatus:</mat-label>
                <mat-select #mySelEstatus name="estatus" formControlName="estatus"  
                    id="estatus"  multiple>
                    <mat-option  #allSelectedEstatus  (click)="toggleAllSelectionEstatus()" [value]="0"><label>Todos</label></mat-option>
                    <mat-option *ngFor="let est of estatusEstudio" [value]="est.EstatusEstudioID" 
                                (click)="tosslePerOneEstatus(allSelectedEstatus.viewValue)"> 
                                {{est.Nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field> 
            <br><br>
            <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
        </div>
    </form>
    <br>
    <div class="mdl-form mat-elevation-z8">

        <mat-table class="table"[dataSource]="dataSource" matSort>
    
            <ng-container matColumnDef="Colegio">
              <mat-header-cell *matHeaderCellDef> Colegio </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Colegio"> {{element.Solicitud.Centro.Nombre}} </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="Nombre">
              <mat-header-cell *matHeaderCellDef> Nombre del alumno </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Nombre"> {{element.Solicitud.Alumno.Nombre}} {{element.Solicitud.Alumno.ApellidoPaterno}} {{element.Solicitud.Alumno.ApellidoMaterno}} </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="NombrePadres">
              <mat-header-cell *matHeaderCellDef> Nombre del padre de familia </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Nombre Padres">  
                  {{element.Solicitud.Alumno.Responsables[0] ? element.Solicitud.Alumno.Responsables[0].Nombre : ''}} 
                  {{element.Solicitud.Alumno.Responsables[0] ? element.Solicitud.Alumno.Responsables[0].ApellidoPaterno : ''}}  
                  {{element.Solicitud.Alumno.Responsables[0] ? element.Solicitud.Alumno.Responsables[0].ApellidoMaterno : ''}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Estatus">
                <mat-header-cell *matHeaderCellDef> Estatus</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Estatus"> {{element.EstatusEstudio.Nombre}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="Opciones">
                <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Opciones"> 
                    <button mat-icon-button color="basic" aria-label="Descargar" 
                    (click)="iniciarProceso(element)" 
                    matTooltip="Descargar información de la solicitud"  matTooltipPosition="right" >
                        <mat-icon>cloud_download</mat-icon></button>
                    <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Cargar" (click)="cargarEstudio(element)" matTooltipPosition="right"><mat-icon>cloud_upload</mat-icon></button>   
                 </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table> 
          
        <div *ngIf="length == 0" class="text-center margin-top">
            <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
        </div>
          
        <mat-paginator #paginator
            [length]="length"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[10, 20]"
            (page)="pageEvent = getServerData($event)" >
        </mat-paginator>
      
    </div>
    <br><br>
</mat-card>