import { Alumno } from './alumno';

export class Usuario {
  UsuarioID: number;
  AlumnoID : number;
  Matricula : string;
  Correo: string;
  correoAlterno: string;
  Nombre: string;
  NombreEmpresa: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;    
  Password: string;  
  Roles?: string[];
  Campus?: string[];
  IsActive?: boolean;
  IsAdmin?: boolean;
  IsEmpleado?: boolean;
  IsProspecto?: boolean;
  IsEmpresa?: boolean;
  Token?: string;
  Expiracion?: number;
  CentroID : string;

}
