<mat-card class="seleccion-beca-card"> 
  <div class="example-form row">
    <mat-card-title class="text-left fz-26">RECUPERA CUENTA.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br>
  <div class="principal1">
    <div class="seleccion-beca-card">
      <br><br>
      <form class="registro-form" name="recuperarCuentaForm" id="recuperarCuentaForm" [formGroup]="recuperarCuentaForm">

        <mat-form-field class="recupera-full-width" appearance="fill">
          <mat-label>CURP</mat-label>
          <input matInput formControlName="curp" id="curp" name="curp" required>                
        </mat-form-field> 
        <br>

        <mat-form-field class="recupera-full-width" appearance="fill">
          <mat-label>Fecha de nacimiento*</mat-label>
          <input matInput [min]="minDate" class="sm-date" placeholder="dd/mm/yyyy" 
          [(ngModel)]='dateNac' [matDatepicker]="picker" formControlName="fechaNac" id="fechaNac" name="fechaNac">    
            <div *ngIf="dateError">
              <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
            </div>                                   
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <br><br>
        <div class="text-center">
          <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" class="color-cancelar" 
           style="margin-right: 1rem;" matTooltip="Volver al menú principal" mat-raised-button routerLink="/login">CANCELAR</button>
          <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" 
          (click)="onSubmit()" [disabled]="!enableBtn" mat-raised-button>CONTINUAR</button>
        </div>
      </form>
    </div>
  </div>  
</mat-card>