import { Usuario } from './usuario';
import { Responsable } from './responsable';
import { Centro } from './centro';

export class MensajeAyuda {
    Curp : string;
    Centro : Centro;
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;  
    Correo : string;
    Mensaje : string;

  }