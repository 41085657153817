import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor() { }

  private isMobileDevice(): boolean {
    return /Android|iPhone|iPad|iPod|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);
  }
  private isChromeBrowser() {
    return /Crios|Chrome/i.test(navigator.userAgent);
  }
  private isIEBrowser(): boolean {
    return !!window.navigator && !!window.navigator.msSaveOrOpenBlob;
  }
  private isSafariBrowser(): boolean {
    return /Safari/i.test(navigator.userAgent);
  }

  private getResponseType(): string {
    // Both Desktop Chrome and IE supports blob properly
    // Chrome also supports Data URI way, but it fails miserably when the file size is more than 2 MB (Not sure about the exact limit though).
    if (this.isIEBrowser() || this.isChromeBrowser()) {
      return 'blob';
    } else if (this.isMobileDevice()) {
      return 'arraybuffer';
    }
    return 'blob';
  }

  private getBlobUriFromResponse(blob: Blob): string {
    var downloadUrl = URL.createObjectURL(blob);
    return downloadUrl;
  }

  private getDataUriFromResponse(base64: string, type: string): string {
    var dataUrl = 'data:' + type + ';charset=utf-16le;base64,' + base64;
    return dataUrl;
  }

  private base64toBlob(base64Data: string, contentType: string): Blob {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  public downloadFile(data, fileName, fileMimeType): void {

    // Create a new Blob object using the response data of the onload object
    let blob: Blob = this.base64toBlob(data, "application/pdf");

    if (this.isIEBrowser()) {
      var bool = window.navigator.msSaveOrOpenBlob(blob, fileName);
      if (!bool) {
        alert('Download failed, Please try again later');
        return;
      }
    } else {
      var dataUrl;
      if (this.getResponseType() === 'blob') {
        dataUrl = this.getBlobUriFromResponse(blob);
      } else {
        dataUrl = this.getDataUriFromResponse(data, fileMimeType);
      }
      var element = document.createElement('a');
      // Safari doesn't work well with blank targets
      if (!this.isSafariBrowser()) {
        element.setAttribute('target', '_blank');
      }
      element.setAttribute('href', dataUrl);
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } 
  }
}