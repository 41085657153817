<mat-card-title class="card-title fz-20">
    Fechas para centros
  </mat-card-title>

  
    <mat-table  [dataSource]="dataSourceCiclosCentros" >
  
        <ng-container matColumnDef="Nombre">
          <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Centro.Nombre}} </mat-cell>
        </ng-container>      
        
  
        <ng-container matColumnDef="fechas">
          <mat-header-cell *matHeaderCellDef> Fechas </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Máximo"> 
            <button mat-icon-button color="basic" matTooltip="Asignar fechas"
            (click)="especificacionFechas(element)"
            matTooltipPosition="right" >
          <mat-icon>dns</mat-icon></button>
            <div class="row" *ngIf="element.TieneEspecificacion">
              <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar"
              (click)="eliminarEspecificacionXCentro(element)"    matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
            </div>

          </mat-cell>
        </ng-container> 
  
        <mat-header-row *matHeaderRowDef="displayedColumnsCentros"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsCentros;">
        </mat-row>
    </mat-table> 
  
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>
    <br>
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <br><br><br><br>
  <div class="modal-form margin-div">
    <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>  
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || guardando"  >Guardar</button>    
  </div>
  
  <div *ngIf="error">
    <br>
    <mat-card class='card-danger'>{{errorMessage}}</mat-card>
  </div>
