<mat-card-title class="card-title fz-20">ACCESO A LA SOICITUD</mat-card-title>
<mat-divider></mat-divider>
<br>
<form class="modal-form" name="detalleExtForm" id="detalleExtForm" [formGroup]="detalleExtForm" autocomplete="off">

    <div class="row"> 
        <mat-form-field class="input-full-width-coment xs-width" appearance="outline">
            <mat-label>Comentarios</mat-label>
            <textarea matInput #inputD name="comentarios" formControlName="comentarios"   
            (ngModelChange)="verify()" rows="8" required></textarea>
            <mat-hint *ngIf="inputD.value?.length == 200">Max 200 caracteres</mat-hint>
        </mat-form-field>
    </div>

    <mat-label>Fecha Ingreso</mat-label> 
    <br><br>
    <table>
        <tr>
            <td>
            </td>
            <td style="text-align: left; width: 18rem;">
                <mat-label>Desde</mat-label> 
            </td>
            <td>
                <mat-label>Hasta</mat-label> 
            </td>
        </tr>
    </table>
    <table>
        <tr>
            <td>
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput [min]="minDate" [max]="detalleExtForm.controls.FechaFin.value" 
                        name ="FechaIni" placeholder="dd/mm/yyyy"
                        (ngModelChange)='verify()'
                    [matDatepicker]="pickerCI" formControlName="FechaIni">  
                    <mat-error *ngIf="detalleExtForm.controls.FechaIni.invalid || fechaError">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="pickerCI"></mat-datepicker-toggle>
                    <mat-datepicker #pickerCI></mat-datepicker> 
                </mat-form-field>
            </td>
            <td>
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaFin" matInput [min]="detalleExtForm.controls.FechaIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="pickerCF" (ngModelChange)='verify()'>  
                    <mat-error *ngIf="detalleExtForm.controls.FechaFin.invalid || fechaError">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="pickerCF"></mat-datepicker-toggle>
                    <mat-datepicker #pickerCF></mat-datepicker>
                </mat-form-field>
            </td>
        </tr>
    </table>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br>
    <div class="modal-form margin-div">
        <button mat-raised-button class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>
        <button mat-raised-button class="button xsbtn" (click)="onSubmit()" [disabled]="guardando">GUARDAR</button>
    </div>  
</form>