import { MensajePropuestaComponent } from "@app/_components/mensaje-propuesta/mensaje-propuesta.component";
import { PropuestaSolicitud } from "@app/_models/propuestaSolicitud";

export function verComentarios(propuesta:PropuestaSolicitud): void {
  propuesta.EditarComentarios = false;
  if(  this.propuestaSolicitudesEditable ){
    propuesta.EditarComentarios = true;
  }

  const dialogRef = this.dialog.open(MensajePropuestaComponent, {
    width: "40%",
    data: propuesta
  }); 
}