

export class EspecificacionRequisito{

    EspecificacionRequisitoID: number;
    Nombre: string;
    Clave : string;
    RequiereRango : boolean;
    RequiereExtension : boolean;

}