<mat-card-title class="card-title fz-20">EDITAR BECA</mat-card-title>
<mat-divider></mat-divider>
<br>
<form [formGroup]="detalleModBeca" class="modal-form" name="detalleModBeca" id="detalleModBeca">  

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Presupuesto autorizado</mat-label>
            <input matInput type="text" formControlName="presupuesto" readonly="true">  
        </mat-form-field>
    
        <mat-form-field  class="input-full-width xs-width" appearance="outline">
          <mat-label>Costo de beca anual actualizado</mat-label>
          <input matInput type="text" formControlName="costo" readonly="true">
        </mat-form-field>
    </div>
    
    <div class="resumenTable">
        <table class="mat-tb mat-elevation-z8">

        <tr>
            <td class="tdtitle">Colegio:</td>
            <td>{{data.Objeto.Solicitud.Centro.Nombre}}</td>
        </tr>
        <tr>
            <td class="tdtitle">Sección:</td>
            <td>{{data.Objeto.Solicitud.Seccion.Nombre}}</td>
        </tr>
        <tr>
            <td class="tdtitle">CURP:</td>
            <td>{{data.Objeto.Solicitud.Alumno.CURP}}</td>
        </tr>
        <tr>
            <td class="tdtitle">Nombre del Alumno:</td>
            <td>
                {{data.Objeto.Solicitud.Alumno.Nombre}} 
                {{data.Objeto.Solicitud.Alumno.ApellidoPaterno}} 
                {{data.Objeto.Solicitud.Alumno.ApellidoMaterno}}
            </td>
        </tr>
        <tr>
            <td class="tdtitle">Tipo de beca:</td>
            <td>{{data.Objeto.TipoBeca.Nombre}}</td>
        </tr>
        <tr>
            <td class="tdtitle">Porcentaje de beca:</td>
            <td>{{data.Objeto.BecaModificada}}%</td>
        </tr>
        <tr>
            <td class="tdtitle">Comentarios:</td>
            <td>{{data.Objeto.Comentarios}}</td>
        </tr>
        <tr>
            <td class="tdtitle">Documento:</td>
            <td>
                {{data.Objeto.NombreOriginal}}
                <button *ngIf="data.Objeto.NombreOriginal" mat-icon-button color="basic" matTooltip="Descargar documento" matTooltipPosition="right" (click)="descargarDoc()">
                    <mat-icon>cloud_download</mat-icon></button>
            </td>
        </tr>

        </table> 
    </div>
  <br><br>
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <div class="modal-form margin-div">
    <button mat-raised-button class="button xsbtn" (click)="onNoClick()" >CANCELAR</button>
    <div *ngIf ="editar">
        <button mat-raised-button class="button xsbtn" (click)="rechazar()" >RECHAZAR</button>
        <button mat-raised-button class="button xsbtn" (click)="aprobar()">APROBAR</button>
    </div>
</div>
</form>