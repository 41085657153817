<mat-card-title class="card-title fz-20">{{data.operacion}} USUARIO</mat-card-title>
<mat-divider></mat-divider>
<br><br>
<form class="modal-form" name="detalleUsuarioForm" id="detalleUsuarioForm" [formGroup]="detalleUsuarioForm" autocomplete="off">

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Número de empleado</mat-label>
            <input matInput id="numEmpleado" name="numEmpleado" 
            (keypress)="validarCaracteres($event)"
            maxlength="10" formControlName="numEmpleado" (input)='verify()' 
            [(ngModel)]='data.user.NumeroEmpleado' required>  
        </mat-form-field>

        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label>Área</mat-label>
            <mat-select  id="area" name="area" formControlName="area" (ngModelChange)='verify()' required>
              <mat-option *ngFor="let area of areas" [value]="area.AreaID">
                {{area.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
    </div>
  
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Nombre(s)</mat-label>
            <input matInput type="text" id="nombre" name="nombre" (keypress)="omit_special_char($event)" formControlName="nombre" (input)='verify()'  [(ngModel)]='data.user.Nombre' required>
        </mat-form-field>
    
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido paterno</mat-label>
            <input matInput type="text" id="apellidoPaterno" (keypress)="omit_special_char($event)" name="apellidoPaterno" formControlName="apellidoPaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoPaterno' required>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido materno</mat-label>
            <input matInput type="text" id="apellidoMaterno" (keypress)="omit_special_char($event)" name="apellidoMaterno" formControlName="apellidoMaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoMaterno'>
        </mat-form-field>
    
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" (input)='verify()' [(ngModel)]='data.user.Correo' autocomplete="off" required  >
            <mat-error *ngIf="detalleUsuarioForm.controls.email.invalid">El correo electrónico no tiene un formato correcto.</mat-error>            
        </mat-form-field>
    </div>
    
    <div class="row">
        <mat-form-field class="input-full-width xs-width"  appearance="outline">
            <mat-label>Región</mat-label>
            <mat-select #mySelRegion name="regiones" formControlName="regiones" 
                id="regiones"
                (openedChange)="onSelectedRegiones($event)"
                (ngModelChange)='verify()' 
                 [(ngModel)]="data.user.IdsRegiones" required  multiple>
                <mat-option #allSelectedRegiones (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
                <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
                (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
                 {{region.Nombre}}
            </mat-option>
        </mat-select>   
        </mat-form-field>

        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label>Colegios</mat-label>
            <mat-select #mySelCentro name="centros" formControlName="centros"  (ngModelChange)='verify()' 
                id="centros"   
                 [(ngModel)]="data.user.IdsCentros" required  multiple>
                <mat-option  #allSelectedCentros (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                {{centro.Nombre}}
            </mat-option>
        </mat-select>
        </mat-form-field> 
    </div>
    
    <mat-form-field class="example-chip-list" appearance="outline">
        <mat-chip-list #chipRoles aria-label="Selección de roles">
            <mat-chip
                *ngFor="let rol of selectedItems"
                [selectable]="selectable"
                (onkeypress)="autoRol"
                [removable]="removable"
                (removed)="removeRol(rol)">
                {{rol.Nombre}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>

            <input
                matInput
                placeholder="Agregar rol..."
                #rolInput
                (onkeypress)="autoRol"
                formControlName="rol_"
                [matAutocomplete]="autoRol"
                [matChipInputFor]="chipRoles"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addRoles($event)"
            >
        </mat-chip-list>

        <mat-autocomplete #autoRol="matAutocomplete">
            <mat-option *ngFor="let item of filteredItems" [value]="selectedItems" (click)="toggleSelection(item)">
                <mat-checkbox [checked]="item.Selected" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
                    {{item.Nombre}}
                </mat-checkbox>
            </mat-option>
        </mat-autocomplete>  
    </mat-form-field>
 
    <div class="loading-screen-icon" *ngIf="esperando">
        <mat-spinner></mat-spinner>
    </div>

    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación">{{textBtnCancelar}}</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || loading || esperando" matTooltip="Guardar usuario">GUARDAR</button>
    </div>
    <div *ngIf="error">
        <br>
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>

    <div *ngIf="correcto">
        <br>
        <mat-card class='card-success'>{{message}}</mat-card>
    </div>
</form>