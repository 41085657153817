import { Injectable } from '@angular/core';
import { Centro } from '@app/_models/centro';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { Alumno } from '@app/_models/alumno';
import { SolicitudService } from './solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';

@Injectable({ providedIn: 'root' })
export class DatosGeneralesService {

  constructor(private http: HttpClient,
      private authenticationService: AuthenticationService,
      private solicitudService: SolicitudService) {
  }

  obtenerGeneralesBeca(SolicitudID : number): Promise<Solicitud> {
    var promise =  this.http.get<Solicitud>(`${environment.apiUrl}/SolicitudGenerales/${SolicitudID}/ObtenerGenerales`).toPromise();
    return promise;
  }
    
  obtenerAlumno(AlumnoID : number): Promise<Alumno> {
    var promise =  this.http.get<Alumno>(`${environment.apiUrl}/Alumno/${AlumnoID}/ObtenerAlumno`).toPromise();  
    return promise;
  }

  buscarAlumno(SolicitudID : number){
    return this.http.get<AlumnoPromedio>(`${environment.apiUrl}/${SolicitudID}/BuscarAlumnoSol` ).toPromise();

  }  

}