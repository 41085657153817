import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { RequisitosService } from '@app/_services/requisitos.service';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { AuthenticationService } from '@app/_services/authentication.service';
import { EspecificacionRequisito } from '@app/_models/especificacionRequisito';
import { MatRadioChange} from '@angular/material/radio';
import { RequisitoOp } from '@app/_models/requisitoOp';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-detalle-req-ciclo',
  templateUrl: './detalle-req-ciclo.component.html',
  styleUrls: ['./detalle-req-ciclo.component.scss']
})
export class DetalleReqCicloComponent implements OnInit {

  detalleReqForm: FormGroup;
  enableBtn = false;
  idUsuario: number;

  guardando = false;

  isEspecificacion : boolean;
  especificaciones:EspecificacionRequisito[] = [];
  especificacionSelected : EspecificacionRequisito;

  error: boolean = false;
  errorMessage: string = "";

  constructor(private formBuilder: FormBuilder,
    private requisitoService: RequisitosService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetalleReqCicloComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RequisitoOp) { }

  ngOnInit(): void {

    console.log("Console" + JSON.stringify(this.data.Requisito));

    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.requisitoService.getEspecificaciones().then(especificaciones => {
      this.especificaciones = especificaciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre))
    });

    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.detalleReqForm = this.formBuilder.group({
      clave: ['', Validators.required],
      nombre: ['', Validators.required],
      descripcion: ['', Validators.required],
      isEspecificacion : ['', Validators.required],
      ext: [''],
      desde: [''],
      hasta: [''],
      especificacion: [''],
    });

    if(this.data.Operation == 'Editar'){
      this.detalleReqForm.get('clave').setValue(this.data.Requisito.Clave);    
      this.detalleReqForm.get('nombre').setValue(this.data.Requisito.Nombre);    
      this.detalleReqForm.get('descripcion').setValue(this.data.Requisito.Descripcion);    
      this.detalleReqForm.get('desde').setValue(this.data.Requisito.Minimo);    
      this.detalleReqForm.get('hasta').setValue(this.data.Requisito.Maximo);    
      this.detalleReqForm.get('ext').setValue(this.data.Requisito.Extensiones);    
      if(this.data.Requisito.EspecificacionRequisito != null){
        this.detalleReqForm.get('especificacion').setValue(this.data.Requisito.EspecificacionRequisito.EspecificacionRequisitoID);
      }
      if(this.data.Requisito.Especificacion){
        this.detalleReqForm.get('isEspecificacion').setValue("1");    
      } else{
        this.detalleReqForm.get('isEspecificacion').setValue("2");    
      }
      
      this.isEspecificacion = this.data.Requisito.Especificacion;
      this.especificacionSelected = this.data.Requisito.EspecificacionRequisito;

      this.enableBtn = true;
      this.detalleReqForm.get('clave').disable();
    }
  }

  ngAfterViewInit() {}

  onSubmit(){
    this.guardando = true;
    this.error = false;

    if (this.detalleReqForm.invalid) {
      return;
    }

    this.enableBtn = true;
    var requisito = new RequisitoBeca();

    if (this.data.Operation === 'Editar') {
      requisito.RequisitoBecaID = this.data.Requisito.RequisitoBecaID;
    }

    requisito.Clave = this.detalleReqForm.get('clave').value;
    requisito.Nombre = this.detalleReqForm.get('nombre').value;
    requisito.Descripcion = this.detalleReqForm.get('descripcion').value;
    requisito.Minimo = this.detalleReqForm.get('desde').value;
    requisito.Maximo = this.detalleReqForm.get('hasta').value;
    requisito.Maximo = this.detalleReqForm.get('hasta').value;
    requisito.Extensiones = this.detalleReqForm.get('ext').value;

    if(this.detalleReqForm.get('isEspecificacion').value == 1){
      requisito.Especificacion = true;

    }
    requisito.UsuarioID = this.idUsuario;

    requisito.EspecificacionRequisito = new EspecificacionRequisito();
    requisito.EspecificacionRequisito.EspecificacionRequisitoID = this.detalleReqForm.get('especificacion').value;

    console.log("requisito " + JSON.stringify(requisito));

    this.requisitoService.post(requisito)
      .pipe(first())
      .subscribe(
      data => {
        if (data.Exito){
          this.guardando = false;
          const dialogData = new ConfirmDialogModel('', "Requisito guardado correctamente", false);

          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData 
          });
          this.dialogRef.close(true);
        }else{
          this.errorMessage = data.Mensaje;
          this.error = true;
          this.guardando = false;
        }     
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al guardar el requisito";
        this.guardando = false;
      });
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }

  verify() {

    if(this.detalleReqForm.value.isEspecificacion == 1 &&
      this.detalleReqForm.value.nombre != ""  &&
      this.detalleReqForm.value.descripcion != "" &&
      this.detalleReqForm.value.ext != "" &&
      this.detalleReqForm.value.especificacion == 2 &&
      this.detalleReqForm.value.clave != "")
    {
      this.enableBtn = true;
      return true
    }
    if(this.detalleReqForm.value.isEspecificacion == 2 &&
      this.detalleReqForm.value.nombre != ""  &&
      this.detalleReqForm.value.descripcion != "" &&
      this.detalleReqForm.value.clave != "")
    {
      this.enableBtn = true;
      return true
    }
    if(this.detalleReqForm.value.isEspecificacion == 1 &&
      this.detalleReqForm.value.nombre != ""  &&
      this.detalleReqForm.value.descripcion != "" &&
      this.detalleReqForm.value.desde != "" &&
      this.detalleReqForm.value.hasta != "" &&
      this.detalleReqForm.value.especificacion == 1 &&
      this.detalleReqForm.value.clave != "")
    {
      this.enableBtn = true;
      return true
    }
    if(this.detalleReqForm.value.isEspecificacion == 1 &&
      this.detalleReqForm.value.nombre != ""  &&
      this.detalleReqForm.value.descripcion != "" &&
      this.detalleReqForm.value.desde != "" &&
      this.detalleReqForm.value.hasta != "" &&
      this.detalleReqForm.value.especificacion == 3 &&
      this.detalleReqForm.value.clave != "")
    {
      this.enableBtn = true;
      return true 
    }
    else{
      this.enableBtn = false;
    }
  }

  onChange(mrChange: MatRadioChange) {
    this.detalleReqForm.get('especificacion').setValue(this.especificaciones[0].EspecificacionRequisitoID);
    if(mrChange.value == 1){
      if(this.especificaciones.length > 0){
        this.especificacionSelected = this.especificaciones[0];
      }
      this.isEspecificacion = true;
    }else{
      this.isEspecificacion = false;
      this.detalleReqForm.get('ext').setValue('');
      this.especificacionSelected = new EspecificacionRequisito();
    }
  }
 
  validarCaracteres(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZÁÉÍÓÚÜabcdefghijklmnñoplrstuvwxyzáéíóúü "
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }

  validarCaracteresDescripcion(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZÁÉÍÓÚÜabcdefghijklmnñoplrstuvwxyzáéíóúü0123456789 "
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }

  onSelectTipoEspecificacion(entrando) {
    if(!entrando){
      var especifiacionID  =  this.detalleReqForm.get('especificacion').value;    
      this.especificacionSelected = this.especificaciones.find( op => op.EspecificacionRequisitoID === especifiacionID);  
    }
  }
  
  omit_special_char(e: any) {
    if (e.keyCode === 95 || e.key == "_" || e.charCode == 95) {
      return true;
    }
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  acept_char(e: any) {
    if (e.keyCode === 44 || e.key == "," || e.charCode == 44) {
      return true;
    }
    if (e.keyCode === 110 || e.key == "." || e.charCode == 110) {
      return true;
    }

    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }
}