<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">PROPUESTA DE BECAS.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br>
  <div class="mdl-form">
      <form (keydown.enter)="$event.preventDefault()" name="adminInfoForm" id="adminInfoForm" >
        <div class="row">
          <button mat-raised-button class="button" (click)="nuevaPropuesta()">+ NUEVA PROPUESTA</button>
          <input class="input-search icon xs-width text-ident" placeholder="Buscar propuesta" [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">          
          <br>
        </div>
      </form>
  </div>
  <br>
  <div class="mdl-form mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
      <ng-container matColumnDef="Ciclo">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Ciclo </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Ciclo"> 
          {{element.Ciclo.Nombre}}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="Centro">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Colegio </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="Centro">
          {{element.Centro.Nombre}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="MontoAprobado">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Monto Aprobado </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="MontoAprobado">
          {{element.MontoAprobado | currency:'MXN'}}
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="costo">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Costo de beca </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="costo">
          {{element.CostoBecas | currency:'MXN'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="porcentaje">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Porcentaje consumido </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="porcentaje">
          {{element.PorcentajeConsumido | number  : '1.2-2'}}%
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Opciones">
            <button mat-icon-button color="basic" aria-label="Editar"  matTooltip="Editar" (click)="editarPropuesta(element)" matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarPropuesta(element)"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            <button mat-icon-button color="basic" aria-label="Historial" (click)="verHistorial(element)" matTooltip="Historial">
              <mat-icon>history</mat-icon>
            </button>
        </mat-cell>          
      </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>

    </mat-table>      
    
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>

    <mat-paginator #paginator
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="pageEvent = getServerData($event)"
    >
  </mat-paginator>
  </div>         
  <br><br>
</mat-card>
