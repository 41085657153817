import { Contacto } from './contacto';

export class Responsable {

    ResponsableID : number;
    ApellidoPaterno : string;
    ApellidoMaterno : string;
    Nombre : string;
    CURP : string;
    ParentescoID : number;
    Contacto : Contacto;

}
    
  