import { TipoBecaReq } from './tipoBecaReq';
import { TipoBecaReqCentro } from './tipoBecaReqCentro';
import { TipoBecaReqSeccion } from './tipoBecaReqSeccion';
import { TipoBecaSeccion } from './tipoBecaSeccion';
import { TipoBecaSeccionCentro } from './TipoBecaSeccionCentro';
import { TipoBecaSeccionGrado } from './tipoBecaSeccionGrado';

export class TipoBecaEspecificaciones{

    TipoBecaSeccion: TipoBecaSeccion;
    BecaSeccionGrados : TipoBecaSeccionGrado[];
    BecaSeccionCentros: TipoBecaSeccionCentro[];
}