import { Alumno } from './alumno';
import { AlumnoPromedio } from './alumnoPromedio';
import { Centro } from './centro';
import { Ciclo } from './ciclo';
import { Grado } from './grado';
import { PropuestaSolicitud } from './propuestaSolicitud';
import { Seccion } from './seccion';
import { Solicitud } from './solicitud';

export class SolicitudBecaExterna {

    SolicitudBecaExternaID: number;
    Solicitud : Solicitud;
    Comentarios : string;
    Porcentaje : number;
    NombreOriginal : string;
    MimeType : string;
    NombreFisico : string;
    Archivo_: File;
    UsuarioID : number;
    BecaSep : boolean;
    TieneArchivo : boolean;


}
  