import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstitucionService {

  constructor(private http: HttpClient) { }

  get(idUsuario:number){
    
    let params = new HttpParams();
    params = params.append('idUsuario', idUsuario.toString() );

    return this.http.get<string[]>(`${environment.apiUrl}/Institucion`, { params });
  }
}
