<mat-card-title class="card-title fz-20">Nueva Beca</mat-card-title>
<mat-divider></mat-divider>
<br>
<form [formGroup]="detalleExt" class="modal-form" name="detalleExt" id="detalleExt">  

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Ciclo</mat-label>
            <mat-select #mySelCentro name="ciclo" formControlName="ciclo" (ngModelChange)='verify()' 
            id="ciclo" required>
            <mat-option *ngFor="let ciclo of ciclos" [value]="ciclo.CicloID" > 
                {{ciclo.Clave}}
            </mat-option>
            </mat-select>
        </mat-form-field> 

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Colegio</mat-label>
            <mat-select #mySelCentro name="centro" formControlName="centro" id="centro"
            (ngModelChange)='verify()' (openedChange)="onSelectedColegios($event)">
              <mat-option *ngFor="let centro of centros" [value]="centro.CentroID"> 
                {{centro.Nombre}} 
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label>CURP</mat-label>
            <input matInput formControlName="curp" (ngModelChange)="validarCurp()"
              [disabled]="disableCurp" required>
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Nombre del alumno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="nombre">
        </mat-form-field>
    </div>
    <mat-hint *ngIf="curpError"> El CURP tiene un formato inválido </mat-hint>
    <mat-error *ngIf="noAlumno"> No se encontró información del alumno para este ciclo </mat-error>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido paterno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="app">  
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido materno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="apm">  
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Sección</mat-label>
            <mat-select #mySelSeccion name="seccion" formControlName="seccion" id="seccion"
            (ngModelChange)='verify()' (openedChange)="onSelectedSecciones($event)">
                <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
                  {{seccion.Nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Grado</mat-label>
            <mat-select #mySelGrado name="grado" (ngModelChange)='verify()' formControlName="grado" id="grado">
                <mat-option *ngFor="let grado of grados" [value]="grado.GradoEducativoID"> 
                    {{grado.Nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Promedio académico</mat-label>
            <input matInput formControlName="pacademico" (ngModelChange)="validarPromedio()"
             (keypress)="omit_special_char($event)" maxlength="2" autocomplete="off" onPaste="return false">   
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Promedio conducta</mat-label>
            <input matInput formControlName="pconducta" (ngModelChange)="validarPromedio()"
             (keypress)="omit_special_char_PC($event)" maxlength="3" autocomplete="off" onPaste="return false">
        </mat-form-field>
    </div>
    <mat-hint *ngIf="promedioError">El promedio debe ser mayor a 0 y menor a 10.</mat-hint>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Tipo Beca:</mat-label>
            <mat-select  (ngModelChange)='verify()'   formControlName="tipobeca">
              <mat-option *ngFor="let c of tiposBeca" [value]="c.TipoBecaID">
                {{c.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Porcentaje de beca</mat-label>
            <input (ngModelChange)='verify()' matInput formControlName="porcentaje" 
            (keypress)="omit_special_char($event)" maxlength="3" (ngModelChange)="validarPorcentaje()" autocomplete="off" onPaste="return false">
        </mat-form-field>
    </div>
    <mat-hint *ngIf="porcentajeError">El porcentaje debe ser mayor a 0 y menor a 100.</mat-hint>

    <label>
        <input type="checkbox" style="transform: scale(1.5);" formControlName="becaSep" >
        ¿Es beca sep?
    </label>

    <mat-form-field class="input-full xs-width" appearance="outline">
        <mat-label>Comentarios</mat-label>
        <textarea matInput maxlength="300" formControlName="comentarios" rows="8"></textarea>
    </mat-form-field>
            
    <br>
    <label>Evidencia</label>
    <br><br>
    <div class="row">
        <button class="buttonSecondary" mat-raised-button (click)="seleccionarArchivo()"><span class='lato-bold espaciado margin-btn' style="font-size: 16px;">+ CARGAR ARCHIVO</span></button>
        <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
        <input class="hidden" id="upload-file" type="file" accept=".pdf, .doc" (change)="adjuntarArchivo($event)">
    </div>
    <mat-error *ngIf="ErrorExt">Extensiones aceptadas: .pdf, .doc</mat-error>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br>
    <div class="row">
        <mat-error class="margin-button" *ngIf="error">{{errorMessage}}</mat-error>
    </div>
    
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación" >CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="aceptar()" style="margin-left: 1em;" [disabled]="!enableBtn || porcentajeError || curpError || guardando" matTooltip="Guardar sobrepaso" >GUARDAR</button>
    </div> 
</form>   