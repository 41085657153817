<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">DESCARGA DE ESTUDIOS SOCIOECONÓMICOS.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br>
  <div class="example-form">
    <mat-label class="color-azul">Filtrar por:</mat-label>
  </div>
  <form class="example-form" name="descargaDocForm" id="descargaDocForm" [formGroup]="descargaDocForm">      

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Ciclos</mat-label>
      <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" id="ciclo" (ngModelChange)='verify()' (openedChange)="onSelectedCiclos($event)" required>
        <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
            {{ciclo.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>  

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Empresa</mat-label>
      <mat-select #mySelEmpresas name="empresas" formControlName="empresas" id="empresas"  multiple>
        <mat-option  #allSelectedEmpresas (click)="toggleAllSelectionEmpresas()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let empresa of empresas" [value]="empresa.EmpresaID" > 
            {{empresa.NombreEmpresa}}
        </mat-option>
      </mat-select>
    </mat-form-field> 
    
    <mat-form-field class="inpunt-width xs-width"  appearance="outline">
      <mat-label>Regiones</mat-label>
      <mat-select #mySelRegion name="ciclos" formControlName="regiones" id="regiones" (openedChange)="onSelectedRegiones($event)" multiple>
        <mat-option #allSelectedRegiones (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
          (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
          {{region.Nombre}}
        </mat-option>
      </mat-select>   
    </mat-form-field>   

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Colegios</mat-label>
      <mat-select #mySelCentro name="centros" formControlName="centros" id="centros" (openedChange)="onSelectedColegios($event)" multiple>
        <mat-option #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
        <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
          (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
          {{centro.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>    

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Secciones</mat-label>
      <mat-select #mySelSeccion name="secciones" formControlName="secciones" id="secciones" (openedChange)="onSelectedSecciones($event)" multiple>
          <mat-option #allSelectedSecciones  (click)="toggleAllSelectionSeccion()" [value]="0"><label>Todos</label></mat-option>
          <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
            (click)="tosslePerOneSecciones(allSelectedCentros.viewValue)"> 
            {{seccion.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Grados</mat-label>
      <mat-select #mySelGrado name="grados" formControlName="grados" id="grados" multiple>
        <mat-option #allSelectedGrados (click)="toggleAllSelectionGrado()" [value]="0"><label>Todos</label></mat-option>
        <mat-option *ngFor="let grado of grados" [value]="grado.GradoEducativoID" 
          (click)="tosslePerOneGrados(allSelectedGrados.viewValue)"> 
          {{grado.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field>       

    <mat-form-field class="inpunt-width xs-width" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre del alumno" 
      formControlName="nombre" id="nombre" name="nombre" (keypress)="omit_special_char($event)">
    </mat-form-field>
    
    <div>
      <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
    </div>
  </form>
  <br><br>
  <div class="example-form mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
        <ng-container matColumnDef="idAlumno">
            <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> CURP Alumno </mat-header-cell><br>
            <mat-cell *matCellDef="let element" data-label="CURP" class='column-id-prospecto'>
              {{element.Solicitud.Alumno.CURP}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre del alumno  </mat-header-cell><br>
          <mat-cell *matCellDef="let element" data-label="nombre">
            {{element.Solicitud.Alumno.ApellidoPaterno}}  {{element.Solicitud.Alumno.ApellidoMaterno}} {{element.Solicitud.Alumno.Nombre}}
          </mat-cell>
        </ng-container>
            
        <ng-container matColumnDef="nivel">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Nivel </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="nivel"> 
            {{element.Solicitud.Seccion.Nombre}}
          </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="grado">
          <mat-header-cell *matHeaderCellDef class="sub-title"> Grado </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="grado"> 
            {{element.Solicitud.GradoEducativo.Nombre}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="porcentaje">
          <mat-header-cell *matHeaderCellDef  class="sub-title">Porcentaje sugerido </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="porcentaje"> 
            {{element.Porcentaje + '%'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Opciones" class='column-opciones'>
              <button mat-icon-button color="basic" aria-label="Descargar documentos"  matTooltip="Descargar documentos"  matTooltipPosition="right" (click)="downloadDocs(element.RetroEstudioSolicitudID, element.Solicitud.Alumno.CURP)">
                <mat-icon>cloud_download</mat-icon></button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>

      </mat-table>      
      
      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
      </div>

      <mat-paginator #paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]" 
      (page)="pageEvent = getServerData($event)">
    </mat-paginator>

  </div>         
  <br><br>
</mat-card>