import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, Self, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, NgControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmpleadoModelOperation } from '../../_models/empleado.model';
import { EmpleadoService } from '@app/_services/empleado.service';
import { RolService } from '@app/_services/rol.service';
import { first } from 'rxjs/operators';
import { Rol } from '@app/_models/rol';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Centro } from '@app/_models/centro';
import { CentroService } from '@app/_services/centro.service';
import { Area } from '@app/_models/area';
import { AreaService } from '@app/_services/area.service';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { empty, Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {map, startWith} from 'rxjs/operators';
import { FocusMonitor } from '@angular/cdk/a11y';


export interface Item {
  value: number;
  description: string;
}

@Component({
  selector: 'app-detalle-usuario',
  templateUrl: './detalle-usuario.component.html',
  styleUrls: ['./detalle-usuario.component.scss']
})
export class DetalleUsuarioComponent implements OnInit{

  detalleUsuarioForm: FormGroup;

  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatAutocomplete;

  @ViewChild('allSelectedRoles') private allSelectedRoles: MatOption;
  @ViewChild('mySelRol') mySelRol: MatSelect;

  enableBtn = false;
  textBtnCancelar = "CANCELAR";
  rol: Item;
  disabled_ = false;

  loading: boolean = false;
  error: boolean = false;
  correcto: boolean = false;
  errorMessage: string = "";
  message: string = "";

  areas: Area[] = [];

  centros: Centro[] = [];
  regiones: Region[] = [];
  selectedCentros : Centro [];
  option = [];
  selectedValue = "";
  cargandoCentros = null;
  cargandoRegiones = null;

  idUsuario: number;
  idEmpleado : number;

  selectedRegiones : number [];
  esperando : boolean;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allRoles: Rol[] = [];
  listRoles: Observable<Rol[]>;   
  filteredRoles: Observable<Rol[]>;
  
  @ViewChild('rolInput') rolInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoRol') matAutocomplete: MatAutocomplete;

  private changeCallback: Function;
  private touchedCallback: Function;
  focused = false;
  isAllSelected = false;
  rolesLength = 0;
  selectedItems: Rol[] = new Array<Rol>();
  filteredItems: Rol[];

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DetalleUsuarioComponent>,
    private usuarioService:EmpleadoService,
    private centroService: CentroService,
    private rolService:RolService,
    private regionService:RegionService,
    private areaService:AreaService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: EmpleadoModelOperation) {
  } 

  ngOnInit() {
    this.esperando = true;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    if(this.data && this.data.user && this.data.user && this.data.user.EmpleadoID){
      this.idEmpleado = this.data.user.EmpleadoID;
    }

    this.getRoles();
    this.getAllRoles();

    console.log("operacion "  + this.data.operacion);
    this.data.user = {
      EmpleadoID: 0, NumeroEmpleado: null, UsuarioID: 0, Correo: '', Nombre: '',ApellidoPaterno: '', ApellidoMaterno: '', EstatusCuentaID: 0, Password: '',
      Area: { AreaID:null, Nombre:'', Clave:''},  Roles_: [], IdsRoles: [], IdsCentros: [], Centros:[], Regiones:[], IdsRegiones : [] 
    };
    if (this.data.operacion ===  'NUEVO') {
      this.disabled_ = false;

    } else {
      this.disabled_ = true;
    }

    this.cargandoCentros = "(Cargando ...)";
    this.cargandoRegiones = "(Cargando ...)";
    this.textBtnCancelar = "CANCELAR";

    this.detalleUsuarioForm = this.formBuilder.group({
      numEmpleado: [{  value: '' }, Validators.required],
      area: [{  value: '' }, Validators.required],
      nombre: [{  value: '',}, Validators.required],
      apellidoPaterno: [{  value: '' }, Validators.required],
      apellidoMaterno: [{  value: '' }],
      email: [{ value: '', disabled: this.disabled_ }, [Validators.required, Validators.email]],
      centros: [{  value: '' }, Validators.required],
      regiones: [{  value: '' }, Validators.required],
      rol_: [{  value: '' }]
    });   

    this.getRegiones();
    this.getAreas();
    this.getCentros();

    if (this.data.operacion === 'EDITAR') {
      this.usuarioService.detalle(this.idEmpleado).then( e => {
        if(e.EmpleadoID != null){
          this.data.user = e;

          console.log("Roles obtenidos" + JSON.stringify(this.data.user.Roles_));
          
          this.selectedItems = this.data.user.Roles_;
          this.filteredItems.forEach( i => {
            this.selectedItems.forEach(s => {
              if (i.RolID == s.RolID)
                i.Selected = true
            })
          });

          this.detalleUsuarioForm.get('numEmpleado').setValue(this.data.user.NumeroEmpleado);
          this.detalleUsuarioForm.get('area').setValue(this.data.user.Area.AreaID);
          this.detalleUsuarioForm.get('nombre').setValue(this.data.user.Nombre);
          this.detalleUsuarioForm.get('apellidoPaterno').setValue(this.data.user.ApellidoPaterno);
          this.detalleUsuarioForm.get('apellidoMaterno').setValue(this.data.user.ApellidoMaterno);
          this.detalleUsuarioForm.get('email').setValue(this.data.user.Correo);
          this.detalleUsuarioForm.get('centros').setValue(this.data.user.IdsCentros);
          this.detalleUsuarioForm.get('regiones').setValue(this.data.user.IdsRegiones); 
          
          //if (this.data.user.IdsRegiones.includes(0)) this.toggleAllSelectionRegiones()  
          //if (this.data.user.IdsCentros.includes("0")) this.toggleAllSelectionCentros()   
          
          this.esperando = false;
          this.enableBtn = !this.detalleUsuarioForm.invalid
          
          this.centroService.consultaCentrosXRegiones(this.data.user.IdsRegiones, this.idUsuario).then(centros => {
            console.log("Obtenidos" +centros.length);
            this.centros = centros;
            this.cargandoCentros = null;
          }).catch(err => {
            this.cargandoCentros = "(Error)";
            console.log("Error" + err);
          });
        }
      } ).catch(err => {
        console.log("Error" + err);
      });    
    }else{
      this.esperando = false;
    }
  }

  onSubmit(){
    this.textBtnCancelar = "CANCELAR";
    this.error = false;
    this.correcto = false;
    this.loading = true;
    
    if (this.detalleUsuarioForm.invalid) {
      console.log("forma invalida");
      return;
    }
      
    this.esperando = true;
    this.data.user.Roles_ = this.selectedItems;
    this.data.user.Area = this.areas.find( op => op.AreaID === this.detalleUsuarioForm.controls.area.value);
    this.usuarioService.post(this.data.user)
    .pipe(first())
    .subscribe(
    data => {
      this.loading = false;
      if (data.Success){
        
        this.message = "Usuario guardado correctamente";
        const dialogData = new ConfirmDialogModel('', this.message, false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        this.dialogRef.close(true);

        this.data.user = {
          EmpleadoID: 0, NumeroEmpleado: 0, UsuarioID: 0, Correo: '', Nombre: '',ApellidoPaterno: '', ApellidoMaterno: '', EstatusCuentaID: 0, Password: '',
          Area: { AreaID:null, Nombre:'', Clave:''},  Roles_: [], IdsRoles: [], IdsCentros: [], Centros:[], Regiones:[], IdsRegiones : [] 
        }
        this.correcto = true;
        
      }else{
        this.errorMessage = data.Message;
        this.error = true;
      }           
      this.esperando = false; 
    },
    error => {
      this.error = true;
      this.errorMessage = "Error al guardar el empleado";
      this.loading = false;
      this.esperando = false;
    });
  }

  cancelar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    
  }

  verify() {
    
    if (this.detalleUsuarioForm.invalid) {
      this.enableBtn = false;
    }else{
      this.enableBtn = true;
    }

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  } 

  validarCaracteres(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = "0123456789 "
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }

  omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }
  
  getAreas() {
    this.areaService.get()
    .pipe(first())
    .subscribe(
      data => {
        this.areas = data;
      },
      error => {       
        console.log("Error al obtener las áreas: ", error);
      });
  }

  getAllRoles(){
    this.rolService.getRoles().then( roles => {
      this.allRoles = roles;
    });
  }

  private _filterRol(value: any): Rol[] {
    const filterValue = value ? ((typeof value === 'string') ? value.toLowerCase() : value.Nombre.toLowerCase()) : "";
    return this.allRoles.filter(option => option.Nombre.toLowerCase().startsWith(filterValue));
  }     

  getRoles() {
    this.rolService.get()
    .pipe(first())
    .subscribe(
      data => {
        this.filteredRoles = this.detalleUsuarioForm.get('rol_').valueChanges.pipe(startWith(''), 
        map((rol: string | null) => rol ? this._filterRol(rol) : data.slice()));
        this.filteredItems = data;
      },
      error => {      
        console.log("Error al obtener los roles: ", error);
      });
  }

  getRegiones() {
    this.regionService.getAll().then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }

  onSelectedRegiones(entrando) {
    if(!entrando){
      var regiones = this.detalleUsuarioForm.get('regiones').value;  
      if ( regiones.includes(0)){
        this.getCentros()
      }else{
        this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
          this.centros = centros;
          this.cargandoCentros = null;
          this.selectedCentros = [];
          this.detalleUsuarioForm.get('centros');  //setValue(this.selectedCentros);
        }).catch(err => {
          this.cargandoCentros = "(Error)";
          console.log("Error" + err);
        }); 
      }
    }
  }
  
  getCentros() {
    this.centroService.getbyUser(this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  get formControls() { return this.detalleUsuarioForm.controls; }

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.detalleUsuarioForm.controls != null && 
      this.detalleUsuarioForm.controls.userType != null &&
      this.detalleUsuarioForm.controls.userType.value != null){
        if(this.detalleUsuarioForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
    }
  } 

  toggleAllSelectionRegiones() {
    
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  toggleAllSelectionCentros() {
    
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());  
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }
    if(this.detalleUsuarioForm.controls != null && 
      this.detalleUsuarioForm.controls.userType != null &&
      this.detalleUsuarioForm.controls.userType.value != null){
        if(this.detalleUsuarioForm.controls.userType.value.length==this.centros.length)
          this.allSelectedRegion.select();
    }
  }
  
  toggleAllSelectionRoles() {
    if (this.allSelectedRoles.selected) {
      this.mySelRol.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRol.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  addRoles(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    
    console.log("Add roles ");
    if ((value || '').trim()) {
    }

    if (input) {
      input.value = '';
    }
  }  

  removeRol(rol: Rol): void {
    const index = this.data.user.Roles_.indexOf(rol);
    const inx = this.selectedItems.indexOf(rol);

    if (index >= 0 || inx >= 0) {
      this.selectedItems.splice(inx, 1);
      this.data.user.Roles_ ? this.data.user.Roles_ = this.selectedItems : this.data.user.Roles_ = [];
      
      this.filteredItems.forEach( f => {
        if(f.RolID == rol.RolID)
          f.Selected = false
      });
    }
  }

  selectedRol(event: MatAutocompleteSelectedEvent): void {
    
    console.log("event value " +JSON.stringify( event.option.value));

    var rol: Rol = event.option.value;
    var encontrado = this.data.user.Roles_.find(x => x.RolID == rol.RolID);
    var isAdmin = rol.Clave == 'ADMIN';

    var temp = [];
    if(isAdmin){
      temp = this.allRoles;
      this.data.user.Roles_ =  this.allRoles.filter((item,index)=>{
        return ( this.allRoles.indexOf(item) == index)
      });
    }else{
      if(!encontrado){
        this.data.user.Roles_.push(event.option.value);
       }
    }
    this.detalleUsuarioForm
  }

  toggleSelectAll(){
    this.isAllSelected = !this.isAllSelected;
    if ( this.isAllSelected ){
        this.selectedItems = this.filteredItems;
        this.filteredItems.forEach( i => {i.Selected = true});
        this.isAllSelected = true;
    } else {
      this.selectedItems = [];
      this.filteredItems.forEach( i => {i.Selected = false});
    }
  }

  toggleSelection(item: Rol) {
    item.Selected = !item.Selected;
    let aux = this.filteredItems;

    if (item.Selected) {
      if(item.RolID == 1 && item.Clave == "ADMIN") {
        this.filteredItems.forEach( i => {
          i.Selected = true
          const include = this.selectedItems.findIndex(value => value.RolID === i.RolID );
          if(include == -1) {
            this.selectedItems.push(i)
          }
        });
        return
      }
      const include = this.selectedItems.findIndex(value => value.RolID === item.RolID );
      if(include == -1) {
        this.selectedItems.push(item);
      }
    } else {
      const i = this.selectedItems.findIndex(value => value.Nombre === item.Nombre );
      
      this.filteredItems.forEach( f => {
        if(f.RolID == item.RolID)
          f.Selected = false
      });

      this.selectedItems.splice(i, 1);
    }
  }
}
