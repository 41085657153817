import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { AvancePropuesta } from '@app/_models/avancePropuesta';

@Injectable({ providedIn: 'root' })
export class avancePropuestaService {

    constructor(private http: HttpClient) {}

    obtenerAvance(PropuestaBecaID: number): Promise<AvancePropuesta> {
        return this.http.get<AvancePropuesta>(`${environment.apiUrl}/${PropuestaBecaID}/ObtenerAvancePropuesta`).toPromise();
    }
}