import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { map, filter, scan } from 'rxjs/operators';
import { Observable, Subject, asapScheduler, pipe, of, from,
  interval, merge, fromEvent } from 'rxjs';
import { Redes } from '@app/_models/redes';
import { CentroService } from '@app/_services/centro.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private returnUrl = '/';
  public redes : Redes;

  constructor(private authenticationService: AuthenticationService,
              private router: Router) 
  {
    let anahuac = localStorage.getItem('usrAhc');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
        if(this.returnUrl == "/login/anahuac" || anahuac == "true") this.isAnahuac = true;
      }
    });
     
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.isAdmin = user.IsAdmin;
        this.isEmpresa = user.IsEmpresa;
        this.isProspect = user.IsProspecto;
      }
    });

    this.redes = new Redes();

    this.redes.Correo = "info@rcsa.mx";
    this.redes.Telefono = "8159500160";
    this.redes.Facebook = "https://www.facebook.com/semperaltius";
    this.redes.Youtube = "https://www.youtube.com/channel/UCKlJZHTHptnvtjYvIAtARFQ";
    this.redes.Linkedin = "https://www.linkedin.com/authwall?trk=gf&trkInfo=AQG3RhCrEhI3ZAAAAXL4SLzAtiAEfBxLAqDKHf0dX-i5wd-FBEz24UJicQp0CnFy8OAfo5MBp0ZYYX3_u5iTQbmIy1N31cE4R9kXW4E1tI24JKiPTq7NZkHLEJHLboDW6vX411Q=&originalReferer=https://www.semperaltius.edu.mx/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fred-de-colegios-semper-altius";
    this.redes.Pinterest = "https://www.pinterest.com/semperaltius/";
    this.redes.Instagram = "https://www.instagram.com/redsemperaltius/";
 }

  public isProspect: boolean = false;
  public isAdmin: boolean = false;
  public isEmpresa: boolean = false;
  public isAnahuac: boolean = false;
  
  get isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated;
  }

  ngOnInit() {
    console.log("En el footer");
   let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
  }
}