import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, NumberValueAccessor } from '@angular/forms';
import { Seccion } from '@app/_models/seccion';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBecaTabsComponent } from '../propuesta-beca-tabs/propuesta-beca-tabs.component';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { MatTableDataSource } from '@angular/material/table';
import { PropuestaAlumno } from '@app/_models/propuesta-alumno';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
import { AvancePropuesta } from '@app/_models/avancePropuesta';

@Component({
  selector: 'app-detalle-alumno-becas',
  templateUrl: './detalle-alumno-becas.component.html',
  styleUrls: ['./detalle-alumno-becas.component.scss']
})
export class DetalleAlumnoBecasComponent implements OnInit {
  propuesta : PropuestaBeca;
  nuevaPropuestaAlumno: FormGroup;
  displayedColumns: string[] = ['seccion', 'grado', 'alumnosa', 'alumnosni', 'alumnosr', 'total', 'variacacion'];
  idColegio: number
  idUsuario: number;
  enableBtn = false;
  revicion = false;
  esperando = false;
  result : string;
  Seccion = null;

  propuestaEditable : boolean;
  
  secciones: Seccion[] = [];
  dataSource = new MatTableDataSource<PropuestaAlumno>();

  constructor(public dialog: MatDialog, 
    private formBuilder: FormBuilder, 
    private authenticationService: AuthenticationService, 
    private seccionService: SeccionService,
    private propuestaBecaService:PropuestaBecaService,
    public dialogRef: MatDialogRef<PropuestaBecaTabsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp,
    public dialogClose: MatDialogRef<PropuestaBecaTabsComponent>) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    console.log("DetalleAlumnoBecasComponent" );

    if(this.data.Operation == 'Revision'){
      this.revicion = true
      this.propuesta = this.data.Objeto;
      this.nuevaPropuestaAlumno = this.formBuilder.group({
        seccion: [''],
      });
    }
    if(this.data.Operation == 'Editar'){  
      this.enableBtn  = true;          
    }
    this.nuevaPropuestaAlumno = this.formBuilder.group({
      seccion: [''],
    });

    this.getSecciones() 
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }
  
  getSecciones() {
    this.Seccion = 'Cargando...'
    var Todos = new Seccion();
    Todos.SeccionID = 0;
    Todos.Nombre = "Todos";

    if(this.data != null && this.data.Objeto != null){
      this.propuesta = this.data.Objeto;
      var centro = this.propuesta.Centro.CentroID;
      this.seccionService.consultaSecciones(centro).then(secciones => {
        this.Seccion = 'Sección'
        this.secciones = secciones;
        this.secciones.splice( 0, 0, Todos );
      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }
  
  onSelectSeccion(entrando) {
    if(!entrando){
      var SeccionID  =  this.nuevaPropuestaAlumno.get('seccion').value;  
      var SeccStr = "" + SeccionID;

      if(SeccStr != ""){
        this.esperando = true;
        this.enableBtn = false;
  
        this.propuestaBecaService.getPropuestaBecasAlumnos(this.propuesta, SeccionID).pipe(first()).subscribe(
        data => {
          this.propuesta.PropuestaAlumnos = data.PropuestaAlumnos;
          this.dataSource = new MatTableDataSource<PropuestaAlumno>(data.PropuestaAlumnos);
          this.esperando = false;
          this.enableBtn = true;
        },
        error => {
          this.esperando = false;
        });        
      }
    }
  }

  save(){
    this.result = "guardar";
    this.enableBtn = false;
    this.esperando = true;
    this.propuesta.UsuarioID = this.idUsuario;

    var SeccionID  =  this.nuevaPropuestaAlumno.get('seccion').value;      

    if(SeccionID != null){
      this.propuestaBecaService.GuardarPropuestaBecasAlumnos(this.propuesta, SeccionID ).pipe(first()).subscribe(
        
        data => {
          if (data.Exito){
            

            const dialogData = new ConfirmDialogModel('', "Conteo de alumnos guardado", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            }); 
          }  
          this.esperando = false;  
          this.enableBtn  = true;
        },
        error => {
          this.esperando = false;  
        }
  
      );
    }
    console.log("Saliendo");
  }

  verify() {
    if(this.data.Operation == 'Revision') return

    if(this.nuevaPropuestaAlumno.value.seccion != null  )
    {
      this.enableBtn = true;
      return
    }
  }

  omit_special_char(e: any) {
    if (e.keyCode === 48 || e.key == "0" || e.charCode == 48) {
      return false;
    }
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  avance(){
    var avance = new AvancePropuesta();
    avance.DatosGenerales = true;
    avance.Alumnos = true;
    avance.Cuotas = true;
    avance.PropuestaBecas = true;
    avance.ResumenBecas = true;
    avance.Analisis = true;

  }
}