import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalleBecasExtComponent } from '../_components/detalle-becas-ext/detalle-becas-ext.component';
import { CentroService } from '@app/_services/centro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { first } from 'rxjs/operators';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { SolicitudBecaExterna } from '@app/_models/becaExterna';
import { MatTableDataSource } from '@angular/material/table';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { HistorialSolicitudComponent } from '@app/_components/historial-solicitud/historial-solicitud.component';
import { BecaExternaService } from '@app/_services/beca-externa.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-becas-externas',
  templateUrl: './becas-externas.component.html',
  styleUrls: ['./becas-externas.component.scss']
})
export class BecasExternasComponent implements OnInit {

  displayedColumns: string[] = ['colegio','seccion', 'id', 'nombre', 'tipobeca', 'porcentaje',  'acciones'];
  dataSource = new MatTableDataSource<SolicitudBecaExterna>();
  revisionSolicitudForm: FormGroup;
  idUsuario: number;

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  grados : GradoEducativo[];
  ciclosBecas : Ciclo [];  
  tiposBeca : TipoBeca [];  

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;

  errorMessageP: string = "";

  errorMessage: string = "";
  error: boolean = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('mySelGrados') mySelGrados: MatSelect;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;

  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private becasExternaService : BecaExternaService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private tipoBecaService : TipoBecaService,
    private gradoService: GradoEducativoService,
    private cicloBecaService: CicloBecaService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    
    this.getCiclosBecas();
    this.cargarCentros();

    this.revisionSolicitudForm = this.formBuilder.group({
      ciclos: ['', Validators.required],
      centros: [''],
      tipobeca: [''],
      secciones: [''],
      nombre: ['']
    });  

    this.tipoBecaService.listadoBecasCicloActivoExternas().then(tiposBeca => {
      this.tiposBeca = tiposBeca
      }).catch(err => {
      console.log("Error" + err);
    }); 
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }
 
  cargarCentros() {
    this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }
 
  nuevaBeca(): void{
    const dialogRef = this.dialog.open(DetalleBecasExtComponent, {
      width: '80%',
      data: {Operation: 'Nuevo'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  editar(extemporanea: BecaExtemporanea): void {
    const dialogRef = this.dialog.open(DetalleBecasExtComponent, {
      width: '80%',
      data: {Operation: 'Editar', Objeto: extemporanea}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  eliminar(externa: SolicitudBecaExterna): void {

    console.log("Beca externa " + externa.SolicitudBecaExternaID);

    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la beca externa?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.becasExternaService.delete(externa)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscar();
            }else{
              this.buscar();
              this.errorMessage = data.Message;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
            }            
          },
          () => {
            this.buscar();
            this.error = true;
            this.errorMessage = "Error al eliminar la beca externa";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedio un error al eliminar la beca externa.', false);    
          });
      }
    });        
  }  

  verHistorial(externa:SolicitudBecaExterna){
    const dialogRef = this.dialog.open(HistorialSolicitudComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: externa.Solicitud}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionSolicitudForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;
        this.grados = [];

      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  buscar() { 
    var ciclos = this.revisionSolicitudForm.get('ciclos').value;  
    var centros   = this.revisionSolicitudForm.get('centros').value;  
    var tipos   = this.revisionSolicitudForm.get('tipobeca').value;  
    var secciones   = this.revisionSolicitudForm.get('secciones').value;  
    var nombre   = this.revisionSolicitudForm.get('nombre').value;  

    if(ciclos == null || ciclos.length == 0){
      console.log("ciclos "+ JSON.stringify(ciclos));
      return;
    }

    this.becasExternaService.buscar(this.pageSize, (this.pageIndex+1), ciclos, centros, secciones,
    tipos, nombre, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<SolicitudBecaExterna>(data.Dato);
      },
      () => {
        this.errorMessageP = "Error al obtener la lista"; 
      });
  }
 

  descargarDoc(becaExterna: SolicitudBecaExterna){

    
    this.becasExternaService.descargarDocumento(becaExterna.SolicitudBecaExternaID)
    .subscribe (
      data => {
        saveAs(data, becaExterna.NombreOriginal, {
          type: becaExterna.MimeType
       });
      }, 
      () => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }  

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }       

  omit_special_char(e: any) {
    if (/^[ñÑa-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
    this.allSelectedSecciones.deselect();
    return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }  

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }
  
  onSelectedSecciones(entrando) {
    if(!entrando){ 
      var centros   = this.revisionSolicitudForm.get('centros').value;  
      var secciones   = this.revisionSolicitudForm.get('secciones').value;  

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      });
    }
  }    

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
    this.allSelectedCentros.deselect();
    return false;
    }

    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  }


  
}
