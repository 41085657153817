import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleAdmSobrepasosComponent } from '../_components/detalle-adm-sobrepasos/detalle-adm-sobrepasos.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Centro } from '@app/_models/centro';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { SobrepasoService } from '@app/_services/sobrepasos.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { Ciclo} from '@app/_models/ciclo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { Sobrepaso } from '@app/_models/sobrepaso';
import { HistorialComponent } from '@app/_components/historial/historial.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-adm-sobrepasos',
  templateUrl: './adm-sobrepasos.component.html',
  styleUrls: ['./adm-sobrepasos.component.scss']
})
export class AdmSobrepasosComponent implements OnInit {

  displayedColumns: string[] = ['Ciclo', 'Colegio', 'Seccion', 'CURP', 'Nombre', 'pAcademico','pConducta', 'adeudo','Acciones'];
  dataSource = new MatTableDataSource<Sobrepaso>();
  //dataSource = ELEMENT_DATA;
  adminSobrePasoForm: FormGroup;
  
  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  pageEvent: PageEvent;
  datasource: null;
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
  
  filtro: string = "";

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  selectedCentros : Centro [];
  selectedSecciones : Seccion [];  
  ciclosBecas : Ciclo [];  

  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoCiclos = null;

  idUsuario: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  constructor(public dialog: MatDialog, 
    private authenticationService: AuthenticationService, 
    private sobrepasoService : SobrepasoService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private cicloBecaService: CicloBecaService,
    private formBuilder: FormBuilder) { }

    buscarAlumno(): void {
    const dialogRef = this.dialog.open(DetalleAdmSobrepasosComponent, {
      width: '80%',
      data: {Operation: 'NUEVO'}
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getSobrepasos();
    });
  }

  editarSobrepaso(sobrepaso:Sobrepaso): void {
    const dialogRef = this.dialog.open(DetalleAdmSobrepasosComponent, {
      width: '80%',
      data: {Operation: 'EDITAR', Objeto: sobrepaso}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result ){
        this.getSobrepasos(); 
      }
    });
  }

  eliminarSobrepaso(sobrepaso:Sobrepaso){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar el sobrepaso?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        
        this.sobrepasoService.delete(sobrepaso)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Exito){
              this.getSobrepasos();
            }else{
              this.errorMessage = data.Mensaje;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Mensaje, false);    
            }            
          },
          () => {
            this.error = true;
            this.errorMessage = "Error al eliminar la empresa";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la Solicitud.', false);    
          });
      }
    });    
  }
  
  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.adminSobrePasoForm = this.formBuilder.group({
      ciclo: ['', Validators.required],
      centros: [''],
      secciones: [''],
      filtro: ['']
    });

    this.getSecciones();    
    this.getCiclosBecas();
    this.initCentros(null);
  }

  getSecciones() {

    this.seccionService.getAll().then(secciones => {
      this.secciones = secciones;
      this.cargandoSecciones = null;
    }).catch(err => {
      this.cargandoSecciones = "(Error)";
      console.log("Error" + err);
    });
  }




  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.getSobrepasos();

    return event;
  }

  getSobrepasos() {
    this.loading = true;

    let ciclo = this.adminSobrePasoForm.get('ciclo').value;  
    if(ciclo == null || ciclo.length == 0){
      return;
    }
    
    var centros   = this.adminSobrePasoForm.get('centros').value;  
    let secciones = this.adminSobrePasoForm.get('secciones').value;  
    let filtro = this.adminSobrePasoForm.get('filtro').value;;
    if(!centros){
      centros = null;
    }

    this.sobrepasoService.buscar(this.pageSize, this.pageIndex+1, ciclo, centros, secciones, filtro, this.idUsuario).pipe(first()).subscribe(
      data => {
        const sortedSobrepasos = data.Dato.sort((a, b) => b.FechaCreacion > a.FechaCreacion ? 1: -1);
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<Sobrepaso>(sortedSobrepasos);
      },
      ()=> {}
    );
  }

  filterText(){
    setTimeout (() => {
   }, 1000);    

    this.pageIndex = 0;
    this.getSobrepasos();
  }


  descargarDoc(sobrepaso: Sobrepaso){

    
    this.sobrepasoService.descargarDocumento(sobrepaso.SobrepasoID)
    .subscribe (
      data => {
        saveAs(data, sobrepaso.NombreOriginal, {
          type: sobrepaso.MimeType
       });
      }, 
      () => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }  


  verHistorial(sobrepaso: Sobrepaso){
    const dialogRef = this.dialog.open(HistorialComponent, {
      width: '60%',
      data: {Operation: 'Sobrepaso', Objeto: sobrepaso}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }



  onSelectedCiclos(entrando) {
    if(!entrando){
    }
  }  


  onSelectedColegios(entrando) {
    if(!entrando){
      var centros   = this.adminSobrePasoForm.get('centros').value;  

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones;
        this.cargandoSecciones = null;

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.adminSobrePasoForm.controls != null && 
      this.adminSobrePasoForm.controls.userType != null &&
      this.adminSobrePasoForm.controls.userType.value != null){
      if(this.adminSobrePasoForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  onSelectedSecciones(entrando) {
    if(!entrando){
      var centros   = this.adminSobrePasoForm.get('centros').value;  
      var secciones   = this.adminSobrePasoForm.get('secciones').value;  

    }
  }  

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }     

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }
    if(this.adminSobrePasoForm.controls != null && 
      this.adminSobrePasoForm.controls.userType != null &&
      this.adminSobrePasoForm.controls.userType.value != null){
      if(this.adminSobrePasoForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }

  initCentros(regiones: number[]){
    this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
      this.adminSobrePasoForm.get('centros').setValue(this.selectedCentros);
    }).catch(err => {
      //this.hayErrores = true;
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  
}
