import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { TipoBeca } from '@app/_models/tipoBeca';
import { OperationResult } from '@app/_models/operationResult';
import { TipoBecaSeccionGrado } from '@app/_models/tipoBecaSeccionGrado';
import { TipoBecaSeccion } from '@app/_models/tipoBecaSeccion';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { Centro } from '@app/_models/centro';

@Injectable({
  providedIn: 'root'
})
export class TipoBecaService {
  constructor(private http: HttpClient) {}

  consultaTiposBecaAlumno( AlumnoID : number, CicloID: number){
    return this.http.get<TipoBeca[]>(`${environment.apiUrl}/TipoBeca/${AlumnoID}/${CicloID}/PorAlumno`).toPromise();
  }    

  consultaTiposBeca():Promise<TipoBeca[]>{
    return this.http.get<TipoBeca[]>(`${environment.apiUrl}/TipoBeca/GetAll`).toPromise();
  }

  consultaTiposBecaXCicloID(CicloID : number):Promise<TipoBeca[]>{
    return this.http.get<TipoBeca[]>(`${environment.apiUrl}/TipoBeca/${CicloID}/GetByCiclo`).toPromise();
  }

  listadoBecas(nRegistros:number, nPagina:number, filtro:string){
    return this.http.post<any>(`${environment.apiUrl}/ListadoBecas`, {nRegistros, nPagina, filtro } );
  }
  
  // listadoBecasXId(id: number):Promise<TipoBeca[]>{
  //   return this.http.get<TipoBeca[]>(`${environment.apiUrl}/${id}`).toPromise();
  // }

  listadoBecasXId(BecaID : number){
    return this.http.get<any>(`${environment.apiUrl}/${BecaID}/DetalleBeca`).toPromise();
  }

  listadoBecasCicloActivo(){
    return this.http.get<TipoBeca[]>(`${environment.apiUrl}/TipoBeca/CicloActivo`).toPromise();
  }

  listadoBecasCicloActivoExternas(){
    return this.http.get<TipoBeca[]>(`${environment.apiUrl}/TipoBeca/CicloActivoExternas`).toPromise();
  }

  getTipoBecaSecciones(TipoBecaID : number){
    return this.http.get<TipoBecaSeccion[]>(`${environment.apiUrl}/TipoBeca/${TipoBecaID}/GetTipoBecaXSeccion`).toPromise();
  }

  getGradosXTipoBecaSeccion(TipoBecaSeccionID : number){
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/TipoBeca/${TipoBecaSeccionID}/GetGradosXTipoBecaSeccion`).toPromise();
  }


  GetGradosTipoBecaReqCentroSec(GetGradosTipoBecaReqCentroSec : number){
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/TipoBeca/${GetGradosTipoBecaReqCentroSec}/GetGradosTipoBecaReqCentroSec`).toPromise();
  }

  

  getCentroTipoBecaSeccion(TipoBecaSeccionID : number){
    return this.http.get<Centro[]>(`${environment.apiUrl}/TipoBeca/${TipoBecaSeccionID}/GetCentrosTipoBecaSeccion`).toPromise();
  }  


  getGradosXTipoBecaSecCen(TipoBecaSeccionID : number, CentroID: string){
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/TipoBeca/${TipoBecaSeccionID}/${CentroID}/GetGradosXTipoBecaSecCentro`).toPromise();
  }  

  
  /*getGradosXTipoBecaReqSec(TipoBecaRequisitoSeccionID : number){
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/TipoBeca/${TipoBecaRequisitoSeccionID}/GradosXTipoBecaReqSec`).toPromise();
  }  */


  

  post(beca: TipoBeca){
    
    return this.http.post<any>(`${environment.apiUrl}/TipoBeca`,  beca );
  }

  delete(beca: TipoBeca){
    let params = new HttpParams();
    params = params.append('TipoBecaID', beca.TipoBecaID.toString() );

    return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/TipoBeca`, { params } );
  }     
}
