import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EstatusModificacionBeca } from '@app/_models/estatusModificacionBeca';
import { MontosPropuesta } from '@app/_models/montosPropuesta';
import { ObjetoOp } from '@app/_models/objectOperation';
import { ModificacionBecasService } from '@app/_services/modificacionBeca.service';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-detalle-revision-modificacion-beca',
  templateUrl: './detalle-revision-modificacion-beca.component.html',
  styleUrls: ['./detalle-revision-modificacion-beca.component.scss']
})
export class DetalleRevisionModificacionBecaComponent implements OnInit {

  detalleModBeca: FormGroup;
  enableBtn = false;
  guardando = false;
  MontosPropuesta : MontosPropuesta;
  errorMessage: string = "";

  error: boolean = false;
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  
  nombreArchivo = 'Ningún archivo seleccionado'
  editar = true;

  constructor(private formBuilder: FormBuilder,
    public propuestaBecaService : PropuestaBecaService,
    private modificacionBecaService:ModificacionBecasService,
    public dialogRef: MatDialogRef<DetalleRevisionModificacionBecaComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.data
    this.detalleModBeca = this.formBuilder.group({
      presupuesto: ['', Validators.required],
      costo: ['', Validators.required],
    });

    //this.data.Objeto.EstatusModificacionBeca = new EstatusModificacionBeca();
    this.guardando = true;
    this.editar = this.data.Objeto.EstatusModificacionBeca.Clave == 'PEND';

    this.modificacionBecaService.ObtenerMontosPropuesta(this.data.Objeto.Solicitud.Centro.CentroID, 
      this.data.Objeto.Solicitud.Ciclo.CicloID,
      this.data.Objeto.Solicitud.SolicitudID).then(consultaMonto => {
      if(consultaMonto.Exito){
        this.MontosPropuesta = consultaMonto.Dato;
        console.log("Monto " + JSON.stringify(this.MontosPropuesta));

        var MontoAprobado = '$' + this.MontosPropuesta.MontoAprobado.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        var CostoAnual = '$' + this.MontosPropuesta.CostoAnual.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

          this.detalleModBeca.get('presupuesto').setValue(MontoAprobado);
          this.detalleModBeca.get('costo').setValue(CostoAnual);
         
      }
      this.guardando = false;
      }).catch(err => {
      console.log("Error" + err);
    });   

    // if (this.data.Operation == 'Editar') {
    //   this.detalleModBeca.get('presupuesto').setValue(this.data.Objeto.Solicitud.Centro.Nombre);
    //   this.detalleModBeca.get('costo').setValue(this.data.Objeto.Solicitud.Seccion.Nombre);
    // }
  }

  aprobar(){
    this.error = false;

    this.data.Objeto.EstatusModificacionBeca.EstatusModificacionBecaID = 5;
    this.guardarModificacion();
  }

  rechazar(){
    this.error = false;
    this.data.Objeto.EstatusModificacionBeca.EstatusModificacionBecaID = 6;
    this.guardarModificacion();
  }

  guardarModificacion(){

    this.modificacionBecaService.aprobarRechazarModificacionBeca(this.data.Objeto).then( res =>{
      if (res.Exito) {
        const dialogData = new ConfirmDialogModel('', "La operación se realizó correctamente", false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        this.dialogRef.close(true);
      }else{
        this.errorMessage = res.Mensaje;
        this.error = true;       
        this.guardando = false;
      }
    }

    ).catch(err => {
      console.log("Error" + err);
    }).finally(() => { });
  }

  descargarDoc(){
    this.modificacionBecaService.descargarDocumento(this.data.Objeto.ModificacionBecaSolicitudID)
    .subscribe (
      data => {
        saveAs(data, this.data.Objeto.NombreOriginal, {
          type: this.data.Objeto.MymeType
       });
      }, 
      error => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    this.Archivo_ = document.target.files[0];
    this.nombreArchivo = document.target.files[0].name;
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    
  }

  verify() {
    if(!this.detalleModBeca.invalid)
    {
      this.enableBtn = true;
      return true 
    }
    else{
      this.enableBtn = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  } 
}