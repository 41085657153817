import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Centro } from '@app/_models/centro';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Seccion } from '@app/_models/seccion';
import { TipoBecaEspecificaciones } from '@app/_models/TipoBecaEspecificaciones';
import { TipoBecaSeccionCentro } from '@app/_models/TipoBecaSeccionCentro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { TipobecaSecCenGraComponent } from '../tipobeca-sec-cen-gra/tipobeca-sec-cen-gra.component';
import { TipobecaSecGradoComponent } from '../tipobeca-sec-grado/tipobeca-sec-grado.component';

@Component({
  selector: 'app-tipobeca-sec-centro',
  templateUrl: './tipobeca-sec-centro.component.html',
  styleUrls: ['./tipobeca-sec-centro.component.scss']
})
export class TipobecaSecCentroComponent implements OnInit {

  displayedColumnsCentros: string[] = ['Aplicable', 'centro', 'grado'];

  dataSourceRequisitoCentros = new MatTableDataSource<TipoBecaSeccionCentro>();

  idSeccionesTipoBeca : number[] = [];
  centros: Centro[] = [];
  dataTipoBecaSecciones : TipoBecaEspecificaciones;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;
  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  reglonSeleccionado :TipoBecaSeccionCentro;
  enableBtn : boolean;
  //tipoRequisito: string = "";

  constructor(private centroService : CentroService,
    private seccionService: SeccionService,
    private tipoBecaService : TipoBecaService,
    private authenticationService: AuthenticationService, 
    public dialogRef: MatDialogRef<TipobecaSecCentroComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaSecciones = data.Objeto;
    }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.enableBtn = true;
    
    if(this.dataTipoBecaSecciones.BecaSeccionCentros == null ||this.dataTipoBecaSecciones.BecaSeccionCentros.length == 0){

      this.initCentros();
    }else{
      this.dataSourceRequisitoCentros= new MatTableDataSource<TipoBecaSeccionCentro>(this.dataTipoBecaSecciones.BecaSeccionCentros);
      this.length = this.dataSourceRequisitoCentros.data.length;
    }
  }

  initCentros(){
    console.log("Iniciando centros");
    this.guardando = true;      
    var selectedSecciones = [];
    //var selectedIds : number[] = [];

    if(this.dataTipoBecaSecciones.BecaSeccionCentros != null){
      //selectedSecciones =   this.dataTipoBecaSecciones.BecaSeccionCentros;
      //selectedIds = selectedSecciones.map(({Seccion}) => Seccion.SeccionID);
      selectedSecciones.push(this.dataTipoBecaSecciones.TipoBecaSeccion.Seccion.SeccionID);
      this.centroService.consultaCentrosXSeccionesUsuario(selectedSecciones, this.idUsuario).then(centros => {
        this.centros = centros;
        console.log("Centros consultados "+ this.centros.length);
        this.initTable();
        this.cargandoBecas = null;
      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoBecas = "(Error)";
        console.log("Error" + err);
      });
  
    }
    

  }

  especificacionXGrado(renglon : TipoBecaSeccionCentro){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.reglonSeleccionado = renglon;
    if(mCopy.BecaSeccionGrados == null || mCopy.BecaSeccionGrados.length ==0){
      mCopy.BecaSeccionGrados = [];
    }
    const dialogRef = this.dialog.open(TipobecaSecCenGraComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosSecciones(renglon);
      }else{
        console.log("Cancelado" + mCopy.BecaSeccionGrados.length);
        var requisitoBeca =this.dataTipoBecaSecciones.BecaSeccionCentros.find(r => r.Centro.CentroID  == renglon.Centro.CentroID);
        requisitoBeca.BecaSeccionGrados = mCopy.BecaSeccionGrados;      
      }
    });
  }

  eliminarEspecificacionXGrado(renglon : TipoBecaSeccionCentro){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por grado?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.BecaSeccionGrados  = [];
      }
    });    
  }

  initTable(){
    this.dataTipoBecaSecciones.BecaSeccionCentros = [];
    console.log("Iniciando tabla");
    this.centros.forEach(
      x => {
        var becaCentro = new TipoBecaSeccionCentro();
        becaCentro.TipoBecaSeccionID = this.dataTipoBecaSecciones.TipoBecaSeccion.TipoBecaSeccionID;
        becaCentro.Aplicable = false;
        becaCentro.Centro = x;
        becaCentro.SeccionNombre = this.dataTipoBecaSecciones.TipoBecaSeccion.Seccion.Nombre;
        becaCentro.TipoBecaNombre = this.dataTipoBecaSecciones.TipoBecaSeccion.TipoBecaNombre;
        becaCentro.TipoBecaSeccionID = this.dataTipoBecaSecciones.TipoBecaSeccion.TipoBecaSeccionID;
        becaCentro.BecaSeccionGrados = [];
        this.dataTipoBecaSecciones.BecaSeccionCentros.push(becaCentro);
      }
    );
    this.dataSourceRequisitoCentros = new MatTableDataSource<TipoBecaSeccionCentro>(this.dataTipoBecaSecciones.BecaSeccionCentros);
    this.length = this.dataSourceRequisitoCentros.data.length;
    console.log("antes de no guardando");
    this.guardando = false;
  }

  /*initIdSeccionesPorBeca(){
    this.guardando = true;
    var TipoBecaSeccionID = this.dataTipoBecaSecciones.TipoBecaSeccion.TipoBecaSeccionID;
    this.tipoBecaService.getCentroTipoBecaSeccion(TipoBecaSeccionID).then(centros => {
      this.centros = centros;
      this.initTable();
      this.cargandoBecas = null;
    }).catch(err => {
      this.cargandoBecas = "(Error)";
      console.log("Error" + err);
    });
  }*/

  agregarRequisitosSecciones(tipoBecaSecCentro : TipoBecaSeccionCentro){
    this.reglonSeleccionado.BecaSeccionGrados = tipoBecaSecCentro.BecaSeccionGrados;
  }  

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

 

}
