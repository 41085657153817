<br><br>
<form class="modal-form" name="nuevaPropuestaDatos" id="nuevaPropuestaDatos" [formGroup]="nuevaPropuestaDatos" autocomplete="off">
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
          <mat-label>Ciclo</mat-label>
          <mat-select  #mySelCiclo name="ciclo" formControlName="ciclo" 
              (ngModelChange)='verify()' [disabled]="!editable"
              id="ciclo" (openedChange)="onSelectedCiclos($event)" required> 
              <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID"> 
                  {{ciclo.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field  class="input-full-width xs-width" appearance="outline">
          <mat-label>Colegios</mat-label>
          <mat-select #mySelCentro name="centro" formControlName="centro" id="centro" [disabled]="!editable"
          (ngModelChange)='verify()' (openedChange)="onSelectedColegios($event)" required>
            <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" > 
              {{centro.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field> 
    </div>
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Monto presupuestal aprobado:</mat-label>
        <input matInput formControlName="monto" (keypress)="omit_special_mont($event)" readonly
         [disabled]="!propuestaEditable" (input)='verify()' (blur)="formatCurrency($event.target, 'blur')" required autocomplete="off">
    </mat-form-field>
    <br><br><br><br>
</form> 
<br>
<div class="loading-screen-icon" *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>
<div class="modal-form margin-div">
  <button mat-raised-button  (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
  <button mat-raised-button  (click)="save()" *ngIf="propuestaEditable" class="button xsbtn" [disabled]="!enableBtn || esperando">GUARDAR</button>
</div>