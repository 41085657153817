import { Component, OnInit } from '@angular/core';
import { CargaDocumentosService } from '@app/_services/cargaDocumentos.service';
import { Solicitud } from '@app/_models/solicitud';
import { SolicitudService } from '@app/_services/solicitud.service';
import { DocumentoRequisitoSolicitud } from '@app/_models/documentoRequisitoSol';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DatosGeneralesService } from '@app/_services/datosGenerales.service';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { Ciclo } from '@app/_models/ciclo';
import { CicloService } from '@app/_services/ciclo.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { EstudioSolicitudService } from '@app/_services/estudioSolicitud.service';
@Component({
  selector: 'app-requisitos',
  templateUrl: './requisitos.component.html',
  styleUrls: ['./requisitos.component.scss']
})
export class RequisitosComponent implements OnInit {
  NINGUN_ARCHIVO:string = '';  
  maxSizeFile:number = 61440000; // 5MB  
  Solicitud : Solicitud;
  AlumnoID : number;
  fechaExtemporanea = null;
  archivoCargado = false;
  isAnahuac: boolean = false;
  requiereDoc: boolean = true;
  fechaSolicitudFin: Date;
  documentos:DocumentoRequisitoSolicitud[] = [];
  tipoBeca = '';
  Ciclo: Ciclo;
  requiereEstudio = false;
  guardando: boolean = false;

  constructor(    
    private cargaDocumentosService:CargaDocumentosService,  
    private solicitudService : SolicitudService,
    private datosGeneralesService : DatosGeneralesService,
    public datepipe: DatePipe,
    private cicloService : CicloService,
    private authenticationService: AuthenticationService, 
    private seleccionBecaService : SeleccionBecaService,
    private estudioSolicitudService : EstudioSolicitudService,
    private router: Router,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    
    this.Solicitud = this.solicitudService.currentSolicitudValue;
    this.fechaSolicitudFin = this.Solicitud.FechaFin;
    
    this.estudioSolicitudService.requiereEstudio(this.Solicitud.SolicitudID).then(requiereEstudio => {
      this.requiereEstudio = requiereEstudio;
      }).catch(err => {
      console.log("Error" + err);
    });     

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      this.becaExtemporanea();
      }).catch(err => {
      console.log("Error" + err);
    }); 

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{
      this.tipoBeca = Solicitud.TipoBeca.Nombre
    }).catch(err => {
      console.log("Error" + err);
    }); 

    this.getGenerales();
    this.getDocumentos();
  }

  getGenerales(){
    this.datosGeneralesService.obtenerGeneralesBeca(this.Solicitud.SolicitudID).then(solicitud =>{
      this.Solicitud = solicitud;
      //this.fechaSolicitudFin = solicitud.Ciclo? solicitud.Ciclo.FechaSolicitudFin : new Date;
    });
  }
  getDocumentos() {
    this.cargaDocumentosService.obtenerDocumentosRequisitos(this.Solicitud.SolicitudID)
    .pipe(first())
    .subscribe(
      data => {
        console.log("Documentos " + data.length);
        if (data.length > 0) this.archivoCargado = true;
        this.documentos = data;
        
        this.documentos.forEach( x => x.EstatusCarga_ = this.NINGUN_ARCHIVO );
        this.documentos.forEach( x => x.DocumentoRequisitoSolicitudID > 0 ? x.BotonDescripcion = 'Modificar' :  x.BotonDescripcion = 'Seleccionar');
        this.documentos.forEach( x => x.DocumentoRequisitoSolicitudID > 0 ? x.EstatusCarga_ = x.NombreOriginal :  x.EstatusCarga_ = '');
        this.documentos.forEach( x => x.DocumentoRequisitoSolicitudID > 0 ? this.requiereDoc = false : this.requiereDoc = true);
        if (this.documentos.length == 0) {
          this.requiereDoc = false; 
          this.archivoCargado = true;
        }
      },
      error => {
        this.documentos = [];
      });
  }

  seleccionarArchivo2(){
    document.getElementById('upload-file').click();
  }

  seleccionarArchivo(clave:string){
    document.getElementById(clave).click();
  }
  
  adjuntarArchivo(fileInput: any, clave:string) {
    
    console.log("File " + JSON.stringify(fileInput));
    let doc = this.documentos.find(x => x.RequisitoBeca.Clave == clave);
      
    if (fileInput.target.files.length <= 0){
      doc.EstatusCarga_ = this.NINGUN_ARCHIVO;
      doc.Archivo_ = null;
    } else {
     
      doc.Archivo_ = fileInput.target.files[0];
      
      let extension = doc.Archivo_.name.substr(doc.Archivo_.name.lastIndexOf('.') + 1 );

      if (doc.RequisitoBeca.Extensiones.toLowerCase().includes(extension.toLowerCase())){
        doc.EstatusCarga_ = doc.Archivo_.name;
        this.archivoCargado = true
      }
      else{
        doc.EstatusCarga_ = this.NINGUN_ARCHIVO;
        this.archivoCargado = false
        doc.Archivo_ = null;
        this.showMessage("Información", "Sólo se permiten archivos con las siguientes extensiones: " + doc.RequisitoBeca.Extensiones, this.Solicitud.SolicitudID);
      }
    }
  }

  showMessage(title:string, message:string, SolicitudID : number):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

            
  }

  importarDocumentos(btn: string){
    
    console.log("importarDocumentos");
    if(!this.requiereDoc){
      if(btn == 'continuar'){
        if(this.requiereEstudio){
          this.router.navigate(['/estudio-socioeconomico']);
        }else{
          this.router.navigate(['/resumen-solicitud']);
        }
        return
      }
    }

    console.log("requiere archivos");
    var SinArchivo = false;
    this.documentos.forEach(element => {
      if(element.NombreOriginal == undefined && element.Archivo_ == undefined){
        if(!SinArchivo){
          SinArchivo = true;
        }
      }
    });

    console.log("SinArchivo " + SinArchivo);

    if(!SinArchivo){
      var  importar = false;
      let filesFD = new FormData();
      console.log("documentos length " + this.documentos.length);

      if(this.documentos.length > 0){
        this.documentos.forEach(documento => {    
          if (documento.Archivo_ != null && documento.Archivo_ != undefined)
          {
            if (documento.Archivo_.size <= this.maxSizeFile){
              importar = true;
              console.log("Agregando a lista " + documento.Archivo_);
              filesFD.append(documento.RequisitoBeca.RequisitoBecaID.toString(), documento.Archivo_, documento.Archivo_.name);
            } else {
              this.showMessage('Información', "El documento (" + documento.NombreOriginal ? documento.NombreOriginal : documento.Archivo_.name+ ") excede el peso permitido.", this.Solicitud.SolicitudID);
              return;
            }
          }
        });
  
        console.log("importar " +importar);
        if(importar){

          this.documentos.forEach(documento => {    
            var entrada: FormDataEntryValue = filesFD.get(documento.RequisitoBeca.RequisitoBecaID.toString());
            
            if(entrada == null){
              console.log("Archivo no agregado ");
            }else{
              console.log("entrada " + entrada.toString());

            }
          });

          this.guardando = true;
          this.cargaDocumentosService.importar(filesFD, this.Solicitud.SolicitudID)
          .pipe(first())
          .subscribe(
          data => {      
            if (data.Exito){
              this.guardando = false;
              if(btn == 'continuar'){
                this.showMessage('Información', data.Mensaje, this.Solicitud.SolicitudID);
                if(this.requiereEstudio){
                  this.router.navigate(['/estudio-socioeconomico']);
                }else{
                  this.router.navigate(['/resumen-solicitud']);
                }
                
                return
              }
            } else{
              this.guardando = false;
              this.showMessage('Error', data.Mensaje, this.Solicitud.SolicitudID);
            }
          },
          error => {
            this.guardando = false;
            console.log('error', error);
          });  
        }
  
      }else{
        this.guardando = false;
        if(btn == 'continuar'){
          if(this.requiereEstudio){
            this.router.navigate(['/estudio-socioeconomico']);

          }else{
            this.router.navigate(['/resumen-solicitud']);
          }
          
          return
        }else{
          this.router.navigate(['/pantalla-principal']);
          return
        }
      } 

    }else{
      this.guardando = false;
      const dialogData = new ConfirmDialogModel('Aviso', 'Debe cargar todos los documentos', false);    
      this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      return;
    }
  }

  guardar(){
    let latest_date = this.datepipe.transform(this.fechaSolicitudFin, 'dd/MM/yyyy');
    this.showMessage('Información', "Recuerda que debes completar la solicitud antes del día " + latest_date + " o tu solicitud será eliminada.", this.Solicitud.SolicitudID);

    this.solicitudService.enviarcorreoRecordatorio(this.Solicitud.SolicitudID).then( res =>{
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {
    });

    this.router.navigate(['/pantalla-principal']);
  }

  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if(e.FechaFin){
        this.fechaExtemporanea = e.FechaFin;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  } 
}