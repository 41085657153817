<mat-card-title class="card-title fz-20">Mensaje de ayuda</mat-card-title>
<mat-divider></mat-divider>
<br>

<form [formGroup]="correoAyudaForm" class="modal-form" name="correoAyudaForm" id="correoAyudaForm">  

    <div class="row margin-button">
        <mat-form-field class="input-centro-width xs-width" appearance="outline">
            <mat-label>Colegio</mat-label>
            <mat-select #mySelCentro name="centro" formControlName="centro" id="centro"
            (ngModelChange)='verify()'>
              <mat-option *ngFor="let centro of centros" [value]="centro.CentroID"> 
                {{centro.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

  
        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label>CURP</mat-label>
            <input matInput formControlName="curp" (ngModelChange)="validarCurp()" (ngModelChange)='verify()'>
            <mat-hint *ngIf="errorCurp"> El CURP tiene un formato inválido </mat-hint>   
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" style="left: 10px;" appearance="outline">
            <mat-label>Nombres</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="nombre">
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido paterno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="apellidoPaterno">
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" style="left: 10px;" appearance="outline">
            <mat-label>Apellido materno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="apellidoMaterno">
        </mat-form-field>

        
    <!--div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Correo de contacto</mat-label>
            <input matInput  formControlName="correo"  (ngModelChange)="validarCorreo()">
        </mat-form-field>
    </div><br>
    <mat-hint *ngIf="correoError"> Ingrese un correo válido </mat-hint> 
    <br-->
    <mat-form-field class="input-full xs-width" appearance="outline">
        <mat-label>Mensaje</mat-label>
        <textarea matInput maxlength="300" (ngModelChange)='verify()' formControlName="mensaje" rows="8"></textarea>
    </mat-form-field>
    <br>

    <div class="loading-screen-icon" *ngIf="enviando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br>
    <div class="row">
        <mat-error class="margin-button" *ngIf="error">{{errorMessage}}</mat-error>
    </div>
    
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación" >CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="aceptar()" style="margin-left: 1em;" [disabled]="!enableBtn || errorCurp" matTooltip="Enviar mensaje" >Enviar</button>
    </div> 
</form>   