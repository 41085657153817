import { Injectable } from '@angular/core';
import { Centro } from '@app/_models/centro';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { Redes } from '@app/_models/redes';

@Injectable({ providedIn: 'root' })
export class CentroService {

    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }

    getAll():Promise<Centro[]>{
        return this.http.get<Centro[]>(`${environment.apiUrl}/Centro/GetAll`).toPromise();
    }

    getXRegiones(RegionID: number):Promise<Centro[]>{
        return this.http.get<Centro[]>(`${environment.apiUrl}/Centro/${RegionID}/ObtenerXRegiones`).toPromise();
    }

    consultaCentrosXRegiones(ids : number[], idUsuario: number):Promise<Centro[]>{
        return this.http.post<Centro[]>(`${environment.apiUrl}/Centro?idUsuario=${idUsuario}`, ids).toPromise();
    }    

    consultaCentrosXSecciones(secciones : number[]):Promise<Centro[]>{
        return this.http.post<Centro[]>(`${environment.apiUrl}/Centro/CentrosXSecciones`, secciones).toPromise();
    }    

    consultaCentrosXSeccionesUsuario(secciones : number[], UsuarioID: number):Promise<Centro[]>{
        console.log("Usuario id " + UsuarioID)
        return this.http.post<Centro[]>(`${environment.apiUrl}/Centro/CentrosXSeccionesUsuario?UsuarioID=${UsuarioID}`, secciones).toPromise();
    }    
    
    getXSeccion(SeccionID: number):Promise<Centro[]>{
        return this.http.get<Centro[]>(`${environment.apiUrl}/Centro/${SeccionID}/ObtenerXSeccion`).toPromise();
    }

    getbyUser( idUsuario: number):Promise<Centro[]>{
        return this.http.get<Centro[]>(`${environment.apiUrl}/Centro/${idUsuario}/ObtenerXUsuario`).toPromise();
    }  

    getTotalCentros( idUsuario: number):Promise<number>{
        return this.http.get<number>(`${environment.apiUrl}/Centro/${idUsuario}/TotalCentros`).toPromise();
    }     
    
    obtenerAvisoPrivacidad( CentroID : string){

        return this.http.get<string>(`${environment.apiUrl}/Centro/${CentroID}/AvisoPrivacidad` ).toPromise();
      } 
      
      obtenerRedes( CentroID : string){

        return this.http.get<Redes>(`${environment.apiUrl}/Centro/${CentroID}/Redes` ).toPromise();
      }       

}