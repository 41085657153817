import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RecuperaCuentaService } from '@app/_services/recupera-cuenta.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common'; 
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-recupera-cuenta-empleado',
  templateUrl: './recupera-cuenta-empleado.component.html',
  styleUrls: ['./recupera-cuenta-empleado.component.scss']
})
export class RecuperaCuentaEmpleadoComponent implements OnInit {
  recuperarCuentaForm: FormGroup;

  enableBtn = true;
  correo = '';
  numeroEmpleado : number;

  dateError = false;

  loading: boolean = false;
  error: boolean = false;

  public SITE_KEY: string = '';
  TokenReCaptcha: string = '';

  minDate: Date;
  maxDate: Date;
  date = new FormControl(null);
  dateNac= new Date();

  constructor(private formBuilder: FormBuilder, private recuperaService:RecuperaCuentaService, public dialog: MatDialog, private router: Router, @Inject(DOCUMENT) document) {

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 10, 0, 1);
    this.date = new FormControl(null);

   }

  ngOnInit() {

    
    this.recuperarCuentaForm = this.formBuilder.group({ 
      email: [{ value : this.correo }, [ Validators.required, Validators.email ]],
      numeroEmpleado: [{ value : this.correo }, [ Validators.required ]]
    });   

  }

  onSubmit(){

    this.error = false;
    this.loading = true;

    if (this.recuperarCuentaForm.invalid) {
      return;
    } 

    this.recuperaService.recuperaCuentaEmpleado(this.correo, this.numeroEmpleado)
    .pipe(first())
    .subscribe(
    data => {
      this.loading = false;

      
      if (data.Success){        
        this.router.navigate(['/recupera-cuenta-espera', this.correo]);
      }else{        
        this.error = true;
        const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }  
    },
    error => {
      const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al recuperar la cuenta de la empresa.', false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      this.error = true;
      this.loading = false;
    });    
  }
  get formControls() { return this.recuperarCuentaForm.controls; }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  }
}
