import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SolicitudBecaComponent } from '@app/_components/solicitud-beca/solicitud-beca.component';
import { Solicitud } from '@app/_models/solicitud';




const ELEMENT_DATA: Solicitud[] = [];


@Component({
  selector: 'app-informacion-becas',
  templateUrl: './informacion-becas.component.html',
  styleUrls: ['./informacion-becas.component.scss']
})
export class InformacionBecasComponent implements OnInit {

  displayedColumns: string[] = ['matricula', 'nombre', 'ciclo', 'centro','seccion', 'grado', 'estatus', 'opciones'];
  dataSource = ELEMENT_DATA; 
  constructor(    private router: Router) { }

  ngOnInit(): void {
  }

  solicitarBeca(): void {
    this.router.navigate(['/solicitud-beca']);    
  }  

}
