import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Historial } from '@app/_models/historial';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Solicitud } from '@app/_models/solicitud';
import { SolicitudService } from '@app/_services/solicitud.service';

@Component({
  selector: 'app-historial-renovacion-beca',
  templateUrl: './historial-renovacion-beca.component.html',
  styleUrls: ['./historial-renovacion-beca.component.scss']
})
export class HistorialRenovacionBecaComponent implements OnInit {
  displayedColumns: string[] = ['Fecha', 'Descripcion', 'Usuario'];
  
  dataSource = new MatTableDataSource<Historial>();
  esperando = false;
  solicitud : Solicitud;

  constructor(public dialogClose: MatDialogRef<HistorialRenovacionBecaComponent>,
              private solicitudService:SolicitudService,
              @Inject(MAT_DIALOG_DATA) public data: ObjetoOp
  ) { }

  ngOnInit(): void {
    this.esperando = true;
    this.solicitud = this.data.Objeto;
    this.solicitudService.ObtenerHistorial(this.solicitud.SolicitudID).then(
      historial => {
        const orderHistorial  = historial.sort((a, b) => new Date(b.Fecha).getTime() - new Date(a.Fecha).getTime())
        this.esperando = false;
        this.dataSource = new MatTableDataSource<Historial>(orderHistorial);
      }
    ).catch( err => {
      console.log("Error " + err);
    });
  }

  onNoClick(): void {
    this.dialogClose.close();
  }
}
