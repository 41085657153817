import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cambia-contrasena-validar',
  templateUrl: './cambia-contrasena-validar.component.html',
  styleUrls: ['./cambia-contrasena-validar.component.scss']
})
export class CambiaContrasenaValidarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
