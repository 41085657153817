<mat-toolbar *ngIf="!isAnahuac" style="background-color: #2B3052;" class="main-navbar">
  <mat-toolbar-row [class]="clase">
   
    <div [fxShow]="!isProspect" [hidden]="!isAuthenticated || isProspect">
      <label class="portal">Portal de Becas - {{titlePortal}}</label>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="center">
      <ul fxLayout fxLayoutGap="20px" class="navigation-items" fxHide fxShow.xs fxShow.sm>
        <li>
          <button [fxShow]="!isProspect" [hidden]="!isAuthenticated" mat-icon-button  style="color: white;" aria-label="Menú" matTooltip="Mostrar el menú de navegación" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" [fxShow]="isProspect" class="navigation-items" [hidden]="!isAuthenticated">
        <div class="vl"></div>
        <div class="vl"></div>
        <li>
          <div>
            <img  src="/assets/SemperAltus/header_logo.png"/>
          </div>
        </li>
        <div class="vl"></div>
      </ul>

      <ul fxLayout fxHide.sm fxLayoutGap="20px" class="navigation-items"  [hidden]="isAuthenticated">
        <li>
          <div>
            <img style="width: 80%;" src="/assets/SemperAltus/header_logo.png"/>
          </div>
        </li>
      </ul>
    </div>
    <div [fxShow]="isProspect" [hidden]="isAdmin || isEmpresa || !isAuthenticated">
      <button mat-icon-button class="icon-exit" aria-label="Menú" matTooltip="Salir" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
    <div [fxShow]="!isProspect" fxHide.xs [hidden]="!isAuthenticated || isProspect">
      <img src="/assets/SemperAltus/RCSA_Logo_H.png" class="align-center">
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar *ngIf="isAnahuac" style="background-color: #FF5900;" class="main-navbar">
  <mat-toolbar-row [class]="clase">
   
    <div [fxShow]="isAdmin || isEmpresa" [hidden]="!isAuthenticated || isProspect">
      <label class="portal">Portal de Becas - {{titlePortal}}</label>
    </div>

    <div fxFlex fxLayout fxLayoutAlign="center">
      <ul fxLayout fxLayoutGap="20px" class="navigation-items" fxHide fxShow.xs fxShow.sm>
        <li>
          <button [fxShow]="!isProspect" [hidden]="!isAuthenticated" mat-icon-button  style="color: white;" aria-label="Menú" matTooltip="Mostrar el menú de navegación" (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
      </ul>

      <ul fxLayout fxHide.xs fxHide.sm fxLayoutGap="20px" [fxShow]="isProspect" class="navigation-items" [hidden]="!isAuthenticated">
        <div class="vl"></div>
        <div class="vl"></div>
        <li>
          <div>
            <img src="/assets/SemperAltus/Logo_Prepa Anahuac.png"/>
          </div>
        </li>
        <div class="vl"></div>
      </ul>

      <ul fxLayout fxHide.sm fxLayoutGap="20px" class="navigation-items"  [hidden]="isAuthenticated">
        <li>
          <div>
            <img style="width: 80%;" src="/assets/SemperAltus/Logo_Prepa Anahuac.png"/>
          </div>
        </li>
      </ul>
    </div>
    <div [fxShow]="isProspect" [hidden]="isAdmin || isEmpresa || !isAuthenticated" >
      <button mat-icon-button class="icon-exit" aria-label="Menú" matTooltip="Salir" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
