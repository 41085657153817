<div>
  <form class="example-form zoom" name="nuevaPropuesta" id="nuevaPropuesta" [formGroup]="nuevaPropuesta" autocomplete="off">
    <div *ngIf="!alumnosCutoasCargados" class="mat-aviso">
      Deber cargar el conteo de alumnos y cuotas para la propuesta
    </div> 
    <div fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="20">
        <mat-form-field class="inpunt-width xs-width" appearance="outline" style="margin-bottom: 4rem;">
          <mat-label>Sección</mat-label>
          <mat-select  name="seccion" formControlName="seccion" id="seccion" [disabled]='!alumnosCutoasCargados' (selectionChange)="onSelectSeccion($event)" >
              <mat-option  [value]="0"><label>Todos</label></mat-option>                                    
              <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
                {{seccion.Nombre}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="8">
        <div *ngIf="semaforo">
          <div *ngIf="verde" class="circulo bg-verde">
          </div>
          <div *ngIf="!verde" class="circulo bg-rojo">
          </div>
        </div>
      </div>

      <div fxLayout="column" fxFlex="20">
        <div *ngIf="semaforo">
          <div *ngIf="verde">
            <p style="font-size: 90%;">El costo anual de becas es menor al presupuesto autorizado</p>
          </div>
          <div *ngIf="!verde">
            <p style="font-size: 90%;">El costo anual de becas es mayor al monto autorizado.</p>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxFlex="20">
        <button mat-raised-button (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
      </div>

      <div fxLayout="column" fxFlex="20">
        <div *ngIf="!esRevision && !isAprobador">
          <button mat-raised-button (click)="save()" class="button xsbtn" [disabled]="!enableBtn || porcentajeMax || esperando">GUARDAR</button>  
        </div>
      </div>
    </div> 
    <br><br><br>
    <div class="row position-rows">
      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Monto presupuestal aprobado:</mat-label>
        <input readonly matInput formControlName="monto">
      </mat-form-field>
      
      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Costo de beca anual propuesto:</mat-label>
        <input matInput  formControlName="costoPropuesto" readonly>
      </mat-form-field>

      <!--mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Porcentaje de presupuesto utilizado:</mat-label>
        <input matInput  formControlName="porcentajePropuesto" readonly>
      </mat-form-field-->

      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Monto ejercido (Ene-Jun):</mat-label>
        <input matInput  formControlName="montoEjercido" readonly>
      </mat-form-field>

      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Propuesta (Ago-Dic):</mat-label>
        <input matInput  formControlName="montoPropuesta" readonly>
      </mat-form-field>

      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Monto propuesto de beca:</mat-label>
        <input matInput  formControlName="totalMonto" readonly>
      </mat-form-field>

      <mat-form-field class="inpunt-width xs-width" appearance="outline">
        <mat-label>Porcentaje de presupuesto utilizado:</mat-label>
        <input matInput  formControlName="porcentajePropuesto2" readonly>
      </mat-form-field>      
    </div>
    <br><br>
    <label class="colegioname">{{colegioName}}</label>
  </form>
</div>
<br><br>

<div>
  <!--Tabla fija, principal-->
  <div class="example-form mat-elevation-z8 table-responsive position-table">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" >

    <ng-container matColumnDef="num">
      <th mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> # </th>
      <td mat-cell *matCellDef="let element" class='column-id-prospecto buttonExp expand-element-icon' [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"> 
          <mat-icon>expand_more</mat-icon> 
          {{element.Solicitud.SolicitudID}} 
      </td>
    </ng-container>
  
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef class="column-opciones"> Nombre alumno </th>
      <td mat-cell *matCellDef="let element" class="buttonExp" [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"> 
        {{element.Solicitud.Alumno.ApellidoPaterno}} {{element.Solicitud.Alumno.ApellidoMaterno}} {{element.Solicitud.Alumno.Nombre}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="tipo">
      <th mat-header-cell *matHeaderCellDef class="sub-title "> Tipo <br> de beca </th>
      <td mat-cell *matCellDef="let element" class="tipo-beca-content"> 
        <mat-select  *ngIf="!element.EsExterna" [disabled]="!(propuestaSolicitudesEditable || propuetaSolicitudEditableRev) || element.EsExterna" [(ngModel)]="element.Solicitud.TipoBeca.TipoBecaID">
          <mat-option *ngFor="let c of tiposBeca" [value]="c.TipoBecaID">
            {{c.Clave}}-{{c.Nombre}}
          </mat-option>
        </mat-select>
        <div  *ngIf="element.EsExterna">
          {{element.Solicitud.TipoBeca.Clave}}-{{element.Solicitud.TipoBeca.Nombre}}
        </div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="becaact">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-beca-actual"> % Beca <br> actual </th>
      <td mat-cell *matCellDef="let element" class="position-beca-actual-contenido">
        <p *ngIf="element.Solicitud.EsRenovacion">
          {{element.Solicitud.PorcentajeBecaActual}}
        </p>
        <p *ngIf="!element.Solicitud.EsRenovacion">
          N/A
        </p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="becasug">
      <th mat-header-cell *matHeaderCellDef class='column-opciones position-beca-sugerida'> % Beca <br> sugerida </th>
      <td mat-cell *matCellDef="let element" class="position-beca-sugerida-contenido">
        {{element.BecaSugerida}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="becaprop">
      <th mat-header-cell *matHeaderCellDef class='column-opciones position-beca-propuesta'> % Beca <br> propuesta </th>
      <td mat-cell *matCellDef="let element" class='position-beca-propuesta-contenido'>
        <input  matInput type="number" min="0" max="100" 
           [(ngModel)] ="element.BecaPropuesta" 
          (input)="calculaValores(element)" [disabled]="!propuestaSolicitudesEditable ||  element.EsExterna || esRevision"
          onkeypress="if(this.value.length==3) return false;"
          >
        <mat-hint *ngIf="element.BecaPropuesta > 100">Max 100%</mat-hint> 
      </td>
    </ng-container>
  
    <ng-container matColumnDef="becaori">
      <th mat-header-cell *matHeaderCellDef class='column-opciones position-beca-original'> % Beca <br> original </th>
      <td mat-cell *matCellDef="let element" class="position-beca-original-contenido">
        <p *ngIf="element.BecaOriginal!= -1" >
          {{element.BecaOriginal}}
        </p>
        <p *ngIf="element.BecaOriginal== -1">
          N/A
        </p>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="BecaSep">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-beca-sep"> Reporte <br> beca sep </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox  
          [(ngModel)]="element.BecaSep"
          [disabled]="!propuestaSolicitudesEditable ||  element.EsExterna || esRevision ||  !(element.BecaAprobada >0)"
          (change)='calculaValores(element)'
          class='position-beca-sep-contenido'>
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="AplicaReinscripcion">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-reinscripcion"> Aplica <br> beca <br> reinscripción </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox  
          [(ngModel)]="element.AplicaReinscripcion"
          [disabled]="!propuestaSolicitudesEditable  || element.EsExterna || esRevision ||  !(element.BecaAprobada >0)"  
          (change)='calculaValores(element)' 
          class="position-reinscripcion-contenido">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="AplicaInscripcion">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-inscripcion"> Aplica <br> beca <br> inscripción </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox  
          [(ngModel)]="element.AplicaInscripcion" 
          [disabled]="!propuestaSolicitudesEditable  || element.EsExterna || esRevision ||  !(element.BecaAprobada >0) " 
          (change)='calculaValores(element)' 
          class="position-inscripcion-contnido">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="condicionada">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-condicionada"> Condicionada </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox  
          [(ngModel)]="element.Condicionada" 
          [disabled]="!propuestaSolicitudesEditable  || element.EsExterna || esRevision  ||  !(element.BecaAprobada >0)" 
          class="position-condicionada-contenido" 
          >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="costoanual">
      <th mat-header-cell *matHeaderCellDef class='column-opciones position-costo-anual'> Costo <br> anual beca </th>
      <td mat-cell *matCellDef="let element" class='position-costo-anual-contenido'>
        <input matInput type="text" disabled [ngModel] ="element.CostoAnualBecaPropuesto | currency:'MXN'">
      </td>
    </ng-container>

    <ng-container matColumnDef="comentarios">
      <th mat-header-cell *matHeaderCellDef class="column-opciones position-comentarios"> Comentarios </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="basic" aria-label="Editar"  matTooltip="Editar" 
          (click)="verComentarios(element)" matTooltipPosition="right" class="contenido-comentarios"><mat-icon>description</mat-icon>
        </button>
      </td>
    </ng-container>

    <!--Expandible-->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsFija.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <!-- tabla Expandible-->
          <table [dataSource]="element.detalleTable" mat-table class="mat-elevation-z8">

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="sub-title curp-alumno"> CURP Alumno </th>
              <td mat-cell *matCellDef="let element" class="font-family-expandible"> {{element.Solicitud.Alumno.CURP}} </td>
            </ng-container>
          
            <ng-container matColumnDef="seccion">
              <th mat-header-cell *matHeaderCellDef class="column-opciones seccion-alumno"> Sección </th>
              <td mat-cell *matCellDef="let element" class="font-family-expandible"> {{element.Solicitud.Seccion.Nombre}} </td>
            </ng-container>
          
            <ng-container matColumnDef="grado">
              <th mat-header-cell *matHeaderCellDef class="column-opciones grado-alumno"> Grado </th>
               <td mat-cell *matCellDef="let element" class="grado-alumno-contenido font-family-expandible"> 
                {{element.Solicitud.GradoEducativo.Nombre}}  
              </td>
            </ng-container>
          
            <ng-container matColumnDef="nr">
              <th mat-header-cell *matHeaderCellDef class="sub-title renovacion-alumno"> Renovación </th>
              <td mat-cell *matCellDef="let element" class='renovacion-alumno-contenido font-family-expandible'> 
                <div *ngIf="!element.Solicitud.EsRenovacion">
                  No
                </div>
                <div *ngIf="element.Solicitud.EsRenovacion">
                  Si
                </div>  
              </td>
            </ng-container>
          
            <ng-container matColumnDef="moroso">
              <th mat-header-cell *matHeaderCellDef class="moroso-alumno"> Es moroso </th>
              <td mat-cell *matCellDef="let element" class="moroso-alumno-contenido font-family-expandible"> 
                <div *ngIf="!element.EsMoroso">
                  No
                </div>
                <div *ngIf="element.EsMoroso">
                  Si
                </div>  
              </td>
            </ng-container>
          
            <ng-container matColumnDef="costocol">
              <th mat-header-cell *matHeaderCellDef class="column-opciones costo-cloegiatura-alumno"> Costo <br> colegiatura </th>
              <td mat-cell *matCellDef="let element" class="costo-cloegiatura-alumno-contenido font-family-expandible"> 
                <input matInput type="text" disabled [ngModel] ="operacionCosto(element.CostoColegiatura, element.PorcentajeIncCol) | currency:'MXN'"  >
              </td>
            </ng-container>
          
            <ng-container matColumnDef="costoreins">
              <th mat-header-cell *matHeaderCellDef class="column-opciones"> Costo <br> reinscripción </th>
              <td mat-cell *matCellDef="let element" class="font-family-expandible"> 
                <input matInput type="text" disabled [ngModel] ="operacionCosto(element.CostoReinscripcion, element.PorcentajeIncReinscripcion)| currency:'MXN'"  >
              </td>
            </ng-container>
          
            <ng-container matColumnDef="promdis">
              <th mat-header-cell *matHeaderCellDef class="column-opciones promedio-disiplina-alumno"> Promedio <br> disciplina </th>
              <td mat-cell *matCellDef="let element" class="promedio-disiplina-alumno-contenido font-family-expandible"> 
                {{element.Solicitud.PromedioConducta}} 
              </td>
            </ng-container>

            <ng-container matColumnDef="promaca">
              <th mat-header-cell *matHeaderCellDef class="column-opciones promedio-academico-alumno"> Promedio <br> académico </th>
              <td mat-cell *matCellDef="let element" class="promedio-academico-alumno-contenido font-family-expandible"> 
                {{element.Solicitud.PromedioActual}}
              </td>
            </ng-container>
          
            <ng-container matColumnDef="aptitud">
              <th mat-header-cell *matHeaderCellDef class="column-opciones prueba-aptitud-alumno"> Prueba <br> aptitud </th>
              <td mat-cell *matCellDef="let element" class="prueba-aptitud-alumno-contenido font-family-expandible"> 
                {{element.CalificacionAptitud}}  
              </td>
            </ng-container>
          
            <ng-container matColumnDef="ensayo">
              <th mat-header-cell *matHeaderCellDef class="column-opciones ensayo-alumno"> Ensayo </th>
              <td mat-cell *matCellDef="let element" class="ensayo-alumno-contenido font-family-expandible"> 
                <p *ngIf="element.CalificacionEnsayo != 0">
                  {{element.CalificacionEnsayo}}  
                </p>
                <p style="justify-content: center; text-align: center;" *ngIf="element.CalificacionEnsayo == 0">
                  N/A
                </p>       
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDespleglable"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsDespleglable;"></tr>

          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsFija"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumnsFija;"
        class="example-element-row"
        >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>


<br>
<div class="loading-screen-icon" *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>
<br><br><br><br>