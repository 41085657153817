import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OperationResult } from '@app/_models/operationResult';
import { environment } from '@environments/environment';
import { SolicitudAdmisionFilters } from '@app/_models/SolicitudAdmisionFilters';
import { SolicitudAdmisionResponse } from '@app/_models/solicitudAdmisionResponse';

@Injectable({
  providedIn: 'root'
})
export class SolicitudAdmisionService {

  constructor(private http: HttpClient) {}

  get(nRegistros:number, nPagina:number, filters:SolicitudAdmisionFilters) {

    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );
    params = params.append('idUsuario', filters.idUsuario.toString() );

    params = params.append('institucion', filters.institucion.toString() );
    params = params.append('campus', filters.campus.toString() );
    params = params.append('grado', filters.grado.toString() );
    params = params.append('programa', filters.programa.toString() );
    params = params.append('ciclo', filters.ciclo.toString() );
    params = params.append('nombre', filters.nombre.toString() );

    return this.http.get<OperationResult<SolicitudAdmisionResponse>>(`${environment.apiUrl}/SolicitudAdmision`, { params });
  }
  
}
