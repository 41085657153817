import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Rol } from '@app/_models/rol';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor(private http: HttpClient) { }

  get(){
    return this.http.get<Rol[]>(`${environment.apiUrl}/rol`);
  }

  getRoles(){
    return this.http.get<Rol[]>(`${environment.apiUrl}/rol`).toPromise();
  }  
}
