import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { TipoBeca } from '@app/_models/tipoBeca';
import { Solicitud } from '@app/_models/solicitud';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Alumno } from '@app/_models/alumno';
import { SolicitudService } from '@app/_services/solicitud.service';
import { SeleccionBecaService } from '@app/_services/seleccionBeca.service';
import { Ciclo } from '@app/_models/ciclo';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { CicloService } from '@app/_services/ciclo.service';
import { AlumnoBecaActual } from '@app/_models/alumnoBecaActual';
import { TipoBecaReq } from '@app/_models/tipoBecaReq';
import { EspecificacionBecaService } from '@app/_services/especificacionBeca.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-seleccion-beca',
  templateUrl: './seleccion-beca.component.html',
  styleUrls: ['./seleccion-beca.component.scss']
})
export class SeleccionBecaComponent implements OnInit {
  seleccionBeca: FormGroup;
  AlumnoID : number;
  ciclos : Ciclo[] = [];
  tiposBeca: TipoBeca[] = [];
  porcentajeInsErr  = false;

  promedioAcademicoAntErr  = false;
  promedioAcademicoActErr  = false;
  promedioConductaErr  = false;
  Solicitud : Solicitud;
  idUsuario : number;
  cicloAnterior : Ciclo;
  cicloActual : Ciclo;
  cicloSiguiente : Ciclo;
  AlumnoPromedio : AlumnoPromedio;
  AlumnoPromedioActual : AlumnoPromedio;
  AlumnoBecaActual  : AlumnoBecaActual;
  isAnahuac: boolean = false;
  mostrarPromedioAcademico : boolean;
  mostrarPromedioDisciplina : boolean;
  requisitoPromAcademico : TipoBecaReq;
  requsitoPromDisciplina : TipoBecaReq;
  inicio = false;
  fechaSolicitudFin: Date;
  tieneExtemporaneaActual : boolean;
  tieneExtemporaneaSiguiente : boolean;
  enFechasSolicitudes : boolean;
  fechaExtemporaneaActualFin : Date;
  fechaExtemporaneaSiguFin : Date;
  fechaExtemporanea = null;
  existeBeca: boolean = false;
  guardando: boolean = false;

  constructor(private formBuilder: FormBuilder,    
    private tipoBecaService : TipoBecaService,
    private authenticationService: AuthenticationService, 
    private solicitudService : SolicitudService,
    private seleccionBecaService : SeleccionBecaService,
    private cicloService : CicloService,
    private especificaconService : EspecificacionBecaService,
    public datepipe: DatePipe,
    private router: Router,
    public dialog: MatDialog) { 
  }

  ngOnInit(): void {
   
    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.Solicitud = this.solicitudService.currentSolicitudValue;

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });


    this.seleccionBeca = this.formBuilder.group({
      ciclo: ['', Validators.required],
      tipobeca: ['', Validators.required],
      renovacion: ['', Validators.required],
      motivo: ['', Validators.required],
      promedioAnt: [''],
      promedioAct: [''],
      promedioCond: [''],
    });

    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      }
    });

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.cicloActual = ciclo;
      this.recuperaPromedioActual();
      this.recuperaBecaActual();
      }).catch(err => {
        console.log("Error" + err);
      });  
    
    this.cicloService.consultaCicloSiguiente(this.AlumnoID).then(ciclo => {
      this.cicloSiguiente = ciclo;
      this.solicitudService.procesadasXCiclo( this.AlumnoID, this.cicloSiguiente.CicloID).then( e => {
        if(e != null && e.Exito && !e.Dato){
          this.habilitarParaCicloSiguiente();
        }
      }).catch(err => {
        console.log("Error" + err);
      }); 

      }).catch(err => {
      console.log("Error" + err);
    });        

    this.cicloService.consultaCicloAnterior(this.AlumnoID).then(ciclo => {
        this.cicloAnterior = ciclo;
        this.recuperaPromedio();
        }).catch(err => {
        console.log("Error" + err);
    });      

    if(this.Solicitud ==  null){
      this.Solicitud = new Solicitud();
      this.Solicitud.SolicitudID =0;  
    }else{
      this.seleccionBecaService.obtenerSeleccionBeca(this.Solicitud.SolicitudID).then(Solicitud =>{

      this.seleccionBeca.get('tipobeca').setValue(Solicitud.TipoBeca.TipoBecaID);

      console.log("Renovacion " + Solicitud.EsRenovacion);
      if(Solicitud.EsRenovacion){
        this.seleccionBeca.get('renovacion').setValue("1");
      }else{
        this.seleccionBeca.get('renovacion').setValue("0");
      }

      this.seleccionBeca.get('motivo').setValue(Solicitud.MotivoSolicitud);
      this.seleccionBeca.get('promedioAnt').setValue(Solicitud.PromedioAnterior);
      this.seleccionBeca.get('promedioAct').setValue(Solicitud.PromedioActual);
      this.seleccionBeca.get('promedioCond').setValue(Solicitud.PromedioConducta);

      this.buscarRequisitos();

      }).catch(err => {
        console.log("Error" + err);
      });  
    }
  }

  guardar(){
    this.inicio = true;
    this.save('/pantalla-principal');
  }

  guardarContinuar(){
    this.inicio = false;
    this.save('/datos-generales')
  }

  onSelectedBeca() {
    this.buscarRequisitos();
      console.log("Cargando...");   
  }  

  onSelectedCiclo() {
    var CicloID = this.seleccionBeca.get('ciclo').value;
    if(CicloID && CicloID != ''){
      this.tipoBecaService.consultaTiposBecaAlumno(this.AlumnoID, CicloID).then(tiposBeca => {
        this.tiposBeca = tiposBeca;
        if (tiposBeca.length == 0 ) this.existeBeca = true;
        if(this.Solicitud.TipoBeca != null && this.Solicitud.TipoBeca != null ){
            this.seleccionBeca.get('tipobeca').setValue(this.Solicitud.TipoBeca.TipoBecaID);
        }
        }).catch(err => {
        console.log("Error" + err);
      });    
    }
  }  
  
  save(ruta : string){
   
    if(!this.seleccionBeca.valid){
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error',this.Solicitud.SolicitudID);
      return;
    }

    this.Solicitud.PromedioAnterior = this.seleccionBeca.get('promedioAnt').value;
    this.Solicitud.PromedioActual = this.seleccionBeca.get('promedioAct').value;
    this.Solicitud.PromedioConducta = this.seleccionBeca.get('promedioCond').value;


    if(this.mostrarPromedioAcademico){
      if(this.Solicitud.PromedioActual < this.requisitoPromAcademico.Minimo || 
        this.Solicitud.PromedioActual > this.requisitoPromAcademico.Maximo){
          this.showMessage('El promedio actual no cumple los requisitos para la beca *', 'Error',this.Solicitud.SolicitudID);
          return;
      }

    }

    if(this.mostrarPromedioDisciplina){
      if(this.Solicitud.PromedioConducta < this.requsitoPromDisciplina.Minimo || 
        this.Solicitud.PromedioConducta > this.requsitoPromDisciplina.Maximo){
          this.showMessage('El promedio de conducta ingresado no cumple los requisitos para la beca *', 'Error',this.Solicitud.SolicitudID);
          return;
      }

    }
    this.mostrarPromedioAcademico = !(this.requisitoPromAcademico == null || !this.requisitoPromAcademico.Aplicable);
    this.mostrarPromedioDisciplina =!(this.requsitoPromDisciplina == null || !this.requsitoPromDisciplina.Aplicable);


    this.Solicitud.TipoBeca = new TipoBeca();
    this.Solicitud.TipoBeca.TipoBecaID = this.seleccionBeca.get('tipobeca').value;
    this.Solicitud.MotivoSolicitud =  this.seleccionBeca.get('motivo').value;
    this.Solicitud.Ciclo = new Ciclo();
    this.Solicitud.Ciclo.CicloID = this.seleccionBeca.get('ciclo').value;

    var renovacion =  this.seleccionBeca.get('renovacion').value;
    console.log("Renovacion " + renovacion);
    if(renovacion=="0"){
      this.Solicitud.EsRenovacion = false;
    }else{
      this.Solicitud.EsRenovacion = true;
    }

    this.Solicitud.PromedioAnterior = this.seleccionBeca.get('promedioAnt').value;
    this.Solicitud.PromedioActual = this.seleccionBeca.get('promedioAct').value;
    this.Solicitud.PromedioConducta = this.seleccionBeca.get('promedioCond').value;

    this.Solicitud.Alumno = new Alumno();
    this.Solicitud.Alumno.AlumnoID = this.AlumnoID;
    this.Solicitud.UsuarioID = this.idUsuario;

    if(this.AlumnoBecaActual != null){
      this.Solicitud.PorcentajeBecaActual = this.AlumnoBecaActual.Porcentaje;
    }

    this.getFechaFin();

    this.guardando = true;
    this.solicitudService.guardarSeleccionBeca(this.Solicitud).then( res =>{

      if (res.Exito) {
        this.guardando = false;
        var solicitudID= res.Dato;
        this.Solicitud.SolicitudID = Number(solicitudID);
        this.Solicitud.FechaFin = this.fechaExtemporanea ? this.fechaExtemporanea : this.fechaSolicitudFin;
        this.solicitudService.almacenarSolicitud(this.Solicitud);

        if(this.inicio){
          let latest_date = this.datepipe.transform(this.Solicitud.FechaFin, 'dd/MM/yyyy');
          this.showMessage('Información', "Recuerda que debes completar la solicitud antes del día " + latest_date + " o tu solicitud será eliminada.", this.Solicitud.SolicitudID);
          this.solicitudService.enviarcorreoRecordatorio(this.Solicitud.SolicitudID).then( res =>{
          }).catch(err => {
            console.log("Error" + err);
          }).finally(() => {
          }); 

          this.router.navigate(['/pantalla-principal']);
        }else{
          const dialogData = new ConfirmDialogModel('', "Información guardada", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });        
  
          dialogRef.afterClosed().subscribe( () => {
            this.router.navigate([ruta]);
          });
        }
      }else{
        //this.error = true;  
        this.guardando = false;
        const dialogData = new ConfirmDialogModel('Error', res.Mensaje, false);    
      }
    }
    ).catch(err => {
      this.guardando = false;
      console.log("Error" +err);
      const message = `Ocurrio un error al conectar con el servidor`;

      const dialogData = new ConfirmDialogModel("Error", message, false);

      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
      dialogRef.afterClosed().subscribe(() => { });
    })
  }

  getFechaFin(){
    if(this.enFechasSolicitudes){
      console.log("Fecha ciclo siguiente " + this.cicloSiguiente.FechaSolicitudFin);
      this.fechaSolicitudFin = this.cicloSiguiente.FechaSolicitudFin;
    }else{
      if(this.tieneExtemporaneaActual){
        console.log("Fecha extemporanea actual " + this.fechaExtemporaneaActualFin);
        this.fechaSolicitudFin = this.fechaExtemporaneaActualFin;
      }else{
        console.log("Fecha extemporane siguiente " + this.fechaExtemporaneaSiguFin);
        this.fechaSolicitudFin = this.fechaExtemporaneaSiguFin;
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  } 

  validarPromAcademicoAnt() {
    this.promedioAcademicoAntErr = false;
    
    var promedioAcademicoAnt = this.seleccionBeca.get('promedioAnt').value;  
    var promedioAcademicoAntNumber =  Number(promedioAcademicoAnt);  

    if( promedioAcademicoAntNumber > 10 || promedioAcademicoAntNumber < 0 || promedioAcademicoAntNumber == 0){
      this.promedioAcademicoAntErr= true;
    }
  }

  validarPromAcademicoAct(){
    this.promedioAcademicoActErr = false;
    
    var promedioAcademicoAct = this.seleccionBeca.get('promedioAct').value;
    var promedioAcademicoAntNumber =  Number(promedioAcademicoAct); 

    if(promedioAcademicoAct == 0 ||  promedioAcademicoAntNumber > 10 || promedioAcademicoAntNumber < 0){     
      this.promedioAcademicoActErr= true;
    }
  }
  
  validarPromAcademicoCond(){
    this.promedioConductaErr = false;
    
    var promedioConducta = this.seleccionBeca.get('promedioCond').value;   
    var promedioConductaNumber =  Number(promedioConducta);

    if(promedioConducta == 0 || promedioConductaNumber > 10 || promedioConductaNumber < 0){  
      this.promedioConductaErr= true;
    }
  }

  recuperaPromedio(){
    this.seleccionBecaService.recuperaPromedio(this.cicloAnterior.CicloID, this.AlumnoID).then( e => {
      if(e != null){
        this.AlumnoPromedio = e;
        if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
          this.seleccionBeca.get('promedioAnt').setValue(this.AlumnoPromedio.PromedioAcademico);
        }

      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  recuperaPromedioActual(){
    this.seleccionBecaService.recuperaPromedio(this.cicloActual.CicloID, this.AlumnoID).then( e => {
      if(e != null){
        this.AlumnoPromedioActual = e;
        if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
          this.seleccionBeca.get('promedioAct').setValue(this.AlumnoPromedioActual.PromedioAcademico);
          this.seleccionBeca.get('promedioCond').setValue(this.AlumnoPromedioActual.PromedioDisciplina);
        }
      }

      this.solicitudService.procesadasXCiclo( this.AlumnoID, this.cicloActual.CicloID).then( e => {
        if(e != null && e.Exito && !e.Dato){
          this.habilitarParaCicloActual();
        }
      }).catch(err => {
        console.log("Error" + err);
      });      


    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  buscarRequisitos(){
    var requisitos :  TipoBecaReq[] = [];
    var tipoBeca = this.seleccionBeca.get('tipobeca').value;
    if(tipoBeca != null && tipoBeca != ''){
      if(this.AlumnoPromedioActual != null && this.AlumnoPromedioActual.Seccion != null
          && this.AlumnoPromedioActual.Centro != null && this.AlumnoPromedioActual.GradoEducativo != null){
        this.especificaconService.consultaRequsitoBeca(tipoBeca, 
          this.AlumnoPromedioActual.Centro.CentroID, 
          this.AlumnoPromedioActual.Seccion.SeccionID,
          this.AlumnoPromedioActual.GradoEducativo.GradoEducativoID
          ).then(
            e => {
              requisitos = e;
              this.requisitoPromAcademico =requisitos.find(x =>  x.RequisitoBeca.Clave == "PROMEDIO_ACADEMICO");
              this.requsitoPromDisciplina =requisitos.find(x =>  x.RequisitoBeca.Clave == "PROMEDIO_CONDUCTA");
              this.mostrarPromedioAcademico = !(this.requisitoPromAcademico == null || !this.requisitoPromAcademico.Aplicable);
              this.mostrarPromedioDisciplina =!(this.requsitoPromDisciplina == null || !this.requsitoPromDisciplina.Aplicable);

              if(this.mostrarPromedioAcademico){
                console.log("aca min" + this.requisitoPromAcademico.Minimo);
                console.log("aca max" + this.requisitoPromAcademico.Maximo);
              }

              if(this.mostrarPromedioDisciplina){
                console.log("dis min" + this.requsitoPromDisciplina.Minimo);
                console.log("dis max" + this.requsitoPromDisciplina.Maximo);
              }
            }
          ).catch(err => {
            console.log("Error" + err);
          });  
      }else{
        this.especificaconService.consultaRequsitosPorBeca(tipoBeca).then(
          e => {
            requisitos = e;
            this.requisitoPromAcademico =requisitos.find(x =>  x.RequisitoBeca.Clave == "PROMEDIO_ACADEMICO");
            this.requsitoPromDisciplina =requisitos.find(x =>  x.RequisitoBeca.Clave == "PROMEDIO_CONDUCTA");
            this.mostrarPromedioAcademico = !(this.requisitoPromAcademico == null || !this.requisitoPromAcademico.Aplicable);
            this.mostrarPromedioDisciplina =!(this.requsitoPromDisciplina == null || !this.requsitoPromDisciplina.Aplicable);
          }
        ).catch(err => {
          console.log("Error" + err);
        });  
      }
    }
  }

  recuperaBecaActual(){
    this.seleccionBecaService.recuperaBecaActual(this.cicloActual.CicloID, this.AlumnoID).then( e => {
      if(e != null){
        this.AlumnoBecaActual = e;
        if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
            this.seleccionBeca.get('renovacion').setValue("1");
        }
        
      }else{
        if(this.Solicitud == null || this.Solicitud.SolicitudID == 0){
          this.seleccionBeca.get('renovacion').setValue("0");
        }
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  habilitarParaCicloActual(){
    var now = new Date();
    console.log("habilitarParaCicloActual  " + this.cicloActual.CicloID);

    var inicio2 = new Date(this.cicloActual.FechaSolicitudIni2);
    var fin2 = new Date(this.cicloActual.FechaSolicitudFin2);
    if ( inicio2 <= now  && now <= fin2 && 
          (this.AlumnoPromedioActual.Seccion.SeccionID == 4 ||   this.AlumnoPromedioActual.Seccion.SeccionID == 7) ) {
      this.enFechasSolicitudes =  true;
      this.ciclos.push(this.cicloActual);
      if(this.Solicitud.Ciclo.CicloID == this.cicloActual.CicloID){
        this.seleccionBeca.get('ciclo').setValue(this.cicloActual.CicloID);
        this.onSelectedCiclo();
      }
    }else{
      this.solicitudService.becaExtemporanea( this.AlumnoID, this.cicloActual.CicloID).then( e => {
        if(e != null){
          var inicio = new Date(e.FechaInicio);
          var fin = new Date(e.FechaFin);
          if (inicio <= now && now <=fin) {
            this.fechaExtemporaneaSiguFin = fin;
            this.tieneExtemporaneaActual =  true;
            this.ciclos.push(this.cicloActual);
            if(this.Solicitud.Ciclo.CicloID == this.cicloActual.CicloID){
              this.seleccionBeca.get('ciclo').setValue(this.cicloSiguiente.CicloID);
              this.onSelectedCiclo();
            } 
          }
        }
      }).catch(err => {
          console.log("Error" + err);
      });
    }

  
  }    

  habilitarParaCicloSiguiente(){
    console.log("habilitarParaCicloSiguiente " + this.cicloSiguiente.CicloID);
    if(this.cicloSiguiente != null){
      var inicio = new Date(this.cicloSiguiente.FechaSolicitudIni);
      var fin = new Date(this.cicloSiguiente.FechaSolicitudFin);
      var now = new Date();
      if ( inicio <= now  && now <= fin) {
        this.enFechasSolicitudes =  true;
        this.ciclos.push(this.cicloSiguiente);
        if(this.Solicitud.Ciclo.CicloID == this.cicloSiguiente.CicloID){
          this.seleccionBeca.get('ciclo').setValue(this.cicloSiguiente.CicloID);
          this.onSelectedCiclo();
        }
      }else{
        this.solicitudService.becaExtemporanea( this.AlumnoID, this.cicloSiguiente.CicloID).then( e => {
          if(e != null){
            var inicio = new Date(e.FechaInicio);
            var fin = new Date(e.FechaFin);
            if (inicio <= now && now <=fin) {
              this.fechaExtemporaneaSiguFin = fin;
              this.tieneExtemporaneaSiguiente =  true;
              this.ciclos.push(this.cicloSiguiente);
              if(this.Solicitud.Ciclo.CicloID == this.cicloSiguiente.CicloID){
                this.seleccionBeca.get('ciclo').setValue(this.cicloSiguiente.CicloID);
                this.onSelectedCiclo();
              }                
            }
          }
        }).catch(err => {
          console.log("Error" + err);
        }); 

 
      }
    }
  }  

  private showMessage(message: string, titulo: string, SolicitudID : number): MatDialogRef<AlertaComponent, any> {
    //const message = `¿Ocurrio un error al conectar con el servidor?`;

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });


    return dialogRef;
  }  

}