<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div class="circulo bg-celeste">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-rosado">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-verde">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-azul">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-naranja">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
        </table>
    </div>
    <br><br><br>
    <mat-card-title class="card-title fz-40">¡Has concluido el proceso de solicitud de beca!</mat-card-title>
    <br>
    <br>
    <br>
    <p class="text-center color-marino fz-17">Tu solicitud será revisada por el comité de becas. <br> Podrás revisar los resultados a partir del 30 de abril de 2021.</p>
    <br>
    <br>
    <div class="text-center">
        <button class="button" [routerLink]="['/pantalla-principal']" routerLinkActive="router-link-active"  mat-raised-button>ACEPTAR</button>
    </div>
</mat-card>