import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatTableDataSource} from '@angular/material/table';
import { first } from 'rxjs/operators';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { EspecificacionBecaService } from '@app/_services/especificacionBeca.service';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { TipoBecaEspecificaciones } from '@app/_models/TipoBecaEspecificaciones';
import { TipoBecaSeccion } from '@app/_models/tipoBecaSeccion';
import { TipobecaSecGradoComponent } from '@app/_components/tipobeca-sec-grado/tipobeca-sec-grado.component';
import { TipobecaSecCentroComponent } from '@app/_components/tipobeca-sec-centro/tipobeca-sec-centro.component';

@Component({
  selector: 'app-especificacion-beca',
  templateUrl: './especificacion-beca.component.html',
  styleUrls: ['./especificacion-beca.component.scss']
})
export class EspecificacionBecaComponent implements OnInit {

  displayedColumnsCiclos: string[] = ['Nombre', 'xGrado', 'xCentro'];
  dataSourceEspecificaciones = new MatTableDataSource<TipoBecaEspecificaciones>();
  detalleBecaForm: FormGroup;
  becas: TipoBeca[] = []; 
  maxInvalid = false
  enableBtn = false
  cargandoRequisitos = null;
  tipoBecaSecciones : TipoBecaSeccion[] = [];
  tipoBecaEspecificaciones : TipoBecaEspecificaciones[] = [];
  length:number = 0
  idUsuario: number;
  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  cargandoBecas = null;
  renglonSeleccionado : TipoBecaEspecificaciones;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private becaservice: TipoBecaService,
    private especificacionRequisitoService : EspecificacionBecaService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    //this.iniciarTiposBeca();
    this.detalleBecaForm = this.formBuilder.group({
      beca: ['', Validators.required],
    }); 
  }
  
  onSubmit(){
    this.enableBtn = false;
    this.especificacionRequisitoService.GuardarEspecificacionBeca(this.tipoBecaEspecificaciones, this.idUsuario ).pipe(first()).subscribe(
      data => {
        if (data.Exito){
          const dialogData = new ConfirmDialogModel('', "Especificaciones guardadas", false);
          const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });    
        }else{
          const dialogData = new ConfirmDialogModel('Error', "Hubo un error al guardar las especificaciones", false);
          const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          });    
        }    
        this.enableBtn  = true;   
      },
      () => {}
    );
  }

  onNoClick(): void {
  }

  iniciarTiposBeca() {
      this.limpiarTabla();
      this.becaservice.consultaTiposBeca().then(becas => {
        this.becas = becas;
        this.cargandoBecas = null;
      }).catch(err => {
        this.cargandoBecas = "(Error)";
        console.log("Error" + err);
      });
  }  

  onSelectTipoBeca() {
    var beca = this.detalleBecaForm.get('beca').value;
    if(beca != null && beca != ''){
      this.consultarEspecificacionesTipoBeca(beca);
    }
  }  
  

  especificacionXGrado(renglon : TipoBecaEspecificaciones){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.renglonSeleccionado = renglon;
    if(mCopy.BecaSeccionGrados == null){
      mCopy.BecaSeccionGrados = [];
    }
   
    const dialogRef = this.dialog.open(TipobecaSecGradoComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarEspecificacionGrados(renglon);
      }else{
        var requisitoBeca = this.tipoBecaEspecificaciones.find(r => r.TipoBecaSeccion.TipoBecaSeccionID == renglon.TipoBecaSeccion.TipoBecaSeccionID);
        requisitoBeca.BecaSeccionGrados = mCopy.BecaSeccionGrados;
      }
    });
  }

  eliminarEspecificacionXGrados(renglon : TipoBecaEspecificaciones){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por sección?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.BecaSeccionGrados  = [];
      }
    });    
  }

  especificacionXCentro(renglon : TipoBecaEspecificaciones){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.renglonSeleccionado = renglon;  
    if(mCopy.BecaSeccionCentros == null){
      mCopy.BecaSeccionCentros = [];
    }  
      const dialogRef = this.dialog.open(TipobecaSecCentroComponent, {
        width: '60%',
        data: {Operation: 'Editar', Objeto: renglon}
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarEspecificacionCentros(renglon);
      }else{
        var requisitoBeca = this.tipoBecaEspecificaciones.find(r => r.TipoBecaSeccion.TipoBecaSeccionID == renglon.TipoBecaSeccion.TipoBecaSeccionID);
        requisitoBeca.BecaSeccionCentros = mCopy.BecaSeccionCentros;

      }

    });
  }

  eliminarEspecificacionXCentro(renglon : TipoBecaEspecificaciones){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por centro?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.BecaSeccionCentros  = [];
      }
    });    
  }

  llenarTipoBecaSeccionesEspecificaciones(){
    this.tipoBecaEspecificaciones = [];
    this.tipoBecaSecciones.forEach(
      x => {
        var tipoBecaSeccion: TipoBecaEspecificaciones = new TipoBecaEspecificaciones();
        tipoBecaSeccion.TipoBecaSeccion = x;
        tipoBecaSeccion.BecaSeccionCentros = [];
        tipoBecaSeccion.BecaSeccionGrados =[];
        this.tipoBecaEspecificaciones.push(tipoBecaSeccion);
      }
    );
  }
 
  consultarEspecificacionesTipoBeca(TipoBecaID : number){
    this.especificacionRequisitoService.consultaEspecificacionBeca(TipoBecaID, this.idUsuario).then(especificaciones => {
      this.tipoBecaEspecificaciones = especificaciones;
   
      this.dataSourceEspecificaciones = new MatTableDataSource<TipoBecaEspecificaciones>(this.tipoBecaEspecificaciones);
      this.length = this.dataSourceEspecificaciones.data.length;
      this.cargandoRequisitos = null;
    }).catch(err => {
      //this.hayErrores = true;
      this.cargandoRequisitos = "(Error)";
      console.log("Error" + err);
    });
  }

  limpiarTabla(){
    this.detalleBecaForm.get('beca').setValue('');
    this.tipoBecaEspecificaciones = [];
    this.dataSourceEspecificaciones = new MatTableDataSource<TipoBecaEspecificaciones>(this.tipoBecaEspecificaciones);
    this.length = this.dataSourceEspecificaciones.data.length;
    this.cargandoRequisitos = null;

  }

  agregarEspecificacionCentros(tipoBecaEspecificaciones : TipoBecaEspecificaciones){
    this.renglonSeleccionado.BecaSeccionCentros = tipoBecaEspecificaciones.BecaSeccionCentros;    
  }

  agregarEspecificacionGrados(tipoBecaEspecificaciones : TipoBecaEspecificaciones){
    this.renglonSeleccionado.BecaSeccionGrados = tipoBecaEspecificaciones.BecaSeccionGrados;
  }

  verify() {
    this.enableBtn = false;
    this.tipoBecaEspecificaciones.forEach(x => {
      if( (x.BecaSeccionCentros!= null &&   x.BecaSeccionCentros.length > 0 ) 
      ||  (x .BecaSeccionGrados != null  &&  x.BecaSeccionGrados.length > 0) ){
        this.enableBtn = true;
      }
    });
  }
}
