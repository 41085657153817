import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DescargaDocFilters } from '@app/_models/descargaDocFilter';
import { environment } from '@environments/environment';
import { RevisionRequisitosParam } from '@app/_models/revisionReqParam';
import { Solicitud } from '@app/_models/solicitud';
import { RetroEstudioSolicitud } from '@app/_models/retroEstudio';

@Injectable({ providedIn: 'root' })
export class DescargaDocumentosService {

    constructor(private http: HttpClient) {}

    get(nRegistros:number, nPagina:number, filters: DescargaDocFilters){}

    buscarSolicitudes(param : RevisionRequisitosParam):Promise<Solicitud[]>{
        return this.http.post<Solicitud[]>(`${environment.apiUrl}/RevisionRequisitosSolicitudes`, param).toPromise();
    }

    download(RetroEstudioSolicitudID: number, CURP : string) {

        let params = new HttpParams();
        params = params.append('RetroEstudioSolicitudID', RetroEstudioSolicitudID.toString());
        params = params.append('CURP', CURP);
        
        return this.http.get(`${environment.apiUrl}/DescargaEstudio`, { params, responseType: 'blob' } );
    }

    buscarRetros(nRegistros:number, nPagina:number, cicloID : number, empresas : string[], centros : string[],
        regiones : number[],secciones : number[], grados : string[],nombre : string, UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/BusquedaDescargaEstudio`,  {nRegistros, nPagina, cicloID, empresas, centros, regiones, secciones,  grados, nombre,UsuarioID } );
    }
}