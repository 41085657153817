<div class="example-form mat-elevation-z8">
  <form class="modal-form" name="nuevaPropuestaAlumno" id="nuevaPropuestaAlumno" [formGroup]="nuevaPropuestaAlumno" autocomplete="off">
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>{{Seccion}}</mat-label>
      <mat-select  name="seccion" formControlName="seccion" id="seccion" (ngModelChange)='verify()' (openedChange)="onSelectSeccion($event)" >
          <!--mat-option  [value]="0"><label>Todos</label></mat-option-->        
          <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
            {{seccion.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   
    <div class="modal-form margin-div">
      <button mat-raised-button (click)="onNoClick()"  class="buttonSecondary xsbtn">CANCELAR</button>
      <button mat-raised-button (click)="save()" *ngIf="propuestaEditable" class="button xsbtn" [disabled]="!enableBtn || revicion || esperando">GUARDAR</button>
    </div>
  </form>
  <br><br>
  <mat-table [dataSource]="dataSource" matSort>
  
    <ng-container matColumnDef="seccion">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Sección </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="seccion" class='column-id-prospecto'>
          {{element.Seccion.Nombre}}
        </mat-cell>
    </ng-container>

    

    <ng-container matColumnDef="grado">
      <mat-header-cell *matHeaderCellDef class="sub-title"> Grado </mat-header-cell><br>
      <mat-cell *matCellDef="let element" data-label="grado">
        {{element.GradoEducativo.Nombre}}
      </mat-cell>
    </ng-container>
        
    <ng-container matColumnDef="alumnosa">
      <mat-header-cell *matHeaderCellDef class="sub-title"> Alumnos <br> actuales </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="alumnosa"> 
        <input matInput type="number" #inputAct onkeypress="return event.charCode >= 48" onKeyPress="if(this.value.length==6) return false;"
          oninput="this.value = Math.abs(this.value)" min="0" max="999999" [disabled]="revicion || !propuestaEditable" [(ngModel)] ="element.Actuales"> 
          <mat-hint *ngIf="0 > element.Actuales">Min 0</mat-hint>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alumnosni">
      <mat-header-cell *matHeaderCellDef class="sub-title"> Alumnos <br> nuevo ingreso </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="alumnosni"> 
        <input matInput type="number" #inputIng onkeypress="return event.charCode >= 48" onKeyPress="if(this.value.length > 6) return false;"
        oninput="this.value = Math.abs(this.value)" min="0" max="999999" [disabled]="revicion || !propuestaEditable" [(ngModel)] ="element.Nuevos"> 
          <mat-hint *ngIf="0 > element.Nuevos">Min 0</mat-hint>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alumnosr">
      <mat-header-cell *matHeaderCellDef class='column-opciones'> Alumnos <br> reinscritos </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="alumnosr"> 
        <input matInput type="number" #inputRein onkeypress="return event.charCode >= 48" onKeyPress="if(this.value.length==6) return false;"
        oninput="this.value = Math.abs(this.value)" min="" [disabled]="revicion || !propuestaEditable" [(ngModel)] ="element.Reingreso"> 
          <mat-hint *ngIf="0 > element.Reingreso">Min 0</mat-hint>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="total">
      <mat-header-cell *matHeaderCellDef class='column-opciones'> Total alumnos <br> proyectados</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="total"> 
        <input matInput type="number" disabled [(ngModel)] ="element.Nuevos + element.Reingreso">             
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="variacacion">
      <mat-header-cell *matHeaderCellDef class='column-opciones'> Variación de <br> alumnos </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="variacacion"> 
        <input matInput type="number" disabled [(ngModel)] ="element.Nuevos + element.Reingreso - element.Actuales">             
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>      

</div>
<br>
<div class="loading-screen-icon"  *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>
<br><br><br><br>