<mat-card class="seleccion-beca-card">
  <div class="example-form row">
    <mat-card-title class="text-left fz-26">REGISTRO DE ALUMNO.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br>
  <div class="principal1">
    <div class="seleccion-beca-card">
      <form class="registro-form" name="cambioPasswordForm" id="cambioPasswordForm"  [formGroup]="cambioPasswordForm">  
        <br>
        <mat-form-field class="registro-full-width" appearance="fill">
          <mat-label>CURP del alumno:</mat-label>
          <input matInput placeholder="Ingrese su curp" (ngModelChange)="validarCurp()"
            matTooltip="Ingrese el CURP del alumno*" maxlength="18"
            value="" formControlName="curp" required>             
        </mat-form-field> 
        <mat-hint *ngIf="curpError">El CURP tiene un formato inválido</mat-hint>  

        <mat-form-field class="registro-full-width" appearance="fill">
          <mat-label>Centro del alumno:</mat-label>
          <mat-select placeholder="Centro" id="centro" name="centro" 
          (openedChange)="onSelectCentro($event)" formControlName="centro" required >
            <mat-option *ngFor="let c of centros" [value]="c.CentroID">
              {{c.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>        
        
        <mat-form-field class="registro-full-width" appearance="fill" >
          <mat-label>Correo electrónico:</mat-label>
          <input matInput placeholder="Ingrese su correo" (ngModelChange)="validarCorreo()" (input)='validarEmail()' [disabled]="true"
            matTooltip="Ingrese un correo electrónico, será tu nombre de usuario*" autocomplete="usuario"
            value="" formControlName="correo" required>
          <mat-error *ngIf="cambioPasswordForm.hasError('existe')">
            El correo ya se  dió de alta en el sistema
          </mat-error>      
          <div *ngIf="correoError">
            <mat-error *ngIf="correoError"> Ingrese un correo válido </mat-error>
          </div>                  
        </mat-form-field>       
        
        <mat-form-field class="registro-full-width" appearance="fill" >
          <mat-label>Confirmar Correo electrónico:</mat-label>
          <input matInput placeholder="Ingrese su correo" autocomplete="usuario"
          onCopy="return false" onDrag="return false" onDrop="return false" onPaste="return false" autocomplete=off
            value="" formControlName="confirmCorreo" (input)='validarEmail()' required>       
          <div *ngIf="correoCoincide">
            <mat-error *ngIf="correoCoincide"> El correo no coincide </mat-error>
          </div>                 
        </mat-form-field>  
    
        <mat-form-field class="registro-full-width" appearance="fill" >
          <mat-label>Correo electrónico alterno:</mat-label>
          <input matInput placeholder="Ingrese su correo"  (input)="validarCorreo()" autocomplete="usuario"
            value="" formControlName="correoAlterno" required>  
          <div *ngIf="correoAlternoError">
            <mat-error *ngIf="correoAlternoError"> Ingrese un correo válido </mat-error>
          </div>      
          <div *ngIf="correoAlternoIgual">
            <mat-error *ngIf="correoAlternoIgual"> Los correos deben ser diferentes </mat-error>
          </div>                           
        </mat-form-field>  
        
        <mat-form-field class="registro-full-width" appearance="fill">
          <mat-label>Nombre(s) del alumno:</mat-label>
          <input matInput placeholder="Nombre(s) del alumno" value="" formControlName="nombre" type="text" (keypress)="validarCaracteres($event)">
        </mat-form-field>
    
        <mat-form-field class="registro-full-width" appearance="fill" onkeypress="return validarCaracteres(event)" (keypress)="validarCaracteres($event)">
          <mat-label>Primer apellido del alumno:</mat-label>
          <input matInput placeholder="Ingrese su primer apellido" value="" formControlName="primerApellido" required>
        </mat-form-field>
      
        <mat-form-field class="registro-full-width" appearance="fill" onkeypress="return validarCaracteres(event)" (keypress)="validarCaracteres($event)">
          <mat-label>Segundo apellido del alumno:</mat-label>
          <input matInput placeholder="Ingrese su segundo apellido" value="" formControlName="segundoApellido">
        </mat-form-field>
      
        <mat-form-field class="md-width" appearance="fill" style="width: 60%;">
          <mat-label>Fecha de nacimiento del alumno:</mat-label>
          <input matInput [min]="minDate" [max]="maxDate" name ="fechaNacimiento"
            (ngModelChange)='validar()' (input)='validar()'
            [matDatepicker]="picker" formControlName="fechaNacimiento" required>   
            <div *ngIf="dateError">
              <mat-error *ngIf="dateError"> La fecha ingresada no tiene un formato correcto. </mat-error>
            </div>                                   
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <br>
        <label class="color-marino">Contraseña:</label> 
        <br><br>
        <mat-form-field class="registro-full-width" appearance="fill">
            <mat-label>Escribe la contraseña</mat-label>
            <input matInput [type]="show2 ? 'text' : 'password'" id="password" name="password" autocomplete="new-password"
              formControlName="password" required (ngModelChange)="updateProgressBar()" 
              (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
              matTooltip="La contraseña debe tener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o carácter especial*" required 
              class="input-type-password">
              <span>
                <button mat-icon-button matSuffix  class="position-view" matTooltip="Visualizar contraseña" (click)="passwordView2()" (click)="hide = !hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>

              <div *ngIf="fortalezaError">
                <mat-error *ngIf="fortalezaError">La contraseña no cumple con los requisitos de seguridad </mat-error>
              </div>            
        </mat-form-field>
        <div *ngIf="mostrarDescripcionPass">
          <mat-label  class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial</mat-label>       
        </div>           
        <br>    
        <mat-progress-bar *ngIf="containsPssword"
        mode="determinate"
        class="example-full-width"
        [color]="colorForce"        
        [value]="valueForce"
        [bufferValue]="bufferValue" >
        </mat-progress-bar>
    
        <mat-form-field class="registro-full-width" appearance="fill">
          <mat-label>Confirmar la contraseña</mat-label>
            <input matInput autocomplete="new-password2"
            [type]="show ? 'text' : 'password'" id="confirmPassword" name="confirmPassword" 
              formControlName="confirmPassword" [errorStateMatcher]="matcher" required>
              <span>
                <button mat-icon-button matSuffix  class="position-view" matTooltip="Visualizar contraseña" (click)="passwordView()" (click)="hide = !hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>
              <mat-error *ngIf="cambioPasswordForm.hasError('notSame')">
              El password no coincide
            </mat-error> 
        </mat-form-field>
        <br>
        <mat-checkbox formControlName="acepto" required>
          <div> Acepto el <a target="blank" href="{{redes.Aviso}}">Aviso de privacidad</a>  </div>
        </mat-checkbox>
        <br>
        <p class="color-marino">Cualquier inconveniente que presente con la creación de Usuario por favor escríbenos 
          <a href="javascript:void(0)" (click)="mensajeAyuda()">aquí</a>
        </p>
        <br>
        <br>
        <div class="text-center">
          <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }"  mat-raised-button routerLink="/pantalla-principal" matTooltip="Cancelar registro">CANCELAR</button>
          <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }"  mat-raised-button  (click)="crearCuenta()" [disabled]="this.cambioPasswordForm.invalid || correoCoincide || correoAlternoIgual ">CREAR CUENTA</button>
        </div>
        <br/>
      </form>
    </div>
  </div>  
</mat-card>

<script>
  
</script>
