<mat-card class="seleccion-beca-card">
    <br><br>
    <div class="example-form">
      <mat-card-title class="text-left fz-26">ADMINISTRACIÓN DE USUARIOS PARA EMPRESAS.</mat-card-title>
      <mat-divider></mat-divider>
    </div>
    <br><br><br><br><br>
      <div class="example-form">
        <form (keydown.enter)="$event.preventDefault()" name="adminInfoForm" id="adminInfoForm" >
          <div class="row">
            <button mat-raised-button class="button" (click)="agregarUsuario()" >+ NUEVO USUARIO</button>
            <input class="input-search icon xs-width text-ident" placeholder="Buscar empresa" [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
            <br>
          </div>
        </form>
      </div>
      <br>
      <div class="example-form mat-elevation-z8">
  
        <mat-table  [dataSource]="dataSource" >
      
            <ng-container matColumnDef="NumeroEmpleado">
              <mat-header-cell *matHeaderCellDef> Empleado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Número empleado"> {{element.NumeroEmpleado}} </mat-cell>
            </ng-container>      
           
            <ng-container matColumnDef="Area">
              <mat-header-cell *matHeaderCellDef> Área </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Área"> {{element.Area_.Nombre}} </mat-cell>
            </ng-container>
               
            <ng-container matColumnDef="Nombre">
              <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Nombre"> {{element.Nombre}} {{element.ApellidoPaterno}} {{element.ApellidoMaterno}} </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="Correo">
              <mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Correo"> {{element.Correo}} </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="Privilegio">
              <mat-header-cell *matHeaderCellDef> Privilegios </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Privilegios"> {{element.Roles}} </mat-cell>
            </ng-container>
  
            <ng-container matColumnDef="Opciones">
              <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Opciones">
                  <button mat-icon-button color="basic" aria-label="Editar" (click)="editarUsuario(element)"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                  <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarUsuario(element)"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
              </mat-cell>
            </ng-container>
  
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table> 
        
          <mat-paginator #paginator
            [length]="length"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]"
            (page)="pageEvent = getServerData($event)"
            >
          </mat-paginator>
  
        </div>    
      
  </mat-card>
  