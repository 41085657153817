<mat-card class="mat_card">         
  <mat-card-title class="card-title fz-40">Ayuda</mat-card-title>
  <br/>
  <div class="col center">
  
    
      <table>
        <tr>
            <td><mat-icon aria-hidden="false" aria-label="Example home icon">phone</mat-icon></td>
            <br/>
            <td>
              <a href="tel:30676826"><label>30676826</label></a><br>
              <a href="tel:30676827"><label>30676827</label></a>
            </td>
        </tr>
        <br/>
        <tr>
            <td><mat-icon aria-hidden="false" aria-label="Example home icon">mail_outline</mat-icon></td>
            <br/>
            <td><a href="mailto:ayudaenlinea@redaliat.com"><label>ayudaenlinea@redaliat.com</label></a></td>
        </tr>
        <br/>
        <br/>
        <tr>
          <td><mat-icon aria-hidden="false" aria-label="Example home icon">chat_bubble_outline</mat-icon></td>
          <br/>
          <td><a href="tel:55 7888 1566"><label>55 7888 1566</label></a></td>
      </tr>
      </table>
 

</div>
</mat-card>