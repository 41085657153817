import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingScreenService } from '@app/_services/loading-screen.service';

@Component({
  selector: 'app-referencia',
  templateUrl: './referencia.component.html',
  styleUrls: ['./referencia.component.scss']
})
export class ReferenciaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingScreenService
  ) { }

  ngOnInit(): void {
    this.loadingService.startLoading();
    let institucion = this.route.snapshot.queryParamMap.get('institucion');
    let campus = this.route.snapshot.queryParamMap.get('campus');
    let programa = this.route.snapshot.queryParamMap.get('programa');
    let grado = this.route.snapshot.queryParamMap.get('grado');

    localStorage.setItem("institucion", institucion);
    localStorage.setItem("campus", campus);
    localStorage.setItem("programa", programa);
    localStorage.setItem("grado", grado);

    this.router.navigate(["/"]);
    this.loadingService.stopLoading();

  }

}
