import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { RequisitoBeca } from '@app/_models/requisitoBeca';
import { environment } from '@environments/environment';
import { DocumentoRequisitoSolicitud } from '@app/_models/documentoRequisitoSol';
import { ResponseRest } from '@app/_models/responseRest';


@Injectable({ providedIn: 'root' })
export class CargaDocumentosService {

  constructor(private http: HttpClient) {}
  
     
  obtenerDocumentosRequisitos(SolicitudID:number){
    return  this.http.get<DocumentoRequisitoSolicitud[]>(`${environment.apiUrl}/CargaDocumento/${SolicitudID}/ArchivosRequisitos`);
  }

  obtenerDocumentosBachillerato(SolicitudID:number){
    return  this.http.get<DocumentoRequisitoSolicitud[]>(`${environment.apiUrl}/CargaDocumento/${SolicitudID}/ArchivosBachillerato`);
  }

  importar(archivos: FormData, SolicitudID: number) {

    let params = new HttpParams();
    params = params.append('SolicitudID', SolicitudID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/CargaDocumento`, archivos, { params } );
  }
}