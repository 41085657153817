<mat-card class="registro-card">
    <div class="center">
      <mat-card class="none">
          <mat-card-title class="card-title fz-40"><div class="el-titulo">¡Tu cuenta ha sido creada!</div></mat-card-title>
          <br>
          <div class="texto color-marino" style='padding-bottom: 150px;'>
           Se ha registrado la cuenta: {{curp}}.
            <br>
            <br>
            En los próximos 10 minutos recibirás un correo electrónico de  noreply@semperaltius.com con los pasos para activar tu contraseña.
            <br>
            <br>
            En caso de que no lo recibas, te pedimos que revises la bandeja de correos no deseados o SPAM.
         </div>
         <br>
         <div class="text-center">
           <button class="button" mat-raised-button routerLink="/login">Continuar</button>
         </div>

      </mat-card>
    </div>
  </mat-card>
  