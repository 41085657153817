<div>
  <mat-card-title class="card-title fz-20">NUEVA PROPUESTA DE BECA.</mat-card-title>
  <mat-divider></mat-divider>
  <br>
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabClick($event)">
    <mat-tab label="Datos generales" >
      <app-detalle-datos-becas  #tabGenerales ></app-detalle-datos-becas>
    </mat-tab>
    <mat-tab label="Alumnos">
      <app-detalle-alumno-becas #tabAlumnos></app-detalle-alumno-becas>
    </mat-tab>
    <mat-tab label="Cuotas">
      <app-detalle-cuotas-becas #tabCuotas></app-detalle-cuotas-becas>
    </mat-tab>
    <mat-tab label="Propuesta de becas"> 
      <app-detalle-propuesta-becas  #tabPropuesta></app-detalle-propuesta-becas>
    </mat-tab>
    <mat-tab label="Resumen de becas" > 
      <app-detalle-resumen-becas #tabResumen></app-detalle-resumen-becas> 
    </mat-tab>
    <mat-tab label="Análisis Comparativo"> 
      <app-detalle-analisis-becas #tabAnalisis></app-detalle-analisis-becas>
    </mat-tab>
  </mat-tab-group>
</div>