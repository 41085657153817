import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';
import { UsuarioRecuperacion } from '@app/_models/usuarioRecuperacion';

@Injectable({ providedIn: 'root' })
export class ActivationService {

  constructor(private http: HttpClient) {}

  activarCuenta(usuario: UsuarioRecuperacion):Promise<ResponseRest<string>>{
    return  this.http.post<ResponseRest<string>>(`${environment.apiUrl}/Activacion`, usuario).toPromise();
  }
}
