import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormControl, FormGroupDirective, NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ObjetoOp } from '@app/_models/objectOperation';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Solicitud } from '@app/_models/solicitud';
import { RevisionSolicitudesService } from '@app/_services/revision-solicitudes.service';
import { Alumno } from '@app/_models/alumno';
import { Responsable } from '@app/_models/responsable';
import { SolicitudService } from '@app/_services/solicitud.service';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
import { MensajeSolicitudComponent } from '../mensaje-solicitud/mensaje-solicitud.component';

@Component({
  selector: 'app-solicitud-beca',
  templateUrl: './solicitud-beca.component.html',
  styleUrls: ['./solicitud-beca.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],

})

export class SolicitudBecaComponent implements OnInit {
  
  idUsuario: number;
  Solicitud : Solicitud;
  Responsable : Responsable;

  constructor(private formBuilder: FormBuilder, private router: Router,
          private authenticationService: AuthenticationService,
          private revisionSolicitudesService : RevisionSolicitudesService,
          private solicitudService : SolicitudService,
          public dialogRef: MatDialogRef<SolicitudBecaComponent>,
          public dialog: MatDialog,
          @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) {}

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    //this.obtenerDetalle();
    this.Solicitud = this.data.Objeto;
    console.log("Solicitud editable " + this.Solicitud.SolicitudEditable);
    this.Responsable = this.obtenerResponsable(this.Solicitud.Alumno, 1);

    if(this.Responsable == null){
      this.Responsable = this.obtenerResponsable(this.Solicitud.Alumno, 2);
    }
    if(this.Responsable == null){
      this.Responsable = this.obtenerResponsable(this.Solicitud.Alumno, 3);
    }


  }

  obtenerResponsable(Alumno : Alumno,  ParentescoID : number): Responsable{
    var Responsable = null;
    Alumno.Responsables.forEach(element => {
      if(element.ParentescoID == ParentescoID){
        Responsable = element;
      }
    });

    return Responsable;
  }

  cancelar(): void {
    this.dialogRef.close(false);
  }
  
  incompleta(): void {
    this.Solicitud.UsuarioID = this.idUsuario;
    this.agregarComentarios(this.Solicitud, "Incompleta");
  
  }
  
  completa(): void {
    this.Solicitud.UsuarioID = this.idUsuario;
    this.agregarComentarios(this.Solicitud, "Completa");
  }

  agregarComentarios(solicitud:Solicitud,  Operacion: string): void {

    const dialogRef = this.dialog.open(MensajeSolicitudComponent, {
      width: "40%",
      data: {Operation: Operacion, Objeto: solicitud}
    }); 

    dialogRef.afterClosed().subscribe(result => {
      this.dialogRef.close(result);
      
    });
  }


}
