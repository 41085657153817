<div class="example-form">
    <mat-card-title class="text-left fz-26">HISTORIAL RENOVACION DE BECAS.</mat-card-title>
</div>
<mat-divider></mat-divider>
<br><br>
<mat-table [dataSource]="dataSource" matSort> 

  <ng-container matColumnDef="Fecha">
    <mat-header-cell *matHeaderCellDef class="sub-title"> Fecha </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Fecha"> {{element.Fecha  | date:'short'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Descripcion">
    <mat-header-cell *matHeaderCellDef class="sub-title"> Descripción del movimiento </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Descripcion}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="Usuario">
    <mat-header-cell *matHeaderCellDef class="sub-title"> Usuario que realizó el movimiento </mat-header-cell>
    <mat-cell *matCellDef="let element" data-label="Usuario"> 
      <p *ngIf="element.Descripcion == 'Creada'">
        {{element.Curp}}
      </p>
      <p *ngIf="element.Descripcion != 'Creada'">
        {{element.Usuario}}
      </p>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;">
  </mat-row>

</mat-table>
<div class="loading-screen-icon"  *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>
<br>
<div class="modal-form margin-div">
  <button mat-raised-button color="danger" (click)="onNoClick()" class="buttonSecondary xsbtn">SALIR</button>
</div>