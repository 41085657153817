import { Component, OnInit, ViewChild } from '@angular/core';
import { CentroService } from '@app/_services/centro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { MatTableDataSource } from '@angular/material/table';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { first } from 'rxjs/operators';
import { ModificacionBecaSolicitud} from '@app/_models/modificacionBecaSolicitud';
import { CartaBecaService } from '@app/_services/carta-beca.service';
import { EstatusEnvioCorreo } from '@app/_models/estatusEnvioCorreo';
import { Solicitud } from '@app/_models/solicitud';
import { HistorialSolicitudComponent } from '@app/_components/historial-solicitud/historial-solicitud.component';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { FileDownloadService } from '@app/_services/file-download.service';
import { faElementor } from '@fortawesome/free-brands-svg-icons';
import { saveAs } from 'file-saver';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { ExpedientesAsignadosService } from '@app/_services/expedientesAsignados.service';
import { DetalleModBecasComponent } from '../detalle-mod-becas/detalle-mod-becas.component';
import { MensajePropuestaComponent } from '../mensaje-propuesta/mensaje-propuesta.component';
import { BecaOtorgada } from '@app/_models/becaOtorgada';
import { MensajeCorreoComponent } from '../mensaje-correo/mensaje-correo.component';
import { TipoCarta } from '@app/_models/tipoCarta';

@Component({
  selector: 'app-envio-carta',
  templateUrl: './envio-carta.component.html',
  styleUrls: ['./envio-carta.component.scss']
})
export class EnvioCartaComponent implements OnInit {

  [x: string]: any;

  displayedColumns: string[] = ['select','colegio','seccion', 'CURP', 'nombre', 'tipo','porcentaje','estatus', 'acciones'];
  dataSource = new MatTableDataSource<BecaOtorgada>();
  becasOtorgadas: BecaOtorgada[];

  modificacioBecaForm: FormGroup;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('mySelEstatus') mySelEstatus: MatSelect;
  @ViewChild('allSelectedEstatus') private allSelectedEstatus: MatOption;
  @ViewChild('mySelTipoBeca') mySelTipoBeca: MatSelect;
  @ViewChild('allSelectedTipoBeca') private allSelectedTipoBeca: MatOption;

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  selectedCentros : Centro [];
  selectedSecciones : Seccion [];  
  estatusMod: EstatusEnvioCorreo[] = [];
  tiposCarta: TipoCarta[] = [];
  ciclosBecas : Ciclo [];  

  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoEstatus = null;  
  cargandoCiclos = null;

  idUsuario: number;
  enviarBtn = false;
  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
  
  filtro: string = "";
  tiposBeca  : TipoBeca[];
  
  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private centroService: CentroService,
    private seccionService: SeccionService,
    private cicloBecaService: CicloBecaService,
    private CartaBecaService: CartaBecaService,
    private fileDownloadService: FileDownloadService,
    private tipoBecaService : TipoBecaService,
    public dialog: MatDialog
  ) { }


  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
  
    this.getCiclosBecas();
    this.cargarCentros();      
    this.obtenerEstatus();
    this.getTiposBeca();
    this.obtenerTiposCarta();
    
    this.modificacioBecaForm = this.formBuilder.group({
      ciclo: ['', Validators.required],
      centros: [''],
      filtro: [''],
      secciones: [''],
      estatus: [''],
      tipoBeca: [''],
      nombre: ['']
    });
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  cargarCentros() {
    this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  editarBeca(modificacion: ModificacionBecaSolicitud): void {
    const dialogRef = this.dialog.open(DetalleModBecasComponent, {
      width: '50%',
      data: {Operation: 'Editar', Objeto: modificacion}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  obtenerEstatus() {
    this.CartaBecaService.consultarEstatusEnvioCorreo().then(estatus => {
      this.estatusMod = estatus;
      this.cargandoEstatus = null;

      this.estatusMod.forEach(element => {
        console.log("Estatus " + JSON.stringify(element));
      });

    }).catch(err => {
      this.cargandoEstatus = "(Error)";
      console.log("Error" + err);
    });
  }


  obtenerTiposCarta() {
    this.CartaBecaService.consultarTipoCartas().then(tiposCarta => {
      this.tiposCarta = tiposCarta;
    }).catch(err => {
    });
  }

  verHistorial(solicitud:Solicitud){
    const dialogRef = this.dialog.open(HistorialSolicitudComponent, {
      width: '80%',
      data: {Operation: 'Editar', Objeto: solicitud}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.modificacioBecaForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  
 
  buscar() { 
    if ( this.modificacioBecaForm.invalid) {
      return
    }
    var cicloID = this.modificacioBecaForm.get('ciclo').value;  
    var centros = this.modificacioBecaForm.get('centros').value;  
    var secciones = this.modificacioBecaForm.get('secciones').value;  
    var estatusModificacion = this.modificacioBecaForm.get('estatus').value;  
    var tiposBeca = this.modificacioBecaForm.get('tipoBeca').value;  
    var filtro = this.modificacioBecaForm.get('filtro').value;  

    console.log("Estatus" + JSON.stringify(filtro));

    //console.log("Estatus modificacion " + JSON.stringify(estatusModificacion));
    this.CartaBecaService.listadoBecas(cicloID,  centros, secciones, estatusModificacion, tiposBeca,
      this.pageSize, (this.pageIndex+1), filtro, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;

        this.becasOtorgadas = data.Dato;

        /*
        this.propuestaSolicitudes.forEach(element => {
          element.Solicitud.EnviarCorreo = true;
          console.log("asignado " + element.Solicitud.EnviarCorreo);
        });       */   
        this.dataSource = new MatTableDataSource<BecaOtorgada>(this.becasOtorgadas);
      },
      () => {
      });
  }



  generarCarta(element : BecaOtorgada) { 
    this.CartaBecaService.GenerarCarta(element).pipe(first())
    .subscribe(
      data => {
        if (data.Exito) {
          this.fileDownloadService.downloadFile(data.Dato, "carta_"+ element.Solicitud.Alumno.CURP + ".pdf", "application/pdf");
        }else{
          const dialogData = new ConfirmDialogModel('', "Hubo un error al generar la carta", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 

        }
      },
      () => {
      });
  }




  descargarCartas(){
    var cicloID = this.modificacioBecaForm.get('ciclo').value;  
    var centros = this.modificacioBecaForm.get('centros').value;  
    var secciones = this.modificacioBecaForm.get('secciones').value;  
    var estatusModificacion = this.modificacioBecaForm.get('estatus').value;  
    var tiposBeca = this.modificacioBecaForm.get('tipoBeca').value;  
    var filtro = this.modificacioBecaForm.get('filtro').value;  

    this.CartaBecaService.DescargarPaquete(cicloID,centros,secciones, estatusModificacion, tiposBeca, filtro, this.idUsuario)
    .subscribe (        
      
      data => {
      if (data.Exito) {
        this.fileDownloadService.downloadFile(data.Dato, "cartas.zip", "application/zip");
      }else{
        const dialogData = new ConfirmDialogModel('', "Hubo un error al generar el paquete de cartas", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        }); 

      }
    }
    );
  }


  verComentarios(becaOtorgada:BecaOtorgada): void {

      becaOtorgada.EditarComentarios = true;

    console.log("Editar comentarios " + this.propuestaSolicitudesEditable);
    const dialogRef = this.dialog.open(MensajeCorreoComponent, {
      width: "40%",
      data: becaOtorgada
    }); 
  }


  enviarCorreo() { 
    this.CartaBecaService.EnviarCorreoPorSeleccion(this.becasOtorgadas, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        console.log("Data.Exito " + data.Exito);
        console.log("data.Dato.length " + data.Dato.length);
        if (data.Exito) {
          var respuesta = "";
          if(data.Dato.length > 0){
              respuesta = "Hubo un problema al enviar algunas cartas";
          }else{
            respuesta = "Los correos fueron enviados exitosamente";

          }

          const dialogData = new ConfirmDialogModel('', respuesta, false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 

        }else{
          const dialogData = new ConfirmDialogModel('', "Hubo un error al enviarlos correos", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 

        }
      },
      () => {
      });
  }


  getTiposBeca(){
    this.tipoBecaService.listadoBecasCicloActivo().then(tiposBeca => {
      this.tiposBeca = tiposBeca;

    }).catch(err => {
      console.log("Error" + err);
    });
  }



  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }
    if(this.modificacioBecaForm.controls != null && 
      this.modificacioBecaForm.controls.userType != null &&
      this.modificacioBecaForm.controls.userType.value != null){
      if(this.modificacioBecaForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }  

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }
  
  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.modificacioBecaForm.controls != null && 
      this.modificacioBecaForm.controls.userType != null &&
      this.modificacioBecaForm.controls.userType.value != null){
      if(this.modificacioBecaForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  tosslePerOneEstatus(all){ 
    if (this.allSelectedEstatus.selected) {  
     this.allSelectedEstatus.deselect();
     return false;
    }

    if(this.modificacioBecaForm.controls != null && 
      this.modificacioBecaForm.controls.userType != null &&
      this.modificacioBecaForm.controls.userType.value != null){
        if(this.modificacioBecaForm.controls.userType.value.length==this.estatusMod.length)
          this.allSelectedEstatus.select();
    }
  }

  toggleAllSelectionEstatus() {
    if (this.allSelectedEstatus.selected) {
      this.mySelEstatus.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelEstatus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }


  tosslePerOneTipoBeca(all){ 
    if (this.allSelectedTipoBeca.selected) {  
     this.allSelectedTipoBeca.deselect();
     return false;
    }

    if(this.modificacioBecaForm.controls != null && 
      this.modificacioBecaForm.controls.userType != null &&
      this.modificacioBecaForm.controls.userType.value != null){
        if(this.modificacioBecaForm.controls.userType.value.length==this.estatusMod.length)
          this.allSelectedTipoBeca.select();
    }
  }

  toggleAllSelectionTipoBeca() {
    if (this.allSelectedTipoBeca.selected) {
      this.mySelTipoBeca.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelTipoBeca.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

//Si el número de elementos seleccionados coincide con el número total de filas.
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}

//Selecciona todas las filas si no están todas seleccionadas. 
masterToggle() {
  this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
}

calculaValores(elm){
  if (elm.checked) this.enviarBtn = true
}


}
