import { Municipio } from './municipio';

export class Colonia{

    ColoniaID: number;
    Clave: string;
    Nombre: string;
    CodigoPostal : string;    
    Municipio : Municipio;
    
}