export class DocumentoEstudio{

    DocumentoEstudioID: number;
    SolicitudID : number;
    NombreOriginal: string;
    MimeType: string;
    NombreFisico: string;
    BotonDescripcion : string;
    EstatusCarga_: string;
    Archivo_: File;
}
