import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { DetalleEstudiosocioeconimicoComponent } from '../_components/detalle-estudiosocioeconimico/detalle-estudiosocioeconimico.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Centro } from '@app/_models/centro';
import { ExpedientesAsignadosService } from '@app/_services/expedientesAsignados.service';
import { EstatusSolicitudService } from '@app/_services/estatusSolicitud.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { Solicitud } from '@app/_models/solicitud';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';
import { FileDownloadService } from '@app/_services/file-download.service';
import { EstatusEstudioService } from '@app/_services/estatusEstudio.service';
import { EstudioSolicitud } from '@app/_models/estudioSolicitud';
import { EstatusEstudio } from '@app/_models/estatusEstudio';

@Component({
  selector: 'app-expedientes-asignados',
  templateUrl: './expedientes-asignados.component.html',
  styleUrls: ['./expedientes-asignados.component.scss']
})
export class ExpedientesAsignadosComponent implements OnInit {

  displayedColumns: string[] = ['Colegio', 'Nombre', 'NombrePadres', 'Estatus', 'Opciones'];

  dataSource = new MatTableDataSource<EstudioSolicitud>();
  expedientesAsignados: FormGroup;  

  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;

  @ViewChild('mySelEstatus') mySelEstatus: MatSelect;
  @ViewChild('allSelectedEstatus') private allSelectedEstatus: MatOption;


  solicitudes: EstudioSolicitud[] = [];

  centros: Centro[] = [];
  selectedCentros : Centro [];  
  cargandoCentros = null;  
  estatusEstudio: EstatusEstudio[] = [];
  selectedEstatus : EstatusSolicitud [];  
  cargandoEstatus = null;  

  idUsuario: number;

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog, 
    private authenticationService: AuthenticationService, 
    private expedientesAsignadosService : ExpedientesAsignadosService,
    private estausEstudioService : EstatusEstudioService,
    private fileDownloadService: FileDownloadService,) { }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.dataSource.paginator = this.paginator;

    this.expedientesAsignados = this.formBuilder.group({
      centros: ['', Validators.required],
      estatus: [''],

    });  
    
    this.obtenerCentros();
    this.obtenerEstatus();
  }

  obtenerCentros() {
    this.expedientesAsignadosService.consultaCentrosEmpresa(this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
      
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }  

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  obtenerEstatus() {
    this.estausEstudioService.consultarEstatusEstudio().then(estatus => {
      this.estatusEstudio = estatus;
      this.cargandoEstatus = null;
    }).catch(err => {
      this.cargandoEstatus = "(Error)";
      console.log("Error" + err);
    });
  }

  buscar() {

    if (this.expedientesAsignados.invalid){
      return
    } 

    var centros = this.expedientesAsignados.get('centros').value;  
    var estatus = this.expedientesAsignados.get('estatus').value;  

    this.expedientesAsignadosService.consultarSolicitudesAsignadas(this.pageSize, (this.pageIndex+1),centros, estatus, this.idUsuario).then(respuesta => {
      if(respuesta.Exito){
        this.solicitudes = respuesta.Dato;
        this.length = respuesta.Total;
        this.dataSource = new MatTableDataSource<EstudioSolicitud>(this.solicitudes);
      }

    }).catch(err => {
      console.log("Error" + err);
    });
  }  

  cargarEstudio(solicitudElement:any): void {
    const dialogRef = this.dialog.open(DetalleEstudiosocioeconimicoComponent, {
      width: '60%',
      data: {Operation: 'Cargar', Objeto: solicitudElement}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
      
    });
  }

  iniciarProceso(estudioSolicitud:EstudioSolicitud){
    this.expedientesAsignadosService.iniciarProceso(estudioSolicitud.Solicitud.SolicitudID, this.idUsuario)
    .subscribe(
      data => {
        if (!!data) {
          estudioSolicitud.EstatusEstudio = new EstatusEstudio();
          estudioSolicitud.EstatusEstudio.Clave = "ENPROCESO";
          estudioSolicitud.EstatusEstudio.EstatusEstudioID = 2;
          estudioSolicitud.EstatusEstudio.Nombre = "En proceso";

          this.fileDownloadService.downloadFile(data, "datos_estudiante.pdf", "application/pdf");
        }
      }
    );
  } 
    
  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.expedientesAsignados.controls != null && 
      this.expedientesAsignados.controls.userType != null &&
      this.expedientesAsignados.controls.userType.value != null){
        if(this.expedientesAsignados.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }      

  tosslePerOneEstatus(all){ 
    if (this.allSelectedEstatus.selected) {  
     this.allSelectedEstatus.deselect();
     return false;
    }

    if(this.expedientesAsignados.controls != null && 
      this.expedientesAsignados.controls.userType != null &&
      this.expedientesAsignados.controls.userType.value != null){
        if(this.expedientesAsignados.controls.userType.value.length==this.estatusEstudio.length)
          this.allSelectedEstatus.select();
    }
  }

  toggleAllSelectionEstatus() {
    if (this.allSelectedEstatus.selected) {
      this.mySelEstatus.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelEstatus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }     
}