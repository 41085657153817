import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { DetalleBecasCicloComponent } from '../_components/detalle-becas-ciclo/detalle-becas-ciclo.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { TipoBeca } from '@app/_models/tipoBeca';
import { Seccion } from '@app/_models/seccion';
import { MatSelect } from '@angular/material/select';
import { TipoBecaService } from '@app/_services/tipoBeca.service';

@Component({
  selector: 'app-configuracion-beca',
  templateUrl: './configuracion-beca.component.html',
  styleUrls: ['./configuracion-beca.component.scss']
})
export class ConfiguracionBecaComponent implements OnInit {

  displayedColumnsBecas: string[] = ['Clave', 'Nombre', 'Secciones','Opciones'];
  dataSource = new MatTableDataSource<TipoBeca>();

  error: boolean = false;
  errorMessageP: string = "";
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;

  secciones: Seccion[] = []; 
  selectedSecciones : Seccion [];  

  cargandoSecciones = null;
  pageEvent: PageEvent;
  
  pageIndex:number = 0;
  pageSize:number = 20;
  length:number = 0
 
  filtro: string = "";
  errorMessage: string = "";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialog: MatDialog, 
    private tipoBecaService:TipoBecaService) { }

  nuevoBeca(): void{
    const dialogRef = this.dialog.open(DetalleBecasCicloComponent, {
      width: '60%',
      data: {operacion: 'Crear'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar()
      }
    });
  }

  editarBeca(beca:TipoBeca): void {
    
    this.tipoBecaService.listadoBecasXId(beca.TipoBecaID).then(tiposBeca => {
      
      const dialogRef = this.dialog.open(DetalleBecasCicloComponent, {
        width: '60%',
        data: {Operation: 'Editar', TipoBeca: tiposBeca.Dato[0]}
      });

      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.buscar();
        }
      });

      }).catch(err => {
      console.log("Error" + err);
      });  
  }

  eliminarBeca(beca:TipoBeca): void {

    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la beca?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.tipoBecaService.delete(beca)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Success){
              this.buscar();
            }else{
              this.errorMessage = data.Message;
              this.error = true;
              console.log("Error " + JSON.stringify(this.errorMessage));
              const dialogData = new ConfirmDialogModel('Error', this.errorMessage, false);    
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              });

            }            
          },
          () => {
            this.error = true;
            this.errorMessage = "Error al eliminar la beca";
          });
      }
    });    
  }
  
  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    //this.buscar()
  }

  public getServerData(event?:PageEvent){

    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();
    return event;
  }

  buscar() { 
    var datos : TipoBeca[];
    this.tipoBecaService.listadoBecas(this.pageSize, (this.pageIndex+1), this.filtro).pipe(first())
    .subscribe(
      data => {
        
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<TipoBeca>(data.Dato);
        datos = data.Dato;

        datos.forEach(element => {
          element.NombresSecciones = element.TipoBecaSeccions.map( x => x.Seccion.Nombre);
        });
      },
      () => {
        this.errorMessageP = "Error al obtener la lista de requisitos";
      });
  }

  filterText(){
    setTimeout (() => {
      console.log("Buscando " + this.filtro);
      this.buscar();
   }, 1000);    

    this.pageIndex = 0;
  }
}