import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Empresa } from '@app/_models/empresa';
import {EstudioEmpresa} from '@app/_models/estudioEmpresa';
import { OperationResult } from '@app/_models/operationResult';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }
  get(nRegistros:number, nPagina:number, filtro: string, idUsuario : number){
    let params = new HttpParams();
    params = params.append('numRegistros', nRegistros.toString() );
    params = params.append('pagina', nPagina.toString() );
    params = params.append('filtro', filtro );
    params = params.append('UsuarioID', idUsuario.toString() );

    return this.http.get<any>(`${environment.apiUrl}/Listado`, { params }).toPromise();
  }

  detalle(EmpresaID : number){
    return this.http.get<any>(`${environment.apiUrl}/${EmpresaID}/DetalleEmpresa`).toPromise();
  }

  consultaEmpresas():Promise<Empresa[]>{
    return this.http.get<Empresa[]>(`${environment.apiUrl}/ObtenerEmpresas`, {}).toPromise();
  }

  getCount(filtro: string){
    let params = new HttpParams();
    params = params.append('filtro', filtro );

    return this.http.get<number>(`${environment.apiUrl}/empresa/Count`, { params });
  }

  guardarEmpresa(empresa: Empresa):Promise<OperationResult<string>> {
    return  this.http.post<OperationResult<string>>(`${environment.apiUrl}/empresa`, empresa).toPromise();
  } 

  post(empresa: Empresa){
    return this.http.post<OperationResult<null>>(`${environment.apiUrl}/empresa`, empresa );
  }

  delete(empresa: Empresa){
    let params = new HttpParams();
    params = params.append('EmpresaID', empresa.EmpresaID.toString() );

    return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/empresa`, { params } );
  }
}
