import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Seccion } from '@app/_models/seccion';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { first } from 'rxjs/operators';
import { PropuestaBecaSolicitudService } from '@app/_services/propuestaBecaSolicitud.service';
import { ResumenPropuesta } from '@app/_models/resumenPropuesta';
import { AnalisisComparativo } from '@app/_models/analisisComparativo';
import { DetalleResumenPropuesta } from '@app/_models/detalleResumenProp';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { Constantes } from '@app/_models/constantes';

@Component({
  selector: 'app-detalle-analisis-becas',
  templateUrl: './detalle-analisis-becas.component.html',
  styleUrls: ['./detalle-analisis-becas.component.scss']
})
export class DetalleAnalisisBecasComponent implements OnInit {

  displayedColumns: string[] = ['concepto', 'cicloant', 'cicloact', 'cicloprop'];
  displayedColumns2: string[] = ['colegiaturas', 'presupuesto', 'monto', 'diferencia'];

  constructor(private formBuilder: FormBuilder, 
    public dialog: MatDialog, 
    private authenticationService: AuthenticationService,
    private propuestaBecaService:PropuestaBecaService,
    private seccionService: SeccionService,
    private propuestaBecaSolicitudService : PropuestaBecaSolicitudService,
    public dialogRef: MatDialogRef<DetalleAnalisisBecasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp,
    public dialogClose: MatDialogRef<DetalleAnalisisBecasComponent>) { }

  propuesta : PropuestaBeca;
  nuevaPropuestaAnalisis: FormGroup;
  idColegio: number;
  idUsuario: number;
  enableBtn = false;
  esperando = false;
  revision = false;
  propuestaEditable : boolean;
  NumeroColegiaturas: number;
  propuestaSolicitudes: PropuestaSolicitud[];
  analisisComparativo : AnalisisComparativo;  
  isAprobador : boolean;
  propuetaSolicitudEditableRev : boolean;
  propuestaSolicitudesEditable : boolean;
  alumnosCutoasCargados = false;
  montoEjercidoSem : number;
  montoPropuestaSem : number;
  montoPropuesta : number;
  diferencia : number;
  becaSEP =0;
  puntosPermitidos =0;
  porcentajeBecaSEP = 0;

  
  secciones: Seccion[] = [];

  ngOnInit(): void {

    this.isAprobador =  this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA")  > -1;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.analisisComparativo = new AnalisisComparativo();
    this.analisisComparativo.cicloActual = new ResumenPropuesta();
    this.analisisComparativo.cicloAnterior = new ResumenPropuesta();
    this.analisisComparativo.cicloPropuesto = new ResumenPropuesta();
    this.analisisComparativo.cicloActual.resumen = new DetalleResumenPropuesta();
    this.analisisComparativo.cicloAnterior.resumen = new DetalleResumenPropuesta();
    this.analisisComparativo.cicloPropuesto.resumen = new DetalleResumenPropuesta();
    
    if(this.data.Operation == 'Revision'){
      this.revision = true
    }

    console.log("Revision " + this.revision);

    this.getSecciones() 

      this.nuevaPropuestaAnalisis = this.formBuilder.group({
        seccion: [''],
        becaSEP: [''],
    });

    this.propuestaBecaService.ObtenerMontoSemestreEjercido(this.propuesta.Centro.CentroID,
      this.propuesta.Ciclo.CicloID).then( res => {
        if(res.Exito){
          this.montoEjercidoSem = res.Dato.CostoAnual;
        }
        this.propuestaBecaService.ObtenerMontoSemestrePropuesta(this.propuesta.Centro.CentroID,
          this.propuesta.Ciclo.CicloID).then( res => {
        if(res.Exito){
          this.montoPropuestaSem = res.Dato.CostoAnual;
        }
        }).catch(err => {
          console.log("Error"+ err);
        });

  }).catch(err => {
    console.log("Error"+ err);
  });   


  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }

  calcular() {
    this.porcentajeBecaSEP  =  this.nuevaPropuestaAnalisis.get('becaSEP').value;  

    var porcentajebecaSEP = this.porcentajeBecaSEP / 100;
    this.becaSEP  = this.analisisComparativo.cicloPropuesto.resumen.CostoColegiaturas * porcentajebecaSEP;
    console.log("becaSEP" + this.becaSEP);
    /*var becaSEP  =  this.nuevaPropuestaAnalisis.get('becaSEP').value;  
    var propuestas  = this.analisisComparativo.cicloPropuesto.resumen.ColegiaturaProyectada * this.analisisComparativo.cicloPropuesto.resumen.Colegiaturas;
    console.log("becaSEP" + becaSEP);
    console.log("propuestas" + propuestas);*/
  }
  
  onSelectSeccion(entrando) {
    if(!entrando){
      console.log("Seleecion seccion...");  

      var SeccionID  =  this.nuevaPropuestaAnalisis.get('seccion').value;  
  
      var SeccStr = "" + SeccionID;

      if(SeccStr != ""){
        this.esperando = true;
        this.enableBtn = false;

        this.propuestaBecaSolicitudService.getAnalisisPropuestaBecas(this.propuesta, SeccionID).pipe(first()).subscribe(
          data => {
            this.analisisComparativo = data;
            if(this.analisisComparativo.cicloPropuesto.resumen.Alumnos != 0){
              this.analisisComparativo.cicloPropuesto.resumen.PorcentajeSobreAlumnos = this.analisisComparativo.cicloPropuesto.resumen.BecasTotal /
              this.analisisComparativo.cicloPropuesto.resumen.Alumnos;
            }
            if( this.analisisComparativo.cicloActual.resumen.Alumnos != 0){
              this.analisisComparativo.cicloActual.resumen.PorcentajeSobreAlumnos = this.analisisComparativo.cicloActual.resumen.BecasTotal /
              this.analisisComparativo.cicloActual.resumen.Alumnos 
            }
            if(this.analisisComparativo.cicloAnterior.resumen.Alumnos  != 0){
              this.analisisComparativo.cicloAnterior.resumen.PorcentajeSobreAlumnos = this.analisisComparativo.cicloAnterior.resumen.BecasTotal /
              this.analisisComparativo.cicloAnterior.resumen.Alumnos 
            }
            this.analisisComparativo.Diferencia = this.analisisComparativo.MontoAprobado - this.analisisComparativo.cicloPropuesto.resumen.CostoBecaAnual;
            this.montoPropuesta = this.montoEjercidoSem + this.montoPropuestaSem;
            this.diferencia = this.analisisComparativo.MontoAprobado - this.montoPropuesta;

            this.recuperaBecaSEP();

            this.propuestaBecaService.getPropuestaBecasCuotas(this.propuesta, SeccionID).pipe(first()).subscribe(
              dataCuota => {
                let colegiaturas = dataCuota.PropuestaCuotas.map(c => {
                  return c.Colegiaturas;
                })
                var maxColegiaturas = Math.max(...colegiaturas);
                var repeat = colegiaturas.filter((e, i, a) => a.indexOf(e) !== i)
                let maxRepeat = Math.max(...repeat);

                let maxNumber = Math.max(maxColegiaturas, maxRepeat);

                this.NumeroColegiaturas = maxNumber;
              },
              error => {
                this.esperando = false;
              }
            ); 
            this.esperando = false;
            this.enableBtn = true;
          },
          error => {
            this.esperando = false;
          }
        );         
      }
    }
  }
  
  getSecciones() {

    if(this.data != null && this.data.Objeto != null){
      this.propuesta = this.data.Objeto;
      var centro = this.propuesta.Centro.CentroID;
      this.seccionService.consultaSecciones(centro).then(secciones => {
        console.log("Secciones " + JSON.stringify(this.secciones));
        this.secciones = secciones;

      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }

  validarCaracteres(e) {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    let letras = "0123456789."
    
    let especiales = [8,13];
    let tecla_especial= false;
    for(var i in especiales)
    {
      if(key == especiales[i])
      {
        tecla_especial = true;
        break;
      }
    }

    if(letras.indexOf(tecla) == -1 && !tecla_especial)
    {
      return false;
    }
  }


  validarDuplicados(e)
  {
    let key = e.keyCode || e.which;
    let tecla =String.fromCharCode(key).toString();
    
    if(tecla == '.'){
      this.puntosPermitidos=this.puntosPermitidos+1;
      if(this.puntosPermitidos>1){
        return false;
      }
    }
  }
  save(){
    this.enableBtn = false;
    this.esperando = true;

    this.propuesta.UsuarioID = this.idUsuario;

    var SeccionID  =  this.nuevaPropuestaAnalisis.get('seccion').value;      

    this.propuesta.PropuestaSolicitudes = this.propuestaSolicitudes;

    if(SeccionID != null){
      this.propuestaBecaSolicitudService.GuardarPropuestaBecasSolicitudes(this.propuesta, SeccionID ).pipe(first()).subscribe(
        
        data => {
          if (data.Exito){
            const dialogData = new ConfirmDialogModel('', "Porcentaje  guardados", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            }); 
          }    
          this.esperando = false;  
          this.enableBtn  = true;   
        },
        error => {
          this.esperando = false;  
        }
      );
    }
    console.log("Saliendo");
  }

  enviar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere enviar la propuesta?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.modificarEstatus(Constantes.PROPUESTA_ENVIADA);
      }
    });    

   
  }

  aprobar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere aprobar la propuesta?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.modificarEstatus(Constantes.PROPUESTA_APROBADA);
      }
    }); 
  }

  revisar(): void {

    const dialogData = new ConfirmDialogModel("Confirmar", '¿Ha revisado la propuesta?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.modificarEstatus(Constantes.PROPUESTA_REVISADA);
      }
    });


  }


  rechazar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere rechazar la propuesta?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.modificarEstatus(Constantes.PROPUESTA_RECHAZADA);
      }
    });
    
  }

  modificarEstatus(EstatusID : number){

    this.propuesta.UsuarioID = this.idUsuario;
    this.propuestaBecaService.actualizarEstausPropuesta(this.propuesta, EstatusID).then( res => {
      if(res.Exito){
        const dialogData = new ConfirmDialogModel('', 'La operación se realizó correctamente', false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

        this.dialogClose.close(true);    
      }else{
        const dialogData = new ConfirmDialogModel('', "Hubo un error al actualizar la propuesta", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });        
      }
    }).catch(err => {
      console.log("Error"+ err);
    });

  }

  guardarBecaSEP(){
    var becaSEP  =  this.nuevaPropuestaAnalisis.get('becaSEP').value; 
    console.log("Porcentaje " + becaSEP);
    this.propuesta.PorcentajeBecaSEP = becaSEP;
    this.propuestaBecaService.GuardarPropuestaBecaSEP(this.propuesta).pipe(first()).subscribe(
      data => {
        if (data.Exito){
          const dialogData = new ConfirmDialogModel('', "Porcentaje guardado", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 
        }else{
          const dialogData = new ConfirmDialogModel('Error al guardar el porcentaje de la propuesta',  data.Mensaje, false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 


        }   
        this.esperando = false;
        this.enableBtn = true;
      },
      error => {
        this.esperando = false;
      }
    );

  }


  recuperaBecaSEP(){

    this.propuestaBecaService.RecuperaPropuestaBecaSEP(this.propuesta).pipe(first()).subscribe(
      data => {
        if (data.Exito){
          var porcentaje  = data.Dato;
          this.nuevaPropuestaAnalisis.get('becaSEP').setValue(porcentaje);
          this.calcular();

        }   
      },
      error => {
        this.esperando = false;
      }
    );


  }


}