export class Redes {

    Telefono: string;
    Correo : string;
    Facebook : string;
    Youtube : string;
    Linkedin : string;
    Instagram : string;
    Pinterest : string;
    Aviso : string;

  }