import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Solicitud } from '@app/_models/solicitud';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class RevisionSolicitudesService {

  constructor(private http: HttpClient) {}

  buscar(nRegistros:number, nPagina:number, cicloID : number, centros : string[],
    regiones : number[],secciones : number[], estatus : number[],nombre : string, UsuarioID: number){
    return this.http.post<any>(`${environment.apiUrl}/RevisionSolicitudes`,  {nRegistros, nPagina, cicloID, centros, regiones, secciones,  estatus, nombre, UsuarioID } );
  }

  obtenerDetalle(SolicitudID : number) {
    return this.http.get<Solicitud>(`${environment.apiUrl}/RevisionSolicitudes/${SolicitudID}/DetalleSolicitud`).toPromise();
  }

  solicitudCompleta(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/RevisionSolicitudes/SolicitudCompleta`, solicitud).toPromise();  
  }

  solicitudIncompleta(solicitud : Solicitud): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/RevisionSolicitudes/SolicitudIncompleta`, solicitud).toPromise();  
  }

}