<mat-card class="seleccion-beca-card">
    <br>
    <div class="example-form">
      <mat-card-title class="text-left fz-26">RENOVACIÓN DE BECAS BACHILLERATO.</mat-card-title>
      <mat-divider></mat-divider>
    </div> 
    <br><br> 
    <div class="mdl-form">
      <mat-label class="color-azul">Filtrar por:</mat-label>
    </div>
    <form  class="mdl-form" name="renovacionBecaForm" id="renovacionBecaForm" [formGroup]="renovacionBecaForm">      
      <mat-form-field  class="inpunt-width xs-width" appearance="outline">
        <mat-label>Ciclos</mat-label>
        <mat-select  name="ciclo" formControlName="ciclo" id="ciclo"  >
          <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
              {{ciclo.Clave}}
          </mat-option> 
        </mat-select>
      </mat-form-field>      
  
      <mat-form-field appearance="outline" class="inpunt-width xs-width" >
        <mat-label>Estatus</mat-label>
        <mat-select name="estatus" formControlName="estatus" id="estatus">
            <mat-option *ngFor="let est of estatusRenovacion" [value]="est.EstatusRenovacionID" > 
              {{est.Nombre}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <br><br>
      <div class="row">
        <button mat-raised-button class="color-btn" class="button" (click)="buscar()"> BUSCAR </button>
      </div>
    </form>
    <br>
    <div class="mdl-form mat-elevation-z8">
      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ciclo">
          <mat-header-cell mat-header-cell *matHeaderCellDef>Ciclo</mat-header-cell>
          <mat-cell  *matCellDef="let element">
            {{element.PropuestaBeca.Ciclo.Clave}} 
          </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="estatus">
          <mat-header-cell mat-header-cell *matHeaderCellDef>Estatus</mat-header-cell>
          <mat-cell *matCellDef="let element"> 
            {{element.EstatusRenovacion.Nombre}} 
          </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="acciones">
            <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
              <div *ngIf="element.EstatusRenovacion.Nombre =='Finalizado' ">
                <button mat-icon-button color="basic" aria-label="Ver" matTooltip="Ver solicitud" matTooltipPosition="right" (click)="verRenovacion(element)">
                  <mat-icon>visibility</mat-icon></button>
              </div>
              <div *ngIf="element.EstatusRenovacion.Nombre =='Pendiente' ">
                <button mat-icon-button color="basic" aria-label="Editar" (click)="renovarCiclo(element)" matTooltip="Editar"  matTooltipPosition="right">
                  <mat-icon>edit</mat-icon></button> 
              </div>        
                <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element)" matTooltip="Historial">
                  <mat-icon>history</mat-icon></button>           
            </mat-cell>
        </ng-container>
      
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>


      
      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
      </div>
      
      <mat-paginator #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 20]"
        (page)="pageEvent = getServerData($event)" >
      </mat-paginator>
    </div> 
    <br>
</mat-card>