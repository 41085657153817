import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Seccion } from '@app/_models/seccion';
import { TipoBecaReqSeccion } from '@app/_models/tipoBecaReqSeccion';
import { TipoBecaReqEspecificaciones } from '@app/_models/TipoBecaReqEspecificaciones';
import { SeccionService } from '@app/_services/seccion.service';
import { TipobecaRequisitoCentroComponent } from '../tipobeca-requisito-centro/tipobeca-requisito-centro.component';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { TipobecaReqSecGraComponent } from '../tipobeca-req-sec-gra/tipobeca-req-sec-gra.component';

@Component({
  selector: 'app-tipobeca-requisito-seccion',
  templateUrl: './tipobeca-requisito-seccion.component.html',
  styleUrls: ['./tipobeca-requisito-seccion.component.scss']
})
export class TipobecaRequisitoSeccionComponent implements OnInit {
  displayedColumnsSecciones: string[] = ['aplicable','Nombre', 'minimo', 'maximo', 'grado'];
  displayedColumns: string[] = ['aplicable','Nombre', 'grado']; 
  dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>();

  secciones: Seccion[] = []; 
  dataTipoBecaSecciones : TipoBecaReqEspecificaciones;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  enableBtn = true;
  tipoRequisito: string = "";
  reglonSeleccionado :TipoBecaReqSeccion;

  constructor(    private seccionService: SeccionService,
    public dialogRef: MatDialogRef<TipobecaRequisitoCentroComponent>,
    public dialog: MatDialog,    
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaSecciones = data.Objeto;
    }

  ngOnInit(): void {
    this.enableBtn = true;
    this.tipoRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.Clave 
    if(this.dataTipoBecaSecciones.BecaRequisitoSecciones == null ||this.dataTipoBecaSecciones.BecaRequisitoSecciones.length == 0){
      this.initSecciones();
    }else{
      this.dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>(this.dataTipoBecaSecciones.BecaRequisitoSecciones);
      this.length = this.dataSourceRequisitoSeccion.data.length;
    }
  }

  initSecciones(){
    this.guardando = true;
    var TipoBecaID = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.TipoBeca.TipoBecaID;
    this.seccionService.consultaSeccionesXTipoBeca(TipoBecaID).then(secciones => {
      this.secciones = secciones;
      this.initTable();
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  initTable(){
    this.dataTipoBecaSecciones.BecaRequisitoSecciones = [];
    
    this.secciones.forEach(
      x => {
        var RequisitoSeccion = new TipoBecaReqSeccion();

        RequisitoSeccion.Seccion = x;
        RequisitoSeccion.TipoBecaRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito;
        RequisitoSeccion.Minimo = this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo;
        RequisitoSeccion.Maximo = this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo;
        RequisitoSeccion.RequisitoGrados = [];
        this.dataTipoBecaSecciones.BecaRequisitoSecciones.push(RequisitoSeccion);
      }
      
    );
    this.dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>(this.dataTipoBecaSecciones.BecaRequisitoSecciones);
    this.length = this.dataSourceRequisitoSeccion.data.length;
    this.guardando = false;
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


  especificacionXGrado(renglon : TipoBecaReqSeccion){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.reglonSeleccionado = renglon;
    renglon.TipoBecaRequisitoSeccionID = this.dataTipoBecaSecciones.TipoBecaRequisito.TipoBecaRequisitoID;
    renglon.TipoBecaRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito;

    if(mCopy.RequisitoGrados == null){
      mCopy.RequisitoGrados = [];
    }
    const dialogRef = this.dialog.open(TipobecaReqSecGraComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosGrados(renglon);
      }else{
        var requisitoBeca =this.dataTipoBecaSecciones.BecaRequisitoSecciones.find(r => r.Seccion.SeccionID  == renglon.Seccion.SeccionID);
        requisitoBeca.RequisitoGrados = mCopy.RequisitoGrados;      
      }
    });
  }

  eliminarEspecificacionXGrado(renglon : TipoBecaReqSeccion){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por grado?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.RequisitoGrados  = [];
      }
    });    
  }


  agregarRequisitosGrados(tipoBecaReqSeccion : TipoBecaReqSeccion){
    this.reglonSeleccionado.RequisitoGrados = tipoBecaReqSeccion.RequisitoGrados;
  }  

  validateMinMax(tipoBecaReq:TipoBecaReqSeccion){
    this.enableBtn = true;
    if(  ( tipoBecaReq.Minimo  >=  this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo  &&   tipoBecaReq.Minimo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)
        && (tipoBecaReq.Maximo  >=   this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo &&   tipoBecaReq.Maximo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)){
      tipoBecaReq.Invalido = false;
    }else{
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }

    if(tipoBecaReq.Maximo < tipoBecaReq.Minimo) {
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }
 }
}
