import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AsignacionBecasExParam } from '@app/_models/asignacion-becas-ex';
import { Solicitud } from '@app/_models/solicitud';
import { ResponseRest } from '@app/_models/responseRest';

@Injectable({ providedIn: 'root' })
export class CargasSevice {

  constructor(private http: HttpClient) {}

  importar(archivos: FormData, TipoCarga: number, UsuarioID  : number) {
    
    let params = new HttpParams();
    params = params.append('TipoCarga', TipoCarga.toString() );
    params = params.append('UsuarioID', UsuarioID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }
    console.log("Llamando")
    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/Carga/CargaRegistros`, archivos, { params } ).toPromise();
  }


  async importarA(archivos: FormData, TipoCarga: number, UsuarioID  : number) {
    let params = new HttpParams();
    params = params.append('TipoCarga', TipoCarga.toString() );
    params = params.append('UsuarioID', UsuarioID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return await this.http.post<ResponseRest<any>> (`${environment.apiUrl}/Carga/CargaRegistros`, archivos, { params } ).toPromise();

  }


  descargarDocumentoSalida(NombreArchivo: string, mimeType: string,  TipoCargaID : number, UsuarioID : number) {
    console.log("Descargar archivo");
    let params = new HttpParams();
    params = params.append('NombreArchivo', NombreArchivo);
    params = params.append('mimeType', mimeType);
    params = params.append('TipoCarga', TipoCargaID.toString());
    params = params.append('UsuarioID', UsuarioID.toString());

    return this.http.get(`${environment.apiUrl}/Carga/DescargaResultado/`, { params, responseType: 'blob' } );
  }  

  cancelarProceso(TipoCargaID: number, UsuarioID : number): Promise<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/Carga/${TipoCargaID}/${UsuarioID}/CancelarProceso` ).toPromise();    
  }

  cargaEnProceso(TipoCargaID: number, UsuarioID : number): Promise<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/Carga/${TipoCargaID}/${UsuarioID}/CargaEnProceso` ).toPromise();    
  }



  porcentajeProcesado(TipoCargaID: number, UsuarioID : number): Promise<number> {
    return this.http.get<number>(`${environment.apiUrl}/Carga/${TipoCargaID}/${UsuarioID}/PorcentajeProcesado` ).toPromise();    
  }

  ultimoProcesado(TipoCargaID: number, UsuarioID : number): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/Carga/${TipoCargaID}/${UsuarioID}/UltimoProcesado` ).toPromise();    
  }


}