import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Sobrepaso } from '@app/_models/sobrepaso';
import { ResponseRest } from '@app/_models/responseRest';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';

@Injectable({
  providedIn: 'root'
})
export class SobrepasoService {

  constructor(private http: HttpClient) { }
  
  buscar(nRegistros:number, nPagina:number, CicloID : number, centros : string[],
    secciones : number[], filtro : string, UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/ListadoSobrepasos`,  { CicloID, centros, secciones,nRegistros, nPagina, filtro, UsuarioID} );   
  }

  guardarSobrepaso(sobrepaso: Sobrepaso){
    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/Sobrepaso`, sobrepaso  ).toPromise();    
  }

  importar(archivos: FormData, SobrepasoID: number) {
    
    let params = new HttpParams();
    params = params.append('SobrepasoID', SobrepasoID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/GuardarDocumento`, archivos, { params } );
  }

  delete(sobrepaso: Sobrepaso){

    let params = new HttpParams();
    params = params.append('SobrepasoID', sobrepaso.SobrepasoID.toString() );

    return this.http.delete<ResponseRest<null>>(`${environment.apiUrl}/Sobrepaso`, { params } );
  }

  descargarDocumento(SobrePasoID: number) {

    let params = new HttpParams();
    params = params.append('SobrePasoID', SobrePasoID.toString());
    
    return this.http.get(`${environment.apiUrl}/DescargaDocSobrepaso/`, { params, responseType: 'blob' } );
}  
}
