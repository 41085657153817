import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalleNuevoExtempComponent } from '../_components/detalle-nuevo-extemp/detalle-nuevo-extemp.component';
import { CentroService } from '@app/_services/centro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { BecasExtemporaneasService } from '@app/_services/beca-extemporanea.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { first } from 'rxjs/operators';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { MatTableDataSource } from '@angular/material/table';
import { HistorialComponent } from '@app/_components/historial/historial.component';
import { saveAs } from 'file-saver';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';

@Component({
  selector: 'app-becas-extemporaneas',
  templateUrl: './becas-extemporaneas.component.html',
  styleUrls: ['./becas-extemporaneas.component.scss']
})
export class BecasExtemporaneasComponent implements OnInit {

  displayedColumns: string[] = ['colegio','seccion', 'id', 'nombre',  'acciones'];
  dataSource = new MatTableDataSource<BecaExtemporanea>();
  revisionSolicitudForm: FormGroup;

  idUsuario: number;

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
 
  centros: Centro[] = [];
  secciones: Seccion[] = [];

  ciclosBecas : Ciclo [];  

  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoCiclos = null; 

  errorMessageP: string = "";
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;

  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private becasExtemporaneasService : BecasExtemporaneasService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private cicloBecaService: CicloBecaService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    this.dataSource.paginator = this.paginator;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.cargarCentros();     
    this.getCiclosBecas();

    this.revisionSolicitudForm = this.formBuilder.group({
      ciclos: ['', Validators.required],
      centros: [''],
      secciones: [''],
      estatus: [''],
      nombre: ['']
    });  
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {

      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  cargarCentros() {
    this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  nuevaBeca(): void{
    const dialogRef = this.dialog.open(DetalleNuevoExtempComponent, {
      width: '80%',
      data: {Operation: 'Nuevo'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  darAcceso(extemporanea: BecaExtemporanea): void {
    const dialogRef = this.dialog.open(DetalleNuevoExtempComponent, {
      width: '80%',
      data: {Operation: 'Editar', Objeto: extemporanea}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }

  verHistorial(extemporanea: BecaExtemporanea){
    const dialogRef = this.dialog.open(HistorialComponent, {
      width: '60%',
      data: {Operation: 'Extemporanea', Objeto: extemporanea}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros = this.revisionSolicitudForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  
  
  buscar() { 
    var ciclos = this.revisionSolicitudForm.get('ciclos').value;  
    var centros   = this.revisionSolicitudForm.get('centros').value;    
    var secciones   = this.revisionSolicitudForm.get('secciones').value; 
    var nombre :string  = this.revisionSolicitudForm.get('nombre').value;  

    if(ciclos == null || ciclos.length == 0){
      console.log("ciclos "+ JSON.stringify(ciclos));
      return;
    }

    this.becasExtemporaneasService.buscar(this.pageSize, (this.pageIndex+1), ciclos, centros, secciones, nombre.trim(), this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<BecaExtemporanea>(data.Dato);
      },
      () => {
        this.errorMessageP = "Error al obtener la lista"; 
      });
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }        

  descargarDoc(extemporanea: BecaExtemporanea){
    this.becasExtemporaneasService.descargarDocumento(extemporanea.BecaExtemporaneaID)
    .subscribe (
      data => {
        saveAs(data, extemporanea.NombreOriginal, {
          type: extemporanea.MimeType
       });
      }, 
      () => {
          const dialogData = new ConfirmDialogModel('Error', "Sucedió un error al descargar los documentos.", false);    
          this.dialog.open(AlertaComponent, {
          maxWidth: "500px",
          data: dialogData
        });
      }
    );
  }  

  omit_special_char(e: any) {
    if (/^[ñÑa-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    
  
  tosslePerOneSecciones(ALL){ 
    if (this.allSelectedSecciones.selected) {  
      this.allSelectedSecciones.deselect();
      return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }  

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }   

  tosslePerOneCentros(ALL){ 
    if (this.allSelectedCentros.selected) {  
      this.allSelectedCentros.deselect();
      return false;
    }

    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 
}
