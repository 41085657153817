<mat-card-title class="card-title fz-20">Nueva Beca</mat-card-title>
<mat-divider></mat-divider>
<br>
<form [formGroup]="detalleExt" class="modal-form" name="detalleExt" id="detalleExt">  

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Ciclo</mat-label>
            <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" (ngModelChange)='verify()' 
            id="ciclo" (openedChange)="fechasxCiclos($event)">
            <mat-option *ngFor="let ciclo of ciclos" [value]="ciclo" > 
                {{ciclo.Clave}}
            </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Colegio</mat-label>
            <mat-select #mySelCentro name="centro" formControlName="centro" id="centro" (ngModelChange)='verify()' (openedChange)="onSelectedColegios($event)">
              <mat-option *ngFor="let centro of centros" [value]="centro.CentroID"> 
                {{centro.Nombre}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>CURP</mat-label>
            <input matInput formControlName="curp" (ngModelChange)="validar()" maxlength="18" required>
        </mat-form-field>
      
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Sección</mat-label>
            <mat-select #mySelSeccion name="seccion" formControlName="seccion" id="seccion">
                <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" (ngModelChange)='verify()'> 
                  {{seccion.Nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <mat-error *ngIf="curpError"> El CURP tiene un formato inválido </mat-error>
    <mat-error *ngIf="noAlumno"> No se encontró información del  alumno para este ciclo </mat-error>
    
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Nombre del alumno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="nombre"   >
        </mat-form-field>
        
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido paterno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="app" >  
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido materno</mat-label>
            <input matInput (ngModelChange)='verify()' (keypress)="name_omit_special_char($event)" formControlName="apm">  
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <input matInput [min]="minDate" [max]="detalleExt.controls.FechaFin.value" 
                name ="FechaIni" placeholder="Fecha de inicio"
                (ngModelChange)='verify()'
            [matDatepicker]="pickerCI" formControlName="FechaIni" style="margin-top: 10px;" >  
            <mat-error *ngIf="detalleExt.controls.FechaIni.invalid">
                Formato de fecha inválida.</mat-error>                                     
            <mat-datepicker-toggle matSuffix [for]="pickerCI"></mat-datepicker-toggle>
            <mat-datepicker #pickerCI></mat-datepicker> 
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <input formControlName="FechaFin" matInput [min]="detalleExt.controls.FechaIni.value" [max]="maxDate" 
            placeholder="Fecha de fin" [matDatepicker]="pickerCF" (ngModelChange)='verify()' style="margin-top: 10px;" >  
            <mat-error *ngIf="detalleExt.controls.FechaFin.invalid">
                Formato de fecha inválida.</mat-error>              
            <mat-datepicker-toggle matSuffix [for]="pickerCF"></mat-datepicker-toggle>
            <mat-datepicker #pickerCF></mat-datepicker>
        </mat-form-field>
    </div>
    <br>
    <div class="row">
        <mat-form-field class="input-full xs-width" appearance="outline">
            <mat-label>Comentarios</mat-label>
            <textarea matInput maxlength="300" formControlName="comentarios" rows="7"></textarea>
        </mat-form-field>
    </div>
            
    <br>
    <label>Evidencia</label>
    <br><br>
    <div class="row">
        <button class="buttonSecondary" mat-raised-button (click)="seleccionarArchivo()"><span class='lato-bold espaciado margin-btn' style="font-size: 90%;">+ CARGAR ARCHIVO</span></button>
        <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
        <input class="hidden" id="upload-file" type="file" accept=".pdf,.doc,.docx"  (change)="adjuntarArchivo($event)">
    </div>
    <mat-error *ngIf="ErrorExt">Extensiones aceptadas: .pdf, .doc</mat-error>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación" >CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="aceptar()" style="margin-left: 1em;" [disabled]="!enableBtn || curpError || guardando" matTooltip="Guardar beca" >GUARDAR</button>
    </div>

    <div *ngIf="error">
        <br>
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>  
</form>   