import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CicloService } from '@app/_services/ciclo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ciclo } from '@app/_models/ciclo';
import { first } from 'rxjs/operators';
import { ObjetoOp } from '@app/_models/objectOperation';
import { SolicitudBecaExterna } from '@app/_models/becaExterna';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { TipoBeca } from '@app/_models/tipoBeca';
import { CentroService } from '@app/_services/centro.service';
import { Centro } from '@app/_models/centro';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { ThrowStmt } from '@angular/compiler';
import { BecasExtemporaneasService } from '@app/_services/beca-extemporanea.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { PropuestaSolicitud } from '@app/_models/propuestaSolicitud';
import { Solicitud } from '@app/_models/solicitud';
import { Alumno } from '@app/_models/alumno';
import { DatosOriginalesService } from '@app/_services/datosOriginales.service';
import { BecaExternaService } from '@app/_services/beca-externa.service';

@Component({
  selector: 'app-detalle-becas-ext',
  templateUrl: './detalle-becas-ext.component.html',
  styleUrls: ['./detalle-becas-ext.component.scss']
})
export class DetalleBecasExtComponent implements OnInit {

  detalleExt: FormGroup;
  disabled_ = true;
  enableBtn = false;
  disableCurp = true;
  option = []

  centros: Centro[] = [];
  tiposBeca: TipoBeca[] = [];
  ciclos: Ciclo[] = [];
  grados: GradoEducativo[] = [];
  secciones: Seccion[] = [];

  guardando = false;
  
  cargandoCentros = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;

  hayErrores = false;
  idUsuario: number;

  archivoCargado = false
  tipoConsulta = false;
  selected = '';
  nombreArchivo = 'Ningún archivo seleccionado' 
  AlumnoPromedio : AlumnoPromedio;
  BecaExterna : SolicitudBecaExterna;

  curpError = false;  
  promedioError = false;
  porcentajeError = false;

  ErrorExt: boolean = false;
  noAlumno = false;
  errorMessage :string;
  error : boolean;
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  
  admision = false;

  constructor(private formBuilder: FormBuilder, 
              public dialogRef: MatDialogRef<DetalleBecasExtComponent>,
              private cicloService: CicloService,
              private becaexternaService : BecaExternaService,
              private becaExtemporaneaService : BecasExtemporaneasService,
              private datosOriginalesService : DatosOriginalesService,
              private seccionService: SeccionService,
              private centroService: CentroService,
              private tipoBecaService : TipoBecaService,
              private gradoService: GradoEducativoService,
              private authenticationService: AuthenticationService,
              public dialog: MatDialog,
            @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) {
    this.BecaExterna = new SolicitudBecaExterna();
    this.BecaExterna.Solicitud = new Solicitud();
    this.BecaExterna.Solicitud.Alumno = new Alumno();
    this.BecaExterna.Solicitud.Ciclo = new Ciclo();
    this.BecaExterna.Solicitud.Centro = new Centro();
    this.BecaExterna.Solicitud.Seccion = new Seccion();
    this.BecaExterna.Solicitud.GradoEducativo = new GradoEducativo();
    this.BecaExterna.Solicitud.TipoBeca = new TipoBeca();
  }
 
  ngOnInit() {
    this.hayErrores = false;
    this.guardando = false;
    this.cargandoCiclos = "(Cargando ...)";
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getCiclos();
    this.cargarCentros();
    this.getTipoBecas();

    this.detalleExt = this.formBuilder.group({
      ciclo: [ '', [Validators.required]],
      curp: [ '', [Validators.required]],
      app: [ '', [Validators.required]],
      apm: [ '', [Validators.required]],
      pacademico: [ ''],
      pconducta: [ ''],
      tipobeca: [ '', [Validators.required]],
      porcentaje: [ '', [Validators.required]],
      centro: [ '', [Validators.required]],
      seccion: [ '', [Validators.required]],
      nombre: [ '', [Validators.required]],
      grado: [ '', [Validators.required]],
      comentarios: [''],
      becaSep: ['']
    });

    this.detalleExt.get('centro').disable();
    this.detalleExt.get('curp').disable();

    if (this.data.Operation === 'Editar') {
      this.enableBtn = true;
      
      this.BecaExterna = this.data.Objeto;
      this.nombreArchivo = this.BecaExterna.NombreOriginal;
      this.fillForm();
      this.detalleExt.get('curp').disable();      
    }
  }
  
  cancelar(): void {
    this.dialogRef.close(false);
  }

  buscarAlumno(curp: string){
    let ciclo = this.detalleExt.get('ciclo').value;
    if(ciclo == null || ciclo == '' || ciclo.value == ''){
        return;
    }

    let centro = this.detalleExt.get('centro').value;
    if(centro == null || centro == '' || centro.value == ''){
        return;
    }

    this.detalleExt.get('nombre').setValue("");
    this.detalleExt.get('app').setValue("");
    this.detalleExt.get('apm').setValue("");
    this.detalleExt.get('seccion').setValue("");
    this.detalleExt.get('pconducta').setValue("");
    this.detalleExt.get('pacademico').setValue("");
    this.detalleExt.get('nombre').enable();
    this.detalleExt.get('app').enable();
    this.detalleExt.get('apm').enable();
    
    this.guardando = true;
    var centros = [];
    var secciones = [];
    console.log("Ciclo " +ciclo);
    console.log("curp " +curp);
    console.log("centro " +centro);
    this.datosOriginalesService.buscarAlumnoActual(curp, centro).then( e => {
     
      this.limpiarCampos();
      
      if(e != null){
        console.log("Resultado: " + console.log(e))        
        if(e.Exito){
          if(e.Mensaje != 'SIN_BUSCAR'){
            this.AlumnoPromedio = e.Dato;
            this.admision = this.AlumnoPromedio.Admision;
            this.detalleExt.get('nombre').setValue(this.AlumnoPromedio.Alumno.Nombre);
            this.detalleExt.get('app').setValue(this.AlumnoPromedio.Alumno.ApellidoPaterno);
            this.detalleExt.get('apm').setValue(this.AlumnoPromedio.Alumno.ApellidoMaterno);
            if(this.AlumnoPromedio.Centro != null){
              centros.push(e.Dato.Centro.CentroID);
              this.detalleExt.get('centro').setValue(this.AlumnoPromedio.Centro.CentroID);
              this.detalleExt.get('centro').disable();
            }

            if(this.AlumnoPromedio.Seccion != null){
              secciones.push(e.Dato.Seccion.SeccionID);
              this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
                this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
                this.cargandoGrados = null;
                this.detalleExt.get('seccion').setValue(this.AlumnoPromedio.Seccion.SeccionID);              
              }).catch(err => {
                this.cargandoGrados = "(Error)";
                console.log("Error" + err);
              }); 
            }

            if(this.AlumnoPromedio.GradoEducativo != null){
              this.detalleExt.get('grado').setValue( this.AlumnoPromedio.GradoEducativo.GradoEducativoID);
            }

            if ( this.AlumnoPromedio.PromedioAcademico == 0){
              this.detalleExt.get('pacademico').setValue('N/A');
            }else{
              this.detalleExt.get('pacademico').setValue( this.AlumnoPromedio.PromedioAcademico);
            }
            if (this.AlumnoPromedio.PromedioDisciplina == 0){
              this.detalleExt.get('pconducta').setValue('N/A');
            }else{
              this.detalleExt.get('pconducta').setValue(this.AlumnoPromedio.PromedioDisciplina);
            }

            this.disableCampos();
            this.enableBtn = true;
          }else{
            this.enableCampos();
            this.enableBtn = true;

          }
        } else{
          this.disableCampos();
          this.showMessage("Error", e.Mensaje);
          this.enableBtn = false;
        }
      }else{
        this.showMessage("Error", "Hubo un error al buscar el alumno");
        this.disableCampos();
        this.enableBtn = false;
      }
      this.guardando = false;
    }).catch(err => {
      console.log("Error" + err);
    });  
  }


  disableCampos(){
    this.detalleExt.get('nombre').disable();
    this.detalleExt.get('app').disable();
    this.detalleExt.get('apm').disable();
    this.detalleExt.get('seccion').disable();
    this.detalleExt.get('grado').disable(); 
    this.detalleExt.get('pconducta').disable();
    this.detalleExt.get('pacademico').disable();

  }

  enableCampos(){
    this.detalleExt.get('nombre').enable();
    this.detalleExt.get('app').enable();
    this.detalleExt.get('apm').enable();
    this.detalleExt.get('seccion').enable();
    this.detalleExt.get('grado').enable(); 
    this.detalleExt.get('pconducta').enable();
    this.detalleExt.get('pacademico').enable();

  }


  aceptar(): void {
    this.guardando = true;
    this.fillBecaExterna();
    this.becaexternaService.guardarbecaexterna(this.BecaExterna).then( res =>{
      if (res.Exito) {
        this.BecaExterna.SolicitudBecaExternaID = res.Dato;
        this.importarDocumentos(res.Dato);

        const dialogData = new ConfirmDialogModel('', "Información guardada", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        
        this.dialogRef.close(true);
      }else{
        this.errorMessage = res.Mensaje ? res.Mensaje : 'Error al guardar';
        this.error = true;       
        this.guardando = false;
      }
    }
    ).catch(err => {
      console.log("Error" + err);
    }).finally(() => {});;
  }

  validarCurp() {
    this.noAlumno = false;
    this.enableBtn = false;
    
    var curp =  this.detalleExt.get('curp').value;
    if(curp != null){
      this.verify()
      curp = curp.toUpperCase();
      this.curpError=false;
      if(curp != null && curp.length > 0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado) {
          this.curpError=true;
        }
        else{
          this.buscarAlumno(curp);
        }
      }
    }
  }  

  validarPromedio() {
    
    var pacademico =  this.detalleExt.get('pacademico').value;
    var pconducta =  this.detalleExt.get('pconducta').value;

    var paca = parseInt(pacademico)
    var pcon = parseInt(pconducta)

    if(paca != null || pcon != null){
      this.promedioError = false;
      if(paca < 0 || paca > 10 || pcon < 0 || pcon > 10){
        this.promedioError = true;
      }
    }
  } 

  validarPorcentaje() {
    
    var porcentaje =  this.detalleExt.get('porcentaje').value;
    var porc = parseInt(porcentaje)

    if(porc != null){
      this.porcentajeError = false;
      if(porc < 0 || porc > 100 ){
        this.detalleExt.get('porcentaje').setValue('100');   
      }
      else{
        this.porcentajeError = false;
      }
    }
  } 

  verify() {
    if (this.detalleExt.controls.ciclo.valid){
      this.detalleExt.get('centro').enable();
    }
    if (this.detalleExt.controls.centro.valid){
      this.detalleExt.get('curp').enable();
    }
    if (this.detalleExt.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }
  }

  getCiclos() {
    this.cicloService.consultaCiclo().then( ciclos => {

      let ciclosActivos = ciclos.filter(c => {
        return new Date(c.FechaFin) >= new Date()
      })
      this.ciclos  = ciclosActivos;

    }).catch(err => {
      console.log("Error" + err);
    });
  }

  cargarCentros() {
    this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  getTipoBecas(){
    this.tipoBecaService.listadoBecasCicloActivoExternas().then(tiposBeca => {
      this.tiposBeca = tiposBeca
      }).catch(err => {
      console.log("Error" + err);
    }); 
  }

  onSelectedColegios(entrando) {
    if(!entrando){
      var centros = [] 
      let centro= this.detalleExt.get('centro').value;  
      this.validarCurp();
      centros.push(centro)
      
      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  } 

  onSelectedSecciones(entrando) {
    if(!entrando){
      var centros = [] 
      var secciones = []
      let centro = this.detalleExt.get('centro').value;  
      centros.push(centro)
      let seccion = this.detalleExt.get('seccion').value;  
      secciones.push(seccion)

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      }); 
    }
  } 

  name_omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  omit_special_char(e: any) {
    if (/^[0-9]+$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  omit_special_char_PC(e: any){
    if (e.charCode == 110 || e.key == "n" || e.charCode == 47 || e.key == "/"
        || e.charCode == 97 || e.key == "a") return false;
    if (/^[0-9]+$/.test(e.key)) {
      return true;
    } else {
        e.preventDefault();
        return false;
    }
  }
  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/pdf" || document.target.files[0].type == "application/docx" || 
    document.target.files[0].type == "application/doc"){
      this.tipoConsulta = true;
      this.archivoCargado = true;
      this.ErrorExt = false;
      this.enableBtn = true;
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.enableBtn = false;
      this.ErrorExt = true;
      this.nombreArchivo = "";
    }
  }

  importarDocumentos(PropuestaBecaSolicitudID: number){ 

    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.enableBtn = false; 
        this.guardando = true;   
        this.becaexternaService.importar(filesFD,PropuestaBecaSolicitudID ).pipe(first())
        .subscribe(data => {
          this.guardando = false;
          this.enableBtn = true;
          if(data.Exito){
            this.dialogRef.close(true);
          }
        });      
        
      }else {
        console.log("Exceso de peso");
        //this.showMessage('Información', "El documento (" + documento.NombreOriginal + ") excede el peso permitido.");
      }
    }else{
      this.dialogRef.close(true);
    }
  }

  deshabilitarCampos(){
    this.detalleExt.get('ciclo').disable();
    this.detalleExt.get('centro').disable();
    this.detalleExt.get('app').disable();
    this.detalleExt.get('apm').disable();
    this.detalleExt.get('nombre').disable();
    this.detalleExt.get('pacademico').disable();
    this.detalleExt.get('pconducta').disable();
    this.detalleExt.get('seccion').disable();
    this.detalleExt.get('grado').disable();
  }

  limpiarCampos(){

    this.detalleExt.get('app').setValue('');
    this.detalleExt.get('apm').setValue('');
    this.detalleExt.get('nombre').setValue('');
    this.detalleExt.get('pacademico').setValue('');
    this.detalleExt.get('pconducta').setValue('');
    this.detalleExt.get('seccion').setValue('');
    this.detalleExt.get('grado').setValue('');
  }

  fillBecaExterna(){

    this.BecaExterna.Solicitud.Ciclo.CicloID =  this.detalleExt.get('ciclo').value;
    this.BecaExterna.Solicitud.Alumno.CURP =  this.detalleExt.get('curp').value;
    this.BecaExterna.Solicitud.Alumno.ApellidoPaterno =  this.detalleExt.get('app').value;
    this.BecaExterna.Solicitud.Alumno.ApellidoMaterno =  this.detalleExt.get('apm').value;
    this.BecaExterna.Solicitud.Alumno.Nombre =  this.detalleExt.get('nombre').value;
    this.BecaExterna.Solicitud.Centro.CentroID =  this.detalleExt.get('centro').value;
    this.BecaExterna.Solicitud.Seccion.SeccionID =  this.detalleExt.get('seccion').value;
    this.BecaExterna.Solicitud.GradoEducativo.GradoEducativoID = this.detalleExt.get('grado').value;
    this.BecaExterna.Solicitud.PromedioActual = this.detalleExt.get('pacademico').value;
    this.BecaExterna.Solicitud.PromedioConducta = this.detalleExt.get('pconducta').value;
    this.BecaExterna.Solicitud.TipoBeca.TipoBecaID =  this.detalleExt.get('tipobeca').value;
    this.BecaExterna.Comentarios = this.detalleExt.get('comentarios').value;
    this.BecaExterna.Porcentaje = this.detalleExt.get('porcentaje').value;
    this.BecaExterna.Solicitud.Admision = this.admision;
    var becaSep = this.detalleExt.get('becaSep').value;
    console.log("Beca sep " + becaSep);
    this.BecaExterna.BecaSep = this.detalleExt.get('becaSep').value;
    this.BecaExterna.UsuarioID = this.idUsuario;
    this.BecaExterna.Solicitud.Admision = this.admision;
  }

  fillForm(){

    this.getCiclos();
    this.cargarCentros();
    this.getTipoBecas();

    if(this.BecaExterna.Solicitud.Seccion != null){
      var centros = [];
      centros.push( this.BecaExterna.Solicitud.Centro.CentroID);
      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;
      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      }); 

      var secciones= [];
      secciones.push( this.BecaExterna.Solicitud.Seccion.SeccionID);

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      }); 
    }

    this.detalleExt.get('ciclo').setValue( this.BecaExterna.Solicitud.Ciclo.CicloID);
    this.detalleExt.get('curp').setValue( this.BecaExterna.Solicitud.Alumno.CURP);
    if(this.BecaExterna.Solicitud.Centro != null){
      this.detalleExt.get('centro').setValue( this.BecaExterna.Solicitud.Centro.CentroID);
    }

    if(this.BecaExterna.Solicitud.Seccion != null){
      this.detalleExt.get('seccion').setValue( this.BecaExterna.Solicitud.Seccion.SeccionID);        
    }
  
    if(this.BecaExterna.Solicitud.GradoEducativo != null){
      this.detalleExt.get('grado').setValue( this.BecaExterna.Solicitud.GradoEducativo.GradoEducativoID);
    }
    if(this.BecaExterna.Solicitud.TipoBeca != null){
      this.detalleExt.get('tipobeca').setValue( this.BecaExterna.Solicitud.TipoBeca.TipoBecaID);
    }

    this.detalleExt.get('nombre').setValue(this.BecaExterna.Solicitud.Alumno.Nombre);
    this.detalleExt.get('app').setValue(this.BecaExterna.Solicitud.Alumno.ApellidoPaterno);
    this.detalleExt.get('apm').setValue(this.BecaExterna.Solicitud.Alumno.ApellidoMaterno);
    this.detalleExt.get('pacademico').setValue(this.BecaExterna.Solicitud.PromedioActual);
    this.detalleExt.get('pconducta').setValue(this.BecaExterna.Solicitud.PromedioConducta);
    this.detalleExt.get('comentarios').setValue(this.BecaExterna.Comentarios);
    this.detalleExt.get('porcentaje').setValue(this.BecaExterna.Porcentaje);
    this.detalleExt.get('becaSep').setValue(this.BecaExterna.BecaSep);

    if(this.BecaExterna.Solicitud.Centro.Colegium){
      this.deshabilitarCampos(); 
    }
  }

  showMessage(title:string, message:string):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }
}
