<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/seleccion-beca" class="circulo bg-celeste">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-generales" class="circulo bg-rosado">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-familiares" class="circulo bg-verde">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/requisitos" class="circulo bg-azul">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/estudio-socioeconomico" class="circulo bg-naranja">
                        <mat-icon class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <br><br><br>
    <mat-card-title class="card-title fz-40">Resumen.</mat-card-title>
    <br>
    <div class="exampleform">
        <p class="text-center color-marino fz-20">Ha completado su proceso de solicitud de beca. Comprobar los datos introducidos
        </p>
    </div>
    <br><br>
    <div class="example-form">
        <div >
          <table style="width: 120%;">
            <label class="color-azul fz-15">Datos del alumno</label>
            <br>
            <tr>
                <td><label class="color-gris fz-14">Nombre del alumno:</label></td>
                <td>
                    <label class="color-marino fz-15">
                        {{nombreAlumno}}
                    </label>
                </td>  
            </tr>
            <tr>
                <td><label class="color-gris fz-14">Sección:</label></td>
                <td>
                    <label class="color-marino fz-15">
                    {{seccion}}
                    </label>
                </td>
            </tr>
            <tr>
                <td><label class="color-gris fz-14">Colegio:</label></td>
                <td>
                    <label class="color-marino fz-15">
                    {{colegio}}
                    </label>
                </td>
            </tr>
            <tr>
              <td><label class="color-gris fz-14">Grado:</label></td>
              <td>
                <label class="color-marino fz-15">
                  {{grado}}
                </label>
              </td>
            </tr>
            <tr>
              <td><label class="color-gris fz-14">CURP:</label></td>
              <td>
                <label class="color-marino fz-15">
                  {{CURP}}
                </label>
              </td>
            </tr>
            <tr>
              <td><label class="color-gris fz-14">Promedio conducta:</label></td>
              <td>
                <label *ngIf="promedioCond != 0" class="color-marino fz-15">
                  {{promedioCond}}
                </label>
                <label *ngIf="promedioCond == 0" class="color-marino fz-15">
                    N/A
                </label>
              </td>
            </tr>
            <tr>
              <td><label class="color-gris fz-14">Promedio académico anterior:</label></td>
              <td>
                <label *ngIf="promedioAnt != 0" class="color-marino fz-15">
                  {{promedioAnt}}
                </label>
                <label *ngIf="promedioAnt == 0" class="color-marino fz-15">
                    N/A
                </label>
              </td>
            </tr>
            <tr>
              <td><label class="color-gris fz-14">Promedio académico actual:</label></td>
              <td>
                <label *ngIf="promedioAct != 0" class="color-marino fz-15">
                  {{promedioAct}}
                </label>
                <label *ngIf="promedioAct == 0" class="color-marino fz-15">
                    N/A
                </label>
              </td>
            </tr>
            <br>
            <label class="color-azul fz-15">Datos del padre</label>
            <br>
            <tr>
              <td *ngIf="auxPadre"><label class="color-gris fz-14">Nombre del padre:</label></td>
              <td *ngIf="auxPadre">
                <label class="color-marino fz-15">
                  {{nombrePadre}}
                </label>
              </td>
            </tr>
            <tr>
                <td *ngIf="auxMadre"><label class="color-gris fz-14">Nombre de la madre:</label></td>
                <td *ngIf="auxMadre">
                  <label class="color-marino fz-15">
                    {{nombreMadre}}
                  </label>
                </td>
            </tr>

            <tr>
            <td *ngIf="auxTutor"><label class="color-gris fz-14">Nombre del tutor:</label></td>
            <td *ngIf="auxTutor">
                <label class="color-marino fz-15">
                {{nombreTutor}}
                </label>
            </td>
            </tr>
            <br>
            <label class="color-azul fz-15">Datos de la beca</label>
            <br>
            <tr>
              <td><label class="color-gris fz-14">¿Es renovación?</label></td>
              <td>
                <div>
                  <div *ngIf="EsRenovacion">
                    <label class="color-marino fz-15">Sí</label><br>
                  </div>
                  <div *ngIf="!EsRenovacion">
                    <label class="color-marino fz-15">No</label><br>
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr  style="background-color: whrite;">
              <td><label class="color-gris fz-14">Motivo de solicitud:</label></td>
              <td>
                <label class="color-marino fz-15">
                  {{Solicitud.MotivoSolicitud}}
                </label>
              </td>
            </tr> -->
            <tr  style="background-color: whrite;">
              <td><label class="color-gris fz-14">Tipo de beca:</label></td>
              <td>
                <label class="color-marino fz-15">
                  {{tipoBeca}}
                </label>
              </td>
            </tr>
            <br>
            <div >


            </div>

            <div *ngIf="requiereEstudio">
              <label class="color-azul fz-15">Datos de la empresa</label>
            </div>
            
            <br>
            <tr *ngIf="requiereEstudio">
              <td ><label class="color-gris fz-14">Nombre de la empresa:</label></td>
              <td>
                <label class="color-marino fz-15">
                  {{nombreEmpresa}}
                </label>
              </td>
            </tr>
            <br>
            <tr *ngIf="requiereEstudio"  style="background-color: whrite;">
                <td><label class="color-gris fz-14">Costo estudio socioeconómico:</label></td>
                <td>
                  <label class="color-marino fz-15">
                    {{costo}}
                  </label>
                </td>
              </tr>
          </table> 
        </div>
      </div>
    <!-- <div class="resumenTable">
        <table class="mat-tb mat-elevation-z8">

        <tr>
            <td class="color-azul">Tipo de beca:</td>
            <td class="color-marino">{{tipoBeca}}</td>
        </tr>
        <tr>
            <td class="color-azul">Nombre del Alumno:</td>
            <td class="color-marino">{{nombreAlumno}}</td>
        </tr>
        <tr>
            <td class="color-azul">costo estudio socioeconómico:</td>
            <td class="color-marino">{{costo}}</td>
        </tr>
        <tr>
            <td class="color-azul">Sección:</td>
            <td class="color-marino">{{seccion}}</td>
        </tr>
        <tr>
            <td class="color-azul">Colegio:</td>
            <td class="color-marino">{{colegio}}</td>
        </tr>
        <tr>
            <td class="color-azul">Grado:</td>
            <td class="color-marino">{{grado}}</td>
        </tr>
        <tr *ngIf="auxPadre">
            <td class="color-azul">Nombre del padre:</td>
            <td class="color-marino">{{nombrePadre}}</td>
        </tr>
        <tr *ngIf="auxMadre">
            <td class="color-azul">Nombre de la madre:</td>
            <td class="color-marino">{{nombreMadre}}</td>
        </tr>
        <tr *ngIf="auxTutor">
            <td class="color-azul">Nombre de tutor:</td>
            <td class="color-marino">{{nombreTutor}}</td>
        </tr>
        </table> 
    </div> -->
    <br><br>
    <br><br><br>
    <div class="btn-div margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" style="margin-right: 1rem;"
            matTooltip="Guardar y volver al menú principal"  (click)="save()"  mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" 
            (click)="enviarSolicitud()" [disabled]="guardando" mat-raised-button>ENVIAR SOLICITUD</button>
    </div>
</mat-card>