<!-- INICIO seccion del select y el boton de cancelar -->
<div class="example-form">
  <form class="modal-form" name="nuevaPropuestaResumenB" id="nuevaPropuestaResumenB" [formGroup]="nuevaPropuestaResumenB" autocomplete="off">
    <div *ngIf="!alumnosCutoasCargados" class="mat-aviso" >
      Deber cargar el conteo de alumnos y cuotas para la propuesta
    </div>
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Sección</mat-label>
      <mat-select  name="seccion" formControlName="seccion" id="seccion" [disabled]='!alumnosCutoasCargados'  (openedChange)="onSelectSeccion($event)" >
        <mat-option  [value]="0"><label>Todos</label></mat-option>                      
          <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
            {{seccion.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   
  </form>
  <div class="modal-form margin-div">
    <button mat-raised-button color="danger" (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
  </div>
</div> 
<!-- FIN seccion del select y el boton de cancelar -->  
<br><br>
<!-- INICIO de la primera tabla dinamica que muestra los totales -->
<div class="example-form mat-elevation-z8 table-responsive">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 200%;">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumnsD1">
      <th mat-header-cell *matHeaderCellDef class="w-100 bg-green bl bt br"> {{column.title | uppercase}} </th>        
      <td mat-cell *matCellDef="let element" data-label="column.name" class="bg-blue">
        <div *ngIf="column.field === 'BecasTotal' || column.field === 'BecasNuevas' || column.field === 'BecasRenovacion'">
          {{element[column.field] | number : '1.2-2'}}
        </div>  
        <div *ngIf="column.field === 'tipoBeca'">
          {{element.TipoBecas[column.index].Conteo | number : '1.2-2'}}
        </div>            
        <div *ngIf="  column.field != 'BecasTotal' &&   column.field != 'BecasNuevas' 
            && column.field != 'BecasRenovacion'  && column.field != 'tipoBeca' ">

            <div *ngIf="column.isCurrency">
              {{element[column.field] | currency}}
            </div>
            <div *ngIf="!column.isCurrency && !column.isDecimal">
              {{element[column.field] | number : '1.0-0'}}
            </div> 
            <div *ngIf="column.isDecimal">
              {{ (element[column.field]*100) | number : '1.2-2'}}%
            </div>              
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table >

  <div *ngIf="dataSource.data.length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar, seleccione una sección para buscar.</span>
    <br><br>
  </div>
</div>
<!-- FIN de la primera tabla dinamica que muestra los totales -->
<br><br>
<!-- INICIO de la segunda tabla dinamica que muestra las secciones y tiene opcion de desplegar -->
<div class="example-form mat-elevation-z8 table-responsive">
  <table mat-table [dataSource]="dataSource2" multiTemplateDataRows style="width: 160%;">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumnsD2">
      <th mat-header-cell *matHeaderCellDef class="w-100 bg-green bl bt br"> {{column.title | uppercase}} </th>
      <td mat-cell *matCellDef="let element" data-label="column.name" class="bg-blue"> 
        <div *ngIf="column.field === 'Seccion'">
          {{element.Seccion.Nombre}}
        </div>      
        
        <div *ngIf="column.field === 'BecasTotal' ||   column.field === 'BecasNuevas' || column.field === 'BecasRenovacion'">
          {{element.resumen[column.field] | number : '1.2-2'}}
        </div>  

        <div *ngIf="column.field === 'tipoBeca'">
          {{element.resumen.TipoBecas[column.index].Conteo | number : '1.2-2'}}
        </div>            
      
        <div *ngIf="column.field != 'BecasTotal' && column.field != 'BecasNuevas' && column.field != 'BecasRenovacion' && column.field != 'tipoBeca'">
            <div *ngIf="column.isCurrency">
              {{element.resumen[column.field] | currency}}
            </div>
            <div *ngIf="!column.isCurrency && !column.isDecimal">
              {{element.resumen[column.field] | number : '1.0-0'}}
            </div> 
            <div *ngIf="column.isDecimal">
              {{ (element.resumen[column.field]*100) | number : '1.2-2'}}%
            </div>      
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay2.length">
          <div class="example-element-detail example-form mat-elevation-z8 table-responsive"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

            <table mat-table [dataSource]="element.detalleTable" class="mat-elevation-z8" style="width: 160%;">
              <ng-container [matColumnDef]="column.name" *ngFor="let column of displayedColumnsD3">
                <th mat-header-cell *matHeaderCellDef class="w-100 bg-green bl bt br"> {{column.title | uppercase}} </th>
                <td mat-cell *matCellDef="let element2"> 
                  <div *ngIf="column.field === 'Seccion'">
                    {{element2.Seccion.Nombre}}
                  </div>
                        
                  <div *ngIf="column.field === 'Grado'">
                    {{element2.GradoEducativo.Nombre}}
                  </div>

                  <div *ngIf="column.field === 'BecasTotal' ||   column.field === 'BecasNuevas' || column.field === 'BecasRenovacion'">
                    {{element2[column.field] | number : '1.2-2'}}
                  </div>  

                  <div *ngIf="column.field === 'tipoBeca'">
                    {{element.resumen.TipoBecas[column.index].Conteo | number : '1.2-2'}}
                  </div>  

                  <div *ngIf="  column.field != 'BecasTotal' &&   column.field != 'BecasNuevas' && column.field != 'BecasRenovacion'  && column.field != 'tipoBeca' ">
                    <div *ngIf="column.isCurrency">
                      {{element2[column.field] | currency}}
                    </div>
                    <div *ngIf="!column.isCurrency && !column.isDecimal">
                      {{element2[column.field] | number : '1.0-0'}}
                    </div> 
                    <div *ngIf="column.isDecimal">
                      {{(element2[column.field]*100) | number : '1.2-2'}}%
                    </div>
                  </div>
                </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay3"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay3;"></tr>
            </table>
          </div>
        </td>
    </ng-container>      
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay2"></tr> 
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay2;"
    class="example-element-row" [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>

  <div *ngIf="dataSource2.data.length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar, seleccione una sección para buscar.</span>
    <br><br>
  </div>
</div>
<br>
<div class="loading-screen-icon" *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>
<br><br><br><br>