import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DetallePropuestaBecasComponent } from '@app/_components/detalle-propuesta-becas/detalle-propuesta-becas.component';
import { Seccion } from '@app/_models/seccion';
import { AuthenticationService } from '@app/_services/authentication.service';
import { SeccionService } from '@app/_services/seccion.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { MatTableDataSource } from '@angular/material/table';
import { CommonModule, CurrencyPipe} from '@angular/common';
import { PropuestaBecaService } from '@app/_services/propuesta-beca.service';
import { first } from 'rxjs/operators';
import { ConfirmDialogModel, AlertaComponent } from '../alerta/alerta.component';
import { PropuestaCuota } from '@app/_models/propuesta-cuota';

@Component({
  selector: 'app-detalle-cuotas-becas',
  templateUrl: './detalle-cuotas-becas.component.html',
  styleUrls: ['./detalle-cuotas-becas.component.scss']
})
export class DetalleCuotasBecasComponent implements OnInit {

 displayedColumns: string[] = [
    'grado', 
    'seccion', 
    'colegiatura', 
    'incremento', 
    'colegiaturapro', 
    'reincripcion', 
    'increm', 
    'reincripcionpr',
    'inscripcionAnual', 
    'incrementoAnual',
    'inscPropuesta',
    'ncolegiatura'];

transactions = [
    {item: 'Beach ball', cost: 4},
    {item: 'Towel', cost: 5}
  ];

  dataSource = new MatTableDataSource<PropuestaCuota>();
  constructor(public dialog: MatDialog, 
    public dialogClose: MatDialogRef<DetalleCuotasBecasComponent>,
    private formBuilder: FormBuilder, 
    private authenticationService: AuthenticationService, 
    private currencyPipe : CurrencyPipe,
    private seccionService: SeccionService,
    private propuestaBecaService:PropuestaBecaService,
    public dialogRef: MatDialogRef<PropuestaBecaService>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp,) { }

  propuesta : PropuestaBeca;
  nuevaPropuestaCuota: FormGroup;
  idColegio: number
  idUsuario: number;
  enableBtn = false;
  revicion = false;
  esperando = false;
  propuestaEditable : boolean;
  isDecimal: boolean = false;

  porcentajeMax = false;
  porcentajeMin = false;
  secciones: Seccion[] = [];

  usAmount = 100;
  
  ngOnInit(): void {
    this.getSecciones() 
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    
    if(this.data.Operation == 'Revision'){
      this.revicion = true
      this.propuesta = this.data.Objeto;
      this.nuevaPropuestaCuota = this.formBuilder.group({
        seccion: [''],
      });
    }
    if(this.data.Operation == 'Editar'){  
      this.enableBtn  = true;          
    }
    this.nuevaPropuestaCuota = this.formBuilder.group({
        seccion: [''],
    });
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
 
    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }
  
  siguiente(): void {
    this.dialogClose.close();
    const dialogRef = this.dialog.open(DetallePropuestaBecasComponent, {
      width: '90%',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      //
    });
  }

  save(){
    this.enableBtn = false;
    this.esperando = true;

    this.propuesta.UsuarioID = this.idUsuario;

    var SeccionID  =  this.nuevaPropuestaCuota.get('seccion').value;      
    console.log("SeccionID " + SeccionID);    
    if(SeccionID != null){
      console.log("SeccionID 2 " + SeccionID);    
      this.propuestaBecaService.GuardarPropuestaBecasCuotas(this.propuesta, SeccionID ).pipe(first()).subscribe(
        data => {
          if (data.Exito){
            const dialogData = new ConfirmDialogModel('', "Cuotas guardadas", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            }); 
          }   
          this.esperando = false;  
          this.enableBtn  = true;  
        },
        error => {
          this.esperando = false;  
          this.enableBtn  = true;  
        }
      );
    }
  }

  onSelectSeccion(entrando) {
    if(!entrando){
     
      var SeccionID  =  this.nuevaPropuestaCuota.get('seccion').value;  
      var SeccStr = "" + SeccionID;

      if(SeccStr != ""){
        this.esperando = true;
        this.enableBtn = false;

        this.propuestaBecaService.getPropuestaBecasCuotas(this.propuesta, SeccionID).pipe(first()).subscribe(
          data => {
            this.propuesta.PropuestaCuotas = data.PropuestaCuotas;
            this.propuesta.PropuestaCuotas.forEach(element => {
              this.calculaPropuesta(element, '');
            });
            
            this.dataSource = new MatTableDataSource<PropuestaCuota>(data.PropuestaCuotas);
            this.esperando = false;
            this.enableBtn = true;
          },
          error => {
            this.esperando = false;
          }
        );        
      }
    }
  }

  getSecciones() {

    var Todos = new Seccion();
    Todos.SeccionID = 0;
    Todos.Nombre = "Todos";

    if(this.data != null && this.data.Objeto != null){
      this.propuesta = this.data.Objeto;
      var centro = this.propuesta.Centro.CentroID;
      this.seccionService.consultaSecciones(centro).then(secciones => {
        this.secciones = secciones;
        this.secciones.splice( 0, 0, Todos );        

      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }

  formatNumber(n) {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  formatPorcentaje(input, blur) {
    var input_val = input.value ? input.value : input;
    input_val = parseFloat(input_val).toFixed(2)+"%";
    return input.value ? input.value = input_val: input = input_val;
  }

  calculaPropuesta(element :PropuestaCuota, input){
    
    if(input.name == 'element.Colegiatura'){
      element.Colegiatura = Number(input.value.toString().replace(/[^0-9\.-]+/g,""));
    }
    if(input.name == 'element.Inscripcion'){
      element.Inscripcion = Number(input.value.toString().replace(/[^0-9\.-]+/g,""));
    }
    if(input.name == 'element.Reinscripcion'){
      element.Reinscripcion = Number(input.value.toString().replace(/[^0-9\.-]+/g,""));
    }
    
    element.PorcentajeIncCol = Number(element.PorcentajeIncCol.toString().replace(/[^0-9\.-]+/g,""));
    element.PorcentajeIncIns = Number(element.PorcentajeIncIns.toString().replace(/[^0-9\.-]+/g,""));
    element.PorcentajeIncReinscripcion = Number(element.PorcentajeIncReinscripcion.toString().replace(/[^0-9\.-]+/g,""));

    if(element.PorcentajeIncCol < 1 || element.PorcentajeIncIns < 1 || element.PorcentajeIncReinscripcion < 1){
      this.porcentajeMin = true;
    }else{this.porcentajeMin = false;}
    if(element.PorcentajeIncCol > 100  || element.PorcentajeIncIns > 100  || element.PorcentajeIncReinscripcion > 100 ){
      this.porcentajeMax = true;
    }else{this.porcentajeMax = false;}

    element.ColegiaturaPropuesta = element.Colegiatura * (1 + (element.PorcentajeIncCol/100));
    element.InscripcionPropuesta = element.Inscripcion * (1 + (element.PorcentajeIncIns/100));
    element.ReinscripcionPropuesta = element.Reinscripcion * (1 + (element.PorcentajeIncReinscripcion/100));

  }

  validarPorcentaje(e) {
    let porcentaje = e;
    if (e.includes('%')){
      porcentaje = e.slice(0, -1);
    }

    let porc = parseInt(porcentaje)
    if (porc == NaN) porc = 0
    
    if(porc != null){
      if(porc < 1 ){
        this.porcentajeMin = true;
      }else{this.porcentajeMin = false;}
      if(porc > 100 ){
        this.porcentajeMax = true;
      }else{this.porcentajeMax = false;}
    }
  }

  verify() {
    if(this.data.Operation == 'Revision') return

    if(this.nuevaPropuestaCuota.value.seccion >= 0 )
    {
      this.enableBtn = true;
      return
    }
  }

  validarColegiatura(e){
    e == "" ? this.enableBtn = false : this.enableBtn = true;
  }

  numberOnly(e){
    if (/^[0-9]*$/.test(e.key) || e.charCode == 46 || e.key == ".") {
      return true;
    } 
    else {
      e.preventDefault(); 
      return false;
    }
  }

  updateUSAmount(event) {
    event.target.value = event.target.value;
  }
}