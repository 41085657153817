import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { PropuestaBeca } from '@app/_models/propuesta-beca';
import { OperationResult } from '@app/_models/operationResult';
import { Historial } from '@app/_models/historial';
import { AvancePropuesta } from '@app/_models/avancePropuesta';
import { MontosPropuesta } from '@app/_models/montosPropuesta';
import { ResponseRest } from '@app/_models/responseRest';
import { EstatusPropuesta } from '@app/_models/estatusPropuesta';

@Injectable({ providedIn: 'root' })
export class PropuestaBecaService {

    constructor(private http: HttpClient) {}

    listadoPropuestas(nRegistros:number, nPagina:number, filtro:string,  UsuarioID: number){
        return this.http.post<any>(`${environment.apiUrl}/PropuestaBecas`,  {nRegistros, nPagina, filtro, UsuarioID } );
    }

    buscarRevisionPropuestas(nRegistros:number, nPagina:number, cicloID : number, centros : string[],
        regiones : number[], estatus : number[],  isAprobacion : boolean, UsuarioID : number){
            if(isAprobacion){
                return this.http.post<any>(`${environment.apiUrl}/GetAprobacionPropuestaBecas`,  {nRegistros, nPagina, cicloID, centros, regiones, estatus, UsuarioID } );

            }else{
                return this.http.post<any>(`${environment.apiUrl}/GetRevisionPropuestaBecas`,  {nRegistros, nPagina, cicloID, centros, regiones, estatus, UsuarioID } );
            }
    }

    getPropuestaBecasAlumnos(propuesta :PropuestaBeca, SeccionID  :number){
        return this.http.post<PropuestaBeca>(`${environment.apiUrl}/GetPropuestaBecasAlumnos`,  {propuesta, SeccionID } );
    }    

    getPropuestaBecasCuotas(propuesta: PropuestaBeca, SeccionID  :number){
        return this.http.post<PropuestaBeca>(`${environment.apiUrl}/GetPropuestaBecasCuotas`,   {propuesta, SeccionID }  );
    }

    getPropuestaBecas(propuesta :PropuestaBeca, SeccionID  :number){
        return this.http.post<PropuestaBeca>(`${environment.apiUrl}/GetPropuestaBecasAlumnos`,  {propuesta, SeccionID } );
    }    

    permitirCreacionPropuesta(propuesta: PropuestaBeca){
        return this.http.post<any>(`${environment.apiUrl}/PermitirCreacionPropuesta`,  propuesta );
    }

    crearComplementoPropuesta(propuesta: PropuestaBeca){
        return this.http.post<any>(`${environment.apiUrl}/CrearComplementoPropuesta`,  propuesta );
    }
    
    post(propuesta: PropuestaBeca){
        return this.http.post<any>(`${environment.apiUrl}/PropuestaBecasGenerales`,  propuesta );
    }

    GuardarPropuestaBecasAlumnos(propuesta: PropuestaBeca, SeccionID  :number){
        return this.http.post<any>(`${environment.apiUrl}/GuardaPropuestaBecasAlumnos`,   {propuesta, SeccionID }  );
    }

    GuardarPropuestaBecasCuotas(propuesta: PropuestaBeca, SeccionID  :number){
        console.log("SeccionID " + SeccionID);    
        return this.http.post<any>(`${environment.apiUrl}/GuardaPropuestaBecasCuotas`,   {propuesta, SeccionID }  );
    }

    ObtenerHistorial(ComplementoPropuestaID : number){
        return this.http.get<Historial[]>(`${environment.apiUrl}/${ComplementoPropuestaID}/HistorialPropuesta`).toPromise();
    }

    delete(propuesta: PropuestaBeca){
        let params = new HttpParams();
        params = params.append('PropuestaBecaID', propuesta.PropuestaBecaID.toString() );
    
        return this.http.delete<OperationResult<null>>(`${environment.apiUrl}/PropuestaBeca`, { params } );
    }  
    
    ObtenerMontosPropuesta(CentroID : string, CicloID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/MontosPropuesta`).toPromise();
    }
    
    ObtenerMontosPropuestaXSeccion(CentroID : string, CicloID : number, SeccionID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/${SeccionID}/MontosPropuesta`).toPromise();
    }


    ObtenerMontoSemestreEjercido(CentroID : string, CicloID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/MontoSemestreEjercido`).toPromise();
    }

    ObtenerMontoSemestrePropuesta(CentroID : string, CicloID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/MontoSemestrePropuesta`).toPromise();
    }

    ObtenerMontosExternasComplemento(CentroID : string, CicloID : number, SeccionID : number, ComplementoID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/${SeccionID}/${ComplementoID}/MontosExternasComplelentos`).toPromise();
    }


    ObtenerMontosSemestrePropuestaComple(CentroID : string, CicloID  : number, ComplementoID : number){
        return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/PropuestaBeca/${CentroID}/${CicloID}/${ComplementoID}/MontosSemExternasComplementos`).toPromise();
    }


    actualizarEstausPropuesta(propuesta : PropuestaBeca, estatus :number): Promise<ResponseRest<string>> {
        propuesta.EstatusPropuesta = new EstatusPropuesta();
        propuesta.EstatusPropuesta.EstatusPropuestaID = estatus;
        return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/PropuestaBeca/ActualizarEstatus`, propuesta).toPromise();
    }

    propuestaEditable( ComplementoPropuestaID : number){
        return this.http.get<ResponseRest<boolean>>(`${environment.apiUrl}/${ComplementoPropuestaID}/PropuestaEditable` ).toPromise();
    }   

    propuestaSolicitudesEditable( ComplementoPropuestaID : number, esRevision :boolean, esAprobacion: boolean){
        return this.http.get<ResponseRest<boolean>>(`${environment.apiUrl}/${ComplementoPropuestaID}/${esRevision}/${esAprobacion}/PropuestaSolicitudesEditable` ).toPromise();
    }


    GuardarPropuestaBecaSEP(propuesta: PropuestaBeca){
        return this.http.post<any>(`${environment.apiUrl}/GuardarPropuestaBecaSEP`,  propuesta );
    }

    RecuperaPropuestaBecaSEP(propuesta: PropuestaBeca){
        return this.http.post<any>(`${environment.apiUrl}/RecuperaPropuestaBecaSEP`,  propuesta );
    }
}