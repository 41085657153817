import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjetoOp } from '@app/_models/objectOperation';

@Component({
  selector: 'app-detalle-extemporaneas',
  templateUrl: './detalle-extemporaneas.component.html',
  styleUrls: ['./detalle-extemporaneas.component.scss']
})
export class DetalleExtemporaneasComponent implements OnInit {

  detalleExtForm: FormGroup;
  enableBtn = false;
  guardando = false;
  minDate: Date;
  maxDate: Date;

  fechaError = false;
  error: boolean = false;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DetalleExtemporaneasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {

    this.detalleExtForm = this.formBuilder.group({
      comentarios: ['', Validators.required],
      FechaIni : ['',Validators.required],
      FechaFin : ['',Validators.required],
    });
  }

  onSubmit(){
    this.error = false;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  verify() {
    if(!this.detalleExtForm.invalid)
    {
      this.enableBtn = true;
      return true 
    }
    else{
      this.enableBtn = false;
    }
  }
}
