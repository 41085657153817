import {
  MatTableDataSource
} from "@angular/material/table";
import {
  PropuestaSolicitud
} from "@app/_models/propuestaSolicitud";
import {
  first
} from "rxjs/operators";

export function onSelectSeccion(entrando) {
  var SeccionID = this.nuevaPropuesta.get('seccion').value;
  var SeccStr = "" + SeccionID;

  if (SeccStr != "") {
    this.esperando = true;
    this.enableBtn = false;
    this.propuestaSolicitudes = [];
    this.dataSource = new MatTableDataSource < PropuestaSolicitud > (this.propuestaSolicitudes);

    this.propuestaBecaSolicitudService.getPropuestaBecasSolicitud(this.propuesta, SeccionID).pipe(first()).subscribe(
      data => {
        this.propuestaSolicitudes = data;

        this.propuestaSolicitudes.forEach(element => {
          this.propuestaSolicitudesDetalle.push(element);
          element.detalleTable = new MatTableDataSource < PropuestaSolicitud > (this.propuestaSolicitudesDetalle);
          this.propuestaSolicitudesDetalle = []
        });

        this.dataSource = new MatTableDataSource < PropuestaSolicitud > (this.propuestaSolicitudes);
        this.enableBtn = this.propuestaSolicitudesEditable || this.propuetaSolicitudEditableRev;
      },
      error => {},
      () => {
        this.esperando = false;
      }
    );

    
  }
}
