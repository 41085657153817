import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class RevisionRequisitosService {

  constructor(private http: HttpClient) {}

  buscar(nRegistros:number, nPagina:number, cicloID: number, centros : string[],
    regiones : number[],secciones : number[], grados : string[],nombre : string, UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/RevisionRequisitosListado`,  {nRegistros, nPagina, cicloID, centros, regiones, secciones,  grados, nombre, UsuarioID } );
  }

  download(SolicitudID: number, CURP : string) {

    let params = new HttpParams();
    params = params.append('SolicitudID', SolicitudID.toString());
    params = params.append('CURP', CURP);    
    
    return this.http.get(`${environment.apiUrl}/RevisionRequisitos`, { params, responseType: 'blob' } );
  }
}