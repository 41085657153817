<mat-card-title class="text-center fz-20 color-marino">SOLICITUD DE BECA.</mat-card-title>
<mat-divider></mat-divider> 
<br>
<div class="example-form">
  <label class="color-azul fz-15">Datos del alumno</label>
  <br>
  <div class="resumenTable">
    <table>
      <tr>
        <td><label class="color-gris fz-14">Nombre del alumno:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.Alumno.Nombre}} {{Solicitud.Alumno.ApellidoPaterno}}  {{Solicitud.Alumno.ApellidoMaterno}}
          </label>
      </td>  
      </tr>
      <tr>
        <td><label class="color-gris fz-14">Grado:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.GradoEducativo.Nombre}}
          </label>
        </td>
      </tr>
      <tr>
        <td><label class="color-gris fz-14">CURP:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.Alumno.CURP}}
          </label>
        </td>
      </tr>
      <tr>
        <td><label class="color-gris fz-14">Promedio académico actual:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.PromedioActual}}
          </label>
        </td>
      </tr>
      <tr>
        <td><label class="color-gris fz-14">Promedio académico anterior:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.PromedioAnterior}}
          </label>
        </td>
      </tr>
      <tr>
        <td><label class="color-gris fz-14">Promedio de conducta actual:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.PromedioConducta}}
          </label>
        </td>
      </tr>
      <br>
      <label class="color-azul fz-15">Datos del padre</label>
      <br>
      <tr>
        <td><label class="color-gris fz-14">Nombre del padre:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Responsable.Nombre}} {{Responsable.ApellidoPaterno}} {{Responsable.ApellidoMaterno}}
          </label>
        </td>
      </tr>
      <br>
      <label class="color-azul fz-15">Datos de la beca</label>
      <br>
      <tr>
        <td><label class="color-gris fz-14">¿Es renovación?</label></td>
        <td>
          <div>
            <div *ngIf="Solicitud.EsRenovacion">
              <label class="color-marino fz-15">Sí</label><br>
            </div>
            <div *ngIf="!Solicitud.EsRenovacion">
              <label class="color-marino fz-15">No</label><br>
            </div>
          </div>
        </td>
      </tr>
      <tr  style="background-color: whrite;">
        <td><label class="color-gris fz-14">Motivo de solicitud:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.MotivoSolicitud}}
          </label>
        </td>
      </tr>
      <tr  style="background-color: whrite;">
        <td><label class="color-gris fz-14">Tipo de beca:</label></td>
        <td>
          <label class="color-marino fz-15">
            {{Solicitud.TipoBeca.Nombre}}
          </label>
        </td>
      </tr>
    </table> 
  </div>
</div>
<br><br>
<div class="text-center">
  <button class="buttonSecondary" (click)="cancelar()" mat-raised-button>CANCELAR</button>
  <button class="button" [disabled]="!Solicitud.SolicitudEditable" (click)="incompleta()" mat-raised-button>SOLICITUD INCOMPLETA</button>
  <button class="button" [disabled]="!Solicitud.SolicitudEditable" (click)="completa()" mat-raised-button>SOLICITUD COMPLETA</button>
</div>
