<mat-card-title class="card-title fz-20">NUEVO REQUISITO</mat-card-title>
<mat-divider></mat-divider>
<mat-divider></mat-divider>
<br><br><br>
<form class="modal-form" name="detalleReqForm" id="detalleReqForm" [formGroup]="detalleReqForm" autocomplete="off">

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Clave</mat-label>
        <input matInput #input type="text" maxlength="20" (input)='verify()' formControlName="clave" required
        oninput="this.value = this.value.toUpperCase()" (keypress)="omit_special_char($event)" onPaste="return false">
        <mat-hint *ngIf="input.value?.length == 20">Max 20 caracteres</mat-hint>
    </mat-form-field>
    <br>
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Nombre</mat-label> 
        <input matInput #inputN type="text" maxlength="100" (input)='verify()' formControlName="nombre" (keypress)="validarCaracteres($event)" required>
        <mat-hint *ngIf="inputN.value?.length == 100">Max 100 caracteres</mat-hint>
    </mat-form-field>
    <br>
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Descripción</mat-label>
        <input matInput #inputD type="text" maxlength="200" (input)='verify()' formControlName="descripcion" (keypress)="validarCaracteresDescripcion($event)" required>
        <mat-hint *ngIf="inputD.value?.length == 200">Max 200 caracteres</mat-hint>
    </mat-form-field>
    <br>
    <mat-label class="color-marino">¿Requiere especificación?</mat-label>
    <br><br>
    <mat-radio-group formControlName="isEspecificacion" (ngModelChange)='verify()' required (change)="onChange($event)" aria-label="Requiere especificación">
        <mat-radio-button class="margin-right" value="1" >SI</mat-radio-button>
        <mat-radio-button value="2">NO</mat-radio-button>
    </mat-radio-group>
    <br><br>
    <div *ngIf="isEspecificacion">
        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label class="color-marino">Seleciona una opción</mat-label>
            <mat-select  id="especificacion" name="especificacion" (ngModelChange)='verify()' formControlName="especificacion"  (openedChange)="onSelectTipoEspecificacion($event)" required>
                <mat-option *ngFor="let especificacion of especificaciones" [value]="especificacion.EspecificacionRequisitoID">
                  {{especificacion.Nombre}}
                </mat-option>
              </mat-select>
        </mat-form-field>
    
        <div class="row" *ngIf="especificacionSelected.RequiereExtension">
            <mat-form-field class="input-full-width xs-width" appearance="outline">
                <mat-label class="color-marino">Extensión permitida</mat-label>
                <input matInput #inputE type="text" maxlength="50" (input)='verify()' formControlName="ext" 
                (keypress)="acept_char($event)" onPaste="return false">
                <mat-hint *ngIf="inputE.value?.length == 50">Max 50 caracteres</mat-hint>
            </mat-form-field>
        </div>
    
        <div class="row" *ngIf="especificacionSelected.RequiereRango">
            <mat-label class="color-marino">Límite de rangos:</mat-label> 
            <br>
            <mat-form-field class="xs-width" appearance="outline">
                <mat-label></mat-label>
                <input matInput type="number" oninput="this.value = Math.abs(this.value)" min="0" (input)='verify()' formControlName="desde">
            </mat-form-field>
            <label class="margin-right">a</label>
            <mat-form-field class="xs-width" appearance="outline">
                <mat-label></mat-label>
                <input matInput type="number" oninput="this.value = Math.abs(this.value)" [min]="detalleReqForm.controls.desde.value" (input)='verify()' formControlName="hasta">
            </mat-form-field>
        </div>
        <mat-hint *ngIf="detalleReqForm.controls.desde.value > detalleReqForm.controls.hasta.value">
            El límite de rangos es inválido.
        </mat-hint>
    </div>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="onNoClick()" matTooltip="Cancelar la creación">CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="guardando || !enableBtn || detalleReqForm.controls.desde.value > detalleReqForm.controls.hasta.value" matTooltip="Guardar usuario">GUARDAR</button>
    </div>
    <div *ngIf="error">
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>
</form>