// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://localhost:44356/api', 
  //apiUrl: '/v1.0/api',
  //office365 QA
  //office365_cliente : 'ec5a2e3e-d2f0-4920-a6fb-30ac7d5c0a45',
  //office365_inclino : '31127095-bc15-43fe-a046-e81438386dc7',
  //apiUrl: 'https://becas-back-semperaltus-dev.azurewebsites.net/api',  
  //apiUrl: 'https://becas-back-semperaltus-qab.azurewebsites.net/api',  

  //office365 PROD
 office365_cliente : 'a4da1137-81d7-4d98-b06a-f0fe4302875f',
 office365_inclino : '31127095-bc15-43fe-a046-e81438386dc7',
 apiUrl: 'https://becas-back-semperaltus.azurewebsites.net/api',  


  gaKey: 'UA-XXXXXXXXX-X'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
