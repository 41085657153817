import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { EstatusPropuesta } from '@app/_models/estatusPropuesta';
import { EstatusEstudio } from '@app/_models/estatusEstudio';

@Injectable({ providedIn: 'root' })
export class EstatusEstudioService {

    constructor(private http: HttpClient) {
    }

    consultarEstatusEstudio():Promise<EstatusEstudio[]>{
        return this.http.get<EstatusEstudio[]>(`${environment.apiUrl}/EstatusEstudio`).toPromise();
    }



}