import { AlertaComponent, ConfirmDialogModel } from "@app/_components/alerta/alerta.component";
import { first } from "rxjs/operators";

export function save(){
  this.enableBtn = false;
  this.esperando = true;

  this.propuesta.UsuarioID = this.idUsuario;

  var SeccionID  =  this.nuevaPropuesta.get('seccion').value;      

  this.propuestaSolicitudes.forEach(p => {
    delete p.detalleTable;
  })
  
  this.propuesta.PropuestaSolicitudes = this.propuestaSolicitudes;
  if(SeccionID != null){
    this.propuestaBecaSolicitudService.GuardarPropuestaBecasSolicitudes(this.propuesta, SeccionID ).pipe(first()).subscribe(
      
      data => {
        if (data.Exito){
          this.onSelectSeccion(false);
          const dialogData = new ConfirmDialogModel('', "Porcentaje  guardados", false);    
          const dialogRef = this.dialog.open(AlertaComponent, {
            maxWidth: "400px",
            data: dialogData
          }); 

          this.esperando = false;  
          this.enableBtn  = true;
        }       
      },
      error => {
        this.esperando = false;  
      }
    );
  }
}