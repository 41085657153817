import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseRest } from '@app/_models/responseRest';
import { environment } from '@environments/environment';
import { Alumno } from '@app/_models/alumno';
import { MensajeAyuda } from '@app/_models/mensajeAyuda';


@Injectable({
    providedIn: 'root'
  })
export class AlumnoService {

  constructor(private http: HttpClient) { }

  public crearCuentaAlumno(alumno: Alumno): Promise<ResponseRest<string>> {
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/CuentaAlumno`, alumno).toPromise();
  }


  public mensajeAyuda(mensaje: MensajeAyuda): Promise<ResponseRest<string>> {
    console.log("SErviocio");
    return this.http.post<ResponseRest<string>>(`${environment.apiUrl}/CuentaAlumno/MensajeAyuda`, mensaje).toPromise();
  }
}