<mat-card class="seleccion-beca-card">

    <br><br><br>
    <mat-card-title class="card-title fz-40">Crear cuenta de empresa.</mat-card-title>
    <br>
    <div class="exampleform">
        <p class="text-center color-marino fz-20">A continuación se debe enviar 
            un correo en donde se le informa que su cuenta ha sido creada y un link para crear su contraseña.
        </p>
    </div>
    <br><br>
    <div class="exampleform">
        
        <mat-card-title class="color-azul">Mensaje para la empresa.</mat-card-title>
        <br><br>
        <mat-form-field class="inpunt-width xs-width" appearance="fill">
            <mat-label>Asunto*:</mat-label>
            <input matInput name="asunto" formControlName="asunto" required>
        </mat-form-field>
        <br><br>
        <mat-form-field class="inpunt-width xs-width" appearance="fill">
            <mat-label>Correo*:</mat-label>
            <input matInput name="correEmpresa" formControlName="correEmpresa" required>
        </mat-form-field>
        <br><br>
        <mat-form-field class="inpunt-width xs-width" appearance="fill">
            <mat-label>Link*:</mat-label>
            <input matInput name="linkContrasena" formControlName="linkContrasena" required>
        </mat-form-field>
        <br><br><br>
        <div class="margin-div margin-top">
            <button class="buttonSecondary" style="margin-right: 1rem;" mat-raised-button>ENVIAR</button>
        </div>
    </div>
    <br><br><br><br><br><br>
    <div class="btn-div margin-div">
        <button class="button" mat-raised-button>CANCELAR</button>
        <button class="button" [routerLink]="['/conclusion-beca']" routerLinkActive="router-link-active"  mat-raised-button>CONTINUAR</button>
    </div>
</mat-card>