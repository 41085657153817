<mat-card-title class="card-title fz-20">COMENTARIO</mat-card-title>
<mat-divider></mat-divider>
<br><br>
<mat-form-field class="example-full-width xs-width margin-top" appearance="fill">
  <mat-label>Comentarios:</mat-label>
  <textarea matInput [(ngModel)] ="Solicitud.Comentarios" rows="8"></textarea>
</mat-form-field>

<div class="modal-form margin-div">
  <button mat-raised-button class="buttonSecondary" (click)="onNoClick()">CANCELAR</button>
  <button mat-raised-button class="button"  (click)="onConfirm()">GUARDAR</button>
</div>