import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { Historial } from '@app/_models/historial';
import { ObjetoOp } from '@app/_models/objectOperation';
import { RenovacionBeca } from '@app/_models/renovacionBeca';
import { Sobrepaso } from '@app/_models/sobrepaso';
import { HistorialService } from '@app/_services/historial.service';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent implements OnInit {


  displayedColumns: string[] = ['Fecha', 'Descripcion', 'Usuario'];
  
  dataSource = new MatTableDataSource<Historial>();
  esperando = false;
  length:number = 0

  constructor(public dialogClose: MatDialogRef<HistorialComponent>,
              private historialService:HistorialService,
              @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {


    var tipo = this.data.Operation;
    var id : number;
    var tipoID : number;

    console.log("tipo " + tipo);

    if(tipo == 'Sobrepaso'){
      var sobrepaso: Sobrepaso = this.data.Objeto;
      id = sobrepaso.SobrepasoID;
      tipoID = 1;
    }else  if(tipo == 'Extemporanea'){   
      var extemporanea: BecaExtemporanea = this.data.Objeto;
      id = extemporanea.BecaExtemporaneaID;
      tipoID = 2;
    }else  if(tipo == 'Renovacion'){   
      console.log("id " + JSON.stringify(this.data.Objeto));
      id = this.data.Objeto;
      tipoID = 3;
      console.log("Renovacion " + tipoID + " id "+ id);
    }

   

    this.historialService.ObtenerHistorial(tipoID, id).then(
      historial => {
        this.esperando = false;
        this.dataSource = new MatTableDataSource<Historial>(historial);
        this.length = historial.length;
        console.log("Conteo " + this.length);
      }
    ).catch( err => {
      console.log("Error " + err);
    });
  }

  onNoClick(): void {
    this.dialogClose.close();
  }



}
