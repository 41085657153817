import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { first } from 'rxjs/operators';
import { ModificacionBecaSolicitud} from '@app/_models/modificacionBecaSolicitud';
import { EstatusModificacionBeca } from '@app/_models/estatusModificacionBeca';
import { Solicitud } from '@app/_models/solicitud';
import { HistorialSolicitudComponent } from '@app/_components/historial-solicitud/historial-solicitud.component';
import { DetalleRenovacionBecaComponent } from '@app/_components/detalle-renovacion-beca/detalle-renovacion-beca.component';
import { EstatusRenovacionService } from '@app/_services/estatusRenovacion.service';
import { EstatusRenovacion } from '@app/_models/estatusRenovacion';
import { MatTableDataSource } from '@angular/material/table';
import { BecaOtorgada } from '@app/_models/becaOtorgada';
import { HistorialRenovacionBecaComponent } from '@app/_components/historial-renovacion-beca/historial-renovacion-beca.component';
import { RenovacionBeca } from '@app/_models/renovacionBeca';
import { RenovacionBecaService } from '@app/_services/renovacionBeca.service';
import { HistorialComponent } from '@app/_components/historial/historial.component';


@Component({
  selector: 'app-renovacion-beca',
  templateUrl: './renovacion-beca.component.html',
  styleUrls: ['./renovacion-beca.component.scss']
})
export class RenovacionBecaComponent implements OnInit {
  displayedColumns: string[] = ['ciclo','estatus', 'acciones'];

  dataSource = new MatTableDataSource<RenovacionBeca>();

  renovacionBecaForm: FormGroup;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  estatusRenovacion: EstatusRenovacion[] = [];
  ciclosBecas : Ciclo [];  

  cargandoEstatus = null;  
  cargandoCiclos = null;

  idUsuario: number;
  
  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;
  
  filtro: string = "";
  
  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private cicloBecaService: CicloBecaService,
    private estatusService: EstatusRenovacionService,
    private renovacionService : RenovacionBecaService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    console.log("Renovacion beca");
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getCiclosBecas();    
    this.obtenerEstatus();
    
    this.renovacionBecaForm = this.formBuilder.group({
      ciclo: [''],
      estatus: [''],
    });  
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  renovarCiclo(renovacionBeca: RenovacionBeca): void {
    const dialogRef = this.dialog.open(DetalleRenovacionBecaComponent, {
      width: '95%',
      data: {Operation: 'Editar', Objeto: renovacionBeca}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }


  verRenovacion(renovacionBeca: RenovacionBeca): void {
    const dialogRef = this.dialog.open(DetalleRenovacionBecaComponent, {
      width: '95%',
      data: {Operation: 'Ver', Objeto: renovacionBeca}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.buscar();
      }
    });
  }


  obtenerEstatus() {
    this.estatusService.consultarEstatus().then(estatus => {
      this.estatusRenovacion = estatus;
      this.cargandoEstatus = null;
    }).catch(err => {
      this.cargandoEstatus = "(Error)";
      console.log("Error" + err);
    });
  }



  verHistorial(renovacionBeca: RenovacionBeca){
    console.log("Renovacion beca " + renovacionBeca.RenovacionBecaID);
    const dialogRef = this.dialog.open(HistorialComponent, {
      width: '60%',
      data: {Operation: 'Renovacion', Objeto: renovacionBeca.RenovacionBecaID}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }  



  buscar() { 
    if ( this.renovacionBecaForm.invalid) {
      return
    }
    var cicloID = this.renovacionBecaForm.get('ciclo').value;  
    var estatusRenovacion = this.renovacionBecaForm.get('estatus').value;  
    console.log("Ciclo " + cicloID + " estatus "+ estatusRenovacion);

    if(cicloID == null || cicloID == ''){
      cicloID = 0;
    }
    if(estatusRenovacion == null || estatusRenovacion == ''){
      estatusRenovacion = 0;
    }

    console.log("Ciclo " + cicloID + " estatus "+ estatusRenovacion);

    this.renovacionService.getList(this.pageSize, (this.pageIndex+1),cicloID, estatusRenovacion, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<RenovacionBeca>(data.Dato);
      },
      () => {
      });
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

 
}
