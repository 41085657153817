<mat-card class="mat_card">         
    <mat-card-title class="card-title fz-40">Contacto</mat-card-title>
    <br/>
    <div class="col center">
        <table>
            <tr><td>Red de Colegios Semper Altius</td></tr>
            <tr><td>Oficina Central: Av. Universidad Anáhuac #46, Col. Lomas Anáhuac, Huixquilucan, EM 52786</td></tr>
            <br>
            <tr><td>Teléfono: <a  href="tel:55 5950 0160">55 5950 0160</a></td></tr>
            <tr><td>Correo: <a href="mailto:info@rcsa.mx"><label>info@rcsa.mx</label></a></td></tr>
        </table>
  </div>
</mat-card>
<br><br>