import { Empresa } from './empresa';



export class EstudioEmpresa{

    EstudioEmpresaID: number;
    Costo : number;
    TiempoEntrega : number;
}
