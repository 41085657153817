<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste-claro">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-celeste">
                        <h2>1</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>2</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>3</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>4</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>5</h2>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <br>
    <br>
    <br>
    <mat-card-title class="card-title fz-40">Selección de beca</mat-card-title>
    <br>
    <br>
    <form class="example-form" name="seleccionBeca" id="seleccionBeca" [formGroup]="seleccionBeca">  
        <div class="example-form">
            <div class="row">
                <mat-form-field class="seleccion-width xs-width" appearance="fill">
                    <mat-label>Ciclo:</mat-label>
                    <mat-select formControlName="ciclo" (ngModelChange)="onSelectedCiclo()" required>
                      <mat-option *ngFor="let c of ciclos" [value]="c.CicloID">
                        {{c.Clave}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
             
                <mat-form-field class="seleccion-width xs-width" appearance="fill">
                    <mat-label>Tipo Beca:</mat-label>
                    <mat-select formControlName="tipobeca" (ngModelChange)="onSelectedBeca()" required>
                      <mat-option *ngFor="let c of tiposBeca" [value]="c.TipoBecaID">
                        {{c.Nombre}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="existeBeca" class="text-center margin-top">
                    <span class="color-obscuro">No existen becas disponibles</span>
                </div>   
            </div>
            <div class="row">
                <div class="radios-mg">
                    <label class="color-azul">¿Es renovación?: *</label><br><br>
                    <mat-radio-group class="margin-top" aria-label="Seleccione una opcion" required  formControlName="renovacion"  name = "renovacion">
                        <mat-radio-button style="margin-right: 1rem;" value="1"><label>Si</label></mat-radio-button>
                        <mat-radio-button value="0"><label>No</label></mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <mat-form-field class="example-full-width xs-width margin-top" appearance="fill">
                <mat-label>Motivo de solicitud de beca (Máximo 500 caractéres):</mat-label>
                <textarea matInput name="motivo" formControlName="motivo" rows="8" maxlength="500" required></textarea>
            </mat-form-field>
            <br>
            <div class="row">
                <mat-form-field *ngIf="mostrarPromedioAcademico" class="inpunt-width xs-width" appearance="fill">
                    <mat-label>Promedio académico anterior:</mat-label>
                    <input matInput name="promedioAnt" formControlName="promedioAnt"  
                        (keypress)="numberOnly($event)" maxlength="4" (ngModelChange)="validarPromAcademicoAnt()" >
                </mat-form-field>
        
                <mat-form-field *ngIf="mostrarPromedioAcademico" class="inpunt-width xs-width" appearance="fill">
                    <mat-label>Promedio académico actual:</mat-label>
                    <input matInput name="promedioAct" formControlName="promedioAct"
                    (keypress)="numberOnly($event)" maxlength="4" (ngModelChange)="validarPromAcademicoAct()">
                </mat-form-field>

                <mat-form-field *ngIf="mostrarPromedioDisciplina"  class="inpunt-width xs-width" appearance="fill">
                    <mat-label>Promedio de conducta actual:</mat-label>
                    <input matInput name="promedioCond" formControlName="promedioCond"
                    (keypress)="numberOnly($event)" maxlength="4" (ngModelChange)="validarPromAcademicoCond()">
                </mat-form-field>
                <br>   
                <br>   
                <div class="row">
                    <mat-hint *ngIf="promedioAcademicoAntErr || promedioAcademicoActErr || promedioConductaErr">El promedio debe ser un número válido menor a 10</mat-hint>               
                </div>                
            </div>
        </div>
    </form>
    <br> <br><br>
    <div class="btn-div margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" 
            (click)="guardar()" matTooltip="Guardar y volver al menú principal" mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }"
            (click)="guardarContinuar()" [disabled]="promedioConductaErr || promedioAcademicoActErr || promedioAcademicoAntErr || guardando" mat-raised-button>SIGUIENTE</button>
    </div>
</mat-card>