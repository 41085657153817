import { Injectable } from '@angular/core';
import { Centro } from '@app/_models/centro';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { Alumno } from '@app/_models/alumno';
import { SolicitudService } from './solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { AlumnoBecaActual } from '@app/_models/alumnoBecaActual';


@Injectable({ providedIn: 'root' })
export class SeleccionBecaService {

    constructor(private http: HttpClient) {
    }


  obtenerSeleccionBeca(SolicitudID : number): Promise<Solicitud> {

    var promise =  this.http.get<Solicitud>(`${environment.apiUrl}/SeleccionBeca/${SolicitudID}/ObtenerSeleccionBeca`).toPromise();
    console.log("Prom: " + promise);    
    return promise;
  }
    

  recuperaPromedio(CicloID : number, AlumnoID : number){
    return this.http.get<AlumnoPromedio>(`${environment.apiUrl}/${CicloID}/${AlumnoID}/RecuperaPromedio` ).toPromise();

  } 
  
  recuperaBecaActual(CicloID : number, AlumnoID : number){
    return this.http.get<AlumnoBecaActual>(`${environment.apiUrl}/${CicloID}/${AlumnoID}/RecuperaBecaActual` ).toPromise();

  } 

}