import { Alumno } from './alumno';
import { Centro } from './centro';
import { Ciclo } from './ciclo';
import { Seccion } from './seccion';

export class BecaExtemporanea {

    BecaExtemporaneaID: number;
    Comentarios: string;
    FechaInicio : Date;
    FechaFin : Date;   
    NombreOriginal : string;
    MimeType : string;
    NombreFisico : string;
    Archivo_: File;
    
    Alumno : Alumno;
    Centro : Centro;
    Ciclo : Ciclo; 
    Seccion : Seccion;
    
    UsuarioID : number;
    TieneArchivo : boolean;
}
  