import { Injectable } from '@angular/core';
import { Region } from '@app/_models/region';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';


@Injectable({ providedIn: 'root' })
export class RegionService {

    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }
    getAll():Promise<Region[]>{
        return this.http.get<Region[]>(`${environment.apiUrl}/Region/GetAll`).toPromise();
    }
    
    getXUsuario(UsuarioID: number):Promise<Region[]>{
        return this.http.get<Region[]>(`${environment.apiUrl}/Region/${UsuarioID}/ObtenerXUsuario`).toPromise();
    }

}