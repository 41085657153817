import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CicloService } from '@app/_services/ciclo.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ciclo } from '@app/_models/ciclo';
import { first } from 'rxjs/operators';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Centro } from '@app/_models/centro';
import { Seccion } from '@app/_models/seccion';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { BecasExtemporaneasService } from '@app/_services/beca-extemporanea.service';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { Alumno } from '@app/_models/alumno';
import { DatosOriginalesService } from '@app/_services/datosOriginales.service';

@Component({
  selector: 'app-detalle-nuevo-extemp',
  templateUrl: './detalle-nuevo-extemp.component.html',
  styleUrls: ['./detalle-nuevo-extemp.component.scss']
})
export class DetalleNuevoExtempComponent implements OnInit {

  detalleExt: FormGroup;
  disabled_ = true;
  enableBtn = false;
  disableCurp = true;
  option = []
  minDate: Date;
  maxDate: Date;

  centros: Centro[] = [];
  secciones: Seccion[] = [];
  ciclos: Ciclo[] = [];

  guardando = false;

  cargandoCiclos = null;
  cargandoCentros = null;
  cargandoSecciones = null;

  hayErrores = false;
  idUsuario: number;

  archivoCargado = false
  tipoConsulta = false;
  selected = '';
  nombreArchivo = 'Ningún archivo seleccionado'
  AlumnoPromedio : AlumnoPromedio;

  ErrorExt: boolean = false;
  curpError = false;  
  noAlumno = false;
  errorMessage :string;
  error : boolean;
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  
  extemporanea : BecaExtemporanea;
  colegium = false;

  constructor(private formBuilder: FormBuilder, 
              public dialogRef: MatDialogRef<DetalleNuevoExtempComponent>,
              private cicloService: CicloService,
              private extemporaneaService : BecasExtemporaneasService,
              private datosOriginalesService : DatosOriginalesService,
              private centroService: CentroService,
              private seccionService: SeccionService,
              private authenticationService: AuthenticationService,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }
 
  ngOnInit() {
    this.hayErrores = false;
    this.guardando = false;
    this.cargandoCiclos = "(Cargando ...)";
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.cargarCentros();
    this.getCiclos();

    this.detalleExt = this.formBuilder.group({
      ciclo: [ '', [Validators.required]],
      curp: [ '', [Validators.required]],
      centro: [ '', [Validators.required]],
      seccion: [ '', [Validators.required]],
      nombre: ['',[Validators.required]],
      app: [ '', [Validators.required]],
      apm: [ ''],
      FechaIni: [ '', [Validators.required]],
      FechaFin: [ '', [Validators.required]],
      comentarios: ['', [Validators.required]],
    });

    this.detalleExt.get('centro').disable();
    this.detalleExt.get('curp').disable();

    this.cargarSecciones();

    if (this.data.Operation === 'Editar') {
      this.enableBtn = true;
      this.extemporanea = this.data.Objeto;

      this.nombreArchivo = this.extemporanea.NombreOriginal;
      this.detalleExt.get('seccion').setValue(this.extemporanea.Seccion.SeccionID);
      this.detalleExt.get('curp').setValue(this.extemporanea.Alumno.CURP);
      this.detalleExt.get('nombre').setValue(this.extemporanea.Alumno.Nombre);
      this.detalleExt.get('app').setValue(this.extemporanea.Alumno.ApellidoPaterno);
      this.detalleExt.get('apm').setValue(this.extemporanea.Alumno.ApellidoMaterno);
      this.detalleExt.get('comentarios').setValue(this.extemporanea.Comentarios);
      this.detalleExt.get('FechaIni').setValue(this.extemporanea.FechaInicio);
      this.detalleExt.get('FechaFin').setValue(this.extemporanea.FechaFin);
      this.detalleExt.get('centro').setValue(this.extemporanea.Centro.CentroID);
      this.detalleExt.get('ciclo').setValue(this.extemporanea.Ciclo.CicloID);

      this.detalleExt.get('ciclo').disable();
      this.detalleExt.get('curp').disable();
      this.detalleExt.get('centro').disable();
      this.detalleExt.get('ciclo').disable();
      this.enableBtn = true;
      //this.verify();
    }
    else {
      this.extemporanea = new BecaExtemporanea();
      this.extemporanea.Centro= new Centro();
      this.extemporanea.Seccion = new Seccion();
      this.extemporanea.Alumno = new Alumno();
      this.disableCurp = false;
    }    
  }
  
  cancelar(): void {

    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    

  }

  cargarSecciones(){
    if(this.data.Operation == 'Nuevo'){
      return
    }
    this.seccionService.consultaSecciones(this.data.Objeto.Centro.CentroID).then(secciones => {
      this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
      this.cargandoSecciones = null;
      if(this.extemporanea.Seccion != null){
        this.detalleExt.get('seccion').setValue(this.extemporanea.Seccion.SeccionID);
      }

    }).catch(err => {
      this.cargandoSecciones = "(Error)";
      console.log("Error" + err);
    });
  }

  buscarAlumno(curp: string){
    let ciclo = this.detalleExt.get('ciclo').value;
    if(ciclo == "" || ciclo == null || ciclo.Ciclo == ''){
      return;
    }
    let centro = this.detalleExt.get('centro').value;
    if(centro == "" || centro == null || centro == '' || centro.value == ''){
      return;
    }

    this.detalleExt.get('nombre').setValue("");
    this.detalleExt.get('app').setValue("");
    this.detalleExt.get('apm').setValue("");
    this.detalleExt.get('nombre').enable();
    this.detalleExt.get('app').enable();
    this.detalleExt.get('apm').enable();
    this.detalleExt.get('seccion').enable();
    
    this.guardando = true;
    this.datosOriginalesService.buscarAlumnoActual( curp,centro).then( e => {
      if(e != null){
        if(e.Exito){
          if(e.Mensaje != 'SIN_BUSCAR'){
            this.AlumnoPromedio = e.Dato;
            this.detalleExt.get('nombre').setValue(this.AlumnoPromedio.Alumno.Nombre);
            this.detalleExt.get('app').setValue(this.AlumnoPromedio.Alumno.ApellidoPaterno);
            this.detalleExt.get('apm').setValue(this.AlumnoPromedio.Alumno.ApellidoMaterno);
            this.detalleExt.get('seccion').setValue(this.AlumnoPromedio.Seccion.SeccionID);
            this.detalleExt.get('nombre').disable();
            this.detalleExt.get('app').disable();
            this.detalleExt.get('apm').disable();
            this.detalleExt.get('seccion').disable();

          }
        } else{
          this.detalleExt.get('nombre').disable();
          this.detalleExt.get('app').disable();
          this.detalleExt.get('apm').disable();
          this.detalleExt.get('seccion').disable();
          this.showMessage("Error", e.Mensaje);
        }
      }
      console.log("Invalida " + this.detalleExt.invalid);
      this.guardando = false;
    }).catch(err => {
      console.log("Error" + err);
    });  
  }

  aceptar(): void {
    this.guardando = true;
    
    this.extemporanea.Ciclo = this.detalleExt.get('ciclo').value;
    this.extemporanea.Centro.CentroID = this.detalleExt.get('centro').value;
    this.extemporanea.Seccion.SeccionID = this.detalleExt.get('seccion').value;
    this.extemporanea.Comentarios = this.detalleExt.get('comentarios').value;
    this.extemporanea.Alumno.CURP = this.detalleExt.get('curp').value;
    this.extemporanea.Alumno.Nombre = this.detalleExt.get('nombre').value;
    this.extemporanea.Alumno.ApellidoPaterno = this.detalleExt.get('app').value;
    this.extemporanea.Alumno.ApellidoMaterno = this.detalleExt.get('apm').value;
    this.extemporanea.FechaInicio = this.detalleExt.get('FechaIni').value;
    this.extemporanea.FechaFin = this.detalleExt.get('FechaFin').value;

    this.extemporanea.UsuarioID = this.idUsuario;
    
    this.extemporaneaService.guardarExtemporanea( this.extemporanea).then( res =>{
      if (res.Exito) {
        this.extemporanea.BecaExtemporaneaID = res.Dato;
        this.importarDocumentos(res.Dato);

      }else{
        this.errorMessage = res.Mensaje;
        this.error = true;       
        this.guardando = false;
      }
    }
    ).catch(err => {
      console.log("Error" + err);
    }).finally(() => {});;
  }

  validar() {
    this.noAlumno = false;
    
    var curp =  this.detalleExt.get('curp').value;
    if(curp != null){
      curp = curp.toUpperCase();
      this.curpError=false;
      if(curp != null && curp.length >0){
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado =curp.match(re);
        if (!validado) {
          this.curpError=true;
        }
        else{
          this.buscarAlumno(curp);
        }
      }
    }
  }  

  verify() {
    if (this.detalleExt.controls.ciclo.valid){
      this.detalleExt.get('centro').enable();
    }
    if (this.detalleExt.controls.centro.valid){
      this.detalleExt.get('curp').enable();
    }
    if (this.detalleExt.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }
    console.log("Verificando" + this.enableBtn);
  }

  getCiclos() {
    this.cicloService.consultaCiclo().then( ciclos => {
   
      let ciclosActivos = ciclos.filter(c => {
        return new Date(c.FechaFin) >= new Date()
      })
      this.ciclos  = ciclosActivos;

      if (this.data.Operation === 'Editar') {
        var ciclo = this.ciclos.find(x => x.CicloID == this.extemporanea.Ciclo.CicloID);
        this.detalleExt.get('ciclo').setValue(ciclo);        
      }
      
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  fechasxCiclos(all){
    let ciclo= this.detalleExt.get('ciclo').value;  
    if (ciclo != "") {
      this.minDate = new Date();
      this.maxDate = ciclo.FechaFin;
    }
  }

  cargarCentros() {
    this.centroService.consultaCentrosXRegiones(null, this.idUsuario).then(centros => {
      this.centros = centros;
      this.cargandoCentros = null;
      if (this.data.Operation === 'Editar' && this.extemporanea.Centro != null) {
        this.detalleExt.get('centro').setValue(this.extemporanea.Centro.CentroID);
        var centro = this.centros.find(x => x.CentroID == this.extemporanea.Centro.CentroID);
        if(centro.Colegium){
          this.detalleExt.get('nombre').disable();
          this.detalleExt.get('app').disable();
          this.detalleExt.get('apm').disable();
          this.detalleExt.get('seccion').disable();
        }
      }

    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  onSelectedColegios(entrando) {
    if(!entrando){
      var centros = [] 
      let centro= this.detalleExt.get('centro').value;  
      this.validar();
      centros.push(centro)
      
      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  name_omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
      return true;
    } else {
        e.preventDefault();
      return false;
    }
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/pdf" || document.target.files[0].type == "application/docx" || 
    document.target.files[0].type == "application/doc"){
      this.tipoConsulta = true;
      this.archivoCargado = true;
      this.ErrorExt = false;
      this.enableBtn = true;
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.enableBtn = false;
      this.ErrorExt = true;
      this.nombreArchivo = "";
    }
  }

  importarDocumentos(extemporaneaID: number){ 

    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.enableBtn = false; 
        this.guardando = true;   
        this.extemporaneaService.importar(filesFD, extemporaneaID ).pipe(first())
        .subscribe(data => {
          this.guardando = false;
          this.enableBtn = true;
          if(data.Exito){
            this.dialogRef.close(true);
            this.showMessage('Información', "Información guardada correctamente");
          }
        });      
        
      }else {
        console.log("Exceso de peso");
        //this.showMessage('Información', "El documento (" + documento.NombreOriginal + ") excede el peso permitido.");
      }
    }else{
      this.dialogRef.close(true);
      this.showMessage('Información', "Información guardada correctamente");
    }
  }

  showMessage(title:string, message:string):void {
    const dialogData = new ConfirmDialogModel(title, message, false);    
    this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }
}
