<mat-card class="admin-user-card">
    <mat-card-title class="card-title fz-40">Información de becas</mat-card-title>
    <label class="rob-18">
        Bienvenido al portal de becas de Semper Altius
    </label>
    <br>       
    <br>
    <br>
    <div style="width: 100%;" >
        <button mat-raised-button class="color-btn"  [routerLink]="['/seleccion-beca']"> Solicitar beca</button>
    </div>

    <br>
    <br>    
    <label class="rob-18">
        Solicitudes del alumno:
    </label>
    <br>
    <br>
    <br>    
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="matricula">
            <mat-header-cell *matHeaderCellDef> Matrícula </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.matricula}} </mat-cell>
          </ng-container>
  
        <ng-container matColumnDef="nombre">
          <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.nombre}} </mat-cell>
        </ng-container>
      
        <ng-container matColumnDef="ciclo">
            <mat-header-cell *matHeaderCellDef> Ciclo </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ciclo}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="centro">
            <mat-header-cell *matHeaderCellDef> Centro </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.centro}} </mat-cell>
          </ng-container> 

          <ng-container matColumnDef="seccion">
            <mat-header-cell *matHeaderCellDef> Sección </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.seccion}} </mat-cell>
          </ng-container> 
          

        <ng-container matColumnDef="grado">
          <mat-header-cell *matHeaderCellDef> Grado </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.grado}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="estatus">
            <mat-header-cell *matHeaderCellDef> Estatus </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.estatus}} </mat-cell>
          </ng-container>
  

        <ng-container matColumnDef="opciones">
            <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Opciones">
                <button mat-icon-button color="basic" aria-label="Editar"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
            </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>                        
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

 
  


</mat-card>




<div  style="width: 80%; margin:auto; text-align: justify;">
    <br>
    <br>
    <label class="rob-18">
        A continuación se muestran los requisitos para las diferentes becas que se pueden solicitar.
    </label>
    <br><br>
    <table style="width:100%" >
        <tr>
            <th>Tipo beca</th>
            <th>Preescolar</th>
            <th>Primaria</th>
            <th>Secundaria</th>
            <th>Preparatoria COLEGIO</th>
            <th>Preparatoria ANAHUAC</th>
            <th>Promedio</th>
            <th>Requisito</th>
        </tr>
        <tr>
            <td>Beca Colegio</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">Colegio 9 <br> Prepa 8.5</td>
            <td>Estudio socioeconomico<br>
                No adeudos<br>
                Pago de preinscripción<br>
                Ensayo para prepa<br>
            </td>
        </tr>
        <tr>
            <td>Beca SEP</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">9</td>
            <td>Otorga SEP</td>
        </tr>
        <tr>
            <td>BEG</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado"></td>
            <td class="centrado">8</td>
            <td>Posible cambio</td>
        </tr>
        <tr>
            <td>Beca especial</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado"></td>
            <td class="centrado">8</td>
            <td>Asignadas</td>
        </tr>
        <tr>
            <td>Beca excelencia</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">X</td>
            <td class="centrado"></td>
            <td class="centrado">95/100 <br>90/100</td>
            <td>
                Carta de buena conducta
            </td>
        </tr>
        <tr>
            <td>Beca Bachillerato Anahuac</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">X</td>
            <td class="centrado">9</td>
            <td>Prueba de aptitud</td>
        </tr>
        <tr>
            <td>Beca académica</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">X</td>
            <td class="centrado">9</td>
            <td>Ensayo</td>
        </tr>
        <tr>
            <td>Beca deportiva</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">X</td>
            <td class="centrado">8</td>
            <td>Ensayo</td>
        </tr>
        <tr>
            <td>Beca artística y cultural</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">X</td>
            <td class="centrado">8</td>
            <td>Ensayo</td>
        </tr>

        <tr>
            <td>Beca académica</td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado"></td>
            <td class="centrado">9</td>
            <td>Ensayo</td>
        </tr>

        <tr>
            <td>Descuento del grupo <br> Anahuac</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">8</td>
            <td>Asignadas</td>
        </tr>

        <tr>
            <td>Descuento del Colegio</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">8</td>
            <td>Se está regularizando</td>
        </tr>        

        <tr>
            <td>Descuento Plan Chihuahua</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">X</td>
            <td class="centrado">8</td>
            <td>Estrategia comercial</td>
        </tr>
        

    </table>

    <br>
    <br>
   
</div>

