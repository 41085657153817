import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { DetalleAdmSobrepasosComponent } from '../_components/detalle-adm-sobrepasos/detalle-adm-sobrepasos.component';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Empresa } from '@app/_models/empresa';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { EmpresaService } from '@app/_services/empresa.service';
import { Centro } from '@app/_models/centro';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { Ciclo} from '@app/_models/ciclo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { Node, Options, TreetableComponent } from 'ng-material-treetable';
import { TipoBecaConteo } from '@app/_models/tipoBecaConteo';
import { ColumnDefinition } from 'ng-material-treetable/src/app/treetable/models';
import { Resumen } from '@app/_models/resumenGeneral';
import { ResumenGeneralService } from '@app/_services/resumenGeneral.service';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { FileDownloadService } from '@app/_services/file-download.service';

@Component({
  selector: 'app-resumen-becas',
  templateUrl: './resumen-becas.component.html',
  styleUrls: ['./resumen-becas.component.scss']
})
export class ResumenBecasComponent implements OnInit {

  resumeBeca: FormGroup;
  
  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";

  datasource: null;

  ciclosBecas : Ciclo[] = [];
  centros: Centro[] = [];
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  selectedCentros : Centro [];
  selectedSecciones : Seccion [];  

  cargandoCentros = null;
  cargandoRegiones = null;
  cargandoSecciones = null;
  cargandoCiclos = null;
  tiposBeca: TipoBeca[] = [];

  idUsuario: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;

  @ViewChild('matTreeTable') matTreeTable: TreetableComponent<Resumen> ;

  constructor(public dialog: MatDialog, 
    private authenticationService: AuthenticationService, 
    private resumenService: ResumenGeneralService, 
    private centroService: CentroService,
    private regionService:RegionService,
    private seccionService: SeccionService,
    private gradoService: GradoEducativoService,
    private cicloBecaService: CicloBecaService,
    private tipoBecaService : TipoBecaService,
    private fileDownloadService: FileDownloadService,
    private formBuilder: FormBuilder) { }
  
  ngOnInit() {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
   // this.dataSource.paginator = this.paginator;

    this.resumeBeca = this.formBuilder.group({
      ciclo: ['', Validators.required],
      centros: [''],
      regiones: [''],
    });

    this.getRegiones();    
    this.getCiclosBecas();
    this.initCentros();
    this.arrayOfNodesTree = this.resumenService.init();
  }

  treeOptions: Options<Resumen> = {
    capitalisedHeader: true,
    customColumnOrder: [
      'Seccion', 'GradoEducativo', 'Centro','Cege','Estimados', "TiposBeca"
    ],
    isExpanded: false,
    verticalSeparator : true
  };

  columnDefinition : ColumnDefinition[] = [
    { name : "Seccion", title : "Sección", value:"Seccion"},
    { name : "GradoEducativo", title : "Grado Educativo", value:"GradoEducativo"},
    { name : "Centro", title : "Centro", value:"Centro"},
    { name : "Cege", title : "U. O.", value:"Cege"},
    { name : "EstatusPropuesta", title : "Estatus Propuesta", value : "EstatusPropuesta" },
    { name : "Estimados", title : "Alumnos estimados", value:"Estimados"},
  ];

  arrayOfNodesTree: Node<Resumen>[]= [];

  logNode(node: Node<Resumen>) {}

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }

initCentros(){
  this.centroService.getbyUser(this.idUsuario).then(centros => {
    this.centros = centros;
  }).catch(err => {
    console.log("Error" + err);
  });
}

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
    }
  }  

  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneCiclos(all){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
    if(this.resumeBeca.controls != null && 
      this.resumeBeca.controls.userType != null &&
      this.resumeBeca.controls.userType.value != null){
      if(this.resumeBeca.controls.userType.value.length==this.centros.length)
      this.allSelectedCiclos.select();
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.resumeBeca.controls != null && 
      this.resumeBeca.controls.userType != null &&
      this.resumeBeca.controls.userType.value != null){
      if(this.resumeBeca.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  onSelectedSecciones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.resumeBeca.get('centros').value;  
      var secciones   = this.resumeBeca.get('secciones').value;  

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
      }).catch(err => {
        console.log("Error" + err);
      });
    }
  }  
   
  onSelectedRegiones(entrando) {

    if(!entrando){
      console.log("Cargando...");   
      var regiones = this.resumeBeca.get('regiones').value;  
      console.log("Regiones " + regiones);

      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
        this.secciones = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.resumeBeca.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      }); 
    }
  }  

  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }

    if(this.resumeBeca.controls != null && 
      this.resumeBeca.controls.userType != null &&
      this.resumeBeca.controls.userType.value != null){
        if(this.resumeBeca.controls.userType.value.length==this.centros.length)
          this.allSelectedRegion.select();
    }
  }

  descargar(){
    console.log("Descargando");
    let ciclo   = this.resumeBeca.get('ciclo').value;  
    if(ciclo == null || ciclo.length == 0){
      return;
    }
    let centros   = this.resumeBeca.get('centros').value;  
    let regiones   = this.resumeBeca.get('regiones').value;  

    this.resumenService.descargar(ciclo, regiones, centros, this.idUsuario)
    .subscribe(
      data => {
        if (!!data.Exito) {
          this.fileDownloadService.downloadFile(data.Dato, "resumen.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }else{
          this.showMessage(data.Mensaje, 'Error');
        }
      }
    );
  }

  buscar(){
     console.log("Buscando");
    let ciclo   = this.resumeBeca.get('ciclo').value;  
    if(ciclo == null || ciclo.length == 0){
      return;
    }

    let centros   = this.resumeBeca.get('centros').value;  
    let regiones   = this.resumeBeca.get('regiones').value;  

    this.resumenService.buscar(ciclo, regiones, centros, this.idUsuario).pipe(first()).subscribe(
      data => {
        this.tipoBecaService.consultaTiposBecaXCicloID(ciclo).then(tiposBeca => {
          this.tiposBeca = tiposBeca;
          var newColumnsDefinition : ColumnDefinition[] = [];
          newColumnsDefinition = this.resumenService.updateHeaderDefinition(this.columnDefinition, this.tiposBeca);
          this.arrayOfNodesTree = this.resumenService.convertResumenGeneralAResumen(data.Dato);
          this.matTreeTable.reloadTreeTable(this.arrayOfNodesTree, newColumnsDefinition);
          }).catch(err => {
          console.log("Error" + err);
          });   
      },
      error=> {
      }
    );    
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
    const dialogData = new ConfirmDialogModel(titulo, message, false);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    return dialogRef
  }
}