export class Contacto {

    ContactoID : number;
    ResponsableID : number;
    NumeroCasa : string;
    Celular : string;
    Oficina : string;
    Correo : string;

}
    
  