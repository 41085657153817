import { Component, OnInit, ViewChild } from '@angular/core';
import { SolicitudBecaComponent } from '../_components/solicitud-beca/solicitud-beca.component';
import { Campus } from '@app/_models/campus';
import { Grado } from '@app/_models/grado';
import { Programa } from '@app/_models/programa';
import { CentroService } from '@app/_services/centro.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Centro } from '@app/_models/centro';
import { AuthenticationService } from '@app/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { Ciclo } from '@app/_models/ciclo';
import { RevisionSolicitudesService } from '@app/_services/revision-solicitudes.service';
import { MatTableDataSource } from '@angular/material/table';
import { Region } from '@app/_models/region';
import { RegionService } from '@app/_services/region.service';
import { SeccionService } from '@app/_services/seccion.service';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { Solicitud } from '@app/_models/solicitud';
import { Empresa } from '@app/_models/empresa';
import { first } from 'rxjs/operators';
import { ExpedientesAsignadosService } from '@app/_services/expedientesAsignados.service';
import { FileDownloadService } from '@app/_services/file-download.service';
import { HistorialSolicitudComponent } from '@app/_components/historial-solicitud/historial-solicitud.component';
import { EstatusSolicitudService } from '@app/_services/estatusSolicitud.service';
import { EstatusSolicitud } from '@app/_models/estatusSolicitud';
import { DetalleCalificacionComponent } from '@app/_components/detalle-calificacion/detalle-calificacion.component';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';

@Component({
  selector: 'app-revision-solicitudes',
  templateUrl: './revision-solicitudes.component.html',
  styleUrls: ['./revision-solicitudes.component.scss']
})
export class RevisionSolicitudesComponent implements OnInit {

  displayedColumns: string[] = ['fecha','colegio', 'seccion', 'id', 'nombre', 'estatus', 'acciones'];

  dataSource = new MatTableDataSource<Solicitud>();
  revisionSolicitudForm: FormGroup;

  campusControl = new FormControl();
  institucionesControl = new FormControl();
  gradosControl = new FormControl();
  ciclosControl = new FormControl();
  programasControl = new FormControl();
  nombreControl = new FormControl();

  idUsuario: number;

  optionCampus = [];
  optionInstituciones:string[] = [];
  optionGrados = [];
  optionCiclos = [];
  optionProgramas = [];

  institucionFiltro:string ="";
  campusFiltro:string ="";
  gradoFiltro:string ="";
  programaFiltro:string ="";
  cicloFiltro:string ="";

  campusSrc: Campus[] = [];
  institucionesSrc: string[] = [];
  gradosSrc: Grado[] = [];
  ciclosSrc: Ciclo[] = [];
  programasSrc: Programa[] = [];

  selectedCampus : Campus [];
  selectedInstituciones : string [];
  selectedGrados: Grado[] = [];
  selectedProgramas: Programa[] = [];
  selectedCiclos: Ciclo[] = [];

  pageEvent: PageEvent;
  length:number = 0;
  pageIndex:number = 0;
  pageSize:number = 20;

  nombreProspecto_:string = "";
 
  empresas: Empresa[] = [];
  centros: Centro[] = [];
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  grados: GradoEducativo[] = [];
  selectedCentros : Centro [];
  selectedEmpresas : Empresa [];
  selectedRegiones : Region [];  
  selectedSecciones : Seccion [];   
  ciclosBecas : Ciclo [];  
  solicitudes : Solicitud [];  
  estatusSol: EstatusSolicitud[] = [];

  cargandoEmpresas = null;
  cargandoCentros = null;
  cargandoRegiones = null;
  cargandoSecciones = null;
  cargandoGrados = null;
  cargandoCiclos = null;
  cargandoEstatus = null;  

  errorMessageP: string = "";
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  
  @ViewChild('mySelCampus') mySelCampus: MatSelect;

  @ViewChild('mySelInstituciones') mySelInstituciones: MatSelect;

  @ViewChild('allSelectedGrados') private allSelectedGrados: MatOption;
  @ViewChild('mySelGrados') mySelGrados: MatSelect;

  @ViewChild('mySelProgramas') mySelProgramas: MatSelect;

  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;

  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;
  @ViewChild('mySelGrado') mySelGrado: MatSelect;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;
  @ViewChild('mySelEmpresas') mySelEmpresas: MatSelect;

  @ViewChild('mySelEstatus') mySelEstatus: MatSelect;
  @ViewChild('allSelectedEstatus') private allSelectedEstatus: MatOption;

  constructor( private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private regionService:RegionService,
    private revisionSolicitudesService : RevisionSolicitudesService,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private gradoService: GradoEducativoService,
    private cicloBecaService: CicloBecaService,
    private expedientesAsignadosService : ExpedientesAsignadosService,    
    private fileDownloadService: FileDownloadService,
    private estausSolicitudService : EstatusSolicitudService,
    public dialog: MatDialog) { }

  ngOnInit(): void {

    //this.dataSource.paginator = this.paginator;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getRegiones();    
    this.getCiclosBecas();
    this.obtenerEstatus();
    this.initSecciones();

    this.revisionSolicitudForm = this.formBuilder.group({
      ciclo: ['', Validators.required],
      centros: [''],
      regiones: [''],
      secciones: [''],
      estatus: [''],
      nombreControl: ['']
    });  
  }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      this.cargandoCiclos = null;
      
    }).catch(err => {
      this.cargandoCiclos = "(Error)";
      console.log("Error" + err);
    });
  }

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});
      this.cargandoRegiones = null;
    }).catch(err => {
      this.cargandoRegiones = "(Error)";
      console.log("Error" + err);
    });
  }
    
  initSecciones(){
    this.seccionService.getAll().then(secciones => {
      this.secciones = secciones;
    }).catch(err => {
    console.log("Error" + err);
    }); 
  }

  getSolcitudesData(event?:PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;     
    return event;
  }

  verSolicitud(solicitud: Solicitud): void {
    this.revisionSolicitudesService.obtenerDetalle(solicitud.SolicitudID).then( solicitud => {
      const dialogRef = this.dialog.open(SolicitudBecaComponent, {
        width: '60%',
        data: {Operation: 'Editar', Objeto: solicitud}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.buscar();
        }
      });
    }).catch(err => {
      console.log("Error" + err);
    });
  }
  
  obtenerEstatus() {
    this.estausSolicitudService.consultarEstatusSolicitudRevision().then(estatus => {
      estatus.forEach(x => {
        if(x.Nombre =="Enviada"){
          x.Nombre = "Pendiente";
        }
      });

      this.estatusSol = estatus;
      this.cargandoEstatus = null;
    }).catch(err => {
      this.cargandoEstatus = "(Error)";
      console.log("Error" + err);
    });
  }

  descargar(solicitud:Solicitud){
   var nombre = solicitud.Alumno.CURP;
    this.expedientesAsignadosService.obtieneFicha(solicitud.SolicitudID)
    .subscribe(
      data => {
        if (!!data) {
          this.fileDownloadService.downloadFile(data, "solicitud_"+ nombre + ".pdf", "application/pdf");
        }
      }
    );
  }   

  verHistorial(solicitud:Solicitud){
    const dialogRef = this.dialog.open(HistorialSolicitudComponent, {
      width: '80%',
      data: {Operation: 'Editar', Objeto: solicitud}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }


  verComentarios(solicitud:Solicitud){
    const dialogData = new ConfirmDialogModel("Comentarios empresa", solicitud.Comentarios, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(() => {});
  }


  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  onSelectedRegiones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var regiones   = this.revisionSolicitudForm.get('regiones').value;  
      console.log("Regiones " + regiones);
      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.cargandoCentros = null;
        this.secciones = [];
        this.grados = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.selectedGrados = [];
        this.revisionSolicitudForm.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        this.cargandoCentros = "(Error)";
        console.log("Error" + err);
      });
    }
  }  

  onSelectedColegios(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionSolicitudForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoSecciones = null;

        this.grados = [];
        this.selectedGrados = [];

      }).catch(err => {
        this.cargandoSecciones = "(Error)";
        console.log("Error" + err);
      });
    }
  }  
 
  buscar() { 

    if(this.revisionSolicitudForm.invalid) return
    var  cicloID = this.revisionSolicitudForm.get('ciclo').value;  

    if(cicloID == null ||  cicloID == 0){
      return;
    }
    
    var centros = this.revisionSolicitudForm.get('centros').value;  
    var regiones = this.revisionSolicitudForm.get('regiones').value;  
    var secciones = this.revisionSolicitudForm.get('secciones').value;  
    var estatus = this.revisionSolicitudForm.get('estatus').value;  
    var nombre = this.revisionSolicitudForm.get('nombreControl').value;  

    this.revisionSolicitudesService.buscar(this.pageSize, (this.pageIndex+1), cicloID, centros,regiones, secciones,
    estatus, nombre, this.idUsuario).pipe(first())
    .subscribe(
      data => {
        this.length = data.Total;
        this.dataSource = new MatTableDataSource<Solicitud>(data.Dato);
      },
      () => {
        this.errorMessageP = "Error al obtener la lista"; 
      });
  }

  public getServerData(event?:PageEvent){
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;      
    this.buscar();

    return event;
  }

  toggleAllSelectionGrado() {
    if (this.allSelectedGrados.selected) {
      this.mySelGrado.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelGrado.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }         

  omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }

  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    
 
  tosslePerOneCiclos(){ 
    if (this.allSelectedCiclos.selected) {  
     this.allSelectedCiclos.deselect();
     return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
      this.allSelectedCiclos.select();
    }
  } 

  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
    }
  }  

  tosslePerOneGrados(){ 
    if (this.allSelectedGrados.selected) {  
     this.allSelectedGrados.deselect();
     return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedGrados.select();
    }
  }   

  tosslePerOneSecciones(e){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }  

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }
  
  onSelectedSecciones(entrando) {
    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.revisionSolicitudForm.get('centros').value;  
      var secciones   = this.revisionSolicitudForm.get('secciones').value;  

      this.gradoService.consultaCentrosSecciones(centros, secciones, this.idUsuario).then(grados => {
        this.grados = grados.sort((a, b) => a.Nombre.localeCompare(b.Nombre));
        this.cargandoGrados = null;
      }).catch(err => {
        this.cargandoGrados = "(Error)";
        console.log("Error" + err);
      });
    }
  }    

  tosslePerOneCentros(e){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  tosslePerOneRegiones(e){ 
    if (this.allSelectedRegion.selected) {  
     this.allSelectedRegion.deselect();
     return false;
    }
    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
      if(this.revisionSolicitudForm.controls.userType.value.length==this.centros.length)
        this.allSelectedRegion.select();
    }
  }
  
  tosslePerOneEstatus(e){ 
    if (this.allSelectedEstatus.selected) {  
     this.allSelectedEstatus.deselect();
     return false;
    }

    if(this.revisionSolicitudForm.controls != null && 
      this.revisionSolicitudForm.controls.userType != null &&
      this.revisionSolicitudForm.controls.userType.value != null){
        if(this.revisionSolicitudForm.controls.userType.value.length==this.estatusSol.length)
          this.allSelectedEstatus.select();
    }
  }

  toggleAllSelectionEstatus() {
    if (this.allSelectedEstatus.selected) {
      this.mySelEstatus.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelEstatus.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }  

  agregarCalificacion(solicitud:Solicitud){
    solicitud.ClaveTipoRequisito = "PRUEBA_APTITUD";
    const dialogRef = this.dialog.open(DetalleCalificacionComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: solicitud}
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}