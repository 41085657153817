import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Campus } from '@app/_models/campus';
import { BusquedaCampusParam } from '@app/_models/busquedaCampusParam';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class CampusService {
private currentCampusSubject: BehaviorSubject<Campus>;
public currentCampus: Observable<Campus>;    

constructor(private http: HttpClient,
    private authenticationService: AuthenticationService) {
}



 /*aspiranteRegistroDatos(): Observable<boolean> {
    let prospectoId = this.authenticationService.currentUserValue.ProspectoID;
    return this.http.post<boolean>(`${environment.apiUrl}/Aspirante/${prospectoId}/TieneDatosRegistrados`,{});
  }*/


 

consultaCampus(idUsuario: number):Promise<Campus[]>{
    return this.http.get<Campus[]>(`${environment.apiUrl}/campus?idUsuario=${idUsuario}`).toPromise();

}


consultaCampusXInstituciones(ids : string[], idUsuario: number):Promise<Campus[]>{
    return this.http.post<Campus[]>(`${environment.apiUrl}/campus?idUsuario=${idUsuario}`, ids).toPromise();
}

consultaInstituciones(idUsuario: number):Promise<string[]>{
    return this.http.get<string[]>(`${environment.apiUrl}/Institucion?idUsuario=${idUsuario}`,).toPromise();

}

getAll():Promise<Campus[]>{
    return this.http.get<Campus[]>(`${environment.apiUrl}/Campus/GetAll`).toPromise();

}

getInstituciones():Promise<string[]>{
    return this.http.get<string[]>(`${environment.apiUrl}/Campus/Instituciones`).toPromise();

}

consultaCampusXCampusOrCP(datosBusqueda: BusquedaCampusParam):Promise<Campus[]>{
    return this.http.post<Campus[]>(`${environment.apiUrl}/Campus/Listado`, datosBusqueda).toPromise();

}


getCount(datosBusqueda: BusquedaCampusParam):Promise<number>{
   return this.http.post<number>(`${environment.apiUrl}/Campus/Conteo`, datosBusqueda).toPromise();
}


almacenarCampus(campus : Campus){
    this.currentCampusSubject = new BehaviorSubject<Campus>(campus);
    this.currentCampus = this.currentCampusSubject.asObservable();
}

public get currentCampusValue(): Campus {
    if(this.currentCampusSubject){
        return this.currentCampusSubject.value;
    }
    return null;
    
  }


  getByIdUser(idUsuario: number):Promise<Campus[]>{
    return this.http.get<Campus[]>(`${environment.apiUrl}/campus/GetByIdUser?idUsuario=${idUsuario}`).toPromise();

}


 
}
