<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
      <mat-card-title class="text-left fz-26">APERTURA DE CICLOS</mat-card-title>
  </div>
  <br>
  <mat-tab-group mat-align-tabs="center"  (selectedTabChange)="tabClick($event)">
    <mat-tab label="CONFIGURACIÓN DE REQUISITOS">
      <app-configuracion-req #tabRequisitos></app-configuracion-req>
    </mat-tab>
    <mat-tab label="CONFIGURACIÓN DE BECAS"> 
      <app-configuracion-beca #tabBecas></app-configuracion-beca>
    </mat-tab>
    <mat-tab label="ESPECIFICACIÓN DE BECAS"> 
      <app-especificacion-beca #tabEspBecas></app-especificacion-beca>
    </mat-tab>     
    <mat-tab label="ESPECIFICACIÓN REQUISITOS"> 
      <app-especificacion-req #tabEspReq></app-especificacion-req>
    </mat-tab>     
    <mat-tab label="CONFIGURACIÓN DE CICLOS"> 
      <br><br><br>
      <div class="example-form">
        <div class="example-form">
          <form name="adminInfoForm" id="adminInfoForm" >
            <div class="row">
              <button mat-raised-button class="button" (click)="nuevoCiclo()">+ NUEVO CICLO</button>
              <input class="input-search icon xs-width text-ident" placeholder="Buscar" [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
              <br><br>
            </div>
          </form>
        </div>
        <br>
        <div class="example-form mat-elevation-z8">
          <mat-table [dataSource]="dataSource" >
              <ng-container matColumnDef="Clave">
                <mat-header-cell *matHeaderCellDef> Clave </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Número empleado"> {{element.Clave}} </mat-cell>
              </ng-container>      
              
              <ng-container matColumnDef="Nombre">
                <mat-header-cell *matHeaderCellDef> Nombre del ciclo </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Área"> {{element.Nombre}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="Opciones">
                <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Opciones">
                    <button mat-icon-button color="basic" aria-label="Editar"  matTooltip="Editar" (click)="editarCiclo(element)" matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumnsCiclos"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsCiclos;">
              </mat-row>
          </mat-table> 
        
          <div *ngIf="length == 0" class="text-center margin-top">
            <span class="color-obscuro">No hay registros para mostrar.</span>
          </div>
          
          <mat-paginator #paginator
            [length]="length"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 20]"
            (page)="pageEvent = getServerData($event)"
            >
          </mat-paginator>
        </div>
        <br> 
      </div>  
    </mat-tab>
  </mat-tab-group>
  <br><br>
</mat-card>