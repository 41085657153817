import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RecuperaCuentaService } from '@app/_services/recupera-cuenta.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common'; 
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-recupera-cuenta',
  templateUrl: './recupera-cuenta.component.html',
  styleUrls: ['./recupera-cuenta.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})

export class RecuperaCuentaComponent implements OnInit {

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  recuperarCuentaForm: FormGroup;

  enableBtn = true;

  dateError = false;

  loading: boolean = false;
  error: boolean = false;

  TokenReCaptcha: string = '';
  anahuac = "false";
  minDate: Date;
  maxDate: Date;
  date = new FormControl(null);
  dateNac= new Date();
  isAnahuac: boolean = false;

  constructor(private formBuilder: FormBuilder, private recuperaService:RecuperaCuentaService, public dialog: MatDialog, private router: Router, @Inject(DOCUMENT) document) {

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 100, 0, 1);
    this.maxDate = new Date(currentYear - 10, 0, 1);
    this.date = new FormControl(null);

   }

  ngOnInit() {

    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true

    this.dateNac = null;
    
    this.recuperarCuentaForm = this.formBuilder.group({ 
      curp: ['', [ Validators.required]],
      fechaNac: ['', [ Validators.required ]],
    });   

  }

  onSubmit(){

    this.error = false;
    this.loading = true;

    if (this.recuperarCuentaForm.invalid) {
      return;
    }  

    let curp = this.recuperarCuentaForm.get('curp').value;
    let date_ = formatDate(this.dateNac,'yyyy-MM-dd', 'es-MX' );
    let fecha:Date = new Date(date_);
    fecha.setDate(fecha.getDate() + 1);

    this.recuperaService.recuperaCuentaAlumno(curp, fecha)
    .pipe(first())
    .subscribe(
    data => {
      this.loading = false;
      if (data.Success){     
        this.router.navigate(['/recupera-cuenta-espera', data.Data]);
      }else{        
        this.error = true;
        const dialogData = new ConfirmDialogModel('Error', data.Message, false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }   
    },
    error => {
      const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la solitud.', false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });

      this.error = true;
      this.loading = false;
    });    
  }
  get formControls() { return this.recuperarCuentaForm.controls; }
}