<mat-card class="recupera-card">
  <div *ngIf="!activa">
    <div class="center">
      <label class='text-center'>
      Espere, confirmando ...
      </label>
    </div>
  </div>
    <div *ngIf="activa">
      <mat-card-title class="card-title fz-40">Cuenta activada</mat-card-title>
      <br>
      <div class="center">
        <label class='text-center'>
        Gracias por activar tu cuenta, ahora puedes iniciar sesión.
        </label>
      </div>
      <br/>
      <div class="center">
          <button class="button" mat-raised-button color="accent" routerLink="/login">Iniciar sesión</button>
      </div>
    </div>
</mat-card>