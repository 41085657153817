import { Injectable } from '@angular/core';
import { Region } from '@app/_models/region';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { environment } from '@environments/environment';
import { ResponseRest } from '@app/_models/responseRest';
import { RenovacionBeca } from '@app/_models/renovacionBeca';
import { BecaOtorgada } from '@app/_models/becaOtorgada';


@Injectable({ providedIn: 'root' })
export class RenovacionBecaService {

    constructor(private http: HttpClient,
        private authenticationService: AuthenticationService) {
    }


    getList(nRegistros: number, nPagina : number,  CicloID : number, EstatusID : number, UsuarioID : number ){
        return this.http.post<any>(`${environment.apiUrl}/RenovacionBeca/GetList`,  {nRegistros, nPagina, CicloID, EstatusID , UsuarioID } );        

    }
    

    getBecasOtorgadas(CicloID : number, CentroID : string, filtro : string){
        return this.http.post<any>(`${environment.apiUrl}/RenovacionBeca/GetBecas`,  {CicloID, CentroID, filtro } );                
    }

    guardarRenovacion(renovacion: RenovacionBeca, becas  :BecaOtorgada[]){
        return this.http.post<any>(`${environment.apiUrl}/GuardarRenovacion`,   {renovacion, becas }  );
    }



}