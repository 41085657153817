import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Centro } from '@app/_models/centro';
import { ObjetoOp } from '@app/_models/objectOperation';
import { TipoBecaReqCentro } from '@app/_models/tipoBecaReqCentro';
import { TipoBecaReqEspecificaciones } from '@app/_models/TipoBecaReqEspecificaciones';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { TipobecaRequisitoCentroSecComponent } from '../tipobeca-requisito-centro-sec/tipobeca-requisito-centro-sec.component';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-tipobeca-requisito-centro',
  templateUrl: './tipobeca-requisito-centro.component.html',
  styleUrls: ['./tipobeca-requisito-centro.component.scss']
})
export class TipobecaRequisitoCentroComponent implements OnInit {

  displayedColumnsCentros: string[] = ['aplicable','Nombre', 'minimo', 'maximo', 'seccion'];
  displayedColumns: string[] = ['aplicable','Nombre', 'seccion'];
  dataSourceRequisitoCentros = new MatTableDataSource<TipoBecaReqCentro>();

  idSeccionesTipoBeca : number[] = [];
  centros: Centro[] = [];
  dataTipoBecaSecciones : TipoBecaReqEspecificaciones;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;
  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  reglonSeleccionado :TipoBecaReqCentro;
  enableBtn : boolean;
  tipoRequisito: string = "";
  selectedIds : number[] = [];

  constructor(private centroService : CentroService,
    private seccionService: SeccionService,
    private tipoBecaService : TipoBecaService,
    public dialogRef: MatDialogRef<TipobecaRequisitoCentroComponent>,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService, 
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaSecciones = data.Objeto;
    }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.enableBtn = true;
    this.tipoRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.Clave
    if(this.dataTipoBecaSecciones.BecaRequisitoCentros == null ||this.dataTipoBecaSecciones.BecaRequisitoCentros.length == 0){
      this.guardando = true;      
      var TipoBecaID = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.TipoBeca.TipoBecaID;
      this.seccionService.consultaSeccionesXTipoBeca(TipoBecaID).then(secciones => {
        this.idSeccionesTipoBeca = secciones.map(  x => x.SeccionID);
        this.centroService.consultaCentrosXSeccionesUsuario(this.idSeccionesTipoBeca, this.idUsuario).then(centros => {
          this.centros = centros;
          this.initTable();
          this.cargandoBecas = null;

        }).catch(err => {
          //this.hayErrores = true;
          this.cargandoBecas = "(Error)";
          console.log("Error" + err);
        });
    
      }).catch(err => {
        console.log("Error" + err);
      });      

 

    }else{
      this.dataSourceRequisitoCentros= new MatTableDataSource<TipoBecaReqCentro>(this.dataTipoBecaSecciones.BecaRequisitoCentros);
      this.length = this.dataSourceRequisitoCentros.data.length;
    }
  }


  especificacionXSeccion(renglon : TipoBecaReqCentro){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.reglonSeleccionado = renglon;
    renglon.TipoBecaID = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.TipoBeca.TipoBecaID;
    renglon.EspecificacionReq = this.tipoRequisito;
    renglon.TipoBecaRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito;
    if(mCopy.BecaRequisitoSecciones == null){
      mCopy.BecaRequisitoSecciones = [];
    }
    const dialogRef = this.dialog.open(TipobecaRequisitoCentroSecComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosSecciones(renglon);
      }else{
        var requisitoBeca =this.dataTipoBecaSecciones.BecaRequisitoCentros.find(r => r.Centro.CentroID  == renglon.Centro.CentroID);
        requisitoBeca.RequisitoSecciones = mCopy.BecaRequisitoSecciones;      
      }
    });
  }

  eliminarEspecificacionXSeccion(renglon : TipoBecaReqCentro){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por sección?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.RequisitoSecciones  = [];
      }
    });    
  }

  initTable(){
    this.dataTipoBecaSecciones.BecaRequisitoCentros = [];
    console.log("Centros " + this.centros.length);
    this.centros.forEach(
      x => {
        var RequisitoCentro = new TipoBecaReqCentro();
        RequisitoCentro.Centro = x;
        RequisitoCentro.Minimo =  this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo;
        RequisitoCentro.Maximo =  this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo;
        RequisitoCentro.RequisitoSecciones = [];
        this.dataTipoBecaSecciones.BecaRequisitoCentros.push(RequisitoCentro);
      }
    );
    this.dataSourceRequisitoCentros = new MatTableDataSource<TipoBecaReqCentro>(this.dataTipoBecaSecciones.BecaRequisitoCentros);
    this.length = this.dataSourceRequisitoCentros.data.length;

    this.guardando = false;
  }


  /*

  initIdSeccionesPorBeca(){
    this.guardando = true;
    var TipoBecaID = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.TipoBeca.TipoBecaID;
    this.seccionService.consultaSeccionesXTipoBeca(TipoBecaID).then(secciones => {
      this.idSeccionesTipoBeca = secciones.map(  x => x.SeccionID);
      this.centroService.consultaCentrosXSecciones(this.idSeccionesTipoBeca).then(centros => {
        this.centros = centros;
        this.initTable();
        this.cargandoBecas = null;
      }).catch(err => {
        //this.hayErrores = true;
        this.cargandoBecas = "(Error)";
        console.log("Error" + err);
      });
  
    }).catch(err => {
      console.log("Error" + err);
    });
  }*/

  agregarRequisitosSecciones(tipoBecaReqCentro : TipoBecaReqCentro){
    this.reglonSeleccionado.RequisitoSecciones = tipoBecaReqCentro.RequisitoSecciones;
  }  

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  validateMinMax(tipoBecaReq:TipoBecaReqCentro){
    this.enableBtn = true;
    if(  ( tipoBecaReq.Minimo  >=  this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo  &&   tipoBecaReq.Minimo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)
        && (tipoBecaReq.Maximo  >=   this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo &&   tipoBecaReq.Maximo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)){
      tipoBecaReq.Invalido = false;
    }else{
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }

    if(tipoBecaReq.Maximo < tipoBecaReq.Minimo) {
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }
  }  
}
