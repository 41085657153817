<div class="separador-inferior">
  <div class="seccion-separador bg-blanco"></div>
  <div class="seccion-separador bg-blanco"></div>
  <div class="seccion-separador bg-blanco"></div>
  <div class="seccion-separador bg-blanco"></div>
  <div class="seccion-separador bg-blanco"></div>
</div>
<div *ngIf="!isAnahuac" class="barra-footer">
  <div fxLayout="row wrap" fxLayout.xs="column" class="text-center">
    <div fxFlex="100" [fxShow]="isAdmin || isEmpresa" class="seccion-footer">
      <div class="row">
       <label class="fz-15 avisoPrivacidad">Red de Colegios Semper Altius 2020 |
         <a>Aviso de Privacidad</a>
        </label>
      </div>
    </div>
    <div fxFlex="50" [fxShow]="isProspect" [hidden]="!isAuthenticated" class="seccion-footer">
      <div class="row">
       <label class="fz-24 color-azul">Contacto</label>
          <br>
          <div class="contenedor-redes-sociales">

            <img class="margin-right  float-left" src="/assets/SemperAltus/Icon_Phone.png"/>

            <a class="margin-right fz-20  float-left" href="tel:{{redes.Telefono}}"><label>{{redes.Telefono}}</label></a>

            <img class="margin-right  float-left" src="/assets/SemperAltus/Icon_Email.png"/>

            <a class="fz-20  float-left" href="mailto:{{redes.Correo}}"><label>{{redes.Correo}}</label></a>
          </div>
      </div>
    </div>
    <div fxFlex="50" [fxShow]="isProspect" [hidden]="!isAuthenticated" class="seccion-footer">
      <div>
        <label class="fz-24 color-azul">Síguenos</label>
           <br>
           <div class="contenedor-redes-sociales">
            <a target="blank" href="{{redes.Facebook}}" class="red-social" aria-label="Facebook">
              <img  src="/assets/SemperAltus/facebook.png"/>
            </a>
            <a target="blank" href="{{redes.Youtube}}" class="red-social" aria-label="Youtube">
              <img  src="/assets/SemperAltus/youtube.png"/>
            </a>
            <a target="blank" href="{{redes.Linkedin}}" class="red-social" aria-label="LinkedIn">
              <img  src="/assets/SemperAltus/in.png"/>
            </a>
            <a target="blank" href="{{redes.Instagram}}" class="red-social" aria-label="Instagram">
              <img  src="/assets/SemperAltus/insta.png"/>
            </a>
            <a  target="blank" href="{{redes.Pinterest}}" class="red-social" aria-label="Pinterest">
              <img  src="/assets/SemperAltus/pinterest.png"/>
            </a>
          </div>
       </div>
    </div>
    <div fxFlex="grow" class="seccion-footer">
      <div>
        <img class="xl-width" src="/assets/SemperAltus/footer_logo.png"/>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAnahuac" class="barra-footer">
  <div fxLayout="row wrap"  fxLayout.xs="column" class="text-center">
    <div fxFlex="50" class="seccion-footer"></div>
    <div fxFlex="50" class="seccion-footer"></div>
    <div fxFlex="grow" class="seccion-footer" style="background-color: #333333;">
      <div style="text-align: center; margin: auto;">
        <label class="fz-15" style="color: #BBBBBB;">Copyright 2020, Red de Universidades Anáhuac. Todos los derechos reservados. | Aviso de Privacidad</label>
      </div>
    </div>
  </div>
</div>