import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Campus } from '@app/_models/campus';
import { Ciclo } from '@app/_models/ciclo';
import { MatSelect } from '@angular/material/select';
import { RegionService } from '@app/_services/region.service';
import { ReporteService } from '@app/_services/reporteService';
import { AuthenticationService } from '@app/_services/authentication.service';
import { FileDownloadService } from '@app/_services/file-download.service';
import { Reporte } from '@app/_models/reporte';
import { Centro } from '@app/_models/centro';
import { Region } from '@app/_models/region';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { CentroService } from '@app/_services/centro.service';
import { Seccion } from '@app/_models/seccion';
import { SeccionService } from '@app/_services/seccion.service';
import { TipoReporte } from '@app/_models/tipoReporte';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent implements OnInit {

  formControl = new FormControl();
  tipoReporte: TipoReporte[] = [{TipoID :1, Nombre : "Reporte de solicitudes"},
                                {TipoID :2, Nombre : "Reporte de becas"},
                                {TipoID :3, Nombre : "Reporte alumno padres"},
                                {TipoID :4, Nombre : "Reporte para caja"},
                                {TipoID :5, Nombre : "Reporte propuesta de beca"},
                                {TipoID :6, Nombre : "Reporte becas SEP"},
                                {TipoID :7, Nombre : "Reporte revisión del comité"}];
  minDate: Date;
  maxDate: Date;

  centros: Centro[] = [];
  ciclosBecas : Ciclo []; 
  regiones: Region[] = [];
  secciones: Seccion[] = [];
  selectedCentros : Centro [];
  selectedRegiones : Region [];   

  selectedCampus : Campus [];
  selectedInstituciones : string [];
  selectedCiclos: Ciclo[] = [];
  selectedSecciones : Seccion []; 

  hayErrores = false;
  disabledForm: boolean = false;
  disablebtn: boolean = false;

  todos = false;

  idUsuario: number;
  reporteForm: FormGroup;
  
  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  @ViewChild('allSelectedRegiones') private allSelectedRegion: MatOption;

  @ViewChild('allSelectedCiclos') private allSelectedCiclos: MatOption;
  @ViewChild('mySelCiclo') mySelCiclo: MatSelect;

  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;

  @ViewChild('allSelectedSecciones') private allSelectedSecciones: MatOption;
  @ViewChild('mySelSeccion') mySelSeccion: MatSelect;

  constructor(private formBuilder: FormBuilder, 
    private regionService:RegionService,
    private seccionService: SeccionService,
    private cicloBecaService: CicloBecaService,
    private centroService: CentroService,
    private reporteService : ReporteService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private fileDownloadService: FileDownloadService) {  
    const currentYear = new Date().getFullYear();
    const currentMoth = new Date().getMonth();
    const currentDay = new Date().getDay();
    this.minDate = new Date(currentYear - 1, currentMoth, currentDay);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    this.disabledForm = true
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.reporteForm = this.formBuilder.group({
      tipoReporte :['', Validators.required],
      fechaInicio: [''],
      fechaFin: [''],
      ciclo: ['', Validators.required],
      regiones: [''],
      centros: [''],
      secciones: ['']
    });
  
    this.getRegiones(); 
    this.getCiclosBecas();
  }

  toggleAllSelectionCiclos() {
    if (this.allSelectedCiclos.selected) {
      this.mySelCiclo.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelCiclo.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneCiclo() { 
    if (this.allSelectedCiclos.selected) {  
    this.allSelectedCiclos.deselect();
    return false;
    }
  } 

  onSelectInstituciones(entrando) {
    if(!entrando){
      console.log("Cargando...");    
    }
  }

  generar(): void { 
    if (this.reporteForm.invalid){
      return
    }
    var reporte = new Reporte();
    console.log("Tipo reporte " + this.reporteForm.get('tipoReporte').value);

    reporte.tipoReporte = this.reporteForm.get('tipoReporte').value;

    reporte.FechaInicial = this.reporteForm.get('fechaInicio').value;
    reporte.FechaFinal = this.reporteForm.get('fechaFin').value;
    reporte.CicloID = this.reporteForm.get('ciclo').value;

    reporte.selectedRegiones = this.reporteForm.get('regiones').value;
    reporte.selectedColegios = this.reporteForm.get('centros').value;
    reporte.selectedSecciones = this.reporteForm.get('secciones').value;
    reporte.idUsuario = this.idUsuario;

    var rep = new TipoReporte();
     rep =this.tipoReporte.find(x => x.TipoID == reporte.tipoReporte);

    console.log("Reporte " + JSON.stringify(reporte));

    this.reporteService.obtieneReporte(reporte)
    .subscribe(
      data => {
        if (!!data.Exito) {
          this.fileDownloadService.downloadFile(data.Dato, rep.Nombre +".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        }else{
          this.showMessage(data.Mensaje, 'Error');
        }
      }
    );
    console.log('Aceptar');
  }

  get f() { return this.reporteForm.controls; }

  getCiclosBecas() {
    this.cicloBecaService.consultaCiclos().then(ciclos => {
      this.ciclosBecas  = ciclos;
      
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  getRegiones() {
    this.regionService.getXUsuario(this.idUsuario).then(regiones => {
      this.regiones = regiones;
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  onSelectedCiclos(entrando) {
    if(!entrando){
      console.log("Cargando...");   
    }
  }  

  tosslePerOneCiclos(){ 
    if (this.allSelectedCiclos.selected) {  
    this.allSelectedCiclos.deselect();
    return false;
    }

    if(this.reporteForm.controls != null && 
      this.reporteForm.controls.userType != null &&
      this.reporteForm.controls.userType.value != null){
        if(this.reporteForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCiclos.select();
    }
  } 
  
  onSelectedRegiones(entrando) {

    if(!entrando){
      console.log("Cargando...");   
      var regiones   = this.reporteForm.get('regiones').value;  
      console.log("Regiones " + regiones);

      this.centroService.consultaCentrosXRegiones(regiones, this.idUsuario).then(centros => {
        this.centros = centros;
        this.secciones = [];
        this.selectedCentros = [];        
        this.selectedSecciones = [];
        this.reporteForm.get('centros').setValue(this.selectedCentros);
      }).catch(err => {
        console.log("Error" + err);
      });
    }
  } 

  toggleAllSelectionRegiones() {
    if (this.allSelectedRegion.selected) {
      this.mySelRegion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelRegion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneRegiones(all){ 
    if (this.allSelectedRegion.selected) {  
      this.allSelectedRegion.deselect();
      return false;
    }

    if(this.reporteForm.controls != null && 
      this.reporteForm.controls.userType != null &&
      this.reporteForm.controls.userType.value != null){
        if(this.reporteForm.controls.userType.value.length==this.centros.length)
          this.allSelectedRegion.select();
    }
  } 
  
  onSelectedColegios(entrando) {

    if(!entrando){
      console.log("Cargando...");   
      var centros   = this.reporteForm.get('centros').value;  
      console.log("Centros " + JSON.stringify(centros));

      this.seccionService.consultaSeccionesXCentros(centros, this.idUsuario).then(secciones => {
        this.secciones = secciones;

      }).catch(err => {
        console.log("Error" + err);
      }); 
    }
  }  

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }    

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.reporteForm.controls != null && 
      this.reporteForm.controls.userType != null &&
      this.reporteForm.controls.userType.value != null){
        if(this.reporteForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
    }
  } 

  toggleAllSelectionSeccion() {
    if (this.allSelectedSecciones.selected) {
      this.mySelSeccion.options.forEach( (item : MatOption) => item.select());
    } else {
      this.mySelSeccion.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  tosslePerOneSecciones(all){ 
    if (this.allSelectedSecciones.selected) {  
     this.allSelectedSecciones.deselect();
     return false;
    }
    if(this.reporteForm.controls != null && 
      this.reporteForm.controls.userType != null &&
      this.reporteForm.controls.userType.value != null){
      if(this.reporteForm.controls.userType.value.length==this.centros.length)
        this.allSelectedSecciones.select();
    }
  }
  

  clearDates(){
    this.reporteForm.get('fechaInicio').setValue('');
    this.reporteForm.get('fechaFin').setValue('');

  }


  verify(){
    //this.reporteForm.get('fechaInicio').setValue('');
    //this.reporteForm.get('fechaFin').setValue('');

    if(this.reporteForm.controls.tipoReporte.value != "" ){
      this.disabledForm = true
    }
    if(!this.reporteForm.invalid){
      this.disablebtn = true
    }
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {
    const dialogData = new ConfirmDialogModel(titulo, message, false);
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    return dialogRef
  }  
}