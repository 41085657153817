<mat-card class="seleccion-beca-card">
    <div class="text-center" >
        <table style="margin: 0 auto;">
            <tr>
                <td>
                    <div routerLink="/pantalla-principal" class="circulo bg-celeste-claro">
                        <mat-icon  style="color:white;" aria-hidden="false" aria-label="Example home icon">home</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/seleccion-beca" class="circulo bg-celeste">
                        <mat-icon routerLink="/seleccion-beca" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div routerLink="/datos-generales" class="circulo bg-rosado">
                        <mat-icon routerLink="/datos-generales" class="color-blanco" aria-hidden="false" aria-label="Example home icon">done</mat-icon>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-verde">
                        <h2>3</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>4</h2>
                    </div>
                </td>
                <td>
                    <div class="circulo bg-disabled">
                        <h2>5</h2>
                    </div>
                </td>
            </tr>
        </table>
    </div>
<br>
<br>
<mat-card-title class="card-title fz-40">Información familiar.</mat-card-title>
<br>
<p class="color-marino fz-14 text-center">Debe llenar la información de al menos un responsable para el alumno.</p>
<br>
    <form class="example-form" name="datosFamiliaresForm" id="datosFamiliaresForm" [formGroup]="datosFamiliaresForm">  
        <div class="example-form">
            <div class="row">              
                <mat-label class="color-marino">Tipo Beca: {{tipoBeca}}</mat-label>
            </div>
            <br>
            <mat-card-title style="color: #3970B5;" class="rob-17">
                Datos del padre
            </mat-card-title>
            <mat-error *ngIf="curpErrorPadre">El CURP tiene un formato inválido</mat-error>
            <mat-error *ngIf="datosFamiliaresForm.controls.correoPadre.invalid">El Correo tiene un formato inválido</mat-error>
           
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Correo*:</mat-label>
                    <input matInput formControlName="correoPadre" (input)="validaForm()" required> 
                </mat-form-field>
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>CURP*:</mat-label>
                    <input matInput formControlName="CURPPadre" (ngModelChange)="validarCurpPadre()" (ngModelChange)="validaForm()" required>
                </mat-form-field> 
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Nombre*:</mat-label>
                    <input matInput formControlName="nombrePadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field> 
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido paterno*:</mat-label>
                    <input matInput formControlName="primerApellidoPadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field>

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido materno:</mat-label>
                    <input matInput formControlName="segundoApellidoPadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50">
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono de casa:</mat-label>
                    <input #inputp matInput formControlName="telefonoCasaPadre" (input)="validaForm()" (input)="verifyNumPadre()" maxlength="10" (keypress)="numberPhone($event)" >
                    <mat-hint *ngIf="telCasaP">número inválido</mat-hint>
                </mat-form-field> 
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Celular*:</mat-label>
                    <input matInput #inputp2 formControlName="celularPadre" (input)="validaForm()" (input)="verifyNumPadre()" maxlength="10" (keypress)="numberPhone($event)" required >
                    <mat-hint *ngIf="telCelP">número inválido</mat-hint>
                </mat-form-field> 

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono oficina:</mat-label>
                    <input matInput #inputp3 formControlName="telefonoOficinaPadre" (input)="verifyNumPadre()" maxlength="10" (keypress)="numberPhone($event)">
                    <mat-hint *ngIf="telOfiP">número inválido</mat-hint>
                </mat-form-field>
            </div>
            <br><br>
            <mat-card-title style="color: #3970B5;" class="rob-17">
                Datos de la madre
            </mat-card-title>
            <mat-error *ngIf="curpErrorMadre">El CURP tiene un formato inválido</mat-error>
            <mat-error *ngIf="datosFamiliaresForm.controls.correoMadre.invalid">El Correo tiene un formato inválido</mat-error>
            
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Correo*:</mat-label>
                    <input matInput formControlName="correoMadre" (input)="validaForm()">
                </mat-form-field>
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>CURP*:</mat-label>
                    <input matInput formControlName="CURPMadre" (ngModelChange)="validarCurpMadre()" (ngModelChange)="validaForm()" required>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Nombre*:</mat-label>
                    <input matInput formControlName="nombreMadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field>  
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido paterno*:</mat-label>
                    <input matInput formControlName="primerApellidoMadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field>  

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido materno*:</mat-label>
                    <input matInput formControlName="segundoApellidoMadre" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field>
            </div>
            <div class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono de casa:</mat-label>
                    <input matInput #inputm formControlName="telefonoCasaMadre" (input)="validaForm()" maxlength="10" (input)="verifyNumMadre()" (keypress)="numberPhone($event)" >
                    <mat-hint *ngIf="telCasaM">número inválido</mat-hint>
                </mat-form-field> 
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Celular*:</mat-label>
                    <input matInput #inputm2 formControlName="celularMadre" (input)="validaForm()" maxlength="10" (input)="verifyNumMadre()" (keypress)="numberPhone($event)" required>
                    <mat-hint *ngIf="telCelM">número inválido</mat-hint>
                </mat-form-field>  

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono oficina:</mat-label>
                    <input matInput #inputm3 formControlName="telefonoOficinaMadre" maxlength="10" (input)="verifyNumMadre()" (keypress)="numberPhone($event)">
                    <mat-hint *ngIf="telOfiM">número inválido</mat-hint>
                </mat-form-field>
            </div>
           
            <mat-card-title style="color: #3970B5;" class="rob-17">
                <br><br>
                <input type="checkbox" style="transform: scale(1.5);" formControlName="checkTutor" (change)="tutorCheck($event)">
                Agregar tutor
            </mat-card-title>
            <br>
            <mat-card-title *ngIf="datosFamiliaresForm.controls.checkTutor.value" style="color: #3970B5;" class="rob-17">
                Datos del tutor 
            </mat-card-title>
            <p class="color-marino fz-14" *ngIf="datosFamiliaresForm.controls.checkTutor.value">
                Se entiende por tutor a la persona física que, en términos de la legislación civil 
                en la que se encuentre el colegio, legalmente le es conferida la patria potestad del alumno(a) 
                a falta de sus dos progenitores o por cualquiera otro de los supuestos previstos en el código
                 civil de la Entidad federativa de que se trate. 
                 Por lo que, para efectos del Beneficio de Educación Garantizada por Orfandad,
                  no será considerado como tutor al familiar (tío (a), abuelo (a) etc.),
                   si no se acredita con documento legal haber sido designado con ese carácter 
                   por una autoridad competente.
            </p>
            <mat-error *ngIf="curpErrorTutor">El CURP tiene un formato inválido</mat-error>
            <mat-error *ngIf="datosFamiliaresForm.controls.correoTutor.invalid">El Correo tiene un formato inválido</mat-error>
        
            <div *ngIf="datosFamiliaresForm.controls.checkTutor.value" class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Correo*:</mat-label>
                    <input matInput formControlName="correoTutor" (input)="validaForm()" required>
                </mat-form-field>
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>CURP*:</mat-label>
                    <input matInput formControlName="CURPTutor" (ngModelChange)="validaForm()" (ngModelChange)="validarCurpTutor()" required>
                </mat-form-field>
            </div>
            <div *ngIf="datosFamiliaresForm.controls.checkTutor.value" class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Nombre*:</mat-label>
                    <input matInput formControlName="nombreTutor" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field> 
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido paterno*:</mat-label>
                    <input matInput formControlName="primerApellidoTutor" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field> 

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Apellido materno*:</mat-label>
                    <input matInput formControlName="segundoApellidoTutor" (input)="validaForm()" 
                    (keypress)="omit_special_char($event)" maxlength="50" required>
                </mat-form-field>
            </div>
            <div *ngIf="datosFamiliaresForm.controls.checkTutor.value" class="row">
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono de casa:</mat-label>
                    <input matInput #inputt formControlName="telefonoCasaTutor" (input)="validaForm()" (input)="verifyNumTutor()" maxlength="10" (keypress)="numberPhone($event)">
                    <mat-hint *ngIf="telCasaT">número inválido</mat-hint>
                </mat-form-field> 
        
                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Celular*:</mat-label>
                    <input matInput #inputt2 formControlName="celularTutor" (input)="validaForm()" (input)="verifyNumTutor()" maxlength="10" (keypress)="numberPhone($event)" required>
                    <mat-hint *ngIf="telCelT">número inválido</mat-hint>
                </mat-form-field>  

                <mat-form-field appearance="fill" class="inpunt-width xs-width">
                    <mat-label>Teléfono oficina:</mat-label>
                    <input matInput #inputt3 formControlName="telefonoOficinaTutor" maxlength="10" (input)="verifyNumTutor()" (keypress)="numberPhone($event)">
                    <mat-hint *ngIf="telOfiT">número inválido</mat-hint>
                </mat-form-field>

            </div> 
                <div *ngIf="datosFamiliaresForm.controls.checkTutor.value" class="row">
                <br><br>
                <button class="buttonFile" mat-raised-button (click)="seleccionarArchivo()">
                <span class='lato-bold espaciado margin-btn' style="font-size: 16px;">+ CARGAR COMPROBANTE</span></button>
                <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
                <input class="hidden" id="upload-file" type="file" (change)="adjuntarArchivo($event)" accept=".jpg,.png,.pdf,.jpeg">
                <br>
            </div>
        </div>
    </form>
    <div class="example-form">
        <br>
        <mat-card-title class="color-azul example-form">
            Otros hijos
        </mat-card-title>
        <br>     
        <div class="example-form">
            <p class="color-marino fz-14">Si tiene otros hijos estudiando en esta institución, por favor proporcione sus nombres, qué grados cursan y confirme si tienen beca.</p>
        </div>
        <br>
        <div class="example-form">
            <button class="buttonSecondary" mat-raised-button (click)="agregarHermano()">+ AGREGAR HIJO</button>
        </div>
        <br><br>
        <mat-table  [dataSource]="dataSource" class="mat-elevation-z8 table-width">
    
            <ng-container matColumnDef="curp">
                <mat-header-cell *matHeaderCellDef> CURP </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="CURP"> {{element.Alumno.CURP}} </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="Nombre">
                <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Nombre"> {{element.Alumno.Nombre}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Seccion">
                <mat-header-cell *matHeaderCellDef> Sección </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Sección"> {{element.Seccion.Nombre}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Centro">
                <mat-header-cell *matHeaderCellDef> Centro </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Centro"> {{element.Centro.Nombre}} </mat-cell>
            </ng-container>
              
            <ng-container matColumnDef="Grado">
                <mat-header-cell *matHeaderCellDef> Grado </mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Grado"> {{element.GradoEducativo.Nombre}} </mat-cell>
            </ng-container>
          
            <ng-container matColumnDef="Opciones">
                <mat-header-cell *matHeaderCellDef> Opciones </mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Opciones">
                    <button mat-icon-button color="basic" aria-label="Editar"  (click)="editarHermano(element)" matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button color="accent" aria-label="Eliminar" (click)="eliminarHermano(element)"  matTooltip="Eliminar" matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table> 
        <br>
        <br>
    </div>
    <br><br>
    <div class="example-form margin-div">
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'buttonSecondary' : !isAnahuac }" style="margin-right: 1rem;"
            (click)="guardar()" matTooltip="Guardar y volver al menú principal" 
            [disabled]="!formCompleto" mat-raised-button>GUARDAR</button>
        <button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" 
            (click)="guardarContinuar()" 
            [disabled]="!formCompleto || curpErrorPadre || curpErrorMadre || curpErrorTutor
            || datosFamiliaresForm.controls.correoPadre.invalid || datosFamiliaresForm.controls.correoTutor.invalid
            || datosFamiliaresForm.controls.correoMadre.invalid || telCasaP || telCelP || telOfiP || telCasaM || telCelM || telOfiM
            || telCasaT || telCelT || telOfiT || guardando"
             mat-raised-button>SIGUIENTE</button>
    </div>
</mat-card>
