import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ObjetoOp } from '@app/_models/objectOperation';
import { TipobecaRequisitoCentroSecComponent } from '../tipobeca-requisito-centro-sec/tipobeca-requisito-centro-sec.component';
import { AuthenticationService } from '@app/_services/authentication.service';
import { CicloCentro } from '@app/_models/cicloCentro';
import { Ciclo } from '@app/_models/ciclo';
import { CicloService } from '@app/_services/ciclo.service';
import { CicloCentroComponent } from '../ciclo-centro/ciclo-centro.component';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-especificacion-ciclo-centro',
  templateUrl: './especificacion-ciclo-centro.component.html',
  styleUrls: ['./especificacion-ciclo-centro.component.scss']
})
export class EspecificacionCicloCentroComponent implements OnInit {

  displayedColumnsCentros: string[] = ['Nombre', 'fechas'];
  dataSourceCiclosCentros = new MatTableDataSource<CicloCentro>();

  cicloCentros: CicloCentro[] = [];
  dataCiclo : Ciclo;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;
  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  reglonSeleccionado :CicloCentro;
  enableBtn : boolean;
  tipoRequisito: string = "";
  selectedIds : number[] = [];

  constructor(private cicloService : CicloService,
    public dialogRef: MatDialogRef<EspecificacionCicloCentroComponent>,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService, 
    private changeDetectorRefs: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataCiclo = data.Objeto;
    }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.enableBtn = true;
    this.guardando = true;

    this.cicloService.consultaCicloCentros(this.dataCiclo.CicloID, this.idUsuario).then(ciclosCentros => {
      this.cicloCentros = ciclosCentros;
      this.dataSourceCiclosCentros = new MatTableDataSource<CicloCentro>(this.cicloCentros);
      this.length = this.dataSourceCiclosCentros.data.length;
      this.changeDetectorRefs.detectChanges();
      this.guardando = false;
      this.cargandoBecas = null;
    }).catch(err => {
      //this.hayErrores = true;
      this.cargandoBecas = "(Error)";
      console.log("Error" + err);
    });

  }


  especificacionFechas(renglon : CicloCentro){

    this.reglonSeleccionado = renglon;
    const dialogRef = this.dialog.open(CicloCentroComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if (this.dataCiclo.FechaSolicitudIni != renglon.FechaSolicitudIni || this.dataCiclo.FechaSolicitudIni2 != renglon.FechaSolicitudIni2
          || this.dataCiclo.FechaSolicitudFin != renglon.FechaSolicitudFin || this.dataCiclo.FechaSolicitudFin2 != renglon.FechaSolicitudFin2)
          {
          renglon.TieneEspecificacion = true;
        }
  
      }

    });
  }

  eliminarEspecificacionXCentro(renglon : CicloCentro){
    renglon.FechaSolicitudIni = this.dataCiclo.FechaSolicitudIni;
    renglon.FechaSolicitudIni2 = this.dataCiclo.FechaSolicitudIni2;
    renglon.FechaSolicitudFin = this.dataCiclo.FechaSolicitudFin;
    renglon.FechaSolicitudFin2 = this.dataCiclo.FechaSolicitudFin2;
    renglon.TieneEspecificacion = false;

  }



  onSubmit(): void {
    this.guardando = true;
    this.enableBtn = false;
    this.cicloService.GuardarCicloCentros(this.cicloCentros, this.idUsuario).then(respuesta => {
      if(respuesta.Exito){
        const dialogData = new ConfirmDialogModel('', "Información guardada", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        this.guardando= false;
        this.dialogRef.close(true);

      }
    }).catch(err => {
      //this.hayErrores = true;
      this.cargandoBecas = "(Error)";
      console.log("Error" + err);
    });

  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


}
