import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogModel, AlertaComponent } from '@app/_components/alerta/alerta.component';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { first } from 'rxjs/operators';
import { ModificacionBecasService } from '@app/_services/modificacionBeca.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detalle-mod-becas',
  templateUrl: './detalle-mod-becas.component.html',
  styleUrls: ['./detalle-mod-becas.component.scss']
})
export class DetalleModBecasComponent implements OnInit {
  porcentajeErr = false;
  detalleModBeca: FormGroup;
  enableBtn = false;
  guardando = false;

  tiposBeca: TipoBeca[] = [];
  AlumnoID : number;

  error: boolean = false;
  Archivo_: File;
  maxSizeFile:number = 5120000; // 5MB  
  nombreArchivo = 'Ningún archivo seleccionado';
  errorMessage: string = "";
  idUsuario: number;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService, 
    private modificacionBecaService:ModificacionBecasService,
    private tipoBecaService : TipoBecaService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetalleModBecasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {

    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.detalleModBeca = this.formBuilder.group({
      colegio: ['', Validators.required],
      seccion: ['', Validators.required],
      curp: ['', Validators.required],
      nombre: ['', Validators.required],
      tipoBeca: ['', Validators.required],
      porcentaje: ['', Validators.required],
      comentarios: ['', Validators.required],
    });

    this.tipoBecaService.consultaTiposBeca().then(tiposBeca => {
      this.tiposBeca = tiposBeca
      }).catch(err => {
      console.log("Error" + err);
    }); 

    if (this.data.Operation == 'Editar') {
      this.enableBtn = true;
      this.detalleModBeca.get('colegio').setValue(this.data.Objeto.Solicitud.Centro.Nombre);
      this.detalleModBeca.get('seccion').setValue(this.data.Objeto.Solicitud.Seccion.Nombre);
      this.detalleModBeca.get('curp').setValue(this.data.Objeto.Solicitud.Alumno.CURP);
      this.detalleModBeca.get('tipoBeca').setValue(this.data.Objeto.TipoBeca.TipoBecaID);
      this.detalleModBeca.get('porcentaje').setValue(this.data.Objeto.BecaModificada);
      this.detalleModBeca.get('nombre').setValue(this.data.Objeto.Solicitud.Alumno.Nombre + ' ' + this.data.Objeto.Solicitud.Alumno.ApellidoPaterno
      + ' ' + this.data.Objeto.Solicitud.Alumno.ApellidoMaterno);
      this.detalleModBeca.get('comentarios').setValue(this.data.Objeto.Comentarios);

      console.log("id "+ this.data.Objeto.ModificacionBecaSolicitudID);
      this.modificacionBecaService.nombreDocumentoModificacionBeca(this.data.Objeto.ModificacionBecaSolicitudID).then(nombre => {
        this.nombreArchivo = nombre;
        console.log("Nombre "+ this.nombreArchivo);
        }).catch(err => {
        console.log("Error" + err);
      }); 
      
    }
  }

  onSubmit(){
    this.error = false;
    let modificacionBeca = this.data.Objeto
    this.guardando = true;   

    modificacionBeca.TipoBeca.TipoBecaID = this.detalleModBeca.get('tipoBeca').value
    modificacionBeca.Comentarios = this.detalleModBeca.get('comentarios').value
    modificacionBeca.BecaModificada = this.detalleModBeca.get('porcentaje').value
    modificacionBeca.UsuarioID = this.idUsuario;

    this.modificacionBecaService.guardarModificacionBeca(modificacionBeca).then( res =>{
      if (res.Exito) {
        const dialogData = new ConfirmDialogModel('', "La operación se realizó correctamente", false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        this.dialogRef.close(true);
        this.importarDocumentos(res.Dato);

      }else{
        this.errorMessage = res.Mensaje;
        this.error = true;       
        this.guardando = false;
      } 
    }

    ).catch(err => {
      console.log("Error" + err);
    }).finally(() => {});
  }

  importarDocumento(SolicitudID: number){ 

    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.modificacionBecaService.importar(filesFD, SolicitudID).pipe(first()).subscribe(data =>
          {
            if(data.Exito){
              var dialogData = new ConfirmDialogModel('', "Información guardada", false);   
              const dialogRef = this.dialog.open(AlertaComponent, {
                maxWidth: "400px",
                data: dialogData
              });        
      
              dialogRef.afterClosed().subscribe( result => {
                //this.router.navigate([ruta]);          
              });
              
            }
          });

      }else {
        console.log("Exceso de peso");
        const dialogData = new ConfirmDialogModel('Error', "El documento excede el peso permitido :5M" , false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
      }
    }else{
      const dialogData = new ConfirmDialogModel('Error', "No se encontró el documento probatorio", false);    
      const dialogRef = this.dialog.open(AlertaComponent, {
        maxWidth: "400px",
        data: dialogData
      });
    }
  }

  seleccionarArchivo(){
    document.getElementById('upload-file').click();
  } 

  importarDocumentos(ModificacionBecaID: number){ 

    let nNulos:number = 0;
    let filesFD = new FormData();

    if(this.Archivo_ != null){
      if( this.Archivo_.size <= this.maxSizeFile){
        filesFD.append("archivo", this.Archivo_, this.Archivo_.name);
        this.enableBtn = false; 
        this.guardando = true;   
        this.modificacionBecaService.importar(filesFD,ModificacionBecaID ).pipe(first())
        .subscribe(data => {
          this.guardando = false;
          this.enableBtn = true;
          if(data.Exito){
            this.dialogRef.close(true);
          }
        });      
        
      }else {
        console.log("Exceso de peso");
        //this.showMessage('Información', "El documento (" + documento.NombreOriginal + ") excede el peso permitido.");
      }
    }else{
      this.dialogRef.close(true);
    }
  }


  adjuntarArchivo(document){
    if (document.target.files[0].type == "application/pdf" || document.target.files[0].type == "application/docx" || 
    document.target.files[0].type == "application/doc"){
      this.Archivo_ = document.target.files[0];
      this.nombreArchivo = document.target.files[0].name;
    }else{
      this.nombreArchivo = "";
    }
  }

  onNoClick(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    }); 
  }

  verify() {
    console.log("verify");
    if(!this.detalleModBeca.invalid && !this.porcentajeErr)
    {
      this.enableBtn = true;
      return true 
    }
    else{
      this.enableBtn = false;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode == 46) return true; 
    if (charCode > 31 && (charCode < 48 || charCode > 57) ) {
      return false;
    }
    return true;
  } 

  validarPorcentaje(){
    console.log("Validar porcentaje");
    this.porcentajeErr = false;
    
    var validaPorcentaje = this.detalleModBeca.get('porcentaje').value;      
    if(validaPorcentaje != null){
      var validaPorcentajeNumber =  Number(validaPorcentaje);      
      if(validaPorcentajeNumber){
        if( validaPorcentajeNumber > 100 || validaPorcentajeNumber < 0){   
          this.detalleModBeca.get('porcentaje').setValue('100');   
        }
      }
      else{
        this.porcentajeErr = true;
      }
    }
  }

}
