import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { GradoSeccionCentro } from '@app/_models/gradoSeccionCentro';

@Injectable({ providedIn: 'root' })
export class GradoEducativoService {

    constructor(private http: HttpClient) {
      }


consultaGradosEducativos(CentroID:string,  SeccionID:number):Promise<GradoEducativo[]>{
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/GradoEducativo/${CentroID}/${SeccionID}/GetXCentroGrado`).toPromise();
}


consultaGradosXSeccion(SeccionID:number):Promise<GradoEducativo[]>{
    return this.http.get<GradoEducativo[]>(`${environment.apiUrl}/GradoEducativo/${SeccionID}/GradosXSeccion`).toPromise();
}

consultaCentrosSecciones(idsCentros : string[], idsSecciones : number[],  idUsuario: number):Promise<GradoEducativo[]>{
    return this.http.post<GradoEducativo[]>(`${environment.apiUrl}/GradoEducativoXCentrosSecciones`, {idsCentros, idsSecciones, idUsuario}).toPromise();    
}    

consultaSiguienteGrado(CentroID : string, SeccionID : number,  GradoID :number, Admision :boolean):Promise<GradoSeccionCentro>{
    return this.http.get<GradoSeccionCentro>(`${environment.apiUrl}/GradoEducativo/${CentroID}/${SeccionID}/${GradoID}/${Admision}/GetSiguienteGrado`).toPromise();    
}    





 
}
