<mat-card-title class="card-title fz-20">NUEVA EMPRESA</mat-card-title>
<mat-divider></mat-divider>
<br><br>
<form [formGroup]="detalleEmpresa" class="modal-form" name="detalleEmpresa" id="detalleEmpresa">  

  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Nombre de empresa</mat-label>
        <input matInput type="text" formControlName="nombreEmpresa" (keypress)="name_omit_special_char($event)" (input)='verify()' required>  
    </mat-form-field>

    <mat-form-field  class="input-full-width xs-width" appearance="outline">
      <mat-label>Correo electrónico</mat-label>
      <input matInput type="email" placeholder="correo@example.com" (input)='verify()' formControlName="correo" required>
      <mat-error *ngIf="detalleEmpresa.controls.correo.invalid">El correo electrónico no tiene un formato correcto.</mat-error>
      <mat-hint *ngIf="successFalse">{{errorCorreo}}</mat-hint>
    </mat-form-field>
    <br>
  </div>

  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Teléfono de empresa</mat-label>
        <input matInput #input (keypress)="numberPhone($event)"  
        type="number" formControlName="telEmpresa" (input)='verify()' required onKeyPress="if(this.value.length > 9) return false;">  
        <mat-hint *ngIf="input.value?.length > 1 && 7 >= input.value?.length || input.value?.length == 9">número inválido</mat-hint>
      </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Extensión</mat-label>
      <input matInput maxlength="10" formControlName="extEmpresa" (input)='verify()' (keypress)="numberPhone($event)">  
    </mat-form-field>
  </div>

  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Nombre(s)</mat-label>
      <input matInput (input)='verify()' (keypress)="name_omit_special($event)" formControlName="nombreContacto" required>
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Apellido paterno</mat-label>
      <input matInput (input)='verify()' (keypress)="name_omit_special($event)" formControlName="appContacto" required>
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Apellido materno</mat-label>
      <input matInput (keypress)="name_omit_special($event)" (input)='verify()' formControlName="apmContacto">
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Costo</mat-label>
        <input matInput type="tel" formControlName="monto" (keypress)="omit_special_mont($event)" 
        (input)='verify()' maxlength="6" required onPaste="return false" autocomplete="off">  
    </mat-form-field>

    <mat-form-field  class="input-full-width xs-width" appearance="outline">
      <mat-label>Tiempo de entrega (Días)</mat-label>
      <input matInput type="text" formControlName="tiempo" (input)='verify()' 
      (keypress)="omit_special_tie($event)" maxlength="6" onPaste="return false" autocomplete="off" required>
    </mat-form-field>
    <br>
  </div>
  
  <mat-form-field  class="input-full-width xs-width" appearance="outline">
    <mat-label>Colegios</mat-label>
    <mat-select #mySelCentro name="centros" formControlName="colegios" (ngModelChange)='verify()' 
      id="centros" required  multiple>
      <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
      <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
        (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
        {{centro.Nombre}}
      </mat-option>
    </mat-select>
  </mat-form-field>    
  
  <mat-hint *ngIf="error" class='card-danger'>{{errorMessage}}</mat-hint>

  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <div class="modal-form margin-div">
    <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="cancelar()" matTooltip="Cancelar la creación" >CANCELAR</button>
    <button mat-raised-button color="primary" class="button xsbtn" (click)="aceptar()" [disabled]="!enableBtn || detalleEmpresa.invalid ||
      input.value?.length > 1 && 7 >= input.value?.length || input.value?.length == 9 || guardando" matTooltip="Guardar Empresa" >GUARDAR</button>
  </div>
</form>