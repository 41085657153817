<mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTab">
    <mat-tab label="Propuesta de becas">
        <app-detalle-propuesta-becas></app-detalle-propuesta-becas>
    </mat-tab>
    <mat-tab label="Resumen de becas"> 
        <app-detalle-resumen-becas></app-detalle-resumen-becas> 
    </mat-tab>
    <mat-tab label="Análisis Comparativo"> 
        <app-detalle-analisis-becas></app-detalle-analisis-becas>
    </mat-tab>
</mat-tab-group>  