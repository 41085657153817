import { MatPaginatorIntl } from '@angular/material/paginator';

export class PaginatorI18n {

  public ObtenerIdioma(): MatPaginatorIntl {
    let paginatorIntl: MatPaginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Elementos por página:';
    paginatorIntl.firstPageLabel = 'Primer página';
    paginatorIntl.previousPageLabel = 'Página anterior';
    paginatorIntl.nextPageLabel = 'Página siguiente';
    paginatorIntl.lastPageLabel = 'Última página';
    paginatorIntl.getRangeLabel = this.obtenerRangeLabel.bind(this);

    return paginatorIntl;
  }

  private obtenerRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex} - ${endIndex} de ${length}`;
  }
}
