import { ContactoEmpresa } from './contactoEmpresa';
import { TelefonoEmpresa } from './telefonoEmpresa';
import { EstudioEmpresa } from './estudioEmpresa';
import { Usuario } from './usuario';
import { Centro } from './centro';

export class Empresa{

    EmpresaID: number;
    Correo : string;
    NombreEmpresa : string;
    ContactoEmpresa : ContactoEmpresa;
    TelefonoEmpresa : TelefonoEmpresa;
    EstudioEmpresa : EstudioEmpresa;
    usuario : Usuario;
    Centros : Centro[];
    IdsCentros: string[];
}
