<mat-card-title class="card-title fz-20">NUEVO CICLO</mat-card-title>
<mat-divider></mat-divider>
<br>
<form class="modal-form" name="detalleCicloForm" id="detalleCicloForm" [formGroup]="detalleCicloForm" autocomplete="off">

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Clave</mat-label>
        <input matInput type="text" id="clave" name="clave" formControlName="clave" (input)='verify()' required
        oninput="this.value = this.value.toUpperCase()" (keypress)="omit_special_char($event)" onPaste="return false">
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Nombre del ciclo</mat-label>
        <input matInput type="text" id="nombre" (keypress)="omit_special_char($event)" name="nombre" formControlName="nombre" (input)='verify()' required>
    </mat-form-field>

    <mat-form-field  class="input-full-width xs-width" appearance="outline">
        <mat-label>Becas</mat-label>
        <mat-select #mySelBecas name="becas" formControlName="becas"  
        [(value)]="selectedBecas" (ngModelChange)='verify()'
        id="becas" required multiple>
          <mat-option  #allSelectedBecas  (click)="toggleAllSelectionBeca()" [value]="0">
            <label>Todos</label></mat-option>
          <mat-option *ngFor="let beca of becas" [value]="beca.TipoBecaID"
          (click)="tosslePerOneBecas(allSelectedBecas.viewValue)"> 
            {{beca.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="data.Operation == 'Editar'" class="input-full-width xs-width"  appearance="outline">
        <button mat-icon-button color="basic" matTooltip="Agregar especificación por centro"
        (click)="especificacionXCentro()" 
        matTooltipPosition="right" >
        <mat-icon>dns</mat-icon>Fechas por centros</button>
    </div>

  



    <br><br>
    <table>
        <tr>
            <td style="width: 45%;" >
            </td>
            <td style="text-align: left; width: 18rem;">
                <mat-label class="color-marino">Desde</mat-label> 
            </td>
            <td>
                <mat-label class="color-marino">Hasta</mat-label> 
            </td>
        </tr>
    </table>
    <table>
        <tr>
            <td>
                <mat-label class="color-marino">Fecha ciclo</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaFin.value" 
                        name ="FechaIni" placeholder="dd/mm/yyyy"
                        (ngModelChange)='verify()'
                    [matDatepicker]="pickerCI" formControlName="FechaIni"
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaIni.invalid || fechaCicloError">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="pickerCI"></mat-datepicker-toggle>
                    <mat-datepicker #pickerCI></mat-datepicker> 
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaFin" matInput [min]="detalleCicloForm.controls.FechaIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="pickerCF" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaFin.invalid || fechaCicloError">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="pickerCF"></mat-datepicker-toggle>
                    <mat-datepicker #pickerCF></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Solicitud de beca</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaSolicitudFin.value" 
                        name ="FechaSolicitudIni" placeholder="dd/mm/yyyy"
                       (ngModelChange)='verify()'
                    [matDatepicker]="picker" formControlName="FechaSolicitudIni"
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaSolicitudIni.invalid">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> 
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaSolicitudFin" matInput [min]="detalleCicloForm.controls.FechaSolicitudIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker2" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaSolicitudFin.invalid">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Solicitud Bachillerato</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput [min]="minDate" 
                    [max]="detalleCicloForm.controls.FechaFin.value" 
                        name ="FechaBachilleratoIni" placeholder="dd/mm/yyyy"
                       (ngModelChange)='verify()'
                    [matDatepicker]="picker19" formControlName="FechaBachilleratoIni"
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaBachilleratoIni.invalid">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="picker19"></mat-datepicker-toggle>
                    <mat-datepicker #picker19></mat-datepicker> 
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaBachilleratoFin" matInput 
                    [min]="minDate" 
                    [max]="detalleCicloForm.controls.FechaFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker20" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaBachilleratoFin.invalid">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                    <mat-datepicker #picker20></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Revisión de solicitudes</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRevisionSolIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaRevisionSolFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker3" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)"> 
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRevisionSolIni.invalid">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRevisionSolFin" matInput [min]="detalleCicloForm.controls.FechaRevisionSolIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker4" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">     
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRevisionSolFin.invalid">
                        Formato de fecha inválida.</mat-error>         
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Retroalimentación de solicitudes</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRetroSolIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaRetroSolFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker5" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRetroSolIni.invalid">
                        Formato de fecha inválida.</mat-error>                  
                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRetroSolFin" matInput [min]="detalleCicloForm.controls.FechaRetroSolIni.value" [max]="maxDate"
                    placeholder="dd/mm/yyyy"  [matDatepicker]="picker6" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRetroSolFin.invalid">
                        Formato de fecha inválida.</mat-error>                  
                    <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                    <mat-datepicker #picker6></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Estudio socioeconómico</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaEstudioIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaEstudioFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker7" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">
                    <mat-error *ngIf="detalleCicloForm.controls.FechaEstudioIni.invalid">
                        Formato de fecha inválida.</mat-error>               
                    <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
                    <mat-datepicker #picker7></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaEstudioFin" matInput [min]="detalleCicloForm.controls.FechaEstudioIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker8" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)"> 
                    <mat-error *ngIf="detalleCicloForm.controls.FechaEstudioFin.invalid">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
                    <mat-datepicker #picker8></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Evaluación de comité</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaEvaluacionIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaEvaluacionFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker9" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">    
                    <mat-error *ngIf="detalleCicloForm.controls.FechaEvaluacionIni.invalid">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
                    <mat-datepicker #picker9></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaEvaluacionFin" matInput [min]="detalleCicloForm.controls.FechaEvaluacionIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker10" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaEvaluacionFin.invalid">
                        Formato de fecha inválida.</mat-error>                
                    <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                    <mat-datepicker #picker10></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Propuesta de becas</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaCreacionPropIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaCreacionPropFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker11" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaCreacionPropIni.invalid">
                        Formato de fecha inválida.</mat-error>                
                    <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                    <mat-datepicker #picker11></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaCreacionPropFin" matInput [min]="detalleCicloForm.controls.FechaCreacionPropIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker12" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">  
                    <mat-error *ngIf="detalleCicloForm.controls.FechaCreacionPropFin.invalid">
                        Formato de fecha inválida.</mat-error>                
                    <mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
                    <mat-datepicker #picker12></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Revisión de propuesta de becas</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRevisionPropIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaRevisionPropFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker13" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">   
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRevisionPropIni.invalid">
                        Formato de fecha inválida.</mat-error>            
                    <mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
                    <mat-datepicker #picker13></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRevisionPropFin" matInput [min]="detalleCicloForm.controls.FechaRevisionPropIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker14" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRevisionPropFin.invalid">
                        Formato de fecha inválida.</mat-error>               
                    <mat-datepicker-toggle matSuffix [for]="picker14"></mat-datepicker-toggle>
                    <mat-datepicker #picker14></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Retroalimentación de propuesta de becas</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRetroPropIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaRetroPropFin.value" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker15" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">   
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRetroPropIni.invalid">
                        Formato de fecha inválida.</mat-error>               
                    <mat-datepicker-toggle matSuffix [for]="picker15"></mat-datepicker-toggle>
                    <mat-datepicker #picker15></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaRetroPropFin" matInput [min]="detalleCicloForm.controls.FechaRetroPropIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker16" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)"> 
                    <mat-error *ngIf="detalleCicloForm.controls.FechaRetroPropFin.invalid">
                        Formato de fecha inválida.</mat-error>                 
                    <mat-datepicker-toggle matSuffix [for]="picker16"></mat-datepicker-toggle>
                    <mat-datepicker #picker16></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Publicación de resultados</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaPublicacionIni" matInput min="2000-01-01" [max]="detalleCicloForm.controls.FechaPublicacionFin.value"
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker17"(ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)">   
                    <mat-error *ngIf="detalleCicloForm.controls.FechaPublicacionIni.invalid">
                        Formato de fecha inválida.</mat-error>             
                    <mat-datepicker-toggle matSuffix [for]="picker17"></mat-datepicker-toggle>
                    <mat-datepicker #picker17></mat-datepicker>
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input formControlName="FechaPublicacionFin" matInput [min]="detalleCicloForm.controls.FechaPublicacionIni.value" [max]="maxDate" 
                    placeholder="dd/mm/yyyy"  [matDatepicker]="picker18" (ngModelChange)='verify()'
                    (keypress)="validarCaracteres($event)"> 
                    <mat-error *ngIf="detalleCicloForm.controls.FechaPublicacionFin.invalid">
                        Formato de fecha inválida.</mat-error>                
                    <mat-datepicker-toggle matSuffix [for]="picker18"></mat-datepicker-toggle>
                    <mat-datepicker #picker18></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
    </table>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br><br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="onNoClick()" matTooltip="Cancelar la creación">CANCELAR</button>
        <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || detalleCicloForm.invalid || guardando" matTooltip="Guardar Ciclo">GUARDAR</button>
    </div>

    <div *ngIf="error">
        <br>
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>    
</form>