import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Seccion } from '@app/_models/seccion';
import { TipoBecaReqCentro } from '@app/_models/tipoBecaReqCentro';
import { TipoBecaReqSeccion } from '@app/_models/tipoBecaReqSeccion';
import { SeccionService } from '@app/_services/seccion.service';
import { TipobecaRequisitoCentroComponent } from '../tipobeca-requisito-centro/tipobeca-requisito-centro.component';
import { TipobecaReqSecCenGraComponent } from '../tipobeca-req-sec-cen-gra/tipobeca-req-sec-cen-gra.component';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
@Component({
  selector: 'app-tipobeca-requisito-centro-sec',
  templateUrl: './tipobeca-requisito-centro-sec.component.html',
  styleUrls: ['./tipobeca-requisito-centro-sec.component.scss']
})
export class TipobecaRequisitoCentroSecComponent implements OnInit {

  displayedColumnsSecciones: string[] = ['aplicable','Nombre', 'minimo', 'maximo', 'grado'];
  displayedColumns: string[] = ['aplicable','Nombre', 'grado'];
  dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>();

  secciones: Seccion[] = [];
  dataTipoBecaReqCentro :  TipoBecaReqCentro;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  enableBtn = false;
  tipoRequisito: string = "";
  reglonSeleccionado :TipoBecaReqSeccion;

  constructor(    private seccionService: SeccionService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TipobecaRequisitoCentroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaReqCentro = data.Objeto;
    }

  ngOnInit(): void {
    
    this.enableBtn = true;
    this.tipoRequisito = this.dataTipoBecaReqCentro.EspecificacionReq;
    if(this.dataTipoBecaReqCentro.RequisitoSecciones == null ||this.dataTipoBecaReqCentro.RequisitoSecciones.length == 0){
      this.initSecciones();
    }else{
      this.dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>(this.dataTipoBecaReqCentro.RequisitoSecciones);
      this.length = this.dataSourceRequisitoSeccion.data.length;
    }
  }

  initSecciones(){
    this.guardando = true;
    var CentroID = this.dataTipoBecaReqCentro.Centro.CentroID;
    var TipoBecaID = this.dataTipoBecaReqCentro.TipoBecaID;
    this.seccionService.consultaSeccionesXCentroTipoBeca( CentroID, TipoBecaID).then(secciones => {
      this.secciones = secciones;
      this.initTable();
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  initTable(){
    this.dataTipoBecaReqCentro.RequisitoSecciones = [];
    this.secciones.forEach(
      x => {
        var RequisitoSeccion = new TipoBecaReqSeccion();

        RequisitoSeccion.Seccion = x;
        RequisitoSeccion.TipoBecaRequisito = this.dataTipoBecaReqCentro.TipoBecaRequisito;        
        RequisitoSeccion.Minimo = this.dataTipoBecaReqCentro.Minimo;
        RequisitoSeccion.Maximo = this.dataTipoBecaReqCentro.Maximo;
        RequisitoSeccion.RequisitoGrados = [];
        this.dataTipoBecaReqCentro.RequisitoSecciones.push(RequisitoSeccion);
      }  
    );
    this.dataSourceRequisitoSeccion = new MatTableDataSource<TipoBecaReqSeccion>(this.dataTipoBecaReqCentro.RequisitoSecciones);
    this.length = this.dataSourceRequisitoSeccion.data.length;
    this.guardando = false;
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  
  especificacionXGrado(renglon : TipoBecaReqSeccion){
    const mCopy = JSON.parse(JSON.stringify(renglon));
    this.reglonSeleccionado = renglon;
    renglon.TipoBecaRequisitoCentroSecID = this.dataTipoBecaReqCentro.TipoBecaRequisitoCentroSecID;
    renglon.TipoBecaRequisito= this.dataTipoBecaReqCentro.TipoBecaRequisito;

    if(mCopy.RequisitoGrados == null){
      mCopy.RequisitoGrados = [];
    }
    const dialogRef = this.dialog.open(TipobecaReqSecCenGraComponent, {
      width: '60%',
      data: {Operation: 'Editar', Objeto: renglon}
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.agregarRequisitosGrados(renglon);
      }else{
        var requisitoBeca =this.dataTipoBecaReqCentro.RequisitoSecciones.find(r => r.Seccion.SeccionID  == renglon.Seccion.SeccionID);
        requisitoBeca.RequisitoGrados = mCopy.RequisitoGrados;      
      }
    });
  }

  eliminarEspecificacionXGrado(renglon : TipoBecaReqSeccion){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar las especificaciones por grado?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        renglon.RequisitoGrados  = [];
      }
    });    
  }


  agregarRequisitosGrados(tipoBecaReqSeccion : TipoBecaReqSeccion){
    this.reglonSeleccionado.RequisitoGrados = tipoBecaReqSeccion.RequisitoGrados;
  }  




  validateMinMax(tipoBecaReq:TipoBecaReqSeccion){
    
    if(  ( tipoBecaReq.Minimo  >=  this.dataTipoBecaReqCentro.Minimo  &&   tipoBecaReq.Minimo <= this.dataTipoBecaReqCentro.Maximo)
        && (tipoBecaReq.Maximo  >=   this.dataTipoBecaReqCentro.Minimo &&   tipoBecaReq.Maximo <= this.dataTipoBecaReqCentro.Maximo)){
      tipoBecaReq.Invalido = false;
    }else{
      tipoBecaReq.Invalido = true;
    }
    this.verify();
    console.log("Validando" + this.enableBtn);

    if(tipoBecaReq.Maximo < tipoBecaReq.Minimo) {
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }
  }

 verify(){
  this.enableBtn = true;
  this.dataTipoBecaReqCentro.RequisitoSecciones.forEach(element => {
    if(element.Invalido){
      this.enableBtn = false;
    }
  });
 }
}