import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../_services/navigation.service';
import { MsalService } from '@azure/msal-angular';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatDialogModule, MatDialogClose, MatDialogActions} from '@angular/material/dialog';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private navigationService: NavigationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SideBarComponent>
  ) 
  {
    this.authenticationService.currentUser.subscribe(user => {
      this.isAdmin = false;
      this.isColab = false;

      this.userName = "";
      if (user != null) {
        this.isAdmin = user.IsAdmin;
        this.isColab = user.IsEmpleado;
        this.isEmpresa = user.IsEmpresa;
        this.isRevisionSolicitud =  user.Roles.indexOf("REVISOL") > -1 || user.IsAdmin;
        this.isRevisionRequisitos =  user.Roles.indexOf("REVIREQ") > -1 || user.IsAdmin;
        //this.isAsignacionPresupuesto = user.Roles.indexOf("ASIGPRESU") > -1 || user.IsAdmin;
        this.isReporteSolicitudes = user.Roles.indexOf("REPORSOL") > -1 || user.IsAdmin;
        this.isPropBecas =user.Roles.indexOf("PROPBECA") > -1 || user.IsAdmin;
        this.isReportePresupuesto = user.Roles.indexOf("REPORPRESU") > -1 || user.IsAdmin;
        this.isEstudioSocioeco = user.Roles.indexOf("ESTUDIOSOC") > -1 || user.IsAdmin;
        this.isCatalogoEmpresas = user.Roles.indexOf("CATEMPR") > -1 || user.IsAdmin;
        this.isAperturaCiclos = user.Roles.indexOf("APERCICLO") > -1 || user.IsAdmin;
        this.isAsignacionBecasExt = user.Roles.indexOf("ASIGBEEXT") > -1 || user.IsAdmin;
        this.isConfiguracionBecas = user.Roles.indexOf("CONFBECAS") > -1 || user.IsAdmin;        
        this.isCoordinador =  user.Roles.indexOf("REVPROPBECA") > -1 || user.IsAdmin;
        this.isDirector =  user.Roles.indexOf("APROPROPBECA") > -1 || user.IsAdmin;        
        this.isCartaBeca =  user.Roles.indexOf("CARTABECA") > -1 || user.IsAdmin;
        this.isAdmSobrepasos = user.Roles.indexOf("ADMSOBREPASOS") > -1 || user.IsAdmin;
        this.isConsolidado = user.Roles.indexOf("CONSOLIDADO") > -1 || user.IsAdmin;
        this.isModBecas = user.Roles.indexOf("MODBECAS") > -1 || user.IsAdmin;
        this.isRevModBecas = user.Roles.indexOf("REVMODBECAS") > -1 || user.IsAdmin;
        this.isBecaExtemporanea = user.Roles.indexOf("BECAEXTEMPORANEA") > -1 || user.IsAdmin;
        this.isCargas = user.Roles.indexOf("CARGAS") > -1 || user.IsAdmin;
        this.isRenovacionBecas = user.Roles.indexOf("RENOVACION") > -1 || user.IsAdmin;

        this.isProspect = user.IsProspecto;
        this.isAuthenticated = true;
     
        this.userName = user.NombreEmpresa ? user.NombreEmpresa : user.Nombre + " " + user.ApellidoPaterno + " " + user.ApellidoMaterno;
      }
    })
  }

  ngOnInit() {
  }

  public isAuthenticated: boolean = false;
  public isProspect: boolean = false;
  public isAdmin: boolean = false;
  public isColab: boolean = false;
  public isCoordinador : boolean = false;
  public isDirector : boolean =false;
  public isEmpresa: boolean = false;  
  public isRevisionSolicitud = false;
  public isRevisionRequisitos = false;
  public isAsignacionPresupuesto = false;
  public isReporteSolicitudes = false;
  public isPropBecas = false;
  public isReportePresupuesto = false;
  public isEstudioSocioeco = false;
  public isCatalogoEmpresas = false;
  public isAperturaCiclos = false;
  public isAsignacionBecasExt = false;
  public isConfiguracionBecas = false;
  public isCartaBeca = false;
  public isAdmSobrepasos = false;
  public isConsolidado = false;
  public isModBecas = false;
  public isRevModBecas = false;
  public isBecaExtemporanea = false;
  public isCargas = false;
  public isRenovacionBecas = false;

  public userName = "";

  public logout() {
    if(this.isColab){
      var loggedIn = !!this.authService.getAccount();
      if(loggedIn){
        this.authService.logout();
      }
    }
    this.navigationService.isToggledMenu.next(false);
    this.authenticationService.logout();
    this.router.navigate(['/logout']);
  }


  cancelar(): void {
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro que desea cerrar sesión?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData  
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.logout();
      }
    }); 
  }
}
