<mat-card *ngIf="enable_" class="recupera-card">
    <mat-card-title class="card-title fz-40"> {{titulo}} </mat-card-title>
    <mat-divider></mat-divider>
    <br><br>
    <form class="example-form" name="cambioPasswordForm" id="cambioPasswordForm" [formGroup]="cambioPasswordForm">
        <div class="text-center">
            <mat-form-field class="recupera-full-width xs-width" appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" id="password" 
                (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
                name="password" formControlName="password" required
                matTooltip="La contraseña debe tener al menos 8 caractéres, mayúsculas, minúsculas y al menos un número o caracter especial"
                (ngModelChange)="updateProgressBar()" >
                <mat-hint *ngIf="fortalezaError">
                    La contraseña no cumple con los requisitos de seguridad 
                </mat-hint>
            </mat-form-field>
            <mat-hint *ngIf="mostrarDescripcionPass" class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o caracter especial</mat-hint>       
             
            <mat-form-field class="recupera-full-width xs-width" appearance="outline">    
                <mat-label>Vuelve a escribir tu contraseña</mat-label>      
                <input matInput placeholder="Vuelve a escribir tu contraseña" type="password" id="confirmPassword" name="confirmPassword" 
                formControlName="confirmPassword" [errorStateMatcher]="matcher">
                <mat-hint *ngIf="cambioPasswordForm.hasError('notSame')">
                    La contraseña no coincide
                </mat-hint>  
            </mat-form-field>
            <br>
            <div #recaptcha *ngIf="showInput"></div>
        </div>
        <div class="text-center">
            <mat-progress-bar *ngIf="containsPssword"
            mode="determinate"
            class="example-full-width"
            [color]="colorForce"        
            [value]="valueForce"
            [bufferValue]="bufferValue">
            </mat-progress-bar>
        </div>
        <br><br><br>
        <div class="text-center">
            <button class="button" mat-raised-button  (click)="cambiarContrasena()">Crear contraseña</button>
        </div>
    </form>
</mat-card>

<mat-card *ngIf="!enable_" class="recupera-card">
    <br>
    <mat-card-title class=""><div class="titulo-danger center">¡Enlace inválido!</div></mat-card-title>
    <br>
    <div class="texto-informativo">
        <label class=''>
        El enlace de recuperación de contraseña ya no es válido. 
        <br>
        <br>
        Si requieres recuperar tu contraseña, de favor ingresa <a id="link" href="#" routerLink="/recupera-cuenta" >aquí</a>.
        <br>
        </label>           
    </div>
</mat-card>
