<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button [class]="{ 'buttonAnahuac' : isAnahuac, 'button' : !isAnahuac }" (click)="onConfirm()">{{modoSiNo ? 'Si' : 'Aceptar'}}</button>
  <button mat-raised-button color="warn" (click)="onDismiss()" *ngIf="modoSiNo">No</button>

</div>