import { Usuario } from './usuario';
import { Responsable } from './responsable';
import { Centro } from './centro';

export class Alumno {

    AlumnoID: number;
    Usuario: Usuario;
    Matricula :  string;
    CURP : string;
    FechaNacimiento: Date;
    Password: string;
    Centro : Centro;
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;  

    Responsables :  Responsable[];  
  }