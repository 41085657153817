import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Seccion } from '@app/_models/seccion';

@Injectable({ providedIn: 'root' })
export class SeccionService {

    constructor(private http: HttpClient) {
      }

    getAll():Promise<Seccion[]>{
        return this.http.get<Seccion[]>(`${environment.apiUrl}/Seccion/GetAll`).toPromise();
    }



    consultaSecciones(CentroID:string):Promise<Seccion[]>{
        return this.http.get<Seccion[]>(`${environment.apiUrl}/Seccion/${CentroID}/ObtenerXCentro`).toPromise();
    }


    consultaSeccionesXCentroTipoBeca(CentroID:string, TipoBecaID : number):Promise<Seccion[]>{
        return this.http.get<Seccion[]>(`${environment.apiUrl}/Seccion/${CentroID}/${TipoBecaID}/ObtenerXCentroSeccion`).toPromise();
    }

    consultaSeccionesXTipoBeca(TipoBecaID:number):Promise<Seccion[]>{
        return this.http.get<Seccion[]>(`${environment.apiUrl}/Seccion/${TipoBecaID}/ObtenerXTipoBeca`).toPromise();
    }
    


    consultaSeccionesXCentros(ids : number[], idUsuario: number):Promise<Seccion[]>{
        return this.http.post<Seccion[]>(`${environment.apiUrl}/SeccionXCentros?idUsuario=${idUsuario}`, ids).toPromise();    
    }    

    seccionesByUser(idUsuario: number):Promise<Seccion[]>{
        return this.http.get<Seccion[]>(`${environment.apiUrl}/${idUsuario}/SeccionXUsuario`).toPromise();
    } 
 
}
