import {SelectionModel} from '@angular/cdk/collections';
import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { BecaOtorgada } from '@app/_models/becaOtorgada';
import { ObjetoOp } from '@app/_models/objectOperation';
import { RenovacionBeca } from '@app/_models/renovacionBeca';
import { AuthenticationService } from '@app/_services/authentication.service';
import { RenovacionBecaService } from '@app/_services/renovacionBeca.service';
import { first } from 'rxjs/operators';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';



@Component({
  selector: 'app-detalle-renovacion-beca',
  templateUrl: './detalle-renovacion-beca.component.html',
  styleUrls: ['./detalle-renovacion-beca.component.scss']
})
export class DetalleRenovacionBecaComponent implements OnInit {

  displayedColumns: string[] = ['renovacion', 'condicionada', 'reporteSep', 'colegio', 'curp', 'nombre',
    'grado', 'tipoBeca', 'porcentaje'];
  dataSource = new MatTableDataSource<BecaOtorgada>();
  //selection = new SelectionModel<BecaOtorgada>(true, []);

  filtro: string = "";
  buscando:boolean = false;  
  renovacionBeca : RenovacionBeca;
  esperando : boolean;
  becasOtorgadas: BecaOtorgada[];
  editar = false;
  idUsuario : number;

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog, 
    public dialogRef: MatDialogRef<DetalleRenovacionBecaComponent>,
    private authenticationService: AuthenticationService,
    private renovacionService : RenovacionBecaService,
    public dialogClose: MatDialogRef<DetalleRenovacionBecaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { }

  ngOnInit(): void {
    this.esperando = true;
    this.renovacionBeca = this.data.Objeto;
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.editar = (this.data.Operation == 'Editar');
    console.log("Editar " + this.editar);

    this.renovacionService.getBecasOtorgadas(this.renovacionBeca.PropuestaBeca.Ciclo.CicloID,
      this.renovacionBeca.PropuestaBeca.Centro.CentroID, this.filtro).pipe(first()).subscribe(
        d => {
          this.becasOtorgadas = d;
          this.dataSource = new MatTableDataSource<BecaOtorgada>(this.becasOtorgadas);
          this.esperando = false;
        }
      );


  }

  save(){
    this.esperando = true;
    this.renovacionBeca.UsuarioID = this.idUsuario;
    this.renovacionService.guardarRenovacion(this.renovacionBeca, this.becasOtorgadas ).pipe(first()).subscribe(
        res => {
          this.esperando = false;
          if(res.Exito){
            const dialogData = new ConfirmDialogModel('', "Renovación almacenada", false);    
            const dialogRef = this.dialog.open(AlertaComponent, {
              maxWidth: "400px",
              data: dialogData
            }); 
            this.dialogClose.close(true);
          }
        }
      );

  }

  
  filterText(){
    if(!this.buscando){
      this.buscando = true;
      setTimeout (() => {
        this.esperando = true;
        this.renovacionService.getBecasOtorgadas(this.renovacionBeca.PropuestaBeca.Ciclo.CicloID,
          this.renovacionBeca.PropuestaBeca.Centro.CentroID, this.filtro).pipe(first()).subscribe(
            d => {
              this.becasOtorgadas = d;
              this.dataSource = new MatTableDataSource<BecaOtorgada>(this.becasOtorgadas);
              this.esperando = false;
            }
          );

        this.buscando = false;
      }, 3000);   
    }
  }

  onNoClick(): void {
    this.dialogClose.close(false);
  }


}
