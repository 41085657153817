<div class="example-form mat-elevation-z8">
  <form class="modal-form" name="nuevaPropuestaCuota" id="nuevaPropuestaCuota" [formGroup]="nuevaPropuestaCuota" autocomplete="off">
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Sección</mat-label>
      <mat-select  name="seccion" formControlName="seccion" id="seccion" (ngModelChange)='verify()' (openedChange)="onSelectSeccion($event)" >
          <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"> 
            {{seccion.Nombre}}
          </mat-option>
      </mat-select>
    </mat-form-field>   
  </form>
  <div class="modal-form margin-div">
    <button mat-raised-button (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
    <button mat-raised-button (click)="save()" *ngIf="propuestaEditable" class="button xsbtn" 
      [disabled]="!enableBtn || porcentajeMax || porcentajeMin || revicion || esperando">GUARDAR</button>
  </div>
  <br><br>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
    <ng-container matColumnDef="grado">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element" class="bl bg-blue"> {{element.GradoEducativo.Nombre}} </td>
    </ng-container>
  
    <ng-container matColumnDef="seccion">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element" class="bg-blue"> {{element.Seccion.Nombre}} </td>
    </ng-container>
  
    <ng-container matColumnDef="colegiatura">
      <th mat-header-cell *matHeaderCellDef class="bl bt bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center"> 
        <input 
        name="element.Colegiatura" 
        id="element.Colegiatura" 
        maxlength="10" 
        data-type="currency" 
        [value]="'$' + element.Colegiatura"
        [disabled]="revicion ||!propuestaEditable" 
        matInput
        (input)="calculaPropuesta(element, $event.target)"
        >
        <mat-hint *ngIf="1 > element.Colegiatura">Min $1</mat-hint>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="incremento">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> COLEGIATURA </th>
      <td mat-cell *matCellDef="let element" class="text-center"> 
        <input 
        [disabled]="revicion || !propuestaEditable" matInput 
        [ngModel] ="element.PorcentajeIncCol + '%'" 
        maxlength="6" data-type="currency" 
        (ngModelChange)="element.PorcentajeIncCol=$event" 
        (input)="calculaPropuesta(element, $event.target)"
        onClick="this.select();"
      >
      <mat-hint *ngIf="element.PorcentajeIncCol > 100">Max 100</mat-hint>
      <mat-hint *ngIf="1 > element.PorcentajeIncCol">Min 1</mat-hint>  
      </td>
    </ng-container>
  
    <ng-container matColumnDef="colegiaturapro">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center br bg-blue"> 
        <input matInput type="text" disabled  [ngModel] ="element.ColegiaturaPropuesta | currency:'MXN'" (ngModelChange)="element.ColegiaturaPropuesta=$event" >                            
      </td>
    </ng-container>
  
    <ng-container matColumnDef="reincripcion">
      <th mat-header-cell *matHeaderCellDef class="bt bl bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center bl"> 
        <input 
        name="element.Reinscripcion" 
        id="element.Reinscripcion" 
        data-type="currency" 
        maxlength="10" 
        [value]="'$' + element.Reinscripcion"
        [disabled]="revicion ||!propuestaEditable" 
        matInput type="text" 
        (input)="calculaPropuesta(element, $event.target)"
      >                           
      <mat-hint *ngIf="1 > element.Reinscripcion">Min $1</mat-hint>  
      </td>
    </ng-container>

    <ng-container matColumnDef="increm">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> REINSCRIPCION </th>
      <td mat-cell *matCellDef="let element" class="text-center"> 
        <input 
        [disabled]="revicion || !propuestaEditable" matInput 
        [ngModel] ="element.PorcentajeIncReinscripcion + '%'" 
        maxlength="6" 
        (ngModelChange)="element.PorcentajeIncReinscripcion=$event" 
        (ngModelChange)="validarPorcentaje($event)" 
        (input)="calculaPropuesta(element, $event.target)"
      >                                       
      <mat-hint *ngIf="element.PorcentajeIncReinscripcion > 100">Max 100</mat-hint>
      <mat-hint *ngIf="1 > element.PorcentajeIncReinscripcion">Min 1</mat-hint>  
      </td>
    </ng-container>
  
    <ng-container matColumnDef="reincripcionpr">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center br bg-blue"> 
        <input matInput type="text" disabled [ngModel] ="element.ReinscripcionPropuesta | currency:'MXN'" (ngModelChange)="element.ReinscripcionPropuesta=$event">                                          
      </td>
    </ng-container>

    <ng-container matColumnDef="inscripcionAnual">
      <th mat-header-cell *matHeaderCellDef class="bt bl bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center bl"> 
        <input 
          name="element.Inscripcion" 
          id="element.Inscripcion" 
          data-type="currency" 
          [value]="'$' + element.Inscripcion"
          maxlength="10" 
          [disabled]="revicion || !propuestaEditable" 
          matInput type="text" 
          (input)="calculaPropuesta(element, $event.target)"
        >                           
        <mat-hint *ngIf="1 > element.Inscripcion">Min $1</mat-hint>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="incrementoAnual">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> INSCRIPCION </th>
      <td mat-cell *matCellDef="let element" class="text-center"> 
        <input 
        [disabled]="revicion || !propuestaEditable" matInput 
        [ngModel] ="element.PorcentajeIncIns + '%'" 
        (keypress)="numberOnly($event)"
        maxlength="6" 
        (ngModelChange)="element.PorcentajeIncIns=$event" 
        (ngModelChange)="validarPorcentaje($event)" 
        (input)="calculaPropuesta(element, $event.target)"
        >                                 
        <mat-hint *ngIf="element.PorcentajeIncIns > 100">Max 100</mat-hint>
        <mat-hint *ngIf="1 > element.PorcentajeIncIns">Min 1</mat-hint>   
      </td>
    </ng-container>
  
    <ng-container matColumnDef="inscPropuesta">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center bg-blue"> 
        <input matInput type="text" disabled [ngModel] ="element.InscripcionPropuesta | currency:'MXN'" (ngModelChange)="element.InscripcionPropuesta=$event">                                          
      </td>
    </ng-container>

    <ng-container matColumnDef="ncolegiatura">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> </th>
      <td mat-cell *matCellDef="let element" class="text-center br bg-blue"> 
        <input [disabled]="revicion || !propuestaEditable" #inputCol matInput [ngModel] ="element.Colegiaturas" (keypress)="numberOnly($event)" 
        maxlength="10" (ngModelChange)="element.Colegiaturas=$event" (ngModelChange)="validarColegiatura($event)">                                                       
        <mat-hint *ngIf="0 > inputCol.value">num. invalido</mat-hint>  
      </td>
    </ng-container>

    <!-- grado Description Column -->
    <ng-container matColumnDef="grado-description">
      <th mat-header-cell *matHeaderCellDef class="bl bt bg-green" style="text-align: inherit !important;"> Grado </th>
    </ng-container>
  
    <!-- seccion Description Column -->
    <ng-container matColumnDef="seccion-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> Sección </th>
    </ng-container>
  
     <!-- colegiatura Description Column -->
     <ng-container matColumnDef="colegiatura-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> Colegiatura <br> actual </th>
    </ng-container>

    <!-- incremento Description Column -->
    <ng-container matColumnDef="incremento-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> % Incremento  </th>
    </ng-container>
  
    <!-- colegiaturapro Description Column -->
    <ng-container matColumnDef="colegiaturapro-description">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> Colegiatura <br> propuesta  </th>
    </ng-container>
  
     <!-- reincripcion Description Column -->
     <ng-container matColumnDef="reincripcion-description">
      <th mat-header-cell *matHeaderCellDef class="bt bl bg-green"> Reinscripción  <br> actual </th>
    </ng-container>

    <!-- increm Description Column -->
    <ng-container matColumnDef="increm-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> % Incremento   </th>
    </ng-container>
  
    <!-- reincripcionpr Description Column -->
    <ng-container matColumnDef="reincripcionpr-description">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> Reinscripción </th>
    </ng-container>
  
     <!-- inscripcionAnual Description Column -->
     <ng-container matColumnDef="inscripcionAnual-description">
      <th mat-header-cell *matHeaderCellDef class="bt bl bg-green"> Inscripción </th>
    </ng-container>

    <!-- incrementoAnual Description Column -->
    <ng-container matColumnDef="incrementoAnual-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> % Incremento  </th>
    </ng-container>
  
    <!-- inscPropuesta Description Column -->
    <ng-container matColumnDef="inscPropuesta-description">
      <th mat-header-cell *matHeaderCellDef class="bt bg-green"> Inscripción <br> propuesta </th>
    </ng-container>
  
     <!-- ncolegiatura Description Column -->
     <ng-container matColumnDef="ncolegiatura-description">
      <th mat-header-cell *matHeaderCellDef class="bt br bg-green"> Colegiaturas </th>
    </ng-container>

    <!-- The table will render two header rows, one data row per data object, and two footer rows. -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"
        class="example-first-header-row">
    </tr>
    <tr mat-header-row *matHeaderRowDef="[
      'grado-description', 
      'seccion-description', 
      'colegiatura-description',
      'incremento-description', 
      'colegiaturapro-description', 
      'reincripcion-description', 
      'increm-description', 
      'reincripcionpr-description', 
      'inscripcionAnual-description', 
      'incrementoAnual-description', 
      'inscPropuesta-description', 
      'ncolegiatura-description']"
        class="example-second-header-row">
    </tr>
  
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>         
<br> 
<div class="loading-screen-icon" *ngIf="esperando">
  <mat-spinner></mat-spinner>
</div>

<br><br><br><br>