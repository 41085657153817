import { GradoEducativo } from './gradoEducativo';


export class TipoBecaReqSecGra{

    public constructor() {}
    Aplicable : boolean;
    Minimo : number;
    Maximo : number;
    Grado: GradoEducativo;
    
    TipoBecaRequisitoSeccionID: number;
    TipoBecaRequisitoCentroSecID : number;

    Invalido :boolean;
}