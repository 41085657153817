import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { ResponseRest } from '@app/_models/responseRest';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';

@Injectable({ providedIn: 'root' })
export class BecasExtemporaneasService {

    constructor(private http: HttpClient) {}

    buscar(nRegistros:number, nPagina:number, cicloID : number, centros : string[], secciones : number[], nombre : string, UsuarioID : number){
        return this.http.post<any>(`${environment.apiUrl}/ListadoBecasExtemporaneas`,  {nRegistros, nPagina, cicloID, centros, secciones, nombre, UsuarioID} );
    }

    guardarExtemporanea(extemporanea: BecaExtemporanea){
        return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/BecaExtemporanea`, extemporanea  ).toPromise();    
    }
    
    importar(archivos: FormData, BecaExtemporaneaID: number) {
    
    let params = new HttpParams();
    params = params.append('BecaExtemporaneaID', BecaExtemporaneaID.toString() );

    const options = {
        headers: new HttpHeaders(),
        params: params
    }

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/BecaExtemporanea/GuardarDocumento`, archivos, { params } );
    }

    descargarDocumento(BecaExtemporaneaID: number) {

        let params = new HttpParams();
        params = params.append('BecaExtemporaneaID', BecaExtemporaneaID.toString());
        
        return this.http.get(`${environment.apiUrl}/DescargaDocBecaExtemporanea/`, { params, responseType: 'blob' } );
    }
}