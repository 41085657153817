<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">RENOVACIÓN DE BECAS BACHILLERATO.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br> 
  <div class="row">
    <input class="input-search icon xs-width text-ident" placeholder="Buscar alumno" [(ngModel)]="filtro" (input)="filterText()" id="filter" name="filter">
    <br><br>
  </div>
  <br>
  <div class="mat-elevation-z8">
    <mat-table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="renovacion">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Renovación</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.Renovacion" 
          [disabled]="!editar">
        </mat-checkbox>           
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="condicionada">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Condicionada</mat-header-cell>
        <mat-cell  *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.Condicionada"  [disabled]="!editar"></mat-checkbox>           
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="reporteSep">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Reporte SEP</mat-header-cell>
        <mat-cell  *matCellDef="let element">
          <mat-checkbox [(ngModel)]="element.BecaSep" [disabled]="!editar" ></mat-checkbox>           
        </mat-cell>
      </ng-container>      

      <ng-container matColumnDef="colegio">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Colegio </mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{element.Solicitud.Centro.Nombre}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="curp">
        <mat-header-cell mat-header-cell *matHeaderCellDef> CURP </mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{element.Solicitud.Alumno.CURP}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="nombre">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{element.Solicitud.Alumno.Nombre}} {{element.Solicitud.Alumno.ApellidoPaterno}} {{element.Solicitud.Alumno.ApellidoMaterno}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="grado">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Grado </mat-header-cell>
        <mat-cell  *matCellDef="let element"> {{element.Solicitud.GradoEducativo.Nombre}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="tipoBeca">
          <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo de beca </mat-header-cell>
          <mat-cell  *matCellDef="let element"> {{element.Solicitud.TipoBeca.Nombre}} </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="porcentaje">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Porcentaje </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.PorcentajeAprobado}} </mat-cell>
      </ng-container>

      <mat-header-row  *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row  *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>  
    
  </div>         
  <br>
  <div class="loading-screen-icon" *ngIf="esperando">
    <mat-spinner></mat-spinner>
  </div>
  <div class="modal-form margin-div">
    <button mat-raised-button (click)="onNoClick()" class="buttonSecondary xsbtn">CANCELAR</button>
    <div  *ngIf="editar">
      <button mat-raised-button  (click)="save()"  class="button xsbtn" [disabled]="esperando">GUARDAR</button>    
    </div>
  </div>  
</mat-card>