import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EmpleadoModelOperation } from '../../_models/empleado.model';

import { EmpleadoService } from '@app/_services/empleado.service';
import { RolService } from '@app/_services/rol.service';

import { first } from 'rxjs/operators';
import { Rol } from '@app/_models/rol';

import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Centro } from '@app/_models/centro';
import { CentroService } from '@app/_services/centro.service';

export interface Item {
  value: number;
  description: string;
}

@Component({
  selector: 'app-det-usuario-empresa',
  templateUrl: './det-usuario-empresa.component.html',
  styleUrls: ['./det-usuario-empresa.component.scss']
})
export class DetUsuarioEmpresaComponent implements OnInit {

  detalleUsuarioForm: FormGroup;

  enableBtn = false;
  textBtnCancelar = "Cancelar";
  rol: Item;
  disabled_ = false;

  loading: boolean = false;
  error: boolean = false;
  correcto: boolean = false;
  errorMessage: string = "";
  message: string = "";

  roles: Rol[] = [];
  centros: Centro[] = [];
  selectedCentros : Centro [];
  option = [];
  selectedValue = "";
  cargandoCentros = null;

  idUsuario: number;

  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;

  constructor(private formBuilder: FormBuilder,
     public dialogRef: MatDialogRef<DetUsuarioEmpresaComponent>,
     private usuarioService:EmpleadoService,
     private centroService: CentroService,
     private rolService:RolService,
     private authenticationService: AuthenticationService,
     @Inject(MAT_DIALOG_DATA) public data: EmpleadoModelOperation) { } 

    ngOnInit() {

      this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

      this.cargandoCentros = "(Cargando ...)";

      this.getRoles();
      this.getCentros();

      this.textBtnCancelar = "Cancelar";

      if (this.data.operacion ===  'NUEVO') {
        this.disabled_ = false;
        this.data.user = {
          EmpleadoID: 0, NumeroEmpleado: null, UsuarioID: 0, Correo: '', Nombre: '',ApellidoPaterno: '', ApellidoMaterno: '', EstatusCuentaID: 0, Password: '',
          Area: { AreaID:null, Nombre:'', Clave:''},  Roles_: [], IdsRoles: [], IdsCentros: [], Centros:[], Regiones:[], IdsRegiones : [] 
        }
      } else {
        this.disabled_ = true;
      }

      this.detalleUsuarioForm = this.formBuilder.group({
        numEmpleado: [{  value : this.data.user.NumeroEmpleado, disabled: this.disabled_ }, Validators.required],
        area: [this.data.user.Area.AreaID, Validators.required],
        nombre: [this.data.user.Nombre, Validators.required],
        apellidoPaterno: [this.data.user.ApellidoPaterno, Validators.required],
        apellidoMaterno: [this.data.user.ApellidoMaterno],
        email: [{ value : this.data.user.Correo, disabled: this.disabled_ }, [Validators.required, Validators.email]],
        centros: [ this.data.user.Centros, Validators.required],
        rol: [this.data.user.Roles_, Validators.required],
        regiones: [this.data.user.Regiones, Validators.required],
      });   

    }

    onSubmit(){
      this.textBtnCancelar = "Cancelar";
      this.error = false;
      this.correcto = false;
      this.loading = true;

      if (this.detalleUsuarioForm.invalid) {
        return;
      }
      
    }

    onNoClick(): void {
      this.dialogRef.close();
  }

  cancelar(): void {
    this.dialogRef.close();
  }

  aceptar(): void {
    this.dialogRef.close();
  }

  agregar(): void {
    this.dialogRef.close();
  }

  verify() {
    if (!this.detalleUsuarioForm.invalid) {
      this.enableBtn = true;
    }else{
      this.enableBtn = false;
    }
  }

  getRoles() {
    this.loading = true;
    this.rolService.get()
    .pipe(first())
    .subscribe(
      data => {
        this.roles = data;
        console.log("roles " );
      },
      error => {       
        console.log("Error al obtener los roles: ", error);
      });
  }

  getCentros() {
    this.centroService.getAll().then(centros => {
      this.centros = centros.sort((a, b) => {
        if(a.Nombre > b.Nombre) {
          return 1;
        } else if(a.Nombre < b.Nombre) {
          return -1;
        } else {
          return 0;
      }});

      //let campus_:Campus = { InstitucionID:'0', CampusID:'1', Clave:'TODOS', Nombre:'TODOS' };
      //this.campuses.unshift(campus_);
      this.cargandoCentros = null;
    }).catch(err => {
      this.cargandoCentros = "(Error)";
      console.log("Error" + err);
    });
  }

  get formControls() { return this.detalleUsuarioForm.controls; }

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }


    if(this.detalleUsuarioForm.controls != null && 
      this.detalleUsuarioForm.controls.userType != null &&
      this.detalleUsuarioForm.controls.userType.value != null){
        if(this.detalleUsuarioForm.controls.userType.value.length==this.centros.length)
          this.allSelectedCentros.select();
    }

  } 

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());
    
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
   }


}
