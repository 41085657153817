import { Region } from './region';

export class Centro {

    CentroID: string;
    Nombre : string;
    Region : Region;
    Colegium : boolean;
    Cege : string;

  }