<mat-card-title class="card-title fz-20">Fechas para  {{cicloCentro.Centro.Nombre}}</mat-card-title>
<mat-divider></mat-divider>
<br>
    <table>
        <tr>
            <td style="width: 45%;" >
            </td>
            <td style="text-align: left; width: 18rem;">
                <mat-label class="color-marino">Desde</mat-label> 
            </td>
            <td>
                <mat-label class="color-marino">Hasta</mat-label> 
            </td>
        </tr>
    </table>
    <table>
        <tr>
            <td>
                <mat-label class="color-marino">Solicitud de beca</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput min="2000-01-01" 
                        name ="FechaSolicitudIni" placeholder="dd/mm/yyyy"
                       (ngModelChange)='verify()'
                        [(ngModel)] ="cicloCentro.FechaSolicitudIni"
                    [matDatepicker]="picker">  
                    <mat-error *ngIf="fechaSolError">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> 
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input  name ="FechaSolicitudFin"  matInput 
                    [(ngModel)] ="cicloCentro.FechaSolicitudFin"
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker2" (ngModelChange)='verify()'>  
                    <mat-error *ngIf="fechaSolError">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </td>
            <td>
            </td>
        </tr>
        <tr>
            <td>
                <mat-label class="color-marino">Solicitud Bachillerato</mat-label> 
            </td>
            <td style="text-align: end;">
                <mat-form-field  class="dateInput xs-width" appearance="outline">
                    <input matInput [min]="minDate" 
                        name ="FechaSolicitudIni2" placeholder="dd/mm/yyyy"
                       (ngModelChange)='verify()'
                       [(ngModel)] ="cicloCentro.FechaSolicitudIni2"
                    [matDatepicker]="picker19" >  
                    <mat-error *ngIf="fechaSolError">
                        Formato de fecha inválida.</mat-error>                                     
                    <mat-datepicker-toggle matSuffix [for]="picker19"></mat-datepicker-toggle>
                    <mat-datepicker #picker19></mat-datepicker> 
                </mat-form-field>
            </td>
            <td style="text-align: end;">
                <mat-form-field   class="dateInput xs-width" appearance="outline">
                    <input matInput   name ="FechaSolicitudFin2"
                    [(ngModel)] ="cicloCentro.FechaSolicitudFin2"
                    [min]="minDate" 
                    placeholder="dd/mm/yyyy" [matDatepicker]="picker20" (ngModelChange)='verify()'>  
                    <mat-error *ngIf="fechaSolError">
                        Formato de fecha inválida.</mat-error>              
                    <mat-datepicker-toggle matSuffix [for]="picker20"></mat-datepicker-toggle>
                    <mat-datepicker #picker20></mat-datepicker>
                </mat-form-field>
            </td>
            <td></td>
        </tr>
    </table>
    <div class="loading-screen-icon" *ngIf="guardando">
        <mat-spinner></mat-spinner>
    </div>
    <br><br><br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || guardando" >GUARDAR</button>
    </div>

    <div *ngIf="error">
        <br>
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>    
