import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service'

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    console.log("Permisos :" +JSON.stringify(this.authenticationService.currentUserValue.Roles));

    if (!!this.authenticationService.currentUserValue) {
      if (this.authenticationService.currentUserValue.Roles.indexOf("ADMIN") > -1) {
        this.router.navigate(['/administracion-usuarios']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("EMPRESA") > -1) {
        this.router.navigate(['/expedientes-asignados']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("CONFBECAS") > -1 ||
      this.authenticationService.currentUserValue.Roles.indexOf("APERCICLO") > -1) {
        this.router.navigate(['/apertura-ciclos']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("ADMSOBREPASOS") > -1) {
        this.router.navigate(['/adm-sobrepasos']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("CONSOLIDADO") > -1) {
        this.router.navigate(['/resumen-becas']);
        return;
      }      
      if (this.authenticationService.currentUserValue.Roles.indexOf("CARGAS") > -1) {
        this.router.navigate(['/cargas']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("ESTUDIOSOC") > -1) {
        this.router.navigate(['/descarga-documentos']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("PROPBECA") > -1) {
        this.router.navigate(['/propuestas-becas']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("REPORSOL") > -1 ) {
        this.router.navigate(['/reportes']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("REVIREQ") > -1) {
        this.router.navigate(['/revision-requisitos']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("REVISOL") > -1) {
        this.router.navigate(['/revision-solicitudes']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("REVPROPBECA") > -1) {
        this.router.navigate(['/revision-propuesta-becas']);
        return;
      }
      if (this.authenticationService.currentUserValue.Roles.indexOf("APROPROPBECA") > -1) {
        this.router.navigate(['/revision-propuesta-becas']);
        return;
      }      
      this.router.navigate(['/pantalla-principal']);
    } else {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }
  }
}
