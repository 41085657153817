<mat-card class="seleccion-beca-card">
    <br>
    <div class="example-form">
      <mat-card-title class="text-left fz-26">ADMINISTRACIÓN DE SOBREPASOS.</mat-card-title>
      <mat-divider></mat-divider>
    </div>
    <br>
    <div class="example-form">
      <form (keydown.enter)="$event.preventDefault()" name="adminSobrePasoForm" 
        [formGroup]="adminSobrePasoForm" id="adminSobrePasoForm" >
        <div class="row">
          <button mat-raised-button class="button" (click)="buscarAlumno()" >+ NUEVO</button>
        </div>
        <br>
        <div>
          <mat-label class="color-azul">Filtrar por:</mat-label>
        </div>
        <mat-form-field  class="inpunt-width xs-width" appearance="outline">
            <mat-label>Ciclo</mat-label>
            <mat-select #mySelCiclo name="ciclo" formControlName="ciclo" id="ciclo" 
            (openedChange)="onSelectedCiclos($event)">
              <mat-option *ngFor="let ciclo of ciclosBecas" [value]="ciclo.CicloID" > 
                {{ciclo.Clave}}
              </mat-option>
            </mat-select>
        </mat-form-field>  

        <mat-form-field  class="input-centro-width md-width" appearance="outline">
          <mat-label>Centros</mat-label>
          <mat-select #mySelCentro name="centros" formControlName="centros"  
              id="centros"  (openedChange)="onSelectedColegios($event)" 
                    multiple>
                <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'0'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                            (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                            {{centro.Nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field> 


        <mat-form-field class="inpunt-width xs-width"  appearance="outline">
          <mat-label>Secciones</mat-label>
          <mat-select #mySelSeccion name="secciones" formControlName="secciones" 
              id="secciones" (openedChange)="onSelectedSecciones($event)" multiple>
              <mat-option  #allSelectedSecciones  (click)="toggleAllSelectionSeccion()" [value]=0><label>Todos</label></mat-option>
              <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID" 
              (click)="tosslePerOneSecciones(allSelectedSecciones.viewValue)"> 
               {{seccion.Nombre}}
            </mat-option>
          </mat-select>   
        </mat-form-field> 

        <!--mat-form-field class="inpunt-width xs-width"  appearance="outline">
          <mat-label>Regiones</mat-label>
          <mat-select #mySelRegion name="regiones" formControlName="regiones" 
              id="regiones" (openedChange)="onSelectedRegiones($event)" multiple>
              <mat-option  #allSelectedRegiones  (click)="toggleAllSelectionRegiones()" [value]="0"><label>Todos</label></mat-option>
              <mat-option *ngFor="let region of regiones" [value]="region.RegionID" 
              (click)="tosslePerOneRegiones(allSelectedRegiones.viewValue)"> 
               {{region.Nombre}}
            </mat-option>
          </mat-select>   
        </mat-form-field--> 

        <mat-form-field class="inpunt-width xs-width" appearance="outline">
          <mat-label>Filtro</mat-label>
          <input matInput placeholder="Nombre del prospecto" value="" id="nombreProspecto"
          formControlName="filtro" name="filtro" >
        </mat-form-field>
        <br/><br/>
        <button mat-raised-button class="color-btn" class="button" (click)="getSobrepasos()"> BUSCAR </button>
      </form>
    </div>
    <br>
    <div class="example-form mat-elevation-z8">
      <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="Ciclo">
            <mat-header-cell *matHeaderCellDef> Ciclo </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Ciclo">
              {{element.AlumnoPromedio.Ciclo.Clave}}
            </mat-cell>
          </ng-container>      
            
          <ng-container matColumnDef="Colegio">
            <mat-header-cell *matHeaderCellDef> Colegio </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Colegio"> 
              <div *ngIf="element.AlumnoPromedio.Centro !== undefined">
                {{element.AlumnoPromedio.Centro.Nombre}}
              </div>
            </mat-cell>
          </ng-container>
          
          <ng-container matColumnDef="Seccion">
            <mat-header-cell *matHeaderCellDef> Sección </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Seccion">
              <div *ngIf="element.AlumnoPromedio.Seccion !== undefined">
                {{element.AlumnoPromedio.Seccion.Nombre}}
              </div>
             </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="CURP">
            <mat-header-cell *matHeaderCellDef>CURP </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="IdAlumno">
                {{element.AlumnoPromedio.Alumno.CURP}}  
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Nombre">
            <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Nombre">
              {{element.AlumnoPromedio.Alumno.Nombre}} {{element.AlumnoPromedio.Alumno.ApellidoPaterno}} {{element.AlumnoPromedio.Alumno.ApellidoMaterno}}   
           </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pAcademico">
            <mat-header-cell *matHeaderCellDef> Promedio  <br>Académico </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="pAcademico"> 
              <p *ngIf="element.AlumnoPromedio.PromedioAcademico != 0">
                {{element.AlumnoPromedio.PromedioAcademico}}  
              </p>
              <p style="justify-content: center; text-align: center;" *ngIf="element.AlumnoPromedio.PromedioAcademico == 0">
                N/A
              </p> 
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="pConducta">
            <mat-header-cell *matHeaderCellDef> Promedio <br> Conducta </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="pConducta">
              <p *ngIf="element.AlumnoPromedio.PromedioDisciplina != 0">
                {{element.AlumnoPromedio.PromedioDisciplina}}  
              </p>
              <p style="justify-content: center; text-align: center;" *ngIf="element.AlumnoPromedio.PromedioDisciplina == 0">
                N/A
              </p> 
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="adeudo">
            <mat-header-cell *matHeaderCellDef>Adeudo </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="adeudo"> 
              <div *ngIf="element.AlumnoPromedio.Moroso">Si</div> 
              <div *ngIf="!element.AlumnoPromedio.Moroso">No</div> 
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="Acciones">
            <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Acciones">
                <button mat-icon-button color="basic" aria-label="Editar" (click)="editarSobrepaso(element)"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>

                <button mat-icon-button color="basic" matTooltip="Descargar documento" [disabled]="!element.TieneArchivo"   matTooltipPosition="right" (click)="descargarDoc(element)">
                  <mat-icon>cloud_download</mat-icon></button>
  
              <button mat-icon-button color="basic" aria-label="Ver historial" (click)="verHistorial(element)" matTooltip="Historial">
                <mat-icon>history</mat-icon></button>                  

                <!--button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarSobrepaso(element)"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button-->
            </mat-cell>
          </ng-container>
  
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
      </mat-table> 
    
      <div *ngIf="length == 0" class="text-center margin-top">
        <span class="color-obscuro">No hay registros para mostrar, complete los filtros y seleccione buscar.</span>
      </div>

      <mat-paginator #paginator
        [length]="length"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20]"
        (page)="pageEvent = getServerData($event)"
        >
      </mat-paginator>
    </div>    
  </mat-card>