<mat-card-title class="card-title fz-20">EDITAR BECA</mat-card-title>
<mat-divider></mat-divider>
<br><br>
<form [formGroup]="detalleModBeca" class="modal-form" name="detalleModBeca" id="detalleModBeca">  

  <div class="row">
      <mat-form-field class="input-full-width xs-width" appearance="outline">
          <mat-label>Colegio</mat-label>
          <input matInput type="text" formControlName="colegio" required readonly>  
      </mat-form-field>
  
      <mat-form-field  class="input-full-width xs-width" appearance="outline">
        <mat-label>Sección</mat-label>
        <input matInput type="text" formControlName="seccion" required readonly>
      </mat-form-field>
      <br>
  </div>

  <div class="row">
      <mat-form-field class="input-full-width xs-width" appearance="outline">
          <mat-label>CURP</mat-label>
          <input matInput type="text" formControlName="curp" required readonly>  
      </mat-form-field>
  
      <mat-form-field  class="input-full-width xs-width" appearance="outline">
        <mat-label>Nombre del alumno</mat-label>
        <input matInput type="text" formControlName="nombre" required readonly>
      </mat-form-field>
      <br>
  </div>

  <div class="row">
      <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Tipo Beca:</mat-label>
        <mat-select formControlName="tipoBeca" required>
          <mat-option *ngFor="let c of tiposBeca" [value]="c.TipoBecaID">
            {{c.Nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field  class="input-full-width xs-width" appearance="outline">
        <mat-label>Nuevo porcentaje de beca</mat-label>
        <input matInput type="text" (input)='verify()' (input)='numberOnly($event)'  maxlength="3"  (ngModelChange)="validarPorcentaje()" formControlName="porcentaje" required>       
      </mat-form-field>
  </div>
  <mat-hint style=" margin: 1rem;" *ngIf="porcentajeErr">El porcentaje debe ser un número válido menor a 100</mat-hint>        
  <br>
  <div class="row"> 
      <mat-form-field class="input-full-width-coment xs-width" appearance="outline">
          <mat-label>Comentarios</mat-label>
          <textarea matInput #inputD name="comentarios" formControlName="comentarios"   
          (ngModelChange)="verify()" rows="8" required></textarea>
          <mat-hint *ngIf="inputD.value?.length == 200">Max 200 caracteres</mat-hint>
      </mat-form-field>
  </div>

  <div class="row">
      <button class="buttonSecondary" mat-raised-button (click)="seleccionarArchivo()"><span class='lato-bold espaciado margin-btn' style="font-size: 90%;">+ CARGAR ARCHIVO</span></button>
      <input class="input-search text-ident xs-width" [(value)]="nombreArchivo" disabled>
      <input class="hidden" id="upload-file" accept=".pdf, .doc" type="file" (change)="adjuntarArchivo($event)">
  </div>
  <br><br>
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <div class="modal-form margin-div">
    <button mat-raised-button color="warn" class="button xsbtn" (click)="onNoClick()" >CANCELAR</button>
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || guardando">GUARDAR</button>
</div>
<div *ngIf="error">
  <br>
  <mat-card class='card-danger'>{{errorMessage}}</mat-card>
</div>  
</form>