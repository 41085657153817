import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MatOption, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { AuthenticationService } from '@app/_services/authentication.service';
import { ObjetoOp } from '@app/_models/objectOperation';
import { Ciclo } from '@app/_models/ciclo';
import { TipoBeca } from '@app/_models/tipoBeca';
import { TipoBecaService } from '@app/_services/tipoBeca.service';
import { CicloTipoBeca } from '@app/_models/cicloTipoBeca';
import { CicloBecaService } from '@app/_services/cicloBeca.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { CicloCentro } from '@app/_models/cicloCentro';

@Component({
  selector: 'app-ciclo-centro',
  templateUrl: './ciclo-centro.component.html',
  styleUrls: ['./ciclo-centro.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-MX' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class CicloCentroComponent implements OnInit {
  enableBtn = false;
  minDate: Date;
  maxDate: Date;
  guardando = false;
  cargandoBecas = null;
  idUsuario: number;
  dateError :boolean;

  error: boolean = false;
  errorMessage: string = "";
  fechaSolError = false;
  cicloCentro : CicloCentro;
  copyCicloCentro : CicloCentro;

  constructor(public dialogRef: MatDialogRef<CicloCentroComponent>,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) {
      const currentYear = new Date().getFullYear();
      const currentMoth = new Date().getMonth();
      const currentDay = new Date().getDay();
      this.minDate = new Date(currentYear - 2, currentMoth, currentDay);
      this.maxDate = new Date(currentYear + 2, currentMoth, currentDay);
      this.cicloCentro = data.Objeto;
    }

  ngOnInit(): void {
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;
    this.copyCicloCentro = JSON.parse(JSON.stringify(this.cicloCentro));
    console.log("copy centro" + this.copyCicloCentro.FechaSolicitudFin);
    if(this.data.Operation == 'Crear'){}


    if(this.data.Operation == 'Editar'){


      this.enableBtn = true;
    }
  }

  onSubmit(){
    this.dialogRef.close(true);
  }

  onNoClick(): void {

    this.cicloCentro.FechaSolicitudIni = this.copyCicloCentro.FechaSolicitudIni;
    this.cicloCentro.FechaSolicitudFin = this.copyCicloCentro.FechaSolicitudFin;
    this.cicloCentro.FechaSolicitudIni2 = this.copyCicloCentro.FechaSolicitudIni2;
    this.cicloCentro.FechaSolicitudFin2 = this.copyCicloCentro.FechaSolicitudFin2;
    this.dialogRef.close(false);
  }

  
  verify() {
    if(this.cicloCentro.FechaSolicitudIni == null ||this.cicloCentro.FechaSolicitudFin == null  ){
      this.enableBtn = false;
    }
  } 

  omit_special_char(e: any) {
    if (e.keyCode === 95 || e.key == "_" || e.charCode == 95
        || e.keyCode === 189 || e.key == "-" || e.charCode == 189) {
      return true;
    }
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
        return true;
    } else {  
        e.preventDefault();
        return false;
    }
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }


}
