<mat-card-title class="card-title fz-20">{{data.operacion}} USUARIO DE EMPRESA</mat-card-title>
<mat-divider></mat-divider>
<br><br><br>
<form class="modal-form" name="detalleUsuarioForm" id="detalleUsuarioForm" [formGroup]="detalleUsuarioForm" autocomplete="off">

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Número de empleado</mat-label>
            <input matInput type="number" id="numEmpleado" name="numEmpleado" formControlName="numEmpleado" (input)='verify()' [(ngModel)]='data.user.NumeroEmpleado' required>  
        </mat-form-field>

        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Area</mat-label>
            <input matInput type="text" id="area" name="area" formControlName="area" (input)='verify()' required>
        </mat-form-field>
    </div>
  
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput type="text" id="nombre" name="nombre" formControlName="nombre" (input)='verify()'  [(ngModel)]='data.user.Nombre' required>
        </mat-form-field>
    
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido paterno</mat-label>
            <input matInput type="text" id="apellidoPaterno" name="apellidoPaterno" formControlName="apellidoPaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoPaterno' required>
        </mat-form-field>
    </div>

    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Apellido materno</mat-label>
            <input matInput type="text" id="apellidoMaterno" name="apellidoMaterno" formControlName="apellidoMaterno" (input)='verify()' [(ngModel)]='data.user.ApellidoMaterno' required>
        </mat-form-field>
    
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="correo@example.com" formControlName="email" id="email" name="email" (input)='verify()' [(ngModel)]='data.user.Correo' autocomplete="off" required  >
            <div *ngIf="formControls.email.errors">
                <mat-error *ngIf="formControls.email.errors.email"> El correo electrónico no tiene un formato correcto. </mat-error>
            </div>          
        </mat-form-field>
    </div>
    
    <div class="row">
        <mat-form-field class="input-full-width xs-width" appearance="outline">
            <mat-label>Region</mat-label>
            <mat-select formControlName="region" required>
              <mat-option>
                Opcion
              </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  class="input-full-width xs-width" appearance="outline">
            <mat-label>Colegios</mat-label>
            <mat-select #mySelCentros name="centros" formControlName="centros" 
                [(value)]="selectedCentros" id="centros" (ngModelChange)='verify()' 
                 [(ngModel)]="data.user.IdsCentros" required  multiple>
                <mat-option  #allSelectedCentros  (click)="toggleAllSelectionCentros()" [value]="'1'"><label>Todos</label></mat-option>
                <mat-option *ngFor="let centro of centros" [value]="centro.CentroID" 
                (click)="tosslePerOneCentros(allSelectedCentros.viewValue)"> 
                {{centro.CentroID}} - {{centro.Nombre}}
            </mat-option>
        </mat-select>
        </mat-form-field> 
    </div>
    
    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Privilegios del sistema</mat-label>
        <mat-select name="rol" formControlName="rol" id="rol" (ngModelChange)='verify()' [(ngModel)]="data.user.IdsRoles" required  multiple>
          <mat-option *ngFor="let rol of roles" [value]="rol.RolID">{{rol.Nombre}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br><br><br><br>
    <div class="modal-form margin-div">
        <button mat-raised-button color="warn" class="button xsbtn" (click)="onNoClick()" >{{textBtnCancelar}}</button>
        <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn" >Guardar</button>
    </div>
    <div *ngIf="error">
        <br>
        <mat-card class='card-danger'>{{errorMessage}}</mat-card>
    </div>

    <div *ngIf="correcto">
        <br>
        <mat-card class='card-success'>{{message}}</mat-card>
    </div>

</form>


