import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { BecaExtemporanea } from '@app/_models/becaExtemporaneas';
import { ResponseRest } from '@app/_models/responseRest';
import { AlumnoPromedio } from '@app/_models/alumnoPromedio';

@Injectable({ providedIn: 'root' })
export class DatosOriginalesService {

    constructor(private http: HttpClient) {}

 
    buscarAlumno(CicloID : number , CURP: string, CentroID : string){
        return this.http.get<ResponseRest<AlumnoPromedio>>(`${environment.apiUrl}/${CicloID}/${CURP}/${CentroID}/BuscarAlumno` ).toPromise();
    }

    
    buscarAlumnoActual( CURP: string, CentroID : string){
        return this.http.get<ResponseRest<AlumnoPromedio>>(`${environment.apiUrl}/${CURP}/${CentroID}/BuscarAlumnoActual` ).toPromise();
    }

   buscarAlumnoSobrepaso(CicloID : number , CURP: string){
    return this.http.get<AlumnoPromedio>(`${environment.apiUrl}/${CicloID}/${CURP}/BuscarAlumnoSobrepaso` ).toPromise();
   }



}