<mat-action-list *ngIf="isAuthenticated" dense>
  <mat-list-item [fxShow]="isAuthenticated">
    <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="usuario-perfil-label">person</mat-icon>
    <h3 matLine id="usuario-perfil-label" class="sidebar-username"> {{userName}} </h3>
    <span matLine> {{isAdmin ? "Administrador" : isEmpresa ? "Empresa" :  isColab ? "Colaborador" : "Prospecto" }} </span>
  </mat-list-item>
  <mat-divider [fxShow]="isAuthenticated"></mat-divider>

  <button [fxShow]="isEmpresa" mat-list-item routerLink="/expedientes-asignados">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">group</mat-icon> -->
    <span id="admin-user-label">Expedientes asignados</span>
  </button>  

  <h3 matSubheader [fxShow]="isAdmin">Administración</h3>
  <button [fxShow]="isAdmin" mat-list-item routerLink="/administracion-usuarios">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">group</mat-icon> -->
    <span id="admin-user-label">Administrar Usuarios</span>
  </button>

  <mat-divider [fxShow]="isAdmin"></mat-divider>
  <h3 matSubheader [fxShow]="isColab">Colaborador</h3>

  <button [fxShow]="isCatalogoEmpresas" mat-list-item routerLink="/catalogo-empresas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">group</mat-icon> -->
    <span id="admin-user-label">Catálogo de empresas</span>
  </button>

  <button [fxShow]="isAperturaCiclos || isConfiguracionBecas" mat-list-item routerLink="/apertura-ciclos">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Apertura de ciclos</span>
  </button> 

  <button [fxShow]="isRevisionRequisitos" mat-list-item routerLink="/revision-requisitos">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Revisión de requisitos</span>
  </button>

  <button [fxShow]="isCartaBeca" mat-list-item routerLink="/carta-beca">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Carta Beca</span>
  </button>

  <button [fxShow]="isRevisionSolicitud" mat-list-item routerLink="/revision-solicitudes">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Revisión de solicitudes</span>
  </button>

  <button [fxShow]="isPropBecas" mat-list-item routerLink="/propuestas-becas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Propuestas de becas</span>
  </button>

    
  <button [fxShow]="isCoordinador|| isDirector" mat-list-item routerLink="/revision-propuesta-becas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Revisión de propuestas de becas</span>
  </button>

  <button [fxShow]="isReporteSolicitudes || isReportePresupuesto" mat-list-item routerLink="/reportes">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Reportes</span>
  </button>

  <button [fxShow]="isRenovacionBecas" mat-list-item routerLink="/renovacion-becas">
    <span id="reporte-label">Renovación de becas</span>
  </button>

  <button [fxShow]="isAdmSobrepasos" mat-list-item routerLink="/adm-sobrepasos">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Sobrepasos</span>
  </button>

  <button [fxShow]="isConsolidado" mat-list-item routerLink="/resumen-becas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Consolidado de becas</span>
  </button>

  <button [fxShow]="isEstudioSocioeco" mat-list-item routerLink="/descarga-documentos">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Descarga de documentos de estudio socioeconómico</span>
  </button>

  <button [fxShow]="isModBecas" mat-list-item routerLink="/modificacion-becas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Modificación de becas</span>
  </button>

  <button [fxShow]="isRevModBecas" mat-list-item routerLink="/revision-modificacion-becas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Revisión de modificación de becas</span>
  </button> 

  <button [fxShow]="isBecaExtemporanea" mat-list-item routerLink="/becas-extemporaneas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Becas extemporáneas</span>
  </button>

  <button [fxShow]="isAsignacionBecasExt" mat-list-item routerLink="/becas-externas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="admin-user-label">text_snippet</mat-icon>     -->
    <span id="conc-banc-label">Registro de becas externas</span>
  </button>   
  
  <button [fxShow]="isCargas" mat-list-item routerLink="/cargas">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="reporte-label">assessment</mat-icon> -->
    <span id="reporte-label">Carga de archivos</span>
  </button>  

  <mat-divider [fxShow]="isProspect"></mat-divider>
  <button mat-list-item routerLink="/contacto">
    <!-- <mat-icon mat-list-icon aria-hidden="false" aria-labelledby="ayuda-label">help</mat-icon> -->
    <span id="ayuda-label">Contacto</span>
  </button>

  <button mat-list-item (click)="cancelar()">
    <i mat-list-icon aria-hidden="false" aria-labelledby="cerrar-label" class="fas fa-sign-out-alt"></i>
    <span id="cerrar-label">Cerrar Sesión</span>
  </button>
</mat-action-list>
