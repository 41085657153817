<mat-card class="seleccion-beca-card">
  <br><br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">ESTUDIO SOCIOECONOMICO.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br>
  <div class="mdl-form">
    <mat-label class="color-azul">Filtrar por:</mat-label>
  </div>
  <form  class="mdl-form" name="descargaDocForm" id="descargaDocForm" [formGroup]="descargaDocForm">      

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Regiones</mat-label>
      <mat-select #mySelInstituciones name="institucionesControl"  formControlName="institucionesControl" [(value)]="selectedInstituciones" id="institucionesControl" multiple>
          <mat-option  #allSelectedInstituciones  (click)="toggleAllSelectionInstituciones()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let institucion_ of institucionesSrc" [value]="institucion_" (click)="tosslePerOneInstitucion()"> 
            {{institucion_}}
            </mat-option>
      </mat-select>
    </mat-form-field>    

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Centros</mat-label>
      <mat-select #mySelCampus name="campusControl"  formControlName="campusControl" [(value)]="selectedCampus" id="campusControl" multiple>
          <mat-option  #allSelectedCampus  (click)="toggleAllSelectionCampus()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let campus_ of campusSrc" [value]="campus_.CampusID" (click)="tosslePerOneCampus()"> 
            {{campus_.CampusID}} - {{campus_.Nombre}}
            </mat-option>
      </mat-select>
    </mat-form-field>    

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Secciones</mat-label>
      <mat-select #mySelGrados name="gradosControl"  formControlName="gradosControl" [(value)]="selectedGrados" id="gradosControl" multiple>
          <mat-option  #allSelectedGrados  (click)="toggleAllSelectionGrados()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let grado_ of gradosSrc" [value]="grado_.Nombre" (click)="tosslePerOneGrado()"> 
              {{grado_.Nombre}}
            </mat-option>
      </mat-select>
    </mat-form-field>    

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Grados</mat-label>
      <mat-select #mySelProgramas name="gradosProgramas"  formControlName="programasControl" [(value)]="selectedProgramas" id="programasControl" multiple>
          <mat-option  #allSelectedProgramas  (click)="toggleAllSelectionProgramas()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let programa_ of programasSrc" [value]="programa_.ProgramaID" (click)="tosslePerOnePrograma()"> 
              {{programa_.Nombre}}
            </mat-option>
      </mat-select>
    </mat-form-field>     

    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Ciclos</mat-label>
      <mat-select #mySelCiclos name="gradosCiclos"  formControlName="ciclosControl" [(value)]="selectedCiclos" id="ciclosControl" multiple>
          <mat-option  #allSelectedCiclos  (click)="toggleAllSelectionCiclos()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let ciclo_ of ciclosSrc" [value]="ciclo_.Nombre" (click)="tosslePerOneCiclo()"> 
              {{ciclo_.Nombre}}
            </mat-option>
      </mat-select>
    </mat-form-field>   

    <mat-form-field class="inpunt-width xs-width" appearance="outline">
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre del prospecto" value="" id="nombreProspecto" name="nombreProspecto" [formControl]="nombreControl" (input)='filtrar()' >
    </mat-form-field>
    
    <mat-form-field  class="inpunt-width xs-width" appearance="outline">
      <mat-label>Empresa</mat-label>
      <mat-select #mySelInstituciones name="institucionesControl"  formControlName="institucionesControl" [(value)]="selectedInstituciones" id="institucionesControl" multiple>
          <mat-option  #allSelectedInstituciones  (click)="toggleAllSelectionInstituciones()" [value]="'1'"><label>Todos</label></mat-option>
            <mat-option *ngFor="let institucion_ of institucionesSrc" [value]="institucion_" (click)="tosslePerOneInstitucion()"> 
            {{institucion_}}
            </mat-option>
      </mat-select>
    </mat-form-field> 
    <br>
  </form>
  <br><br><br>
  <div class="mdl-form mat-elevation-z8">
    <mat-table [dataSource]="dataSource" matSort>
    
      
      <ng-container matColumnDef="colegio">
        <mat-header-cell *matHeaderCellDef class="sub-title column-id-prospecto"> Colegio </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="colegio" class='column-id-prospecto'>
          {{element.IdProspecto}}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seccion">
        <mat-header-cell *matHeaderCellDef class="sub-title"> Sección </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="seccion">
          {{element.Nombre}}
        </mat-cell>
      </ng-container>
        
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="sub-title"> ID del alumno </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="id"> 
        {{element.Email}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef class="sub-title"> Nombre del alumno</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="nombre"> 
        {{element.Institucion_Campus}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef class='column-opciones'> Acciones </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="acciones" class='column-opciones'>
            <button mat-icon-button color="basic" aria-label="Descargar documentos"  matTooltip="Descargar documentos"  matTooltipPosition="right" (click)="downloadDocs(element.IdProspecto)">
              <mat-icon>cloud_download</mat-icon></button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>

  </mat-table>      
  
  <mat-paginator #paginator
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [pageSizeOptions]="[10, 20]"
  (page)="pageEvent = getSolcitudesData($event)" >
  </mat-paginator>
  </div>         
  <br><br>  
</mat-card>
  