import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { OperationResult } from '@app/_models/operationResult';

@Injectable({
  providedIn: 'root'
})
export class ProspectoDocumentoService {

  constructor(private http: HttpClient) { }

  importar(archivos: FormData, idProspecto: number) {
    
    let params = new HttpParams();
    params = params.append('idProspecto', idProspecto.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<OperationResult<any>> (`${environment.apiUrl}/ProspectoDocumento`, archivos, { params } );
  }

  download(idProspecto: number, idUsuario: number) {

    let params = new HttpParams();
    params = params.append('idProspecto', idProspecto.toString());
    params = params.append('idUsuario', idUsuario.toString());

    return this.http.get(`${environment.apiUrl}/ProspectoDocumento`, { params, responseType: 'blob' } );

  }

}
