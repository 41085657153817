import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Grado } from '../../_models/grado';
import { AuthenticationService } from '@app/_services/authentication.service';
import { EmpresaOp } from '../../_models/empresaOp'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Ciclo } from '@app/_models/ciclo';
import { Centro } from '@app/_models/centro';
import { CentroService } from '@app/_services/centro.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { CurrencyPipe} from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Empresa } from '@app/_models/empresa';
import { TelefonoEmpresa } from '@app/_models/telefonoEmpresa';
import { ContactoEmpresa } from '@app/_models/contactoEmpresa';
import {EstudioEmpresa} from '@app/_models/estudioEmpresa';
import { Usuario } from '@app/_models/usuario';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';
import { EmpresaService } from '@app/_services/empresa.service';

@Component({
  selector: 'app-detalle-empresas',
  templateUrl: './detalle-empresas.component.html',
  styleUrls: ['./detalle-empresas.component.scss']
})
export class DetalleEmpresasComponent implements OnInit {

  detalleEmpresa: FormGroup;
  @ViewChild('allSelectedCentros') private allSelectedCentros: MatOption;
  @ViewChild('mySelCentro') mySelCentro: MatSelect;
  @ViewChild('mySelRegion') mySelRegion: MatSelect;
  disabled_ = false;
  enableBtn = false;

  disableCorreo = true;
  successFalse = false;

  option = []
  grados: Grado[] = [];
  centros: Centro[] = [];
  periodos: Ciclo[] = [];

  idEmpresa = null;
  guardando = false;
  cargandoCampus = null;
  cargandoGrado = null;
  cargandoPeriodo = null;
  hayErrores = false;

  selectedCentros : Centro [];
  selectedValue = "";
  cargandoCentros = null;
  cargandoRegiones = null;

  idUsuario: number;

  selectedRegiones : number [];
  emailInvalid = false
  value: string;
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  error: boolean = false;
  correcto: boolean = false;
  errorMessage: string = "";
  message: string = "";
  errorCorreo: string = "";

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DetalleEmpresasComponent>,
    private centroService: CentroService,
    private empresaService: EmpresaService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: EmpresaOp) { }

  selectedOption(event) {
    this.selectedValue = event.source.value;

    const setVal = this.option.find( op => op.CampusID === this.selectedValue)
    this.myControl.setValue(this.selectedValue + " - " + setVal.Nombre)
  }
 
  ngOnInit() {
    this.hayErrores = false;
    this.guardando = false;
    this.cargandoCampus = "(Cargando ...)";
    this.cargandoGrado = "(Cargando ...)";
    this.cargandoPeriodo = "(Cargando ...)";
    this.selectedValue = "";
    
    this.idUsuario = this.authenticationService.currentUserValue.UsuarioID;

    this.getCentros();

    this.detalleEmpresa = this.formBuilder.group({
      nombreEmpresa: [{ value: '', disabled: this.disabled_ }, Validators.required],
      correo: [{ value: '', disabled: this.disabled_ }, [Validators.required, Validators.email]],
      telEmpresa: [{ value: '', disabled: this.disabled_ }, Validators.required],
      nombreContacto: [{ value: '', disabled: this.disabled_, }, Validators.required],
      monto: [{ value: '', disabled: this.disabled_ }, Validators.required],
      tiempo: [{ value: '', disabled: this.disabled_, }, Validators.required],
      colegios: [{ value: '', disabled: this.disabled_, }, Validators.required],
      extEmpresa: [{ value: '', disabled: this.disabled_ }],
      apmContacto: [{ value: '', disabled: this.disabled_ }],
      appContacto: [{ value: '', disabled: this.disabled_, }, Validators.required],
    });

    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    if (this.data.Operation === 'EDITAR') {
      this.guardando = true;
      this.empresaService.detalle(this.data.Empresa.EmpresaID).then( e => {
        if(e.EmpresaID != null){
          this.data.Empresa = e;
  
          this.idEmpresa = this.data.Empresa.EmpresaID;
          this.detalleEmpresa.get('correo').setValue(this.data.Empresa.Correo);
          this.detalleEmpresa.get('nombreEmpresa').setValue(this.data.Empresa.NombreEmpresa);
          this.detalleEmpresa.get('telEmpresa').setValue(this.data.Empresa.TelefonoEmpresa.Numero);
          this.detalleEmpresa.get('nombreContacto').setValue(this.data.Empresa.ContactoEmpresa.Nombre);
          this.detalleEmpresa.get('tiempo').setValue(this.data.Empresa.EstudioEmpresa.TiempoEntrega);
          this.detalleEmpresa.get('extEmpresa').setValue(this.data.Empresa.TelefonoEmpresa.Extension);
          this.detalleEmpresa.get('apmContacto').setValue(this.data.Empresa.ContactoEmpresa.ApellidoMaterno);
          this.detalleEmpresa.get('appContacto').setValue(this.data.Empresa.ContactoEmpresa.ApellidoPaterno);
          this.detalleEmpresa.get('monto').setValue(this.data.Empresa.EstudioEmpresa.Costo);
          this.detalleEmpresa.get('colegios').setValue(this.data.Empresa.IdsCentros);
          this.disableCorreo = true;
          if(this.data.Empresa.IdsCentros.includes('0')){
            this.mySelCentro.options.forEach( (item : MatOption) => item.select());  
          }
          this.guardando = false;
        }
      } ).catch(err => {
        this.guardando = false;
        console.log("Error" + err);
      });   

      this.enableBtn = true;
      this.detalleEmpresa.get('correo').disable();
    }
    else {
      this.disableCorreo = false;
    }    
  }

  cancelar(): void { 
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Quiere cerrar sin guardar?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.dialogRef.close(false);
      }
    });    
  }

  aceptar(): void {
    if (this.detalleEmpresa.invalid) {
      this.errorMessage = 'Verifique todos los campos'
      this.error = true
      return;
    }

    var monto = this.detalleEmpresa.get('monto').value;
    if(monto < 1){
      this.errorMessage = 'Debe ingresar un costo válido';
      this.error = true;
      return;
    }

    var monto = this.detalleEmpresa.get('tiempo').value;
    if(monto < 1){
      this.errorMessage = 'Debe ingresar un tiempo de entrega válido';
      this.error = true;
      return;
    }

    this.guardando = true;
    var empresa = new Empresa();
    empresa.EmpresaID = this.idEmpresa ? this.idEmpresa : 0;
    empresa.Correo = this.detalleEmpresa.get('correo').value;
    empresa.NombreEmpresa = this.detalleEmpresa.get('nombreEmpresa').value;

    empresa.TelefonoEmpresa = new TelefonoEmpresa();
    empresa.TelefonoEmpresa.PaisID = 'MEX'
    empresa.TelefonoEmpresa.Numero = this.detalleEmpresa.get('telEmpresa').value;
    empresa.TelefonoEmpresa.Extension = this.detalleEmpresa.get('extEmpresa').value;

    empresa.ContactoEmpresa = new ContactoEmpresa();
    empresa.ContactoEmpresa.ApellidoPaterno = this.detalleEmpresa.get('appContacto').value;
    empresa.ContactoEmpresa.ApellidoMaterno = this.detalleEmpresa.get('apmContacto').value;
    empresa.ContactoEmpresa.Nombre = this.detalleEmpresa.get('nombreContacto').value;

    empresa.EstudioEmpresa = new EstudioEmpresa(); 
    empresa.EstudioEmpresa.Costo  = this.detalleEmpresa.get('monto').value
    empresa.EstudioEmpresa.TiempoEntrega = this.detalleEmpresa.get('tiempo').value;
    
    empresa.usuario = new Usuario();
    empresa.usuario.UsuarioID  = this.idUsuario

    empresa.IdsCentros =  this.detalleEmpresa.get('colegios').value;
    
    this.empresaService.guardarEmpresa(empresa).then(res => {
      console.log("Regreso" + JSON.stringify(res));
      if (res.Success) {
        this.correcto = true;
        this.message = "Empresa guardada correctamente";
        const dialogData = new ConfirmDialogModel('', this.message, false);

        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData 
        });
        this.dialogRef.close(true);
      } else {
        this.successFalse = true
        this.errorMessage = res.Message;
        this.error = true;        
      }
      this.guardando = false;
    }).catch(err => {
      console.log("Error" + err);
    }).finally(() => {

    });    
  }

  tosslePerOneCentros(all){ 
    if (this.allSelectedCentros.selected) {  
     this.allSelectedCentros.deselect();
     return false;
    }

    if(this.detalleEmpresa.controls != null && 
      this.detalleEmpresa.controls.userType != null &&
      this.detalleEmpresa.controls.userType.value != null){
      if(this.detalleEmpresa.controls.userType.value.length==this.centros.length)
        this.allSelectedCentros.select();
    }
  } 

  toggleAllSelectionCentros() {
    if (this.allSelectedCentros.selected) {
      this.mySelCentro.options.forEach( (item : MatOption) => item.select());  
    } else {
      this.mySelCentro.options.forEach( (item : MatOption) => {item.deselect()});
    }
  }

  verify() {
    if (!this.detalleEmpresa.invalid) {
      this.enableBtn = true;
    } 
    if (this.detalleEmpresa.controls.colegios.value == []) {
      this.enableBtn = false;
    } 
  }

  getCentros() {
    this.centroService.getbyUser(this.idUsuario).then(centros => {
      this.centros = centros;
    }).catch(err => {
      console.log("Error" + err);
    });
  }

  _filter(value): string[] {
    const filterValue = value.toLowerCase();
    const resp =  this.option.filter(option => option.Nombre.toLowerCase().includes(filterValue) ||
    option.CampusID.toLowerCase().includes(filterValue) );

    if(resp.length == 1){
      resp.forEach((c) => {
        this.detalleEmpresa.controls.campus.setValue(c.CampusID.toString())
      });
    }
    return resp
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
      return true;
    } else if (/^[0-9]+$/.test(e.key)){
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }

  name_omit_special(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }
  
  omit_special_tie(e: any){
    let Tiempo = this.detalleEmpresa.get('tiempo').value;

    if (Tiempo.length == 0 ) {
      if (e.charCode == 48) {
        e.preventDefault();
        return false;
      } 
    } 
    if  (/^[0-9]+$/.test(e.key)){
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }

  omit_special_mont(e: any){
    let Monto = this.detalleEmpresa.get('monto').value;

    if (Monto.length == 0) {
      if (e.charCode == 48) {
        e.preventDefault();
        return false;
      } 
    } 
    if  (/^[0-9]+$/.test(e.key)){
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }

  numberPhone(e){
    if (/^[0-9]*$/.test(e.key)) {
      return true;
    } 
    else {
      e.preventDefault(); 
      return false;
    }
  }
}