<mat-card-title class="card-title fz-20">NUEVA BECA</mat-card-title>
<mat-divider></mat-divider>
<mat-divider></mat-divider>
<br><br>
<form class="modal-form" name="detalleBecaForm" id="detalleBecaForm" [formGroup]="detalleBecaForm" autocomplete="off">

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Clave</mat-label>
        <input matInput type="text" #input maxlength="20" id="clave" name="clave" formControlName="clave" (input)='verify()' required
        oninput="this.value = this.value.toUpperCase()" (keypress)="omit_special_char($event)" onPaste="return false">
        <mat-hint *ngIf="input.value?.length == 20">Max 20 caracteres</mat-hint>
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Nombre de la beca</mat-label>
        <input #inputNom matInput type="text" maxlength="50" id="nombre"
         name="nombre" formControlName="nombre" (input)='verify()' (keypress)="name_omit_special_char($event)" required>
        <mat-hint *ngIf="inputNom.value?.length == 50">Max 50 caracteres</mat-hint>
    </mat-form-field>

    <mat-form-field class="input-full-width xs-width" appearance="outline">
      <mat-label>Secciones</mat-label>
      <mat-select #mySelSeccion name="secciones" formControlName="secciones"  
      [(value)]="selectedSecciones" id="secciones" required (ngModelChange)='verify()' multiple>
            <mat-option  #allSelectedSecciones (click)="toggleAllSelectionSeccion()" [value]="0">
              <label>Todos</label></mat-option>
            <mat-option *ngFor="let seccion of secciones" [value]="seccion.SeccionID"
            (click)="tosslePerOneSecciones(allSelectedSecciones.viewValue)"> 
              {{seccion.Nombre}}
            </mat-option>
        </mat-select>
    </mat-form-field> 

    <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Requisitos</mat-label>
        <mat-select #mySelRequisitos name="requisitos" formControlName="requisitos"  
        (openedChange)="onSelectRequisitos($event)"
        [(value)]="selectedRequisitos"
        id="requisitos" required (ngModelChange)='verify()' multiple>
          <mat-option #allSelectedRequisitos (click)="toggleAllSelectionRequisito()" [value]="0">
            <label>Todos</label></mat-option>
          <mat-option *ngFor="let requisito of requisitos" [value]="requisito.RequisitoBecaID"
          (click)="tosslePerOneRequisitos(allSelectedRequisitos.viewValue)"> 
            {{requisito.Nombre}}
          </mat-option>
      </mat-select> 
    </mat-form-field>

    <!-- <div class="registro-full-with" style="margin-right: 2rem;">
      <label class="color-marino">
        <input type="checkbox" style="transform: scale(1.5);" 
        (change)='mostrarPorcentaje()'
        formControlName="checkPorcentaje" >
        Configurar Porcentaje
      </label>
    </div>
    <br>
    <div   *ngIf="checkPorcentaje" class="registro-full-with" style="margin-right: 2rem;">
      <mat-form-field class="input-full-width xs-width" appearance="outline">
        <mat-label>Porcentaje:</mat-label>
        <input matInput requiered formControlName="porcentaje" maxlength="3" 
          (ngModelChange)="validarPorcentaje()"
          (keypress)="number_special_char($event)" onPaste="return false" autocomplete="off">
        <mat-hint *ngIf="porcentajeErr">El porcentaje debe ser un número válido menor a 100</mat-hint>
        <span matSuffix>%</span>
      </mat-form-field>    
    </div> -->

    <br><br>
    <table>
      <tr>
        <td><mat-label class="color-marino margin-right">Tipo de beca</mat-label></td>
        <td><mat-radio-group (ngModelChange)='verify()' aria-label="Tipo de beca" required formControlName="tipoBeca">
            <mat-radio-button style="margin-right: 1rem;" value="1"><label>Proceso</label></mat-radio-button>
            <mat-radio-button value="0"><label>Directa</label></mat-radio-button>
            </mat-radio-group> 
        </td>
      </tr>
      <br>
      <tr>
        <td><mat-label class="color-marino margin-right">Mostrar en propuesta de becas</mat-label></td>
        <td>
          <mat-radio-group (ngModelChange)='verify()' aria-label="Mostrar en propuesta de becas" required formControlName="mostrarEnPropuesta">
            <mat-radio-button style="margin-right: 1rem;" value="1"><label>Si</label></mat-radio-button>
            <mat-radio-button value="0"><label>No</label></mat-radio-button>
          </mat-radio-group> 
        </td>
      </tr>
      <br>
      <tr>
        <td><mat-label class="color-marino margin-right">Incluir en presupuesto</mat-label></td>
        <td>
          <mat-radio-group (ngModelChange)='verify()' aria-label="Incluir en presupuesto" required formControlName="incluirEnPresupuesto">
            <mat-radio-button style="margin-right: 1rem;" value="1"><label>Si</label></mat-radio-button>
            <mat-radio-button value="0"><label>No</label></mat-radio-button>
          </mat-radio-group> 
        </td>
      </tr>
  </table>      
</form> 
<br>  
<div class="example-form mat-elevation-z8">
  <mat-table [dataSource]="dataSourceRequisitos" >
      <ng-container matColumnDef="Nombre">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.RequisitoBeca.Nombre}} </mat-cell>
      </ng-container>      
      
      <ng-container matColumnDef="Minimo">
        <mat-header-cell *matHeaderCellDef> Mínimo </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Mínimo"> 
          <input #inputMin matInput type="number" maxlength="10" 
           [min]="element.RequisitoBeca.Minimo"  
           [max]="element.RequisitoBeca.Maximo"
           (input)="validateMinMax(element)" 
           [(ngModel)] ="element.Minimo"> 
           <mat-hint *ngIf="element.RequisitoBeca.Minimo > element.Minimo">Min {{element.RequisitoBeca.Minimo}}</mat-hint>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Maximo">
        <mat-header-cell *matHeaderCellDef> Máximo </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Máximo"> 
          <input #inputMax matInput type="number" maxlength="10" 
              [min]="element.Minimo"  
              [max]="element.RequisitoBeca.Maximo" 
              (input)="validateMinMax(element)"
              [(ngModel)] ="element.Maximo"> 
            <mat-hint *ngIf="element.Minimo > element.Maximo">Min {{element.Minimo}}</mat-hint>
            <mat-hint *ngIf="element.Maximo > element.RequisitoBeca.Maximo">Max {{element.RequisitoBeca.Maximo}}</mat-hint>
        </mat-cell>
      </ng-container> 

      <mat-header-row *matHeaderRowDef="displayedColumnsCiclos"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsCiclos;">
      </mat-row>
  </mat-table> 

  <div *ngIf="length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar.</span>
  </div>
  <br>
</div>
<div class="loading-screen-icon" *ngIf="guardando">
  <mat-spinner></mat-spinner>
</div>
<br><br>
<div class="modal-form margin-div">
    <button mat-raised-button color="warn" class="buttonSecondary xsbtn" (click)="onNoClick()" matTooltip="Cancelar la creación">CANCELAR</button>
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn || guardando" matTooltip="Guardar beca">GUARDAR</button>
</div>

<div *ngIf="error">
  <br>
  <mat-card class='card-danger'>{{errorMessage}}</mat-card>
</div>