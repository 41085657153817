import { PropuestaSolicitud } from "@app/_models/propuestaSolicitud";

export function calculaValores(propuestaSol: PropuestaSolicitud, costoPropuesto?: number) {
  propuestaSol != null && this.reflejaAprobado(propuestaSol);

  if(this.propuestaSolicitudesTodas == null) {
    return;
  }

  /* reset amounts */

  this.costoPropuesto = 0;
  this.porcentajePropuesto = 0;
  this.porcentajeBecasSep = 0;
  this.becasSep = 0;
  this.costoPropuestoSemestre = 0;

  this.propuestaSolicitudesTodas
    .forEach(element => {

      var porcentajeIncCol = 0,
          porcentajeIncReinscripcion = 0,
          porcentajeIncInscripcion = 0,
          porcentajePropuesto = 0,
          ColegiaturaProyectada = 0,
          ReinscripcionProyectada = 0,
          InscripcionProyectada = 0,
          costoAnualSolProp = 0,
          costoSemestre = 0;

      var propuestaSol: PropuestaSolicitud = this.propuestaSolicitudes
        .find(e => e.Solicitud.SolicitudID == element.Solicitud.SolicitudID) ||
        element;

      porcentajeIncCol = propuestaSol.PorcentajeIncCol / 100;
      porcentajeIncReinscripcion = propuestaSol.PorcentajeIncReinscripcion / 100;
      porcentajeIncInscripcion = propuestaSol.PorcentajeIncIns / 100;
      porcentajePropuesto = propuestaSol.BecaPropuesta / 100;
      ColegiaturaProyectada = propuestaSol.CostoColegiatura * (1 + porcentajeIncCol);
      ReinscripcionProyectada = propuestaSol.CostoReinscripcion * (1 + porcentajeIncReinscripcion);
      InscripcionProyectada = propuestaSol.CostoInscripcion * (1 + porcentajeIncInscripcion);

      if(propuestaSol) {
        this.becasSep += porcentajePropuesto;
      }

      costoAnualSolProp = ColegiaturaProyectada * porcentajePropuesto * propuestaSol.Colegiaturas;

      if(propuestaSol.AplicaReinscripcion) {
        costoAnualSolProp += ReinscripcionProyectada * porcentajePropuesto;
      }

      if(propuestaSol.AplicaInscripcion) {
        costoAnualSolProp += InscripcionProyectada * porcentajePropuesto;
      }

      propuestaSol.CostoAnualBecaPropuesto = costoAnualSolProp
      console.log(`costo: ${costoAnualSolProp}`);
      this.costoPropuesto += costoAnualSolProp;

      if(propuestaSol.Colegiaturas != 0) {
        if([4, 5].includes(propuestaSol.Solicitud.Seccion.SeccionID)) {
          costoSemestre += (costoAnualSolProp / propuestaSol.Colegiaturas) * 5;
        }
        else {
          costoSemestre += (costoAnualSolProp / propuestaSol.Colegiaturas) * 4;
        }
      }

      this.costoPropuestoSemestre += costoSemestre;
    });

    this.costoPropuesto += this.costoBecasExternas;

    this.costoPropuestoSemestre += this.costoBecasExternasSemestre;
    this.porcentajeBecasSep = this.becasSep / this.propuesta.TotalAlumnos;
    this.porcentajePropuesto = this.costoPropuesto * 100 / this.propuesta.MontoAprobado;

    var porcProp = this.porcentajePropuesto.toFixed(2);

    if(costoPropuesto) {
      this.costoPropuesto = costoPropuesto;
    }

    var MontoPropuesto = `$${this.costoPropuesto.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
    var MontoPropuestoSemestre = `$${this.costoPropuestoSemestre.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;     

    this.nuevaPropuesta.get('totalMonto').setValue(MontoPropuesto);
    this.nuevaPropuesta.patchValue({ totalMonto: "EXT" });        
    this.nuevaPropuesta.get('porcentajePropuesto').setValue(`${porcProp}%`);  
    this.nuevaPropuesta.get('montoPropuesta').setValue(MontoPropuestoSemestre);

    var totalMonto = this.montoEjercido + this.costoPropuestoSemestre;

    var totalMontoStr = `$${totalMonto.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
    this.nuevaPropuesta.get('costoPropuesto').setValue(totalMontoStr);

    this.verde = this.propuesta.MontoAprobado > totalMonto;
}