<mat-card-title class="card-title fz-20">
  Especificaciones de  {{dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.TipoBeca.Nombre}} por centros
</mat-card-title>
<p class="color-marino fz-14 text-center">Requisito: {{dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.Nombre}}</p>
<div *ngIf="tipoRequisito != 'NUM'"  class="example-form mat-elevation-z8">
  <mat-table  [dataSource]="dataSourceRequisitoCentros" >

    <ng-container matColumnDef="aplicable">
      <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Descripción">
        <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
      </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="Nombre">
      <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Centro.Nombre}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seccion">
      <mat-header-cell *matHeaderCellDef> Especificación por sección </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Máximo"> 
        <button mat-icon-button color="basic" 
        [disabled]="!element.Aplicable"   
        matTooltip="Agregar especificación por sección"
        matTooltipPosition="right" (click)="especificacionXSeccion(element)">
      <mat-icon>dns</mat-icon></button>
      <div class="row" *ngIf="element.RequisitoSecciones.length > 0">
        <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar {{element.RequisitoSecciones.length}} especificaciones"
        (click)="eliminarEspecificacionXSeccion(element)"
        matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
      </div>
      </mat-cell>
    </ng-container> 

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table> 

  <div *ngIf="length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar.</span>
  </div>
  <br>
</div>

<div *ngIf="tipoRequisito == 'NUM'"  class="example-form mat-elevation-z8">
  <mat-table  [dataSource]="dataSourceRequisitoCentros" >
    <ng-container matColumnDef="aplicable">
      <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Descripción">
        <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
      </mat-cell>
    </ng-container>  

      <ng-container matColumnDef="Nombre">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Centro.Nombre}} </mat-cell>
      </ng-container>      
      
      <ng-container matColumnDef="minimo">
        <mat-header-cell *matHeaderCellDef class="sub-title">Mínimo </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="Mínimo">
          <input #inputMin matInput type="number"
          [min]="dataTipoBecaSecciones.TipoBecaRequisito.Minimo"  
          [max]="dataTipoBecaSecciones.TipoBecaRequisito.Maximo" 
          (input)="validateMinMax(element)"     
          [(ngModel)] ="element.Minimo"> 
          <mat-hint *ngIf="element.Invalido">Min {{dataTipoBecaSecciones.TipoBecaRequisito.Minimo}}</mat-hint>              
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="maximo">
        <mat-header-cell *matHeaderCellDef> Máximo </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Máximo"> 
          <input #inputMin matInput type="number"
          [min]="dataTipoBecaSecciones.TipoBecaRequisito.Minimo"  
          [max]="dataTipoBecaSecciones.TipoBecaRequisito.Maximo" 
          (input)="validateMinMax(element)"              
          [(ngModel)] ="element.Maximo"> 
          <mat-hint *ngIf="element.Maximo > dataTipoBecaSecciones.TipoBecaRequisito.Maximo">Max {{dataTipoBecaSecciones.TipoBecaRequisito.Maximo}}</mat-hint>
        </mat-cell>
      </ng-container> 

      <ng-container matColumnDef="seccion">
        <mat-header-cell *matHeaderCellDef> Especificación por sección </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Máximo"> 
          <button mat-icon-button color="basic" matTooltip="Agregar especificación por sección"
          [disabled]="!element.Aplicable"          
          matTooltipPosition="right" (click)="especificacionXSeccion(element)">
        <mat-icon>dns</mat-icon></button>
        <div class="row" *ngIf="element.RequisitoSecciones.length > 0">
          <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar {{element.RequisitoSecciones.length}} especificaciones"
          (click)="eliminarEspecificacionXSeccion(element)"
          matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                              
        </div>
        </mat-cell>
      </ng-container> 

      <mat-header-row *matHeaderRowDef="displayedColumnsCentros"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsCentros;">
      </mat-row>
  </mat-table> 

  <div *ngIf="length == 0" class="text-center margin-top">
    <span class="color-obscuro">No hay registros para mostrar.</span>
  </div>
  <br>
</div>
<div class="loading-screen-icon" *ngIf="guardando">
  <mat-spinner></mat-spinner>
</div>
<br><br><br><br>
<div class="modal-form margin-div">
  <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>  
  <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn"  >ACEPTAR</button>    
</div>

<div *ngIf="error">
  <br>
  <mat-card class='card-danger'>{{errorMessage}}</mat-card>
</div>