<mat-card class="seleccion-beca-card">
  <br>
  <div class="example-form">
    <mat-card-title class="text-left fz-26">CATÁLOGO DE EMPRESAS.</mat-card-title>
    <mat-divider></mat-divider>
  </div>
  <br><br>
  <div class="example-form">
    <form (keydown.enter)="$event.preventDefault()" name="adminInfoForm" id="adminInfoForm" >
      <div class="row">
        <button mat-raised-button class="button" (click)="agregarEmpresa()" >+ NUEVA EMPRESA</button>
        <input class="input-search icon xs-width text-ident" placeholder="Buscar empresa" [(ngModel)]="filtro"  (input)="filterText()" id="filter" name="filter">
        <br>
      </div>
    </form>
  </div>
  <br>
  <div class="example-form mat-elevation-z8">
    <mat-table  [dataSource]="dataSource">
        <ng-container matColumnDef="Nombre">
          <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Nombre"> {{element.NombreEmpresa}} </mat-cell>
        </ng-container>      
          
        <ng-container matColumnDef="Telefono">
          <mat-header-cell *matHeaderCellDef> Teléfono </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Telefono"> {{element.TelefonoEmpresa.Numero}} </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="Correo">
          <mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Correo"> {{element.Correo}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Contacto">
          <mat-header-cell *matHeaderCellDef> Contacto </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Contacto"> {{element.ContactoEmpresa.Nombre}} {{element.ContactoEmpresa.ApellidoPaterno}} {{element.ContactoEmpresa.ApellidoMaterno}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="Costo">
          <mat-header-cell *matHeaderCellDef> Costo </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Costo"> $ {{element.EstudioEmpresa.Costo}}.00 </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Acciones">
          <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Acciones">
              <button mat-icon-button color="basic" aria-label="Editar" (click)="editarEmpresa(element)"  matTooltip="Editar"  matTooltipPosition="right"><mat-icon>edit</mat-icon></button>
              <button mat-icon-button color="accent" aria-label="Eliminar" matTooltip="Eliminar" (click)="eliminarEmpresa(element)"  matTooltipPosition="right"><mat-icon>delete_forever</mat-icon></button>                  
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
    </mat-table> 
  
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>
    
    <mat-paginator #paginator
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 20]"
      (page)="pageEvent = getServerData($event)"
      >
    </mat-paginator>
  </div>    
  <br><br>
</mat-card>