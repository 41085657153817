import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder, NumberValueAccessor } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HermanoSolicitud } from '@app/_models/hermanoSolicitud';
import { Centro } from '@app/_models/centro';
import { Seccion } from '@app/_models/seccion';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { HermanoSolicitudOperation } from '@app/_models/hermanoOperacion';
import { CentroService } from '@app/_services/centro.service';
import { SeccionService } from '@app/_services/seccion.service';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { Alumno } from '@app/_models/alumno';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-detalle-hermanos',
  templateUrl: './detalle-hermanos.component.html',
  styleUrls: ['./detalle-hermanos.component.scss']
})
export class DetalleHermanosComponent implements OnInit {
  detalleHermanoForm: FormGroup;
  porcentajeErr = false;
  hermanoSolicitud : HermanoSolicitud;
  errorCurp: boolean = false; 
  enableBtn: boolean = false; 
  centros: Centro[] = [];
  secciones: Seccion[] = [];
  grados : GradoEducativo [] = [];   

  constructor(private formBuilder: FormBuilder, 
    public dialogRef: MatDialogRef<DetalleHermanosComponent>,
    private centroService: CentroService,
    private seccionService: SeccionService,
    private router: Router,
    private gradoEducativoService: GradoEducativoService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: HermanoSolicitudOperation) { }

  ngOnInit(): void {
  
    this.seccionService.getAll().then(secciones => {
      this.secciones = secciones;
      console.log("Secciones " + JSON.stringify(this.secciones));    
    }).catch(err => {
    console.log("Error" + err);
    });    
    
    if (this.data.Operacion === 'Crear') {
      this.data.HermanoSolicitud = new HermanoSolicitud();
      this.data.HermanoSolicitud.Alumno = new Alumno();
      this.data.HermanoSolicitud.Centro = new Centro();
      this.data.HermanoSolicitud.GradoEducativo= new GradoEducativo();
      this.data.HermanoSolicitud.Seccion = new Seccion();
    }else{
      this.enableBtn = true
      this.hermanoSolicitud =this.data.HermanoSolicitud;
      if(this.hermanoSolicitud.Seccion != null ){
        this.centroService.getXSeccion(this.hermanoSolicitud.Seccion.SeccionID).then(centros => {
          this.centros = centros
        }).catch(err => {
        console.log("Error" + err);
        }); 
      }

      if(this.hermanoSolicitud.Seccion != null && this.hermanoSolicitud.Centro != null){
        this.gradoEducativoService.consultaGradosEducativos(this.hermanoSolicitud.Centro.CentroID, this.hermanoSolicitud.Seccion.SeccionID).then(grados => {
          this.grados = grados;
        }).catch(err => {
        console.log("Error" + err);
        }); 
      }
    }

    this.detalleHermanoForm = this.formBuilder.group({
      curp: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      apellidoPaterno: ['', [Validators.required]],
      apellidoMaterno: ['', ],            
      seccion: ['', [Validators.required]],
      centro: ['', [Validators.required]],
      grado: ['', [Validators.required]],      
      tieneBeca: ['', [Validators.required]],
      porcentaje: [''],
    });
    
    console.log("Data: " + JSON.stringify(this.data.HermanoSolicitud));
      this.detalleHermanoForm.get('curp').setValue(this.data.HermanoSolicitud.Alumno.CURP);    
      this.detalleHermanoForm.get('nombre').setValue(this.data.HermanoSolicitud.Alumno.Nombre);    
      this.detalleHermanoForm.get('apellidoPaterno').setValue(this.data.HermanoSolicitud.Alumno.ApellidoPaterno);    
      this.detalleHermanoForm.get('apellidoMaterno').setValue(this.data.HermanoSolicitud.Alumno.ApellidoMaterno);    
      this.detalleHermanoForm.get('seccion').setValue(this.data.HermanoSolicitud.Seccion);    
      this.detalleHermanoForm.get('centro').setValue(this.data.HermanoSolicitud.Centro);        
      this.detalleHermanoForm.get('grado').setValue(this.data.HermanoSolicitud.GradoEducativo);    
      this.detalleHermanoForm.get('tieneBeca').setValue(this.data.HermanoSolicitud.TieneBeca);    
      this.detalleHermanoForm.get('porcentaje').setValue(this.data.HermanoSolicitud.Porcentaje);   
      
      if(this.data.HermanoSolicitud.TieneBeca){
        this.detalleHermanoForm.get('tieneBeca').setValue("1");    
      }else{
        this.detalleHermanoForm.get('tieneBeca').setValue("0");    
      }
  }

  agregar(){
    
    if (this.detalleHermanoForm.invalid) {
      this.showMessage('Debe llenar todos los campos marcados con *', 'Error');
      return
    }
    if (this.detalleHermanoForm.controls.seccion.value.SeccionID == undefined ||
      this.detalleHermanoForm.controls.centro.value.CentroID == undefined ||
      this.detalleHermanoForm.controls.grado.value.GradoEducativoID == undefined) {
      return
    }
    this.data.HermanoSolicitud.Alumno.CURP = this.detalleHermanoForm.get('curp').value;
    this.data.HermanoSolicitud.Alumno.Nombre = this.detalleHermanoForm.get('nombre').value;
    this.data.HermanoSolicitud.Alumno.ApellidoPaterno = this.detalleHermanoForm.get('apellidoPaterno').value;
    this.data.HermanoSolicitud.Alumno.ApellidoMaterno = this.detalleHermanoForm.get('apellidoMaterno').value;
    this.data.HermanoSolicitud.Seccion = this.detalleHermanoForm.get('seccion').value;
    this.data.HermanoSolicitud.Centro = this.detalleHermanoForm.get('centro').value;
    this.data.HermanoSolicitud.GradoEducativo = this.detalleHermanoForm.get('grado').value;
    this.data.HermanoSolicitud.Porcentaje = this.detalleHermanoForm.get('porcentaje').value;

    var tieneBeca = this.detalleHermanoForm.get('tieneBeca').value;
    if(tieneBeca=="0"){
      this.data.HermanoSolicitud.TieneBeca = false;
    }else{
      this.data.HermanoSolicitud.TieneBeca = true;      
    }

    this.dialogRef.close(this.data);
  }

  verify() {
    if (this.detalleHermanoForm.controls.tieneBeca.value == 0) {
      this.detalleHermanoForm.get('porcentaje').setValue(null);  
    }
    if (this.detalleHermanoForm.controls.tieneBeca.value == 1 && this.detalleHermanoForm.controls.porcentaje.value == null) {
      this.porcentajeErr = true
    }
    if (this.detalleHermanoForm.controls.seccion.value.SeccionID != undefined &&
      this.detalleHermanoForm.controls.centro.value.CentroID != undefined &&
      this.detalleHermanoForm.controls.grado.value.GradoEducativoID != undefined) {
      this.enableBtn = true
    }
    if (this.detalleHermanoForm.invalid) {
      this.enableBtn = false
    }
  }

  name_omit_special(e: any) {
    if (/^[a-zA-ZÀ-ú\s]*$/.test(e.key)) {
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }
  
  cancelar(): void {
    this.dialogRef.close();
  }

  onSelectSeccion(entrando) {
    if(!entrando){
      this.centros = [];
      var Seccion  =  this.detalleHermanoForm.get('seccion').value;  
      console.log("Seleecion seccion..." + Seccion.SeccionID);    
      if(Seccion != null && Seccion.SeccionID != 0){
        this.centroService.getXSeccion(Seccion.SeccionID).then(centros => {
          this.centros = centros
        }).catch(err => {
        console.log("Error" + err);
        });
      }
    }
  }

  onSelectCentro(entrando) {
    if(!entrando){
      console.log("Seleecion grado...");  
      this.grados = [];
      var Seccion  =  this.detalleHermanoForm.get('seccion').value;  
      var Centro  =  this.detalleHermanoForm.get('centro').value;  
      if( Seccion  != null && Seccion.SeccionID != 0 
            && Centro.CentroID != null && Centro.CentroID != 0){
          this.gradoEducativoService.consultaGradosEducativos(Centro.CentroID, Seccion.SeccionID).then(grados => {
          this.grados = grados;
        }).catch(err => {
        console.log("Error" + err);
        }); 
      }
    }
  }

  compareSecction(s1: Seccion, s2: Seccion): boolean {
    return s1.SeccionID ===s2.SeccionID;
  }
  
  compareCentro(s1: Centro, s2: Centro): boolean {
    return s1.CentroID ===s2.CentroID;
  }

  compareGrado(s1: GradoEducativo, s2: GradoEducativo): boolean {
    return s1.GradoEducativoID ===s2.GradoEducativoID;
  }

  validarPorcentaje(){
    this.porcentajeErr = false;
    
    var validaPorcentaje = this.detalleHermanoForm.get('porcentaje').value;      
    if(validaPorcentaje != null){
      var validaPorcentajeNumber =  Number(validaPorcentaje);      
      if(validaPorcentajeNumber){
        if( validaPorcentajeNumber > 100 || validaPorcentajeNumber < 0){              
          this.porcentajeErr = true;
        }
      }
      else{
        this.porcentajeErr = true;
      }
    }
  }

  validarCurp(){

    var curp =  this.detalleHermanoForm.get('curp').value;
    
     if(curp != null){
       curp = curp.toUpperCase();
       this.errorCurp = false;
       if(curp != null && curp.length >0){
         var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
         validado = curp.match(re);
         if (!validado)  //Coincide con el formato general?
         this.errorCurp = true;
       }
     }
  }

  omit_special_mont(e: any){
    if  (/^[0-9]+$/.test(e.key)){
      return true;
    } else{
      e.preventDefault();
      return false;
    }
  }

  private showMessage(message: string, titulo: string): MatDialogRef<AlertaComponent, any> {

    const dialogData = new ConfirmDialogModel(titulo, message, false);

    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    return dialogRef
  } 
}