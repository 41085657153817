<mat-card-title class="card-title fz-20">
  Especificaciones para  {{dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.Nombre}} por grados
  </mat-card-title>
  <div *ngIf="tipoRequisito == 'NUM'"  class="example-form mat-elevation-z8">
    <mat-table  [dataSource]="dataSourceRequisitoGrados" >

        <ng-container matColumnDef="aplicable">
            <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Descripción">
              <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
            </mat-cell>
          </ng-container>           

      <ng-container matColumnDef="grado">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Grado.Nombre}} </mat-cell>
      </ng-container>
 
        
      <ng-container matColumnDef="minimo">
        <mat-header-cell *matHeaderCellDef class="sub-title">Mínimo </mat-header-cell><br>
        <mat-cell *matCellDef="let element" data-label="Mínimo">
          <input #inputMin matInput type="number"   [disabled]="!element.Aplicable"
          [min]="dataTipoBecaSecciones.Minimo"  
          [max]="dataTipoBecaSecciones.Maximo" 
          (input)="validateMinMax(element)"       
          [(ngModel)] ="element.Minimo"> 
          <mat-hint *ngIf="dataTipoBecaSecciones.Minimo > element.Minimo">Min {{dataTipoBecaSecciones.Minimo}}</mat-hint>            
        </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="maximo">
        <mat-header-cell *matHeaderCellDef> Máximo </mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Máximo"> 
          <input #inputMin matInput type="number"  [disabled]="!element.Aplicable"
          [min]="dataTipoBecaSecciones.Minimo"  
          [max]="dataTipoBecaSecciones.Maximo" 
          (input)="validateMinMax(element)"       
          [(ngModel)] ="element.Maximo"> 
          <mat-hint *ngIf="element.Maximo > dataTipoBecaSecciones.Maximo">Max {{dataTipoBecaSecciones.Maximo}}</mat-hint>               
        </mat-cell>
      </ng-container> 
        
  
      <mat-header-row *matHeaderRowDef="displayedColumnsNum"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsNum;">
      </mat-row>
    </mat-table> 
    </div>

  <div *ngIf="tipoRequisito != 'NUM'"  class="example-form mat-elevation-z8">
    <mat-table  [dataSource]="dataSourceRequisitoGrados" >

        <ng-container matColumnDef="aplicable">
            <mat-header-cell *matHeaderCellDef> Aplicable</mat-header-cell>
            <mat-cell *matCellDef="let element" data-label="Descripción">
              <mat-checkbox  [(ngModel)]="element.Aplicable" ></mat-checkbox>
            </mat-cell>
          </ng-container>           

      <ng-container matColumnDef="grado">
        <mat-header-cell *matHeaderCellDef> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Descripción"> {{element.Grado.Nombre}} </mat-cell>
      </ng-container>
 
        
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table> 
  
    <div *ngIf="length == 0" class="text-center margin-top">
      <span class="color-obscuro">No hay registros para mostrar.</span>
    </div>
    <br>
  </div>
  
  <div class="loading-screen-icon" *ngIf="guardando">
    <mat-spinner></mat-spinner>
  </div>
  <br><br><br><br>
  <div class="modal-form margin-div">
    <button mat-raised-button color="primary" class="buttonSecondary xsbtn" (click)="onNoClick()" >CANCELAR</button>  
    <button mat-raised-button color="primary" class="button xsbtn" (click)="onSubmit()" [disabled]="!enableBtn"  >ACEPTAR</button>    
  </div>
  
  <div *ngIf="error">
    <br>
    <mat-card class='card-danger'>{{errorMessage}}</mat-card>
  </div>