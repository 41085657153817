import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '@app/_services/authentication.service'
import { NavigationService } from '@app/_services/navigation.service';
import { environment } from '@environments/environment';
import { FooterComponent } from './_components/footer/footer.component';
import { NavigationBarComponent } from './_components/navigation-bar/navigation-bar.component';
import { CentroService } from './_services/centro.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Portal de becas';
  @ViewChild('footer') footer:FooterComponent;
  @ViewChild('navigationbar') navigationbar:NavigationBarComponent;

  

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private centroService: CentroService, 
    private navigationService: NavigationService,
  ) {

    console.log("constructor app.components ");
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
      //   gtag('config', environment.gaKey,
      //     {
      //       'page_path': event.urlAfterRedirects
      //     }
      //   );
      // }
    });
    this.navigationService.isMobile.subscribe(data => {
      let modoAnterior = this.sidebarMode;
      this.sidebarMode = data ? "over" : "side";
      this.sidebarPosition = data ? "end" : "start";
      if (!data && !this.sidebarOpened) {
        this.sidebarOpened = true;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }
      if (data && this.sidebarOpened && this.sidebarMode != modoAnterior && this.sidebarMode == "over") {
        this.sidebarOpened = false;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }

      if (!this.authenticationService.isAuthenticated) {
        this.sidebarOpened = false;
        this.navigationService.isToggledMenu.next(this.sidebarOpened);
      }
      return;
    })

    this.navigationService.ToggleMenuValue.subscribe(data => {
        this.sidebarOpened = data;
    });
    
    this.authenticationService.currentUser.subscribe(user => {
      this.isProspect = false;
      if (user != null) {
        if(user.IsProspecto && user.IsAdmin){
        this.isProspect = user.IsProspecto;
        this.isProspect ? this.sidebarStyle ="display: block" : this.sidebarStyle ="display: none";
        return
        }
        if(user.IsProspecto){
          this.isProspect = user.IsProspecto;
          this.isProspect ? this.sidebarStyle ="display: none" : this.sidebarStyle ="display: block";
          var CentroID  = user.CentroID;
          if(CentroID != ""){
            this.centroService.obtenerRedes(CentroID).then( e => {
              if(e){
                this.footer.redes = e;
              }

              if(this.navigationbar != null){
                this.navigationbar.footer = this.footer;
          
              }
            }).catch(err => {
              console.log("Error" + err);
            });   
            
          }    
          return
        }
        this.sidebarStyle ="display: block"
        this.isAuth = true
      }else{
        this.isAuth = false
      }
    })


    
  };

  public isAuthenticated() {
    return this.authenticationService.isAuthenticated;
  }

  public sidebarMode: string = "side";
  public sidebarPosition: string = "start";
  public sidebarOpened: boolean = true;
  public sidebarStyle: string = "display: block";
  public isProspect: boolean = false;
  public isAuth: boolean = true;
}
