import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Centro } from '@app/_models/centro';
import { MensajeAyuda } from '@app/_models/mensajeAyuda';
import { AlumnoService } from '@app/_services/alumno.service';
import { CentroService } from '@app/_services/centro.service';
import { AlertaComponent, ConfirmDialogModel } from '../alerta/alerta.component';

@Component({
  selector: 'app-correo-ayuda',
  templateUrl: './correo-ayuda.component.html',
  styleUrls: ['./correo-ayuda.component.scss']
})
export class CorreoAyudaComponent implements OnInit {

  correoAyudaForm: FormGroup;
  centros: Centro[] = [];
  errorCurp: boolean = false; 
  enviando = false;
  errorMessage :string;
  error : boolean;
  enableBtn = false;
  correoError = false;

  constructor(    
    private centroService: CentroService,
    private formBuilder: FormBuilder, 
    private alummnoService: AlumnoService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CorreoAyudaComponent>,
  ) { }

  ngOnInit(): void {
    this.correoAyudaForm = this.formBuilder.group({
      curp: ['', Validators.required],
      centro: ['', Validators.required],
      apellidoPaterno: ['', Validators.required],
      apellidoMaterno: [''],
      nombre: ['', Validators.required],
      //correo: ['', Validators.required],
      mensaje: ['', Validators.required,],
    });    

    this.getCentro();
  }

  getCentro(){
    this.centroService.getAll().then(centros => {
      this.centros = centros
    }).catch(err => {
    console.log("Error" + err);
    }); 
  }

  aceptar(): void {
    this.enviando = true;
    
    var mensaje = new MensajeAyuda()
        var CentroID = this.correoAyudaForm.get('centro').value;
    var Centro = this.centros.find(x => x.CentroID == CentroID);
    mensaje.Centro = Centro;
    mensaje.ApellidoPaterno = this.correoAyudaForm.get('apellidoPaterno').value;
    mensaje.ApellidoMaterno = this.correoAyudaForm.get('apellidoMaterno').value;
    mensaje.Nombre = this.correoAyudaForm.get('nombre').value;    
    mensaje.Curp = this.correoAyudaForm.get('curp').value;
    //mensaje.Correo = this.correoAyudaForm.get('correo').value;
    mensaje.Mensaje = this.correoAyudaForm.get('mensaje').value;
    
    this.alummnoService.mensajeAyuda(mensaje).then( res =>{
      if (res.Exito) {
        const dialogData = new ConfirmDialogModel('', "Mensaje enviado", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });
        
        this.dialogRef.close(true);
      }else{
        const dialogData = new ConfirmDialogModel('Error', "Hubo un error al enviar el mensaje", false);    
        const dialogRef = this.dialog.open(AlertaComponent, {
          maxWidth: "400px",
          data: dialogData
        });

        this.errorMessage = res.Mensaje ? res.Mensaje : 'Error al guardar';
        this.error = true;       
      }
      this.enviando = false;
    }
    ).catch(err => {
      console.log("Error" + err);
    }).finally(() => {});;
  }

  cancelar(): void {
    this.dialogRef.close(false);
  }

  validarCurp(){
    var curp =  this.correoAyudaForm.get('curp').value;
     if(curp != null){
       curp = curp.toUpperCase();
       this.errorCurp = false;
       if(curp != null && curp.length >0){
         var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
         validado = curp.match(re);
         if (!validado)  //Coincide con el formato general?
         this.errorCurp = true;
       }
     }
  }

  name_omit_special_char(e: any) {
    if (/^[a-zA-Z\s]*$/.test(e.key)) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
  }

  verify() {
    if (this.correoAyudaForm.invalid) {
      this.enableBtn = false;
    } else {
      this.enableBtn = true;
    }
    console.log("enableBtn" + this.enableBtn);
  }

  validarCorreo() {
    this.correoError = false;
    var correo = this.correoAyudaForm.get('correo').value;

    if(correo != null && correo.length > 0){
      var mailformat =`(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"`
      + `(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")`
      + `@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`
      + `|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]`
      + `:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`;
      var match = correo.match(mailformat);
      console.log("Correo " + match);
      if (correo != "" && !match)
      {
        this.correoError=true;
      }
      {
        this.correoError=true;
      }
    }
  }   
}