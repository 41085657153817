import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { JwtInterceptor } from './_shared/jwt.interceptor';
import { ErrorInterceptor } from './_shared/error.interceptor';
import { LoadingScreenInterceptor } from './_shared/loading.interceptor';
import { PortalInterceptor } from './_shared/portal.interceptor';
import { PaginatorI18n } from './_shared/es-paginator-intl';
import {CurrencyPipe} from '@angular/common';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';

import { AdministracionComponent } from './administracion/administracion.component';
import { LoginComponent } from './login/login.component';
import { RecuperaCuentaComponent } from './recupera-cuenta/recupera-cuenta.component';
import { RegistroComponent } from './registro/registro.component';
import { AyudaComponent } from './ayuda/ayuda.component';
import { DetalleUsuarioComponent } from './_components/detalle-usuario/detalle-usuario.component';
import { NavigationBarComponent } from './_components/navigation-bar/navigation-bar.component';
import { CambiaContrasenaComponent } from './cambia-contrasena/cambia-contrasena.component';
import { FooterComponent } from './_components/footer/footer.component';
import { InicioComponent } from './inicio/inicio.component';
import { SideBarComponent } from './_components/side-bar/side-bar.component';
import { AlertaComponent } from './_components/alerta/alerta.component';
import { ActivarComponent } from './activar/activar.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { RegistroEsperaComponent } from './registro-espera/registro-espera.component';
import { LoadingScreenComponent } from './_components/loading-screen/loading-screen.component';
import { RecuperaCuentaEsperaComponent } from './recupera-cuenta-espera/recupera-cuenta-espera.component';
import { CambiaContrasenaValidarComponent } from './cambia-contrasena-validar/cambia-contrasena-validar.component';
import { PantallaPrincipalComponent } from './pantalla-principal/pantalla-principal.component';
import { BuscarCodigoPostalComponent } from './_components/buscar-codigo-postal/buscar-codigo-postal.component';
import { AdministracionUsuariosComponent } from './administracion-usuarios/administracion-usuarios.component';
import { DescargaDocumentosComponent } from './descarga-documentos/descarga-documentos.component';
import { ReferenciaComponent } from './referencia/referencia.component';
import { ProspectosAvisoComponent } from './prospectos-aviso/prospectos-aviso.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ContactoComponent } from './contacto/contacto.component';
import { SolicitudBecaComponent } from './_components/solicitud-beca/solicitud-beca.component';
import { RevisionSolicitudesComponent } from './revision-solicitudes/revision-solicitudes.component';
import { RevisionRequisitosComponent } from './revision-requisitos/revision-requisitos.component';
import { AsignacionPresupuestoComponent } from './asignacion-presupuesto/asignacion-presupuesto.component';
import { AnalisisBecasComponent } from './analisis-becas/analisis-becas.component';
import { EstudioSocioeconomicoComponent } from './estudio-socioeconomico/estudio-socioeconomico.component';
import { CatalogoEmpresasComponent } from './catalogo-empresas/catalogo-empresas.component';
import { DetalleEmpresasComponent } from './_components/detalle-empresas/detalle-empresas.component';
import { InformacionBecasComponent } from './informacion-becas/informacion-becas.component';
import { PropuestasBecasComponent } from './propuestas-becas/propuestas-becas.component';
import { AperturaCiclosComponent } from './apertura-ciclos/apertura-ciclos.component';


import { DatePipe } from '@angular/common'
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { ProfileComponent } from './profile/profile.component';
import { SeleccionBecaComponent } from './seleccion-beca/seleccion-beca.component';
import { DatosGeneralesComponent } from './datos-generales/datos-generales.component';
import { DatosFamiliaresComponent } from './datos-familiares/datos-familiares.component';
import { RequisitosComponent } from './requisitos/requisitos.component';
import { ConclusionBecaComponent } from './conclusion-beca/conclusion-beca.component';
import { DetalleHermanosComponent } from './_components/detalle-hermanos/detalle-hermanos.component';
import { ExpedientesAsignadosComponent } from './expedientes-asignados/expedientes-asignados.component';
import { DetalleEstudiosocioeconimicoComponent } from './_components/detalle-estudiosocioeconimico/detalle-estudiosocioeconimico.component';
import { AltaEmpresaComponent } from './alta-empresa/alta-empresa.component';
import { ContrasenaEmpresasComponent } from './contrasena-empresas/contrasena-empresas.component';
import { ContrasenaEmpleadoComponent } from './contrasena-empleado/contrasena-empleado.component';
import { AdmEstudiosocioeconomicoComponent } from './adm-estudiosocioeconomico/adm-estudiosocioeconomico.component';
import { RevisionEstudioSocioeconomicoComponent } from './revision-estudio-socioeconomico/revision-estudio-socioeconomico.component';
import { AdmUsuariosEmpresasComponent } from './adm-usuarios-empresas/adm-usuarios-empresas.component';
import { DetUsuarioEmpresaComponent } from './_components/det-usuario-empresa/det-usuario-empresa.component';
import { DetalleDatosBecasComponent } from './_components/detalle-datos-becas/detalle-datos-becas.component';
import { DetalleAlumnoBecasComponent } from './_components/detalle-alumno-becas/detalle-alumno-becas.component';
import { DetalleCuotasBecasComponent } from './_components/detalle-cuotas-becas/detalle-cuotas-becas.component';
import { DetallePropuestaBecasComponent } from './_components/detalle-propuesta-becas/detalle-propuesta-becas.component';
import { DetalleResumenBecasComponent } from './_components/detalle-resumen-becas/detalle-resumen-becas.component';
import { DetalleAnalisisBecasComponent } from './_components/detalle-analisis-becas/detalle-analisis-becas.component';
import { RevisionPropuestaBecasComponent } from './revision-propuesta-becas/revision-propuesta-becas.component';
import { ReportesComponent } from './reportes/reportes.component';
import { DetalleCicloComponent } from './_components/detalle-ciclo/detalle-ciclo.component';
import { DetalleReqCicloComponent } from './_components/detalle-req-ciclo/detalle-req-ciclo.component';
import { DetalleBecasCicloComponent } from './_components/detalle-becas-ciclo/detalle-becas-ciclo.component';
import { ConfiguracionReqComponent } from './configuracion-req/configuracion-req.component';
import { ConfiguracionBecaComponent } from './configuracion-beca/configuracion-beca.component';
import { PropuestaBecaTabsComponent } from './_components/propuesta-beca-tabs/propuesta-beca-tabs.component';
import { RevisionBecaTabsComponent } from './_components/revision-beca-tabs/revision-beca-tabs.component';
import { HistorialPropuestaBecaComponent } from './_components/historial-prop-beca/historial-prop-beca.component';
import { MensajePropuestaComponent } from './_components/mensaje-propuesta/mensaje-propuesta.component';
import { HistorialSolicitudComponent } from './_components/historial-solicitud/historial-solicitud.component';
import { DetalleAdmSobrepasosComponent } from './_components/detalle-adm-sobrepasos/detalle-adm-sobrepasos.component';
import { AdmSobrepasosComponent } from './adm-sobrepasos/adm-sobrepasos.component';
import {MatChipsModule} from '@angular/material/chips';
import { ResumenBecasComponent } from './resumen-becas/resumen-becas.component';
import { ResumenSolicitudComponent } from './resumen-solicitud/resumen-solicitud.component';
import { TreetableModule } from 'ng-material-treetable';
import { RecuperaCuentaEmpresaComponent } from './recupera-cuenta-empresa/recupera-cuenta-empresa.component';
import { DetalleCalificacionComponent } from './_components/detalle-calificacion/detalle-calificacion.component';
import { ModificacionBecasComponent } from './modificacion-becas/modificacion-becas.component';
import { RevisionModificacionBecasComponent } from './revision-modificacion-becas/revision-modificacion-becas.component';
import { BecasExtemporaneasComponent } from './becas-extemporaneas/becas-extemporaneas.component';
import { DetalleModBecasComponent } from './_components/detalle-mod-becas/detalle-mod-becas.component';
import { HistorialModBecasComponent } from './_components/historial-mod-becas/historial-mod-becas.component';
import { HistorialExtemporaneasComponent } from './_components/historial-extemporaneas/historial-extemporaneas.component';
import { DetalleExtemporaneasComponent } from './_components/detalle-extemporaneas/detalle-extemporaneas.component';
import { DetalleRevisionModificacionBecaComponent } from './_components/detalle-revision-modificacion-beca/detalle-revision-modificacion-beca.component';

import { environment } from '@environments/environment';
import { DetalleNuevoExtempComponent } from './_components/detalle-nuevo-extemp/detalle-nuevo-extemp.component';
import { DetalleBecasExtComponent } from './_components/detalle-becas-ext/detalle-becas-ext.component';
import { BecasExternasComponent } from './becas-externas/becas-externas.component';
import { HistorialComponent } from './_components/historial/historial.component';
import { EspecificacionReqComponent } from './especificacion-req/especificacion-req.component';
import { TipobecaRequisitoCentroComponent } from './_components/tipobeca-requisito-centro/tipobeca-requisito-centro.component';
import { TipobecaRequisitoSeccionComponent } from './_components/tipobeca-requisito-seccion/tipobeca-requisito-seccion.component';
import { TipobecaRequisitoCentroSecComponent } from './_components/tipobeca-requisito-centro-sec/tipobeca-requisito-centro-sec.component';
import { CargasComponent } from './cargas/cargas.component';
import { CartaBecaComponent } from './carta-beca/carta-beca.component';
import { MensajeSolicitudComponent } from './_components/mensaje-solicitud/mensaje-solicitud.component';
import { EnvioCartaComponent } from './_components/envio-carta/envio-carta.component';
import  {MatCurrencyFormatModule} from 'mat-currency-format';
import { MensajeCorreoComponent } from './_components/mensaje-correo/mensaje-correo.component';
import { RenovacionBecaComponent } from './renovacion-beca/renovacion-beca.component';
import { DetalleRenovacionBecaComponent } from './_components/detalle-renovacion-beca/detalle-renovacion-beca.component';
import { HistorialRenovacionBecaComponent } from './_components/historial-renovacion-beca/historial-renovacion-beca.component';
import { RecuperaCuentaEmpleadoComponent } from './recupera-cuenta-empleado/recupera-cuenta-empleado.component';
import { EspecificacionBecaComponent } from './especificacion-beca/especificacion-beca.component';
import { TipobecaSecGradoComponent } from './_components/tipobeca-sec-grado/tipobeca-sec-grado.component';
import { TipobecaSecCentroComponent } from './_components/tipobeca-sec-centro/tipobeca-sec-centro.component';
import { TipobecaSecCenGraComponent } from './_components/tipobeca-sec-cen-gra/tipobeca-sec-cen-gra.component';
import { CorreoAyudaComponent } from './_components/correo-ayuda/correo-ayuda.component';
import { CicloCentroComponent } from './_components/ciclo-centro/ciclo-centro.component';
import { EspecificacionCicloCentroComponent } from './_components/especificacion-ciclo-centro/especificacion-ciclo-centro.component';
import { TipobecaReqSecGraComponent } from './_components/tipobeca-req-sec-gra/tipobeca-req-sec-gra.component';
import { TipobecaReqSecCenGraComponent } from './_components/tipobeca-req-sec-cen-gra/tipobeca-req-sec-cen-gra.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

registerLocaleData(localeEsMx);

@NgModule({
  declarations: [
    AppComponent,
    AdministracionComponent,
    LoginComponent,
    RecuperaCuentaComponent,
    RegistroComponent,
    AyudaComponent,
    DetalleUsuarioComponent,
    NavigationBarComponent,
    CambiaContrasenaComponent,
    FooterComponent,
    InicioComponent,
    SideBarComponent,
    AlertaComponent,
    ActivarComponent,
    SignOutComponent,
    RegistroEsperaComponent,
    LoadingScreenComponent,
    CambiaContrasenaValidarComponent,
    RecuperaCuentaEsperaComponent,
    PantallaPrincipalComponent,
    BuscarCodigoPostalComponent,
    AdministracionUsuariosComponent,
    DescargaDocumentosComponent,
    ReferenciaComponent,
    ProspectosAvisoComponent,
    ContactoComponent,
    SolicitudBecaComponent,
    RevisionSolicitudesComponent,
    RevisionRequisitosComponent,
    AsignacionPresupuestoComponent,
    AnalisisBecasComponent,
    EstudioSocioeconomicoComponent,
    CatalogoEmpresasComponent,
    DetalleEmpresasComponent,
    InformacionBecasComponent,
    PropuestasBecasComponent,
    AperturaCiclosComponent,
    ProfileComponent,
    SeleccionBecaComponent,
    DatosGeneralesComponent,
    DatosFamiliaresComponent,
    RequisitosComponent,
    ConclusionBecaComponent,
    DetalleHermanosComponent,
    ExpedientesAsignadosComponent,
    DetalleEstudiosocioeconimicoComponent,
    AltaEmpresaComponent,
    ContrasenaEmpresasComponent,
    ContrasenaEmpleadoComponent,
    AdmEstudiosocioeconomicoComponent,
    RevisionEstudioSocioeconomicoComponent,
    AdmUsuariosEmpresasComponent,
    DetUsuarioEmpresaComponent,
    DetalleDatosBecasComponent,
    DetalleAlumnoBecasComponent,
    DetalleCuotasBecasComponent,
    DetallePropuestaBecasComponent,
    DetalleResumenBecasComponent,
    DetalleAnalisisBecasComponent,
    RevisionPropuestaBecasComponent,
    ReportesComponent,
    DetalleCicloComponent,
    DetalleReqCicloComponent,
    DetalleBecasCicloComponent,
    ConfiguracionReqComponent,
    ConfiguracionBecaComponent,
    PropuestaBecaTabsComponent,
    RevisionBecaTabsComponent,
    HistorialPropuestaBecaComponent,
    MensajePropuestaComponent,
    HistorialSolicitudComponent,
    DetalleAdmSobrepasosComponent,
    AdmSobrepasosComponent,
    ResumenBecasComponent,
    ResumenSolicitudComponent,
    RecuperaCuentaEmpresaComponent,
    DetalleCalificacionComponent,
    ModificacionBecasComponent,
    RevisionModificacionBecasComponent,
    BecasExtemporaneasComponent,
    DetalleModBecasComponent,
    HistorialModBecasComponent,
    HistorialExtemporaneasComponent,
    DetalleExtemporaneasComponent,
    DetalleRevisionModificacionBecaComponent,
    DetalleNuevoExtempComponent,
    DetalleBecasExtComponent,
    BecasExternasComponent,
    HistorialComponent,
    EspecificacionReqComponent,
    TipobecaRequisitoCentroComponent,
    TipobecaRequisitoSeccionComponent,
    TipobecaRequisitoCentroSecComponent,
    CargasComponent,
    CartaBecaComponent,
    MensajeSolicitudComponent,
    EnvioCartaComponent,
    MensajeCorreoComponent,
    RenovacionBecaComponent,
    DetalleRenovacionBecaComponent,
    HistorialRenovacionBecaComponent,
    RecuperaCuentaEmpleadoComponent,
    EspecificacionBecaComponent,
    TipobecaSecGradoComponent,
    TipobecaSecCentroComponent,
    TipobecaSecCenGraComponent,
    TipobecaReqSecGraComponent,
    TipobecaReqSecCenGraComponent,
    CorreoAyudaComponent,
    CicloCentroComponent,
    EspecificacionCicloCentroComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FlexLayoutModule,
    RecaptchaModule,  //this is the recaptcha main module
    RecaptchaFormsModule,
    FontAwesomeModule, //this is the module for form incase form validation
    MatToolbarModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatProgressBarModule,
    CKEditorModule,
    MatChipsModule,
    TreetableModule,
    MatExpansionModule,
    MatSortModule,
    MatCurrencyFormatModule,
    MsalModule.forRoot({
      auth: {
        clientId: `${environment.office365_cliente}`,
        authority: `https://login.microsoftonline.com/${environment.office365_inclino}`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    },
    {
      popUp: !isIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources:  ["https://www.microsoft.com/en-us/"],
      protectedResourceMap : [["https://graph.microsoft.com/v1.0/me", ["user.read"]]],
      extraQueryParameters: {}
    })
  ],
  entryComponents: [DetalleUsuarioComponent,  AlertaComponent],
  providers: [
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PortalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MatDialogRef, useValue: {} },
	  { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MatPaginatorIntl, useValue: new PaginatorI18n().ObtenerIdioma() },
    { provide: LOCALE_ID, useValue: "es-MX" },
    DatePipe,
    { provide:  DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
