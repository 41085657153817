import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Avance } from '@app/_models/avance';
import { SolicitudService } from '@app/_services/solicitud.service';
import { Solicitud } from '@app/_models/solicitud';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { FileDownloadService } from '@app/_services/file-download.service';
import { Router } from '@angular/router';
import { ExpedientesAsignadosService } from '@app/_services/expedientesAsignados.service';
import { AlertaComponent, ConfirmDialogModel } from '@app/_components/alerta/alerta.component';
import { MatDialog } from '@angular/material/dialog';
import { CicloService } from '@app/_services/ciclo.service';
import { Ciclo } from '@app/_models/ciclo';

@Component({
  selector: 'app-pantalla-principal',
  templateUrl: './pantalla-principal.component.html',
  styleUrls: ['./pantalla-principal.component.scss']
})
export class PantallaPrincipalComponent implements OnInit {

  displayedColumns: string[] = ['Ciclo','Curp', 'Nombre',  'Centro', 'Seccion', 'Grado', 'Estatus', 'Opciones'];
  dataSource = new MatTableDataSource<Solicitud>();;

  loading: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  breakpoint = 5
  //adeudo: boolean = false;
  isAnahuac: boolean = false;

  permitirSol : boolean = true;
  mensajePermitirSol : string = "";
  
  length:number = 0; 
  AlumnoID : number;
  Ciclo: Ciclo;

  public avance: Avance = {
    CompletoDatosPersonales: false,
    CompletoMatriculacion: false,
    CompletoPago: false,
    CompletoSolicitud: false,
    SolicitudAdmisionId: -1
  };

  constructor(
    private authenticationService: AuthenticationService,
    private solicitudService: SolicitudService,
    private fileDownloadService: FileDownloadService,
    private cicloService : CicloService,
    private expedientesAsignadosService : ExpedientesAsignadosService,  
    public dialog: MatDialog,
    private router: Router) {
      
    this.authenticationService.currentUser.subscribe(user => {
      if (user != null) {
        this.AlumnoID = user.AlumnoID;
      };
    });
  }

  ngOnInit(): void {
    this.loading = true;

    let anahuac = localStorage.getItem('usrAhc');
    if(anahuac == "true") this.isAnahuac = true;
    this.permitirSolicitud();
    
    this.breakpoint = (window.innerWidth <= 960) ? 1 : 5;
    this.solicitudService.limpiarSolicitud();
    this.obtenerSolicitudes();

    this.cicloService.consultaCicloActivo(this.AlumnoID).then(ciclo => {
      this.Ciclo = ciclo;
      }).catch(err => {
      console.log("Error" + err);
    }); 
  }

  obtenerSolicitudes(){
      console.log("Busncado solicitudes");

    this.solicitudService.obtenerSolicitudesAlumno()
    .pipe(first())
    .subscribe(
      data => {
        this.length = data.length;
        data.forEach(element => {
          console.log(element.SolicitudID + " " + element.SolicitudEditable);
        });
        this.dataSource = new MatTableDataSource<Solicitud>(data);
        this.loading = false;
      },
      error => {
        this.error = true;
        this.errorMessage = "Error al obtener la lista de usuarios-empleados";
        this.loading = false;
      }); 
  }

  onResize(event) {
    this.breakpoint = (window.innerWidth <= 960) ? 1 : 5;
  }

  editarSolicitud(solicitud:Solicitud){
    this.solicitudService.almacenarSolicitud(solicitud);
    this.router.navigate(['/seleccion-beca']);  
  }

  permitirSolicitud(){
    this.solicitudService.permitirSolicitud( this.AlumnoID).then( e => {
      if(!e.Dato){
        this.permitirSol = e.Dato;
        this.mensajePermitirSol = e.Mensaje;
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  }  

  /*
  becaExtemporanea(){
    this.solicitudService.becaExtemporanea( this.AlumnoID, this.Ciclo.CicloID).then( e => {
      if(e.FechaFin){
        if(e.FechaFin  > this.Ciclo.FechaSolicitudFin){
          this.permitirSol = true;
        }
      }
    }).catch(err => {
      console.log("Error" + err);
    });  
  }  */

  eliminarSolicitud(solicitud : Solicitud){
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Está seguro de que quiere borrar la solicitud?');
    const dialogRef = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.solicitudService.delete(solicitud)
        .pipe(first())
        .subscribe(
          data => {
            if (data.Exito){
              this.obtenerSolicitudes();
            }else{
              this.errorMessage = data.Mensaje;
              this.error = true;
              const dialogData = new ConfirmDialogModel('Error', data.Mensaje, false);    
            }            
          },
          () => {
            this.error = true;
            this.errorMessage = "Error al eliminar la empresa";
            const dialogData = new ConfirmDialogModel('Error', 'Sucedió un error al procesar la Solicitud.', false);    
          });
      }
    });    
  }  

  /*
  esAlumnoMoroso(){
    this.solicitudService.esAlumnoMoroso( this.AlumnoID).then( e => {
      this.adeudo = e;
    }).catch(err => {
      console.log("Error" + err);Ks
    });  

  }  */

  descargar(solicitud:Solicitud){
    var nombre = solicitud.Alumno.CURP;
     this.expedientesAsignadosService.obtieneFicha(solicitud.SolicitudID)
     .subscribe(
       data => {
         if (!!data) {
           this.fileDownloadService.downloadFile(data, "solicitud_"+ nombre + ".pdf", "application/pdf");
         }
       }
     );
  }

  descargarReglamento(){
    
     this.solicitudService.descargarReglamento(this.AlumnoID)
     .subscribe(
       data => {
         if (!!data) {
           this.fileDownloadService.downloadFile(data, "Reglamento" + ".pdf", "application/pdf");
           this.aceptarReglamento();
         }
       }
     );
  }
  
  aceptarReglamento(): void { 
    const dialogData = new ConfirmDialogModel("Confirmar", '¿Ha leído el reglamento y acepta los términos y condiciones?');
    const dialogRefConfirmar = this.dialog.open(AlertaComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRefConfirmar.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        this.router.navigate(['/seleccion-beca']);  
      }
    });    
  }
}
