import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ModificacionBecaSolicitud } from '@app/_models/modificacionBecaSolicitud';
import { EstatusModificacionBeca } from '@app/_models/estatusModificacionBeca';
import { ResponseRest } from '@app/_models/responseRest';
import { MontosPropuesta } from '@app/_models/montosPropuesta';

@Injectable({ providedIn: 'root' })
export class ModificacionBecasService {

  constructor(private http: HttpClient) {}

  listadoModBecas(cicloID: number, centros : string[],
    secciones : number[], estatusModificacion : number[], nRegistros:number, nPagina:number, nombre:string, UsuarioID : number){
    return this.http.post<any>(`${environment.apiUrl}/ModificacionBecas`,  {cicloID,  centros, secciones,
        estatusModificacion, nombre, nRegistros, nPagina, UsuarioID} );
  }

  guardarModificacionBeca(ModificacionBeca: ModificacionBecaSolicitud){  
    return this.http.post<any>(`${environment.apiUrl}/ModificacionBeca`,  ModificacionBeca ).toPromise();
  }

  aprobarRechazarModificacionBeca(ModificacionBeca: ModificacionBecaSolicitud){
    return this.http.post<any>(`${environment.apiUrl}/AprobarRechazarModificacion`,  ModificacionBeca ).toPromise();
  }

  consultarEstatusModificacion():Promise<EstatusModificacionBeca[]>{
    return this.http.get<EstatusModificacionBeca[]>(`${environment.apiUrl}/EstatusModificacionBeca`).toPromise();
  }

  listadoRevModBecas(cicloID: number, centros : string[],
    secciones : number[], estatusModificacion : number[], nRegistros:number, nPagina:number, nombre:string, UsuarioID : number){
    console.log("Estatus modi"+ JSON.stringify(estatusModificacion));
    return this.http.post<any>(`${environment.apiUrl}/RevisionModificacionBecas`,  {cicloID,  centros, secciones,
      estatusModificacion, nombre, nRegistros, nPagina, UsuarioID} );
  }

  importar(archivos: FormData, ModificacionBecaSolicitudID: number) {
  
    let params = new HttpParams();
    params = params.append('ModificacionBecaID', ModificacionBecaSolicitudID.toString() );

    const options = {
      headers: new HttpHeaders(),
      params: params
    }

    return this.http.post<ResponseRest<any>> (`${environment.apiUrl}/GuardarDocumentoModBeca`, archivos, { params } );
  }

  descargarDocumento(ModificacionBecaSolicitudID: number) {
     console.log("Descargando archivo " + ModificacionBecaSolicitudID);
      let params = new HttpParams();
      params = params.append('ModificacionBecaSolicitudID', ModificacionBecaSolicitudID.toString());
      
      return this.http.get(`${environment.apiUrl}/DescargaDocModBeca/`, { params, responseType: 'blob' } );
  }

  nombreDocumentoModificacionBeca(ModificacionBecaSolicitudID : number){
    return this.http.get<string>(`${environment.apiUrl}/${ModificacionBecaSolicitudID}/NombreDocumento` ).toPromise();
  }

  ObtenerMontosPropuesta(CentroID : string, CicloID : number, SolicitudID : number){
    return this.http.get<ResponseRest<MontosPropuesta>>(`${environment.apiUrl}/ModificacionBeca/${CentroID}/${CicloID}/${SolicitudID}/NuevoMonto`).toPromise();
  }
}