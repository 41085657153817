<app-navigation-bar  #navigationbar></app-navigation-bar>

<mat-sidenav-container  class="sidebar-container">
  <mat-sidenav  [mode]="sidebarMode" *ngIf="isAuth" [position]="sidebarPosition" [(opened)]="sidebarOpened" #sideBar [style]="sidebarStyle">
    <app-side-bar></app-side-bar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-loading-screen></app-loading-screen>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<app-footer  #footer></app-footer>
