import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revision-beca-tabs',
  templateUrl: './revision-beca-tabs.component.html',
  styleUrls: ['./revision-beca-tabs.component.scss']
})
export class RevisionBecaTabsComponent implements OnInit {

  selectedTab = 0
  constructor() { }

  ngOnInit(): void {
  }

  changeTab() {
    this.selectedTab += 1;
    if (this.selectedTab >= 2) this.selectedTab = 0;
  }
}
