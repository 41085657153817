import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GradoEducativo } from '@app/_models/gradoEducativo';
import { ObjetoOp } from '@app/_models/objectOperation';
import { TipoBecaReqSeccion } from '@app/_models/tipoBecaReqSeccion';
import { TipoBecaReqSecGra } from '@app/_models/tipoBecaReqSecGra';
import { GradoEducativoService } from '@app/_services/gradoEducativo.service';
import { TipoBecaService } from '@app/_services/tipoBeca.service';

@Component({
  selector: 'app-tipobeca-req-sec-gra',
  templateUrl: './tipobeca-req-sec-gra.component.html',
  styleUrls: ['./tipobeca-req-sec-gra.component.scss']
})
export class TipobecaReqSecGraComponent implements OnInit {
  displayedColumnsNum: string[] = ['aplicable','grado', 'minimo', 'maximo'];
  displayedColumns: string[] = ['aplicable', 'grado']; 
  dataSourceRequisitoGrados = new MatTableDataSource<TipoBecaReqSecGra>();

  grados: GradoEducativo[] = []; 
  dataTipoBecaSecciones : TipoBecaReqSeccion;
  cargandoBecas : string;
  length:number = 0
  idUsuario: number;

  error: boolean = false;
  errorMessage: string = "";
  guardando = false;
  enableBtn = true;
  tipoRequisito: string = "";
  reglonSeleccionado :TipoBecaReqSecGra;

  constructor(    private gradoService: GradoEducativoService,
    private tipoBecaService : TipoBecaService,
    public dialogRef: MatDialogRef<TipobecaReqSecGraComponent>,
    public dialog: MatDialog,    
    @Inject(MAT_DIALOG_DATA) public data: ObjetoOp) { 
      this.dataTipoBecaSecciones = data.Objeto;
    }

  ngOnInit(): void {
    this.enableBtn = true;
    this.tipoRequisito = this.dataTipoBecaSecciones.TipoBecaRequisito.RequisitoBeca.Clave 
    if(this.dataTipoBecaSecciones.RequisitoGrados == null ||this.dataTipoBecaSecciones.RequisitoGrados.length == 0){
      this.initGrados();
    }else{
      this.dataSourceRequisitoGrados = new MatTableDataSource<TipoBecaReqSecGra>(this.dataTipoBecaSecciones.RequisitoGrados);
      this.length = this.dataSourceRequisitoGrados.data.length;
    }
  }



  initGrados(){
    this.guardando = true;
    var SeccionID = this.dataTipoBecaSecciones.Seccion.SeccionID;
    this.gradoService.consultaGradosXSeccion(SeccionID).then(grados => {
      this.grados = grados;
      this.initTable();
    }).catch(err => {
      console.log("Error" + err);
    });
  }


  initTable(){
    
    this.dataTipoBecaSecciones.RequisitoGrados = [];
    
    this.grados.forEach(
      x => {
        var tipoBecaReqSecGra= new TipoBecaReqSecGra();
        tipoBecaReqSecGra.Grado = x;
        if(this.tipoRequisito == 'NUM'){
          tipoBecaReqSecGra.Minimo = this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo;
          tipoBecaReqSecGra.Maximo = this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo;
        }


        this.dataTipoBecaSecciones.RequisitoGrados.push(tipoBecaReqSecGra);
      }
      
    );
    this.dataSourceRequisitoGrados = new MatTableDataSource<TipoBecaReqSecGra>(this.dataTipoBecaSecciones.RequisitoGrados);
    this.length = this.dataSourceRequisitoGrados.data.length;
    this.guardando = false;
    
  }

  onSubmit(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }


  validateMinMax(tipoBecaReq:TipoBecaReqSeccion){
    this.enableBtn = true;
    if(  ( tipoBecaReq.Minimo  >=  this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo  &&   tipoBecaReq.Minimo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)
        && (tipoBecaReq.Maximo  >=   this.dataTipoBecaSecciones.TipoBecaRequisito.Minimo &&   tipoBecaReq.Maximo <= this.dataTipoBecaSecciones.TipoBecaRequisito.Maximo)){
      tipoBecaReq.Invalido = false;
    }else{
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }

    if(tipoBecaReq.Maximo < tipoBecaReq.Minimo) {
      tipoBecaReq.Invalido = true;
      this.enableBtn = false;
    }
 }
}

