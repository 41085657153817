<mat-card *ngIf="enable_" class="seleccion-beca-card">

    <br><br><br>
    <mat-card-title class="card-title fz-40">Bienvenido a SEMPER ALTIUS.</mat-card-title>
    <br>
    <br><br>
    <form class="example-form" name="cambioPasswordForm" id="cambioPasswordForm" [formGroup]="cambioPasswordForm">
      <div class="text-center">
          <mat-form-field class="recupera-full-width xs-width" appearance="outline">
              <mat-label>Contraseña</mat-label>
              <input matInput [type]="show ? 'text' : 'password'" id="password" 
              (focus)="mostrarDescripcion()" (focusout)="ocultarDescripcion()"
              name="password" formControlName="password" required
              matTooltip="La contraseña debe tener al menos 8 caractéres, mayúsculas, minúsculas y al menos un número o carácter especial"
              (ngModelChange)="updateProgressBar()" >
              <span>
                <button mat-icon-button matSuffix  class="position-view" matTooltip="Visualizar contraseña" (click)="passwordView()" (click)="hide = !hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>
              <mat-hint *ngIf="fortalezaError">
                  La contraseña no cumple con los requisitos de seguridad 
              </mat-hint>
          </mat-form-field>
          <mat-hint *ngIf="mostrarDescripcionPass" class="registro-label-pass">Debe contener al menos 8 caracteres, mayúsculas, minúsculas y al menos un número o carácter especial</mat-hint>       
           
          <mat-form-field class="recupera-full-width xs-width" appearance="outline">    
              <mat-label>Vuelve a escribir tu contraseña</mat-label>      
              <input matInput placeholder="Vuelve a escribir tu contraseña" [type]="show2 ? 'text' : 'password'" id="confirmPassword" name="confirmPassword" 
              formControlName="confirmPassword" [errorStateMatcher]="matcher">
              <span>
                <button mat-icon-button matSuffix  class="position-view" matTooltip="Visualizar contraseña" (click)="passwordView2()" (click)="hide = !hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </span>
              <mat-hint *ngIf="cambioPasswordForm.hasError('notSame')">
                  La contraseña no coincide
              </mat-hint>  
          </mat-form-field>
      </div>
      <div class="text-center">
          <mat-progress-bar *ngIf="containsPssword"
          mode="determinate"
          class="example-full-width"
          [color]="colorForce"        
          [value]="valueForce"
          [bufferValue]="bufferValue">
          </mat-progress-bar>
      </div>
      <br><br><br>
      <div class="text-center">
          <button class="button" mat-raised-button  (click)="cambiarContrasena()">Crear contraseña</button>
      </div>
  </form>
</mat-card>

<mat-card *ngIf="!enable_" class="recupera-card">
  <br>
  <mat-card-title class=""><div class="titulo-danger center">¡Enlace inválido!</div></mat-card-title>
  <br>
  <div class="texto-informativo">
      <label class=''>
      El enlace de recuperación de contraseña ya no es válido. 
      <br>
      <br>
      Si requieres recuperar tu contraseña, de favor ingresa <a id="link" href="#" routerLink="/recupera-cuenta-empresa" >aquí</a>.
      <br>
      </label>           
  </div>
</mat-card>