import { DecimalPipe } from '@angular/common';
import { ResumenPropuesta } from './resumenPropuesta';

export class AnalisisComparativo {

    cicloAnterior : ResumenPropuesta;
    cicloActual : ResumenPropuesta;
    cicloPropuesto : ResumenPropuesta;
    MontoAprobado : number;
    Diferencia : number;
}